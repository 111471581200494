<template>
    <div id="app">
        <!-- 面包屑导航 -->
        <!-- <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>商品设置</el-breadcrumb-item>
            <el-breadcrumb-item>创建商品</el-breadcrumb-item>

        </el-breadcrumb> -->

        <!-- 主要区域 -->

        <el-card>

            <div class="goBackDiv">
                <el-button size="mini" class="goBack reverseBtn" @click="goGoodsList">返 回</el-button>
            </div>

            <el-steps :active="active" finish-status="success" >
                <el-step title="填写基本信息" description="设置商品基本信息">
                </el-step>
                <el-step title="选择产品" description="选择商品所含有的产品"></el-step>

                <el-step title="完成"></el-step>
            </el-steps>

            <!-- 第一步 -->
            <div class="showArea" v-show="active==0?true:false">
                <div class="rules" v-show="closeRulesState">
                    <div class="title">
                        <i class="el-icon-s-opportunity" style="color:#1661dd;margin-right:5px"></i>创建商品规则:
                    </div>
                    <div class="content">
                        1.设置好商品价格后，可不用再设置每件商品的价格 <br>
                    </div>

                    <div class="closeBtn" @click="closeRules">
                        <i class="el-icon-close"></i>
                    </div>
                </div>


                <el-form :model="addGoods_Form" :rules="addGoods_Rules" label-width="180px" class="addGoodsForm--Style">

                    <div class="first">
                        <span>
                            <el-form-item label="商品名称:" prop="name">
                                <el-input v-model="addGoods_Form.name" placeholder="请输入商品名称" class="goodsName"
                                    size="medium">
                                </el-input>
                            </el-form-item>
                        </span>
                        <span>
                            <el-form-item label="商品分类:" prop="category">
                                <el-cascader class="chooseCategory" :options="sortSetData" :show-all-levels="true"
                                    clearable placeholder="请选择商品分类"
                                    :props="{value:'id',label:'name',checkStrictly: true}" size="medium"
                                    v-model="addGoods_Form_Category"></el-cascader>
                            </el-form-item>
                        </span>
                    </div>

                    <div class="second">
                        
                        <span>
                            <el-form-item label="下架日期:" >
                                <el-date-picker v-model="addGoods_Form.end_time" type="date" placeholder="选择日期"
                                    size="small" value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                        </span>

                         <span>
                            <el-form-item label="是否一口价:" prop="is_return">
                                <el-radio-group v-model="addGoods_Form.is_fixed">
                                    <el-radio :label="1">是</el-radio>
                                    <el-radio :label="0">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </span>
                    </div>

                    <div class="third">
                        <span>
                            <el-form-item label="是否可售:" prop="is_sell">
                                <el-radio-group v-model="addGoods_Form.is_sell">
                                    <el-radio :label="1">是</el-radio>
                                    <el-radio :label="0">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </span>
                        <span>
                            <el-form-item label="是否可退回:" prop="is_return">
                                <el-radio-group v-model="addGoods_Form.is_return">
                                    <el-radio :label="1">是</el-radio>
                                    <el-radio :label="0">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </span>
                    </div>

                    <div class="fourthly">
                        
                        <span>
                            <el-form-item label="最小起订量：" prop="is_return">
                                <el-input placeholder="请输入" v-model="addGoods_Form.sell_min_num" size="small" clearable></el-input>
                            </el-form-item>
                        </span>

                        <span>
                             <el-form-item label="最大可售量：" prop="is_return">
                            <el-input placeholder="请输入" v-model="addGoods_Form.sell_max_num" size="small" clearable></el-input>
                        </el-form-item>
                        </span>
                    </div>

                </el-form>
            </div>

            <!-- 第二步 -->
            <div class="showArea" v-show="active==1?true:false">

                <el-button class="text_primary btn" type="text" size="small" @click="addGoodsChooseProducts">添加产品
                </el-button>

                <el-table :data="choosedAddGoodsProduct3" tooltip-effect="dark" style="width: 100%" ref="multipleTable"
                    border :header-cell-style="styleBindFun" >

                    <el-table-column prop="sn" label="货号" align="center">
                    </el-table-column>
                    <el-table-column align="center" prop="brand_name" label="品牌名称">
                    </el-table-column>
                    <el-table-column label="产品名称" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.name }} <span v-show="scope.row.spec">( {{ scope.row.spec }} )</span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="remarks" label="数量" align="center">
                        <template slot-scope="scope">
                            <el-input size="mini"  @blur="calc"
                            placeholder="请输入内容"  v-model="scope.row.quantity">
                                {{scope.row.unit_name}}
                            </el-input>
                            
                        </template>

                    </el-table-column>
                    <el-table-column label="价格" align="center">
                        <template slot-scope="scope">
                            <el-input size="small"  @blur="calc"
                            placeholder="请输入内容"  v-model="scope.row.price">
                            </el-input>
                        </template>
                    </el-table-column>

                      <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                           <el-button type="text" class="text_delete" @click="deleteNode(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>


                </el-table>

                <div class="count">
                    <span>
                        种类: <span class="Number">{{type}}</span>
                    </span>

                    <span style="margin:0 30px">
                        个数: <span class="Number">{{total}} </span>
                    </span>

                    <span>
                        总价: <span class="Number"> {{totalPrice}}</span>元 (<span
                            class="Number">{{CNCapitalValue==0?'零':CNCapitalValue}}</span>)
                    </span>

                </div>
            </div>

            <!-- 第三步 -->
            <div class="showArea" v-show="active==2?true:false">
                <el-result icon="success" title="创建商品成功">
                    <template slot="extra">
                        <el-button type="primary" size="medium" @click="goGoodsList">返回</el-button>
                    </template>
                </el-result>
            </div>

            <div class="stepBtns--style">
                <el-button plain size="small"  v-show="StepStatePrev" @click="Prev">
                    上一步</el-button>
                <el-button size="small" class="frontBtn" v-show="StepStateNext" @click="next">
                    下一步</el-button>
                <el-button style="margin-top: 12px;" v-show="submitState" size="small" class="submit" @click="submit">提
                    交
                </el-button>
            </div>

        </el-card>



        <!-- -----------------------------------Dialog   对话框（一层）------------------------------------------- -->
        <!-- 增加商品选择产品对话框 -->
        <el-dialog class="ChooseProducts" title="选择产品" :visible.sync="is_addGoodsChooseProducts" width="85%">

            <div class="conditionArea">
                <div class="first">
                    <span>
                        选择品牌：
                        <el-select @change="searchWithCondition" v-model="ChooseProducts_form.brand_id" clearable placeholder="请选择" size="mini">
                            <el-option v-for="item in brandData" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </span>

                    <span>
                        是否计划生产：
                        <el-select @change="searchWithCondition" v-model="ChooseProducts_form.is_plan" clearable placeholder="请选择" size="mini">
                            <el-option v-for="item in is_planData" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </span>

                  <span>
                        是否生成二维码：
                        <el-select @change="searchWithCondition" v-model="ChooseProducts_form.is_qr" clearable placeholder="请选择" size="mini">
                            <el-option v-for="item in is_qrData" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </span>

    
                     <span>
                        是否生产：
                        <el-select @change="searchWithCondition" v-model="ChooseProducts_form.is_produce" clearable placeholder="请选择" size="mini">
                            <el-option v-for="item in is_produceData" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </span>
                </div>


                <div class="second">
                   

                  
                    <span>
                        批次管理：
                        <el-select @change="searchWithCondition" v-model="ChooseProducts_form.is_batch" clearable placeholder="请选择" size="mini">
                            <el-option v-for="item in is_batchData" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </span>
                    
                    <span>
                         搜索：
                    <el-input @change="searchWithCondition" placeholder="关键词搜索 支持产品名 别名 产品货号 条码" v-model="ChooseProducts_form.keyword" size="mini" class="searchStyle" clearable></el-input>
                    </span>

                    <span>
                    <el-button class="reverseBtn el-icon-refresh" size="mini" @click="resetBtn">重 置</el-button>

                    </span>
                </div>

            </div>

            <el-table :data="ChooseProductsData" tooltip-effect="dark" style="width: 100%" ref="multipleTable"
                :header-cell-style="styleBindFun" border>
                <el-table-column prop="sn" label="货号" align="center">
                </el-table-column>
                <el-table-column align="center" prop="brand_name" label="品牌名称">
                    <template slot-scope="scope">
                        {{scope.row.brand_name}}
                    </template>
                </el-table-column>
                <el-table-column label="产品名称" align="center" width="390">
                    <template slot-scope="scope">
                        {{ scope.row.name }} <span v-show="scope.row.spec ">( {{ scope.row.spec }} )</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="barcode" label="条码">
                </el-table-column>

                <el-table-column  label="是否批次管理" align="center">
                    <template slot-scope="scope">
                       <div v-if="scope.row.is_batch" class="yesIcon el-icon-check"></div>
                            <div v-else class="noIcon el-icon-close"></div>
                    </template>
                </el-table-column>

                 <el-table-column  label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button  type="text" :disabled="scope.row.mychecked" @click="addProduct(scope.row)">添加
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>


            <!-- 分页器 -->
            <el-pagination @current-change="handleCurrentChangeaddGoodsChooseProducts" :current-page="ChooseProducts_form.page"
                :page-sizes="[5]" :page-size="ChooseProducts_form.per_page"
                layout="total, sizes, prev, pager, next, jumper" :total="ChooseProductsTotal" align="center">
            </el-pagination>


            <div slot="footer" class="dialog-footer">
               
                <el-button size="mini" class="frontBtn"  @click="choosedProduct">确 定</el-button>
            </div>
        </el-dialog>


        <!-- -----------------------------------Dialog   对话框（二层）------------------------------------------- -->





        <!-- -----------------------------------Drawer 抽屉（一层）------------------------------------------- -->
        <!-- <el-drawer title="商品分类回收站" :visible.sync="drawer" :direction="direction">
            <el-table :data="sortSetRecycleData" stripe border style="width: 100%">
                <el-table-column prop="name" label="名字" align="center">
                </el-table-column>
                <el-table-column fixed="right" label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button @click="restoreSortSet(scope.row)" type="primary" size="mini">
                            移出回收站
                        </el-button>
                        <el-button @click="deleteSortSet(scope.row)" type="danger" size="mini">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-drawer> -->


    </div>
</template>

<script>
    import {
        adminApi
    } from '../../config'

    export default {
        data() {
            return {
                active: 0,
                sortSetData: [],

                // ------------------创建商品 数据---------------------
                // is_addGoods: false,
                addGoods_Form: {
                    name: '',
                    category_id: '',
                   
                    end_time: '',
                    is_sell: '',
                    is_return: '',
                    is_fixed: '',
                    sell_min_num: '',
                    sell_max_num: '',

                    product_list: []
                },
                addGoods_Form_Category: [],
                addGoods_Rules: {
                    name: {
                        required: true,
                        message: '该项为必须填的',
                        trigger: 'blur'
                    },

                    category: {
                        required: true,
                        message: '该项为必须填的',
                        trigger: 'blur'
                    },



                    end_time: {
                        required: true,
                        message: '该项为必须填的',
                        trigger: 'blur'
                    },

                    is_sell: {
                        required: true,
                        message: '该项为必须填的',
                        trigger: 'blur'
                    },
                    is_return: {
                        required: true,
                        message: '该项为必须填的',
                        trigger: 'blur'
                    },

                },
                is_addGoodsChooseProducts: false,
                ChooseProducts_form: {
                    page: 1,
                    per_page: 5,
                    brand_id: '',
                    is_plan: '',
                    is_show: 1,
                    is_produce: '',
                    is_qr: '',
                    is_batch: '',
                    keyword: '',
                },
                brandData: [],
                is_planData: [{
                        id: 0,
                        name: '否'
                    },
                    {
                        id: 1,
                        name: '是'
                    }
                ],
                is_showData: [{
                        id: 0,
                        name: '否'
                    },
                    {
                        id: 1,
                        name: '是'
                    }
                ],
                is_produceData: [{
                        id: 0,
                        name: '否'
                    },
                    {
                        id: 1,
                        name: '是'
                    }
                ],
                is_qrData: [{
                        id: 0,
                        name: '否'
                    },
                    {
                        id: 1,
                        name: '是'
                    }
                ],
                is_batchData: [{
                        id: 0,
                        name: '否'
                    },
                    {
                        id: 1,
                        name: '是'
                    }
                ],
                ChooseProductsData: [],
                ChooseProductsTotal: 0,
                is_addNumber: false,
                addGoodsOfProduct_num: '',
                addGoodsOfPro_id: '',

                choosedAddGoodsProduct: [],
                choosedAddGoodsProduct3: [],
                dataTem: [],
                is_addPrice: false,
                addGoodsOfProduct_price: '',
                CNCapitalValue: '',
                addGoodsOfPrice_id: '',
                addGoodsCNCapitalValue: '',
                valve: true,
                Goodstotal: 0,
                CNCapital_Goodstotal: '',

                // ----------------总计-----------------------------
                type: 0,
                total: 0,
                totalPrice: 0,

                // 按钮状态
                StepStatePrev: false,
                StepStateNext: true,
                submitState: false,
                closeRulesState: true,

                // 设置价格按钮显示与隐藏
                setPriceState: true,
            }
        },
        created() {

            this.getSortSetInfo()
        },
        methods: {

            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind = "background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                return styleBind;
            },

            closeRules() {
                this.closeRulesState = false
            },

            goGoodsList() {
                this.$router.push({
                    path: '/goodsSet',
                    query: {
                        data: 'add'
                    }
                })
            },



            next() {
                if (this.active++ > 2) this.active = 0;
                this.StepStatePrev = true
                this.StepStateNext = false
                this.submitState = true
            },

            Prev() {
                if (this.active-- < 0) this.active = 3;

                this.StepStatePrev = false
                this.StepStateNext = true
                this.submitState = false
            },

            // ------------展示设置分类数据   方法------------------------
            async getSortSetInfo() {
                const res = await this.$https.post(adminApi+'/category/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.sortSetData = res.data
            },
            // 数字转为大写方法
            addGoodsCNCapital() {
                this.addGoodsCNCapitalValue = this.numberParseChina(this.addGoods_Form.price)
                this.totalPrice = this.addGoods_Form.price
                this.CNCapital()
                this.setPriceState = false

            },
            //阿拉伯数字转换成大写汉字
            numberParseChina(money) {
                //汉字的数字
                var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');
                //基本单位
                var cnIntRadice = new Array('', '拾', '佰', '仟');
                //对应整数部分扩展单位
                var cnIntUnits = new Array('', '万', '亿', '兆');
                //对应小数部分单位
                var cnDecUnits = new Array('角', '分', '毫', '厘');
                //整数金额时后面跟的字符
                var cnInteger = '整';
                //整型完以后的单位
                var cnIntLast = '圆';
                //最大处理的数字
                var maxNum = 999999999999999.9999;
                //金额整数部分
                var integerNum;
                //金额小数部分
                var decimalNum;
                //输出的中文金额字符串
                var chineseStr = '';
                //分离金额后用的数组，预定义
                var parts;
                if (money == '') {
                    return '';
                }
                money = parseFloat(money);
                if (money >= maxNum) {
                    //超出最大处理数字
                    return '';
                }
                if (money == 0) {
                    chineseStr = cnNums[0] + cnIntLast + cnInteger;
                    return chineseStr;
                }
                //转换为字符串
                money = money.toString();
                if (money.indexOf('.') == -1) {
                    integerNum = money;
                    decimalNum = '';
                } else {
                    parts = money.split('.');
                    integerNum = parts[0];
                    decimalNum = parts[1].substr(0, 4);
                }
                //获取整型部分转换
                if (parseInt(integerNum, 10) > 0) {
                    var zeroCount = 0;
                    var IntLen = integerNum.length;
                    for (var i = 0; i < IntLen; i++) {
                        var n = integerNum.substr(i, 1);
                        var p = IntLen - i - 1;
                        var q = p / 4;
                        var m = p % 4;
                        if (n == '0') {
                            zeroCount++;
                        } else {
                            if (zeroCount > 0) {
                                chineseStr += cnNums[0];
                            }
                            //归零
                            zeroCount = 0;
                            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
                        }
                        if (m == 0 && zeroCount < 4) {
                            chineseStr += cnIntUnits[q];
                        }
                    }
                    chineseStr += cnIntLast;
                }
                //小数部分
                if (decimalNum != '') {
                    var decLen = decimalNum.length;
                    for (var i = 0; i < decLen; i++) {
                        var n = decimalNum.substr(i, 1);
                        if (n != '0') {
                            chineseStr += cnNums[Number(n)] + cnDecUnits[i];
                        }
                    }
                }
                if (chineseStr == '') {
                    chineseStr += cnNums[0] + cnIntLast + cnInteger;
                } else if (decimalNum == '') {
                    chineseStr += cnInteger;
                }
                return chineseStr;
            },

            // ---------------------------------------------------点击添加产品方法-------------------------------------------------------------------------------------------------------------------------

            // 获取品牌
            async getBrandData() {
                const res = await this.$https.post(adminApi + '/brand/select?_ajax=1')
                this.brandData = res.data
            },

            searchWithCondition(){
                this.ChooseProducts_form.page=1
                this.addGoodsChooseProducts()
            },

            resetBtn(){
                this.ChooseProducts_form.brand_id=''
                this.ChooseProducts_form.is_plan=''
                this.ChooseProducts_form.is_produce=''
                this.ChooseProducts_form.is_qr=''
                this.ChooseProducts_form.is_batch=''
                this.ChooseProducts_form.keyword=''
                this.addGoodsChooseProducts()
            },



            // 点击添加产品方法
            async addGoodsChooseProducts() {
                this.is_addGoodsChooseProducts = true
                let params = this.ChooseProducts_form
                const res = await this.$https.post(adminApi + '/product/select?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.ChooseProductsData = res.data.data
                this.ChooseProductsTotal = res.data.total
                this.getBrandData()
                this.echo()
            },
            echo(){
                this.choosedAddGoodsProduct3.forEach(ele=>{
                    this.ChooseProductsData.forEach(node=>{
                        if(node.id == ele.id ){
                            node.mychecked=true
                        }
                    })
                })
            },

            deleteNode(row){
                let i=this.choosedAddGoodsProduct3.indexOf(row)
                this.choosedAddGoodsProduct3.splice(i,1)
                this.calc()
            },

            calc(){
               this.type= this.choosedAddGoodsProduct3.length
                this.total=0
               this.choosedAddGoodsProduct3.forEach(node=>{
                   this.total+=Number(node.quantity)
               })
               this.totalPrice=0
        
                this.totalPrice=this.choosedAddGoodsProduct3.reduce((total,cur)=>{
                    return total+Number(cur.price) * cur.quantity
                },this.totalPrice)


                    this.totalPrice=this.totalPrice.toFixed(2)
                this.CNCapital()
            },



           
         

            addProduct(row){
                row.mychecked=true
                let obj = {
                        id: row.id,
                        sn: row.sn,
                        brand_name: row.brand_name,
                        name: row.name,
                        quantity: 0,
                        price: 0,
                        spec: row.spec,
                        unit_name: row.unit_name,
                }
                this.choosedAddGoodsProduct3.push(obj)
                this.calc()
            },
            

    
            // 页面大小改变事件
            handleCurrentChangeaddGoodsChooseProducts(data) {
                this.ChooseProducts_form.page = data
                this.addGoodsChooseProducts()
            },
            // 选择产品框确定按钮方法
            choosedProduct() {
                this.is_addGoodsChooseProducts = false
                this.calc()
            },


            // 待测试方法-------------------------------------------------------------
            CNCapital() {
                this.CNCapitalValue = this.numberParseChina(this.totalPrice)
            },



            // --------------------------------------创建商品-----------------------------------------------------------------
            async submit() {


                this.addGoods_Form.category_id = this.addGoods_Form_Category[this.addGoods_Form_Category.length-1]

                 this.addGoods_Form.product_list=[]

                this.choosedAddGoodsProduct3.forEach(item => {
                    let obj = {
                        product_id: item.id,
                        quantity: item.quantity,
                        price: item.price
                    }

                this.addGoods_Form.product_list.push(obj)
                })

                if (this.addGoods_Form.name == '') {
                    return this.$message.error('请将表单填写完整1')
                }
                if (this.addGoods_Form.category_id == '') {
                    return this.$message.error('请将表单填写完整2')
                }
               
                if (this.addGoods_Form.is_sell == '' && this.addGoods_Form.is_sell !==0) {
                    return this.$message.error('请将表单填写完整4')
                }
                if (this.addGoods_Form.is_return == ''  && this.addGoods_Form.is_return !==0) {
                    return this.$message.error('请将表单填写完整5')
                }

                if (this.addGoods_Form.is_fixed == ''  && this.addGoods_Form.is_fixed !==0) {
                    return this.$message.error('请将表单填写完整6')
                }

                if (this.addGoods_Form.sell_min_num == '') {
                    return this.$message.error('请将表单填写完整7')
                }

                if (this.addGoods_Form.sell_max_num == '') {
                    return this.$message.error('请将表单填写完整8')
                }

                let params = this.addGoods_Form
                const {
                    data
                } = await this.$https.post(adminApi+'/goods/create?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)

                // 跳转

                if (this.active++ > 2) this.active = 0;
                this.StepStatePrev = false
                this.StepStateNext = false
                this.submitState = false
            },



        },
    }
</script>

<style lang="less" scoped>
    .showArea {
        margin-top: 30px;

    }



    .count {
        font-size: 16px;
        color: #333;
        font-weight: 560;
        font-family: Arial, Helvetica, sans-serif;
        padding-top: 20px;
        padding-left: 50%;
       
    }



    .submit {
        background-color: #0052d9;
        border-color: #0052d9;
        color: #ffffff;
    }

    .submit:hover {
        background-color: #0052d9;
        border-color: #0052d9;
        color: #ffffff;
    }

    .submit:focus {
        background-color: #024cd2;
        border-color: #024cd2;
        color: #ffffff;
    }

    .rules {
        padding: 10px 50px;
        background-color: #e0ebff;
        margin-bottom: 10px;
        position: relative;
    }

    .rules .title {
        font-size: 15px;
        color: #000;
        font-weight: 560;
        margin: 10px 0;
    }

    .rules .content {
        font-size: 14px;
        color: #00000099;
        line-height: 200%;
    }

    .closeBtn {
        position: absolute;
        right: 3%;
        top: 15%;
        font-size: 14px;
    }

    .closeBtn:hover {
        cursor: pointer;

    }

    .goBackDiv {
        display: flex;
        justify-content: end;
        padding-right: 5%;
        margin-bottom: 10px;
    }

    .addGoodsForm--Style {
        margin-top: 30px;
    }



    .addGoodsForm--Style .first,
    .addGoodsForm--Style .second,
    .addGoodsForm--Style .third,
    .addGoodsForm--Style .fourthly,
    .addGoodsForm--Style .fifth {
        padding: 0 20%;
        display: flex;
        margin-bottom: 10px;
    }

    .addGoodsForm--Style .first span:nth-child(1),
    .addGoodsForm--Style .second span:nth-child(1),
    .addGoodsForm--Style .third span:nth-child(1),
    .addGoodsForm--Style .fourthly span:nth-child(1) {
        flex: 1;
    }

    .addGoodsForm--Style .first span:nth-child(2),
    .addGoodsForm--Style .second span:nth-child(2),
    .addGoodsForm--Style .third span:nth-child(2),
    .addGoodsForm--Style .fourthly span:nth-child(2) {
        flex: 1;
    }

     div /deep/.el-form-item__label{
        font-weight: bolder;
        color: #333;
        font-size: 14   px;
    }

    div /deep/.addGoodsForm--Style .el-cascader {
        width: 108%;
    }

    .stepBtn--style {
        margin-left: 50%;
    }

    .stepBtns--style {
        margin-top: 20px;
        text-align: center;
    }

    .showArea .btn {

        margin-left: 10px;
    }


    .ChooseProducts .conditionArea {
        background-color: #f5f7fa;
        padding: 5px 20px;
    }

    .ChooseProducts .conditionArea .first,
    .ChooseProducts .conditionArea .second {
        margin-bottom: 10px;
        display: flex;
    }

    .ChooseProducts .conditionArea .first span,
    .ChooseProducts .conditionArea .second span {
        width: 25%;
        color: #333;
        font-size: 14px;
        display: flex;
        align-items: center;
    }

    .searchStyle{
        width: 80%;
    }

   
</style>