<template>
    <div>
        <el-card>
            <div class="title_style">请填写创建配送类型表单:</div>
            <div class="Back">
                <el-button size="small" class="reverseBtn" @click="gobackBtn">返 回</el-button>
            </div>

            <el-form class="create" :model="Form" :rules="Rules" label-width="170px">
                <el-form-item label="配送类型名称：" prop="name">
                    <el-input v-model="Form.name" class="numInput" size="small"></el-input>
                </el-form-item>
                <el-form-item label="赠送：" prop="amount">
                    <el-input v-model="Form.amount" @blur="CNCapital" class="numInput" size="small"
                        placeholder="请输入整千的倍数"></el-input>
                    <span v-if="CNCapitalStated" style="margin-left:15px"> 人民币（大写）：{{CNCapitalPrice}}</span>
                </el-form-item>
                <el-form-item label="转出:" prop="is_transfer">
                    <el-radio-group v-model="Form.is_transfer">
                        <el-radio :label="0">否</el-radio>
                        <el-radio :label="1">是</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="起订量:" prop="is_deliver_limit">
                    <el-radio-group v-model="Form.is_deliver_limit">
                        <el-radio :label="0">否</el-radio>
                        <el-radio :label="1">是</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="依赖:" prop="is_depend">
                    <el-radio-group v-model="Form.is_depend">
                        <el-radio :label="0">否</el-radio>
                        <el-radio :label="1">是</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="排序:" prop="sort_order">
                    <el-input class="sort_order_input" v-model="Form.sort_order" clearable size="small"
                        placeholder="默认100，范围0~200"></el-input>
                </el-form-item>
                <el-form-item label="商品选择:" prop="goodsList">
                    <el-button type="text" size="big" @click="add">添加</el-button>
                </el-form-item>
                <el-form-item prop="goodsList">

                    <el-table ref="multipleTable" :data="tempData" tooltip-effect="dark" style="width: 100%"
                        :header-cell-style="styleBindFun" border>

                        <el-table-column prop="sn" label="商品编号" align="center">
                        </el-table-column>
                        <el-table-column prop="name" label="商品名称" align="center">
                            <template slot-scope="scope">
                                {{scope.row.name}} <span v-show="scope.row.spec">{{scope.row.spec}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column fixed="right" label="操作" width="120" align="center">
                            <template slot-scope="scope">
                                <el-button type="text" class="deleteTextBtn" @click="deleteBtn(scope.row)">删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>

            <el-button size="small" class="frontBtn submit" @click="submitBtn"> 提 交</el-button>


        </el-card>

        <!-- 增加配送类型添加商品对话框 -->
        <el-dialog class="addGoods" title="添加商品" :visible.sync="is_add_goods" width="88%">
            <!--按钮区 -->

            <div class="DistributionSchemeBtns">
                <div>
                    <div class="one">

                        <span>
                            是否销售： <el-select v-model="is_sell" @change="search" clearable placeholder="请选择" size="mini">
                                <el-option v-for="item in is_sell_state" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </span>

                        <span>
                            是否退货： <el-select v-model="is_return" @change="search" clearable placeholder="请选择"
                                size="mini">
                                <el-option v-for="item in is_return_state" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </span>

                        <span>
                            是否一口价： <el-select v-model="is_fixed" @change="search" clearable placeholder="请选择"
                                size="mini">
                                <el-option v-for="item in is_fixed_state" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </span>


                    </div>

                    <div class="two">


                        <span>
                            是否设定了下架日期： <el-select v-model="is_end" @change="search" clearable placeholder="请选择"
                                size="mini">
                                <el-option v-for="item in is_end_state" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </span>


                        <span>
                            商品分类:
                            <el-cascader class="chooseCategory" :options="sortSetData" :show-all-levels="false"
                                clearable :props="{value:'id',label:'name',checkStrictly: true}" size="mini"
                                v-model="chooseCategory" @change="chooseCategoryFunction"></el-cascader>
                        </span>

                        <span>
                            请输入关键词:
                            <el-input placeholder="请输入" @change="search" v-model="add_goods_Input" class="input"
                                clearable size="mini" maxlength="10">
                            </el-input>
                        </span>
                    </div>

                </div>

                <div class="btns-Style">
                    <el-button size="small" icon="el-icon-refresh-right" class="reverseBtn" @click="reset">重置
                    </el-button>
                </div>
            </div>

            <el-table ref="multipleTable" :data="add_goods_Data" tooltip-effect="dark" style="width: 100%"
                :header-cell-style="styleBindFun" border @selection-change="handleSelectionChange"
                :row-key="getRowKeys">
                <el-table-column type="selection" width="55" align="center" :reserve-selection="true">
                </el-table-column>
                <el-table-column prop="sn" label="商品编号" width="120" align="center">
                </el-table-column>
                <el-table-column prop="name" label="商品名称" align="center" width="230">
                </el-table-column>
                <el-table-column label="价格" show-overflow-tooltip align="center" width="160">
                    <template slot-scope="scope">
                        ￥{{scope.row.price}}
                    </template>
                </el-table-column>

                <el-table-column label="是否可售" align="center" width="120">
                    <template slot-scope="scope">

                        <div v-if="scope.row.is_sell" class="yesIcon el-icon-check"></div>
                        <div v-else class="noIcon el-icon-close"></div>
                    </template>
                </el-table-column>

                <el-table-column label="是否可回收" align="center" width="120">
                    <template slot-scope="scope">

                        <div v-if="scope.row.is_return" class="yesIcon el-icon-check"></div>
                        <div v-else class="noIcon el-icon-close"></div>
                    </template>
                </el-table-column>

                <el-table-column label="是否一口价" align="center" width="120">
                    <template slot-scope="scope">
                        <div v-if="scope.row.is_fixed" class="yesIcon el-icon-check"></div>
                        <div v-else class="noIcon el-icon-close"></div>
                    </template>
                </el-table-column>
                <el-table-column label="包含的产品种类" align="center" width="150">
                    <template slot-scope="scope">
                        {{scope.row.products_count}}
                    </template>
                </el-table-column>
                <el-table-column prop="products_sum" label="包含的产品个数" align="center" width="150">
                </el-table-column>
                <el-table-column label="所属分类" align="center" width="150">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-show=" scope.row.category_full_name">
                            <span> {{ scope.row.category_full_name }}</span>
                        </el-tag>
                    </template>
                </el-table-column>

                <el-table-column prop="sell_min_num" label="起订数量" align="center">
                </el-table-column>

                <el-table-column prop="sell_max_num" label="限购数量" align="center">
                </el-table-column>

                <el-table-column prop="max_num" label="最大库存数" align="center" width="150">
                </el-table-column>

                <el-table-column prop="update_time" label="更新时间" align="center" width="180">
                    <template slot-scope="scope">
                        {{scope.row.update_time.trim().split(" ")[0]}}
                    </template>
                </el-table-column>

                <el-table-column label="商品下架日期" align="center" width="180">
                    <template slot-scope="scope">

                        <span v-show="scope.row.end_time !==0">{{scope.row.end_time}}</span>
                    </template>
                </el-table-column>

                <el-table-column label="删除时间" align="center" width="180">
                    <template slot-scope="scope">
                        <span v-show="scope.row.delete_time !== 0">{{scope.row.delete_time}}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="remarks" label="备注" show-overflow-tooltip align="center" width="320">
                </el-table-column>

            </el-table>

            <!-- 分页器 -->
            <el-pagination @size-change="handleSizeChangeAddGoods" @current-change="handleCurrentChangeAddGoods"
                :current-page="getDistributionTypeAddProInfo.page" :page-sizes="[6]"
                :page-size="getDistributionTypeAddProInfo.per_page" layout="total, sizes, prev, pager, next, jumper"
                :total="total" align="center">
            </el-pagination>

            <div slot="footer" class="dialog-footer">
                <el-button class="frontBtn" size="mini" @click="chooseProDo">确 定</el-button>
            </div>
        </el-dialog>



    </div>
</template>

<script>
    import {
        adminApi
    } from '../../config'

    import {
        domainApi
    } from '../../config'

    export default {
        data() {
            return {
                Form: {
                    name: '',
                    amount: '',
                    is_transfer: 0,
                    is_deliver_limit: 0,
                    is_depend: 0,
                    sort_order: '',
                    goods_list: []
                },
                Rules: {
                    name: {
                        required: true,
                        message: '请输入配送类型名称',
                        trigger: 'blur'
                    },
                    amount: {
                        required: true,
                        message: '请输入金额',
                        trigger: 'blur'
                    },
                    is_transfer: {
                        required: true,
                        message: '请确定是否能转出',
                        trigger: 'blur'
                    },
                    is_deliver_limit: {
                        required: true,
                        message: '请确定是否限额',
                        trigger: 'blur'
                    },
                    goodsList: {
                        required: true,
                        message: '请选择商品',
                        trigger: 'blur'
                    },
                    is_depend: {
                        required: true,
                        message: '请选择是否依赖其他如账单',
                        trigger: 'blur'
                    },
                    sort_order: {
                        required: true,
                        message: '请选择是否排序',
                        trigger: 'blur'
                    },

                },
                CNCapitalPrice: '',
                CNCapitalStated: false,
                is_add_goods: false,
                add_goods_Data: [],
                getDistributionTypeAddProInfo: {
                    page: 1,
                    per_page: 6,
                    is_fixed: 1,
                },
                total: 0,
                is_sell: '',
                is_sell_state: [{
                        label: '是',
                        value: 1
                    },
                    {
                        label: '否',
                        value: 0
                    },
                ],
                is_return: '',
                is_return_state: [{
                        label: '是',
                        value: 1
                    },
                    {
                        label: '否',
                        value: 0
                    },
                ],
                is_fixed: '',
                is_fixed_state: [{
                        label: '是',
                        value: 1
                    },
                    {
                        label: '否',
                        value: 0
                    },

                ],
                is_end: '',
                is_end_state: [{
                        label: '是',
                        value: 1
                    },
                    {
                        label: '否',
                        value: 0
                    },
                ],
                add_goods_Input: '',
                chooseCategory: '',
                sortSetData: [],
                GoodsDetail_data: [],
                choosedGoods: [],
                CNCapitalPrice: '',
                CNCapitalStated: false,
                chooseProductData: [],

                rowsData: [],
                tempData: [],

            }
        },
        created() {

        },
     

        methods: {
           

            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind = "background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                return styleBind;
            },

            //  返回
            gobackBtn() {
                this.$router.push({
                    path: '/distributionType'
                })
            },

            // 添加
            async add() {
                this.is_add_goods = true
                let params = this.getDistributionTypeAddProInfo
                const res = await this.$https.post(adminApi + '/goods/select?_ajax=1', params)
                
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.add_goods_Data = res.data.data
                this.total = res.data.total
                this.getSortSetInfo()

            },




            async getSortSetInfo() {
                const res = await this.$https.post(adminApi + '/categorys?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.sortSetData = res.data
            },
            handleSizeChangeAddGoods(data) {
                this.getDistributionTypeAddProInfo.per_page = data
                this.add()
            },
            handleCurrentChangeAddGoods(data) {
                this.getDistributionTypeAddProInfo.page = data
                this.add()
            },

            CNCapital() {
                if (this.Form.amount.length != 0) {
                    this.CNCapitalStated = true
                } else {
                    this.CNCapitalStated = false
                }
                this.CNCapitalPrice = this.numberParseChina(this.Form.amount)
            },
            chooseCategoryFunction(data) {
                let params = data.pop();
                this.chooseCategory = params
                this.search()
            },

            search() {
                // 1.判断搜索条件
                if (this.is_sell !== '') {
                    this.getDistributionTypeAddProInfo.is_sell = this.is_sell
                } else {
                    delete this.getDistributionTypeAddProInfo.is_sell
                }

                if (this.is_return !== '') {
                    this.getDistributionTypeAddProInfo.is_return = this.is_return
                } else {
                    delete this.getDistributionTypeAddProInfo.is_return
                }

                if (this.is_fixed !== '') {
                    this.getDistributionTypeAddProInfo.is_fixed = this.is_fixed
                } else {
                    delete this.getDistributionTypeAddProInfo.is_fixed
                }

                if (this.is_end !== '') {
                    this.getDistributionTypeAddProInfo.is_end = this.is_end
                } else {
                    delete this.getDistributionTypeAddProInfo.is_end
                }

                if (this.add_goods_Input !== '') {
                    this.getDistributionTypeAddProInfo.keyword = this.add_goods_Input
                } else {
                    delete this.getDistributionTypeAddProInfo.keyword
                }

                if (this.chooseCategory !== '') {
                    this.getDistributionTypeAddProInfo.category_id = this.chooseCategory
                } else {
                    delete this.getDistributionTypeAddProInfo.category_id
                }

                this.add()

            },
            reset() {
                // 重置
                this.is_sell = ''
                this.is_return = ''
                this.is_fixed = ''
                this.is_end = ''
                this.add_goods_Input = ''
                this.chooseCategory = ''
                this.search()
            },
            //阿拉伯数字转换成大写汉字
            numberParseChina(money) {
                //汉字的数字
                var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');
                //基本单位
                var cnIntRadice = new Array('', '拾', '佰', '仟');
                //对应整数部分扩展单位
                var cnIntUnits = new Array('', '万', '亿', '兆');
                //对应小数部分单位
                var cnDecUnits = new Array('角', '分', '毫', '厘');
                //整数金额时后面跟的字符
                var cnInteger = '整';
                //整型完以后的单位
                var cnIntLast = '圆';
                //最大处理的数字
                var maxNum = 999999999999999.9999;
                //金额整数部分
                var integerNum;
                //金额小数部分
                var decimalNum;
                //输出的中文金额字符串
                var chineseStr = '';
                //分离金额后用的数组，预定义
                var parts;
                if (money == '') {
                    return '';
                }
                money = parseFloat(money);
                if (money >= maxNum) {
                    //超出最大处理数字
                    return '';
                }
                if (money == 0) {
                    chineseStr = cnNums[0] + cnIntLast + cnInteger;
                    return chineseStr;
                }
                //转换为字符串
                money = money.toString();
                if (money.indexOf('.') == -1) {
                    integerNum = money;
                    decimalNum = '';
                } else {
                    parts = money.split('.');
                    integerNum = parts[0];
                    decimalNum = parts[1].substr(0, 4);
                }
                //获取整型部分转换
                if (parseInt(integerNum, 10) > 0) {
                    var zeroCount = 0;
                    var IntLen = integerNum.length;
                    for (var i = 0; i < IntLen; i++) {
                        var n = integerNum.substr(i, 1);
                        var p = IntLen - i - 1;
                        var q = p / 4;
                        var m = p % 4;
                        if (n == '0') {
                            zeroCount++;
                        } else {
                            if (zeroCount > 0) {
                                chineseStr += cnNums[0];
                            }
                            //归零
                            zeroCount = 0;
                            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
                        }
                        if (m == 0 && zeroCount < 4) {
                            chineseStr += cnIntUnits[q];
                        }
                    }
                    chineseStr += cnIntLast;
                }
                //小数部分
                if (decimalNum != '') {
                    var decLen = decimalNum.length;
                    for (var i = 0; i < decLen; i++) {
                        var n = decimalNum.substr(i, 1);
                        if (n != '0') {
                            chineseStr += cnNums[Number(n)] + cnDecUnits[i];
                        }
                    }
                }
                if (chineseStr == '') {
                    chineseStr += cnNums[0] + cnIntLast + cnInteger;
                } else if (decimalNum == '') {
                    chineseStr += cnInteger;
                }
                return chineseStr;
            },



            handleSelectionChange(selection) {
                this.chooseProductData = []
                selection.forEach(item => {
                    let obj = {
                        sn: item.sn,
                        id: item.id,
                        name: item.name,
                        spec: item.spec,
                    }
                    this.chooseProductData.push(obj)
                })
            },


            chooseProDo() {

                this.tempData = this.chooseProductData
                this.is_add_goods = false

            },


            async submitBtn() {
                this.$confirm('确认添加这个配送类型吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    .then(async () => {

                        // 置空
                        this.Form.goods_list = []

                        this.tempData.forEach(item => {
                            this.Form.goods_list.push(item.id)
                        })

                        let params = this.Form
                        const {
                            data
                        } = await this.$https.post(adminApi + '/present/create?_ajax=1', params)



                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)
                        this.$router.go(-1)
                    })
                    .catch(() => {
                        this.$message.info('您取消了按钮')
                    })

            },

            // 删除
            deleteBtn(row) {
                let index = this.tempData.indexOf(row)
                this.tempData.splice(index, 1)
                this.JudgeNodeStatusDelete(row)
            },

            // 删除的时候改变节点状态
            JudgeNodeStatusDelete(row) {
                let rows = []
                this.add_goods_Data.forEach(item => {
                    if (item.id === row.id) {
                        rows.push(item)
                    }
                })
                rows.forEach(item => {
                    this.$refs.multipleTable.toggleRowSelection(item)
                })
            },

        }
    }
</script>

<style lang="css" scoped>
    .el-card {
        position: relative;
    }

    .title_style {
        font-size: 14px;
        font-weight: bolder;
    }

    .backBtn {
        float: right;

    }

    .DistributionSchemeBtns {
        display: flex;
        flex-wrap: wrap;
        background-color: #f5f7fa;
        padding: 20px 20px;
        padding-bottom: 0;
    }

    .DistributionSchemeBtns div:nth-child(1) {
        flex: 8;
    }

    .DistributionSchemeBtns div:nth-child(2) {
        flex: 2;
    }

    .one,
    .two {
        display: flex;
        margin-bottom: 20px;
    }

    .two span,
    .one span {
        display: inline-block;
        width: 30%;
        margin-right: 50px;
    }

    .input {
        width: 60%;
    }

    .submit {
        margin-left: 10%;
        margin-top: 20px;
    }

    .Back {
        display: flex;
        justify-content: end;
        padding-right: 5%;
    }

    .btns-Style {
        margin-bottom: 20px;
        display: flex;
        align-items: flex-end;
    }

    /* el-table 滚动条样式 */
    div /deep/.el-table__body-wrapper::-webkit-scrollbar {
        height: 8px;
        width: 6px t;
    }

    div /deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }

    .sort_order_input {
        width: 18%;
    }

    div /deep/.el-table__header tr,
    div /deep/.el-table__header th {
        padding: 2px;
        /* height: 12px; */
    }
</style>