<template>
    <div id="app">
        <!-- 面包屑导航 -->
        <!-- <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统设置</el-breadcrumb-item>
            <el-breadcrumb-item>客户设置</el-breadcrumb-item>
            <el-breadcrumb-item>客户设置</el-breadcrumb-item>
        </el-breadcrumb> -->

        <el-card>
            <div class="DistributionSchemeBtns">


                <span>
                    客户分组:
                    <el-cascader @change="searchWithCondition" :options="GroupData" clearable v-model="choose_group"
                        :props="{value:'id',label:'name',checkStrictly: true}" size="small">
                    </el-cascader>
                </span>

                <span>
                    配送方案: <el-select @change="searchWithCondition" v-model="getCustomersInfo.plan_id" clearable
                        size="small">
                        <el-option v-for="item in DistributionSchemeData" :key="item.id" :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </span>

                <span>
                    信用等级: <el-select @change="searchWithCondition" v-model="getCustomersInfo.credit_id" clearable
                        size="small">
                        <el-option v-for="item in creditRatingData" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </span>

                <span>
                    折扣: <el-select @change="searchWithCondition" v-model="getCustomersInfo.discount_id" clearable
                        size="small">
                        <el-option v-for="item in discountsData" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </span>

                <span class="last">
                    搜索:
                    <el-input @change="searchWithCondition" v-model="getCustomersInfo.keyword" clearable size="small"
                        placeholder="关键词搜索 支持店名 别名">
                    </el-input>
                </span>

                <div class="btnsDo">
                    <el-button class="frontBtn" size="small" icon="el-icon-search" @click="searchWithCondition">搜索
                    </el-button>
                    <el-button class="reverseBtn" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
                </div>
            </div>

            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="全部" name="first">
                    <el-table :data="CustomersData" border style="width: 100%" :header-cell-style="styleBindFun">
                        <el-table-column prop="number" label="客户编号" align="center" width="230">
                        </el-table-column>
                        <el-table-column label="客户名称" align="center" width="300">
                            <template slot-scope="scope">
                                {{scope.row.name}}
                            </template>
                        </el-table-column>


                        <el-table-column label="是否启用" align="center" width="150">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row.is_enable" @change="enableCustomer(scope.row)">
                                </el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column prop="group_full_name" show-overflow-tooltip label="所属客户组" align="center" width="300">

                        </el-table-column>
                        <el-table-column label="方案" align="center" width="150">
                            <template slot-scope="scope">
                                {{scope.row.plan_name}}

                            </template>
                        </el-table-column>
                        <el-table-column label="信用" align="center" width="150">
                            <template slot-scope="scope">
                                {{scope.row.credit_name}}
                            </template>
                        </el-table-column>

                        <el-table-column label="折扣" align="center" width="150">
                            <template slot-scope="scope">
                                {{scope.row.discount_name}}
                            </template>
                        </el-table-column>

                        <el-table-column label="起订额" align="center" width="150">
                            <template slot-scope="scope">
                                {{scope.row.deliver_name}}
                            </template>
                        </el-table-column>


                        <el-table-column prop="start_time" label="合作开始日期" align="center" width="160">
                        </el-table-column>


                        <el-table-column prop="end_time" label="合作结束日期" align="center" width="160">
                        </el-table-column>


                        <el-table-column prop="update_time" label="更新时间" align="center" width="180">

                        </el-table-column>
                        <el-table-column fixed="right" label="操作" align="center" width="150">
                            <template slot-scope="scope">
                                <el-button @click="edit(scope.row)" type="text" size="medium">修改客户</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="已启用" name="second">
                      <el-table :data="CustomersData" border style="width: 100%" :header-cell-style="styleBindFun">
                        <el-table-column prop="number" label="客户编号" align="center" width="230">
                        </el-table-column>
                        <el-table-column label="客户名称" align="center" width="300">
                            <template slot-scope="scope">
                                {{scope.row.name}}
                            </template>
                        </el-table-column>


                        <el-table-column label="是否启用" align="center" width="150">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row.is_enable" @change="enableCustomer(scope.row)">
                                </el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column prop="group_full_name" show-overflow-tooltip label="所属客户组" align="center" width="300">

                        </el-table-column>
                        <el-table-column label="方案" align="center" width="150">
                            <template slot-scope="scope">
                                {{scope.row.plan_name}}

                            </template>
                        </el-table-column>
                        <el-table-column label="信用" align="center" width="150">
                            <template slot-scope="scope">
                                {{scope.row.credit_name}}
                            </template>
                        </el-table-column>

                        <el-table-column label="折扣" align="center" width="150">
                            <template slot-scope="scope">
                                {{scope.row.discount_name}}
                            </template>
                        </el-table-column>

                        <el-table-column label="起订额" align="center" width="150">
                            <template slot-scope="scope">
                                {{scope.row.deliver_name}}
                            </template>
                        </el-table-column>


                        <el-table-column prop="start_time" label="合作开始日期" align="center" width="160">
                        </el-table-column>


                        <el-table-column prop="end_time" label="合作结束日期" align="center" width="160">
                        </el-table-column>


                        <el-table-column prop="update_time" label="更新时间" align="center" width="180">

                        </el-table-column>
                        <el-table-column fixed="right" label="操作" align="center" width="150">
                            <template slot-scope="scope">
                                <el-button @click="edit(scope.row)" type="text" size="medium">修改客户</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="未启用" name="third">
                     <el-table :data="CustomersData" border style="width: 100%" :header-cell-style="styleBindFun">
                        <el-table-column prop="number" label="客户编号" align="center" width="230">
                        </el-table-column>
                        <el-table-column label="客户名称" align="center" width="300">
                            <template slot-scope="scope">
                                {{scope.row.name}}
                            </template>
                        </el-table-column>


                        <el-table-column label="是否启用" align="center" width="150">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row.is_enable" @change="enableCustomer(scope.row)">
                                </el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column prop="group_full_name" show-overflow-tooltip label="所属客户组" align="center" width="300">

                        </el-table-column>
                        <el-table-column label="方案" align="center" width="150">
                            <template slot-scope="scope">
                                {{scope.row.plan_name}}

                            </template>
                        </el-table-column>
                        <el-table-column label="信用" align="center" width="150">
                            <template slot-scope="scope">
                                {{scope.row.credit_name}}
                            </template>
                        </el-table-column>

                        <el-table-column label="折扣" align="center" width="150">
                            <template slot-scope="scope">
                                {{scope.row.discount_name}}
                            </template>
                        </el-table-column>

                        <el-table-column label="起订额" align="center" width="150">
                            <template slot-scope="scope">
                                {{scope.row.deliver_name}}
                            </template>
                        </el-table-column>


                        <el-table-column prop="start_time" label="合作开始日期" align="center" width="160">
                        </el-table-column>


                        <el-table-column prop="end_time" label="合作结束日期" align="center" width="160">
                        </el-table-column>


                        <el-table-column prop="update_time" label="更新时间" align="center" width="180">

                        </el-table-column>
                        <el-table-column fixed="right" label="操作" align="center" width="150">
                            <template slot-scope="scope">
                                <el-button @click="edit(scope.row)" type="text" size="medium">修改客户</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>

            </el-tabs>


            <!-- 分页器 -->
            <el-pagination @current-change="handleCurrentChangeCustomerSet" :current-page="getCustomersInfo.page"
                :page-sizes="[10]" :page-size="getCustomersInfo.per_page"
                layout="total, sizes, prev, pager, next, jumper" :total="total" align="center">
            </el-pagination>


        </el-card>



        <!-- ---------------------------------------- 对话框第一层 --------------------------------------------------- -->
        <!-- 修改分组对话框 -->
        <el-dialog title="修改分组" :visible.sync="is_Groups_edit" width="35%">

            <el-form :model="Form_createGroups" :rules="GroupsRules" label-width="120px">
                <el-form-item label="分组名称" prop="name">
                    <el-input v-model="Form_createGroups.name"></el-input>
                </el-form-item>

            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="is_Groups_edit = false">取 消</el-button>
                <el-button type="primary" @click="editGroupsDo">确 定</el-button>
            </div>
        </el-dialog>


        <!-- 创建客户组对话框 -->
        <el-dialog title="创建客户组" :visible.sync="is_createGroups" width="30%">

            <el-form :model="Form_createGroups" :rules="GroupsRules" label-width="120px">
                <el-form-item label="客户组名称：" prop="name">
                    <el-input v-model="Form_createGroups.name"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="is_createGroups = false">取 消</el-button>
                <el-button type="primary" @click="createGroupsDo">确 定</el-button>
            </div>
        </el-dialog>


        <!-- 快速启用客户对话框 -->
        <el-dialog title="改变客户状态" :visible.sync="is_enableCustomer" :show-close='false' :close-on-press-escape='false'
            :close-on-click-modal='false' width="30%">

            <el-form class="enableCustomer" width="100%" label-width="120px">
                <el-form-item label="缘由：" prop="name">
                    <el-input type="textarea" v-model="enableCustomer_remarks"></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size='mini' @click="cancelEnable">取 消</el-button>
                <el-button size='mini' class="frontBtn" @click="enableCustomerDo">确 定</el-button>
            </div>
        </el-dialog>


        <!-- 修改客户对话框 -->
        <el-dialog title="修改客户" :visible.sync="is_edit" width="30%">

            <el-form :model="Form_edit" :rules="editRules" label-width="150px">
                <el-form-item label="客户名称" prop="name">
                    <el-input size="small" v-model="Form_edit.name"></el-input>
                </el-form-item>
                <el-form-item label="是否有积分" prop="name">
                    <el-radio-group v-model="Form_edit.is_integral">
                        <el-radio :label="0">否</el-radio>
                        <el-radio :label="1">是</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="备注" prop="remarks">
                    <el-input size="small" v-model="Form_edit.remarks"></el-input>
                </el-form-item>

            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_edit = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" type="primary" @click="editDo">确 定</el-button>
            </div>
        </el-dialog>




    </div>
</template>

<script>
    import {
        adminApi
    } from '../../config'
    import {
        domainApi
    } from '../../config'

    export default {
        data() {
            return {
                // -----------------------获取客户数据--------------------------------------
                CustomersData: [],
                getCustomersInfo: {
                    page: 1,
                    per_page: 10,
                    is_enable: '',
                    keyword: '',
                    group_id: '',
                    plan_id: '',
                    credit_id: '',
                    discount_id: '',
                },
                total: 0,
                enables: [{
                        id: 0,
                        label: '否'
                    },
                    {
                        id: 1,
                        label: '是'
                    },
                ],

                GroupData: [],
                choose_group: '',

                DistributionSchemeData: [],
                creditRatingData: [],
                discountsData: [],

                activeName: 'first',






                // ----------------------创建客户组数据--------------------------------------
                is_createGroups: false,
                Form_createGroups: {
                    name: '',
                },
                GroupsRules: {
                    name: {
                        required: true,
                        message: '请输入折扣名称',
                        trigger: 'blur'
                    }
                },

                // ----------------------编辑信用等级数据--------------------------------------
                is_Groups_edit: false,
                current_edit_id: "",



                // ----------------------快速启用客户数据--------------------------------------
                is_enableCustomer: false,
                enableCustomer_remarks: '',
                currentEnableNumber: '',


                // ----------------------修改客户----------------------------------
                is_edit: false,
                Form_edit: {
                    customer_number: "",
                    name: "",
                    is_integral: 0,
                    remarks: '',
                },
                editRules: {
                    name: {
                        required: true,
                        message: '请输入折扣名称',
                        trigger: 'blur'
                    },
                    integral: {
                        required: true,
                        message: '请选择是否有积分',
                        trigger: 'blur'
                    },
                    remarks: {
                        required: true,
                        message: '请填写备注',
                        trigger: 'blur'
                    }
                },

            }
        },
        created() {
            this.getCustomers();
            this.getGroups();
            this.getDistributionScheme();
            this.getCreditRating();
            this.getDiscounts();
        },
    
        methods: {

           

            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind = "background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                return styleBind;
            },

            handleClick(data) {
                console.log(data.index, 'index');
                if (data.index == 0) {
                    this.activeName = 'first'
                    this.getCustomersInfo.page = 1
                    this.getCustomersInfo.is_enable = ''
                    this.getCustomers()
                }
                if (data.index == 1) {
                    this.activeName = 'second'
                    this.getCustomersInfo.page = 1
                    this.getCustomersInfo.is_enable = 1
                    this.getCustomers()
                }
                if (data.index == 2) {
                    this.activeName = 'third'
                    this.getCustomersInfo.page = 1
                    this.getCustomersInfo.is_enable = 0
                    this.getCustomers()
                }
            },

            // ----------------------搜索与重置--------------------------------------
            searchWithCondition() {
                this.getCustomersInfo.page = 1
                this.getCustomers()
            },
            reset() {
                this.getCustomersInfo.page = 1
                this.getCustomersInfo.keyword = ''
                this.getCustomersInfo.group_id = ''
                this.getCustomersInfo.plan_id = ''
                this.getCustomersInfo.credit_id = ''
                this.getCustomersInfo.discount_id = ''
                this.choose_group = []
                this.getCustomers()
            },

            // -----------------------获取客户组列表数据--------------------------------------
            async getCustomers() {
                if (this.choose_group.length > 0) {
                    this.getCustomersInfo.group_id = this.choose_group[this.choose_group.length - 1]
                } else {
                    this.getCustomersInfo.group_id = ''
                }
                let params = this.getCustomersInfo
                const res = await this.$https.post(adminApi + '/customers?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.CustomersData = res.data.data
                this.total = res.data.total

            },

            handleCurrentChangeCustomerSet(data) {
                this.getCustomersInfo.page = data
                this.getCustomers()
            },
            // -----------------------获取客户组列表数据--------------------------------------
            async getGroups() {
                const res = await this.$https.post(adminApi + '/group/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.GroupData = res.data
                console.log(this.GroupData, 'GroupData');
            },
            // -----------------------获取配送方案数据--------------------------------------
            async getDistributionScheme() {
                const res = await this.$https.post(adminApi + '/plan/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.DistributionSchemeData = res.data
            },
            // -----------------------获取信用等级列表数据--------------------------------------
            async getCreditRating() {
                const res = await this.$https.post(adminApi + '/credit/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.creditRatingData = res.data

            },
            // -----------------------获取信用等级列表数据--------------------------------------
            async getDiscounts() {
                const res = await this.$https.post(adminApi + '/discount/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.discountsData = res.data
            },


            // ----------------------创建客户组数据--------------------------------------
            createGroups() {
                this.is_createGroups = true

                // 清空
                for (let k in this.Form_createGroups) {
                    this.Form_createGroups[k] = ''
                }
            },
            async createGroupsDo() {
                this.$confirm('确认增加这个客户组吗?')
                    .then(async () => {
                        let params = this.Form_createGroups
                        const {
                            data
                        } = await this.$https.post(adminApi + '/group/create?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)
                        this.is_createGroups = false
                        this.getGroups();
                    })
                    .catch(() => {
                        this.$message.info('您取消了按钮')
                    });
            },

            // ----------------------编辑分组数据--------------------------------------
            editGroups(row) {
                this.is_Groups_edit = true
                let {
                    id,
                    name,

                } = row
                this.Form_createGroups.name = name
                this.current_edit_id = id
            },
            async editGroupsDo() {
                this.$confirm('确认修改这个分组吗?')
                    .then(async () => {
                        let params = this.Form_createGroups
                        params.id = this.current_edit_id
                        const {
                            data
                        } = await this.$https.post(adminApi + '/group/edit?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)

                        this.$message.success(data.msg);
                        this.is_Groups_edit = false
                        this.getGroups();
                    })
                    .catch(() => {
                        this.$message.info('您取消了按钮')
                    });
            },


            // ----------------------删除分组数据--------------------------------------
            async deleteGroups(row) {
                this.$confirm('确认删除这个分组吗?')
                    .then(async () => {
                        let params = {
                            id: row.id
                        }
                        const {
                            data
                        } = await this.$https.post(adminApi + '/group/destroy?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)
                        this.getGroups()
                    })
                    .catch(() => {
                        this.$message.info('您取消了按钮')
                    });
            },






            // ----------------------快速启用客户数据--------------------------------------
            enableCustomer(row) {
                this.is_enableCustomer = true
                this.currentEnableNumber = row.number
                this.enableCustomer_remarks = ''
            },
            async enableCustomerDo() {
                let params = {
                    customer_number: this.currentEnableNumber,
                    type: 'enable',
                    remarks: this.enableCustomer_remarks
                }
                const {
                    data
                } = await this.$https.post(adminApi + '/customer/set_status?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.getCustomers();
                this.is_enableCustomer = false
            },
            cancelEnable() {
                this.is_enableCustomer = false
                this.getCustomers()
            },






            // ---------------------修改----------------------------------------
            edit(row) {
                this.is_edit = true
                this.Form_edit.customer_number = row.number
                this.Form_edit.name = row.name
            },

            async editDo() {
                let params = this.Form_edit
                const {
                    data
                } = await this.$https.post(adminApi + '/customer/edit?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_edit = false
                this.getCustomers()

            },
        },
    }
</script>

<style lang="less" scoped>
    .DistributionSchemeBtns {
        // background-color: #dfe9f5;
        background-color: #f5f7fa;
        padding: 15px 20px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        color: #333;
    }

    .DistributionSchemeBtns span {
        width: 25%;
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 10px;
    }

    .DistributionSchemeBtns span .el-input {
        width: 60%;
    }

    .enableCustomer {
        width: 100%;
    }



    /* el-table 滚动条样式 */
    /deep/.el-table__body-wrapper::-webkit-scrollbar {
        height: 8px;
        width: 6px;
    }



    /deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }
</style>