<template>
    <div id="app">
        <!-- 布局 -->
        <div class="purchaseLayout">

            <!-- 面包屑导航 -->
            <!-- <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>库存管理</el-breadcrumb-item>
                <el-breadcrumb-item>采购入库</el-breadcrumb-item>
                <el-breadcrumb-item>添加采购单</el-breadcrumb-item>
            </el-breadcrumb> -->
        </div>

        <el-card class="addPurchaseOrder">

            <div class="addPurchaseOrder__tit">
                采购单:{{edit__PurchaseName__current}}
                <el-button size="small" class="backBtn reverseBtn" @click="goBack">返 回</el-button>
            </div>

            <div class="addPurchaseOrder__remark">
                <span>备注：</span>
                <span>
                    {{edit__PurchaseRemark__current}}
                </span>
            </div>

            <div class="addPurchaseOrder__btn">
                <el-button :disabled="is_addproduct__btnDis" class="frontBtn" icon="el-icon-circle-plus" size="small"
                    @click="addPurChaseProduct">添加产品
                </el-button>
            </div>

            <el-form :model="form_add_purchase" id="add_purchase">
                <el-form-item>
                    <el-table :data="edit__PurchaseProduct__current" style="width:100%"
                        :header-cell-style="styleBindFun" height="510px" @cell-dblclick="changeInputAndShow">
                        <el-table-column prop="sn" label="货号" align="center">
                        </el-table-column>
                        <el-table-column label="产品名称" align="center">
                            <template slot-scope="scope">
                                {{scope.row.product_name}} （{{scope.row.spec}}）
                            </template>
                        </el-table-column>
                        <el-table-column prop="is_batch" label="是否按批次管理" align="center">
                            <template slot-scope="scope">
                                <div v-if="scope.row.is_batch" class="yesIcon el-icon-check"></div>
                                <div v-else class="noIcon el-icon-close"></div>
                            </template>
                        </el-table-column>

                          <el-table-column prop="unit_name" label="单位" align="center">
                        </el-table-column>

                        <el-table-column label="数量" align="center">
                            <template slot-scope="scope">
                                <el-input size="mini" placeholder="请输入内容" v-show="scope.row.showNum"
                                    v-model="scope.row.quantity">
                                </el-input>
                                <span v-show="!scope.row.showNum">{{scope.row.quantity}}</span>
                            </template>
                        </el-table-column>

                      

                        <el-table-column fixed="right" label="操作" width="200" align="center">
                            <template slot-scope="scope">
                                <!-- <el-button @click="deletePurchase__productDo(scope.row)" type="text" :disabled="!is__delete__Btn"
                                    class="text_primary" size="big">删除
                                </el-button> -->
                                <el-button @click="setPurchase__productDo(scope.row)" type="text" 
                                    class="text_primary" size="big">设置
                                </el-button>
                                <!-- <el-button @click="SaveProductNum(scope.row)" type="text" size="big">保存数量
                                </el-button> -->
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
            <div class="containnumber">
                <span class="typeClass"> 产品种类:
                    <span class="Number"> {{ProductType}}</span>
                </span>
                总计划采购量:
                <span class="Number"> {{ProductNumber}}</span>
            </div>

            <el-button v-show="!is__submit__Btn" size="mini" class="define frontBtn" @click="addPurchaseDo">提 交</el-button>











        </el-card>


        <!-- 添加产品对话框 -->
        <el-dialog title="添加产品" :visible.sync="is_add_product" width="62%" class="addProductDialog__Style">
            <div class="addProduct">
                <el-select size="small" v-model="choose_brand" clearable placeholder="选择品牌">
                    <el-option v-for="item in productBrand" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>

                <el-select size="small" v-model="choose_show" clearable placeholder="选择是否上架">
                    <el-option v-for="item in showState" :key="item.id" :label="item.value" :value="item.id">
                    </el-option>
                </el-select>

                <el-select size="small" v-model="choose_qr" clearable placeholder="选择是否生成二维码">
                    <el-option v-for="item in qrState" :key="item.id" :label="item.value" :value="item.id">
                    </el-option>
                </el-select>

                <el-input size="small" class="key_word" placeholder="请输入要查找的内容" v-model="key_word" clearable>

                </el-input>

                <el-button size="small" class="search_addPro frontBtn" icon="el-icon-search"
                    @click="getAddProductShowProduct">搜 索
                </el-button>

            </div>


            <el-table tooltip-effect="dark" style="width: 100%" ref="multipleTable" :data="purchase_add_data"
                :header-cell-style="styleBindFun" @selection-change="handleSelectionChange" :row-key="getRowKeys">
                <el-table-column type="selection" width="80" align="center" :reserve-selection="true">
                </el-table-column>
                <el-table-column prop="brand_name" label="品牌名称" align="center">
                </el-table-column>
                <el-table-column prop="sn" label="货号" align="center">
                </el-table-column>
                <el-table-column prop="name" label="产品名称" align="center" width="350">
                    <template slot-scope="scope">
                        {{scope.row.name}} （ {{scope.row.spec}}）
                    </template>
                </el-table-column>

                <el-table-column prop="is_batch" label="是否按批次管理" align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.is_batch" class="yesIcon el-icon-check"></div>
                        <div v-else class="noIcon el-icon-close"></div>
                    </template>
                </el-table-column>

                <el-table-column prop="unit_name" label="单位" align="center">
                </el-table-column>
                <el-table-column fixed="right" label="操作" align="center" width="200">
                    <template slot-scope="scope">
                        <el-button @click="seePlanProduct(scope.row)" type="text" plain size="mini">查看产品相关信息
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>



            <!-- 分页器 -->
            <el-pagination @size-change="handleSizeChangeAddProduct" @current-change="handleCurrentChangeAddProduct"
                :current-page="form_add_product_showproduct.currentpage" :page-sizes="[5, 8, 20, 50]"
                :page-size="form_add_product_showproduct.pagesize" layout="total, sizes, prev, pager, next, jumper"
                :total="total_addproduct" align="center">
            </el-pagination>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_add_product = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" @click="addPurchaseProductDo">确 定</el-button>
            </div>
        </el-dialog>




        <!-- 查看商品相关信息抽屉对话框 -->
        <el-drawer title="查看产品相关信息" :visible.sync="drawer" size="45%" :direction="direction">
            <div class="drawerTitStyle">{{drawerTitle}}</div>
            <el-collapse accordion>
                <el-collapse-item title="查看计划生产信息" name="1">
                    <el-table tooltip-effect="dark" :header-cell-style="styleBindFun" style="width: 100%"
                        :data="plan_production_info" align="center" stripe border>
                        <el-table-column prop="order_num" label="计划生产单号" align="center">
                        </el-table-column>
                        <el-table-column prop="quantity" label="产品总数" align="center">
                        </el-table-column>
                        <el-table-column prop="receive" label="已到货数" align="center">
                        </el-table-column>
                        <el-table-column prop="create_time" label="创建时间" align="center">
                        </el-table-column>
                        <el-table-column prop="update_time" label="更新时间" align="center">
                        </el-table-column>
                    </el-table>
                </el-collapse-item>
                <el-collapse-item title="查看产品库存" name="2">
                    <el-table tooltip-effect="dark" :header-cell-style="styleBindFun" style="width: 100%"
                        :data="stock_info" align="center" stripe border>
                        <el-table-column prop="store_name" label="仓库名称" align="center">
                        </el-table-column>
                        <el-table-column prop="quantity" label="库存数" align="center">
                        </el-table-column>
                        <el-table-column prop="create_time" label="创建时间" align="center">
                        </el-table-column>
                        <el-table-column prop="update_time" label="更新时间" align="center">
                        </el-table-column>
                    </el-table>
                </el-collapse-item>
                <el-collapse-item title="查看所属商品信息" name="3">
                    <el-table tooltip-effect="dark" :header-cell-style="styleBindFun" style="width: 100%"
                        :data="belong_product_info" align="center" stripe border>
                        <el-table-column prop="store_name" label="商品名" align="center">
                        </el-table-column>
                        <el-table-column prop="price" label="价格" align="center">
                        </el-table-column>
                        <el-table-column prop="pivot.num" label="商品数量" align="center">
                        </el-table-column>
                        <el-table-column prop="update_time" label="更新时间" align="center">
                        </el-table-column>
                        <el-table-column label="是否可售" align="center">
                            <template slot-scope="scope">
                                <div v-if="scope.row.is_sell" class="yesIcon el-icon-check"></div>
                                <div v-else class="noIcon el-icon-close"></div>
                            </template>
                        </el-table-column>
                        <el-table-column label="是否可回收" align="center">
                            <template slot-scope="scope">
                                <div v-if="scope.row.is_return" class="yesIcon el-icon-check"></div>
                                <div v-else class="noIcon el-icon-close"></div>

                            </template>
                        </el-table-column>
                        <el-table-column label="能否一口价" align="center">
                            <template slot-scope="scope">
                                <div v-if="scope.row.is_fixed" class="yesIcon el-icon-check"></div>
                                <div v-else class="noIcon el-icon-close"></div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="remark" label="备注" align="center">
                        </el-table-column>
                    </el-table>
                </el-collapse-item>
            </el-collapse>
        </el-drawer>


          <!-- 添加产品对话框 -->
        <el-dialog title="确定要修改此产品数量吗?" :visible.sync="is_edit_number" width="36%" >
          

          <div class="editNumber--Style">
              数量:
              <el-input class="inputValue" v-model="current_number_value" placeholder="请输入内容" clearable size="small"></el-input>
          </div>
          
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_edit_number = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" @click="editNumberDo">确 定</el-button>
            </div>
        </el-dialog>





    </div>
</template>

<script>
    import {
        erpApi
    } from '../../config'


    export default {
        data() {
            return {

                current_order_num:this.$route.query.order_num,
                edit__PurchaseName__current: '',
                edit__PurchaseRemark__current: '',
                edit__PurchaseProduct__current: [],
                is__submit__Btn: false,
                is__delete__Btn: false,
                is_addproduct__btnDis: false,

                currentPurchase: '',
                // 添加采购单  表单数据
                form_add_purchase: {
                    remarks: '', // 设置备注
                    products: [], //商品数组
                },
                // 添加采购单商品数据

                product_data: [], //商品列表数据
                product_name: '',
                product_num: 1, // 当前商品个数变量
                product_id: '', // 设置当前商品个数的id  
                is_product_num: false, // 控制设置商品个数对话框显示与隐藏
                products: [],
                ProductNumber: 0,
                ProductType: 0,

                // ---------------------------添加产品数据--------------------------------------------
                is_add_product: false,
                testchooseProductData: [], //选择的商品数组 
                chooseProductData: [], //选择的商品数组 
                purchase_add_data: [],
                total_addproduct: 200, // 添加商品时查看商品数据的商品总数

                plan_production_info: [], //查看产品计划生产信息数组
                stock_info: [], //查看库存信息数组
                belong_product_info: [], //查看所属商品信息数组
                plan_total_quantity: '', //查看所属商品计划生产总数
                plan_total_receive: '', //查看所属商品到货总数
                storage_total_quantity: '', //查看所属商品库存总数
                is_product_info: false, //控制查看产品相关信息对话框的显示与隐藏
                // 添加商品时查看商品数据的参数
                form_add_product_showproduct: {
                    currentpage: 1,
                    pagesize: 8,
                    is_plan: 1,
                    is_produce: 1,
                },

                productBrand: [], //品牌数据
                choose_brand: '', //选择的品牌
                showState: [{
                        id: 0,
                        value: '不上架'
                    },
                    {
                        id: 1,
                        value: '上架'
                    }
                ], //是否上架信息数组
                choose_show: '', //选择的上架状态
                qrState: [{
                        id: 0,
                        value: '不生成'
                    },
                    {
                        id: 1,
                        value: '生成'
                    }
                ], //是否生成二维码
                choose_qr: '', //选择的是否生成二维码
                key_word: '', // 搜索词

                // 查看商品相关信息抽屉对话框
                drawer: false,
                direction: 'ltr',
                drawerTitle: "",

                // 修改数量
                is_edit_number:false,
                current_number_value:'',
                current_product_id:'',


            }
        },
        created() {
            this.getEditPurchaseCurrent()
        },
        methods: {

            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind =
                    "background:#f6f8fa;fontSize:14px;color:#333333;fontWeight: bold;height:40px;padding:0px"
                return styleBind;
            },

            // 返回
            goBack() {
                this.$router.go(-1)
            },

            // 获取当前采购单信息
            async getEditPurchaseCurrent() {
                let params = {
                    order_num: this.current_order_num
                }
                let res = await this.$https.post(erpApi + '/plan/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.currentPurchase = res.data
                console.log(this.currentPurchase, 'currentPurchase');
                if(this.$route.query.desc=='deletePurchaseProduct'){
                    this.init__deletePP()
                }else if(this.$route.query.desc=='editPurchaseProductNum'){
                    this.init__editPPN()
                }else if(this.$route.query.desc=='addPurchaseProduct'){
                    this.init__addPP()
                }
            },

            // 初始化 --删除采购单中的产品
            init__deletePP() {
                this.is_addproduct__btnDis = true
                this.is__submit__Btn=true
                this.is__delete__Btn=true
                this.edit__PurchaseName__current = this.currentPurchase.order_num
                this.edit__PurchaseRemark__current = this.$route.query.remarks
                this.edit__PurchaseProduct__current = this.currentPurchase.products
                // this.edit__PurchaseProduct__current.forEach(node => {
                //     node.showNum = false
                // })
                console.log( this.edit__PurchaseProduct__current ,' this.edit__PurchaseProduct__current ');
                this.calcNum__deleteProduct()
            },

            // 初始化 --修改采购产品数量
            init__editPPN() {
                this.is_addproduct__btnDis = true
                this.is__submit__Btn=true
                this.edit__PurchaseName__current = this.currentPurchase.order_num
                this.edit__PurchaseRemark__current = this.$route.query.remarks
                this.edit__PurchaseProduct__current = this.currentPurchase.products
                this.edit__PurchaseProduct__current.forEach(node => {
                    node.showNum = false    
                })
                console.log(this.edit__PurchaseProduct__current,'this.edit__PurchaseProduct__current');
                this.calcNum__deleteProduct()
            },


                // 初始化 --增加采购产品
            init__addPP() {
                this.is_addproduct__btnDis = false
                this.is__submit__Btn=true
                this.edit__PurchaseName__current = this.currentPurchase.order_num
                this.edit__PurchaseRemark__current = this.$route.query.remarks
                this.edit__PurchaseProduct__current = this.currentPurchase.products
                this.edit__PurchaseProduct__current.forEach(node => {
                    node.showNum = false    
                })
                
                console.log(this.edit__PurchaseProduct__current,'this.edit__PurchaseProduct__current');
                this.calcNum__deleteProduct()
            },


            // 12.添加商品方法
            addPurChaseProduct() {
                this.is_add_product = true
                this.getAddProductShowProduct()
                this.getAddProductFilterBrand()
            },



            //  19.添加保存数量方法
            // AddProductNum(row) {
            //     row.showNum = !row.showNum
            //     this.calcNum()
            // },
            // SaveProductNum(row) {
            //     row.showNum = !row.showNum
            //     this.calcNum()
            // },

            // 17.删除采购单添加商品选中项
            // deleteAddProduct(data) {
            //     this.$confirm('您确定删除该商品吗？')
            //         .then(
            //             () => {
            //                 this.product_data.splice(this.product_data.indexOf(data), 1)
            //                 this.$message.success('您删除成功！')
            //                 let sum = 0;
            //                 this.product_data.forEach(item => {

            //                     sum += Number(item.num)
            //                 })

            //                 this.ProductNumber = sum



            //             }
            //         )
            //         .catch(
            //             () => {
            //                 this.$message.error('您取消了按钮')
            //             })
            // },

            async addPurchaseDo() {
                this.products = []
                this.product_data.forEach(item => {
                    let productsObj = {};
                    let {
                        num,
                        id
                    } = item
                    productsObj.product_id = id
                    productsObj.quantity = num
                    this.products.push(productsObj)
                })
                let params = {
                    remarks: this.form_add_purchase.remarks,
                    product_list: this.products
                }
                const {
                    data
                } = await this.$https.post(erpApi + '/plan/create?_ajax=1', params)
                console.log(data, 'res');
                if (data.status !== 'success') return this.$message.error(data.msg)
                this.$message.success('创建采购单成功！')
                this.is_add_purchase = false;

                // 清空
                this.product_data = [];

                this.form_add_purchase.remarks = [];

                this.$router.push({
                    path: '/purchase'
                })

            },


            // 20.确认选中商品数量
            setProductNumDo() {
                var that = this
                this.is_product_num = false
                this.product_data.forEach(item => {
                    if (item.id == that.product_id) {
                        item.num = that.product_num;
                    }
                })

                let sum = 0;
                this.product_data.forEach(item => {

                    sum += Number(item.num)
                })

                this.ProductNumber = sum

            },



            // 15.添加商品时查看商品数据的方法
            async getAddProductShowProduct() {
                let params = {
                    page: this.form_add_product_showproduct.currentpage,
                    per_page: this.form_add_product_showproduct.pagesize,
                    is_plan: 1,
                    is_produce: 1,
                    brand_id: this.choose_brand,
                    is_show: this.choose_show,
                    is_qr: this.choose_qr,
                    keyword: this.key_word,
                }
                if (this.choose_show == '') {
                    delete params.is_show
                }
                if (this.choose_qr == '') {
                    delete params.is_qr
                }
                const res = await this.$https.post(erpApi + '/product/select?_ajax=1', params)
                if (res.status !== 200) return this.$message.error('获取商品数据失败')
                this.purchase_add_data = res.data.data
                this.total_addproduct = res.data.total

                // 数据回显
                this.edit__PurchaseProduct__current.forEach(node=>{
                    this.purchase_add_data.forEach(ele=>{
                        if(ele.id == node.product_id){
                            this.$refs.multipleTable.toggleRowSelection(ele,true)
                        }
                    })
                })

            },


            // 当页面大小发生改变触发的方法
            handleSizeChangeAddProduct(data) {
                this.form_add_product_showproduct.pagesize = data;
                this.getAddProductShowProduct()

            },
            // 当前页发生改变触发的方法
            handleCurrentChangeAddProduct(data) {
                this.form_add_product_showproduct.currentpage = data;
                this.getAddProductShowProduct()
            },


            // 16.添加商品时过滤品牌数据的方法
            async getAddProductFilterBrand() {
                const res = await this.$https.post(erpApi + '/brand/select?_ajax=1')
                if (res.status !== 200) return this.$message.error('获取品牌数据失败')
                this.productBrand = res.data
            },

            // 返回
            goBack() {
                this.$router.go(-1)
            },

            //    13.多选事件
            handleSelectionChange(selection) {
                this.chooseProductData = []
                selection.forEach(item => {
                    let obj = {
                        sn: item.sn,
                        id: item.id,
                        name: item.name,
                        num: item.num,
                        spec: item.spec,
                        unit_name: item.unit_name,
                        is_batch: item.is_batch,
                        showNum: false
                    }
                    this.chooseProductData.push(obj)
                })
            },
            getRowKeys(row) {
                return row.id
            },






            // 21.查看计划生产信息方法
            async seePlanProduct(row) {
                this.drawer = true
                // this.is_product_info = true
                let params = {
                    product_id: row.id
                }
                const res = await this.$https.post(erpApi + '/product/detail?_ajax=1', params)

                if (res.status !== 200) return this.$message.error(res.statusText)
                // 赋值名称
                this.drawerTitle = res.data.name + ' ' + '(' + res.data.spec + ')' // 赋值产品计划生产信息数组
                this.plan_production_info = res.data.plan_details;

                // 赋值库存信息数组
                this.stock_info = res.data.storages;

                // 赋值所属商品信息数组
                this.belong_product_info = res.data.goods

                // 赋值其余参数
                this.plan_total_quantity = res.data.plan_total_quantity
                this.plan_total_receive = res.data.plan_total_receive
                this.storage_total_quantity = res.data.storage_total_quantity
            },

            // 14.确认选择商品
            addPurchaseProductDo() {
                this.is_add_product = false

                console.log(this.chooseProductData,'this.chooseProductData');

                let arr1 = [];
                let arr2 = [];

                this.product_data.forEach(item => {
                    arr2.push(item.id)
                })

                this.chooseProductData.forEach(item => {
                    arr1.push(item.id)
                })

                let data = arr1
                    .filter(x => arr2.indexOf(x) == -1)
                    .concat(arr2.filter(x => arr1.indexOf(x) == -1));

                // 判断用户是增加了产品还是减少了
                // 增加
                if (arr1.length > arr2.length) {
                    for (let i = 0; i < data.length; i++) {
                        this.chooseProductData.forEach(item => {
                            if (item.id == data[i]) {
                                this.product_data.push(item)
                            }
                        })
                    }
                } else {
                    // 减少
                    for (let i = 0; i < data.length; i++) {
                        this.product_data.forEach(item => {
                            if (item.id == data[i]) {
                                this.product_data.splice(this.product_data.indexOf(item), 1)
                            }
                        })
                    }
                }

                console.log(this.product_data,'product_data');

                this.calcNum()
            },

            //双击事件
            changeInputAndShow(row, column, cell, event) {
                console.log(row,'rowwww');
                row.showNum = !row.showNum
                // this.calcNum__deleteProduct()
                // if(row.showNum){
                //     this.editPurchaseProductNumDo(row)
                // }
            },

            // 修改采购产品数量发送请求
            editPurchaseProductNumDo(row){
                console.log(row,'row');
            },

            setPurchase__productDo(row){
                console.log(row,'row');
                this.is_edit_number=true
                this.current_number_value=row.quantity
                this.current_product_id=row.product_id
            },


            // 计算数量
            calcNum() {
                this.ProductNumber = 0
                this.product_data.forEach(item => {
                    this.ProductNumber += +item.num
                })
                this.ProductType = this.product_data.length
            },

            // 计算数量 --删除产品版
            calcNum__deleteProduct() {
                this.ProductNumber = 0
                this.edit__PurchaseProduct__current.forEach(item => {
                    this.ProductNumber += +item.quantity
                })
                this.ProductType = this.edit__PurchaseProduct__current.length
            },


            // ---------------------------------删除采购中的产品------------------------------------
            deletePurchase__productDo(row) {
                this.$confirm('您确定删除该产品吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    .then(async () => {
                       

                        let params = {
                            order_num: this.edit__PurchaseName__current,
                            product_id: row.product_id
                        }

                        const {
                            data
                        } = await this.$https.post(erpApi + '/plan/delete?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)

                         // 删除本地数组
                        let index = this.edit__PurchaseProduct__current.indexOf(row)
                        this.edit__PurchaseProduct__current.splice(index, 1)

                        this.calcNum__deleteProduct()
                    })
                    .catch(() => {
                        this.$message.error('您取消了按钮')
                    })


            },


            // 修改数量
            async editNumberDo(){
                let params={
                    order_num: this.current_order_num,
                    product_id: this.current_product_id,    
                    quantity:this.current_number_value,    
                }
                let {data} = await this.$https.post(erpApi + '/plan/update?_ajax=1', params)
                if (data.status !== 'success') return this.$message.error(data.msg)
                this.$message.success('修改数量成功！')
                this.getEditPurchaseCurrent()
                this.is_edit_number=false
            },







        },
    }
</script>

<style lang="less" scoped>
    .addPurchaseOrder {
        position: relative;
        height: 87vh;
    }

    .back {
        // background-color: #e7e7e7;
        // border-color: #e7e7e7;
        // color: #333;
        position: absolute;
        left: 33%;
    }


    .search_addPro {
        margin-left: 20px;
    }


    .confirmList {
        margin-bottom: 20px;
    }

    #app {
        overflow: hidden;
    }

    h3 {
        font-size: 24px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        margin-bottom: 20px;
    }

    .el-breadcrumb {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .el-card {
        position: relative;
    }

    .purchasechoose {
        margin: 0px 0 30px 0;
        background-color: #dfe9f5;
        padding: 8px 10px;
    }

    .datePicker {
        margin-left: 20px;
    }

    .addpurchase {
        margin-left: 20px;
        margin-right: 10px;
    }



    .remarks {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 150px;
        text-align: center;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    /deep/.el-dialog__body {
        display: flex;
        flex-direction: column;
    }

    .purchaseRemark,
    .chooseWareHouse {
        margin-top: 30px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        font-weight: 550;
        font-size: 18px;
        letter-spacing: 2px;
    }

    .addProduct {
        // background-color: #dfe9f5;
        background-color: #f5f7fa;
        padding: 10px 20px;
        margin-bottom: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .PDProduct {
        justify-content: start
    }

    .addProduct .el-select {
        margin-right: 20px;
    }

    /deep/.el-input .el-input-group {
        width: 280px;
        float: right
    }

    /deep/ .key_word {
        width: 300px;
    }



    .el-tag {
        margin-right: 20px;
        margin-bottom: 15px;
        // padding: 0 10px;
    }

    .setProductNumber {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .icon-yiruku,
    .icon-bufenruku {
        color: #409eff;
        font-size: 26px;
        margin-left: 5px;
    }

    .addProduct .labelName {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
    }

    .goodsCheck {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 500px;
    }

    .goodsCheckLeft {
        flex: 2;
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: #dfe9f5;
    }

    .goodsCheckRight {
        flex: 12;
        display: flex;
        align-items: flex-start;
        height: 100%;
        padding: 0;
        overflow-Y: auto;
        margin-left: 15px;
    }

    .goodsCheckRightContent .el-table {
        margin-top: 0;
    }

    .searchDo {
        margin-left: 20px;
        margin-bottom: 20px;
    }

    .setNumberInput {
        width: 150px;
    }

    .el-form {
        // margin-bottom: -22px !important;
    }

 

    .define {
        margin-left: 8%;
        margin-top: 20px;
    }


    .backBtn {
        position: absolute;
        right: 5%;
    }

    .addPurchaseOrder__tit {
        font-size: 15px;
        color: #333;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight: bolder;
    }

    .addPurchaseOrder__remark {
        display: flex;
        align-items: center;
        margin: 20px 0px;
    }

    .addPurchaseOrder__remark span {
        color: #666;
        font-size: 14px;
    }

    .addPurchaseOrder__remark--input {
        width: 85%;
    }


    .addPurchaseOrder__btn {
        margin-top: 30px;
    }

    .drawerTitStyle {
        text-align: center;
        font-size: 16px;
        // color: #606266;
        font-weight: 500;
        color: #1f6ef0;
    }

    .editNumber--Style{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #666;
    }

    .inputValue{
        width: 30%;
        margin-left: 3%;
    }
</style>