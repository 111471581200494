import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'




Vue.use(Vuex)
  
export default new Vuex.Store({
  //状态值
  state: {
      data:[],
      creditRatingData:[],
      discountData:[],
      DistributionSchemeData:[],
      StaffData:[],
      groupData:[],


  },
  // 修改状态
  mutations: {
    async getDistributionScheme(state){
     
      const res = await axios.post('/admin/plans?_ajax=1')
      // const res = await axios.post('https://api.rainben.cn/admin/plans?_ajax=1')
      console.log(res,'getApo');
      if (res.status !== 200) return this.$message.error(res.statusText)
      state.data = res.data
     
    },

    // 获取信用等级
    async getcreditRating(state){
      const res=await axios.post('/admin/credit/select?_ajax=1')
      state.creditRatingData=res.data
    },

    // 获取折扣选择
    async getDiscount(state){
      const res=await axios.post('/admin/discount/select?_ajax=1')
      state.discountData=res.data
     
    },

    // 获取配送方案选择
    async getDistributionScheme(state){
      const res=await axios.post('/admin/plan/select?_ajax=1')
      state.DistributionSchemeData=res.data
    },


    // 获取员工
    // async getStaff(state){
    //   const res=await axios.post('/erp/user/select?_ajax=1')
    //   state.StaffData=res.data
    //   console.log(state.StaffData,'state.StaffData');
    // },





    

  },
  actions: {
  },
  modules: {
  }
})






