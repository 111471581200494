<template>
    <div id="app">
        <!-- 面包屑导航 -->
        <!-- <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>业务管理</el-breadcrumb-item>
            <el-breadcrumb-item>客户管理</el-breadcrumb-item>

        </el-breadcrumb> -->

        <el-card>
            <!--按钮区 -->
            <div class="DistributionSchemeBtns">

                <div class="first">
                    <span>
                        客户分组:
                        <el-cascader popper-class='sCustomerGroup' clearable size="mini" v-model="checkGroupIds"
                            @change="searchWithCondition" :options="GroupData" :show-all-levels="false"
                            @focus="fnFocusGroup" :props="{value:'id',label:'labelVal',checkStrictly: true}">
                            <template slot-scope="{data}">
                                {{data.name}} <i class="NumberNoLeft fontSize12Style">({{data.customers_count}})</i>
                            </template>


                        </el-cascader>
                    </span>

                    <span>
                        配送方案: <el-select clearable v-model="getCustomersInfo.plan_id" size="mini" @focus="fnFocusPlan"
                            @change="searchWithCondition">
                            <el-option v-for="item in DistributionSchemeData" :key="item.id"
                                :label="`${item.name}(${item.customers_count})`" :value="item.id">
                                {{item.name}} <i class="NumberNoLeft fontSize12Style">({{item.customers_count}})</i>
                            </el-option>
                        </el-select>
                    </span>

                    <span>
                        信用等级: <el-select clearable v-model="getCustomersInfo.credit_id" size="mini"
                            @focus="fnFocusCredit" @change="searchWithCondition">
                            <el-option v-for="item in creditRatingData" :key="item.id"
                                :label="`${item.name}(${item.customers_count})`" :value="item.id">
                                {{item.name}} <i class="NumberNoLeft fontSize12Style">({{item.customers_count}})</i>

                            </el-option>
                        </el-select>
                    </span>
                </div>

                <div class="second">
                    <span>
                        用户折扣: <el-select clearable v-model="getCustomersInfo.discount_id" size="mini"
                            @focus="fnFocusDiscount" @change="searchWithCondition">
                            <el-option v-for="item in discountsData" :key="item.id"
                                :label="`${item.name}(${item.customers_count})`" :value="item.id">
                                {{item.name}} <i class="NumberNoLeft fontSize12Style">({{item.customers_count}})</i>

                            </el-option>
                        </el-select>
                    </span>





                    <span>
                        起订额: <el-select clearable v-model="getCustomersInfo.deliver_id" size="mini"
                            @focus="fnFocusDeliver" @change="searchWithCondition">
                            <el-option v-for="item in deliversData" :key="item.id"
                                :label="`${item.name}(${item.customers_count})`" :value="item.id">
                                {{item.name}} <i class="NumberNoLeft fontSize12Style">({{item.customers_count}})</i>

                            </el-option>
                        </el-select>
                    </span>

                    <span>
                        区域: <el-cascader clearable size="mini" v-model="checkedRegion" @change="searchWithCondition"
                            :options="regionArray" :show-all-levels="true" @focus="getRegion"
                            :props="{value:'id',label:'name',expandTrigger: 'hover',checkStrictly: true}">
                        </el-cascader>
                    </span>
                </div>

                <div class="third">
                    <span>
                        创建时间:
                        <el-date-picker @change="fnChangeTime" v-model="sBeginTime" type="datetime" size="mini"
                            value-format="yyyy-MM-dd HH:mm:ss" placeholder="开始日期">
                        </el-date-picker>

                        <i class="toStyle">至</i>

                        <el-date-picker @change="fnChangeTime" v-model="sEndTime" size="mini" type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束日期">
                        </el-date-picker>

                        <div class="sTimeShortCut">
                            <el-button plain :class="nActiveIndex==0?'dateSwitchBtn':''" size="mini"
                                @click="fnInitDateRange">今</el-button>
                            <el-button plain :class="nActiveIndex==1?'dateSwitchBtn':''" size="mini"
                                @click="fnYesterday">昨</el-button>
                            <el-button plain :class="nActiveIndex==2?'dateSwitchBtn':''" size="mini" @click="fnWeek">近一周
                            </el-button>
                            <el-button plain :class="nActiveIndex==3?'dateSwitchBtn':''" size="mini" @click="fnMonth">本月
                            </el-button>
                            <el-button plain :class="nActiveIndex==4?'dateSwitchBtn':''" size="mini" @click="fnYear">本年
                            </el-button>
                            <el-button plain :class="nActiveIndex==5?'dateSwitchBtn':''" size="mini"
                                @click="fnUnlimited">不限时间</el-button>
                        </div>


                    </span>
                </div>

                <div class="fourth">
                    <span>

                        请输入关键词:
                        <el-input class="sKeyWordStyle" v-model="getCustomersInfo.keyword"
                            placeholder="支持店名 别名 联系人 电话 手机" clearable size="mini" @change="searchWithCondition">
                        </el-input>

                    </span>

                </div>



                <div class="searchStyle">
                    <el-button size="mini" class="frontBtn" icon="el-icon-search" @click="searchWithCondition">搜 索
                    </el-button>

                    <el-button size="mini" class="reverseBtn" icon="el-icon-refresh" @click="resetNewCustomer">重 置
                    </el-button>

                    <el-button size="mini" class="stat2Btn sCreateCustomerBtn" icon="el-icon-circle-plus-outline"
                        @click="addNewCustomer">新建客户
                    </el-button>

                    <!-- <el-button size="mini" class="reverseBtn sRecycleBtn" icon="el-icon-delete" @click="openRecycle">回收站
                    </el-button> -->
                </div>
            </div>



            <!-- <div style="margin-top:35px" v-for="item in CustomersData" :key="item.number">
                <el-descriptions :title="item.name" :column="3">
                    <el-descriptions-item label="状态">
                        <el-tag v-if="item.is_enable" effect="dark" size="small" type="primary">启用</el-tag>
                        <el-tag v-else effect="dark" type="info" size="small">未启用</el-tag>
                    </el-descriptions-item>
                    <el-descriptions-item label="客户编号">{{item.number}}</el-descriptions-item>
                    <el-descriptions-item label="所属分组">{{item.group_name}}</el-descriptions-item>
                    <el-descriptions-item label="信用等级">{{item.credit_name}}</el-descriptions-item>
                    <el-descriptions-item label="折扣">{{item.discount_name}}</el-descriptions-item>
                    <el-descriptions-item label="参加方案">{{item.plan_name}}</el-descriptions-item>

                    <el-descriptions-item label="操作">
                      
                        <el-button type="primary" plain size="mini" @click="lookDetail(item)">查看详情</el-button>
                        <el-button type="primary" plain size="mini" @click="financialManagement(item)">余额管理</el-button>
                        <el-button type="primary" plain size="mini" @click="OrderManagement(item)">订单管理</el-button>
                        <el-button type="primary" plain size="mini" @click="authorityAssignment(item)">客户管理权分配</el-button>
                        <el-button type="danger" plain size="mini" @click="deleteCustomer(item)">删除客户</el-button>
                      
                    </el-descriptions-item>

                </el-descriptions>
                <el-divider></el-divider>
            </div> -->


            <el-tabs v-model="activeName" @tab-click="handleClick" class="tabs_Style">
                <el-tab-pane label="全部客户" name="first">
                    <!-- <table width="100%" border="1" cellspacing="0">
                        <thead class="sCustomerTheadStyle">
                            <tr class="sCustomerHeadStyle">
                                <th>状态</th>
                                <th>客户<span style="color:#fa5a57">（双击查看详情）</span></th>
                                <th>别名</th>
                                <th>类型</th>
                                <th>预存款</th>
                                <th>保证金</th>
                                <th>信用消费</th>
                                <th>余额</th>
                                <th>信用等级</th>
                                <th>折扣</th>
                                <th>参加方案</th>
                                <th>起订额</th>
                                <th>所属分组<span style="color:#fa5a57">（双击查看详情）</span></th>
                                <th>操作</th>
                                

                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(node,index) in CustomersData" :key="index" class="sCustomerItemStyle"
                                @click="fnClickItem(node)"
                                :class="nCustomerListNumber == node.number ? 'sClickItemStyle' : ''">
                                <td align="center">
                                    <el-tag v-if="node.is_enable" size="mini" type="primary">启用</el-tag>
                                    <el-tag v-else type="info" size="mini">未启用</el-tag>
                                </td>
                                <td align="center" @dblclick="lookDetail(node)">
                                    {{node.name}}
                                </td>
                                <td align="center">{{node.alias}}</td>
                                <td align="center">
                                    <span v-if="node.is_single">单店</span>
                                    <span v-else>连锁</span>
                                    </td>
                                <td align="center"><span class="NumberNoLeft">￥{{node.account}}</span></td>
                                <td align="center"> <span class="NumberNoLeft">￥{{node.deposit}}</span></td>
                                <td align="center"><span class="NumberNoLeft">￥{{node.credit_spend}}</span></td>
                                <td align="center"><span class="NumberNoLeft">￥{{node.balance}}</span></td>
                                <td align="center">{{node.credit_name}}</td>
                                <td align="center">{{node.discount_name}}</td>
                                <td align="center">{{node.plan_name}}</td>
                                <td align="center">{{node.deliver_name}}</td>
                                <td align="center" @dblclick="seeGroupbtn(node)">{{node.group_full_name}}</td>
                                <td align="center"><span @click="remove(node)" class="sRemoveStyle">移至回收站</span></td>
                            </tr>
                        </tbody>
                    </table> -->

                    <el-table :data="CustomersData" border style="width: 100%" :header-cell-style="styleBindFun" :cell-class-name="cellClsNm">
                        <el-table-column fixed label="状态" align="center" width="70">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.is_enable" size="mini" type="primary">启用</el-tag>
                                <el-tag v-else type="info" size="mini">未启用</el-tag>
                            </template>
                        </el-table-column>

                        <el-table-column fixed align="center" width="350" >
                            <template slot="header">
                                客户 <span style="color:#fa5a57">（单击查看详情）</span>
                            </template>
                            <template slot-scope="scope">
                                    <span type="text" class="text_customername" @click="lookDetail(scope.row)"
                                    size="big">
                                    {{scope.row.name}}</span>
                                <span class="text_copy_style">
                                    <el-button class="copyNameBtn" type="text"  size="mini" icon="el-icon-document-copy"
                                        @click="fnCopyCustomerName(scope.row.name)">复制
                                    </el-button>
                                </span>
                                

                            </template>
                        </el-table-column>


                        <el-table-column fixed label="别名" align="center" width="350">
                            <template slot-scope="scope">
                                {{scope.row.alias}}
                            </template>
                        </el-table-column>

                        <el-table-column fixed label="类型" align="center" width="70">
                            <template slot-scope="scope">
                                <span v-if="scope.row.is_single">单店</span>
                                <span v-else>连锁</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="预存款" align="center" width="120">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">￥{{scope.row.account}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="保证金" align="center" width="120">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">￥{{scope.row.deposit}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="信用消费" align="center" width="120">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">￥{{scope.row.credit_spend}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="余额" align="center" width="120">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">￥{{scope.row.balance}}</span>
                            </template>
                        </el-table-column>



                        <el-table-column v-for="(node,index) in aPresent" :key="index" :label="node.name" align="center"
                            width="120">
                            <template slot-scope="scope">
                                <span class="sNoActiveStyle" v-if="scope.row.presents.length==0">未激活</span>
                                <span v-else>
                                    <span v-for="item in scope.row.presents" :key="item.id">
                                        <span v-if="item.id == node.id">
                                            <i class="NumberNoLeft">￥{{item.pivot.account}}</i>
                                        </span>
                                        <span v-else class="sNoActiveStyle">
                                            未激活
                                        </span>
                                    </span>
                                </span>

                            </template>
                        </el-table-column>

                        <el-table-column label="信用等级" align="center">
                            <template slot-scope="scope">
                                {{scope.row.credit_name}}
                            </template>
                        </el-table-column>

                        <el-table-column label="折扣" align="center">
                            <template slot-scope="scope">
                                {{scope.row.discount_name}}
                            </template>
                        </el-table-column>

                        <el-table-column label="参加方案" align="center" width="160">
                            <template slot-scope="scope">
                                {{scope.row.plan_name}}
                            </template>
                        </el-table-column>

                        <el-table-column label="起订额" align="center" width="100">
                            <template slot-scope="scope">
                                {{scope.row.deliver_name}}
                            </template>
                        </el-table-column>

                        <el-table-column align="center" width="260">
                            <template slot="header">
                                所属分组 <span style="color:#fa5a57">（点击查看详情）</span>
                            </template>
                            <template slot-scope="scope">

                                <el-button @click="seeGroupbtn(scope.row)" type="text" class="text_customername"
                                    size="big">
                                    {{scope.row.group_full_name}}</el-button>
                            </template>
                        </el-table-column>

                        <el-table-column label="操作" show-overflow-tooltip align="center" width="110">
                            <template slot-scope="scope">
                                <el-button @click="remove(scope.row)" type="text" class="textBtnColor" size="big">
                                    移至回收站</el-button>
                            </template>
                        </el-table-column>

                    </el-table>


                </el-tab-pane>
                <el-tab-pane label="未启用客户" name="second">
                    <el-table :data="CustomersData" border style="width: 100%" :header-cell-style="styleBindFun" :cell-class-name="cellClsNm">
                        <el-table-column fixed label="状态" align="center" width="70">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.is_enable" size="mini" type="primary">启用</el-tag>
                                <el-tag v-else type="info" size="mini">未启用</el-tag>
                            </template>
                        </el-table-column>

                        <el-table-column fixed align="center" width="350" >
                            <template slot="header">
                                客户 <span style="color:#fa5a57">（单击查看详情）</span>
                            </template>
                            <template slot-scope="scope">
                                    <span type="text" class="text_customername" @click="lookDetail(scope.row)"
                                    size="big">
                                    {{scope.row.name}}</span>
                                <span class="text_copy_style">
                                    <el-button class="copyNameBtn" type="text"  size="mini" icon="el-icon-document-copy"
                                        @click="fnCopyCustomerName(scope.row.name)">复制
                                    </el-button>
                                </span>
                                

                            </template>
                        </el-table-column>

                        <el-table-column fixed label="别名" align="center" width="350">
                            <template slot-scope="scope">
                                {{scope.row.alias}}
                            </template>
                        </el-table-column>

                        <el-table-column fixed label="类型" align="center" width="70">
                            <template slot-scope="scope">
                                <span v-if="scope.row.is_single">单店</span>
                                <span v-else>连锁</span>
                            </template>
                        </el-table-column>


                        <el-table-column label="预存款" align="center" width="120">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">￥{{scope.row.account}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="保证金" align="center" width="120">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">￥{{scope.row.deposit}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="信用消费" align="center" width="120">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">￥{{scope.row.credit_spend}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="余额" align="center" width="120">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">￥{{scope.row.balance}}</span>
                            </template>
                        </el-table-column>




                        <el-table-column v-for="(node,index) in aPresent" :key="index" :label="node.name" align="center"
                            width="120">
                            <template slot-scope="scope">
                                <span class="sNoActiveStyle" v-if="scope.row.presents.length==0">未激活</span>
                                <span v-else>
                                    <span v-for="item in scope.row.presents" :key="item.id">
                                        <span v-if="item.id == node.id">
                                            <i class="NumberNoLeft">￥{{item.pivot.account}}</i>
                                        </span>
                                        <span v-else class="sNoActiveStyle">
                                            未激活
                                        </span>
                                    </span>
                                </span>

                            </template>
                        </el-table-column>

                        <el-table-column label="信用等级" align="center">
                            <template slot-scope="scope">
                                {{scope.row.credit_name}}
                            </template>
                        </el-table-column>

                        <el-table-column label="折扣" align="center">
                            <template slot-scope="scope">
                                {{scope.row.discount_name}}
                            </template>
                        </el-table-column>

                        <el-table-column label="参加方案" align="center" width="160">
                            <template slot-scope="scope">
                                {{scope.row.plan_name}}
                            </template>
                        </el-table-column>

                        <el-table-column label="起订额" align="center" width="100">
                            <template slot-scope="scope">
                                {{scope.row.deliver_name}}
                            </template>
                        </el-table-column>

                        <el-table-column align="center" width="260">
                            <template slot="header">
                                所属分组 <span style="color:#fa5a57">（点击查看详情）</span>
                            </template>
                            <template slot-scope="scope">

                                <el-button @click="seeGroupbtn(scope.row)" type="text" class="text_customername"
                                    size="big">
                                    {{scope.row.group_full_name}}</el-button>
                            </template>
                        </el-table-column>

                        <el-table-column label="操作" show-overflow-tooltip align="center" width="110">
                            <template slot-scope="scope">
                                <el-button @click="remove(scope.row)" type="text" class="textBtnColor" size="big">
                                    移至回收站</el-button>
                            </template>
                        </el-table-column>

                    </el-table>

                </el-tab-pane>
                <el-tab-pane label="已启用客户" name="third">
                    <el-table :data="CustomersData" border style="width: 100%" :header-cell-style="styleBindFun" :cell-class-name="cellClsNm">
                        <el-table-column fixed label="状态" align="center" width="70">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.is_enable" size="mini" type="primary">启用</el-tag>
                                <el-tag v-else type="info" size="mini">未启用</el-tag>
                            </template>
                        </el-table-column>

                        <el-table-column fixed align="center" width="350" >
                            <template slot="header">
                                客户 <span style="color:#fa5a57">（单击查看详情）</span>
                            </template>
                            <template slot-scope="scope">
                                    <span type="text" class="text_customername" @click="lookDetail(scope.row)"
                                    size="big">
                                    {{scope.row.name}}</span>
                                <span class="text_copy_style">
                                    <el-button class="copyNameBtn" type="text"  size="mini" icon="el-icon-document-copy"
                                        @click="fnCopyCustomerName(scope.row.name)">复制
                                    </el-button>
                                </span>
                                

                            </template>
                        </el-table-column>


                        <el-table-column fixed label="别名" align="center" width="350">
                            <template slot-scope="scope">
                                {{scope.row.alias}}
                            </template>
                        </el-table-column>

                        <el-table-column fixed label="类型" align="center" width="70">
                            <template slot-scope="scope">
                                <span v-if="scope.row.is_single">单店</span>
                                <span v-else>连锁</span>
                            </template>
                        </el-table-column>



                        <el-table-column label="预存款" align="center" width="120">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">￥{{scope.row.account}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="保证金" align="center" width="120">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">￥{{scope.row.deposit}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="信用消费" align="center" width="120">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">￥{{scope.row.credit_spend}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="余额" align="center" width="120">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">￥{{scope.row.balance}}</span>
                            </template>
                        </el-table-column>



                        <el-table-column v-for="(node,index) in aPresent" :key="index" :label="node.name" align="center"
                            width="120">
                            <template slot-scope="scope">
                                <span class="sNoActiveStyle" v-if="scope.row.presents.length==0">未激活</span>
                                <span v-else>
                                    <span v-for="item in scope.row.presents" :key="item.id">
                                        <span v-if="item.id == node.id">
                                            <i class="NumberNoLeft">￥{{item.pivot.account}}</i>
                                        </span>
                                        <span v-else class="sNoActiveStyle">
                                            未激活
                                        </span>
                                    </span>
                                </span>

                            </template>
                        </el-table-column>

                        <el-table-column label="信用等级" align="center">
                            <template slot-scope="scope">
                                {{scope.row.credit_name}}
                            </template>
                        </el-table-column>

                        <el-table-column label="折扣" align="center">
                            <template slot-scope="scope">
                                {{scope.row.discount_name}}
                            </template>
                        </el-table-column>

                        <el-table-column label="参加方案" align="center" width="160">
                            <template slot-scope="scope">
                                {{scope.row.plan_name}}
                            </template>
                        </el-table-column>

                        <el-table-column label="起订额" align="center" width="100">
                            <template slot-scope="scope">
                                {{scope.row.deliver_name}}
                            </template>
                        </el-table-column>

                        <el-table-column align="center" width="260">
                            <template slot="header">
                                所属分组 <span style="color:#fa5a57">（点击查看详情）</span>
                            </template>
                            <template slot-scope="scope">

                                <el-button @click="seeGroupbtn(scope.row)" type="text" class="text_customername"
                                    size="big">
                                    {{scope.row.group_full_name}}</el-button>
                            </template>
                        </el-table-column>

                        <el-table-column label="操作" show-overflow-tooltip align="center" width="110">
                            <template slot-scope="scope">
                                <el-button @click="remove(scope.row)" type="text" class="textBtnColor" size="big">
                                    移至回收站</el-button>
                            </template>
                        </el-table-column>

                    </el-table>

                </el-tab-pane>

                 <el-tab-pane label="回收站" name="fifth">
                    <el-table :data="CustomersData" border style="width: 100%" :header-cell-style="styleBindFun" :cell-class-name="cellClsNm">
                        <el-table-column fixed label="状态" align="center" width="70">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.is_enable" size="mini" type="primary">启用</el-tag>
                                <el-tag v-else type="info" size="mini">未启用</el-tag>
                            </template>
                        </el-table-column>

                        <el-table-column fixed align="center" width="350" >
                            <template slot="header">
                                客户 <span style="color:#fa5a57">（单击查看详情）</span>
                            </template>
                            <template slot-scope="scope">
                                    <span type="text" class="text_customername" @click="lookDetail(scope.row)"
                                    size="big">
                                    {{scope.row.name}}</span>
                                <span class="text_copy_style">
                                    <el-button class="copyNameBtn" type="text"  size="mini" icon="el-icon-document-copy"
                                        @click="fnCopyCustomerName(scope.row.name)">复制
                                    </el-button>
                                </span>
                                

                            </template>
                        </el-table-column>


                        <el-table-column fixed label="别名" align="center" width="350">
                            <template slot-scope="scope">
                                {{scope.row.alias}}
                            </template>
                        </el-table-column>

                        <el-table-column fixed label="类型" align="center" width="70">
                            <template slot-scope="scope">
                                <span v-if="scope.row.is_single">单店</span>
                                <span v-else>连锁</span>
                            </template>
                        </el-table-column>



                        <el-table-column label="预存款" align="center" width="120">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">￥{{scope.row.account}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="保证金" align="center" width="120">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">￥{{scope.row.deposit}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="信用消费" align="center" width="120">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">￥{{scope.row.credit_spend}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="余额" align="center" width="120">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">￥{{scope.row.balance}}</span>
                            </template>
                        </el-table-column>



                        <el-table-column v-for="(node,index) in aPresent" :key="index" :label="node.name" align="center"
                            width="120">
                            <template slot-scope="scope">
                                <span class="sNoActiveStyle" v-if="scope.row.presents.length==0">未激活</span>
                                <span v-else>
                                    <span v-for="item in scope.row.presents" :key="item.id">
                                        <span v-if="item.id == node.id">
                                            <i class="NumberNoLeft">￥{{item.pivot.account}}</i>
                                        </span>
                                        <span v-else class="sNoActiveStyle">
                                            未激活
                                        </span>
                                    </span>
                                </span>

                            </template>
                        </el-table-column>

                        <el-table-column label="信用等级" align="center">
                            <template slot-scope="scope">
                                {{scope.row.credit_name}}
                            </template>
                        </el-table-column>

                        <el-table-column label="折扣" align="center">
                            <template slot-scope="scope">
                                {{scope.row.discount_name}}
                            </template>
                        </el-table-column>

                        <el-table-column label="参加方案" align="center" width="160">
                            <template slot-scope="scope">
                                {{scope.row.plan_name}}
                            </template>
                        </el-table-column>

                        <el-table-column label="起订额" align="center" width="100">
                            <template slot-scope="scope">
                                {{scope.row.deliver_name}}
                            </template>
                        </el-table-column>

                        <el-table-column align="center" width="260">
                            <template slot="header">
                                所属分组 <span style="color:#fa5a57">（点击查看详情）</span>
                            </template>
                            <template slot-scope="scope">

                                <el-button @click="seeGroupbtn(scope.row)" type="text" class="text_customername"
                                    size="big">
                                    {{scope.row.group_full_name}}</el-button>
                            </template>
                        </el-table-column>

                        <el-table-column label="操作" show-overflow-tooltip align="center" width="110">
                            <template slot-scope="scope">
                                <!-- <el-button @click="remove(scope.row)" type="text" class="textBtnColor" size="big">
                                    移至回收站</el-button> -->
                                 <el-button @click="restoreCustomer(scope.row)" type="text" class="textBtnColor" size="big">
                                    移出</el-button>

                            </template>
                        </el-table-column>

                    </el-table>

                </el-tab-pane>
                <el-tab-pane label="客户快照" name="fourth">
                    <div>
                        <el-button class="frontBtn createSnapshot--style" size="mini" @click="createSnapshot">创建客户信息快照
                        </el-button>
                    </div>
                    <el-table :data="snapshotsData" max-height="auto" style="width:100%" border height="490"
                        :header-cell-style="styleBindFun">
                        <el-table-column label="快照名称" align="center">
                            <template slot-scope="scope">
                                {{scope.row.name}}
                            </template>
                        </el-table-column>

                        <el-table-column label="操作者" align="center">
                            <template slot-scope="scope">
                                {{scope.row.user_name}}
                            </template>
                        </el-table-column>



                        <el-table-column fixed="right" label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button @click="lookSnapshotDetail(scope.row)" type="text" class="text_primary"
                                    size="big">
                                    查看详情</el-button>
                                <el-button @click="downloadSnapshot(scope.row)" type="text" class="text_primary"
                                    size="big">
                                    下载</el-button>
                            </template>
                        </el-table-column>

                    </el-table>

                </el-tab-pane>
            </el-tabs>


            <!-- 分页器 -->
            <el-pagination @current-change="handleCurrentChangeCustomerManage" :current-page="getCustomersInfo.page"
                :page-sizes="[8]" :page-size="getCustomersInfo.per_page"
                layout="total, sizes, prev, pager, next, jumper" :total="customersTotal" align="center">
            </el-pagination>

        </el-card>









        <!-- ---------------------------------------- 对话框第一层 --------------------------------------------------- -->
        <!-- 查看客户配送对话框 -->
        <el-dialog title="客户配送信息" :visible.sync="is_CustomersData_Presents" width="60%">


            <el-table :data="lookPresentsData" border style="width: 100%">
                <el-table-column prop="name" label="配送名称" align="center">
                </el-table-column>
                <el-table-column prop="amount" label="价格" align="center">
                </el-table-column>
                <el-table-column prop="tel" label="联系方式" align="center">
                </el-table-column>
                <el-table-column label="类型余额是否支持转出" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.is_transfer" style="color:#67c23a">是</span>
                        <span v-else style="color:#f56c6c">否</span>
                    </template>
                </el-table-column>
                <el-table-column label="是否遵守起订金额限制" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.is_deliver_limit" style="color:#67c23a">是</span>
                        <span v-else style="color:#f56c6c">否</span>
                    </template>
                </el-table-column>
                <el-table-column prop="account" label="消费(单位: 元)" align="center">
                </el-table-column>
            </el-table>


            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="is_CustomersData_Presents = false">确 定</el-button>
            </div>
        </el-dialog>










        <!-- 修改分组对话框 -->
        <el-dialog title="修改分组" :visible.sync="is_Groups_edit" width="35%">

            <el-form :model="Form_createGroups" :rules="GroupsRules" label-width="120px">
                <el-form-item label="分组名称" prop="name">
                    <el-input v-model="Form_createGroups.name"></el-input>
                </el-form-item>

            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="is_Groups_edit = false">取 消</el-button>
                <el-button type="primary" @click="editGroupsDo">确 定</el-button>
            </div>
        </el-dialog>


        <!-- 创建客户组对话框 -->
        <el-dialog title="创建客户组" :visible.sync="is_createGroups" width="30%">

            <el-form :model="Form_createGroups" :rules="GroupsRules" label-width="120px">
                <el-form-item label="客户组名称：" prop="name">
                    <el-input v-model="Form_createGroups.name"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="is_createGroups = false">取 消</el-button>
                <el-button type="primary" @click="createGroupsDo">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 快照详情对话框 -->
        <el-dialog title="快照详情" :visible.sync="is_snapshotDetail" width="75%">
            <el-table :data="snapshotsDetailData" border style="width: 100%" :header-cell-style="styleBindFun">
                <el-table-column prop="customer_number" label="客户编号" align="center" width="220">
                </el-table-column>
                <el-table-column prop="customer_name" label="客户名称" align="center">
                </el-table-column>
                <el-table-column prop="account" label="预存款余额(元)" align="center">
                </el-table-column>
                <el-table-column prop="deposit" label="保证金(元)" align="center">
                </el-table-column>
                <el-table-column prop="credit_spend" label="信用支出(元)" align="center">
                </el-table-column>
                <el-table-column prop="balance" label="余额(元)" align="center">
                </el-table-column>
                <el-table-column prop="group_full_name" label="客户组" align="center" width="200">
                </el-table-column>
                <el-table-column label="配送" align="center">
                    <template slot-scope="scope">
                        <el-dropdown v-show="scope.row.presents.length>0">
                            <span class="el-dropdown-link">
                                查看配送<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-for="list  in scope.row.presents" :key="list.present_id">
                                    {{list.present_name}} <span class="validityDateStyle">配送金额：<span
                                            class="NumberNoLeft">{{list.present_account}}</span></span>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>

            <div slot="footer" class="dialog-footer">

                <el-button size="mini" class="frontBtn" @click="is_snapshotDetail = false">确 定</el-button>
            </div>
        </el-dialog>


        <!--查看客户组的员工权限对话框 -->
        <el-dialog title="查看客户组的员工权限" :visible.sync="is_seeGroupbtn" width="50%" class="seeGroupsdiaplog_style">
            <div class="seeGroupsTit">{{seeGroup_tit}}</div>
            <el-table :data="seeGroup_users" border style="width: 100%" :header-cell-style="styleBindFun">
                <el-table-column prop="name" label="员工" align="center" width="220">
                </el-table-column>
                <el-table-column prop="department_full_name" label="部门" align="center">
                </el-table-column>
                <el-table-column prop="position_name" label="职位" align="center">
                </el-table-column>
            </el-table>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" class="frontBtn" @click="is_seeGroupbtn = false">确 定</el-button>
            </div>
        </el-dialog>






        <!-- -----------------------------------Drawer 抽屉（一层）------------------------------------------- -->
        <el-drawer title="客户列表回收站" :visible.sync="drawer" :direction="direction">
            <el-table :data="CustomersRecycle" stripe border style="width: 100%" :header-cell-style="styleBindFun">
                <el-table-column prop="number" label="客户编号" align="center" width="260">
                </el-table-column>
                <el-table-column prop="name" label="名称" align="center">
                </el-table-column>
                <el-table-column fixed="right" label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button @click="restoreCustomer(scope.row)" type="text" class="text_primary" size="big">
                            恢复
                        </el-button>
                        <el-button @click="deleteCustomer(scope.row)" type="text" class="text_delete" size="big">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-drawer>
    </div>
</template>

<script>
    import {
        erpApi
    } from '../../config'

    import {
        domainApi
    } from '../../config'

    export default {
        data() {
            return {
                // -----------------------获取客户列表数据--------------------------------------
                CustomersData: [],
                getCustomersInfo: {
                    type: '',
                    page: 1,
                    per_page: 8,
                    is_enable: '',
                    keyword: '',
                    group_id: '',
                    plan_id: '',
                    credit_id: '',
                    discount_id: '',
                    deliver_id: '',
                    start_time: '',
                    end_time: '',
                    region_id: '',
                },

                backUp: this.$route.query.backUp,
                customersTotal: 0,


                GroupData: [],


                DistributionSchemeData: [],

                creditRatingData: [],

                discountsData: [],

                deliversData: [],

                searchInput: '',

                // ----------------------回收站数据--------------------------------------
                drawer: false,
                direction: 'rtl',
                CustomersRecycle: [],




                // -----------------------查看客户配送数据--------------------------------------
                is_CustomersData_Presents: false,
                lookPresentsData: [],





                // ----------------------创建客户组数据--------------------------------------
                is_createGroups: false,
                Form_createGroups: {
                    name: '',
                },
                GroupsRules: {
                    name: {
                        required: true,
                        message: '请输入折扣名称',
                        trigger: 'blur'
                    }
                },

                // ----------------------编辑信用等级数据--------------------------------------
                is_Groups_edit: false,
                current_edit_id: "",
                // ----------------------创建客户组数据--------------------------------------
                is_createReceivingAddress: false,
                RegionData: [],
                choosedRegion: [],
                Form_Region: {
                    customer_number: '',
                    region_id: '',
                    address: '',
                    remarks: '',
                    name: '',
                    mobile: ''
                },


                // --------------------------详细数组----------------------------------
                CustomerDetailData: [],


                activeName: 'first',

                // 快照
                snapshotsData: [],
                is_snapshotDetail: false,
                snapshotsDetailData: [],

                // 查看客户组的员工权限
                is_seeGroupbtn: false,

                seeGroup_tit: '',
                seeGroup_users: [],

                dateArea: [],
                regionArray: [],
                checkedRegion: [],
                checkGroupIds: [],


                // 客户列表点击项
                nCustomerListNumber: '',


                bSmallScreen: false,

                sBeginTime: '',
                sEndTime: '',

                nActiveIndex: '',

                aPresent: [],





            }
        },
        created() {

            this.getPresent() // 获取配送
            // this.getGroups()   //获取客户组列表数据
            // this.getDistributionScheme()  //获取配送方案数据
            // this.getCreditRating()  //获取信用等级列表数据
            // this.getDiscounts()  //获取折扣数据
            // this.getDelivers() //获取起订额数据
            // this.getRegion()
            this.fnUnlimited()
        },

        mounted() {
            window.onresize = () => {
                let width = document.body.clientWidth
                if (width < 1550) {
                    this.bSmallScreen = true
                } else {
                    this.bSmallScreen = false
                }
            }
        },

        methods: {

            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind = "background:#f2f3f5;fontSize:14px;color:#666;fontWeight: normal;"
                return styleBind;
            },

            // 获取配送
            async getPresent() {
                const res = await this.$https.post(erpApi + '/present/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.aPresent = res.data
            },


            // 获取区域
            async getRegion() {
                const res = await this.$https.get(erpApi + '/region/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.regionArray = res.data
            },

            // 初始化当天日期范围
            fnInitDateRange() {
                let date = new Date();
                let year = date.getFullYear();
                let month = Number(date.getMonth() + 1);
                month = month >= 10 ? month : '0' + month
                let day = Number(date.getDate());
                day = day >= 10 ? day : '0' + day

                // 当天
                let sIntraDayBegin = year + '-' + month + '-' + day + ' ' + '00:00:00'

                let sIntraDayEnd = year + '-' + month + '-' + day + ' ' + '23:59:59'

                this.sBeginTime = sIntraDayBegin
                this.sEndTime = sIntraDayEnd

                this.getCustomersInfo.start_time = this.sBeginTime
                this.getCustomersInfo.end_time = this.sEndTime
                this.searchWithCondition()

                this.nActiveIndex = 0

            },

            fnYesterday() {
                let date = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24);
                let year = date.getFullYear();
                let month = Number(date.getMonth() + 1);
                month = month >= 10 ? month : '0' + month
                let day = Number(date.getDate());
                day = day >= 10 ? day : '0' + day

                // 昨天
                let Begin = year + '-' + month + '-' + day + ' ' + '00:00:00'

                let End = year + '-' + month + '-' + day + ' ' + '23:59:59'

                this.sBeginTime = Begin
                this.sEndTime = End

                this.getCustomersInfo.start_time = this.sBeginTime
                this.getCustomersInfo.end_time = this.sEndTime
                this.searchWithCondition()

                this.nActiveIndex = 1
            },

            fnWeek() {
                let date = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                let year = date.getFullYear();
                let month = Number(date.getMonth() + 1);
                month = month >= 10 ? month : '0' + month
                let day = Number(date.getDate());
                day = day >= 10 ? day : '0' + day


                let date2 = new Date();
                let year2 = date2.getFullYear();
                let month2 = Number(date2.getMonth() + 1);
                month2 = month2 >= 10 ? month2 : '0' + month2
                let day2 = Number(date2.getDate());
                day2 = day2 >= 10 ? day2 : '0' + day2

                // 近一周
                let Begin = year + '-' + month + '-' + day + ' ' + '00:00:00'

                let End = year2 + '-' + month2 + '-' + day2 + ' ' + '23:59:59'

                this.sBeginTime = Begin
                this.sEndTime = End

                this.getCustomersInfo.start_time = this.sBeginTime
                this.getCustomersInfo.end_time = this.sEndTime
                this.searchWithCondition()

                this.nActiveIndex = 2
            },

            fnMonth() {
                let date = new Date();
                let year = date.getFullYear();
                let month = Number(date.getMonth() + 1);
                month = month >= 10 ? month : '0' + month
                let day = Number(date.getDate());
                day = day >= 10 ? day : '0' + day


                let dayBegin = "01"
                // 本月
                let Begin = year + '-' + month + '-' + dayBegin + ' ' + '00:00:00'

                let End = year + '-' + month + '-' + day + ' ' + '23:59:59'

                this.sBeginTime = Begin
                this.sEndTime = End

                this.getCustomersInfo.start_time = this.sBeginTime
                this.getCustomersInfo.end_time = this.sEndTime
                this.searchWithCondition()

                this.nActiveIndex = 3

            },

            fnYear() {
                let date = new Date();
                let year = date.getFullYear();
                let month = Number(date.getMonth() + 1);
                month = month >= 10 ? month : '0' + month
                let day = Number(date.getDate());
                day = day >= 10 ? day : '0' + day


                let dayBegin = "01"
                let monthBegin = "01"
                // 本年
                let Begin = year + '-' + monthBegin + '-' + dayBegin + ' ' + '00:00:00'

                let End = year + '-' + month + '-' + day + ' ' + '23:59:59'

                this.sBeginTime = Begin
                this.sEndTime = End

                this.getCustomersInfo.start_time = this.sBeginTime
                this.getCustomersInfo.end_time = this.sEndTime
                this.searchWithCondition()

                this.nActiveIndex = 4

            },

            fnUnlimited() {
                this.sBeginTime = ''
                this.sEndTime = ''
                this.getCustomersInfo.start_time = this.sBeginTime
                this.getCustomersInfo.end_time = this.sEndTime
                this.searchWithCondition()

                this.nActiveIndex = 5
            },



            searchWithCondition() {
                this.getCustomersInfo.start_time = this.sBeginTime
                this.getCustomersInfo.end_time = this.sEndTime

                if (this.sBeginTime == null) {
                    this.getCustomersInfo.start_time = ''
                }

                if (this.sEndTime == null) {
                    this.getCustomersInfo.end_time = ''
                }

                if (this.sBeginTime == null && this.sEndTime !== null) {
                    return this.$message.error('请把时间选择完整')
                }

                if (this.sBeginTime !== null && this.sEndTime == null) {
                    return this.$message.error('请把时间选择完整')
                }

                if (this.sBeginTime == '' && this.sEndTime !== '') {
                    return this.$message.error('请把时间选择完整')
                }

                if (this.sBeginTime !== '' && this.sEndTime == '') {
                    return this.$message.error('请把时间选择完整')
                }




                this.getCustomersInfo.page = 1
                this.getCustomerList()
            },



            fnFocusGroup() {
                let params = {
                    plan_id: this.getCustomersInfo.plan_id,
                    credit_id: this.getCustomersInfo.credit_id,
                    discount_id: this.getCustomersInfo.discount_id,
                    deliver_id: this.getCustomersInfo.deliver_id,
                }
                this.getGroups(params)
            },

            fnFocusPlan() {
                if (this.checkGroupIds.length > 0) {
                    this.getCustomersInfo.group_id = this.checkGroupIds[this.checkGroupIds.length - 1]
                } else {
                    this.getCustomersInfo.group_id = ''
                }

                let params = {
                    group_id: this.getCustomersInfo.group_id,
                    credit_id: this.getCustomersInfo.credit_id,
                    discount_id: this.getCustomersInfo.discount_id,
                    deliver_id: this.getCustomersInfo.deliver_id,
                }
                this.getDistributionScheme(params)
            },

            fnFocusCredit() {
                if (this.checkGroupIds.length > 0) {
                    this.getCustomersInfo.group_id = this.checkGroupIds[this.checkGroupIds.length - 1]
                } else {
                    this.getCustomersInfo.group_id = ''
                }
                let params = {
                    group_id: this.getCustomersInfo.group_id,
                    plan_id: this.getCustomersInfo.plan_id,
                    discount_id: this.getCustomersInfo.discount_id,
                    deliver_id: this.getCustomersInfo.deliver_id,
                }
                this.getCreditRating(params)
            },

            fnFocusDiscount() {
                if (this.checkGroupIds.length > 0) {
                    this.getCustomersInfo.group_id = this.checkGroupIds[this.checkGroupIds.length - 1]
                } else {
                    this.getCustomersInfo.group_id = ''
                }
                let params = {
                    group_id: this.getCustomersInfo.group_id,
                    plan_id: this.getCustomersInfo.plan_id,
                    credit_id: this.getCustomersInfo.credit_id,
                    deliver_id: this.getCustomersInfo.deliver_id,
                }
                this.getDiscounts(params)
            },

            fnFocusDeliver() {
                if (this.checkGroupIds.length > 0) {
                    this.getCustomersInfo.group_id = this.checkGroupIds[this.checkGroupIds.length - 1]
                } else {
                    this.getCustomersInfo.group_id = ''
                }
                let params = {
                    group_id: this.getCustomersInfo.group_id,
                    plan_id: this.getCustomersInfo.plan_id,
                    credit_id: this.getCustomersInfo.credit_id,
                    discount_id: this.getCustomersInfo.discount_id,
                }
                this.getDelivers(params)
            },


            fnChangeTime() {
                this.nActiveIndex = ''
            },

            // -----------------------获取客户列表数据--------------------------------------
            async getCustomerList() {
                // if (this.dateArea !== null && this.dateArea.length > 0) {
                //     this.getCustomersInfo.start_time = this.dateArea[0]
                //     this.getCustomersInfo.end_time = this.dateArea[1]
                // } else {
                //     this.getCustomersInfo.start_time = ''
                //     this.getCustomersInfo.end_time = ''
                // }

                if (this.checkedRegion.length > 0) {
                    this.getCustomersInfo.region_id = this.checkedRegion[this.checkedRegion.length - 1]
                } else {
                    this.getCustomersInfo.region_id = ''
                }


                if (this.checkGroupIds.length > 0) {
                    this.getCustomersInfo.group_id = this.checkGroupIds[this.checkGroupIds.length - 1]
                } else {
                    this.getCustomersInfo.group_id = ''
                }



                let params = this.getCustomersInfo

                const res = await this.$https.post(erpApi + '/customers?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.CustomersData = res.data.data
                this.customersTotal = res.data.total

            },
            handleSizeChangeCustomerManage(data) {
                this.getCustomersInfo.per_page = data
                this.getCustomerList()
            },
            handleCurrentChangeCustomerManage(data) {
                this.getCustomersInfo.page = data
                this.getCustomerList()
            },


            // -----------------------获取客户组列表数据--------------------------------------
            async getGroups(params) {
                const res = await this.$https.post(erpApi + '/group/select?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.GroupData = res.data
                this.recursionGroups(this.GroupData)
            },

            recursionGroups(data) {
                data.forEach(node => {
                    let value = node.name + '(' + node.customers_count + ')'
                    node['labelVal'] = value

                    if (node.customers_count == 0) {
                        node['disabled'] = true
                    }

                    if (node.children) {
                        this.recursionGroups(node.children)
                    }
                })
            },

            // -----------------------获取配送方案数据--------------------------------------
            async getDistributionScheme(params) {
                const res = await this.$https.post(erpApi + '/plan/select?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.DistributionSchemeData = res.data
            },
            // -----------------------获取信用等级列表数据--------------------------------------
            async getCreditRating(params) {
                const res = await this.$https.post(erpApi + '/credit/select?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.creditRatingData = res.data
            },
            // -----------------------获取折扣数据--------------------------------------
            async getDiscounts(params) {
                const res = await this.$https.post(erpApi + '/discount/select?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.discountsData = res.data

            },

            // -----------------------获取起订额数据--------------------------------------
            async getDelivers(params) {
                const res = await this.$https.post(erpApi + '/deliver/select?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.deliversData = res.data
            },



            // -----------------------查看客户配送数据--------------------------------------
            lookPresents(row) {
                this.is_CustomersData_Presents = true
                this.CustomersData.forEach(item => {
                    if (item.id === row.id) {
                        this.lookPresentsData = item.presents
                    }
                })
            },

            async openRecycle() {
                this.drawer = true
                this.getCustomersInfo.type = 'onlytrash'
                let params = this.getCustomersInfo
                const res = await this.$https.post(erpApi + '/customers?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.CustomersRecycle = res.data.data
                this.getCustomersInfo.type = ''
                this.getCustomerList()
            },



            // -----------------------查看客户详情数据--------------------------------------
            async lookDetail(row) {

                let routeData = this.$router.resolve({
                    path: '/customerDetail',
                    query: {
                        data: row.number,

                    }
                })
                window.open(routeData.href, '_blank')
            },

            // ---------------------------1.获取初始化方法-----------------------------------
            async getDetailData(row) {
                let parmas = {
                    customer_number: row.number
                }
                const res = await this.$https.post(erpApi + '/customer/detail?_ajax=1', parmas)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.CustomerDetailData = res.data
            },


            // ----------------------创建客户组数据--------------------------------------
            createGroups() {
                this.is_createGroups = true

                // 清空
                for (let k in this.Form_createGroups) {
                    this.Form_createGroups[k] = ''
                }
            },
            async createGroupsDo() {
                this.$confirm('确认增加这个客户组吗?')
                    .then(async () => {
                        let params = this.Form_createGroups
                        const {
                            data
                        } = await this.$https.post('/admin/group/create?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)
                        this.is_createGroups = false
                        this.getGroups();
                    })
                    .catch(() => {
                        this.$message.info('您取消了按钮')
                    });
            },

            // ----------------------编辑分组数据--------------------------------------
            editGroups(row) {
                this.is_Groups_edit = true
                let {
                    id,
                    name,

                } = row
                this.Form_createGroups.name = name
                this.current_edit_id = id
            },
            async editGroupsDo() {
                this.$confirm('确认修改这个分组吗?')
                    .then(async () => {
                        let params = this.Form_createGroups
                        params.id = this.current_edit_id
                        const {
                            data
                        } = await this.$https.post('/admin/group/edit?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)

                        this.$message.success(data.msg);
                        this.is_Groups_edit = false
                        this.getGroups();
                    })
                    .catch(() => {
                        this.$message.info('您取消了按钮')
                    });
            },


            // ----------------------删除分组数据--------------------------------------
            async deleteGroups(row) {
                this.$confirm('确认删除这个分组吗?')
                    .then(async () => {
                        let params = {
                            id: row.id
                        }
                        const {
                            data
                        } = await this.$https.post('/admin/group/destroy?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)
                        this.getGroups()
                    })
                    .catch(() => {
                        this.$message.info('您取消了按钮')
                    });
            },




            // ----------------------新建方法------------------------------------------------
            addNewCustomer() {
                this.$router.push({
                    path: '/addNewCustomer'
                })
            },


            // --------------------------客户快照管理方法--------------------------------------------
            SnapshotMana() {
                this.$router.push({
                    path: '/SnapshotManagement'
                })
            },






            // tabs切换的方法
            handleClick(data) {

                if (data.index == '0') {
                    this.getCustomersInfo.is_enable = ''
                this.getCustomersInfo.type = ''
                    this.getCustomerList()
                }
                if (data.index == '1') {
                    this.getCustomersInfo.is_enable = 0
                this.getCustomersInfo.type = ''
                    this.getCustomerList()
                }
                if (data.index == '2') {
                    this.getCustomersInfo.is_enable = 1
                this.getCustomersInfo.type = ''
                    this.getCustomerList()
                }
                if (data.index == '3') {
                    this.getCustomersInfo.is_enable = ''
                    this.getCustomersInfo.type = 'onlytrash'
                    this.getCustomerList()
                }
                 if (data.index == '4') {
                    this.getSnapshotsData()
                }
            },

            // 客户快照
            // 1.获取快照
            async getSnapshotsData() {
                const res = await this.$https.post(erpApi + '/customer/snapshots?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.snapshotsData = res.data
            },

            // 创建快照
            async createSnapshot() {
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/snapshot/create?_ajax=1')
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.getSnapshotsData()
            },

            //    快照详情
            async lookSnapshotDetail(row) {
                this.is_snapshotDetail = true
                let params = {
                    snapshot_id: row.id
                }
                const res = await this.$https.post(erpApi + '/customer/snapshot/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.snapshotsDetailData = res.data.detail
            },

            // 下载快照
            downloadSnapshot(row) {
                window.location.href = erpApi + "/customer/snapshot/down?_ajax=1&snapshot_id=" + row.id
            },

            // 移至回收站
            async remove(row) {
                let params = {
                    customer_number: row.number
                }
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/destroy?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.getCustomerList()
            },

            // 恢复
            async restoreCustomer(row) {
                let params = {
                    customer_number: row.number
                }
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/restore?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.openRecycle()
            },

            // 删除
            deleteCustomer(row) {
                this.$confirm('您确定删除该客户吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    .then(async () => {
                        let params = {
                            customer_number: row.number,
                            type: 'delete'
                        }
                        const {
                            data
                        } = await this.$https.post(erpApi + '/customer/destroy?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)
                        this.openRecycle()


                    })
                    .catch(() => {
                        this.$message.error('您取消了按钮')
                    })
            },

            // 查看客户组的员工权限
            async seeGroupbtn(row) {
                this.is_seeGroupbtn = true
                let params = {
                    group_id: row.group_id
                }
                const res = await this.$https.post(erpApi + '/customer/group_detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.seeGroup_tit = res.data.full_name
                this.seeGroup_users = res.data.users
            },








            resetNewCustomer() {
                this.getCustomersInfo.keyword = ''
                this.getCustomersInfo.group_id = ''
                this.getCustomersInfo.plan_id = ''
                this.getCustomersInfo.credit_id = ''
                this.getCustomersInfo.discount_id = ''
                this.getCustomersInfo.deliver_id = ''

                this.checkedRegion = []
                this.checkGroupIds = []
                this.fnUnlimited()
            },



            fnClickItem(node) {
                this.nCustomerListNumber = node.number
            },

            fnCopyCustomerName(data){
                var input = document.createElement("input"); // 创建input对象
                input.value = data; // 设置复制内容
                document.body.appendChild(input); // 添加临时实例
                input.select(); // 选择实例内容
                document.execCommand("Copy"); // 执行复制
                document.body.removeChild(input); // 删除临时实例
                this.$message.success('复制成功！   客户名称：'+data);
            },


            cellClsNm(data){
                if(data.columnIndex == '1'){
                    return 'sCustomerNameCell'
                }
            }
























        },
    }
</script>

<style lang="less" scoped>
    div /deep/.el-card__body {
        padding-top: 10px;
    }

    .DistributionSchemeBtns {
        padding: 10px 20px;
        padding-bottom: 0px;

        background-color: #f5f7fa;
        border-radius: 5px;
        position: relative;
        padding-bottom: 10px;
    }

    .el-descriptions {
        width: 75%;
    }

    .DistributionSchemeBtns .first,
    .DistributionSchemeBtns .fourth,
    .DistributionSchemeBtns .second {
        display: flex;
    }


    .DistributionSchemeBtns .first span,
    .DistributionSchemeBtns .fourth span,
    .DistributionSchemeBtns .second span {

        color: #333;
        font-size: 14px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        margin-right: 50px;
    }

    .DistributionSchemeBtns .third span {

        color: #333;
        font-size: 14px;
        margin-bottom: 10px;
        margin-right: 50px;
        display: flex;
        align-items: center;

    }

    .DistributionSchemeBtns .fourth span {
        width: 100%;
    }

    .sKeyWordStyle {
        width: 30%;
    }









    // .ReceivingAddress {
    //     position: relative;
    // }

    // .ReceivingAddress .el-button {
    //     position: absolute;
    //     top: 10%;
    //     right: 7%;
    //     background-color: #e2231a;
    //     border: none;
    // }

    .users .el-descriptions-item__content {
        width: 100%;
    }

    .users,
    .ReceivingAddress,
    .remarks,
    .ReturnVisitRecord,
    .StoreManagement,
    .logs {
        width: 100%;
        box-sizing: border-box;
    }

    .users .title,
    .StoreManagement .title,
    .remarks .title,
    .ReceivingAddress .title,
    .ReturnVisitRecord .title,
    .logs .title {
        color: #303133;
        font-size: 16px;
        font-family: 'microsoft yahei';
        font-weight: 550;
        // background-color: pink;
        display: flex;
        align-items: center;
    }

    .ReceivingAddress .title {
        color: #303133;
        font-size: 16px;
        font-family: 'microsoft yahei';
        font-weight: 550;
    }

    .ReceivingAddress .content {
        display: flex;
        flex-wrap: wrap;
        // padding-left: 10%;
    }

    .ReceivingAddress .content .item {
        width: 20%;
        padding: 0 20px;
        margin-top: 20px;
        margin-right: 10%;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        // background-color:#f7f8fa;
        position: relative;
    }

    .ReceivingAddress .content .item div {
        margin: 2px 0;
    }

    .ReceivingAddress .content .item div:nth-child(1) {
        margin: 5px 0;
    }

    .ReceivingAddress .content .item .tit {
        color: #000000D9;
        font-size: 15px;
        font-weight: 550;
    }

    .ReceivingAddress .delete {
        position: absolute;
        right: 10%;
        bottom: 3%;
    }

    .addReceving {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: none;
        background-color: #fafafa;
    }

    .addReturnVisit {
        background-color: #0052d9;
        border-color: #0052d9;
        color: #ffffff;
    }

    .addReturnVisit:hover {
        background-color: #176eff;
        border-color: #176eff;
        color: #ffffff;
    }



    .addReturnVisit:focus {
        background-color: #024cd2;
        border-color: #024cd2;
        color: #ffffff;
    }

    .create {
        position: absolute;
        right: 1%;
        bottom: 20px;
    }

    .reset {
        position: absolute;
        right: 10px;
        bottom: 20px;
    }

    .Recycle {
        position: absolute;
        right: 10%;
        bottom: 20px;
    }

    .CustomersData--style {
        margin-top: 30px;
    }

    .createSnapshot--style {
        margin-left: 5px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .tabs_Style {
        margin-top: 10px;
        margin-left: 10px;
    }

    .seeGroupsTit {
        color: #333;
        font-size: 18px;
        font-weight: bolder;
        text-align: left;
        margin: 20px 0 10px 0;
    }

    .seeGroupBtn--Style {
        margin-left: 10px;
    }

    /deep/.seeGroupsdiaplog_style .el-dialog__body {
        display: flex;
        flex-direction: column;
        padding-top: 0;
    }



    /* el-table 滚动条样式 */
    div /deep/.el-table__body-wrapper::-webkit-scrollbar {
        height: 8px;
        width: 6px;
    }



    div /deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }

    div /deep/.el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
        background-color: #a8a8a8;
    }



    .searchStyle {
        width: 100%;
        position: relative;
    }

    .sRecycleBtn {
        position: absolute;
        top: 0;
        right: 0;

    }

    .sCreateCustomerBtn {
        position: absolute;
        top: 0;
        left: 15%;
    }

    .el-table {
        margin-top: 0px;
    }

    .sNoActiveStyle {
        font-size: 12px;
    }

    

   

    
</style>