<template>
    <div id="app">
        <!-- 面包屑导航 -->
        <!-- <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>客户设置</el-breadcrumb-item>
            <el-breadcrumb-item>客户订单起订设置</el-breadcrumb-item>
        </el-breadcrumb> -->


        <el-card>

            <!--按钮区 -->

            <div class="DistributionSchemeBtns">
                <el-button @click="createCustomerMOQ" icon="el-icon-plus" class="frontBtn" size="small">创建订单起订
                </el-button>
            </div>

            <el-table :data="customerMOQData" border style="width: 100%" :header-cell-style="styleBindFun">
                <el-table-column prop="name" label="名称" align="center">
                </el-table-column>
                <el-table-column prop="amount" label="金额" align="center">
                    <template slot-scope="scope">
                        ￥{{scope.row.amount}}
                    </template>
                </el-table-column>
                <el-table-column prop="customers_count" label="顾客人数" align="center">
                </el-table-column>
                <el-table-column prop="update_time" label="更新时间" align="center">
                    <template slot-scope="scope">
                        {{scope.row.update_time.trim().split(" ")[0]}}
                    </template>
                </el-table-column>

                <el-table-column fixed="right" label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button @click="editcustomerMOQ(scope.row)" type="text" size="medium">修改</el-button>
                        <span class="splitLineClass"></span>
                        <el-button @click="deletecustomerMOQ(scope.row)" type="text" class="deleteTextBtn"
                            size="medium">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>



        <!-- ---------------------------------------- 对话框第一层 --------------------------------------------------- -->
        <!-- 编辑配送方案详情对话框 -->
        <el-dialog title="修改起订方案" :visible.sync="is_customerMOQ_edit" width="24%">

            <el-form :model="Form_createCustomerMOQ" :rules="customerMOQRules" label-width="120px">
                <el-form-item label="起订方案名称" prop="name">
                    <el-input v-model="Form_createCustomerMOQ.name"></el-input>
                </el-form-item>
                <el-form-item label="金额" prop="amount">
                    <el-input v-model="Form_createCustomerMOQ.amount"></el-input>
                </el-form-item>
                <el-form-item label="排序：">
                    <el-input v-model="Form_createCustomerMOQ.sort_order" placeholder="默认100，范围100~200"></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_customerMOQ_edit = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" @click="editcustomerMOQDo">确 定</el-button>
            </div>
        </el-dialog>


        <!-- 创建起订方案详情对话框 -->
        <el-dialog title="创建起订" :visible.sync="is_createCustomerMOQ" width="28%">

            <el-form :model="Form_createCustomerMOQ" :rules="customerMOQRules" label-width="100px">
                <el-form-item label="起订名称：" prop="name">
                    <el-input v-model="Form_createCustomerMOQ.name"></el-input>
                </el-form-item>
                <el-form-item label="包邮金额：" prop="amount">
                    <el-input v-model="Form_createCustomerMOQ.amount"></el-input>
                </el-form-item>
                <el-form-item label="排序：">
                    <el-input v-model="Form_createCustomerMOQ.sort_order" placeholder="默认100，范围100~200"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_createCustomerMOQ = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" type="primary" @click="createCustomerMOQDo">确 定</el-button>
            </div>
        </el-dialog>


    </div>
</template>

<script>
    import {
        adminApi
    } from '../../config'

    import {
    domainApi
  } from '../../config'
    export default {
        data() {
            return {
                // -----------------------获取配送方案数据--------------------------------------
                customerMOQData: [],

                // ----------------------查看配送方案详情数据--------------------------------------
                DistributionSchemeDetailData: [],
                is_DistributionScheme_detail: false,

                // ----------------------创建配送方案详情数据--------------------------------------
                is_createCustomerMOQ: false,
                Form_createCustomerMOQ: {
                    name: '',
                    amount: '',
                    sort_order: '',
                },
                customerMOQRules: {
                    name: {
                        required: true,
                        message: '请输入起订名称',
                        trigger: 'blur'
                    },
                    amount: {
                        required: true,
                        message: '请输入金额',
                        trigger: 'blur'
                    },
                },

                // ----------------------编辑配送方案详情数据--------------------------------------
                is_customerMOQ_edit: false,
                current_edit_id: "",

            }
        },
        created() {
            this.getcustomerMOQ();
        },
       
        methods: {
          
            // 绑定表格头部样式方法
            styleBindFun() {
                //   let  styleBind="background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                let styleBind = "background:#f6f8fa;fontSize:14px;color:#333333;fontWeight: bold;"
                return styleBind;
            },

            // -----------------------获取起订方案数据--------------------------------------
            async getcustomerMOQ() {
                // const res = await this.$https.post('/admin/deliver?_ajax=1')
                const res = await this.$https.post(adminApi + '/delivers?_ajax=1')

                if (res.status !== 200) return this.$message.error(res.statusText)
                this.customerMOQData = res.data
            },


            // ----------------------创建起订量详情数据--------------------------------------
            createCustomerMOQ() {
                this.is_createCustomerMOQ = true

                // 清空
                for (let k in this.Form_createCustomerMOQ) {
                    this.Form_createCustomerMOQ[k] = ''
                }
            },
            async createCustomerMOQDo() {
                this.$confirm('确认增加这个起订量吗?')
                    .then(async () => {
                        let params = this.Form_createCustomerMOQ
                        const {
                            data
                        } = await this.$https.post(adminApi + '/deliver/create?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)
                        this.is_createCustomerMOQ = false
                        this.getcustomerMOQ();
                    })
                    .catch(() => {
                        this.$message.ingo('您取消了按钮')
                    });
            },

            // ----------------------编辑配送方案详情数据--------------------------------------
            editcustomerMOQ(row) {
                console.log(row, 'rowq');
                this.is_customerMOQ_edit = true
                let {
                    id,
                    name,
                    amount,
                    sort_order
                } = row
                this.Form_createCustomerMOQ.name = name
                this.Form_createCustomerMOQ.amount = amount
                this.Form_createCustomerMOQ.sort_order = sort_order

                this.current_edit_id = id
            },
            async editcustomerMOQDo() {
                this.$confirm('确认修改这个起订量吗?')
                    .then(async () => {
                        let params = this.Form_createCustomerMOQ
                        params.id = this.current_edit_id
                        const {
                            data
                        } = await this.$https.post(adminApi + '/deliver/edit?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg);
                        this.is_customerMOQ_edit = false
                        this.getcustomerMOQ();
                    })
                    .catch(() => {
                        this.$message.ingo('您取消了按钮')
                    });
            },


            // ----------------------删除起订方案详情数据--------------------------------------
            async deletecustomerMOQ(row) {
                this.$confirm('确认删除这个起订方案吗?')
                    .then(async () => {
                        let params = {
                            id: row.id
                        }
                        const {
                            data
                        } = await this.$https.post(adminApi + '/deliver/delete?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)
                        this.getcustomerMOQ()
                    })
                    .catch(() => {
                        this.$message.ingo('您取消了按钮')
                    });
            },
        },
    }
</script>

<style lang="less" scoped>
    .DistributionSchemeBtns {
        // background-color: #dfe9f5;
        padding: 6px 20px;
        margin-bottom: 20px;
    }
</style>