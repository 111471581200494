<template>
    <div id="app">
        <!-- 面包屑导航 -->
        <!-- <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>业务管理</el-breadcrumb-item>
            <el-breadcrumb-item>客户管理</el-breadcrumb-item>
            <el-breadcrumb-item>客户财务管理</el-breadcrumb-item>
        </el-breadcrumb> -->


        <el-card class="RechargeDeduction">


            <div class="searchBtns">
                <div class="top">

                    <span>操作类型：
                        <el-select v-model="getRechargeDeductionInfo.finance_type_id" clearable placeholder="请选择"
                            size="mini" @change="seacrhWithCondition">
                            <el-option v-for="item in FinanceData" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </span>

                    <span>财务类型：
                        <el-select v-model="getRechargeDeductionInfo.present_id" clearable placeholder="请选择"
                            @change="seacrhWithCondition" size="mini">
                            <el-option v-for="item in PresentData" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </span>



                </div>

                <div class="middle">
                    <!-- <span>日期：
                    <el-date-picker @change="seacrhWithCondition" v-model="dataArea" type="datetimerange" size="small"
                        value-format="yyyy-MM-dd hh:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </span> -->
                    <span>

                        出单时间:
                        <el-date-picker @change="fnChangeTime" v-model="sBeginTime" type="datetime" size="mini"
                            value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间">
                        </el-date-picker>

                        <i class="toStyle">至</i>

                        <el-date-picker @change="fnChangeTime" v-model="sEndTime" size="mini" type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束时间">
                        </el-date-picker>

                        <span class="sTimeShortCut">
                            <el-button plain :class="nActiveIndex==0?'dateSwitchBtn':''" size="mini"
                                @click="fnInitDateRange">今</el-button>
                            <el-button plain :class="nActiveIndex==1?'dateSwitchBtn':''" size="mini"
                                @click="fnYesterday">昨</el-button>
                            <el-button plain :class="nActiveIndex==2?'dateSwitchBtn':''" size="mini" @click="fnWeek">近一周
                            </el-button>
                            <el-button plain :class="nActiveIndex==3?'dateSwitchBtn':''" size="mini" @click="fnMonth">本月
                            </el-button>
                            <el-button plain :class="nActiveIndex==4?'dateSwitchBtn':''" size="mini" @click="fnYear">本年
                            </el-button>
                            <el-button plain :class="nActiveIndex==5?'dateSwitchBtn':''" size="mini"
                                @click="fnUnlimited">不限时间</el-button>

                        </span>

                    </span>




                </div>

                <div class="bottom">

                    <span>
                        <el-button size="mini" icon="el-icon-search" class="frontBtn" @click="seacrhWithCondition">搜 索
                        </el-button>

                        <el-button size="mini" icon="el-icon-refresh" class="reverseBtn" @click="reset">重 置</el-button>

                    </span>

                    <span class="downloadBillStyle">

                        <el-button icon="el-icon-download" class="stat2Btn " size="mini" @click="downloadBill">下载对账单
                        </el-button>

                    </span>


                </div>


            </div>



        </el-card>

        <el-card class="cardTwo">

            <!-- <div class="scalculateStyle">
                <el-button size="mini" class="frontBtn" @click="fnCalculate">计算总金额</el-button>
                <div >
                    <span>总金额: {{nTotalMoney}}</span>
                </div>
            </div> -->

            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="全部" name="first">
                    <el-table :data="yueKouKuanData" border style="width: 100%" :header-cell-style="styleBindFun">
                        <el-table-column label="状态" align="center" width="130">
                            <template slot-scope="scope">
                                {{scope.row.status_name }}
                            </template>
                        </el-table-column>

                        <el-table-column label="财务单号" align="center" width="220">
                            <template slot-scope="scope">
                                <el-button @click="seeDetail(scope.row)" type="text" size="small" class="text_primary">
                                    {{scope.row.order_num}}
                                </el-button>
                            </template>
                        </el-table-column>


                        <el-table-column label="操作类型" align="center" width="120">
                            <template slot-scope="scope">
                                {{scope.row.finance_type_name}}
                                <i v-show="scope.row.finance_type_name=='扣款'"
                                    class="el-icon-upload2 cutPaymentStyle"></i>
                                <i v-show="scope.row.finance_type_name=='预存'"
                                    class="el-icon-download preStoreStyle"></i>
                                <i v-show="scope.row.finance_type_name=='系统生成'"
                                    class="el-icon-s-operation systemGenerateStyle"></i>
                            </template>
                        </el-table-column>

                        <el-table-column label="财务类型" align="center" width="120">
                            <template slot-scope="scope">
                                {{scope.row.present_type_name}}
                            </template>
                        </el-table-column>

                        <el-table-column align="center" width="350">
                            <template slot="header">
                                客户 <span style="color:#fa5a57">（点击查看详情）</span>
                            </template>
                            <template slot-scope="scope">

                                <el-button @click="lookDetail(scope.row)" type="text" class="text_customername"
                                    size="big">
                                    {{scope.row.customer_name}}</el-button>
                            </template>
                        </el-table-column>

                        <el-table-column label="别名" align="center" width="300">
                            <template slot-scope="scope">
                                {{scope.row.customer_alias}}
                            </template>
                        </el-table-column>

                        <el-table-column label="金额" align="center" width="120">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">￥{{scope.row.amount_format}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="创建时间" align="center" width="180">
                            <template slot-scope="scope">
                                {{scope.row.create_time}}
                            </template>
                        </el-table-column>

                        <el-table-column label="配送依赖" align="center">
                            <template slot-scope="scope">
                                <div v-if="scope.row.is_can_present" class="yesIcon el-icon-check"></div>
                                <div v-else class="noIcon el-icon-close"></div>
                            </template>
                        </el-table-column>

                        <el-table-column label="申请人" show-overflow-tooltip align="center" width="120">
                            <template slot-scope="scope">
                                {{scope.row.user_name}}
                            </template>
                        </el-table-column>

                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="待审核" name="second">
                    <el-table :data="yueKouKuanData" border style="width: 100%" :header-cell-style="styleBindFun">
                        <el-table-column label="状态" align="center" width="130">
                            <template slot-scope="scope">
                                {{scope.row.status_name }}
                            </template>
                        </el-table-column>

                        <el-table-column label="财务单号" align="center" width="220">
                            <template slot-scope="scope">
                                <el-button @click="seeDetail(scope.row)" type="text" size="small" class="text_primary">
                                    {{scope.row.order_num}}
                                </el-button>
                            </template>
                        </el-table-column>


                        <el-table-column label="操作类型" align="center" width="120">
                            <template slot-scope="scope">
                                {{scope.row.finance_type_name}}
                                <i v-show="scope.row.finance_type_name=='扣款'"
                                    class="el-icon-upload2 cutPaymentStyle"></i>
                                <i v-show="scope.row.finance_type_name=='预存'"
                                    class="el-icon-download preStoreStyle"></i>
                                <i v-show="scope.row.finance_type_name=='系统生成'"
                                    class="el-icon-s-operation systemGenerateStyle"></i>
                            </template>
                        </el-table-column>

                        <el-table-column label="财务类型" align="center" width="120">
                            <template slot-scope="scope">
                                {{scope.row.present_type_name}}
                            </template>
                        </el-table-column>

                        <el-table-column align="center" width="350">
                            <template slot="header">
                                客户 <span style="color:#fa5a57">（点击查看详情）</span>
                            </template>
                            <template slot-scope="scope">
                                <el-button @click="lookDetail(scope.row)" type="text" class="text_customername"
                                    size="big">
                                    {{scope.row.customer_name}}</el-button>
                            </template>
                        </el-table-column>

                        <el-table-column label="别名" align="center" width="300">
                            <template slot-scope="scope">
                                {{scope.row.customer_alias}}
                            </template>
                        </el-table-column>

                        <el-table-column label="金额" align="center" width="120">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">￥{{scope.row.amount_format}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="创建时间" align="center" width="180">
                            <template slot-scope="scope">
                                {{scope.row.create_time}}
                            </template>
                        </el-table-column>

                        <el-table-column label="配送依赖" align="center">
                            <template slot-scope="scope">
                                <div v-if="scope.row.is_can_present" class="yesIcon el-icon-check"></div>
                                <div v-else class="noIcon el-icon-close"></div>
                            </template>
                        </el-table-column>

                        <el-table-column label="申请人" show-overflow-tooltip align="center" width="120">
                            <template slot-scope="scope">
                                {{scope.row.user_name}}
                            </template>
                        </el-table-column>







                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="已审核待确认" name="third">
                    <el-table :data="yueKouKuanData" border style="width: 100%" :header-cell-style="styleBindFun">
                        <el-table-column label="状态" align="center" width="130">
                            <template slot-scope="scope">
                                {{scope.row.status_name }}
                            </template>
                        </el-table-column>

                        <el-table-column label="财务单号" align="center" width="220">
                            <template slot-scope="scope">
                                <el-button @click="seeDetail(scope.row)" type="text" size="small" class="text_primary">
                                    {{scope.row.order_num}}
                                </el-button>
                            </template>
                        </el-table-column>


                        <el-table-column label="操作类型" align="center" width="120">
                            <template slot-scope="scope">
                                {{scope.row.finance_type_name}}
                                <i v-show="scope.row.finance_type_name=='扣款'"
                                    class="el-icon-upload2 cutPaymentStyle"></i>
                                <i v-show="scope.row.finance_type_name=='预存'"
                                    class="el-icon-download preStoreStyle"></i>
                                <i v-show="scope.row.finance_type_name=='系统生成'"
                                    class="el-icon-s-operation systemGenerateStyle"></i>
                            </template>
                        </el-table-column>

                        <el-table-column label="财务类型" align="center" width="120">
                            <template slot-scope="scope">
                                {{scope.row.present_type_name}}
                            </template>
                        </el-table-column>

                        <el-table-column align="center" width="350">
                            <template slot="header">
                                客户 <span style="color:#fa5a57">（点击查看详情）</span>
                            </template>
                            <template slot-scope="scope">

                                <el-button @click="lookDetail(scope.row)" type="text" class="text_customername"
                                    size="big">
                                    {{scope.row.customer_name}}</el-button>
                            </template>
                        </el-table-column>

                        <el-table-column label="别名" align="center" width="300">
                            <template slot-scope="scope">
                                {{scope.row.customer_alias}}
                            </template>
                        </el-table-column>

                        <el-table-column label="金额" align="center" width="120">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">￥{{scope.row.amount_format}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="创建时间" align="center" width="180">
                            <template slot-scope="scope">
                                {{scope.row.create_time}}
                            </template>
                        </el-table-column>

                        <el-table-column label="配送依赖" align="center">
                            <template slot-scope="scope">
                                <div v-if="scope.row.is_can_present" class="yesIcon el-icon-check"></div>
                                <div v-else class="noIcon el-icon-close"></div>
                            </template>
                        </el-table-column>

                        <el-table-column label="申请人" show-overflow-tooltip align="center" width="120">
                            <template slot-scope="scope">
                                {{scope.row.user_name}}
                            </template>
                        </el-table-column>







                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="已审核已确认" name="fourth">
                    <el-table :data="yueKouKuanData" border style="width: 100%" :header-cell-style="styleBindFun">
                        <el-table-column label="状态" align="center" width="130">
                            <template slot-scope="scope">
                                {{scope.row.status_name }}
                            </template>
                        </el-table-column>

                        <el-table-column label="财务单号" align="center" width="220">
                            <template slot-scope="scope">
                                <el-button @click="seeDetail(scope.row)" type="text" size="small" class="text_primary">
                                    {{scope.row.order_num}}
                                </el-button>
                            </template>
                        </el-table-column>


                        <el-table-column label="操作类型" align="center" width="120">
                            <template slot-scope="scope">
                                {{scope.row.finance_type_name}}
                                <i v-show="scope.row.finance_type_name=='扣款'"
                                    class="el-icon-upload2 cutPaymentStyle"></i>
                                <i v-show="scope.row.finance_type_name=='预存'"
                                    class="el-icon-download preStoreStyle"></i>
                                <i v-show="scope.row.finance_type_name=='系统生成'"
                                    class="el-icon-s-operation systemGenerateStyle"></i>

                            </template>
                        </el-table-column>

                        <el-table-column label="财务类型" align="center" width="120">
                            <template slot-scope="scope">
                                {{scope.row.present_type_name}}
                            </template>
                        </el-table-column>

                        <el-table-column align="center" width="350">
                            <template slot="header">
                                客户 <span style="color:#fa5a57">（点击查看详情）</span>
                            </template>
                            <template slot-scope="scope">

                                <el-button @click="lookDetail(scope.row)" type="text" class="text_customername"
                                    size="big">
                                    {{scope.row.customer_name}}</el-button>
                            </template>
                        </el-table-column>

                        <el-table-column label="别名" align="center" width="300">
                            <template slot-scope="scope">
                                {{scope.row.customer_alias}}
                            </template>
                        </el-table-column>

                        <el-table-column label="金额" align="center" width="120">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">￥{{scope.row.amount_format}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="创建时间" align="center" width="180">
                            <template slot-scope="scope">
                                {{scope.row.create_time}}
                            </template>
                        </el-table-column>

                        <el-table-column label="配送依赖" align="center">
                            <template slot-scope="scope">
                                <div v-if="scope.row.is_can_present" class="yesIcon el-icon-check"></div>
                                <div v-else class="noIcon el-icon-close"></div>
                            </template>
                        </el-table-column>

                        <el-table-column label="申请人" show-overflow-tooltip align="center" width="120">
                            <template slot-scope="scope">
                                {{scope.row.user_name}}
                            </template>
                        </el-table-column>







                    </el-table>
                </el-tab-pane>
            </el-tabs>




            <!-- 分页器 -->
            <el-pagination @current-change="handleCurrentChangeCZKK" :current-page="getRechargeDeductionInfo.page"
                :page-size="getRechargeDeductionInfo.per_page" layout="total, prev, pager, next, jumper"
                :total="yueKouKuanTotal" align="center">
            </el-pagination>



        </el-card>

        <!-- 客户预存款冻结为保证金状态对话框 -->
        <el-dialog title="预存款冻结为保证金" :visible.sync="is_frozen" width="30%">

            <el-form :model="Form_frozen" label-width="120px">
                <el-form-item label="冻结金额:" required>
                    <el-input size="small" placeholder="请按照0.00格式输入" v-model="Form_frozen.amount"></el-input>
                </el-form-item>
                <el-form-item label="备注：" required>
                    <el-input size="small" placeholder="请多写一些文字" v-model="Form_frozen.remarks"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_frozen = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" @click="frozenDo">确 定</el-button>
            </div>
        </el-dialog>


        <!-- 客户客户保证金退还至余额对话框 -->
        <el-dialog title="客户保证金退还至余额" :visible.sync="is_giveBack" width="30%">

            <el-form :model="Form_frozen" label-width="120px">
                <el-form-item label="退还金额:" required>
                    <el-input size="small" placeholder="请按照0.00的格式输入" v-model="Form_giveBack.amount"></el-input>
                </el-form-item>
                <el-form-item label="备注：" required>
                    <el-input size="small" placeholder="请多些一些文字" v-model="Form_giveBack.remarks"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_giveBack = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" @click="giveBackDo">确 定</el-button>
            </div>
        </el-dialog>









        <!-- 详情对话框 -->
        <el-dialog title="财务记录详情" :visible.sync="is_detail" :width="dialogscreen?'95%':'60%'"
            :close-on-click-modal="false">



            <div class="detail_style">
                <div class="btnsArea">
                    <span>
                        <span v-show="statusName=='待审核'">
                            <el-tag type="info" effect="plain">
                                {{statusName }}
                            </el-tag>
                        </span>


                        <span v-show="statusName=='已审核,待确认'">
                            <el-tag type="warning" effect="plain">
                                {{statusName }}
                            </el-tag>
                        </span>


                        <span v-show="statusName=='已审核,已确认'">
                            <el-tag effect="plain">
                                {{statusName }}
                            </el-tag>
                        </span>
                    </span>


                    <span>

                        <el-button plain v-show="presentId==0 && isVerifyOrder ==0 && statusOrder == 0" size="mini"
                            @click="checkPredeposit">审核预存款申请
                        </el-button>
                        <el-button plain v-show="presentId!==0" size="mini" @click="checkPresentfund">审核配送款申请
                        </el-button>
                        <el-button plain v-show="statusOrder == 0" size="mini" @click="cancelApply">撤销申请
                        </el-button>
                        <el-button plain v-show="statusOrder == 1 && isVerifyOrder ==0 " size="mini" @click="affirm">
                            确认到账</el-button>

                    </span>
                </div>

                <div class="slideArea_style">

                    <div class="status_style">

                        <div class="tit sAlignItemsCenter">
                            <span class="sRectangleStyle"></span>
                            基本信息:</div>
                        <span>
                            客户：
                            <span class="word_666" @dblclick="copyCustomerName">
                                {{customerName}}
                            </span>

                        </span>

                        <!-- <span class="type_style">
                        编号：<span class="word_666">
                            {{customerNumber}}
                        </span>
                    </span> -->

                        <span class="type_style">
                            财务单：<span>{{currentOrdernum}}</span>
                        </span>
                        <span class="type_style">
                            财务类型： <span>{{currentPrename}}</span>
                        </span>
                        <span class="type_style">
                            操作类型：<span class="text_primary">
                                {{currentFinancetypename}}
                            </span>
                        </span>
                        <span class="type_style">
                            金额：<i class="NumberNoLeft">
                                ￥{{amount}}
                            </i>
                        </span>
                    </div>


                    <div class="first" v-show="this.detailData.finance_detail !== undefined">
                        <div class="tit sAlignItemsCenter">
                            <span class="sRectangleStyle"></span>
                            账户记录:</div>
                        <div class="content">
                            <div>变动前账户：<span class="NumberNoLeft">￥{{before_account}}</span></div>
                            <div>变动后账户：<span class="NumberNoLeft">￥{{after_account}}</span></div>

                        </div>
                    </div>

                    <div class="image" v-show="imgUrl">
                        <div class="tit sAlignItemsCenter">
                            <span class="sRectangleStyle"></span>
                            预览 <span>（点击可看大图）：</span></div>
                        <el-image style="width: 100px; height: 100px" :src="imgUrl" :preview-src-list="imgSrcList">
                        </el-image>
                    </div>

                    <div class="second" v-show="useby.length>0">
                        <div class="tit sAlignItemsCenter">
                            <span class="sRectangleStyle"></span>
                            被依赖:
                        </div>
                        <div>

                            <el-table :data="useby" border style="width: 100%" :header-cell-style="styleBindFun">
                                <el-table-column prop="order_num" label="财务单号" align="center">
                                </el-table-column>


                                <el-table-column label="金额" align="center">
                                    <template slot-scope="scope">
                                        {{scope.row.amount}}
                                    </template>
                                </el-table-column>

                                <el-table-column label="创建时间" align="center">
                                    <template slot-scope="scope">
                                        {{scope.row.create_time}}
                                    </template>
                                </el-table-column>

                            </el-table>
                        </div>
                    </div>

                    <div class="third" v-show="depends.length>0">
                        <div class="tit sAlignItemsCenter">
                            <span class="sRectangleStyle"></span>
                            依赖:
                            <span>依赖金额：<i v-show="depends_sum !==0" class="Number marginRight">￥{{depends_sum}}
                                </i></span>
                            <span> 依赖数：<i v-show="depends_count !==0"
                                    class="Number marginRight">{{depends_count}}</i></span>
                            <span> 建议配送：<i class="Number ">￥{{max_present}}</i></span>
                        </div>
                        <el-table :data="depends" border style="width: 100%" :header-cell-style="styleBindFun">
                            <el-table-column prop="order_num" label="财务单号" align="center">
                            </el-table-column>


                            <el-table-column label="金额" align="center">
                                <template slot-scope="scope">
                                    ￥{{scope.row.amount_format}}
                                </template>
                            </el-table-column>

                            <el-table-column label="创建时间" align="center">
                                <template slot-scope="scope">
                                    {{scope.row.create_time}}
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>

                    <div class="fourthly" v-show="logs.length>0">
                        <div class="tit sAlignItemsCenter">
                            <span class="sRectangleStyle"></span>
                            日志:</div>
                        <el-table :data="logs" border style="width: 100%" :header-cell-style="styleBindFun">
                            <el-table-column prop="log" label="操作内容" align="center" width="390">
                            </el-table-column>


                            <el-table-column label="操作人" align="center">
                                <template slot-scope="scope">
                                    {{scope.row.user_name}}
                                </template>
                            </el-table-column>


                            <el-table-column prop="create_time" label="操作时间" align="center">
                            </el-table-column>
                        </el-table>
                    </div>

                    <div class="fifth">
                        <div class="tit sAlignItemsCenter">
                            <span class="sRectangleStyle"></span>
                            备注:</div>
                        <div class="detailRemarksStyle">
                            <span class="RemarksStyle">{{detailRemarks}}</span>

                        </div>
                    </div>

                </div>

            </div>


            <div slot="footer" class="dialog-footer">
                <el-button class="frontBtn" size="mini" @click="detailDo">确 定</el-button>
            </div>
        </el-dialog>



        <!-- 审核预存款申请对话框 -->
        <el-dialog title="审核预存款申请" :visible.sync="is_checkPredeposit" width="30%">

            <el-form :model="Form_checkPredeposit" label-width="100px" class="checkPredeposit_style">

                <el-form-item label="备注:">
                    <el-input type="textarea" v-model="Form_checkPredeposit.remarks" placeholder="请输入缘由"></el-input>
                </el-form-item>


            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_checkPredeposit = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" type="primary" @click="checkPredepositDo">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 审核配送款申请对话框 -->
        <el-dialog title="审核配送款申请" :visible.sync="is_checkPresentfund" width="30%">

            <el-form :model="Form_checkPresentfund" label-width="100px" class="checkPredeposit_style">

                <el-form-item label="备注:">
                    <el-input type="textarea" v-model="Form_checkPresentfund.remarks" placeholder="请输入缘由"></el-input>
                </el-form-item>


            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_checkPresentfund = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" type="primary" @click="checkPresentfundDo">确 定</el-button>
            </div>
        </el-dialog>


        <!-- 撤销申请对话框 -->
        <el-dialog title="撤销申请" :visible.sync="is_cancelApply" width="30%">

            <el-form :model="Form_cancel" :rules="Form_cancel_rules" label-width="100px" class="checkPredeposit_style">

                <el-form-item label="备注:" prop="remarks">
                    <el-input type="textarea" v-model="Form_cancel.remarks" placeholder="请输入缘由"></el-input>
                </el-form-item>


            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_cancelApply = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" type="primary" @click="cancelApplyDo">确 定</el-button>
            </div>
        </el-dialog>



        <!-- 确认到账对话框 -->
        <el-dialog title="确认到账" :visible.sync="is_affirm" width="30%">

            <el-form :model="Form_affirm" label-width="100px" class="checkPredeposit_style">

                <el-form-item label="备注:">
                    <el-input type="textarea" v-model="Form_affirm.remarks" placeholder="请输入缘由"></el-input>
                </el-form-item>


            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_affirm = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" type="primary" @click="affirmDo">确 定</el-button>
            </div>
        </el-dialog>














    </div>
</template>

<script>
    import {
        erpApi
    } from '../../config'

    import {
        domainApi
    } from '../../config'

    export default {
        data() {
            return {

                dialogscreen: false,
                getRechargeDeductionInfo: {
                    page: 1,
                    per_page: 8,
                    finance_type_id: '',
                    present_id: '',
                    start_time: '',
                    end_time: '',
                    status: '',
                    is_verify: '',
                },
                sBeginTime: '',
                sEndTime: '',
                choose_Finance: '系统生成',
                FinanceData: [],
                PresentData: [],

                yueKouKuanTotal: 0,
                yueKouKuanData: [],

                // 审核+确认状态
                checkStatusData: [{
                        id: 0,
                        name: '待审核'
                    },
                    {
                        id: 1,
                        name: '已审核'
                    },
                ],

                verifyStatusData: [{
                        id: 0,
                        name: '待确认'
                    },
                    {
                        id: 1,
                        name: '已确认'
                    },
                ],

                // 客户预存款冻结为保证金状态
                is_frozen: false,
                Form_frozen: {
                    amount: '',
                    remarks: ''
                },

                // 客户保证金退还至余额
                is_giveBack: false,
                Form_giveBack: {
                    amount: '',
                    remarks: ''
                },


                max_present: '',



                // 扣款
                is_Deduction: false,

                // 转换
                is_change: false,
                Form_change: {
                    transfer_present_id: '',
                    receive_present_id: '',
                    amount: '',
                    remarks: "",
                },

                // 激活
                is_activation: false,
                Form_activation: {
                    present_id: '',
                },

                // 详情
                is_detail: false,
                detailData: [],
                amount: 0.00,
                after_account: 0.00,
                before_account: 0.00,
                useby: [],
                depends: [],
                logs: [],
                remarks: '',
                currentOrdernum: '',
                currentPrename: '',
                currentFinancetypename: '',
                presentId: '',
                depends_sum: '',
                depends_count: '',
                // 配送时充值单选择
                is_setRefillCard: false,
                form_getRefillCard: {
                    page: 1,
                    per_page: 5,
                    customer_number: ''
                },
                refillCardData: [],
                refillCardTotal: 0,
                selectedRefillCardData: [],
                is_set: false,

                is_Recharge: false,

                dataArea: [],

                imgUrl: '',
                imgSrcList: [],

                is_checkPredeposit: false,
                Form_checkPredeposit: {
                    order_num: '',
                    remarks: ''
                },

                is_checkPresentfund: false,
                Form_checkPresentfund: {
                    order_num: '',
                    remarks: ''
                },


                is_cancelApply: false,
                Form_cancel: {
                    order_num: '',
                    remarks: ''
                },

                Form_cancel_rules: {
                    remarks: [{
                        required: true,
                        message: '请填写撤销申请备注',
                        trigger: 'blur'
                    }, ]
                },

                is_affirm: false,
                Form_affirm: {
                    order_num: '',
                    remarks: ''
                },

                activeName: 'first',
                statusName: '',
                customerName: '',
                customerNumber: '',
                statusOrder: '',
                isVerifyOrder: '',

                detailRemarks: '',

                nActiveIndex: '0',

                nTotalMoney: 0,
                aTotalOrder: [],


            }
        },
        created() {
            this.getFinance()
            this.getPresent()
            this.fnWeek()
            this.fnJudgeScreen()
        },

        mounted() {
            window.onresize = () => {
                let width = document.body.clientWidth
                if (width < 1550) {
                    this.dialogscreen = true
                } else {
                    this.dialogscreen = false
                }
            }
        },



        methods: {

            fnJudgeScreen() {
                let width = document.body.clientWidth
                if (width < 1550) {
                    this.dialogscreen = true
                } else {
                    this.dialogscreen = false
                }
            },

            // 绑定表格头部和内容样式方法
            styleBindFun() {
                let styleBind = "background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                return styleBind;
            },




            // 初始化当天日期范围
            fnInitDateRange() {
                let date = new Date();
                let year = date.getFullYear();
                let month = Number(date.getMonth() + 1);
                month = month >= 10 ? month : '0' + month
                let day = Number(date.getDate());
                day = day >= 10 ? day : '0' + day

                // 当天
                let sIntraDayBegin = year + '-' + month + '-' + day + ' ' + '00:00:00'

                let sIntraDayEnd = year + '-' + month + '-' + day + ' ' + '23:59:59'

                this.sBeginTime = sIntraDayBegin
                this.sEndTime = sIntraDayEnd

                this.getRechargeDeductionInfo.start_time = this.sBeginTime
                this.getRechargeDeductionInfo.end_time = this.sEndTime
                this.seacrhWithCondition()

                this.nActiveIndex = 0

            },

            fnYesterday() {
                let date = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24);
                let year = date.getFullYear();
                let month = Number(date.getMonth() + 1);
                month = month >= 10 ? month : '0' + month
                let day = Number(date.getDate());
                day = day >= 10 ? day : '0' + day

                // 昨天
                let Begin = year + '-' + month + '-' + day + ' ' + '00:00:00'

                let End = year + '-' + month + '-' + day + ' ' + '23:59:59'

                this.sBeginTime = Begin
                this.sEndTime = End

                this.getRechargeDeductionInfo.start_time = this.sBeginTime
                this.getRechargeDeductionInfo.end_time = this.sEndTime
                this.seacrhWithCondition()

                this.nActiveIndex = 1
            },

            fnWeek() {
                let date = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                let year = date.getFullYear();
                let month = Number(date.getMonth() + 1);
                month = month >= 10 ? month : '0' + month
                let day = Number(date.getDate());
                day = day >= 10 ? day : '0' + day


                let date2 = new Date();
                let year2 = date2.getFullYear();
                let month2 = Number(date2.getMonth() + 1);
                month2 = month2 >= 10 ? month2 : '0' + month2
                let day2 = Number(date2.getDate());
                day2 = day2 >= 10 ? day2 : '0' + day2

                // 近一周
                let Begin = year + '-' + month + '-' + day + ' ' + '00:00:00'

                let End = year2 + '-' + month2 + '-' + day2 + ' ' + '23:59:59'

                this.sBeginTime = Begin
                this.sEndTime = End

                this.getRechargeDeductionInfo.start_time = this.sBeginTime
                this.getRechargeDeductionInfo.end_time = this.sEndTime
                this.seacrhWithCondition()

                this.nActiveIndex = 2
            },

            fnMonth() {
                let date = new Date();
                let year = date.getFullYear();
                let month = Number(date.getMonth() + 1);
                month = month >= 10 ? month : '0' + month
                let day = Number(date.getDate());
                day = day >= 10 ? day : '0' + day


                let dayBegin = "01"
                // 本月
                let Begin = year + '-' + month + '-' + dayBegin + ' ' + '00:00:00'

                let End = year + '-' + month + '-' + day + ' ' + '23:59:59'

                this.sBeginTime = Begin
                this.sEndTime = End

                this.getRechargeDeductionInfo.start_time = this.sBeginTime
                this.getRechargeDeductionInfo.end_time = this.sEndTime
                this.seacrhWithCondition()

                this.nActiveIndex = 3

            },

            fnYear() {
                let date = new Date();
                let year = date.getFullYear();
                let month = Number(date.getMonth() + 1);
                month = month >= 10 ? month : '0' + month
                let day = Number(date.getDate());
                day = day >= 10 ? day : '0' + day


                let dayBegin = "01"
                let monthBegin = "01"
                // 本年
                let Begin = year + '-' + monthBegin + '-' + dayBegin + ' ' + '00:00:00'

                let End = year + '-' + month + '-' + day + ' ' + '23:59:59'

                this.sBeginTime = Begin
                this.sEndTime = End

                this.getRechargeDeductionInfo.start_time = this.sBeginTime
                this.getRechargeDeductionInfo.end_time = this.sEndTime
                this.seacrhWithCondition()

                this.nActiveIndex = 4

            },

            fnUnlimited() {
                this.sBeginTime = ''
                this.sEndTime = ''
                this.getRechargeDeductionInfo.start_time = this.sBeginTime
                this.getRechargeDeductionInfo.end_time = this.sEndTime
                this.seacrhWithCondition()

                this.nActiveIndex = 5
            },

            fnChangeTime() {
                this.nActiveIndex = ''
            },





            seacrhWithCondition() {
                this.getRechargeDeductionInfo.start_time = this.sBeginTime
                this.getRechargeDeductionInfo.end_time = this.sEndTime

                if (this.sBeginTime == null) {
                    this.getRechargeDeductionInfo.start_time = ''
                }

                if (this.sEndTime == null) {
                    this.getRechargeDeductionInfo.end_time = ''
                }

                if (this.sBeginTime == null && this.sEndTime !== null) {
                    return this.$message.error('请把时间选择完整')
                }

                if (this.sBeginTime !== null && this.sEndTime == null) {
                    return this.$message.error('请把时间选择完整')
                }

                if (this.sBeginTime == '' && this.sEndTime !== '') {
                    return this.$message.error('请把时间选择完整')
                }

                if (this.sBeginTime !== '' && this.sEndTime == '') {
                    return this.$message.error('请把时间选择完整')
                }


                this.getRechargeDeductionInfo.page = 1
                this.getRechargeDeduction()
            },

            // 获取数据
            async getRechargeDeduction() {

                // if (this.dataArea !== null && this.dataArea.length > 0) {
                //     this.getRechargeDeductionInfo.start_time = this.dataArea[0]
                //     this.getRechargeDeductionInfo.end_time = this.dataArea[1]
                // } else {
                //     this.getRechargeDeductionInfo.start_time = ''
                //     this.getRechargeDeductionInfo.end_time = ''
                // }



                let params = this.getRechargeDeductionInfo
                const res = await this.$https.post(erpApi + '/finances?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.yueKouKuanData = res.data.data
                this.yueKouKuanTotal = res.data.total


            },
            handleCurrentChangeCZKK(data) {
                this.getRechargeDeductionInfo.page = data
                this.getRechargeDeduction()
            },

            // 获取财务类型
            async getFinance() {
                const res = await this.$https.post(erpApi + '/finance/type/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.FinanceData = res.data
                let obj = {
                    "id": 0,
                    "name": "系统生成"
                }
                this.FinanceData.unshift(obj)
            },

            // 获取配送类型
            async getPresent() {
                const res = await this.$https.post(erpApi + '/present/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.PresentData = res.data
                let obj = {
                    "id": 0,
                    "name": "预存款"
                }
                this.PresentData.unshift(obj)


            },

            // --------------搜索与重置----------------------------------------
            search() {
                this.getRechargeDeduction()
            },
            reset() {
                this.getRechargeDeductionInfo.finance_type_id = ''
                this.getRechargeDeductionInfo.present_id = ''
                this.getRechargeDeductionInfo.status = ''
                this.getRechargeDeductionInfo.is_verify = ''

                this.fnInitDateRange()
            },

            // 客户预存款冻结为保证金
            frozen() {
                this.Form_frozen.amount = ''
                this.Form_frozen.remarks = ''

                this.is_frozen = true
                this.Form_frozen.customer_number = this.KHNumber
            },
            async frozenDo() {
                let params = this.Form_frozen
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/finance/deposit?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_frozen = false
            },


            // 客户保证金退还至余额
            giveBack() {
                this.Form_giveBack.amount = ''
                this.Form_giveBack.remarks = ''

                this.is_giveBack = true
                this.Form_giveBack.customer_number = this.KHNumber
            },
            async giveBackDo() {
                let params = this.Form_giveBack
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/finance/undeposit?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_giveBack = false
            },





            // -------------------转换-------------------------------------------
            change() {
                this.Form_change.transfer_present_id = ''
                this.Form_change.receive_present_id = ''
                this.Form_change.amount = ''
                this.is_change = true
            },
            async ChangeDo() {
                let params = this.Form_change
                params.customer_number = this.KHNumber
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/finance/transfer?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_change = false
                this.getRechargeDeduction()

            },


            // -------------------激活-------------------------------------------
            activation() {
                this.Form_activation.present_id = ''
                this.is_activation = true
            },
            async activationDo() {
                let params = this.Form_activation
                params.customer_number = this.KHNumber
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/finance/activate?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_activation = false
            },

            empty() {
                // 置空
                this.before_account = 0.00
                this.after_account = 0.00
                this.amount = 0.00
                this.currentFinancetypename = ''
                this.presentId = ''
                this.depends_sum = ''
                this.depends_count = ''
                this.max_present = ''
                this.statusName = ''
                this.customerName = ''
                this.customerNumber = ''
                this.statusOrder = ''
                this.isVerifyOrder = ''
                this.imgUrl = ''
                this.imgSrcList = []
                this.useby = []
                this.depends = []
                this.logs = []
                this.detailRemarks = ''


            },
            // --------------------查看详情 -----------------------------
            async seeDetail(row) {
                this.empty()
                this.currentOrdernum = row.order_num
                this.currentPrename = row.present_type_name
                this.is_detail = true
                let params = {
                    order_num: row.order_num
                }
                const res = await this.$https.post(erpApi + '/finance/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.detailData = res.data




                this.currentFinancetypename = this.detailData.finance_type_name
                this.presentId = this.detailData.present_id
                this.depends_sum = this.detailData.depends_sum_format
                this.depends_count = this.detailData.depends_count
                this.max_present = this.detailData.max_present_format
                this.statusName = this.detailData.status_name
                this.customerName = this.detailData.customer_name
                this.customerNumber = this.detailData.customer_number
                this.statusOrder = this.detailData.status
                this.isVerifyOrder = this.detailData.is_verify ? 1 : 0
                this.imgUrl = this.detailData.file_url
                this.imgSrcList.push(this.imgUrl)
                if (this.detailData.finance_detail !== undefined && this.detailData.finance_detail
                    .before_account !== undefined) {
                    this.before_account = this.detailData.finance_detail.before_account
                }
                if (this.detailData.finance_detail !== undefined && this.detailData.finance_detail.after_account !==
                    undefined) {
                    this.after_account = this.detailData.finance_detail.after_account
                }



                this.amount = this.detailData.amount_format
                this.useby = this.detailData.useby
                this.depends = this.detailData.depends
                this.logs = this.detailData.logs
                this.detailRemarks = this.detailData.remarks

            },
            async seeDetailAfterOper() {
                this.empty()
                let params = {
                    order_num: this.currentOrdernum
                }
                const res = await this.$https.post(erpApi + '/finance/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.detailData = res.data
                this.currentFinancetypename = this.detailData.finance_type_name
                this.presentId = this.detailData.present_id
                this.depends_sum = this.detailData.depends_sum_format
                this.depends_count = this.detailData.depends_count
                this.max_present = this.detailData.max_present_format
                this.statusName = this.detailData.status_name
                this.customerName = this.detailData.customer_name
                this.customerNumber = this.detailData.customer_number
                this.statusOrder = this.detailData.status
                this.isVerifyOrder = this.detailData.is_verify ? 1 : 0


                this.imgUrl = this.detailData.file_url
                this.imgSrcList.push(this.imgUrl)
                if (this.detailData.finance_detail.befor_account !== undefined) {
                    this.before_account = this.detailData.finance_detail.befor_account
                }
                if (this.detailData.finance_detail.after_account !== undefined) {
                    this.after_account = this.detailData.finance_detail.after_account
                }


                this.amount = this.detailData.amount_format
                this.useby = this.detailData.useby
                this.depends = this.detailData.depends
                this.logs = this.detailData.logs
                this.detailRemarks = this.detailData.remarks

            },

            // 配送时充值单选择
            async setRefillCard() {
                this.is_setRefillCard = true
                this.form_getRefillCard.customer_number = this.KHNumber
                let params = this.form_getRefillCard
                const res = await this.$https.post(erpApi + '/finance/select?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.refillCardData = res.data.data
                this.refillCardTotal = res.data.total
            },


            // 审核预存款申请
            checkPredeposit() {
                this.is_checkPredeposit = true
                this.Form_checkPredeposit.is_verify = 0
                this.Form_checkPredeposit.remarks = ''
            },
            async checkPredepositDo() {
                this.Form_checkPredeposit.order_num = this.currentOrdernum
                let params = this.Form_checkPredeposit
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/finance/audit?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_checkPredeposit = false
                this.seeDetailAfterOper()
                this.getRechargeDeduction()

            },


            //  审核配送款申请
            checkPresentfund() {
                this.is_checkPresentfund = true
                this.Form_checkPresentfund.order_num = this.currentOrdernum
            },
            async checkPresentfundDo() {
                let params = this.Form_checkPresentfund
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/finance/check?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_checkPresentfund = false
                this.seeDetailAfterOper()
                this.getRechargeDeduction()
            },


            // 撤销申请
            cancelApply() {
                this.is_cancelApply = true
                this.Form_cancel.order_num = this.currentOrdernum
                this.Form_cancel.remarks = ''
            },
            cancelApplyDo() {
                this.$confirm('您确定撤销该财务单吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    .then(async () => {

                        let params = this.Form_cancel
                        const {
                            data
                        } = await this.$https.post(erpApi + '/customer/finance/cancel?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)
                        this.is_cancelApply = false
                        this.detailDo()

                    })
                    .catch(() => {
                        this.$message.error('您取消了按钮')
                    })



            },


            // 确认到账
            affirm() {
                this.is_affirm = true
                this.Form_affirm.order_num = this.currentOrdernum
            },
            async affirmDo() {

                this.$confirm('您确定该财务单到账吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    .then(async () => {

                        let params = this.Form_affirm
                        const {
                            data
                        } = await this.$https.post(erpApi + '/customer/finance/verify?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)
                        this.is_affirm = false
                        this.seeDetailAfterOper()
                        this.getRechargeDeduction()
                    })
                    .catch(() => {
                        this.$message.error('您取消了按钮')
                    })


            },


            // tabs切换
            handleClick(data) {
                if (data.index == 0) {
                    this.getRechargeDeductionInfo.status = ''
                    this.getRechargeDeductionInfo.is_verify = ''
                    this.getRechargeDeductionInfo.page = 1

                    this.activeName = 'first'
                    this.getRechargeDeduction()
                }

                if (data.index == 1) {
                    this.getRechargeDeductionInfo.status = 0
                    this.getRechargeDeductionInfo.is_verify = ''
                    this.getRechargeDeductionInfo.page = 1

                    this.activeName = 'second'
                    this.getRechargeDeduction()

                }

                if (data.index == 2) {
                    this.getRechargeDeductionInfo.status = 1
                    this.getRechargeDeductionInfo.is_verify = 0
                    this.getRechargeDeductionInfo.page = 1

                    this.activeName = 'third'
                    this.getRechargeDeduction()
                }

                if (data.index == 3) {
                    this.getRechargeDeductionInfo.status = 1
                    this.getRechargeDeductionInfo.is_verify = 1
                    this.getRechargeDeductionInfo.page = 1

                    this.activeName = 'fourth'
                    this.getRechargeDeduction()


                }
            },


            detailDo() {
                this.getRechargeDeductionInfo.page = 1
                this.getRechargeDeduction()
                this.is_detail = false
            },



            // -----------------------查看客户详情数据--------------------------------------
            async lookDetail(row) {
                let routeData = this.$router.resolve({
                    path: '/customerDetail',
                    query: {
                        data: row.customer_number,

                    }
                })
                window.open(routeData.href, '_blank')
            },


            downloadBill() {
                let customer_number = ''
                let present_id = this.getRechargeDeductionInfo.present_id
                let finance_type_id = this.getRechargeDeductionInfo.finance_type_id
                let start_time = this.getRechargeDeductionInfo.start_time
                let end_time = this.getRechargeDeductionInfo.end_time
                let status = this.getRechargeDeductionInfo.status
                let is_verify = this.getRechargeDeductionInfo.is_verify




                window.location.href = erpApi + "/finance/down?_ajax=1&customer_number=" + customer_number +
                    "&present_id=" + present_id + "&finance_type_id=" + finance_type_id + "&start_time=" + start_time +
                    "&end_time=" + end_time + "&status=" + status + "&is_verify=" + is_verify


            },


            // 双击复制名字
            copyCustomerName() {

                var input = document.createElement("input"); // 创建input对象
                input.value = this.customerName; // 设置复制内容
                document.body.appendChild(input); // 添加临时实例
                input.select(); // 选择实例内容
                document.execCommand("Copy"); // 执行复制
                document.body.removeChild(input); // 删除临时实例
                this.$message.success('复制成功！');

            },


            fnCalculate() {
                let val1 = parseInt(this.yueKouKuanTotal / this.getRechargeDeductionInfo.per_page) + 1;

                this.aTotalOrder = []
                for (let i = 1; i <= val1; i++) {
                    this.getRechargeDeductionInfo.page = i
                    this.fnGetRechargeDeductionOfCalculate()
                }


            },

            // 获取数据
            async fnGetRechargeDeductionOfCalculate() {
                let params = this.getRechargeDeductionInfo
                const res = await this.$https.post(erpApi + '/finances?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                let data = res.data.data
                data.forEach(node => {
                    // this.aTotalOrder.push(node.amount_format)

                    // this.nTotalMoney = Number(this.nTotalMoney)+ Number(parseFloat(node.amount_format))
                    // console.log( this.nTotalMoney,' this.nTotalMoney');
                })

                // data.forEach(node=>{
                //    this.nTotalMoney+= node.amount_format
                // })

                // console.log(this.nTotalMoney,'nTotalMoney');

            },







        },
    }
</script>

<style lang="less" scoped>
    .RechargeDeduction {
        // margin: 35px 0;
        position: relative;
        margin-bottom: 20px;
    }

    .btns {
        background-color: #dfe9f5;
        padding: 8px 20px;
    }






    .searchBtns {

        position: relative;
        background-color: #f5f7fa;
        padding: 10px 20px 1px 20px;
        box-sizing: border-box;

    }


    .searchBtns .top,
    .searchBtns .middle,
    .searchBtns .bottom {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 12px;
    }

    .searchBtns .top span,
    .searchBtns .middle span {
        font-size: 14px;
        color: #333;
        margin-right: 50px;
        display: flex;
        align-items: center;

    }



    .BackBtn {
        position: absolute;
        right: 1%;
    }


    .icon-chongzhi,
    .icon-koukuanguize,
    .icon-zijin_dongjie,
    .icon-tuihuan,
    .icon-zhuanhuan {
        font-size: 12px;
    }

    .detail_style {
        width: 100%;
        padding: 0 10px;
        box-sizing: border-box;
    }

    .detail_style .image .tit,
    .detail_style .first .tit {
        font-size: 16px;
        font-weight: bolder;
    }

    .detail_style .image .tit {
        margin-bottom: 10px;
    }

    .detail_style .image {
        margin-top: 30px;
    }

    .detail_style .image .tit span {
        font-size: 14px;
        font-weight: normal;
    }


    .detail_style .first .content {
        display: flex;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .detail_style .first .content div {
        flex: 1;
        font-size: 14px;
        color: #666666;
    }



    .detail_style .first .content div span .Number {
        color: #ff0000 !important;
        font-size: 18px !important;

    }

    .detail_style .first .remark {
        font-size: 16px;
        font-weight: bolder;

    }

    .detail_style .first .remark span {
        font-size: 14px;
        font-weight: normal;
        color: #333;

    }

    .detail_style .second {
        margin-top: 50px;
    }

    .detail_style .third,
    .detail_style .fifth,
    .detail_style .fourthly {
        margin-top: 30px;
    }

    .detail_style .third .tit,
    .detail_style .fourthly .tit,
    .detail_style .fifth .tit,
    .detail_style .second .tit {
        font-size: 16px;
        font-weight: bolder;
    }

    .slideArea_style {
        max-height: 400px;
        overflow-y: auto;
    }

    /* el-table 滚动条样式 */
    .slideArea_style::-webkit-scrollbar {

        width: 6px;
    }



    .slideArea_style::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }

    .slideArea_style::-webkit-scrollbar-thumb:hover {
        background-color: #a8a8a8;
    }





    /* el-table 滚动条样式 */
    div /deep/.el-table__body-wrapper::-webkit-scrollbar {
        height: 8px;
        width: 6px;
    }



    div /deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }

    div /deep/.el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
        background-color: #a8a8a8;
    }


    .title_style {
        font-weight: bolder;
        font-size: 18px;
        margin-bottom: 20px;
        // padding: 5px 10px 5px 20px;
        // background-color: #21397f;
        // width: 5%;
        // color: #fff;
    }




    .detail_style .ordernum_style {
        text-align: right;
        margin-bottom: 10px;
    }

    .detail_style .ordernum_style span span {
        color: #888;
    }

    .status_style .tit,
    .first .tit,
    .image .tit,
    .second .tit,
    .third .tit,
    .fourthly .tit,
    .fifth .tit {
        display: flex;
    }



    .type_style {
        margin-left: 20px;
    }

    .btnsArea {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        background-color: #f5f7fa;
        padding: 3px 0px;
    }


    .status_style {
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .status_style .tit {
        color: #303133;
        font-size: 16px;
        font-weight: bolder;
        margin-bottom: 10px;
    }


    .detailRemarksStyle {
        color: #666;
        margin-top: 10px;
        margin-left: 2px;
    }

    .infoStyle {
        color: #1f6eef;
        font-size: 16px;
        display: flex;
        align-items: center;
        margin-right: 3px;
    }






    .downloadBillStyle {
        margin-left: 50px;
    }

    .checkPredeposit_style {
        width: 100%;
    }

    .cutPaymentStyle {
        color: #f56c6c;
    }

    .preStoreStyle {
        color: #409eff;
    }

    .systemGenerateStyle {
        color: #909399;
    }


    .el-range-editor.el-input__inner {
        width: 80%;
    }

    .marginRight {
        margin-right: 20px;
    }

    .scalculateStyle {
        display: flex;
        align-items: center;
    }

   
</style>