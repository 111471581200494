<template>
    <div id="app">
        <!-- 布局 -->
        <div class="purchaseLayout">

            <!-- 面包屑导航 -->
            <!-- <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }" class="breadcrumbStyle">首页</el-breadcrumb-item>
                <el-breadcrumb-item>采购入库</el-breadcrumb-item>
            </el-breadcrumb> -->

            <el-card>
                <!-- 工具栏部分 -->
                <div class="purchasechoose">
                    <span>

                    </span>

                    <el-date-picker v-model="choose_time" type="daterange" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期" value-format="yyyy-MM-dd" size="small">
                    </el-date-picker>
                    <el-button size="small" class="searchDo frontBtn" icon="el-icon-search" @click="CGsearch">搜索
                    </el-button>

                </div>

                <div class="functionStyle">
                      <!-- 添加按钮 -->
                    <el-button class="frontBtn " icon="el-icon-circle-plus" size="small" @click="addPurChase">添加采购单
                    </el-button>
                    <el-button class="frontBtn " icon="el-icon-s-home" size="small" @click="DoWarehousing">入库
                    </el-button>
                </div>

                <!-- 数据表格 -->
                <el-table :data="purchaseTableData" max-height="578" style="width:100%" stripe border
                    :header-cell-style="styleBindFun">

                    <el-table-column prop="order_num" label="采购单号" align="center" width="200" fixed>
                    </el-table-column>

                     <el-table-column prop="psn" label="包装码" align="center" width="100" >
                    </el-table-column>

                     <el-table-column prop="ssn" label="识别码" align="center" width="100" >
                    </el-table-column>

                    <el-table-column prop="status" label="状态" align="center" width="150">
                        <template slot-scope="scope">
                            <el-tag size="small" v-show="scope.row.status === '部分到货'">部分到货</el-tag>
                            <el-tag size="small" v-show="scope.row.status === '全部到货'" type="success">全部到货</el-tag>
                            <el-tag size="small" v-show="scope.row.status === '未到货'" type="danger">未到货</el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column prop="user_name" label="创建人" align="center" width="150">
                        <template slot-scope="scope">
                            <!-- <span v-if="scope.row.department_name">{{scope.row.department_name}}</span>
                            <span v-else>暂无</span>
                            ——
                            <span v-if="scope.row.position_name">{{scope.row.position_name}}</span>
                            <span v-else>暂无</span> -->
                            <!-- —— -->
                            <span v-if="scope.row.user_name">{{scope.row.user_name}}</span>
                            <!-- <span v-else>暂无</span> -->

                        </template>
                    </el-table-column>



                    <el-table-column prop="products_count" label="采购产品种类" align="center" width="150">
                    </el-table-column>

                    <el-table-column prop="products_sum" label="采购产品总数" align="center" width="150">
                    </el-table-column>

                    <el-table-column prop="receives_count" label="已入库种类" align="center" width="150">
                    </el-table-column>

                      <el-table-column prop="receives_sum" label="已入库总数" align="center" width="150">
                    </el-table-column>


                      <el-table-column label="创建时间" align="center" width="150">
                            <template slot-scope="scope">
                              {{scope.row.create_time.trim().split(" ")[0]}}
                        </template>
                    </el-table-column>


                    <el-table-column prop="remarks" label="备注" show-overflow-tooltip align="center" width="260">
                        <template slot-scope="scope">
                            <div class="remarks">{{scope.row.remarks}}</div>
                        </template>
                    </el-table-column>

                    <el-table-column label="采购单明细" align="center" width="160">
                        <template slot-scope="scope">
                            <el-button round @click="showPurchase(scope.row)" type="text" class="text_primary" size="medium">查看</el-button>
                        </template>
                    </el-table-column>

                    <el-table-column label="入库单" align="center" width="160">
                        <template slot-scope="scope">
                            <el-button round @click="showWarehouse(scope.row)" type="text" class="text_primary" size="medium">查看</el-button>
                        </template>
                    </el-table-column>


                    <el-table-column fixed="right" label="操作" width="240" align="center">
                        <template slot-scope="scope">

                            <el-button @click="editPurchaseNote(scope.row)" type="text" class="text_primary" size="big">
                                修改</el-button>
                            <!-- <el-button @click="Warehousing(scope.row)" type="text" size="big"
                                :disabled="scope.row.receive!==scope.row.quantity?false:true">入库</el-button> -->
                                 <el-button @click="Warehousing(scope.row)" type="text" class="text_primary" size="big"
                                >入库</el-button>
                            <!-- <el-button @click="edit(scope.row)" type="primary" size="mini"
                                :disabled="scope.row.receive==0?false:true">编辑</el-button> -->

                            <el-dropdown class="purchaseMore--Style">
                                <span class="el-dropdown-link text_primary">
                                    更多<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item @click.native="deletePurchase(scope.row)">删除采购</el-dropdown-item>
                                    <!-- <el-dropdown-item @click.native="deletePurchase__Product(scope.row)">删除采购中的产品</el-dropdown-item> -->
                                    <el-dropdown-item @click.native="addPurchase__Product(scope.row)">增加采购产品</el-dropdown-item>
                                    <el-dropdown-item @click.native="editPurchaseProduct__Num(scope.row)">修改采购产品数量</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页器 -->
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="getPurchaseInfo.current_page" :page-sizes="[2, 10, 20, 30]"
                    :page-size="getPurchaseInfo.page_size" layout="total, sizes, prev, pager, next, jumper"
                    :total="total" align="center">
                </el-pagination>
            </el-card>

            <!-- 查看购物单明细对话框 -->
            <el-dialog :title="currentPurchaseName" :visible.sync="dialogFormVisibleOfshowPurchase" width="78%">
                <el-button class="frontBtn createSecurityCode--Style" size="small" @click="createSecurityCode__All"
               v-loading.fullscreen.lock="fullscreenLoading"
                >生成整单防伪码</el-button>
                <el-table :data="purchaseDetailData"
                    :header-cell-style="{background:'#f2f3f5',fontSize:'14px',color:'#1D2129', fontWeight: 'normal'}"
                    style="width: 100%">
                    <el-table-column prop="product_sn" label="货号" width="180" align="center">
                    </el-table-column>

                    <el-table-column prop="brand_name" label="品牌名" align="center">
                    </el-table-column>

                    <el-table-column label="产品名" width="300" align="center">
                        <template slot-scope="scope">
                            {{scope.row.product_name}} <span v-show="scope.row.product_spec">({{scope.row.product_spec}})</span>
                        </template>
                    </el-table-column>

                  

                    <el-table-column label="应入库数量" width="120" align="center">
                        <template slot-scope="scope">
                            {{scope.row.quantity}}{{scope.row.unit_name}}
                        </template>
                    </el-table-column>

                    <el-table-column label="已入库数量" width="120" align="center">
                        <template slot-scope="scope">
                            {{scope.row.receive}}{{scope.row.unit_name}}
                        </template>
                    </el-table-column>

                     <el-table-column label="是否有批次" width="120" align="center">
                        <template slot-scope="scope">
                           <div v-if="scope.row.is_batch" class="yesIcon el-icon-check"></div>
                            <div v-else class="noIcon el-icon-close"></div>
                        </template>
                    </el-table-column>

                      <el-table-column label="是否一物一码" width="120" align="center">
                        <template slot-scope="scope">
                           <div v-if="scope.row.is_qr" class="yesIcon el-icon-check"></div>
                            <div v-else class="noIcon el-icon-close"></div>
                        </template>
                    </el-table-column>


                    <el-table-column fixed="right" label="操作" width="300" align="center">
                        <template slot-scope="scope">
                            <el-button @click="createSecurityCode__Single(scope.row)" type="text" size="medium" class="text_primary"
                                :disabled="scope.row.have_psns"
                                >生成防伪码</el-button>
                                 <el-button @click="downLoadSecurityCode__Single(scope.row)" type="text" size="medium" class="text_primary"
                                >下载防伪码</el-button>
                            <el-button @click="deletePurchseItem(scope.row)" type="text" size="medium" class="text_delete"
                                :disabled="scope.row.receive==0?false:true">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <div class="purchaseRemark">备注：{{currentRemarks}}</div>
            </el-dialog>


            <!-- 查看入库单明细对话框 -->
            <el-dialog :title="'购物单名称:'+currentWarehouseName" :visible.sync="dialogFormVisibleOfshowWarehouse"
                width="35%">

                <el-table :data="purchaseWarehouseData"
                    :header-cell-style="{background:'#f2f3f5',fontSize:'14px',color:'#1D2129', fontWeight: 'normal'}"
                    style="width: 100%">

                    <el-table-column prop="order_num" label="入库单" align="center">
                    </el-table-column>

                      <el-table-column prop="store_name" label="存放仓库" align="center">
                    </el-table-column>


                    <el-table-column fixed="right" label="操作" width="240" align="center">
                        <template slot-scope="scope">
                            <el-button @click="lookRK(scope.row)" type="text" size="big">查看入库单明细</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-dialog>






            <!-- 查看入库单明细对话框 -->
            <el-dialog title="查看入库单明细信息" :visible.sync="is_RK_detail" width="70%">
                <el-descriptions class="margin-top" :column="2" size="" border>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-user"></i>
                            仓库名
                        </template>
                        {{RKDetailDataObj.store_name}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-mobile-phone"></i>
                            入库单
                        </template>
                        {{RKDetailDataObj.order_num}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-location-outline"></i>
                            更新时间
                        </template>
                        {{RKDetailDataObj.update_time}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            备注
                        </template>
                        {{RKDetailDataObj.remarks}}
                    </el-descriptions-item>

                </el-descriptions>
                <el-table tooltip-effect="dark" style="width: 100%" :data="RKDetailDataObj.stocks" align="center"
                    :header-cell-style="styleBindFun" border>

                    <el-table-column prop="product_sn" label="货号" align="center" width="120">
                    </el-table-column>
                    <el-table-column label="产品名称" align="center" width="200">
                        <template slot-scope="scope">
                            {{scope.row.product_name}} {{scope.row.spec}}
                        </template>
                    </el-table-column>

                  
                    <el-table-column prop="before_quantity" label="入库前个数" align="center">
                    </el-table-column>
                    <el-table-column prop="after_quantity" label="入库后个数" align="center">
                    </el-table-column>

                      <el-table-column label="入库数量" align="center">
                        <template slot-scope="scope">
                            <span class="Number">{{scope.row.quantity}}</span> 
                        </template>
                    </el-table-column>
                     <el-table-column prop="unit_name" label="单位" align="center">
                    </el-table-column>]


                     <el-table-column label="批次" align="center" width="300">
                        <template slot-scope="scope">
                            {{scope.row.batch_name}} <span class="validityDateStyle">限用日期：{{scope.row.batch_expiry_date}}</span>
                        </template>
                    </el-table-column>

                </el-table>
                <div slot="footer" class="dialog-footer">

                    <el-button size="mini" class="frontBtn" @click="is_RK_detail = false">确 定</el-button>
                </div>
            </el-dialog>


            <!-- 修改采购单对话框 -->
            <el-dialog title="修改购物单(实际上只能改备注)" :visible.sync="is_editPurchaseNote" width="35%">
                <el-form :model="form__editPurchaseNote" :rules="editPurchaseNote_rules">
                    <el-form-item label="单号:" label-width="100px">
                        {{form__editPurchaseNote.order_num}}
                    </el-form-item>
                    <el-form-item label="备注:" prop="remarks" label-width="100px">
                        <el-input size="medium" type="textarea" v-model="form__editPurchaseNote.remarks"></el-input>
                    </el-form-item>
                </el-form>

                <div slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="is_editPurchaseNote=false">取 消</el-button>
                    <el-button size="mini" class="frontBtn" @click="editPurchaseNoteDo">确 定</el-button>
                </div>

            </el-dialog>


        </div>

    </div>
</template>

<script>
    import {
        erpApi
    } from '../../config'

    export default {
        data() {
            return {
                dialogFormVisibleOfshowPurchase: false, //控制显示购物单明细
                dialogFormVisibleOfshowWarehouse: false, //控制显示入库明细
                purchaseTableData: [], //采购单列表数据
                purchaseDetailData: [], //采购单详细数据
                purchaseWarehouseData: [], //入库单数据
                currentPurchaseName: '',
                currentWarehouseName: '', //当前入库名称
                total: 0, // 采购单总数
                // 分页数据
                getPurchaseInfo: {
                    page_size: 10,
                    current_page: 1,
                    start_date: '',
                    end_date: ''
                },
                choose_time: [],
                currentRemarks: '', //当前项备注
                is_add_purchase: false, // 控制添加采购单显示与隐藏
                is_edit_purchase: false, //控制编辑对话框的显示与隐藏








                temporary_data_product: [], //一个临时储存数据对象的数组
                setProductNumber_name: '', //设置商品数的时候产品名称


                products: [],
                is_RK_detail: false, //控制入库明细单显示与隐藏
                RKDetailDataObj: {}, //入库单明细数据数组


                // 仓库部分
                currentPurchaseNum: '', //正在查看的采购单Num
                is_warehouse_state: false, //控制仓库信息的显示与隐藏     
                is_warehousing_state: false, //控制入库对话框的显示与隐藏  
                is_add_RK_num: false, //控制添加入库数量对话框的显示与隐藏
                RK_order_num: '', //当前入库的采购单号
                RK_product_name: '', //添加入库产品个数产品名称
                RK_product_num: '', //添加的入库产品个数
                RK_product_id: '', //添加的入库产品ID
                RK_products: [], //添加入库产品数组
                RKproObj: {}, //添加入库的产品对象
                RK_max_num: '', //最大的入库个数
                warehouseData: [], //仓库列表数组
                WareHouse_Name: '选择仓库', //仓库名字
                purchaseRemarkRK: '', //入库单备注
                store_id: '', //入库的时候选中的仓库id
                Ready_Rk_products: [], //准备入库的产品清单
                is_directRK: false, //控制直接入库的对话框显示与隐藏
                // 获取数据添加条件
                RKgetPurchaseInfo: {
                    page: 1, //当前页
                    per_page: 8, //页面显示个数
                    start_date: "", //搜索条件开始时间
                    end_date: '', //搜索条件结束时间
                    store_id: '', //选择仓库id
                },
                directRKData: [], //入库单项数组
                RKtotal: 0, //直接入库单项总数
                chooseDateRange: '', //删选条件时间范围
                RkChooseWarehouse: '', //选中的仓库

                callin_warehouse: '',
                callOut_warehouse: '',
                chooseDateRangeWHMove: [], //调拨仓库选择时间范围
                //  获取调拨单添加条件
                DBgetInfo: {
                    page: 1, //当前页
                    per_page: 5, //页面显示个数
                    start_date: "", //搜索条件开始时间
                    end_date: '', //搜索条件结束时间
                    transfer_store_id: '', //调出仓库id
                    receive_store_id: '', //调入仓库id
                },
                DBTotal: 0, //调拨单总数
                Product_WHMove_data: [], //调拨单数据数组 
                is_add_WHMove: false, //控制添加调拨单显示与隐藏
                form_add_WHMove: {
                    transfer_store_id: "",
                    receive_store_id: '',
                    products: [],
                    remarks: ''
                }, //调拨单添加表单
                is_addWHMoveProducts: false, //
                WHMoveProductsData: [],
                chooseWHMoveProductsObj: {
                    name: '',
                    num: ''
                },
                chooseWHMoveProductsData: [], //添加产品（调拨单）选中的数组
                choosedWHMoveProductMax: 0, //选中的产品最大数
                WHMoveDetail: [], //查看调拨单详情数组
                is_WHMoveDetail: false, // 控制调拨单详情显示与隐藏






                // ------------------商品-------------------

                is_WareHouseCheckGoods_state: false, // 控制仓库盘点(商品)的显示与隐藏
                check_warehouse_goods: '', // 控制仓库盘点的选择仓库名
                check_warehouse_category: '', // 控制仓库盘点商品分类的选择
                check_warehouse_category_Data: [], //商品分类数组
                defaultProps: {
                    children: 'children',
                    label: 'name'
                },
                defaultExpand: [], //默认展开的数组
                PDGoodsInfo: {
                    page: 1,
                    per_page: 5,
                    category_id: '', //盘点仓库商品--商品id
                },
                handleNodeCategoryId: "",
                PDGoodsTotal: 0,
                PDGoodsData: [], //盘点仓库商品 --商品数据
                is_PDGoodsDetail: false,
                PDGoodsDetail_Data: [],
                // 添加采购单  表单数据
                form_add_purchase: {
                    remarks: '', // 设置备注
                    products: [], //商品数组
                },

                // ------------------库存变动列表---------------------------------
                KCPDInfo: {
                    page: 1,
                    per_page: 6,
                    start_date: '',
                    end_date: '',
                    product_id: '',
                    store_id: '',
                },
                KCPDTotal: 0,
                Stock_Change_data: [],
                Stock_Change_Details_data: [],
                Stock_Change_Detail_data: [],
                is_Stock_Change_Detail: false,
                chooseDateRangeKCBD: [],


                // ---------------------批次信息----------------------------------------
                BatchData: [],
                ItemChooseBatchNameId: '',
                ItemChooseBatchName: '',

                // 修改采购单
                is_editPurchaseNote: false,
                editPurchaseNote__order_num: '',
                editPurchaseNote__remarks: '',
                form__editPurchaseNote: {
                    order_num: '',
                    remarks: ''
                },
                editPurchaseNote_rules: {
                    remarks: [{
                        required: true,
                        message: '请输入备注',
                        trigger: 'blur'
                    }, ]
                },

                // 生成下载防伪码
                currentSecurityCode_Ordernum:'',
                fullscreenLoading:false,




            }
        },
        created() {
            this.getPurchase();
            this.getWareHouse();

        },
        methods: {
            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind = "background:#f6f8fa;fontSize:14px;color:#333333;fontWeight: bold;"
                return styleBind;
            },

            SearchCheckWareHouseGoods() {},
            SearchCheckWareHouse() {},
            // 1. 获取采购单信息方法
            async getPurchase() {
                var params = {
                    per_page: this.getPurchaseInfo.page_size,
                    page: this.getPurchaseInfo.current_page,
                    start_date: this.getPurchaseInfo.start_date,
                    end_date: this.getPurchaseInfo.end_date
                }
                const res = await this.$https.post(erpApi + '/plans?_ajax=1', params)
                if (!res) {
                    return this.$message.error('获取失败：' + res.statusText)
                }
                this.purchaseTableData = res.data.data
                this.total = res.data.total
            },


            // 3.查看采购单明细方法
            async showPurchase(row) {

                // 打开对话框
                this.dialogFormVisibleOfshowPurchase = true
                // 赋值当前购物单名称
                this.currentPurchaseName = `购物单编号：${row.order_num}`
                this.currentSecurityCode_Ordernum=row.order_num
                this.currentRemarks = row.remarks
                var params = {
                    order_num: row.order_num
                }
                const res = await this.$https.post(erpApi + '/plan/detail?_ajax=1', params)
                if (!res) {
                    return this.$message.error('获取失败：' + res.statusText)
                }
                // 赋值到购物车明细数组
                this.purchaseDetailData = res.data.products
                //赋值 正在查看的采购单
                this.currentPurchaseNum = row.order_num
            },


            //每页显示数发生变化
            handleSizeChange(val) {
                this.getPurchaseInfo.page_size = val;
                this.getPurchase();

            },
            // 当前页发生变化
            handleCurrentChange(val) {
                this.getPurchaseInfo.current_page = val;
                this.getPurchase();
            },


            // 4.查看入库单
            async showWarehouse(row) {

                // 1.打开对话框
                this.dialogFormVisibleOfshowWarehouse = true
                // 2. 赋值到名称
                this.currentWarehouseName = row.order_num
                var params = {
                    order_num: this.currentWarehouseName
                }
                const res = await this.$https.post(erpApi + '/plan/detail?_ajax=1', params)

                if (!res) {
                    return this.$message.error('获取失败：' + res.statusText)
                }

                // 赋值到入库单数组
                this.purchaseWarehouseData = res.data.receives
                console.log(this.purchaseWarehouseData,'this.purchaseWarehouseData');
            },






            // 6.添加采购单
            addPurChase() {
                this.$router.push({
                    path: '/addPurchase',
                })
                // this.is_add_purchase = true
            },











            // 11.编辑购物单方法
            edit(row) {
                this.is_edit_purchase = true
            },


























            // 23.查看入库单明细方法
            async lookRK(row) {
                // RKDetailData
                this.is_RK_detail = true
                let params = {
                    order_num: row.order_num
                }

                const res = await this.$https.post(erpApi + '/receive/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.RKDetailDataObj = res.data
            },


            // 25.删除购物单项的方法
            async deletePurchseItem(row) {
                this.$confirm('删除不可再恢复，您确定把产品：' + row.product_name + '放入回收站吗？','提示',{
                     confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                })
                    .then(async () => {
                            let params = {
                                order_num: this.currentPurchaseNum,
                                product_id: row.product_id
                            }
                            const {
                                data
                            } = await this.$https.post(erpApi + '/plan/delete?_ajax=1', params)
                            if (data.error_code !== 0) return this.$message.error(data.msg)
                            this.$message.success(data.msg);
                            this.getPurchase()
                            // this.dialogFormVisibleOfshowPurchase = false
                        }

                    )
                    .catch(
                        () => {
                            this.$message.error('您取消了按钮')
                        }
                    )


            },


            // 入库方法
            async Warehousing(row) {



                this.$router.push({
                    path: '/PurchaseOrderRK',
                    query: {
                        data: this.warehouseData,
                        order_num: row.order_num
                    }
                })

                // this.is_warehousing_state = true
                // this.RK_order_num = row.order_num



                // // 清空
                // this.purchaseRemarkRK = ''
                // this.WareHouse_Name = '选择仓库'
                // this.RK_products = []

                // console.log(this.purchaseRemarkRK, '1');
                // console.log(this.store_id, '2');
                // console.log(this.Ready_Rk_products, '3');


                // // 赋值当前购物单名称
                // this.currentPurchaseName = `购物单编号：${row.order_num}`
                // var params = {
                //     order_num: row.order_num
                // }
                // const res = await this.$https.post('/erp/plan/detail?_ajax=1', params)
                // console.log(res, 'resres');
                // if (!res) {
                //     return this.$message.error('获取失败：' + res.statusText)
                // }
                // // 赋值到购物单明细数组
                // this.purchaseDetailData = res.data.details

                // // 请求仓库数据
                // this.getWareHouse()
            },


            // 添加入库数量
            RKPurchaseItem(row) {
                this.is_add_RK_num = true;
                this.RK_product_name = row.product_name;
                this.RK_product_id = row.product_id;
                this.RK_max_num = row.quantity

            },
            RKPurchaseItemDo() {
                let RKproObj = {}
                this.is_add_RK_num = false;
                RKproObj.product_id = this.RK_product_id;
                RKproObj.quantity = this.RK_product_num;
                RKproObj.name = this.RK_product_name;
                this.RK_products.push(RKproObj)

                // for (let index = 0; index < this.RK_products.length; index++) {
                //     console.log(this.RK_products[index].product_id,'this.RK_products[index].product_id');
                //     console.log(this.RK_product_id,'this.RK_product_id');
                //     if(this.RK_products[index].product_id!==this.RK_product_id){
                //          return 
                //          }
                //        this.RK_products.push(RKproObj)
                // }

                // this.RK_products=[...new Set(...this.RK_products)]

            },


            // 入库产品删除
            handleCloseRK(tag) {
                this.RK_products.splice(this.RK_products.indexOf(tag), 1)
            },


            // 获取仓库列表
            async getWareHouse() {
                const res = await this.$https.post(erpApi + '/store/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.warehouseData = res.data
            },


            // 选择仓库方法
            dropdownWareHouse(data) {
                this.WareHouse_Name = data
            },


            // 确认入库
            async WarehousingDo() {

                this.warehouseData.forEach(item => {
                    if (item.name == this.WareHouse_Name) {
                        this.store_id = item.id
                    }
                })


                this.RK_products.forEach(item => {
                    let obj = {}
                    let {
                        product_id,
                        quantity,
                        name
                    } = item
                    obj.product_id = product_id
                    obj.quantity = quantity
                    this.Ready_Rk_products.push(obj)
                })

                //    --------------------------------------------------------------

                this.$confirm('您确定把这些产品入到 ' + this.WareHouse_Name + ' 吗？')
                    .then(async () => {
                            let params = {
                                order_num: this.RK_order_num,
                                remarks: this.purchaseRemarkRK,
                                store_id: this.store_id,
                                products: this.Ready_Rk_products
                            }
                            const {
                                data
                            } = await this.$https.post(erpApi + '/plan/receive?_ajax=1', params)
                            if (data.error_code !== 0) return this.$message.error(data.msg)
                            this.$message.success(data.msg);

                            this.is_warehousing_state = false
                        }

                    )
                    .catch(
                        () => {
                            this.$message.error('您取消了按钮')
                        }
                    )
            },



            // 分页条件触发
            handleSizeChangeRK(data) {

                this.RKgetPurchaseInfo.per_page = data

            },
            handleCurrentChangeRK(data) {

                this.RKgetPurchaseInfo.page = data

            },

            addDirectRK() {},



            RKSearch() {

                this.RKgetPurchaseInfo.start_date = this.chooseDateRange[0]
                this.RKgetPurchaseInfo.end_date = this.chooseDateRange[1]
                this.RKgetPurchaseInfo.store_id = this.RkChooseWarehouse

            },

            //  仓库调拨------------------------------------------------------------------------------
            //  打开仓库信息方法
            async openWarehouse() {
                this.$router.push({
                    path: '/allocation'
                })

            },

            // 添加调拨单方法
            addWHMove() {
                this.is_add_WHMove = true
                this.form_add_WHMove.transfer_store_id = ''
                this.form_add_WHMove.receive_store_id = ''
                this.form_add_WHMove.remarks = ''
                this.chooseWHMoveProductsData = []
            },
            async addWHMoveDo() {

                let params = {
                    transfer_store_id: this.form_add_WHMove.transfer_store_id,
                    receive_store_id: this.form_add_WHMove.receive_store_id,
                    product_list: this.form_add_WHMove.products,
                    remarks: this.form_add_WHMove.remarks
                }

                const {
                    data
                } = await this.$https.post(erpApi + '/transfer/create?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_add_WHMove = false;
                this.openWarehouse();
            },



            // 获取指定仓库的产品数据
            async getWHProducts() {
                let params = {
                    store_id: this.form_add_WHMove.transfer_store_id
                }
                this.getProducts(params)
            },

            async getProducts(data) {
                const res = await this.$https.post(erpApi + '/storage/select?_ajax=1', data)

                if (res.status !== 200) return this.$message.error(res.statusText)
                this.WHMoveProductsData = res.data.data

            },

            // 监听下拉框选择事件，把值赋到最大选择数上
            choosedWHMoveProduct(data) {
                this.WHMoveProductsData.forEach(item => {
                    if (data == item.product_name) {
                        this.choosedWHMoveProductMax = item.quantity
                    }
                })
            },

            // 仓库调拨添加商品的方法
            addWHMoveProducts() {
                // 判断有没有选择仓库
                if (this.form_add_WHMove.transfer_store_id == '') {
                    this.$message.error('请先选择仓库')
                } else {
                    this.is_addWHMoveProducts = true
                    this.chooseWHMoveProductsObj.name = ''
                    this.chooseWHMoveProductsObj.num = ''

                    this.getWHProducts()
                    this.getBatchInfo()
                }


            },


            addWHMoveProductItem() {
                this.is_addWHMoveProducts = false
                let obj = {}

                let name = this.chooseWHMoveProductsObj.name
                let num = this.chooseWHMoveProductsObj.num
                obj.name = name
                obj.quantity = num
                this.chooseWHMoveProductsData.push(obj)


                // 赋值
                let obj2 = {}
                var that = this
                this.WHMoveProductsData.forEach(item => {
                    if (item.product_name == that.chooseWHMoveProductsObj.name) {
                        obj2.product_id = item.product_id
                    }
                })
                obj2.quantity = this.chooseWHMoveProductsObj.num
                obj2.batch_id = this.ItemChooseBatchNameId
                this.form_add_WHMove.products.push(obj2)

            },

            // 关闭调拨产品标签数组方法
            handleClose_tags_add_WHMove(tag) {
                this.chooseWHMoveProductsData.splice(this.chooseWHMoveProductsData.indexOf(tag), 1)
                this.form_add_WHMove.products.splice(this.form_add_WHMove.products.indexOf(tag))
            },
            // 关闭报废产品标签数组方法
            handleClose_tags_add_WHScrap(tag) {
                this.form_add_WHScrap.products.splice(this.form_add_WHScrap.products.indexOf(tag), 1)
            },

            // 搜索调拨单方法
            async RKSearchWHMove() {
                if (this.chooseDateRangeWHMove == null) {
                    this.chooseDateRangeWHMove = []
                }
                this.DBgetInfo.start_date = this.chooseDateRangeWHMove[0]
                this.DBgetInfo.end_date = this.chooseDateRangeWHMove[1]
                this.openWarehouse()
                // let params=this.DBgetInfo
                // const res=await this.$https.post('/erp/transfers?_ajax=1')
                // if(res.status!==200)  return this.$message.error(res.statusText)
                // this.Product_WHMove_data=res.data.data
                // this.DBTotal=res.data.total
            },


            handleSizeChangeDBgetInfo(data) {
                this.DBgetInfo.per_page = data
                this.openWarehouse()
            },
            handleCurrentChangeDBgetInfo(data) {
                this.DBgetInfo.page = data
                this.openWarehouse()
            },


            //查看调拨单详情 
            async seeDBDetail(row) {
                this.is_WHMoveDetail = true
                let params = {
                    order_num: row.order_num
                }
                const res = await this.$https.post(erpApi + '/transfer/detail?_ajax=1', params)

                if (res.status !== 200) return this.$message.error(res.statusText)
                this.WHMoveDetail = res.data.stocks

            },

          





            chooseWHScrapProductFun(data) {
                this.WHMoveProductsData.forEach(item => {
                    if (item.product_id == data) {
                        this.chooseWHScrapProductMax = item.quantity
                    }
                })
            },




           



            // ----------------------采购搜索-------------------------------
            CGsearch() {

                if (this.choose_time == null) {
                    this.getPurchaseInfo.start_date = ''
                    this.getPurchaseInfo.end_date = ''
                    // 再次刷新
                    this.getPurchase()
                    return
                }

                //分别赋时间值
                let [startDate, endDate] = this.choose_time
                this.getPurchaseInfo.start_date = startDate
                this.getPurchaseInfo.end_date = endDate

                // 再次刷新
                this.getPurchase()

            },

            // ------------------------链接到入库-------------------------------------------
            DoWarehousing() {
                this.$router.push({
                    path: '/Warehousing'
                })
            },

          



            // ----------------------获取批次------------------------------------------------
            // 获取批次
            async getBatchInfo() {
                const res = await this.$https.post(erpApi + '/batch/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.BatchData = res.data
            },
            // 选择批次
            changeBatch(data) {
                this.BatchData.forEach(item => {
                    if (item.name == data) {
                        this.ItemChooseBatchNameId = item.id
                    }
                })
            },


            // -----------------------修改采购单--------------------------------------------------
            editPurchaseNote(row) {
                this.form__editPurchaseNote.order_num = row.order_num
                this.form__editPurchaseNote.remarks = row.remarks

                this.is_editPurchaseNote = true
            },

            async editPurchaseNoteDo() {
                let params = this.form__editPurchaseNote
                const {
                    data
                } = await this.$https.post(erpApi + '/plan/edit?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_editPurchaseNote = false
                this.getPurchase()
            },


            //----------------------- 删除采购单----------------------------------
            deletePurchase(row) {
               
                this.$confirm('您确定删除该采购单吗', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    .then(async () => {
                        let params = {
                            order_num: row.order_num
                        }
                        const {
                            data
                        } = await this.$https.post(erpApi + '/plan/delete?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)
                        this.getPurchase()

                    })
                    .catch(() => {
                        this.$message.error('您取消了按钮')
                    })
            },

            //----------------------- 删除采购单中的产品----------------------------------
            deletePurchase__Product(row){
                  this.$router.push({
                    path: '/editPurchase',
                    query:{
                        order_num:row.order_num,
                        remarks:row.remarks,
                        desc:'deletePurchaseProduct'
                    }
                })
            },

            //----------------------- 修改采购产品数量----------------------------------
            editPurchaseProduct__Num(row){
                 this.$router.push({
                    path: '/editPurchase',
                    query:{
                        order_num:row.order_num,
                        remarks:row.remarks,
                        desc:'editPurchaseProductNum'
                    }
                })
            },

            //----------------------- 增加采购产品----------------------------------
            addPurchase__Product(row){
                 this.$router.push({
                    path: '/addtoPurchase',
                    query:{
                        order_num:row.order_num,
                        remarks:row.remarks,
                        desc:'addPurchaseProduct'
                    }
                })
            },

            // 生成防伪码
            async createSecurityCode__All(){
                this.fullscreenLoading = true;
                let params={
                    order_num:this.currentSecurityCode_Ordernum
                }

                  const{data} = await this.$https.post(erpApi + '/plan/generate?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                if(data.error_code == 0){
                    this.fullscreenLoading = false;
                    this.$message.success(data.msg) 
                }
            },

            async createSecurityCode__Single(row){    
                this.fullscreenLoading = true;
                let params={
                    order_num:this.currentSecurityCode_Ordernum,
                    product_id:row.product_id
                }

                  const{data} = await this.$https.post(erpApi + '/plan/generate?_ajax=1', params)
                if (data.error_code !== 0) {
                    this.fullscreenLoading = false;
                    return this.$message.error(data.msg)
                }
                if(data.error_code == 0){
                    this.fullscreenLoading = false;
                    this.$message.success(data.msg) 
                }
                 
            },


             downLoadSecurityCode__Single(row){
                window.location.href='https://api.rainben.cn/erp/plan/down?_ajax=1&order_num='+this.currentSecurityCode_Ordernum+'&product_id='+row.product_id
            },







        },
    }
</script>

<style lang="less" scoped>
    #app {
        overflow: hidden;
    }

    h3 {
        font-size: 24px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        margin-bottom: 20px;
    }

    .el-breadcrumb {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .el-card {
        position: relative;
    }

    .purchasechoose {
        margin-bottom: 20px;
        padding: 6px 0px;
        display: flex;
        align-items: center;
        background-color: #f5f7fa;
        padding: 10px 20px;
    }



    .addpurchase {
        margin-left: 20px;
        margin-right: 10px;
    }


    .el-pagination {
        // margin-top: 30px;
    }

    .remarks {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 150px;
        text-align: center;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    /deep/.el-dialog__body {
        display: flex;
        flex-direction: column;
    }

   
    .chooseWareHouse {
        margin-top: 30px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        font-weight: 550;
        font-size: 18px;
        letter-spacing: 2px;
    }

     .purchaseRemark{
        margin-top: 30px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        color: #333;
        font-size: 16px;
      
     }



    .PDProduct {
        justify-content: start
    }

    .addProduct .el-select {
        margin-right: 20px;
    }

    /deep/.el-input .el-input-group {
        width: 280px;
        float: right
    }

    /deep/.el-input-group>.el-input__inner {}

    /deep/ .key_word {
        width: 300px;
    }



    .el-tag {
        margin-right: 20px;
        margin-bottom: 15px;
        // padding: 0 10px;
    }

    .setProductNumber {
        display: flex;
        align-items: center;
        justify-content: center;
    }




    .goodsCheck {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 500px;
    }

    .goodsCheckLeft {
        flex: 2;
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: #dfe9f5;
    }

    .goodsCheckRight {
        flex: 12;
        display: flex;
        align-items: flex-start;
        height: 100%;
        padding: 0;
        overflow-Y: auto;
        margin-left: 15px;
    }

    .goodsCheckRightContent .el-table {
        margin-top: 0;
    }

    .searchDo {
        margin-left: 20px;
        margin-right: 27%;
    }


    .delete {
        color: #f56c6c;
    }

    .el-card {
        height: 87vh;
    }

    .purchaseMore--Style {
        margin-left: 10px;
        cursor: pointer;
    }


   /* el-table 滚动条样式 */
/deep/.el-table__body-wrapper::-webkit-scrollbar{
    height: 8px;
    width: 6px;
 }

 

/deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

  .createSecurityCode--Style{
      width: 10%;
  }

</style>