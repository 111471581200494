<template>
    <div id="app">

        <!-- 布局 -->
        <div class="purchaseLayout">

            <!-- 面包屑导航 -->
            <!-- <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>采购单入库</el-breadcrumb-item>
            </el-breadcrumb> -->

            <el-card>

                <div class="btns">
                    <el-button class="reverseBtn backBtn" size="small" @click="goBack">返 回</el-button>

                </div>

                <div class="container">
                    <el-form :model="addForm" :rules="rules" ref="ruleForm" label-width="100px">

                        <el-form-item label="选择仓库:" prop="name">
                            <el-select v-model="addForm.store_id" clearable placeholder="请选择" size="small">
                                <el-option v-for="item in wareHouseData" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>

                          <el-form-item label="备注:">
                            <el-input type="textarea" v-model="addForm.remarks" placeholder="请输入内容"></el-input>
                        </el-form-item>


                        <div class="tableData">
                            <!-- 数据表格 -->
                            <el-table :data="OrderRKData" max-height="auto" style="width:100%" border
                                :header-cell-style="styleBindFun" @cell-dblclick="changeInputAndShow">

                                <el-table-column prop="sn" label="货号" align="center">
                                    <template slot-scope="scope">
                                        {{scope.row.sn}}
                                        <i v-if="scope.row.receive!==0"
                                            :class="scope.row.quantity==scope.row.receive?'iconfont icon-yiruku':'iconfont icon-bufenruku'"></i>
                                    </template>
                                </el-table-column>

                                <el-table-column label="品牌" align="center">
                                    <template slot-scope="scope">
                                        {{scope.row.brand_name}}
                                    </template>
                                </el-table-column>


                                <el-table-column label="产品" align="center" width="320">
                                    <template slot-scope="scope">
                                        {{scope.row.product_name}} <span
                                            v-show="scope.row.spec">（{{scope.row.spec}}）</span>
                                    </template>
                                </el-table-column>

                                <el-table-column label="应入库数量" align="center">
                                    <template slot-scope="scope">
                                        {{scope.row.quantity}} {{scope.row.unit_name}}
                                    </template>
                                </el-table-column>


                                <el-table-column label="已入库数量" align="center">
                                    <template slot-scope="scope">

                                        {{scope.row.receive}} {{scope.row.unit_name}}


                                    </template>
                                </el-table-column>


                                <el-table-column label="本次入库数量" align="center">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.quantity!==scope.row.receive">
                                            <el-input v-show="scope.row.showNum" size="small" v-model="scope.row.num">
                                            </el-input>
                                            <span v-show="!scope.row.showNum">
                                                {{scope.row.num}}
                                            </span>
                                        </span>
                                        <span v-else>
                                            <i>——</i>
                                        </span>

                                    </template>
                                </el-table-column>

                                <el-table-column label="批次" align="center">
                                    <template slot-scope="scope">


                                        <span v-if="scope.row.quantity!==scope.row.receive">
                                            <el-select v-model="scope.row.batchId" :disabled="!scope.row.is_batch"
                                                filterable remote  :remote-method="remoteMethod" 
                                                clearable placeholder="请选择批次" size="small">
                                                <el-option v-for="item in batchData" :key="item.id" :label="item.name"
                                                    :value="item.id">
                                                  {{item.name}}   — 限用日期至: {{item.expiry_date}}
                                                </el-option>
                                            </el-select>
                                        </span>
                                        <span v-else>
                                            <i>——</i>
                                        </span>

                                    </template>
                                </el-table-column>

                                <!-- <el-table-column label="操作" align="center">
                                    <template slot-scope="scope">
                                        <el-button :disabled="scope.row.quantity==scope.row.receive" type="text"
                                            size="medium" @click="setNum(scope.row)">设置数量</el-button>
                                    </template>
                                </el-table-column> -->



                            </el-table>
                        </div>

                      


                    </el-form>
                </div>




                <el-button class="frontBtn submitBtn" size="small" @click="submit">入 库</el-button>


            </el-card>

        </div>








    </div>
</template>

<script>
     import {
        erpApi
    } from '../../config'
    export default {
        data() {
            return {

                wareHouseData: this.$route.query.data,
                batchData: [],
                addForm: {
                    order_num: this.$route.query.order_num,
                    store_id: '',
                    product_list: [],
                    remarks: ''
                },
                rules: {
                    name: [{
                        required: true,
                        message: '请选择仓库',
                        trigger: 'blur'
                    }],
                },

                OrderRKData: [],













            }
        },
        created() {
            this.getBatch()
            this.getOrderDetail()
        },
        methods: {

            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind = "background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                return styleBind;
            },

            // 获取批次
            async getBatch() {
                const res = await this.$https.post(erpApi+'/batch/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.batchData = res.data
            },

            // 获取采购单详情数据
            async getOrderDetail() {
                let params = {
                    order_num: this.addForm.order_num
                }
                const res = await this.$https.post(erpApi+'/plan/detail?_ajax=1', params)
                console.log(res,'res');
                if (res.status !== 200) return this.$message.error(res.statusText)
                let data = res.data.products

                data.forEach(item => {
                    let obj = {}
                    obj.product_id = item.product_id
                    obj.sn = item.sn
                    obj.brand_name = item.brand_name
                    obj.product_name = item.product_name
                    obj.spec = item.spec
                    obj.quantity = item.quantity
                    obj.unit_name = item.unit_name
                    obj.receive = item.receive
                    obj.unit_name = item.unit_name
                    obj.is_batch = item.is_batch
                    obj.batchId = ''

                    obj.num = 0
                    obj.showNum = false

                    this.OrderRKData.push(obj)
                })
            },



            changeInputAndShow(row) {

                row.showNum = !row.showNum
            },

            setNum(row) {

                row.showNum = !row.showNum
            },


            async submit() {
                if (this.addForm.store_id == '') {
                    return this.$message.error('请选择仓库')
                }

                // 清空
                this.addForm.product_list = []

                this.OrderRKData.forEach(item => {
                    if (item.num !== 0) {
                        let obj = {}
                        obj.product_id = item.product_id
                        obj.quantity = item.num
                        obj.batch_id = item.batchId
                        this.addForm.product_list.push(obj)
                    }
                })

                let params = this.addForm

                const {
                    data
                } = await this.$https.post(erpApi+'/plan/receive?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)

                this.$router.push({
                    path: "/purchase"
                })
            },




            calc() {
                this.NumTotal = 0,
                    this.chooseData2.forEach(item => {
                        this.NumTotal += +item.num
                    })

                this.TypeTotal = this.chooseData2.length
            },

            
           async remoteMethod(data){
             
               setTimeout(async ()=>{
                   let params={
                   keyword:data
               }
                const 
                res = await this.$https.post(erpApi+'/batch/select?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
               this.batchData=res.data
               },300)

            },

          







            // 返回
            goBack() {
                this.$router.go(-1)
            },




        },
    }
</script>

<style lang="less" scoped>
    .btns {
        position: relative;
        display: flex;
        justify-content: end;
        padding-right: 5%;
    }

    .btnsDialog {
        display: flex;
        justify-content: start;
    }



    .backBtn {}

    /* --------------------------表单label--------------------------------- */
    /deep/ .el-form-item__label {
        font-size: 14px;
        color: #000000E6;
        font-weight: 540;

    }

    .inputClass {
        width: 20%;
    }

    .search {
        margin-left: 1%;
    }

    .el-card {
        height: 87vh;
    }

    .tableData {
        margin: 50px 0px;
    }


    .submitBtn {
        margin-left: 8%;
        // margin-top: 10px;
    }

    

    /* 图标样式 */
    .icon-yiruku,
    .icon-bufenruku {
        color: #409eff;
        font-size: 30px;
        margin-left: 5px;
    }
   .icon-bufenruku {
       color: #F56C6C;
   }
</style>