<template>
    <div id="app">
        <!-- 面包屑导航 -->
        <!-- <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统设置</el-breadcrumb-item>
            <el-breadcrumb-item>客户设置</el-breadcrumb-item>
            <el-breadcrumb-item>配送类型设置</el-breadcrumb-item>
        </el-breadcrumb> -->


        <el-card>

            <!--按钮区 -->

            <div class="DistributionSchemeBtns">
                <el-button @click="createDistributionType" icon="el-icon-plus" class="frontBtn" size="small">创建配送类型
                </el-button>
            </div>

            <el-table :data="DistributionTypeData" border style="width: 100%" :header-cell-style="styleBindFun">
                <el-table-column prop="name" label="配送类型名称" align="center">
                </el-table-column>
                <el-table-column label="赠送" align="center">
                    <template slot-scope="scope">
                        ￥{{scope.row.amount}}
                    </template>
                </el-table-column>
                <el-table-column label="转出" align="center">
                    <template slot-scope="scope">

                        <div v-if="scope.row.is_transfer" class="yesIcon el-icon-check"></div>
                        <div v-else class="noIcon el-icon-close"></div>
                    </template>
                </el-table-column>
                <el-table-column label="起订" align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.is_deliver_limit" class="yesIcon el-icon-check"></div>
                        <div v-else class="noIcon el-icon-close"></div>
                    </template>
                </el-table-column>

                <el-table-column label="依赖" align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.is_depend" class="yesIcon el-icon-check"></div>
                        <div v-else class="noIcon el-icon-close"></div>
                    </template>
                </el-table-column>
                <el-table-column label="商品" align="center">
                    <template slot-scope="scope">
                        {{scope.row.goods_list_count}}
                        <el-button @click="seeDistributionType(scope.row)" type="text" class="text_primary">查看
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="update_time" label="更新时间" align="center">
                    <template slot-scope="scope">
                        {{scope.row.update_time.trim().split(" ")[0]}}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" align="center">
                    <template slot-scope="scope">

                        <el-button @click="editDistributionType(scope.row)" type="text" class="text_primary">修改
                        </el-button>
                        <span class="splitLineClass"></span>
                        <el-button @click="deleteDistributionType(scope.row)" type="text" class="text_primary">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页器 -->
            <el-pagination @size-change="handleSizeChangePSLX" @current-change="handleCurrentChangePSLX"
                :current-page="getPSLXInfo.page" :page-sizes="[5,8,10,20]" :page-size="getPSLXInfo.per_page"
                layout="total, sizes, prev, pager, next, jumper" :total="getPSLXTotal" align="center">
            </el-pagination>
        </el-card>



        <!-- ---------------------------------------- 对话框第一层 --------------------------------------------------- -->
        <!-- 修改分组对话框 -->
        <!-- <el-dialog title="修改分组" :visible.sync="is_Groups_edit" width="35%">

            <el-form :model="Form_createGroups" :rules="GroupsRules" label-width="120px">
                <el-form-item label="分组名称" prop="name">
                    <el-input v-model="Form_createGroups.name"></el-input>
                </el-form-item>
              
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="is_Groups_edit = false">取 消</el-button>
                <el-button type="primary" @click="editGroupsDo">确 定</el-button>
            </div>
        </el-dialog> -->










        <!-- 编辑配送类型数据对话框 -->
        <!-- <el-dialog title="编辑配送类型" :visible.sync="is_DistributionType_edit">
            <el-form class="create" :model="Form_createDistributionType" :rules="DistributionTypeRules"
                label-width="150px">
                <el-form-item label="配送类型名称：" prop="name">
                    <el-input v-model="Form_createDistributionType.name"></el-input>
                </el-form-item>
                <el-form-item label="金额：" prop="amount">
                    <el-input v-model="Form_createDistributionType.amount" @blur="CNCapital"></el-input>
                    <span v-if="CNCapitalStated" style="margin-left:15px"> ({{CNCapitalPrice}})</span>
                </el-form-item>
                <el-form-item label="是否能转出:" prop="is_transfer">
                    <el-radio-group v-model="Form_createDistributionType.is_transfer">
                        <el-radio :label="0">否</el-radio>
                        <el-radio :label="1">是</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否限额:" prop="is_deliver_limit">
                    <el-radio-group v-model="Form_createDistributionType.is_deliver_limit">
                        <el-radio :label="0">否</el-radio>
                        <el-radio :label="1">是</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="商品选择:" prop="goodsList">
                    <el-tag style="margin-right:10px;margin-bottom:10px" v-for="item in this.choosedGoods"
                        :key="item.id" closable :disable-transitions="false" type="primary" effect="dark" size="big"
                        @close="handleClose(item)">
                        {{item.name}}
                    </el-tag>
                    <el-button type="text" size="big" @click="addGoods_of_Distribution">添加</el-button>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="is_DistributionType_edit = false">取 消</el-button>
                <el-button type="primary" @click="EditDistributionTypeDo">确 定</el-button>
            </div>
        </el-dialog> -->

        <!-- 查看配送类型详细数据对话框 -->
        <el-dialog title="配送类型详细清单" :visible.sync="is_DistributionTypeDetail" width="80%">

            <el-table :data="DistributionTypeDetail" border style="width: 100%" :header-cell-style="styleBindFun">
                <el-table-column prop="sn" label="商品货号" width="130" align="center">
                </el-table-column>
                <el-table-column prop="name" label="商品名称" align="center" width="260">
                </el-table-column>
                <el-table-column prop="category_full_name" label="商品名称" align="center" width="260">
                </el-table-column>
                <el-table-column label="单价" align="center">
                    <template slot-scope="scope">
                        ￥{{ scope.row.price }}
                    </template>
                </el-table-column>
                <el-table-column label="上架" align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.is_sell" class="yesIcon el-icon-check"></div>
                        <div v-else class="noIcon el-icon-close"></div>
                    </template>
                </el-table-column>
                <el-table-column label="退货" align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.is_return" class="yesIcon el-icon-check"></div>
                        <div v-else class="noIcon el-icon-close"></div>
                    </template>
                </el-table-column>
                <el-table-column label="一口价" align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.is_fixed" class="yesIcon el-icon-check"></div>
                        <div v-else class="noIcon el-icon-close"></div>
                    </template>
                </el-table-column>
                <el-table-column prop="sell_min_num" label="起订数量" align="center">

                </el-table-column>
                <el-table-column prop="sell_max_num" label="限购数量" align="center">

                </el-table-column>
                <el-table-column label="下架时间" align="center">
                    <template slot-scope="scope">
                        <span v-show="scope.row.end_time">
                            {{scope.row.end_time}}
                        </span>
                    </template>
                </el-table-column>


                <el-table-column prop="update_time" label="更新时间" align="center">
                    <template slot-scope="scope">
                        {{scope.row.create_time.trim().split(" ")[0]}}
                    </template>
                </el-table-column>

            </el-table>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" class="frontBtn" @click="is_DistributionTypeDetail = false">确 定</el-button>
            </div>
        </el-dialog>





    </div>
</template>

<script>
    import {
        adminApi
    } from '../../config'

    import {
        domainApi
    } from '../../config'
    export default {
        data() {
            return {
                // -----------------------获取配送类型列表数据--------------------------------------
                DistributionTypeData: [],
                getPSLXInfo: {
                    DistributionType_id: '',
                    page: 1,
                    per_page: 10
                },
                getPSLXTotal: 0,


                // ----------------------查看配送类型详细数据--------------------------------------
                DistributionTypeDetail: [],
                is_DistributionTypeDetail: false,


                // ----------------------创建配送类型数据--------------------------------------
                is_createDistributionType: false,



                // ----------------------编辑信用等级数据--------------------------------------
                is_DistributionType_edit: false,
                current_edit_id: "",



            }
        },
        created() {
            this.getDistributionType();
        },
       
        methods: {

          

            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind = "background:#f6f8fa;fontSize:14px;color:#333333;fontWeight: bold;"
                return styleBind;
            },
            // -----------------------获取配送类型列表数据--------------------------------------
            async getDistributionType() {
                delete this.getPSLXInfo.DistributionType_id
                let params = this.getPSLXInfo
                // const res = await this.$https.post('/admin/presents?_ajax=1', params)
                const res = await this.$https.post(adminApi + '/presents?_ajax=1', params)

                if (res.status !== 200) return this.$message.error(res.statusText)
                this.DistributionTypeData = res.data.data
                this.getPSLXTotal = res.data.total
            },

            handleSizeChangePSLX(data) {
                this.getPSLXInfo.per_page = data
                this.getDistributionType()
            },
            handleCurrentChangePSLX(data) {
                this.getPSLXInfo.page = data
                this.getDistributionType()
            },


            // ----------------------查看配送类型详细数据--------------------------------------
            async seeDistributionType(row) {

                this.is_DistributionTypeDetail = true
                let params = {
                    id: row.id
                }
                const res = await this.$https.post(adminApi + '/present/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.DistributionTypeDetail = res.data.goods_list
            },





            handleClose(item) {
                this.choosedGoods.splice(this.choosedGoods.indexOf(item), 1)
            },





            createDistributionType() {
                this.$router.push({
                    path: "/addDistributionType"
                })
                // this.is_createDistributionType = true

                // // 清空
                // this.Form_createDistributionType.name = ''
                // this.Form_createDistributionType.amount = ''
                // this.Form_createDistributionType.is_transfer = 0
                // this.Form_createDistributionType.is_deliver_limit = 0
                // this.Form_createDistributionType.goods_list = []
                // this.choosedGoods = []
            },

            CNCapital() {
                if (this.Form_createDistributionType.amount.length != 0) {
                    this.CNCapitalStated = true
                } else {
                    this.CNCapitalStated = false
                }
                this.CNCapitalPrice = this.numberParseChina(this.Form_createDistributionType.amount)
            },
            //阿拉伯数字转换成大写汉字
            numberParseChina(money) {
                //汉字的数字
                var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');
                //基本单位
                var cnIntRadice = new Array('', '拾', '佰', '仟');
                //对应整数部分扩展单位
                var cnIntUnits = new Array('', '万', '亿', '兆');
                //对应小数部分单位
                var cnDecUnits = new Array('角', '分', '毫', '厘');
                //整数金额时后面跟的字符
                var cnInteger = '整';
                //整型完以后的单位
                var cnIntLast = '圆';
                //最大处理的数字
                var maxNum = 999999999999999.9999;
                //金额整数部分
                var integerNum;
                //金额小数部分
                var decimalNum;
                //输出的中文金额字符串
                var chineseStr = '';
                //分离金额后用的数组，预定义
                var parts;
                if (money == '') {
                    return '';
                }
                money = parseFloat(money);
                if (money >= maxNum) {
                    //超出最大处理数字
                    return '';
                }
                if (money == 0) {
                    chineseStr = cnNums[0] + cnIntLast + cnInteger;
                    return chineseStr;
                }
                //转换为字符串
                money = money.toString();
                if (money.indexOf('.') == -1) {
                    integerNum = money;
                    decimalNum = '';
                } else {
                    parts = money.split('.');
                    integerNum = parts[0];
                    decimalNum = parts[1].substr(0, 4);
                }
                //获取整型部分转换
                if (parseInt(integerNum, 10) > 0) {
                    var zeroCount = 0;
                    var IntLen = integerNum.length;
                    for (var i = 0; i < IntLen; i++) {
                        var n = integerNum.substr(i, 1);
                        var p = IntLen - i - 1;
                        var q = p / 4;
                        var m = p % 4;
                        if (n == '0') {
                            zeroCount++;
                        } else {
                            if (zeroCount > 0) {
                                chineseStr += cnNums[0];
                            }
                            //归零
                            zeroCount = 0;
                            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
                        }
                        if (m == 0 && zeroCount < 4) {
                            chineseStr += cnIntUnits[q];
                        }
                    }
                    chineseStr += cnIntLast;
                }
                //小数部分
                if (decimalNum != '') {
                    var decLen = decimalNum.length;
                    for (var i = 0; i < decLen; i++) {
                        var n = decimalNum.substr(i, 1);
                        if (n != '0') {
                            chineseStr += cnNums[Number(n)] + cnDecUnits[i];
                        }
                    }
                }
                if (chineseStr == '') {
                    chineseStr += cnNums[0] + cnIntLast + cnInteger;
                } else if (decimalNum == '') {
                    chineseStr += cnInteger;
                }
                return chineseStr;
            },




            // ----------------------编辑分组数据--------------------------------------
            async editDistributionType(row) {
                this.$router.push({
                    path: '/editDistributionType',
                    query: {
                        data: row
                    }
                })
                // this.is_DistributionType_edit = true

                // let {
                //     id,
                //     name,
                //     amount,
                //     is_deliver_limit,
                //     is_transfer
                // } = row
                // this.Form_createDistributionType.name = name
                // this.Form_createDistributionType.amount = amount
                // this.Form_createDistributionType.is_transfer = is_transfer ? 1 : 0
                // this.Form_createDistributionType.is_deliver_limit = is_deliver_limit ? 1 : 0
                // // this.current_edit_id = id
                // let params1 = {
                //     id: id
                // }
                // const res = await this.$https.post('/admin/present/detail?_ajax=1', params1)
                // if (res.status !== 200) return this.$message.error(res.statusText)
                // let DistributionType = res.data
                // this.choosedGoods = []
                // DistributionType.goods_list.forEach(item => {
                //     this.choosedGoods.push(item)
                // })
                // this.Form_createDistributionType.id = id
                // this.CNCapital()
            },
            async EditDistributionTypeDo() {
                this.$confirm('确认修改这个配送类型吗?')
                    .then(async () => {
                        this.choosedGoods.forEach(item => {
                            this.Form_createDistributionType.goods_list.push(item.id)
                        })
                        let params = this.Form_createDistributionType
                        const {
                            data
                        } = await this.$https.post('/admin/present/edit?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)

                        this.$message.success(data.msg);
                        this.is_DistributionType_edit = false
                        this.Form_createDistributionType.goods_list = []
                        this.getDistributionType();
                    })
                    .catch(() => {
                        this.$message.info('您取消了按钮')
                    });
            },

            // ----------------------回收站数据--------------------------------------


            // ----------------------删除配送类型数据--------------------------------------
            async deleteDistributionType(row) {
                this.$confirm('确认删除这个配送类型吗?')
                    .then(async () => {
                        let params = {
                            id: row.id

                        }
                        const {
                            data
                        } = await this.$https.post(adminApi + '/present/delete?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)
                        this.getDistributionType()
                    })
                    .catch(() => {
                        this.$message.info('您取消了按钮')
                    });
            },
        },
    }
</script>

<style lang="less" scoped>
    .DistributionSchemeBtns {
        // background-color: #dfe9f5;
        padding: 6px 20px;
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        position: relative;
    }

    .el-input {
        width: 200px;
        margin-left: 20px;
    }

    .DistributionSchemeBtns span {
        width: 30%;
        display: flex;
        justify-content: start;
        align-items: center;
        margin: 7px 20px;
        //  background-color: pink;
    }


    .create {
        width: 100%;
        // background-color: pink;
    }
</style>