<template>
    <div id="app">
        <!-- 面包屑导航 -->
        <!-- <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>业务管理</el-breadcrumb-item>
            <el-breadcrumb-item>客户管理</el-breadcrumb-item>
            <el-breadcrumb-item>创建客户</el-breadcrumb-item>
        </el-breadcrumb> -->

        <el-card class="addNewCustomer" >
            <div class="title">修改{{Form_addNewCustomer.name}}:
            </div>
            <el-button class="goBack reverseBtn" @click="goBack" size="small">返回</el-button>
            <div>
                 <div class="baseTit">
                <!-- <i class="el-icon-caret-bottom baseTit_icon"></i> -->
                <span class="sRectangleStyle"></span>
                基本信息
            </div>
                <div class="item">
                    <span>

                        <el-form  label-position="top" label-width="80px">
                            <el-form-item label="名称：" >
                                <el-input disabled placeholder="请输入" size="small" v-model="Form_addNewCustomer.name">
                                </el-input>
                            </el-form-item>
                        </el-form>
                    </span>
                    <span>

                        <el-form :rules="rules" label-position="top" label-width="80px">
                            <el-form-item label="别名：" prop="value">
                                <el-input placeholder="请输入" size="small" v-model="Form_addNewCustomer.alias"></el-input>
                            </el-form-item>
                        </el-form>
                    </span>
                </div>

                <div class="item">
                    <span>

                        <el-form :rules="rules" label-position="top" label-width="80px">
                            <el-form-item label="联系人姓名：" prop="value"> 
                                <el-input v-model="Form_addNewCustomer.contact" placeholder="请输入" size="small">
                                </el-input>
                            </el-form-item>
                        </el-form>
                    </span>
                    <span>

                        <el-form :rules="rules" label-position="top" label-width="80px">
                            <el-form-item label="座机：" prop="value">
                                <el-input v-model="Form_addNewCustomer.telephone" placeholder="请输入" size="small">
                                </el-input>
                            </el-form-item>
                        </el-form>
                    </span>
                </div>

                <div class="item">

                     <span>

                        <el-form :rules="rules" label-position="top" label-width="80px">
                            <el-form-item label="选择区域：" prop="value">
                                <el-cascader class="choosed" :options="RegionData" clearable 
                                    v-model="initializedRegionIds" :props="{value:'id',label:'name'}" size="small">
                                </el-cascader>
                            </el-form-item>
                        </el-form>
                    </span>

                    <span>
                        <el-form :rules="rules" label-position="top" label-width="80px">
                            <el-form-item label="填写详细地址：" prop="value">
                                <el-input v-model="Form_addNewCustomer.address" placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-form>
                    </span>

                  

                
                </div>


                <div class="item">
                      <span>

                        <el-form :rules="rules" label-position="top" label-width="80px">
                            <el-form-item label="手机：" prop="value">
                                <el-input v-model="Form_addNewCustomer.mobile" placeholder="请输入" size="small">
                                </el-input>
                            </el-form-item>
                        </el-form>
                    </span>


                    <span>
                        <el-form :rules="rules" label-position="top" label-width="80px">
                            <el-form-item label="是否接收短信：" prop="value">
                                <el-radio-group v-model="Form_addNewCustomer.is_sms">
                                    <el-radio :label="0">无</el-radio>
                                    <el-radio :label="1">有</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-form>
                    </span>

                </div>

                  <div class="certificationTit">
                 <span class="sRectangleStyle"></span>
                资质信息
            </div>

                   <div class="item">
                    <span>

                        <el-form :rules="rules" label-position="top" label-width="80px">
                            <el-form-item label="合作开始日期（不能晚于今天）：" prop="value">
                                <el-date-picker v-model="Form_addNewCustomer.start_time" type="date" placeholder="选择日期"
                                    size="small" value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                        </el-form>
                    </span>
                    <span>

                        <el-form :rules="rules" label-position="top" label-width="80px">
                            <el-form-item label="合作结束日期（只能选一年，不能多选）：" prop="value">
                                <el-date-picker v-model="Form_addNewCustomer.end_time" type="date" placeholder="选择日期"
                                    size="small" value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                        </el-form>
                    </span>
                </div>

                  <div class="item">
                    
                    <span>

                        <el-form  :rules="rules" label-position="top" label-width="80px">
                            <el-form-item label="选择客户组：" prop="value">
                                <el-cascader  class="choosed" :options="GroupData" clearable
                                    v-model="initializedGroupIds" 
                                    :props="{value:'id',label:'name',checkStrictly: true }" size="small">
                                </el-cascader>
                            </el-form-item>
                        </el-form>
                    </span>
                    <span>

                        <el-form  :rules="rules" label-position="top" label-width="80px">
                            <el-form-item label="选择信用等级：" prop="value">
                                <el-select class="choosed" v-model="Form_addNewCustomer.credit_id" clearable
                                    placeholder="请选择" size="small">
                                    <el-option v-for="item in creditRatingData" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </span>
                   
                </div>


                <div class="item">




                    <span>

                        <el-form :rules="rules" label-position="top" label-width="80px">
                            <el-form-item label="选择折扣：" prop="value">
                                <el-select class="choosed" v-model="Form_addNewCustomer.discount_id" clearable
                                    placeholder="请选择" size="small">
                                    <el-option v-for="item in discountsData" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </span>
                    <span>

                        <el-form  :rules="rules" label-position="top" label-width="80px">
                            <el-form-item label="选择配送方案：" prop="value">
                                <el-select class="choosed" v-model="Form_addNewCustomer.plan_id" clearable
                                   placeholder="请选择" size="small">
                                    <el-option v-for="item in DistributionSchemeData" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </span>
                </div>




             


              
                <div class="item">

                        <span>
                        <el-form :rules="rules" label-position="top" label-width="80px">
                            <el-form-item label="选择起订额：" prop="value">
                                <el-select class="choosed" v-model="Form_addNewCustomer.deliver_id" clearable
                                      placeholder="请选择" size="small">
                                    <el-option v-for="item in deliverData" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </span>

                    

                    <span>
                        <el-form :rules="rules" label-position="top" label-width="80px">
                            <el-form-item label="备注：" prop="value">
                                <el-input type="textarea" v-model="Form_addNewCustomer.remarks" placeholder="请输入" size="small">
                                </el-input>
                            </el-form-item>
                        </el-form>
                    </span>
                </div>
            </div>

            <div class="btns">
                <el-button class="frontBtn" @click="submitDo" size="small">提交</el-button>
                <el-button class="reverseBtn" size="small">重置</el-button>
            </div>


        </el-card>




    </div>
</template>

<script>
    import {
        erpApi
    } from '../../config'

    export default {
        data() {
            return {

                infoData: [],
                // 新建客户表单数据
                Form_addNewCustomer: {
                    customer_number: this.$route.query.data,
                    name: '',
                    alias: '',
                    group_id: '',
                    credit_id: '',
                    discount_id: '',
                    deliver_id: '',
                    plan_id: '',
                    contact: '',
                    telephone: '',
                    mobile: '',
                    remarks: '',
                    start_time: '',
                    end_time: '',
                    sort_order: '',
                    region_id: '',
                    address: '',
                    is_sms: '',
                    sort_order: 100,
                },
                selectedGroupsIds: [],
                selectedRegionsIds: [],
                RegionsIds_children: [],
                RegionsIds_grandson: [],
                // 控制提取最后一个元素的阀门
                valve: true,
                RegionData: [],
                GroupData: [],
                groupdata: [],

                DistributionSchemeData: [],

                creditRatingData: [],

                discountsData: [],

                deliverData: [],
                checkedGroupData: [],

                initializedGroupIds: [],
                initializedRegionIds: [],

               



                // ConditionData: this.$route.query.data,
            }
        },
        created() {
            // this.splitConditionData()
            this.getRegion()
            this.getGroups()
            this.getCreditRating()
            this.getCustomerPeiSong()
            this.getCustomerDisCount()
            this.getDeliver()
            
        },
        mounted () {
        },
        methods: {

          

            async getInfo() {
                let params = {
                    customer_number: this.Form_addNewCustomer.customer_number
                }
                const res = await this.$https.post(erpApi + '/customer/detail?_ajax=1', params)
                this.infoData = res.data
                this.init(this.infoData)
            },

            init(data) {
                this.Form_addNewCustomer.alias = data.alias
                this.Form_addNewCustomer.name = data.name
                this.Form_addNewCustomer.credit_id = data.credit_id
                this.Form_addNewCustomer.discount_id = data.discount_id
                this.Form_addNewCustomer.deliver_id = data.deliver_id
                this.Form_addNewCustomer.plan_id = data.plan_id
                this.Form_addNewCustomer.contact = data.customer_contact
                this.Form_addNewCustomer.telephone = data.telephone
                this.Form_addNewCustomer.mobile = data.customer_mobile
                this.Form_addNewCustomer.start_time = data.start_time
                this.Form_addNewCustomer.end_time = data.end_time
                this.Form_addNewCustomer.sort_order = data.sort_order
                this.Form_addNewCustomer.address = data.address_info
                this.Form_addNewCustomer.is_sms = data.is_sms ? 1 : 0
                // 获取客户组
                let groupId = data.group_id
                this.initializedGroupIds = []
                console.log(this.GroupData,'GroupData');
                this.recursionGetGroupIds(this.GroupData, groupId)
                console.log(this.initializedGroupIds,'this.initializedGroupIds ');

                // 获取选择区域
                let  regionId=data.region_id
                this.initializedRegionIds=[]
                this.recursionGetRegionIds(this.RegionData,regionId)

            },

             recursionGetRegionIds(data, id) {
                data.forEach(node => {
                    if (node.id == id) {
                        if (node.parent_id !== 0) {
                            this.initializedRegionIds.unshift(node.id)
                            this.recursionGetRegionIds(this.RegionData, node.parent_id)
                        }else{
                            this.initializedRegionIds.unshift(node.id)
                        }
                    } else if (node.children) {
                        this.recursionGetRegionIds(node.children, id)
                    }
                })
            },




            recursionGetGroupIds(data, id) {
                data.forEach(node => {
                    if (node.id == id) {
                        if (node.parent_id !== 0) {
                            this.initializedGroupIds.unshift(node.id)
                            this.recursionGetGroupIds(this.GroupData, node.parent_id)
                        }else{
                            this.initializedGroupIds.unshift(node.id)
                        }
                    } else if (node.children) {
                        this.recursionGetGroupIds(node.children, id)
                    }
                })
            },



            // 
            async getDeliver() {
                const res = await this.$https.post(erpApi + '/deliver/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.deliverData = res.data
            },


            // 
            async getGroups() {
                const res = await this.$https.post(erpApi + '/group/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.GroupData = res.data
                this.getInfo()
            },

            // 
            async getCreditRating() {
                const res = await this.$https.post(erpApi + '/credit/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.creditRatingData = res.data
            },

            // 
            async getCustomerPeiSong() {
                const res = await this.$https.post(erpApi + '/plan/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.DistributionSchemeData = res.data

            },

            // 
            async getCustomerDisCount() {
                const res = await this.$https.post(erpApi + '/discount/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.discountsData = res.data

            },


            
            async getRegion() {
                const res = await this.$https.get(erpApi + '/region/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.RegionData = res.data
            },


           





            // 选择信用等级选择数据
            fnFocusCreditRating(){
                this.getCreditRating()
            },



            // 提交信息
            async submitDo() {

                // 判断
                if (this.Form_addNewCustomer.remarks == '') return this.$message.error('请填写备注，总得介绍下修改客户的原因')
                delete this.Form_addNewCustomer.name
                // 控制提取最后一个元素的阀门
                this.Form_addNewCustomer.region_id = ''
                this.Form_addNewCustomer.group_id = ''
                if (this.initializedRegionIds.length > 0) {
                    this.Form_addNewCustomer.region_id = this.initializedRegionIds[this.initializedRegionIds.length - 1]
                }
                if (this.initializedGroupIds.length > 0) {
                    this.Form_addNewCustomer.group_id = this.initializedGroupIds[this.initializedGroupIds.length - 1];
                }

                console.log(this.initializedGroupIds[this.initializedGroupIds.length - 1],'this.initializedGroupIds[this.initializedGroupIds.length - 1]');

                let params = this.Form_addNewCustomer
                console.log(params,'params1123');

                const {
                    data
                } = await this.$https.post(erpApi + '/customer/edit?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                // 返回
                this.$router.go(-1)
            },

            // 返回
            goBack() {
                this.$router.go(-1)
            }
        },
    }
</script>

<style lang="less" scoped>
    .addNewCustomer {
        padding: 10px 2%;
        position: relative;
    }

   
    .addNewCustomer .item {
        display: flex;
        justify-content: space-around;
        margin-bottom: 5px;
    }



    .addNewCustomer .item span {
        width: 36%;

    }

    .addNewCustomer .title {

        font-size: 20px;
        color: #333;
        margin-left: 6%;
        font-weight: 540;
        margin-bottom: 18px;
    }

    .detailAddress {
        width: 100%;

    }


    .goBack {
        position: absolute;
        right: 12%;
        top: 2%;
    }

    .btns {

        padding-left: 20%;
        margin-top: 40px;
        margin-bottom: 30px;
    }

    .choosed {
        width: 90%;
    }

     .certificationTit,
    .baseTit{
        margin-left: 6%;
        font-weight:bolder;
        font-size: 15px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
    }

    .certificationTit{
        margin-top: 20px;
        margin-bottom: 10px;
    }
</style>