<template>
    <div id="app">

        <!-- 布局 -->
        <div class="purchaseLayout">

            <!-- 面包屑导航 -->
            <!-- <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>采购入库</el-breadcrumb-item>
                <el-breadcrumb-item>产品调拨</el-breadcrumb-item>
                <el-breadcrumb-item>添加调拨单</el-breadcrumb-item>
            </el-breadcrumb> -->

            <el-card>

                <div class="btns">
                    <el-button class="reverseBtn backBtn" size="small" @click="goBack">返 回</el-button>

                </div>

                <div class="container">
                    <el-form :model="addForm" :rules="rules" ref="ruleForm" label-width="100px">
                        <el-form-item label="调出仓库：" prop="name" class="formItem">
                            <el-select v-model="addForm.transfer_store_id" clearable placeholder="请选择" size="small" @change="changeStore_fun">
                                <el-option v-for="item in wareHouseData" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="调入仓库：" prop="name">
                            <el-select v-model="addForm.receive_store_id" clearable placeholder="请选择" size="small">
                                <el-option v-for="item in wareHouseData" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="备注：">
                            <el-input size="mini" type="textarea" v-model="addForm.remarks" placeholder="请输入内容"></el-input>
                        </el-form-item>

                            <div class="addProduct--Style">
                               <el-button  size="mini" class="frontBtn" icon="el-icon-circle-plus"  @click="addPro">添加产品</el-button>
                            </div>


                        <!-- 数据表格 -->
                        <el-table :data="chooseData2" max-height="auto" style="width:100%" border height="400"
                            :header-cell-style="styleBindFun">

                            <el-table-column prop="sn" label="货号" align="center">
                            </el-table-column>

                          

                            <el-table-column label="产品" align="center" width="390">
                                <template slot-scope="scope">
                                    {{scope.row.product_name}} <span v-show="scope.row.spec">（{{scope.row.spec}}）</span>
                                </template>
                            </el-table-column>

                            <el-table-column label="当前库存" align="center">
                                <template slot-scope="scope">
                                    {{scope.row.quantity}} {{scope.row.unit_name}}
                                </template>
                            </el-table-column>


                            <el-table-column label="调拨数量" align="center">
                                <template slot-scope="scope">
                                    <el-input  size="small" clearable v-model="scope.row.num" @blur="allotNumFun(scope.row)">
                                    </el-input>
                                   
                                </template>
                            </el-table-column>

                            <el-table-column label="批次" align="center">
                                <template slot-scope="scope">
                                    <el-select v-model="scope.row.batchId" :disabled="!scope.row.is_batch" clearable @change="selectedBatchFun"
                                        placeholder="请选择批次" size="mini">
                                        <el-option v-for="item in scope.row.batchs" :key="item.id" :label="item.name"
                                            :value="item.id">
                                            {{item.name}} <span class="validityDateStyle">限用日期：{{item.expiry_date}} 本批次数量：<span class="NumberNoLeft">{{item.pivot.quantity}}</span></span>
                                        </el-option>
                                    </el-select>

                                </template>
                            </el-table-column>

                              <el-table-column label="操作" align="center">
                                <template slot-scope="scope">
                                   <el-button type="text" class="text_delete" @click="deleteItem(scope.row)">删除</el-button>
                                   <el-button type="text" class="text_primary" @click="copyItem(scope.row)">复制</el-button>
                                </template>
                            </el-table-column>

                          


                        </el-table>

                        <div class="containnumber">
                             产品种类: <span class="Number">{{TypeTotal}}</span>
                            <span >总调拨量:
                                <span class="Number"> {{NumTotal}}</span>
                            </span>
                        </div>
      


                  


                    </el-form>
                </div>






                <el-button class="frontBtn submitBtn" size="small" @click="submit">创 建 调 拨</el-button>
            </el-card>

        </div>


        <!-- 查看产品详情对话框 -->
        <el-dialog title="查看产品详情" :visible.sync="is_addAllocation" width="70%" class="addGoods">
            <div class="btnsDialog">
                <el-input placeholder="请输入内容" v-model="getProductInfo.keyword" clearable class="inputClass"
                    size="small">
                </el-input>
                <el-button size="small" class="frontBtn search" @click="searchBtn">搜 索</el-button>
            </div>
            <!-- 数据表格 -->


            <el-table tooltip-effect="dark" style="width: 100%" ref="multipleTable" :data="addProductData"
                :header-cell-style="styleBindFun"
                align="center" border>

                <el-table-column prop="sn" label="货号" align="center">
                </el-table-column>


                <el-table-column label="产品" align="center" width="390">
                    <template slot-scope="scope">
                        {{scope.row.product_name}} <span v-show="scope.row.spec">（{{scope.row.spec}}）</span>
                    </template>
                </el-table-column>

                <el-table-column label="当前库存" align="center">
                    <template slot-scope="scope">
                        {{scope.row.quantity}} {{scope.row.unit_name}}
                    </template>
                </el-table-column>

                <el-table-column label="仓库" align="center">
                    <template slot-scope="scope">
                        {{scope.row.store_name  }}
                    </template>
                </el-table-column>

                   <el-table-column label="批次" align="center">
                    <template slot-scope="scope">
                        {{scope.row.batchs.length}}
                    </template>
                </el-table-column>



                 <el-table-column label="操作" fixed="right" align="center">
                    <template slot-scope="scope">
                       <el-button @click="add(scope.row)" type="text" :disabled="scope.row.myChecked" size="medium">添加</el-button>
                    </template>
                </el-table-column>

            </el-table>

            <!-- 分页器 -->
            <el-pagination @current-change="handleCurrentChangeList" :current-page="getProductInfo.page"
                :page-size="getProductInfo.per_page" layout="total, prev, pager, next, jumper" :total="addProTotal"
                align="center">
            </el-pagination>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" class="frontBtn" @click="is_addAllocation=false">确 定</el-button>
            </div>
        </el-dialog>


     


    </div>
</template>

<script>
    import {
        erpApi
    } from '../../config'

    export default {
        data() {
            return {

                // wareHouseData: this.$route.query.data,
                wareHouseData: [],
                batchData: [],
                addForm: {
                    transfer_store_id: '',
                    receive_store_id: '',
                    product_list: [],
                    remarks: ''
                },
                rules: {
                    name: [{
                        required: true,
                        message: '请选择仓库名称',
                        trigger: 'blur'
                    }],
                },

                is_addAllocation: false,
                getProductInfo: {
                    page: 1,
                    per_page: 6,
                    store_id: '',
                    keyword: '',
                },
                addProductData: [],
                addProTotal: 0,

                chooseData1: [],
                chooseData2: [],

                TypeTotal: 0,
                NumTotal: 0,
                // 选择批次
                is_chooseBath: false,
                choose__batch__data: [],
                current_choose__batch__name:'',
                current_choose__batch:'',
                choose__batch__product_id:'',


                rowsData:[],




            }
        },
        created() {
            this.getBatch()
            this.getWareHouseInfo()
        },

        methods: {

            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind = "background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                return styleBind;
            },

            // 获取仓库方法
            async getWareHouseInfo() {
                const res = await this.$https.post(erpApi + '/store/select?_ajax=1')

                if (res.status !== 200) return this.$message.error(res.statusText)
                this.wareHouseData = res.data
            },

            // 获取批次
            async getBatch() {
                const res = await this.$https.post(erpApi + '/batch/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.batchData = res.data
            },

            //  添加方法
            async addPro() {
                if (this.addForm.transfer_store_id == '') {
                    return this.$message.error('请选择调出仓库名称')
                }
                this.is_addAllocation = true
                this.getData()

            },

            async getData() {
                this.getProductInfo.store_id = this.addForm.transfer_store_id

                let params = this.getProductInfo


                const res = await this.$https.post(erpApi + '/storage/product/select?_ajax=1', params)
                console.log(res,'res123456');
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.addProductData = res.data.data
                this.addProTotal = res.data.total

                this.echo()

            },


            echo(){
                this.chooseData2.forEach(node=>{
                    this.addProductData.forEach(ele=>{
                        if(node.product_id == ele.product_id){
                            ele.myChecked=true
                        }
                    })
                })
            },

          

           

            handleCurrentChangeList(data) {
                this.getProductInfo.page = data
                this.getData()
            },

            searchBtn() {
                this.getData()
            },

            calc() {
                this.NumTotal = 0,
                    this.chooseData2.forEach(item => {
                        this.NumTotal += +item.num
                    })

                this.TypeTotal = this.chooseData2.length
            },


            deleteItem(row){
                  // 删除
           
               let i= this.chooseData2.indexOf(row)
               this.chooseData2.splice(i,1)
                this.calc()
            },


            copyItem(row){
                  this.chooseData2.push(row)
                this.chooseData2 = JSON.parse(JSON.stringify(this.chooseData2))
                this.calc()

            },



            add(row){
                console.log(row,'row13121');
                row.myChecked=true
            //    
               
                   let obj={
                        is_batch:row.is_batch,
                        myChecked:row.myChecked, 
                        product_id:row.product_id, 
                        product_name:row.product_name, 
                        quantity:row.quantity, 
                        sn:row.sn, 
                        spec:row.spec, 
                        store_name:row.store_name, 
                        unit_name:row.unit_name, 
                        batchs:row.batchs, 
                        num:0
                   }
                   this.chooseData2.push(obj)
               

                this.calc()

            },


            async submit() {

                this.addForm.product_list = []
                this.chooseData2.forEach(item => {
                    let obj = {}
                    let {
                        product_id,
                        num,
                        batchId
                    } = item
                    obj.product_id = product_id
                    obj.quantity = num
                    obj.batch_id = batchId?batchId:0
                    this.addForm.product_list.push(obj)
                })

                let params = this.addForm

                const {
                    data
                } = await this.$https.post(erpApi + '/transfer/create?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.$router.push({
                    path: '/allocation'
                })
            },


            selectedBatchFun(data){
               
            },

            allotNumFun(data){
                this.calc()
            },

            changeStore_fun(){
                this.chooseData2=[]
            },

          

            // 返回
            goBack() {
                this.$router.go(-1)
            },




        },
    }
</script>

<style lang="less" scoped>
    .btns {
        position: relative;
        display: flex;
        justify-content: end;
        padding-right: 5%;
    }

    .btnsDialog {
        display: flex;
        justify-content: start;
    }



    .backBtn {}

    /* --------------------------表单label--------------------------------- */
    /deep/ .el-form-item__label {
        font-size: 14px;
        color: #000000E6;
        font-weight: 540;

    }

    .inputClass {
        width: 20%;
    }

    .search {
        margin-left: 1%;
    }

    .el-card {
        height: 87vh;
    }

    .summary {
        display: flex;
        justify-content: end;
        font-size: 16px;
        color: #1d2129;
        font-weight: 600;
        margin: 18px 0;
        margin-right: 5%;

    }

    .submitBtn {
        margin-left: 8%;
        // margin-top: 10px;
    }

    .validityDateStyle {
        font-size: 12px;
        margin-left: 10px;
    }

    .addProduct--Style{
        padding-left: 5px;
        margin-top: 30px;
    }


     .containnumber {
        font-size: 15px;
        color: #1d2129;
        padding: 10px;
        padding-left: 83%;
        font-weight: 600;
        // background-color: #f6f8fb;
    }

     .containnumber span:nth-child(2){
         margin-left:30px;
}

         /* el-table 滚动条样式 */
div /deep/.el-table__body-wrapper::-webkit-scrollbar{
    height: 8px;
    width: 6px;
 }

 

div /deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

    
</style>