<template>
  <div id="app">
    <!-- 布局 -->
    <div class="unitSet">
      <div class="unitSet_left">


        <!-- 卡片视图区域 -->
        <el-card class="card_left">
          <div>
            <el-button size="small" class="frontBtn" @click="dialogFormVisibleOfadd = true" icon="el-icon-circle-plus">
              添加单位</el-button>
          </div>

          <el-table :data="tableData" max-height="auto" style="width:100%" :header-cell-style="styleBindFun">
            <el-table-column prop="name" label="单位" align="center" width="200">
            </el-table-column>

            <el-table-column prop="update_time" label="修改时间" align="center" width="200">
            </el-table-column>

            <el-table-column fixed="right" label="操作" width="220" align="center">
              <template slot-scope="scope">
                <el-button @click="editUnit(scope.row)" class="text_primary" type="text" size="mini">编辑</el-button>
                <span class="splitLineClass"></span>
                <el-button @click="deleteUnit(scope.row)" class="text_delete" type="text" size="mini">删除</el-button>
              </template>
            </el-table-column>
          </el-table>

        </el-card>
      </div>


    </div>

    <el-dialog title="增加单位" :visible.sync="dialogFormVisibleOfadd" width="600px">
      <el-form :model="form">
        <el-form-item label="请输入要增加的单位名称：" :label-width="formLabelWidth">
          <el-input size="small" v-model="addUnitForm.unit_name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogFormVisibleOfadd = false">取 消</el-button>
        <el-button size="mini" class="frontBtn" @click="addUnit">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="修改单位" :visible.sync="dialogFormVisibleOfedit" width="600px">
      <el-form :model="form">
        <el-form-item label="当前要修改的单位名称：" :label-width="formLabelWidth">
          {{currentEditUnit}}
        </el-form-item>
        <el-form-item label="请输入要修改的单位名称：" :label-width="formLabelWidth">
          <el-input size="small" v-model="editUnitForm.unit_name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogFormVisibleOfedit = false">取 消</el-button>
        <el-button size="mini" class="frontBtn" @click="editLaunchUnit">确 定</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>
  import {
    adminApi
  } from '../../config'


  import {
    domainApi
  } from '../../config'
  export default {
    data() {
      return {
        tableData: [], //单位数据
        unitadd: '',
        currentEditUnit: '', //当前要编辑的单位
        currentEditId: 0, //当前要编辑的单位的id
        currentDeleteUnit: '', //当前要删除的单位
        currentDeleteId: '', //当前要删除的单位Id
        currentsortOrder: '',
        dialogFormVisibleOfadd: false, //控制添加单位弹出框
        dialogFormVisibleOfedit: false, //控制编辑单位弹出框
        dialogFormVisibleOfdelete: false, //控制删除单位弹出框
        form: {
          unit_name: ''
        }, //
        addUnitForm: {
          unit_name: ''
        }, //添加框的输入值
        editUnitForm: {
          unit_name: 'dami'
        }, //编辑框的输入值
        formLabelWidth: '200px' //表单宽度
      }
    },
    created() {

      this.getUnit();

    },

  
    methods: {
    
      styleBindFun() {
        // let  styleBind="background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
        let styleBind = "background:#f6f8fa;fontSize:14px;color:#333333;fontWeight: bold;"
        return styleBind;
      },

      // 获取正常使用单位数据
      async getUnit() {
        // 发送请求获取单位属性数据
        const res = await this.$https.post(adminApi + '/units?_ajax=1')
        if (res.status !== 200) {
          return this.$message.error(res.statusText)
        }
        this.tableData = res.data
      },

      // 增加
      async addUnit() {
        // 关闭添加弹出框
        this.dialogFormVisibleOfadd = false
        // 发送请求增加单位属性数据
        const res = await this.$https.post(adminApi + '/unit/create?_ajax=1', {
          name: this.addUnitForm.unit_name,
          sort_order: 100
        });

        if (res.data.error_code !== 0) {
          return this.$message.error('添加失败:' + res.msg);
          // 重置 
          this.addUnitForm.unit_name = ''
        } else {
          // 重置 
          this.addUnitForm.unit_name = ''
          this.$message.success('添加成功')
          // 重新调用
          this.getUnit()

        }
      },
      // 编辑
      editUnit(row) {
        // 重置输入值
        this.editUnitForm.unit_name = ''
        //  1.打开弹窗
        this.dialogFormVisibleOfedit = true
        //  2.把当前值显示到弹窗里
        this.currentEditUnit = row.name
        this.currentEditId = row.id
        this.currentsortOrder = row.sort_order
      },
      // 编辑完发送
      async editLaunchUnit() {
        // 关闭弹窗
        this.dialogFormVisibleOfedit = false
        // 参数
        let params = {
          id: this.currentEditId,
          name: this.editUnitForm.unit_name,
          sort_order: this.currentsortOrder
        }
        // 3. 点击确定发送修改数据
        const res = await this.$https.post(adminApi + '/unit/edit?_ajax=1', params)

        if (res.data.error_code !== 0) {
          return this.$message.error('修改失败:' + res.data.msg)
        }
        this.$message.success('修改成功')
        //   // 重新调用
        this.getUnit()
        // }
      },



      deleteUnit(row) {
        this.$confirm('您确定删除该单位吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
          .then(async () => {
            let params = {
              id: row.id
            }
            const res = await this.$https.post(adminApi + '/unit/delete?_ajax=1', params)

            if (res.data.error_code !== 0) {
              return this.$message.error(res.data.msg)
            } else {
              this.$message.success(res.data.msg)
              this.getUnit()
            }
          })
          .catch(() => {
            this.$message.error('您取消了按钮')
          })

      },

    },

  }
</script>

<style lang="less" scoped>
  #app {
    overflow: hidden;
  }



  .card_left {
    width: 40%;
    padding: 10px;
    height: auto;
    max-height: auto;
    overflow-y: auto;
  }

  .card_left div:nth-child(1) {
    padding-left: 80%;
  }

  ul,
  li {
    text-decoration: none;
    list-style: none;
  }

  #app {
    position: relative;
  }



  .unitSet {
    display: flex;
    width: 100%;
  }

  .unitSet_left {
    flex: 1;
  }

  .unitSet_right {
    flex: 1;
    margin-top: 50px;

  }

  .card_right {
    margin-top: 20px;
    width: 55%;
  }

  .unitSet_right .word {
    color: #606266;
    font-weight: 500;
  }



  .icon-huishouzhan {
    font-size: 20px;
    color: #606266;
    margin-right: 10px;
  }


  .recycle_bin {
    // position: absolute;
    // left: 500px;
  }

  .recycle_card {
    margin-top: 20px;
  }

  /* el-table */
  div /deep/.el-table__body .el-table__row .el-table__cell {
    padding: 10px
  }
</style>