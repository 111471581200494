<template>
    <div id="app">

        <!-- 布局 -->
        <div class="purchaseLayout">


            <!-- 面包屑导航 -->
            <!-- <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>采购入库</el-breadcrumb-item>
                <el-breadcrumb-item>批次管理</el-breadcrumb-item>
            </el-breadcrumb> -->

            <el-card>

                <div class="btns">
                </div>

                <div class="functionArea">
                    <span class="searchBtn">
                        搜索：
                        <el-input class="search" clearable size="small" v-model="getBatchManageInfo.keyword"
                            placeholder="支持批次名称/批次代码" @keyup.enter.native="getBatchManage"></el-input>
                    </span>

                    <span>
                        <el-button size="mini" class="frontBtn addBatchStyle" @click="addBatch">添加产品批次</el-button>
                    </span>
                    <el-button size="small" class="reverseBtn back" @click="goBack">返 回</el-button>
                </div>





                <!-- 数据表格 -->
                <el-table :data="batchManageData" :header-cell-style="styleBindFun" max-height="auto" style="width:100%"
                    border>

                    <el-table-column prop="sn" label="批次代码" align="center">
                    </el-table-column>

                    <el-table-column prop="name" label="批次名称" align="center">
                    </el-table-column>

                    <el-table-column prop="expiry_date" label="限用日期" align="center">
                    </el-table-column>

                    <el-table-column prop="is_show" label="是否展示" align="center">
                        <template slot-scope="scope">
                            <el-button type="success" size="mini" v-if="scope.row.is_show" icon="el-icon-check" circle>
                            </el-button>
                            <el-button type="danger" size="mini" v-else icon="el-icon-close" circle></el-button>
                        </template>
                    </el-table-column>

                     <el-table-column prop="is_show" label="产品个数" align="center">
                        <template slot-scope="scope">
                            {{scope.row.products_count}}
                             <el-button @click="seeDetail(scope.row)" type="text" class="textBtnColor" >查看
                            </el-button>
                        </template>
                    </el-table-column>


                    <el-table-column fixed="right" label="操作" align="center">
                        <template slot-scope="scope">
                          
                              <el-button @click="editBatch(scope.row)" type="text" class="textBtnColor" size="medium">修改
                            </el-button>
                            <span class="splitLineClass"></span>
                            <el-button @click="updateBatch(scope.row)" type="text" class="textBtnColor" size="medium">设置状态
                            </el-button>

                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页器 -->
                <el-pagination  @current-change="handleCurrentChange"
                    :current-page="getBatchManageInfo.page" :page-sizes="[9]"
                    :page-size="getBatchManageInfo.per_page" layout="total, sizes, prev, pager, next, jumper"
                    :total="total" align="center">
                </el-pagination>
            </el-card>






            <!-- 添加产品批次对话框 -->
            <el-dialog title="添加产品批次" :visible.sync="is_addBatch" width="28%" class="addBatch">

                <el-form :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="批次名称:" prop="name">
                        <el-input size="medium" v-model="ruleForm.name"></el-input>
                    </el-form-item>

                    <el-form-item label="批次代码:" prop="sn">
                        <el-input size="medium" v-model="ruleForm.sn"></el-input>
                    </el-form-item>

                    <el-form-item label="限用日期:" prop="expiry_date">
                        <el-date-picker v-model="ruleForm.expiry_date" value-format="yyyy-MM-dd" type="date"
                            placeholder="选择日期时间">
                        </el-date-picker>
                    </el-form-item>

                </el-form>




                <div slot="footer" class="dialog-footer">
                    <el-button  size="mini" @click="is_addBatch = false">取 消</el-button>
                    <el-button size="mini" class="frontBtn"  @click="addBatchDo">确 定</el-button>
                </div>
            </el-dialog>


               <!-- 修改产品批次对话框 -->
            <el-dialog title="修改产品批次" :visible.sync="is_editBatch" width="28%" class="addBatch">

                <el-form :model="editForm" :rules="rules" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="批次名称:" prop="name">
                        <el-input size="medium" v-model="editForm.name"></el-input>
                    </el-form-item>

                    <el-form-item label="货号:" prop="sn">
                        <el-input size="medium" v-model="editForm.sn"></el-input>
                    </el-form-item>

                    <el-form-item label="限用日期:" prop="expiry_date">
                        <el-date-picker v-model="editForm.expiry_date" value-format="yyyy-MM-dd" type="date"
                            placeholder="选择日期时间">
                        </el-date-picker>
                    </el-form-item>
                </el-form>

                <div slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="is_editBatch = false">取 消</el-button>
                    <el-button size="mini" class="frontBtn" @click="editBatchDo">确 定</el-button>
                </div>
            </el-dialog>




            <!-- 查看批次详情对话框 -->
            <el-dialog title="查看批次详情" :visible.sync="is_seeBartch" width="60%" class="seebatchDetailStyle">
                <!-- 标题 -->
                <div class="title">
                    产品总数量：{{batchDetailData.products_sum}}
                </div>

                <!-- 数据表格 -->
                <el-table :data="batchDetailData.products" :header-cell-style="styleBindFun" max-height="auto" style="width:100%"
                    border>

                    <el-table-column prop="product_sn" label="产品货号" align="center">
                    </el-table-column>

                    <el-table-column prop="name" label="名称" align="center" width="300">
                        <template slot-scope="scope">
                            {{scope.row.product_name}} <span v-show="scope.row.product_spec!==''">(
                                {{scope.row.product_spec}} )</span> 
                        </template>
                    </el-table-column>

                    <el-table-column prop="store_name" label="存放仓库" align="center">
                    </el-table-column>

                    <el-table-column prop="quantity" label="数量" align="center">
                    </el-table-column>

                    <el-table-column label="更新时间" align="center">
                        <template slot-scope="scope">
                            {{scope.row.update_time.trim().split(" ")[0]}}
                        </template>
                    </el-table-column>

                </el-table> 

                <div slot="footer" class="dialog-footer">
                    <el-button size="mini" class="frontBtn" @click="is_seeBartch = false">确 定</el-button>
                </div>
            </el-dialog>




        </div>

    </div>
</template>

<script>

     import {
        erpApi
    } from '../../config'

    export default {
        data() {
            return {
                
                getBatchManageInfo: {
                    page: 1,
                    per_page: 9,
                    keyword: ''
                },
                total: 0,
                batchManageData: [],
                is_addBatch: false,
                ruleForm: {
                    name: '',
                    sn: '',
                    expiry_date: ''
                },
                rules: {
                    name: {
                        required: true,
                        message: '请填写名称',
                        trigger: 'blur'
                    },
                    sn: {
                        required: true,
                        message: '请填货号',
                        trigger: 'blur'
                    },
                    expiry_date: {
                        required: true,
                        message: '请设置限用日期',
                        trigger: 'blur'
                    },
                },

                is_seeBartch: false,
                is_editBatch:false,
                batchDetailData: [],
                editForm: {
                    id:'',
                    name: '',
                    sn: '',
                    expiry_date: ''
                },


            }
        },
        created() {
            this.getBatchManage()
        },
        methods: {

            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind = "background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                return styleBind;
            },




            //   获取数据
            async getBatchManage() {
                let params = this.getBatchManageInfo
                const res = await this.$https.post(erpApi + '/batchs?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.batchManageData = res.data.data
                this.total = res.data.total
            },
          
            handleCurrentChange(data) {
                this.getBatchManageInfo.page = data
                this.getBatchManage()
            },

            // 添加
            addBatch() {
                this.is_addBatch = true
                for (let i in this.ruleForm) {
                    this.ruleForm[i] = ''
                }
            },
            async addBatchDo() {

                for (let k in this.ruleForm) {
                    if (this.ruleForm[k] == '') {
                        return this.$message.error('请把表单填写完整')
                    }
                }
                let params = this.ruleForm
                const {
                    data
                } = await this.$https.post(erpApi +'/batch/create?_ajax=1', params)

                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success('添加批次成功！')
                this.is_addBatch = false
                this.getBatchManage()
            },


            // --- --------------------------查看详情-----------------------------------
            async seeDetail(row) {
                this.is_seeBartch = true
                let params = {
                    id: row.id
                }
                const {
                    data
                } = await this.$https.post(erpApi+ '/batch/detail?_ajax=1', params)

                this.batchDetailData=data
            },

            // ------------------------------更新详情---------------------------
            async updateBatch(row) {

                let params = {
                    id: row.id
                }

                const {data} = await this.$https.post(erpApi + '/batch/set_status?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.getBatchManage()
            },

            goBack() {
                this.$router.go(-1)
            },

            editBatch(row){
                this.is_editBatch=true
                this.editForm.id=row.id
                this.editForm.name=row.name
                this.editForm.sn=row.sn
                this.editForm.expiry_date=row.expiry_date
            },


            async editBatchDo(){
                let params=this.editForm
                const {data} = await this.$https.post(erpApi + '/batch/edit?_ajax=1', params)
                 if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_editBatch=false
                this.getBatchManage()
          },




        },
    }
</script>

<style lang="less" scoped>
    
    .back {
        position: absolute;
        right: 5%;
        top: 50%;
        transform: translateY(-50%);
    }

    .el-card {
        height: 87vh;
    }

    .functionArea {
        background-color: #f5f7fa;
        border-radius: 5px;
        padding: 10px 20px;
        position: relative;
    }

    .search {
        width: 15%;
    }

    .searchBtn {
        margin-left: 15px;
        margin-right: 30px;
        color: #333;
        font-size: 14px;
    }

    .addBatchStyle {}


    .seebatchDetailStyle .title{
        font-size: 17px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        color: #000;
        margin-bottom: 5px;
    }

    /deep/.seebatchDetailStyle  .el-dialog__body{
        display: flex;
        flex-direction: column;
    }


    /deep/.functionArea span .el-input__inner {
        border: 1px solid #eee;
    }
</style>