<template>
    <div>

        <el-card>
            <div class="title_style">请修改创建配送类型表单:</div>
            <div class="Back">
                <el-button size="small" class="reverseBtn" @click="goback"> 返 回</el-button>
            </div>


            <el-form class="create" :model="Form" :rules="Rules" label-width="170px">
                <el-form-item label="配送类型名称：" prop="name">
                    <el-input v-model="Form.name" class="numInput" size="small"></el-input>
                </el-form-item>
                <el-form-item label="金额：" prop="amount">
                    <el-input v-model="Form.amount" @blur="CNCapital" class="numInput" size="small"></el-input>
                    <span v-if="CNCapitalStated" style="margin-left:15px"> 人民币（大写）：{{CNCapitalPrice}}</span>
                </el-form-item>
                <el-form-item label="是否能转出:" prop="is_transfer">
                    <el-radio-group v-model="Form.is_transfer">
                        <el-radio :label="0">否</el-radio>
                        <el-radio :label="1">是</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否有起订量:" prop="is_deliver_limit">
                    <el-radio-group v-model="Form.is_deliver_limit">
                        <el-radio :label="0">否</el-radio>
                        <el-radio :label="1">是</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否依赖其他如账单:" prop="is_depend">
                    <el-radio-group v-model="Form.is_depend">
                        <el-radio :label="0">否</el-radio>
                        <el-radio :label="1">是</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="排序:" prop="sort_order">
                    <el-input class="sort_order_input" v-model="Form.sort_order" clearable size="small"
                        placeholder="默认100，范围0~200"></el-input>
                </el-form-item>
                <el-form-item label="商品选择:" prop="goodsList">
                    <div class="condition_style">
                        <span>
                            是否销售：
                            <el-select @change="search" v-model="getDistributionTypeAddProInfo.is_sell" clearable
                                placeholder="请选择" size="mini">
                                <el-option v-for="item in is_sell_state" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </span>

                        <span>
                            是否销售：
                            <el-select @change="search" v-model="getDistributionTypeAddProInfo.is_return" clearable
                                placeholder="请选择" size="mini">
                                <el-option v-for="item in is_return_state" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </span>

                        <span>
                            是否一口价：
                            <el-select @change="search" v-model="getDistributionTypeAddProInfo.is_fixed" clearable
                                placeholder="请选择" size="mini">
                                <el-option v-for="item in is_fixed_state" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </span>

                        <span>
                            是否设定了下架日期：
                            <el-select @change="search" size="mini" v-model="getDistributionTypeAddProInfo.is_end"
                                clearable placeholder="请选择">
                                <el-option v-for="item in is_end_state" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </span>

                        <span>
                            商品分类：
                            <el-cascader @change="search" :options="category_Data" size="mini" :show-all-levels="false"
                                :props="{value:'id',label:'name',checkStrictly: true}" v-model="checkedCategory"
                                clearable></el-cascader>
                        </span>

                        <span>
                            <span>
                                搜索：
                            </span>
                            <el-input @change="search" clearable size="mini" class="searchGood"
                                v-model="getDistributionTypeAddProInfo.keyword"></el-input>
                        </span>

                        <el-button size="mini" class="reverseBtn" @click="reset">重 置</el-button>
                    </div>
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选
                    </el-checkbox>
                    <el-checkbox-group v-model="checkedGood" @change="handleCheckedGoodsChange">


                        <el-checkbox v-for="good in add_goods_Data" :label="good" :key="good.id"
                            class="checkedGood_item_style">
                            {{good.sn}}
                            {{good.name}}
                        </el-checkbox>


                    </el-checkbox-group>

                </el-form-item>
                <el-form-item>

                </el-form-item>
            </el-form>

            <el-button size="small" class="frontBtn submit" @click="submitBtn"> 提 交</el-button>


        </el-card>




    </div>
</template>

<script>
    import {
        adminApi
    } from '../../config'

    import {
        domainApi
    } from '../../config'
    export default {
        data() {
            return {
                Form: {
                    id: '',
                    name: '',
                    amount: '',
                    is_transfer: 0,
                    is_deliver_limit: 0,
                    is_depend: 0,
                    sort_order: '',
                    goods_list: []
                },
                Rules: {
                    name: {
                        required: true,
                        message: '请输入配送类型名称',
                        trigger: 'blur'
                    },
                    amount: {
                        required: true,
                        message: '请输入金额',
                        trigger: 'blur'
                    },
                    is_transfer: {
                        required: true,
                        message: '请确定是否能转出',
                        trigger: 'blur'
                    },
                    is_deliver_limit: {
                        required: true,
                        message: '请确定是否限额',
                        trigger: 'blur'
                    },
                    goodsList: {
                        required: true,
                        message: '请选择商品',
                        trigger: 'blur'
                    },
                    is_depend: {
                        required: true,
                        message: '请选择是否依赖其他如账单',
                        trigger: 'blur'
                    },
                    sort_order: {
                        required: true,
                        message: '请选择是否排序',
                        trigger: 'blur'
                    },
                },
                CNCapitalPrice: '',
                CNCapitalStated: false,
                category_Data: [],
                getDistributionTypeAddProInfo: {
                    page: 1,
                    per_page: 1000,
                    is_sell: '',
                    is_return: '',
                    is_fixed: '',
                    is_end: '',
                    category_id: '',
                    keyword: '',
                },
                total: 0,
                is_sell_state: [{
                        name: '是',
                        id: 1
                    },
                    {
                        name: '否',
                        id: 0
                    },
                ],
                is_return_state: [{
                        name: '是',
                        id: 1
                    },
                    {
                        name: '否',
                        id: 0
                    },
                ],
                is_fixed_state: [{
                        name: '是',
                        id: 1
                    },
                    {
                        name: '否',
                        id: 0
                    },

                ],
                is_end_state: [{
                        name: '是',
                        id: 1
                    },
                    {
                        name: '否',
                        id: 0
                    },
                ],
                add_goods_Input: '',
                chooseCategory: '',
                sortSetData: [],
                choosedGoods: [],
                CNCapitalPrice: '',
                CNCapitalStated: false,
                chooseProductData: [],
                product_data: [],

                selectionData: [],

                rowsData: [],
                tempData: [],

                is_add_goods: false,
                add_goods_Data: [],
                valve: true,
                testData: [],

                deleteSelectedIds: [],


                checkedGood: [],
                isIndeterminate: false,
                checkAll: [],
                checkedCategory: [],
                sell: '',


            }
        },
        created() {
            this.initEdit()
            this.getCategory()

        },
       
        methods: {
           

            initEdit() {
                let data = this.$route.query.data
                this.Form.id = data.id
                this.Form.name = data.name
                this.Form.amount = data.amount
                this.Form.is_transfer = data.is_transfer ? 1 : 0
                this.Form.is_deliver_limit = data.is_deliver_limit ? 1 : 0
                this.Form.is_depend = data.is_depend ? 1 : 0
                this.Form.sort_order = data.sort_order
                // 置空
                this.deleteSelectedIds = []
                this.getPSLXDetail(data.id)
                setTimeout(() => {
                    this.getGoodsInfo()
                })
                this.CNCapital()

            },

            // 获取商品分类
            async getCategory() {
                const res = await this.$https.post(adminApi + '/category/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.category_Data = res.data
            },

            // 获取配送类型详情
            async getPSLXDetail(id) {
                let params = {
                    id: id
                }

                const res = await this.$https.post(adminApi + '/present/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.tempData = res.data.goods_list

            },

            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind = "background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                return styleBind;
            },

            //  返回
            goback() {
                this.$router.push({
                    path: '/distributionType'
                })
            },



            // 添加
            add() {
                this.is_add_goods = true

            },





            async getGoodsInfo() {
                let params = this.getDistributionTypeAddProInfo
                const res = await this.$https.post(adminApi + '/goods/select?_ajax=1', params)

                if (res.status !== 200) return this.$message.error(res.statusText)
                this.add_goods_Data = res.data.data
                this.total = res.data.total


                // 赋值
                if (this.add_goods_Data.length > 0 && this.tempData.length > 0) {

                    this.tempData.forEach(node => {
                        this.add_goods_Data.forEach(ele => {
                            if (node.id == ele.id) {
                                this.checkedGood.push(ele)
                            }
                        })
                    })
                }


            },
            handleCheckedGoodsChange(data) {
                this.judgeIsAllSelected()
            },

            // 判断是否全选
            judgeIsAllSelected() {
                if (this.checkedGood.length !== this.add_goods_Data.length) {
                    this.isIndeterminate = true
                } else {
                    this.isIndeterminate = false
                    this.checkAll = true
                }

                if (this.checkedGood.length == 0) {
                    this.isIndeterminate = false
                    this.checkAll = false
                }
            },

            handleCheckAllChange(data) {
                if (data) {
                    this.checkedGood = this.add_goods_Data
                } else {
                    this.checkedGood = []
                }
                this.isIndeterminate = false
            },




            async getSortSetInfo() {
                const res = await this.$https.post(adminApi + '/categorys?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.sortSetData = res.data
            },


            CNCapital() {
                if (this.Form.amount.length != 0) {
                    this.CNCapitalStated = true
                } else {
                    this.CNCapitalStated = false
                }
                this.CNCapitalPrice = this.numberParseChina(this.Form.amount)
            },

            search() {
                // 1.判断搜索条件
                if (this.checkedCategory.length == 0) {
                    this.getDistributionTypeAddProInfo.category_id = ''
                } else {
                    this.getDistributionTypeAddProInfo.category_id = this.checkedCategory[this.checkedCategory.length -
                        1]
                }
                this.getGoodsInfo()

            },
            reset() {
                // 重置
                this.getDistributionTypeAddProInfo.is_sell = ''
                this.getDistributionTypeAddProInfo.is_return = ''
                this.getDistributionTypeAddProInfo.is_fixed = ''
                this.getDistributionTypeAddProInfo.is_end = ''
                this.getDistributionTypeAddProInfo.keyword = ''
                this.checkedCategory = []
                this.search()
            },
            //阿拉伯数字转换成大写汉字
            numberParseChina(money) {
                //汉字的数字
                var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');
                //基本单位
                var cnIntRadice = new Array('', '拾', '佰', '仟');
                //对应整数部分扩展单位
                var cnIntUnits = new Array('', '万', '亿', '兆');
                //对应小数部分单位
                var cnDecUnits = new Array('角', '分', '毫', '厘');
                //整数金额时后面跟的字符
                var cnInteger = '整';
                //整型完以后的单位
                var cnIntLast = '圆';
                //最大处理的数字
                var maxNum = 999999999999999.9999;
                //金额整数部分
                var integerNum;
                //金额小数部分
                var decimalNum;
                //输出的中文金额字符串
                var chineseStr = '';
                //分离金额后用的数组，预定义
                var parts;
                if (money == '') {
                    return '';
                }
                money = parseFloat(money);
                if (money >= maxNum) {
                    //超出最大处理数字
                    return '';
                }
                if (money == 0) {
                    chineseStr = cnNums[0] + cnIntLast + cnInteger;
                    return chineseStr;
                }
                //转换为字符串
                money = money.toString();
                if (money.indexOf('.') == -1) {
                    integerNum = money;
                    decimalNum = '';
                } else {
                    parts = money.split('.');
                    integerNum = parts[0];
                    decimalNum = parts[1].substr(0, 4);
                }
                //获取整型部分转换
                if (parseInt(integerNum, 10) > 0) {
                    var zeroCount = 0;
                    var IntLen = integerNum.length;
                    for (var i = 0; i < IntLen; i++) {
                        var n = integerNum.substr(i, 1);
                        var p = IntLen - i - 1;
                        var q = p / 4;
                        var m = p % 4;
                        if (n == '0') {
                            zeroCount++;
                        } else {
                            if (zeroCount > 0) {
                                chineseStr += cnNums[0];
                            }
                            //归零
                            zeroCount = 0;
                            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
                        }
                        if (m == 0 && zeroCount < 4) {
                            chineseStr += cnIntUnits[q];
                        }
                    }
                    chineseStr += cnIntLast;
                }
                //小数部分
                if (decimalNum != '') {
                    var decLen = decimalNum.length;
                    for (var i = 0; i < decLen; i++) {
                        var n = decimalNum.substr(i, 1);
                        if (n != '0') {
                            chineseStr += cnNums[Number(n)] + cnDecUnits[i];
                        }
                    }
                }
                if (chineseStr == '') {
                    chineseStr += cnNums[0] + cnIntLast + cnInteger;
                } else if (decimalNum == '') {
                    chineseStr += cnInteger;
                }
                return chineseStr;
            },

            async submitBtn() {

                this.$confirm('确认添加这个配送类型吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    .then(async () => {
                        this.Form.goods_list = []
                        this.checkedGood.forEach(item => {
                            this.Form.goods_list.push(item.id)
                        })

                        let params = this.Form

                        const {
                            data
                        } = await this.$https.post(adminApi + '/present/edit?_ajax=1', params)

                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)
                        this.$router.go(-1)
                    })
                    .catch(() => {
                        this.$message.info('您取消了按钮')
                    })

            },





        }
    }
</script>

<style lang="css" scoped>
    .el-card {
        position: relative;
    }

    .Back {
        display: flex;
        justify-content: end;
        padding-right: 5%;
    }

    .DistributionSchemeBtns {
        display: flex;
        flex-wrap: wrap;

    }

    .DistributionSchemeBtns div:nth-child(1) {
        flex: 8;
    }

    .DistributionSchemeBtns div:nth-child(2) {
        flex: 2;
    }

    .one,
    .two {
        display: flex;
        margin-bottom: 20px;
    }

    .two span,
    .one span {
        display: inline-block;
        width: 30%;
        margin-right: 50px;

    }

    .input {
        width: 60%;
    }

    .submit {
        margin-left: 10%;
        /* margin-top: 20px; */
    }

    .sort_order_input {
        width: 18%;
    }

    .title_style {
        font-size: 14px;
        font-weight: bolder;
    }

    .DistributionSchemeBtns {
        display: flex;
        flex-wrap: wrap;
        background-color: #f5f7fa;
        padding: 20px 20px;
        padding-bottom: 0;
    }

    .DistributionSchemeBtns div:nth-child(1) {
        flex: 8;
    }

    .DistributionSchemeBtns div:nth-child(2) {
        flex: 2;
    }

    div /deep/.el-table__body-wrapper::-webkit-scrollbar {
        height: 8px;
        width: 6px t;
    }

    div /deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }


    div /deep/.el-table__header tr,
    div /deep/.el-table__header th {
        padding: 2px;
        /* height: 12px; */
    }


    .checkedGood_item_style {
        width: 30%;
    }

    .condition_style {
        display: flex;
        flex-wrap: wrap;
        background-color: #f5f7fa;
        padding: 5px 20px;
        margin-bottom: 10px;
    }

    .condition_style span {
        width: 30%;
        display: flex;
        align-items: center;
    }


    div /deep/.searchGood .el-input__inner {
        width: 50%;
    }
</style>