<template>
    <div id="app">

        <!-- 布局 -->
        <div class="unitSet">
            <div class="unitSet_left">

                <!-- 卡片视图区域 -->
                <el-card class="card_left">
                    <div class="sConditionStyle">
                        <!-- 选择品牌 -->
                        <el-select v-model="brand_value_id" clearable placeholder="请选择品牌" size="small"
                            @change="getProduct">
                            <el-option v-for="item in brandData" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                        <el-select slot="prepend" clearable v-model="is_plan_value_id" placeholder="请选择是否计划生产"
                            size="small" @change="getProduct">
                            <el-option v-for="item in is_plan_options" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                        <el-select slot="prepend" size="small" clearable v-model="is_show_value_id" @change="getProduct"
                            placeholder="请选择是否上架">
                            <el-option v-for="item in is_show_options" :key="item.value" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                        <el-select slot="prepend" size="small" clearable v-model="is_produce_value_id"
                            placeholder="请选择是否生产" @change="getProduct">
                            <el-option v-for="item in is_produce_options" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                        <el-select slot="prepend" size="small" clearable @change="getProduct" v-model="is_qr_value_id"
                            placeholder="请选择是否生成二维码">
                            <el-option v-for="item in is_qr_options" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>

                        <el-input clearable size="small" placeholder="搜索" class="searchinput_widthStyle"
                            v-model="keyword" autocomplete="off" @change="getProduct"></el-input>


                        <el-button icon="el-icon-delete" class="reverseBtn recycleBtn-style" @click="drawer = true"
                            size="small">回收站</el-button>
                    </div>

                    <div class="addBtn--style">
                        <el-button size="mini" class="frontBtn" @click="createProductBtn">创建产品</el-button>
                        <el-button size="mini" class="frontBtn" @click="dataExportBtn">数据导出</el-button>
                    </div>

                    <el-table :data="productTableData" max-height="auto" :header-cell-style="styleBindFun"
                        style="width:100%">

                        <el-table-column prop="sn" label="货号" align="center" width="100">
                        </el-table-column>

                        <el-table-column prop="brand_name" label="品牌" align="center">
                        </el-table-column>

                        <el-table-column  align="center" width="320">
                            <template slot="header">
                              名称  <span style="color:#fa5a57">（点击查看详情）</span>
                            </template>	
                            <template slot-scope="scope">
                                <el-button type="text" class="text_name" @click="fnLookDetail(scope.row)"> {{scope.row.name}}</el-button>
                                <span v-show="scope.row.spec">（{{scope.row.spec}}） </span>
                                组：<i class="NumberNoLeft">{{scope.row.goods_count}}</i>
                            </template>
                        </el-table-column>

                        <el-table-column prop="alias" label="别名" align="center" width="200">
                            <template slot-scope="scope">
                                {{scope.row.alias}}
                            </template>
                        </el-table-column>

                        <el-table-column prop="ename" label="英文名" show-overflow-tooltip align="center" width="380">

                        </el-table-column>



                        <el-table-column prop="barcode" label="条码" align="center" width="200">
                            <template slot-scope="scope">
                                <el-tag effect="dark" type="info" label="scope.row.barcode" v-show="scope.row.barcode">
                                    {{scope.row.barcode}}</el-tag>
                            </template>
                        </el-table-column>

                     



                        <el-table-column label="低库存报警数量" align="center" width="150">
                            <template slot-scope="scope">
                                {{scope.row.warning}} {{scope.row.unit_name}}
                            </template>
                        </el-table-column>
                        <el-table-column label="是否计划生产" align="center" width="150">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row.is_plan" @change="setStatus(scope.row,'plan')" active-color="#409eff" inactive-color="#dcdfe6">
                                </el-switch>
                            </template>
                        </el-table-column>

                        <el-table-column label="是否显示" align="center" width="150">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row.is_show" @change="setStatus(scope.row,'show')" active-color="#409eff" inactive-color="#dcdfe6">
                                </el-switch>     
                            </template>
                        </el-table-column>

                        <el-table-column label="是否继续生产" align="center" width="150">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row.is_produce" @change="setStatus(scope.row,'produce')" active-color="#409eff" inactive-color="#dcdfe6">
 </el-switch>
                            </template>
                        </el-table-column>

                        <el-table-column label="是否一物一码" align="center" width="150">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row.is_qr" @change="setStatus(scope.row,'qr')" active-color="#409eff" inactive-color="#dcdfe6">
 </el-switch>
                            </template>
                        </el-table-column>



                        <el-table-column fixed="right" label="操作" width="220" align="center">
                            <template slot-scope="scope">
                                <el-button @click="editProduct(scope.row)" type="text" class="text_primary" size="big">
                                    编辑</el-button>
                                <el-button @click="moveRecovery(scope.row)" type="text" class="text_primary" size="big">
                                    移至回收站
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>

                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="getProductInfo.current_page" :page-sizes="[10]"
                        :page-size="getProductInfo.page_size" layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                    </el-pagination>

                </el-card>
            </div>



            <el-drawer title="回收站" :visible.sync="drawer" :direction="direction" size="36%">
                <el-table :header-cell-style="styleBindFun" :data="recycleProductTableData" max-height="auto"
                    style="width:100%">
                    <el-table-column prop="sn" label="货号" align="center" width="200">
                    </el-table-column>

                    <el-table-column prop="name" label="名称" align="center" width="200">
                    </el-table-column>

                    <el-table-column fixed="right" label="操作" width="190" align="center">
                        <template slot-scope="scope">
                            <el-button class="text_primary" type='text' size="big" @click="removeRecovery(scope.row)">恢复
                            </el-button>
                            <el-button class="text_delete" type='text' size="big" @click="deleteRecovery(scope.row)">删除
                            </el-button>

                        </template>
                    </el-table-column>
                </el-table>
            </el-drawer>
        </div>

        <!-- 增加产品对话框 -->
        <el-dialog title="增加产品" :visible.sync="dialogFormVisibleOfadd" width="650px">
            <el-form :rules="rules">
                <el-form-item label="请输入产品名称：" prop="name" :label-width="formLabelWidth">
                    <el-input v-model="addProductForm.product_name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="请输入产品英文名：" :label-width="formLabelWidth">
                    <el-input v-model="addProductForm.product_ename" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="请选择产品品牌：" prop="brand_id" :label-width="formLabelWidth">
                    <el-dropdown size="middle" split-button type="primary" @command="handleCommandBrand">
                        {{currentBrand}}
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="item1 in brandData" :key="item1.id" :command="item1.name">
                                {{item1.name}}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-form-item>
                <el-form-item label="请选择产品单位：" prop="unit_id" :label-width="formLabelWidth">
                    <el-dropdown size="middle" split-button type="primary" @command="handleCommandUnit">
                        {{currentUnit}}
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="item2 in unitData" :key="item2.id" :command="item2.name">
                                {{item2.name}}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-form-item>
                <el-form-item label="请输入产品条码：" :label-width="formLabelWidth">
                    <el-input v-model="addProductForm.product_barcode" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="请输入产品货号：" prop="sn" :label-width="formLabelWidth">
                    <el-input v-model="addProductForm.product_sn" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="请输入产品规格" :label-width="formLabelWidth">
                    <el-input v-model="addProductForm.product_spec" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="请输入产品别名：" prop="alias" :label-width="formLabelWidth">
                    <el-input v-model="addProductForm.product_alias" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="请输入产品低库存报警数量：" :label-width="formLabelWidth">
                    <el-input v-model="addProductForm.product_warning" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="计划生产：" prop="is_plan" :label-width="formLabelWidth">
                    <el-switch v-model="addProductForm.product_is_plan" active-text="是" inactive-text="否"></el-switch>
                </el-form-item>
                <el-form-item label="显示：" prop="is_show" :label-width="formLabelWidth">
                    <el-switch v-model="addProductForm.product_is_show" active-text="是" inactive-text="否"></el-switch>
                </el-form-item>
                <el-form-item label="继续生产：" prop="is_produce" :label-width="formLabelWidth">
                    <el-switch v-model="addProductForm.product_is_produce" active-text="是" inactive-text="否">
                    </el-switch>

                </el-form-item>
                <el-form-item label="一物一码：" prop="is_qr" :label-width="formLabelWidth">
                    <el-switch v-model="addProductForm.product_is_qr" active-text="是" inactive-text="否"></el-switch>

                </el-form-item>
                <el-form-item label="备注：" :label-width="formLabelWidth">
                    <el-input v-model="addProductForm.product_remarks" autocomplete="off"></el-input>
                </el-form-item>


            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisibleOfadd = false">取 消</el-button>
                <el-button type="primary" @click="addProduct">确 定</el-button>
            </div>
        </el-dialog>



      


        <!-- 创建产品对话框 -->
        <el-dialog title="创建产品" :visible.sync="is_createProduct" width="68%" class="addProduct__dialog--style">

            <div class="sBaseInfo">
                <!-- <span class="el-icon-info infoIconStyle"></span> -->
                <span class="sRectangleStyle "></span>
                基本信息
            </div>
            <div class="content">
          
             <div class="first">
                    <span>货号：
                        <el-input clearable size="small" placeholder="例如：NS0022-2" class="input_widthStyle"
                            v-model="add_form.sn" autocomplete="off"></el-input>
                    </span>

                    <span>名称：
                        <el-input clearable size="small" placeholder="请输入" class="input_widthStyle"
                            v-model="add_form.name" autocomplete="off"></el-input>
                    </span>
                    <span>英文名：
                        <el-input clearable size="small" placeholder="请输入" class="input_widthStyle"
                            v-model="add_form.ename" autocomplete="off"></el-input>
                    </span>

                </div>

                <div class="second">
                    <span>别名：
                        <el-input clearable size="small" placeholder="请输入" class="input_widthStyle"
                            v-model="add_form.alias" autocomplete="off"></el-input>
                    </span>

                    <span>条码：
                        <el-input clearable size="small" placeholder="例如：0893794003212" class="input_widthStyle"
                            v-model="add_form.barcode" autocomplete="off"></el-input>
                    </span>


                    <span>规格：
                        <el-input clearable size="small" placeholder="例如：50g" class="input_widthStyle"
                            v-model="add_form.spec" autocomplete="off"></el-input>
                    </span>
                </div>

                <div class="third">
                    <span>低库存报警数量：
                        <el-input clearable size="small" placeholder="请输入" class="low_input_widthStyle"
                            v-model="add_form.warning" autocomplete="off"></el-input>
                    </span>

                    <span></span>
                    <span></span>


                    
                </div>

              
            </div>

             <div class="sStateInfo">
                <!-- <span class="el-icon-info infoIconStyle"></span> -->
                <span class="sRectangleStyle "></span>
                状态信息
            </div>
            <div class="content">
                    <div class="fourthly">
                        <span>选择品牌：
                        <el-select clearable v-model="add_form.brand_id" size="small" placeholder="请选择">
                            <el-option v-for="item in brandData" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </span>


                    <span>选择单位：
                        <el-select clearable v-model="add_form.unit_id" size="small" placeholder="请选择">
                            <el-option v-for="item in unitData" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </span>

                    <span>是否计划生产:
                        <el-select clearable v-model="add_form.is_plan" size="small" placeholder="请选择">
                            <el-option v-for="item in is_plan_options" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </span>

                </div>

                <div class="fifth">
                     <span>是否上架:
                        <el-select clearable v-model="add_form.is_show" size="small" placeholder="请选择">
                            <el-option v-for="item in is_show_options" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </span>


                    <span>是否继续生产:
                        <el-select clearable v-model="add_form.is_produce" size="small" placeholder="请选择">
                            <el-option v-for="item in is_produce_options" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </span>
                    <span>是否一物一码:
                        <el-select clearable v-model="add_form.is_qr" size="small" placeholder="请选择">
                            <el-option v-for="item in is_qr_options" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </span>
                  
                </div>

                <div class="sixth">
                     <span>是否有批次:
                        <el-select clearable v-model="add_form.is_batch" size="small" placeholder="请选择">
                            <el-option v-for="item in is_batch_options" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </span>

                      <span>排序依据:
                        <el-input clearable placeholder="默认100，范围100~200" size="small" class="input_widthStyle"
                            v-model="add_form.sort_order" autocomplete="off"></el-input>
                    </span>


                     <span></span>

                
                </div>
            </div>

             <div class="sStateInfo">
                <!-- <span class="el-icon-info infoIconStyle"></span> -->
                <span class="sRectangleStyle "></span>
                其他信息
            </div>
            <div class="content">
                <div class="seventh">
                    <span>
                        <div class="remarks_proSet_style">备注:</div>
                        <el-input clearable type="textarea" class="input_widthStyle" placeholder="请输入" v-model="add_form.remarks"
                            autocomplete="off"></el-input>
                    </span>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_createProduct = false">取 消</el-button>
                <el-button class="frontBtn" size="mini" @click="createProductDo">确 定</el-button>
            </div>

        </el-dialog>

        <!-- 修改产品对话框 -->
        <el-dialog title="修改产品" :visible.sync="is_editForm" width="68%" class="addProduct__dialog--style">

             <div class="sStateInfo">
                <!-- <span class="el-icon-info infoIconStyle"></span> -->
                <span class="sRectangleStyle "></span>
                基本信息
            </div>
            <div class="content">
                <div class="first">
                    <span>货号：
                        <el-input clearable size="small" placeholder="例如：NS0022-2" class="input_widthStyle"
                            v-model="edit_form.sn" autocomplete="off"></el-input>
                    </span>

                    <span>名称：
                        <el-input clearable size="small" placeholder="请输入" class="input_widthStyle"
                            v-model="edit_form.name" autocomplete="off"></el-input>
                    </span>
                    <span>英文名：
                        <el-input clearable size="small" placeholder="请输入" class="input_widthStyle"
                            v-model="edit_form.ename" autocomplete="off"></el-input>
                    </span>

                </div>

                <div class="second">
                    <span>别名：
                        <el-input clearable size="small" placeholder="请输入" class="input_widthStyle"
                            v-model="edit_form.alias" autocomplete="off"></el-input>
                    </span>

                    <span>条码：
                        <el-input clearable size="small" placeholder="例如：0893794003212" class="input_widthStyle"
                            v-model="edit_form.barcode" autocomplete="off"></el-input>
                    </span>


                    <span>规格：
                        <el-input clearable size="small" placeholder="例如：50g" class="input_widthStyle"
                            v-model="edit_form.spec" autocomplete="off"></el-input>
                    </span>
                </div>

                <div class="third">
                    <span>低库存报警数量：
                        <el-input clearable size="small" placeholder="请输入" class="low_input_widthStyle"
                            v-model="edit_form.warning" autocomplete="off"></el-input>
                    </span>

                    <span></span>
                    <span></span>
                    
                </div>

                
            </div>

             <div class="sStateInfo">
                <!-- <span class="el-icon-info infoIconStyle"></span> -->
                <span class="sRectangleStyle "></span>
                状态信息
            </div>
            <div class="content">
                <div class="fourthly">

                    <span>选择品牌：
                        <el-select clearable v-model="edit_form.brand_id" size="small" placeholder="请选择">
                            <el-option v-for="item in brandData" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </span>


                    <span>选择单位：
                        <el-select clearable v-model="edit_form.unit_id" size="small" placeholder="请选择">
                            <el-option v-for="item in unitData" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </span>
                    <span>是否计划生产:
                        <el-select clearable v-model="edit_form.is_plan" size="small" placeholder="请选择">
                            <el-option v-for="item in is_plan_options" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </span>

                   
                </div>

                <div class="fifth">
                     <span>是否上架:
                        <el-select clearable v-model="edit_form.is_show" size="small" placeholder="请选择">
                            <el-option v-for="item in is_show_options" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </span>


                    <span>是否继续生产:
                        <el-select clearable v-model="edit_form.is_produce" size="small" placeholder="请选择">
                            <el-option v-for="item in is_produce_options" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </span>

                    <span>是否一物一码:
                        <el-select clearable v-model="edit_form.is_qr" size="small" placeholder="请选择">
                            <el-option v-for="item in is_qr_options" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </span>

                  
                </div>

                <div class="seventh">

                      <span>是否有批次:
                        <el-select clearable v-model="edit_form.is_batch" size="small" placeholder="请选择">
                            <el-option v-for="item in is_batch_options" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </span>

                     <span>重量:
                        <el-input clearable size="small" class="input_widthStyle" v-model="edit_form.weight"
                            autocomplete="off"></el-input>
                    </span>

                    <span>体积:
                        <el-input clearable size="small" class="input_widthStyle" v-model="edit_form.volume"
                            autocomplete="off"></el-input>
                    </span>


                    
                </div>

                   <div class="seventh">

                      <span>排序依据:
                        <el-input clearable placeholder="默认100，范围100~200" size="small" class="input_widthStyle"
                            v-model="edit_form.sort_order" autocomplete="off"></el-input>
                    </span>

                   
                    <span></span>

                    <span></span>
                </div>

                
            </div>

               <div class="sStateInfo">
                <!-- <span class="el-icon-info infoIconStyle"></span> -->
                <span class="sRectangleStyle "></span>
                其他信息
            </div>
            <div class="content">
                <div class="sixth">
                    <span><div class="remarks_proSet_style">备注:</div>
                        <el-input clearable type="textarea" class="input_widthStyle" placeholder="请输入" v-model="edit_form.remarks"
                            autocomplete="off"></el-input>
                    </span>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_editForm = false">取 消</el-button>
                <el-button class="frontBtn" size="mini" @click="editProductDo">确 定</el-button>
            </div>

        </el-dialog>


          <!-- 产品详情对话框 -->
        <el-dialog title="组成商品" :visible.sync="bProductDetail" width="68%" class="addProduct__dialog--style">
            <div class="sConstituteStyle">
           
            <div class="first">
                <span>{{oProductDetail.name}}</span>
                <span>组成商品数：<i class="NumberNoLeft">{{nlength}}</i></span>
            </div>

            <div class="second">
                 <el-table :header-cell-style="styleBindFun" :data="oProductDetail.goods" max-height="auto"
                    style="width:100%">
                    <el-table-column prop="sn" label="货号" align="center" >
                    </el-table-column>

                    <el-table-column prop="name" label="名称" align="center" width="360">
                    </el-table-column>


                     <el-table-column prop="category_full_name" label="所属分类" align="center" width="200" >
                    </el-table-column>

                    


                     <el-table-column  label="单价" align="center" >
                         <template slot-scope="scope">
                            <span class="NumberNoLeft">￥{{scope.row.price}}</span> 
                         </template>
                    </el-table-column>


                     <el-table-column  label="在商品的数量" align="center" >
                         <template slot-scope="scope">
                            {{scope.row.pivot.quantity}}
                         </template>
                    </el-table-column>

                      <el-table-column  label="在商品的单价" align="center" >
                         <template slot-scope="scope">
                            ￥{{scope.row.pivot.price}}
                         </template>
                    </el-table-column>

                      <el-table-column prop="update_time" label="更新" align="center" width="200">
                    </el-table-column>


                    
                </el-table>
            </div>

            <div class="third">
                <span>
                    备注:
                </span>
                {{oProductDetail.remarks}}
            </div>

                 
            </div>
        
            <div slot="footer" class="dialog-footer">
             
                <el-button class="frontBtn" size="mini" @click="bProductDetail = false">确 定</el-button>
            </div>

        </el-dialog>


    </div>
</template>

<script>
    import {
        adminApi
    } from '../../config'

    export default {
        data() {
            return {
                drawer: false,
                direction: 'rtl',
                productTableData: [], //产品数据
                recycleProductTableData: [], //回收站数据
                total: 100, //产品总数
                // 分页参数列表
                getProductInfo: {
                    current_page: 1, // 当前页
                    page_size: 10 // 每页显示条数
                },
                currentEditBrand: '', //当前要编辑的品牌
                currentEditId: '', //当前要编辑的品牌的id
                currentDeleteProduct: '', //当前要删除的品牌
                dialogFormVisibleOfadd: false, //控制添加品牌弹出框
                dialogFormVisibleOfedit: false, //控制编辑品牌弹出框


                // 获取品牌列表数组
                brandData: [],
                currentBrand: '请选择', //选中的品牌

                // 获取单位列表数组
                unitData: [],
                currentUnit: '请选择', //选中的单位

                //添加框的输入值
                addProductForm: {
                    product_name: '',
                    product_ename: '',
                    product_brand_id: 0,
                    product_unit_id: 0,
                    product_barcode: '',
                    product_sn: '',
                    product_spec: '',
                    product_alias: '',
                    product_warning: '',
                    product_is_plan: false,
                    product_is_show: false,
                    product_is_produce: false,
                    product_is_qr: false,
                    product_remarks: ''
                },

                add_form: {
                    name: "",
                    ename: "",
                    brand_id: "",
                    unit_id: "",
                    barcode: "",
                    sn: "",
                    spec: "",
                    alias: "",
                    warning: "",
                    is_plan: "",
                    is_show: "",
                    is_produce: "",
                    is_qr: "",
                    sort_order: "",
                    remarks: "",
                    is_batch: "",
                },
                is_addForm_empty: false,
                is_editForm: false,
                edit_form: {
                    id: "",
                    name: "",
                    ename: "",
                    brand_id: "",
                    unit_id: "",
                    barcode: "",
                    sn: "",
                    spec: "",
                    alias: "",
                    warning: "",
                    is_plan: "",
                    is_show: "",
                    is_produce: "",
                    is_qr: "",
                    sort_order: "",
                    remarks: "",
                    is_batch: "",
                    weight: "",
                    volume: "",

                },
                editWarehouseForm: {
                    warehouse_name: '',
                    warehouse_address: '',
                    warehouse_phone: '',
                    warehouse_active: 0,
                    remarks: ''
                }, //编辑框的输入值
                formLabelWidth: '220px', //表单宽度
                rules: {
                    name: {
                        required: true,
                        message: '产品名称是必填的',
                        trigger: 'blur'
                    },
                    brand_id: {
                        required: true,
                        message: '产品品牌是必填的',
                        trigger: 'blur'
                    },
                    unit_id: {
                        required: true,
                        message: '产品单位是必填的',
                        trigger: 'blur'
                    },
                    sn: {
                        required: true,
                        message: '产品货号是必填的',
                        trigger: 'blur'
                    },
                    alias: {
                        required: true,
                        message: '产品别名是必填的',
                        trigger: 'blur'
                    },
                    is_plan: {
                        required: true,
                        message: '产品是否计划生产是必填的',
                        trigger: 'blur'
                    },
                    is_show: {
                        required: true,
                        message: '产品是否显示是必填的',
                        trigger: 'blur'
                    },
                    is_produce: {
                        required: true,
                        message: '产品是否继续生产是必填的',
                        trigger: 'blur'
                    },
                    is_qr: {
                        required: true,
                        message: '产品是否一物一码是必填的',
                        trigger: 'blur'
                    },
                },

                brand_value_id: '',

                is_plan_value_id: '',

                is_show_value_id: '',

                is_produce_value_id: '',

                is_qr_value_id: '',

                keyword: '',
                brandDataOptions: [],
                is_plan_options: [{
                    id: 1,
                    name: '是'
                }, {
                    id: 0,
                    name: '否'
                }],
                is_show_options: [{
                    id: 1,
                    name: '是'
                }, {
                    id: 0,
                    name: '否'
                }],
                is_produce_options: [{
                    id: 0,
                    name: '否'
                }, {
                    id: 1,
                    name: '是'
                }],
                is_qr_options: [{
                    id: 0,
                    name: '否'
                }, {
                    id: 1,
                    name: '是'
                }],
                is_batch_options: [{
                    id: 0,
                    name: '否'
                }, {
                    id: 1,
                    name: '是'
                }],

                // 添加产品
                is_createProduct: false,
                // 数据导出
                is_dataExport: false,

                oProductDetail:'',
                bProductDetail:false,
                nlength:0,

            }
        },
        created() {
            this.getProduct();
            this.getProductOnlyTrash();
            this.getBrand();
            this.getUnit();

        },
        methods: {
            styleBindFun() {
                let styleBind = "background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                // let  styleBind="background:#f6f8fa;fontSize:14px;color:#333333;fontWeight: bold;"
                return styleBind;
            },

           

       

            // 获取正常使用产品数据
            async getProduct() {
                //参数
                let obj = {
                    // 当前页
                    page: this.getProductInfo.current_page,
                    // 每页显示条数
                    per_page: this.getProductInfo.page_size,
                    // 筛选条件
                    brand_id: this.brand_value_id,
                    is_plan: this.is_plan_value_id,
                    is_show: this.is_show_value_id,
                    is_produce: this.is_produce_value_id,
                    is_qr: this.is_qr_value_id,
                    keyword: this.keyword
                }

                // 判断
                if (this.brand_value_id == '' && this.brand_value_id !== 0) {
                    delete obj.brand_id
                }

                if (this.is_plan_value_id == '' && this.is_plan_value_id !== 0) {
                    delete obj.is_plan
                }

                if (this.is_show_value_id == '' && this.is_show_value_id !== 0) {
                    delete obj.is_show
                }

                if (this.is_produce_value_id == '' && this.is_produce_value_id !== 0) {
                    delete obj.is_produce
                }

                if (this.is_qr_value_id == '' && this.is_qr_value_id !== 0) {
                    delete obj.is_qr
                }

                if (this.keyword == '') {
                    delete obj.keyword
                }

                let params = obj
                // 发送请求获取产品属性数据
                const res= await this.$https.post(adminApi + '/products?_ajax=1', params)
                 
                 if (res.status !== 200) {
          return this.$message.error(res.statusText)
        }
                // // 赋值产品项数据
                let {
                    data
                } = res.data
                this.productTableData = data
                // 赋值产品总数
                let {
                    total
                } = res.data
                this.total = total
            },
            //每页显示数发生变化
            handleSizeChange(val) {
                this.getProductInfo.page_size = val;
                this.getProduct();

            },
            // 当前页发生变化
            handleCurrentChange(val) {
                this.getProductInfo.current_page = val;
                this.getProduct();
            },
            // 获取回收站仓库数据
            async getProductOnlyTrash() {

                // 发送请求获取仓库属性数据
                const res= await this.$https.post(adminApi + '/products?_ajax=1', {
                    type: 'onlytrash'
                })

                if (res.status !== 200) {
                     return this.$message.error(res.statusText)
                }
                this.recycleProductTableData = res.data.data
                console.log(this.recycleProductTableData, 'this.recycleProductTableData');
            },

            //  1.获取品牌列表
            async getBrand() {
                const {
                    data: brandData
                } = await this.$https.post(adminApi + '/brand/select?_ajax=1')
                this.brandData = brandData
              
            },
            //  2.获取单位列表
            async getUnit() {
                const {
                    data: unitData
                } = await this.$https.post(adminApi + '/unit/select?_ajax=1')
                this.unitData = unitData
            },


            // 增加
            async addProduct() {
                var params = {
                    name: this.addProductForm.product_name,
                    ename: this.addProductForm.product_ename,
                    brand_id: this.addProductForm.product_brand_id,
                    unit_id: this.addProductForm.product_unit_id,
                    barcode: this.addProductForm.product_barcode,
                    sn: this.addProductForm.product_sn,
                    spec: this.addProductForm.product_spec,
                    alias: this.addProductForm.product_alias,
                    warning: this.addProductForm.product_warning,
                    is_plan: this.addProductForm.product_is_plan,
                    is_show: this.addProductForm.product_is_show,
                    is_produce: this.addProductForm.product_is_produce,
                    is_qr: this.addProductForm.product_is_qr,
                    remarks: this.addProductForm.product_remarks,
                };

                // 发送请求增加产品数据
                const {
                    data: res
                } = await this.$https.post(adminApi + '/product/create?_ajax=1', params);

                if (res.error_code !== 0) {
                    return this.$message.error('添加失败:' + res.msg);
                } else {
                    this.$message.success('添加成功')
                    // 重新调用
                    this.getProduct();
                    this.getProductOnlyTrash();

                    // 关闭添加弹出框
                    this.dialogFormVisibleOfadd = false
                }

            },
            // 选择品牌的方法
            handleCommandBrand(command) {
                //  赋值选中的品牌
                this.currentBrand = command
                //  赋值id到表单
                for (let i in this.brandData) {
                    if (this.brandData[i].name == command) {
                        this.addProductForm.product_brand_id = this.brandData[i].id
                    }
                }
            },
            // 选择单位的方法
            handleCommandUnit(command) {
                //  赋值选中的品牌
                this.currentUnit = command
                //  赋值id到表单
                for (let i in this.unitData) {
                    if (this.unitData[i].name == command) {
                        this.addProductForm.product_unit_id = this.unitData[i].id
                    }
                }
            },
            // 编辑
           async editProduct(row) {
                console.log(row,'row');
                //赋值
                this.edit_form.id = row.id
                this.edit_form.name = row.name
                this.edit_form.ename = row.ename
                this.edit_form.barcode = row.barcode
                this.edit_form.sn = row.sn
                this.edit_form.spec = row.spec
                this.edit_form.alias = row.alias
                this.edit_form.warning = row.warning
                this.edit_form.is_plan = row.is_plan ? 1 : 0
                this.edit_form.is_show = row.is_show ? 1 : 0
                this.edit_form.is_produce = row.is_produce ? 1 : 0
                this.edit_form.is_qr = row.is_qr ? 1 : 0
                
                this.edit_form.is_batch = row.is_batch ? 1 : 0

                this.brandData.forEach(node=>{
                    if(node.name == row.brand_name){
                        this.edit_form.brand_id=node.id
                    }
                })

                 this.unitData.forEach(node=>{
                    if(node.name == row.unit_name){
                        this.edit_form.unit_id=node.id
                    }
                })

                let params ={
                    id:row.id
                }
                const res = await this.$https.post(adminApi + '/product/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                let oProductDetail=res.data
                this.edit_form.remarks = oProductDetail.remarks
                this.edit_form.weight = oProductDetail.weight
                this.edit_form.volume = oProductDetail.volume
                this.edit_form.sort_order = oProductDetail.sort_order


                this.is_editForm = true
            },

            async editProductDo() {
                let params = this.edit_form
                const {
                    data
                } = await this.$https.post(adminApi + '/product/edit?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_editForm = false
                this.getProduct();
            },



            // 编辑完发送
            async editLaunchProduct() {
                // 参数
                var params = {
                    id: this.currentEditId,
                    name: this.addProductForm.product_name,
                    ename: this.addProductForm.product_ename,
                    brand_id: this.addProductForm.product_brand_id,
                    unit_id: this.addProductForm.product_unit_id,
                    barcode: this.addProductForm.product_barcode,
                    sn: this.addProductForm.product_sn,
                    spec: this.addProductForm.product_spec,
                    alias: this.addProductForm.product_alias,
                    warning: this.addProductForm.product_warning,
                    is_plan: this.addProductForm.product_is_plan,
                    is_show: this.addProductForm.product_is_show,
                    is_produce: this.addProductForm.product_is_produce,
                    is_qr: this.addProductForm.product_is_qr,
                    remarks: this.addProductForm.product_remarks,
                };
                // 3. 点击确定发送修改数据
                const {
                    data: res
                } = await this.$https.post(adminApi + '/product/edit?_ajax=1', params)
                if (res.error_code !== 0) {
                    return this.$message.error('修改失败:' + res.msg)
                } else {
                    // 关闭弹窗
                    this.dialogFormVisibleOfedit = false
                    this.$message.success('修改成功')
                    // 重新调用
                    this.getProduct();
                    this.getProductOnlyTrash();

                }
            },
            // 移至回收站
            async moveRecovery(row) {
                //   //  1.请求参数
                var data = {
                    id: row.id
                }
                // 2.发送
                const res= await this.$https.post(adminApi + '/product/destroy?_ajax=1', data)
                if (res.data.error_code !== 0) {
                    return this.$message.error( res.data.msg)
                }
                this.$message.success(res.data.msg)
                // 重新调用
                this.getProduct();
                this.getProductOnlyTrash();
            },
            // 删除
            deleteRecovery(row) {
                this.$confirm('您确定删除该商品吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    .then(async () => {

                        var data = {
                            id: row.id,
                            type: 'delete'
                        }
                        //   // 发送
                        const {
                            data: res
                        } = await this.$https.post(adminApi + '/product/destroy?_ajax=1', data)
                        if (res.error_code !== 0) {
                            return this.$message.error('删除产品失败:' + res.msg)
                        }
                        this.$message.success(res.msg)
                        // 重新调用
                        this.getProduct();
                        this.getProductOnlyTrash();
                    })
                    .catch(() => {
                        this.$message.error('您取消了按钮')
                    })


            },

            //移出回收站
            async removeRecovery(row) {
                let params = {
                    id: row.id
                }
                const res = await this.$https.post(adminApi + '/product/restore?_ajax=1', params)
                if (res.data.error_code !== 0) {
                    return this.$message.error( res.data.msg)
                }
                this.$message.success('移至回收站成功')
                // 重新调用
                this.getProduct();
                this.getProductOnlyTrash();
            },


            createProductBtn() {
                this.is_createProduct = true
                // 清空
                if (this.is_addForm_empty) {
                    for (let i in this.add_form) {
                        this.add_form[i] = ''
                    }
                    this.is_addForm_empty = false
                }
            },

            async createProductDo() {
                let params = this.add_form
                const {
                    data
                } = await this.$https.post(adminApi + '/product/create?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_createProduct = false
                this.getProduct()
                this.is_addForm_empty = true

            },

            dataExportBtn() {
                //     let params=''
                //    if(this.brand_value_id !==''){

                //     }
                //        window.location.href=adminApi+'/product/down?_ajax=1&'+params
            },


            async setStatus(row,info){
               let params={
                   id:row.id,
                   type:''
               }
               if(info =='plan'){
                   params.type='plan'
               }
                if(info =='show'){
                   params.type='show'
               }
                if(info =='produce'){
                   params.type='produce'
               }
                if(info =='qr'){
                   params.type='qr'
               }
                 const res = await this.$https.post(adminApi + '/product/set_status?_ajax=1', params)
                if (res.data.error_code !== 0) return this.$message.error(res.data.msg)
                this.$message.success(res.data.msg)
            },


            // 查看详情
           async fnLookDetail(row){
                console.log(row,'row');
                let params={
                    id:row.id
                }
                const res = await this.$https.post(adminApi + '/product/detail?_ajax=1', params)
                console.log(res,'res123');
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.oProductDetail=res.data
                this.nlength=this.oProductDetail.goods.length
                this.bProductDetail=true

            },

        }

    }
</script>

<style lang="less" scoped>
    #app {
        overflow: hidden;
    }

    .el-pagination {
        margin-top: 35px;
        display: flex;
        justify-content: center;
    }



    .card_left {
        width: 100%;
        padding: 10px;
        // height: 700px;
        // max-height: 700px;
        overflow-y: auto;
    }

    .card_left div:nth-child(1) {
        padding: 6px 10px;
        // background-color: #f6f8fa;
        position: relative;
    }

    .addBtn--style {
        margin-top: 20px;
    }

    .recycleBtn-style {
        position: absolute;
        right: 2%;
        top: 50%;
        transform: translateY(-50%);
    }

    #app {
        position: relative;
    }

    .addUnit {
        margin-bottom: 10px;
    }

    .unitSet {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .unitSet_left {
        flex: 1;
    }

    .unitSet_right {
        flex: 1;
        margin-top: 30px;
        width: 100%;

    }

    .card_right {
        margin-top: 20px;
        width: 70%;
        height: 220px;
        overflow-y: auto;
    }

    .unitSet_right .word {
        color: #606266;
        font-weight: 500;
    }



    .icon-huishouzhan {
        font-size: 20px;
        color: #606266;
        margin-right: 10px;
        background-color: #fff;
    }

    .el-table--scrollable-y .el-table__body-wrapper {
        overflow-y: hidden;
    }

    .el-table--scrollable-y .el-table__body-wrapper:hover {
        overflow-y: auto;
    }



    .recycle_card {
        margin-top: 20px;
    }

    .el-select {
        margin-right: 10px;
    }

    .search {
        margin-right: 11%;
    }

    /deep/.addProduct__dialog--style .el-dialog__body {
        display: flex;
        flex-direction: column;
        max-height: 560px;
    }

    /* el-table 滚动条样式 */
div /deep/.addProduct__dialog--style .el-dialog__body::-webkit-scrollbar{
    height: 8px;
    width: 6px;
 }

 

div /deep/.addProduct__dialog--style .el-dialog__body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

    .input_widthStyle {
        width: 70%;
    }

    .searchinput_widthStyle {
        width: 12%;
    }

    .low_input_widthStyle {
        width: 60%;
    }

    .addProduct__dialog--style .sBaseInfo ,
    .addProduct__dialog--style .sStateInfo {
        margin-bottom: 20px;
        color: #333;
        font-size: 15px;
        font-weight: bolder;
    }

    .infoIconStyle {
        color: #1f6eef;
        margin-right: 5px;
        margin-left: 5px;
        font-size: 16px;
    }


    .addProduct__dialog--style .content {
        padding: 0% 5%;
    }

    
    .addProduct__dialog--style .content .first,
    .addProduct__dialog--style .content .second,
    .addProduct__dialog--style .content .third,
    .addProduct__dialog--style .content .fourthly,
    .addProduct__dialog--style .content .fifth,
    .addProduct__dialog--style .content .seventh,
    .addProduct__dialog--style .content .sixth {
        margin-bottom: 24px;
        display: flex;
    }

    .addProduct__dialog--style .content .first span,
    .addProduct__dialog--style .content .second span,
    .addProduct__dialog--style .content .third span,
    .addProduct__dialog--style .content .fourthly span,
    .addProduct__dialog--style .content .fifth span,
    .addProduct__dialog--style .content .seventh span,
    .addProduct__dialog--style .content .sixth span {

        flex: 1;
        margin-right: 10px;
        color: #666;
        display: flex;
        align-items: center;
    }

    div /deep/.el-table__body-wrapper::-webkit-scrollbar{
  height: 8px;
  width: 6px;
}



div /deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
border-radius: 5px;
-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
background: rgba(0, 0, 0, 0.2);
}

  .remarks_proSet_style{
      margin-right: 20px;
  }


    .sConstituteStyle .first{
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .sConstituteStyle .third{
       margin-top: 20px;
    }

    .sConstituteStyle .first span:nth-child(1),
    .sConstituteStyle .third span:nth-child(1){
        font-size: 16px;
        font-weight: bolder;
        color: #333;
    }

    .sConditionStyle{
        background-color: #f5f7fa;
    }


</style>