<template>
  <div class="home_root">

    <el-container v-if="!bWorkModel" class="home-container">
      <!-- 头部区域 -->
      <el-header>
        <div>
          <div v-if="!isCollapse" class="logoArea">
            <!--logo  -->
            <div class="logoImg">
              <img src="../assets/logo.png" alt="">
            </div>
          </div>

          <div v-else class="logoHidden">
            <!--logo  -->
            <div class="logoImg">
              <img src="../assets/logo.png" alt="">
            </div>
          </div>

          <!-- 顶部标题 -->
          <!-- <span>薇碧ERP后台管理设置系统</span> -->
        </div>
        <!-- <el-button type="info" size="medium" class="back" @click="logout">退出</el-button> -->
        <div class="right">
          <span class="currentPathName--Style">{{currentPathName}}</span>
          <div class="roleInfo">
            <el-dropdown size="big" @command="instructFun">
              <span class="el-dropdown-link">
                <i v-if="roleGender=='男'" class="el-icon-user-solid roleColorMan"></i>
                <i v-else class="el-icon-user-solid roleColorWomen"></i>
                {{roleName}}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="mine">
                  <i class="el-icon-user"></i>
                  个人中心</el-dropdown-item>
                <el-dropdown-item command="loginout">
                  <i class="el-icon-switch-button"></i>
                  退出登录</el-dropdown-item>
                   <el-dropdown-item command="fnSwitchNavigation">
                  <i class="el-icon-sort"></i>
                  切换导航</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-header>
      <!-- 页面主体区域 -->
      <el-container>
        <!-- 侧边栏 -->
        <!-- 侧边栏,宽度根据是否折叠进行设置 -->
        <el-aside :width="isCollapse ? '64px' : '200px'">
          <!-- 伸缩侧边栏按钮 -->
          <div class="toggle-button" @click="toggleCollapse">|||</div>
          <!-- 侧边栏菜单 :collapse="isCollapse"（设置折叠菜单为绑定的 isCollapse 值），:collapse-transition="false"（关闭默认的折叠动画）  -->
          <el-menu :collapse="isCollapse" :collapse-transition="false" background-color="#252526" text-color="#ebebeb"
            active-text-color="#333" unique-opened router :default-active="activePath">
            <el-submenu :index="item.id+''" v-for="item in menuList" :key="item.id">
              <!-- 一级菜单模板 -->
              <template slot="title">
                <!-- 图标 -->
                <i :class="iconsObj[item.id]"></i>
                <!-- 文本  -->
                <span>{{item.authName}}</span>
              </template>

              <div v-for="subItem in item.children" :key="subItem.id">
                <!-- 二级菜单 -->
                <el-menu-item :index="'/'+subItem.path" @click="saveNavState(subItem)"
                  v-if="subItem.children == undefined">
                  <!-- <el-menu-item >111111 -->
                  <!-- 二级菜单模板 -->
                  <template slot="title">
                    <!-- 图标 -->
                    <!-- <i :class="iconsObj[subItem.id]"></i> -->
                    <!-- 文本 -->
                    <span>{{subItem.authName}}</span>
                  </template>
                </el-menu-item>

                <!-- 二级菜单 -->
                <el-submenu v-else :index="subItem.id+''">
                  <!-- 一级菜单模板 -->
                  <template slot="title">
                    <!-- 图标 -->
                    <i :class="iconsObj[subItem.id]"></i>
                    <!-- 文本  -->
                    <span>{{subItem.authName}}</span>
                  </template>
                  <!-- 三级菜单 -->
                  <el-menu-item :index="'/'+grandSonItem.path" v-for="grandSonItem in subItem.children"
                    :key="grandSonItem.id" @click="saveNavState(grandSonItem)">

                    <!-- 三级菜单模板 -->
                    <template slot="title">
                      <!-- 图标 -->
                      <!-- <i :class="iconsObj[grandSonItem.id]"></i> -->
                      <!-- 文本 -->
                      <span>{{grandSonItem.authName}}</span>
                    </template>
                  </el-menu-item>
                </el-submenu>
              </div>



            </el-submenu>
          </el-menu>
        </el-aside>
        <!-- 主体结构 -->
        <el-main>
          <!-- 路由占位符 -->
          <router-view></router-view>
        </el-main>

      </el-container>

    </el-container>


    <div v-else class="home-container_work">
      
      <div class="top"> 
        <span class="sLogoStyle">
           薇碧ERP工作台
           <!-- <div class="imageStyle">
             <img src="../assets/logomini.png" alt="">
           </div> -->
        </span>
       <span>
      
        <!-- 侧边栏菜单 :collapse="isCollapse"（设置折叠菜单为绑定的 isCollapse 值），:collapse-transition="false"（关闭默认的折叠动画）  -->
          <el-menu  mode="horizontal" :collapse-transition="false" background-color="#252526" text-color="#ebebeb" class="workStyle"
            active-text-color="#ffd04b" unique-opened router :default-active="activePath">
            <el-submenu :index="item.id+''" v-for="item in menuList" :key="item.id">
              <!-- 一级菜单模板 -->
              <template slot="title">
                <!-- 图标 -->
                <i :class="iconsObj[item.id]"></i>
                <!-- 文本  -->
                <span>{{item.authName}}</span>
              </template>

              <div v-for="subItem in item.children" :key="subItem.id">
                <!-- 二级菜单 -->
                <el-menu-item :index="'/'+subItem.path" @click="saveNavState(subItem)"
                  v-if="subItem.children == undefined">
                  <!-- <el-menu-item >111111 -->
                  <!-- 二级菜单模板 -->
                  <template slot="title">
                    <!-- 图标 -->
                    <!-- <i :class="iconsObj[subItem.id]"></i> -->
                    <!-- 文本 -->
                    <span>{{subItem.authName}}</span>
                  </template>
                </el-menu-item>

                <!-- 二级菜单 -->
                <el-submenu v-else :index="subItem.id+''">
                  <!-- 一级菜单模板 -->
                  <template slot="title">
                    <!-- 图标 -->
                    <i :class="iconsObj[subItem.id]"></i>
                    <!-- 文本  -->
                    <span>{{subItem.authName}}</span>
                  </template>
                  <!-- 三级菜单 -->
                  <el-menu-item :index="'/'+grandSonItem.path" v-for="grandSonItem in subItem.children"
                    :key="grandSonItem.id" @click="saveNavState(grandSonItem)">

                    <!-- 三级菜单模板 -->
                    <template slot="title">
                      <!-- 图标 -->
                      <!-- <i :class="iconsObj[grandSonItem.id]"></i> -->
                      <!-- 文本 -->
                      <span>{{grandSonItem.authName}}</span>
                    </template>
                  </el-menu-item>
                </el-submenu>
              </div>



            </el-submenu>
          </el-menu>
             
       </span>

       <span>
          <div class="roleInfo_work_style">
            <el-dropdown size="big" @command="instructFun">
              <span class="el-dropdown-link">
                <i v-if="roleGender=='男'" class="el-icon-user-solid roleColorMan"></i>
                <i v-else class="el-icon-user-solid roleColorWomen"></i>
                {{roleName}}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="mine">
                  <i class="el-icon-user"></i>
                  个人中心</el-dropdown-item>
                <el-dropdown-item command="loginout">
                  <i class="el-icon-switch-button"></i>
                  退出登录</el-dropdown-item>
                   <el-dropdown-item command="fnSwitchNavigation">
                  <i class="el-icon-sort"></i>
                  切换导航</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
       </span>
      </div>

      <div class="middle">
        <span>{{currentPathName}}</span> 
      </div>

      <div class="bottom">
             <!-- 路由占位符 -->
          <router-view></router-view>
      </div>
   
    </div>
  </div>
</template>

<script>
  import {
    domainApi
  } from '../config'


  export default {
    data() {
      return {
        currentPathName: '与薇碧一起成长，创百年品牌',
        // 左侧菜单数据
        // menuList:[
        //   {
        //     "id": 101,
        //     "authName": "采购入库",
        //     "path": 'purchase',
        //   },
        //    {
        //     "id": 102,
        //     "authName": "员工管理",
        //     "path": 'employeemanagement'
        //   },
        //    {
        //     "id": 103,
        //     "authName": "商品设置",
        //     "path": 'goodsSet'
        //   },
        //    {
        //     "id": 104,
        //     "authName": "客户设置",
        //     "path": 'customerSet'
        //   },
        menuList: [{
            "id": 1,
            "authName": '库存管理',
            children: [{
                "id": 101,
                "authName": '采购入库',
                "path": 'purchase'
              },
              {
                "id": 102,
                "authName": '仓库调拨',
                "path": 'allocation'
              },
              {
                "id": 103,
                "authName": '产品报废',
                "path": 'productScrap'
              },
              {
                "id": 104,
                "authName": '仓库盘点',
                "path": 'WarehouseInventory'
              },
              {
                "id": 105,
                "authName": '批次管理',
                "path": 'batchManage'
              },
              {
                "id": 106,
                "authName": '产品列表',
                "path": 'productList'
              },
            ]
          },
          {
            "id": 2,
            "authName": '业务管理',
            children: [{
                "id": 201,
                "authName": '员工管理',
                "path": 'employeemanagement'
              },
              {
                "id": 202,
                "authName": '客户管理',
                "path": 'customerManagement',
              },
              {
                "id": 205,
                "authName": '财务管理',
                "path": 'cusFinancialManagementAll',
              },
              {
                "id": 203,
                "authName": '订单管理',
                "path": 'OrderManagementAll',
              },
              {
                "id": 204,
                "authName": '开票管理',
                "path": 'invoice',
              },
              {
                "id": 206,
                "authName": '退货管理',
                "path": 'salesReturn',
              },

            ]
          },
          {
            "id": 3,
            "authName": '系统设置',
            children: [{
                "id": 103,
                "authName": '商品设置',
                children: [{
                    "id": 1031,
                    "authName": '单位设置',
                    "path": 'unitSet'
                  }, {
                    "id": 1032,
                    "authName": '品牌设置',
                    "path": 'brandSet'
                  }, {
                    "id": 1033,
                    "authName": '仓库设置',
                    "path": 'wareHouseSet'
                  },
                  {
                    "id": 1035,
                    "authName": '产品设置',
                    "path": 'productsSet'
                  },
                  {
                    "id": 1034,
                    "authName": '商品设置',
                    "path": 'goodsSet'
                  },
                ]
              },
              {
                "id": 104,
                "authName": '客户设置',
                children: [{
                    "id": 1041,
                    "authName": '配送方案设置',
                    "path": 'distributionScheme'
                  },
                  {
                    "id": 1042,
                    "authName": '客户订单起订设置',
                    "path": 'customerMOQ'
                  },
                  {
                    "id": 1043,
                    "authName": '信用等级设置',
                    "path": 'creditRating'
                  },
                  {
                    "id": 1044,
                    "authName": '折扣设置',
                    "path": 'discountSet'
                  },
                  {
                    "id": 1045,
                    "authName": '客户分组设置',
                    "path": 'customerGroup'
                  },
                  {
                    "id": 1046,
                    "authName": '客户设置',
                    "path": 'customerSet'
                  },
                  {
                    "id": 1047,
                    "authName": '配送类型设置',
                    "path": 'distributionType'
                  },
                ]
              }
            ]
          },

        ],
        //    {
        //     "id": 103,
        //     "authName": "产品设置",
        //     "path": 'productset',

        //   },
        //    {
        //     "id": 104,
        //     "authName": "仓库管理",
        //     "path": 'warehouseset',
        // //     "children": [
        // //     {
        // //         "id": 104,
        // //         "authName": "商品列表",
        // //         "path": null,
        // //         "children": []
        // //     }
        // // ]
        //   },
        // ],
        iconsObj: {
          '1': 'iconfont icon-cangku_kucun_o',
          '2': 'iconfont icon-qiyeyewu',
          '3': 'iconfont icon-xitong',
          '101': 'iconfont icon-caigou',
          '201': 'iconfont icon-yuangong',
          '202': 'iconfont icon-kehu1',
          '104': 'iconfont icon-kehu1',
          '125': 'iconfont icon-user',
          '103': 'el-icon-goods',
          '1041': 'iconfont icon-icon-peisongfanganguanli',
          '1042': 'iconfont icon-qidingliang',
          '1043': 'iconfont icon-xinyongdengji',
          '1044': 'iconfont icon-tubiao_youhuizhekou',
          '1045': 'iconfont icon-fenzu',
          '1046': 'iconfont icon-kehu',
          '1047': 'iconfont icon-type',

        },
        // 是否折叠
        isCollapse: true,
        //动态绑定路径
        activePath: '',
        roleName: '',
        roleGender: '',

        // 工作模式导航切换
        bWorkModel:true,

      }
    },
    created() {
      // 在created阶段请求左侧数据
      this.getMenuList();
      //在页面刷新时,赋值给路径
      this.activePath = window.sessionStorage.getItem('activePath');
      this.getRoleInfo()
    },
    updated() {
      this.judgeStateFun()
    },
    methods: {

      // 判断状态
      async judgeStateFun() {
        const res = await this.$https.post(domainApi + '/status?_ajax=1')
        this.judgeState(res)
      },
      judgeState(res) {
        if (res.data.error_code == 1000) {
          this.$confirm('您现在还未登录, 请扫码登录', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'error'
          }).then(() => {
            this.$router.push({
              path: '/login',
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            });
          });
        }
      },


      instructFun(data) {
        console.log(data,'data');
        if (data == 'loginout') {
          this.logout()
        } 
        if(data == 'mine') {
          this.$router.push({
            path: '/welcome'
          })
        }
        if(data == 'fnSwitchNavigation') {
            this.bWorkModel=!this.bWorkModel
        }

      },
      async logout() {
        const res = await this.$https.get(domainApi + '/logout?_ajax=1')
        this.judgeState(res)
        if (res.data.error_code !== 0) return this.$message.error(res.data.msg)
        this.$message.success(res.data.msg)
        this.$router.push('/login')
        // 退出就是清除token
        //  let value=this.$cookies.remove('PHPSESSID')
        // console.log(value,'value');
        // this.$cookies.set('PHPSESSID','')
        // let all=this.$cookies.keys()
        // console.log(all,'all');
        //    let val=this.$cookies.get('PHPSESSID')
        // console.log(val,'val');
        // window.sessionStorage.clear();
        // console.log('token');
        // this.$router.push('/login')
      },
      async getMenuList() {
        // 发送请求获取左侧数据
        //  const {data : res}= await this.$https.get('menus');
        //  if(res.meta.status!==200) return this.$message.error(res.meta.msg)

        //  this.menuList=res.data
        //  console.log(res,'左侧');
      },
      // 点击按钮,切换菜单的折叠
      toggleCollapse() {
        this.isCollapse = !this.isCollapse
      },
      //保存路径
      saveNavState(data) {
        window.sessionStorage.setItem('activePath', data.path);
        this.activePath = '/' + data.path;
        this.currentPathName = data.authName


      },

      // 获取角色信息
      async getRoleInfo() {
        const {
          data
        } = await this.$https.post(domainApi + '/profile?_ajax=1')
        if (data == undefined) return this.$message.error('获取登录信息失败')
        this.roleName = data.name
        this.roleGender = data.gender

      },
    }
  }
</script>


<style lang="less" scoped>
  .con::-webkit-scrollbar,

  .home_root::-webkit-scrollbar {

    height: 8px;
    width: 2px;
  }




  .home_root::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }

  .home_root {
    height: 100%;

    overflow: auto;
  }

  .home-container {
    height: 100%;
  }









  .el-header {
    background-color: #ffffff;
    display: flex;
    height: 56px;
    padding-left: 0;
    align-items: center;
    color: #fff;
    font-size: 20px;
    position: relative;

    >div {
      display: flex;
      align-items: center;

      span {
        margin-left: 15px;
      }
    }
  }

  .back {
    position: absolute;
    right: 5%;
  }

  .el-aside {
    height: 100%;
    // background-color: #1c2233;
    background-color: #252526;

    box-sizing: border-box;

    .el-menu {
      border-right: none;
      // background-color: #1c2233;
      background-color: #252526;

    }
  }

  .el-main {
    // background-color: #f5f5f5;
    background-color: #f7f8fa;
    padding: 16px;
    box-sizing: border-box;
  }

  .iconfont {
    margin-right: 10px;
  }

  .toggle-button {

    background-color: #393d49;
    font-size: 10px;
    line-height: 24px;
    color: #c4c5c9;
    text-align: center;
    letter-spacing: 0.2em;
  }

  .toggle-button :hover {
    cursor: pointer;
  }


  .icon-caidanlan-caigou-caigoudaohuotixing {
    font-size: 24px;
  }

  .el-header .right {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .currentPathName--Style {
    color: #333;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding-left: 1%;
  }



  .roleInfo {
    width: 132px;
    height: 32px;
    position: absolute;
    right: 2%;

    display: flex;
    align-items: center;
    justify-content: center;
    color: #707070;
    border-radius: 3px;
  }

  .roleInfo_work_style {
     width: 132px;
    height: 40px;
    position: absolute;
    right: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #707070;
    border-radius: 3px;
  }

    .roleInfo_work_style .el-dropdown{
     color: #fff;
  }


  .roleInfo:hover ,
  .roleInfo_work_style:hover {
    cursor: pointer;
  }

  

  .roleColorMan {
    color: #7da4d1;
  }

  .roleColorWomen {
    color: #f56c86;
  }



  // .roleInfo i:first-child{
  //     font-size: 20px;
  // }

  // .roleInfo i:last-child{
  //   margin-left: 4px;
  //  font-size: 16px;
  //   display: flex;
  //   align-items: center;
  //   vertical-align: middle;

  // }

  // .roleInfo span{
  //   margin-left: 16px !important;
  //   color: #333;
  //   font-size: 16px;
  //   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  // }

  .imgArea {
    width: 5%;
    position: absolute;
    bottom: 5%;
    right: 40%;
    // transform: translateX(50%);
    border-radius: 5px;
  }

  .imgArea img {
    width: 100%;
  }

  .logoArea {
    width: 200px;
    height: 56px;
    // background-color: #1c2233;
    background-color: #252526;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;

  }

  .logoHidden {
    display: block;
    background-color: #252526;
    width: 64px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }



  .logoImg {
    background-color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-top: 2px;
  }



  .logoImg img {
    width: 80%;
    height: auto;
  }

  // 工作模式
  .home-container_work {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;


  }

  .home_root .home-container_work .top{
    height: 40px;
    display: flex;
    background-color: #252526 ;
    padding-left: 20px;
    box-sizing: border-box;

  }

  div /deep/.workStyle .el-menu,
  div /deep/.workStyle .el-submenu,
  div /deep/.workStyle .el-submenu__title{
    height: 40px !important;
    line-height: 40px;
  }

   div /deep/.workStyle .el-menu {
    height: 40px !important;
  }

  .home_root .home-container_work .bottom{
    height: auto;
    border:none;
    padding: 10px 16px;
    background-color: #f7f8fa;
    overflow-y: scroll;

  }

  .sLogoStyle{
    color: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    margin-right: 10px;

  }

  .home-container_work .middle{
    background-color:#fff;
    height: 38px;
    width: 100%;
    font-size: 14px;
    color: #666;
    display: flex;
    align-items: center;
    padding-left: 20px;
    border: none;
    box-sizing: border-box;

  }

  .home-container_work .middle span{
    background-color: #f7f8fa;
    border-radius: 10px 10px 0 0 ;
    padding: 10px 20px;
  }

  /* el-table 滚动条样式 */
.home_root .home-container_work .bottom::-webkit-scrollbar{
    width: 13px;
 }

 

.home_root .home-container_work .bottom::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

/* el-menu 选中背景色修改 */
  .home-container .el-menu-item.is-active { 
  /* background-color: #536183 !important; */
  background-color:#f7f8fa !important;
  /* background-color: #f5f5f5 !important;
  border-right: 1x solid #f5f5f5 !important;
  color: #333333 !important; */
} 

  .imageStyle{
    width: 30px;
    height: 30px;
    // background-color: #fafafa;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .imageStyle img{
    width: 80%;
    height: 80%;
  }

</style>