<template>
    <div id="app">
        <!-- 面包屑导航 -->
        <!-- <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>业务管理</el-breadcrumb-item>
            <el-breadcrumb-item>客户管理</el-breadcrumb-item>
            <el-breadcrumb-item>客户详情</el-breadcrumb-item>
        </el-breadcrumb> -->

        <el-card v-show="skeletonState">
            <el-skeleton :rows="6" animated />
        </el-card>

        <el-card v-show="skeletonState">
            <el-skeleton :rows="9" animated />
        </el-card>

        <el-card v-show="!skeletonState" class="sCustomerDetailStyle">

            <div class="sCustomerDetailTopStyle">
                <div class="tit">
                    <div class="name">{{lookDetailData.name}}
                        <span class="status">
                            <el-tag v-if="lookDetailData.is_enable" size="small">启用</el-tag>
                            <el-tag v-else type="info" size="small">未启用</el-tag>
                        </span></div>
                    <div class="alias"> 别名： {{lookDetailData.alias}} <span class="sTypeStyle">类型：
                            {{lookDetailData.is_single?'单店':'连锁'}}</span></div>
                    <el-divider></el-divider>
                </div>
                <div class="content" :class="dialogscreen?'sCustomerDetailTopSmallScreenStyle':' '">
                    <span>
                        <div class="left">客户编号:</div>
                        <div class="right">{{lookDetailData.number}}</div>
                    </span>
                    <span>
                        <div class="left">消费:</div>
                        <div class="right"><i class="NumberNoLeft">￥{{lookDetailData.spend}}</i></div>
                    </span>
                    <span>
                        <div class="left">信用消费:</div>
                        <div class="right"><i class="NumberNoLeft">￥{{lookDetailData.credit_spend}} </i></div>
                    </span>
                    <span>
                        <div class="left">预存款:</div>
                        <div class="right"><i class="NumberNoLeft">￥{{lookDetailData.account}} </i></div>
                    </span>
                    <span>
                        <div class="left">保证金:</div>
                        <div class="right"><i class="NumberNoLeft">￥{{lookDetailData.deposit}} </i></div>
                    </span>
                    <span>
                        <div class="left">可用信用额:</div>
                        <div class="right"><i class="NumberNoLeft">￥{{lookDetailData.credit_limit}} </i></div>
                    </span>

                    <span>
                        <div class="left">已开发票:</div>
                        <div class="right"><i class="NumberNoLeft">￥{{lookDetailData.invoice}} </i></div>
                    </span>

                </div>
            </div>

            <div v-if="!dialogscreen" class="sCustomerDetaolBtnStyle">
                <span @click="addNewOrder">创建订单
                </span>

                <span @click="OrderManagement">订单管理
                </span>

                <span @click="fnSalesReturn">退货管理
                </span>

                <span @click="doRecharge">申请入账
                </span>


                <span @click="financialManagement">财务管理
                </span>

                <span @click="freeze">冻结保证金
                </span>

                <span @click="returnCashDeposit">退还保证金
                </span>
                <span @click="transitionMoney">配送余额转换
                </span>

                <span @click="addInvoice">申请发票
                </span>
                <span @click="fnInvoiceManagement">发票管理
                </span>

                <span @click="InvoicingInfo">发票信息
                </span>


                <span @click="editCustomer">修改客户
                </span>
            </div>

            <div v-else class="sCustomerDetailBtnSmallScreenStyle">
                <div class="top">

                    <span @click="addNewOrder">创建订单
                    </span>

                    <span @click="OrderManagement">订单管理
                    </span>

                    <span @click="fnSalesReturn">退货管理
                    </span>

                    <span @click="doRecharge">申请入账
                    </span>


                    <span @click="financialManagement">财务管理
                    </span>

                    <span @click="freeze">冻结保证金
                    </span>


                </div>

                <div class="bottom">

                    <span @click="returnCashDeposit">退还保证金
                    </span>
                    <span @click="transitionMoney">配送余额转换
                    </span>

                    <span @click="addInvoice">申请发票
                    </span>
                    <span @click="fnInvoiceManagement">发票管理
                    </span>

                    <span @click="InvoicingInfo">发票信息
                    </span>

                    <span @click="editCustomer">修改客户
                    </span>
                </div>


            </div>
        </el-card>

        <el-card v-show="!skeletonState" class="sCustomerDetailTabsStyle">
            <el-tabs v-model="sCustomerDetailActiveName" type="card" @tab-click="fnCutsomerDetailHandleClick">
                <el-tab-pane label="客户概况" name="first" class="sCustomerGeneralStyle">
                    <div class="sCustomerGeneralItemStyle">
                        <span>
                            <div class="left">是否有积分:</div>
                            <div class="right"><span v-if="lookDetailData.is_integral"
                                    style="color:#409eff;font-weight:bold">是</span>
                                <span v-else style="color:#f56c6c;font-weight:bold">否</span></div>
                        </span>
                        <span>
                            <div class="left">接收消息提醒:</div>
                            <div class="right"><span v-if="lookDetailData.is_sms"
                                    style="color:#409eff;font-weight:bold">是</span>
                                <span v-else style="color:#f56c6c;font-weight:bold">否</span></div>
                        </span>
                    </div>

                    <div class="sCustomerGeneralItemStyle">
                        <span>
                            <div class="left">所属客户组:</div>
                            <div class="right">{{lookDetailData.group_full_name}}</div>
                        </span>
                        <span>
                            <div class="left">信用等级:</div>
                            <div class="right">{{lookDetailData.credit_full_name}}</div>
                        </span>
                    </div>


                    <div class="sCustomerGeneralItemStyle">
                        <span>
                            <div class="left">配送方案:</div>
                            <div class="right">{{lookDetailData.plan_name}}</div>
                        </span>
                        <span>
                            <div class="left">折扣:</div>
                            <div class="right">
                                <el-tag size="small " effect="plain">
                                    {{lookDetailData.discount_name}}
                                </el-tag>
                            </div>
                        </span>
                    </div>


                    <div class="sCustomerGeneralItemStyle">
                        <span>
                            <div class="left">起订额:</div>
                            <div class="right">{{lookDetailData.deliver_name}}</div>
                        </span>

                        <span>
                            <div class="left">更新时间:</div>
                            <div class="right">{{lookDetailData.update_time}}</div>
                        </span>
                    </div>


                    <div class="sCustomerGeneralItemStyle">
                        <span>
                            <div class="left">合同日期:</div>
                            <div class="right"><span style=" color: #606266;">{{lookDetailData.start_time}} 至
                                    {{lookDetailData.end_time}}</span></div>
                        </span>

                        <span>
                            <div class="left">开户时间:</div>
                            <div class="right">{{lookDetailData.create_time}}</div>
                        </span>
                    </div>

                    <div class="sCustomerGeneralItemStyle">
                        <span>
                            <div class="left">联系人:</div>
                            <div class="right">{{lookDetailData.customer_contact}}</div>
                        </span>

                        <span>
                            <div class="left">座机:</div>
                            <div class="right">{{lookDetailData.telephone}}</div>
                        </span>
                    </div>

                    <div class="sCustomerGeneralItemStyle">
                        <span>
                            <div class="left">手机号:</div>
                            <div class="right">{{lookDetailData.customer_mobile}}</div>
                        </span>

                        <span>
                            <div class="left">所在区县:</div>
                            <div class="right">{{lookDetailData.region_full_name}}</div>
                        </span>
                    </div>


                    <div class="sCustomerGeneralItemStyle">
                        <span>
                            <div class="left">详细地址:</div>
                            <div class="right">{{lookDetailData.address_info}}</div>
                        </span>

                    </div>


                </el-tab-pane>
                <el-tab-pane label="配送" name="second" class="DistributionType_style">
                    <div>
                        <el-button @click="DistributionTypeDo" class="frontBtn addReturnVisit" size="mini">激活配送
                        </el-button>

                        <div class="content">
                            <div class="item" v-for="item in PresentData" :key="item.id">
                                <div class="tit">{{item.present_name}}</div>
                                <div class="con" v-show="item.account">余额：{{item.account}} 元</div>
                                <div class="con" v-show="item.is_depend">依赖：<span v-if="item.is_depend"
                                        class="yesColor">是</span><span v-else class="noColor">否</span></div>
                                <div class="con" v-show="item.create_time">激活时间：{{item.create_time}}
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="负责的员工信息" name="third" class="users">
                    <div>
                        <el-tabs v-model="activeName" class="tabs_style">
                            <el-tab-pane label="客户" name="first">
                                <el-button @click="authorityAssignmentDo"
                                    class="frontBtn ManagementAuthorityAssignmentBtn" size="mini">客户管理权分配</el-button>
                                <el-table :header-cell-style="styleBindFun" :data="customer_usersData"
                                    style="width: 100%">
                                    <el-table-column align="center">
                                        <template slot="header">
                                            员工 <span style="color:#fa5a57">（点击查看详情）</span>
                                        </template>
                                        <template slot-scope="scope">

                                            <el-button @click="fnDetail(scope.row)" type="text"
                                                class="text_customername" size="big">
                                                {{scope.row.name}}</el-button>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="department_full_name" label="部门" align="center">
                                    </el-table-column>
                                    <el-table-column prop="position_name" label="职务" align="center">
                                    </el-table-column>

                                </el-table>
                            </el-tab-pane>
                            <el-tab-pane label="客户组" name="second">
                                <el-table :header-cell-style="styleBindFun" height="300" :data="group_usersData"
                                    style="width: 100%">
                                    <el-table-column prop="name" label="员工" align="center">
                                    </el-table-column>
                                    <el-table-column prop="department_full_name" label="部门" align="center">
                                    </el-table-column>
                                    <el-table-column prop="position_name" label="职务" align="center">
                                    </el-table-column>
                                </el-table>
                            </el-tab-pane>

                        </el-tabs>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="收货地址信息" name="fourth" class="ReceivingAddress">
                    <el-button @click="addReceivingAddress" class="frontBtn addReturnVisit" size="mini">新增收货地址
                    </el-button>
                    <div class="content sStoreManagementTableStyle">
                        <div class="item" v-for="item in addressInfoData" :key="item.id">
                            <div class="tit">{{item.region_full_name}}</div>
                            <div class="con take_delivery_of_goods_color_style">联系人：<span>{{item.contact.name}}</span>
                            </div>
                            <div class="con take_delivery_of_goods_color_style">手机：<span>{{item.contact.mobile}}</span>
                            </div>
                            <div class="con take_delivery_of_goods_color_style">详细地址：<span>{{item.address}}</span></div>

                            <div class="con take_delivery_of_goods_color_style">备注：<span>{{item.remarks}}</span>

                            </div>
                            <el-button size="mini" type="text" @click="editReceivingAddress(item)" class="edit">
                                修改</el-button>
                            <el-button size="mini" type="text" @click="deleteReceivingAddress(item)" class="delete">
                                删除</el-button>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="门店管理" name="fifth" class="StoreManagement">
                    <el-button @click="addStore" class="frontBtn addReturnVisit" size="mini">
                        新增门店</el-button>
                    <div class="sStoreManagementTableStyle">
                        <table width="100%" border="1" cellspacing="0" class="sStoreTableStyle">
                            <thead class="sCustomerTheadStyle">
                                <tr class="sCustomerHeadStyle">
                                    <th>门店</th>
                                    <th>座机</th>
                                    <th>门店地址</th>
                                    <th>门店联系人</th>
                                    <th>门店联系电话</th>
                                    <th>是否营业</th>
                                    <th>是否显示</th>
                                    <th>操作</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(node,index) in storeManagementData" :key="index" class="sCustomerItemStyle"
                                    @click="fnStoreClickItem(node)"
                                    :class="nCurrentStoreId == node.id ? 'sClickItemStyle' : ''">
                                    <td align="center">
                                        {{node.name}}
                                    </td>
                                    <td align="center">
                                        {{node.telephone}}
                                    </td>
                                    <td align="center">{{node.address.region_full_name}} {{node.address.address}}</td>
                                    <td align="center">{{node.address.contact.name}}</td>
                                    <td align="center">{{node.address.contact.mobile}}</td>
                                    <td align="center">
                                        <i v-if="node.is_business" class="el-icon-check iconCheck"></i>
                                        <i v-else class="el-icon-close iconClose"></i>

                                    </td>
                                    <td align="center">
                                        <i v-if="node.is_show" class="el-icon-check iconCheck"></i>
                                        <i v-else class="el-icon-close iconClose"></i>

                                    </td>


                                    <td align="center" class="sOperationStyle">
                                        <span @click="seeStoreUsers(node)" class="sRemoveStyle">查看门店员工权限</span>
                                        <span @click="editStore(node)" class="sRemoveStyle">修改</span>
                                        <span @click="deleteStore(node)" class="sDeleteStyle">删除</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="回访记录" name="sixth" class="ReturnVisitRecord">
                    <el-button @click="addReturnVisitRecord" class=" frontBtn addReturnVisit" size="mini">新增回访记录
                    </el-button>

                    <div class="remarks_search">
                        搜索：
                        <el-input class="remarks_input_style" v-model="getnoteInfo.keyword" @change="getNote" clearable
                            placeholder="请输入搜索内容" size="mini"></el-input>
                    </div>


                    <div class="sStoreManagementTableStyle">
                        <table width="100%" height="50" border="1" cellspacing="0">
                            <thead class="sCustomerTheadStyle">
                                <tr class="sCustomerHeadStyle">
                                    <th>回访内容</th>
                                    <th>工作人员</th>
                                    <th>部门</th>
                                    <th>职位</th>
                                    <th>回访时间</th>
                                    <th>操作</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(node,index) in noteInfoData" :key="index" class="sCustomerItemStyle"
                                    @click="fnNoteClickItem(node)"
                                    :class="nCurrentNoteId == node.id ? 'sClickItemStyle' : ''">

                                    <td align="center">
                                        {{node.remarks}}
                                    </td>
                                    <td align="center">
                                        {{node.user_name}}
                                    </td>
                                    <td align="center">{{node.department_full_name}}</td>
                                    <td align="center">{{node.position_name}}</td>
                                    <td align="center">{{node.create_time}}</td>



                                    <td align="center">

                                        <span @click="deleteReturnVR(node)" class="sDeleteStyle">删除</span>
                                    </td>
                                </tr>
                                <tr v-show="noteInfoData.length == 0">
                                    <td colspan="6" class="sNoDataStyle">暂无数据</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <el-pagination @current-change="handleCurrentChangeGetNote" :current-page="getnoteInfo.page"
                        :page-sizes="[5]" :page-size="getnoteInfo.per_page"
                        layout="total, sizes, prev, pager, next, jumper" :total="noteTotal" align="center">
                    </el-pagination>
                </el-tab-pane>
                <el-tab-pane label="备注" name="seventh" class="remarks">
                    <el-button @click="addRemarks" class="addReturnVisit frontBtn" size="mini">新增备注</el-button>
                    <div class="remarks_search">
                        搜索：
                        <el-input class="remarks_input_style" v-model="getRemarksInfo.keyword" @change="getRemarks"
                            clearable placeholder="请输入搜索内容" size="mini"></el-input>
                    </div>


                    <div class="sStoreManagementTableStyle">
                        <table width="100%" height="50" border="1" cellspacing="0">
                            <thead class="sCustomerTheadStyle">
                                <tr class="sCustomerHeadStyle">
                                    <th>备注内容</th>
                                    <th>工作人员</th>
                                    <th>部门</th>
                                    <th>职位</th>
                                    <th>备注时间</th>
                                    <th>操作</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(node,index) in remarksInfoData" :key="index" class="sCustomerItemStyle"
                                    @click="fnRemarksClickItem(node)"
                                    :class="nCurrentRemarksId == node.id ? 'sClickItemStyle' : ''">

                                    <td align="center">
                                        {{node.remarks}}
                                    </td>
                                    <td align="center">
                                        {{node.user_name}}
                                    </td>
                                    <td align="center">{{node.department_full_name}}</td>
                                    <td align="center">{{node.position_name}}</td>
                                    <td align="center">{{node.create_time}}</td>



                                    <td align="center">

                                        <span @click="deleteRemarks(node)" class="sDeleteStyle">删除</span>
                                    </td>
                                </tr>
                                <tr v-show="remarksInfoData.length == 0">
                                    <td colspan="6" class="sNoDataStyle">暂无数据</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <el-pagination @current-change="handleCurrentChangeGetRemarks" :current-page="getRemarksInfo.page"
                        :page-sizes="[5]" :page-size="getRemarksInfo.per_page"
                        layout="total, sizes, prev, pager, next, jumper" :total="remarksTotal" align="center">
                    </el-pagination>
                </el-tab-pane>
                <el-tab-pane label="操作日志" name="eighth" class="logs">
                    <div class="title">操作日志：</div>

                    <div class="content">
                        <el-timeline>
                            <el-timeline-item v-for="(activity, index) in logsData" :key="index" icon="el-icon-check"
                                type="primary" size="activity.large" :timestamp="activity.create_time">
                                <span class="logItem_style">{{activity.log}}</span> --<span
                                    class="textBtnColor">{{activity.user_name}}</span>
                            </el-timeline-item>
                        </el-timeline>

                        <!-- 分页器 -->
                        <el-pagination @current-change="handleCurrentChangelogs" :current-page="this.getlogs_form.page"
                            :page-size="this.getlogs_form.per_page" layout="total, prev, pager, next, jumper"
                            :total="logsTotal" align="center">
                        </el-pagination>
                    </div>
                </el-tab-pane>

            </el-tabs>
        </el-card>





        <!-- ------------------------------------------------- 对话框（一层）-------------------------------------------- -->
        <!-- 创建备注对话框 -->
        <el-dialog title="备注" :visible.sync="is_addRemarks" :width="dialogscreen?'50%':'30%'"
            class="addRemarksOfcustomerDetail">
            <el-form label-width="120px">

                <el-form-item label="备注信息：">
                    <el-input placeholder="请填写" class="remarksContent" v-model="addRemarks_content" type="textarea"
                        size="medium">
                    </el-input>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_addRemarks = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" type="primary" @click="addRemarksDo">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 创建回访记录对话框 -->
        <el-dialog title="回访记录" :visible.sync="is_ReturnVisitRecord" :width="dialogscreen?'50%':'30%'"
            class="addReturnVisitRecordOfcustomerDetail">
            <el-form :model="Form_addReturnVisitRecord" label-width="120px">
                <el-form-item label="回访记录信息：">
                    <el-input placeholder="请填写" type="textarea" class="remarksContent"
                        v-model="Form_addReturnVisitRecord.remarks">
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_ReturnVisitRecord = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" type="primary" @click="addReturnVisitRecordDo">确 定</el-button>
            </div>
        </el-dialog>



        <!-- 创建门店对话框 -->
        <el-dialog title="请填写门店信息" :visible.sync="is_StoreManagement" :width="dialogscreen?'60%':'36%'"
            class="edit_Store_style">
            <el-form :model="Form_addStoreManagement" label-width="120px">
                <el-form-item label="区域：">
                    <el-cascader :options="RegionData" clearable v-model="choosedRegion"
                        :props="{value:'id',label:'name',checkStrictly: true}" size="small">
                    </el-cascader>
                </el-form-item>

                <el-form-item label="详细地址：">
                    <el-input placeholder="请填写" v-model="Form_addStoreManagement.address" size="small"></el-input>
                </el-form-item>

                <el-form-item label="门店名：">
                    <el-input placeholder="请填写" v-model="Form_addStoreManagement.name" size="small"></el-input>
                </el-form-item>

                <el-form-item label="门店电话：">
                    <el-input placeholder="请填写" v-model="Form_addStoreManagement.telephone" size="small"></el-input>
                </el-form-item>

                <el-form-item label="联系人：">
                    <el-input placeholder="请填写" v-model="Form_addStoreManagement.contact_name" size="small"></el-input>
                </el-form-item>

                <el-form-item label="联系人电话：">
                    <el-input placeholder="请填写" v-model="Form_addStoreManagement.contact_mobile" size="small">
                    </el-input>
                </el-form-item>

                <el-form-item label="备注：">
                    <el-input placeholder="请填写" type="textarea" v-model="Form_addStoreManagement.remarks"></el-input>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_StoreManagement = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" type="primary" @click="addStoreDo">确 定</el-button>
            </div>
        </el-dialog>


        <!-- 创建收货地址对话框 -->
        <el-dialog title="请填写收货地址" :visible.sync="is_addReceivingAddress" :width="dialogscreen?'60%':'36%'"
            class="edit_Store_style">
            <el-form :model="Form_addReceivingAddress" :rules="Form_addReceivingAddressRules" label-width="120px">
                <el-form-item label="区域：" prop="region_id">
                    <el-cascader :options="RegionData" clearable v-model="choosedRegion"
                        :props="{value:'id',label:'name',checkStrictly: true}" size="small">
                    </el-cascader>
                </el-form-item>

                <el-form-item label="详细地址：" prop="address">
                    <el-input placeholder="请输入" v-model="Form_addReceivingAddress.address" size="small"></el-input>
                </el-form-item>

                <el-form-item label="联系人：" prop="name">
                    <el-input placeholder="请输入" v-model="Form_addReceivingAddress.name" size="small"></el-input>
                </el-form-item>

                <el-form-item label="联系人电话：" prop="mobile">
                    <el-input placeholder="请输入" v-model="Form_addReceivingAddress.mobile" size="small"></el-input>
                </el-form-item>

                <el-form-item label="排序：" prop="mobile">
                    <el-input placeholder="默认100，范围0~200" v-model="Form_addReceivingAddress.sort_order" size="small">
                    </el-input>
                </el-form-item>

                <el-form-item label="备注：" prop="remarks">
                    <el-input placeholder="请输入" type="textarea" size="small" v-model="Form_addReceivingAddress.remarks">
                    </el-input>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_addReceivingAddress = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" type="primary" v-loading.fullscreen.lock="fullscreenLoading"
                    @click="addReceivingAddressDo">确 定</el-button>
            </div>
        </el-dialog>


        <!-- 修改收货地址对话框 -->
        <el-dialog title="修改收货地址" :visible.sync="is_editReceivingAddress" :width="dialogscreen?'60%':'36%'"
            class="edit_Store_style">
            <el-form :model="Form_addReceivingAddress" :rules="Form_addReceivingAddressRules" label-width="120px">

                <el-form-item label="区域：" prop="region_id">
                    <el-cascader :options="RegionData" clearable v-model="choosedRegion"
                        :props="{value:'id',label:'name',checkStrictly: true}" size="small">
                    </el-cascader>
                </el-form-item>



                <el-form-item label="详细地址：" prop="address">
                    <el-input v-model="Form_addReceivingAddress.address" size="small"></el-input>
                </el-form-item>



                <el-form-item label="联系人：" prop="name">
                    <el-input v-model="Form_addReceivingAddress.name" size="small"></el-input>
                </el-form-item>



                <el-form-item label="联系人电话：" prop="mobile">
                    <el-input v-model="Form_addReceivingAddress.mobile" size="small"></el-input>
                </el-form-item>


                <el-form-item label="排序：" prop="name">
                    <el-input v-model="Form_addReceivingAddress.sort_order" size="small"></el-input>
                </el-form-item>

                <el-form-item label="备注：" prop="remarks">
                    <el-input type="textarea" v-model="Form_addReceivingAddress.remarks"></el-input>
                </el-form-item>




            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_editReceivingAddress = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" type="primary" v-loading.fullscreen.lock="fullscreenLoading"
                    @click="editReceivingAddressDo">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 修改门店对话框 -->
        <el-dialog title="修改门店" :visible.sync="is_editStore" :width="dialogscreen?'60%':'36%'" class="edit_Store_style">
            <el-form label-width="120px">
                <el-form-item label="区域：">
                    <el-cascader :options="RegionData" clearable v-model="choosedRegion"
                        :props="{value:'id',label:'name',checkStrictly: true}" size="medium">
                    </el-cascader>
                </el-form-item>

                <el-form-item label="详细地址：">
                    <el-input v-model="Form_addStoreManagement.address" size="medium"></el-input>
                </el-form-item>




                <el-form-item label="门店名称：">
                    <el-input v-model="Form_addStoreManagement.name" size="medium"></el-input>
                </el-form-item>

                <el-form-item label="门店电话：">
                    <el-input v-model="Form_addStoreManagement.tel" size="medium"></el-input>
                </el-form-item>




                <el-form-item label="联系人名称：">
                    <el-input v-model="Form_addStoreManagement.contact_name" size="medium"></el-input>
                </el-form-item>

                <el-form-item label="联系人电话：">
                    <el-input v-model="Form_addStoreManagement.contact_mobile" size="medium"></el-input>
                </el-form-item>

                <el-form-item label="排序：">
                    <el-input v-model="Form_addStoreManagement.sort_order" size="medium"></el-input>
                </el-form-item>

                <el-form-item label="备注：" style="margin-left:7%">
                    <el-input type="textarea" v-model="Form_addStoreManagement.remarks"></el-input>
                </el-form-item>


            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_editStore = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" type="primary" @click="editStoreDo">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 客户管理权分配对话框 -->
        <el-dialog title="管理权分配" :visible.sync="is_usersDistribution" :width="dialogscreen?'56%':'36%'"
            class="bindUserGroup">
            <div class="all">
                <div class="left  setSupSubStyle">
                    <!-- 输入框 -->
                    <el-input clearable placeholder="搜索员工" v-model="search_keywords" @change="searchFunction"
                        class="setSuperior__search__style" size="small" prefix-icon="el-icon-search">
                    </el-input>

                    <div v-if="is_searchRes" class="setSuperior__search__result__style">
                        <div class="tit">成员</div>
                        <div class="content">
                            <span v-for="(list,index) in search__result__Data" :key="index" class="item"
                                @click="selectStaffFun(list,index)"
                                :class="index==0?'active__setsuperior__style':'hover__setsuperior__style'">
                                <i class='el-icon-s-custom'></i>
                                {{list.name}}
                            </span>
                        </div>
                    </div>

                    <div v-else>
                        <el-tree :data="userInfoData" accordion :props="defaultProps" class="addDAMElTree" node-key="id"
                            :default-expanded-keys="user_data_ids" @node-click="handleNodeClick_Choose">
                            <span class="bindUserGroup-tree-node" slot-scope="{ data }">
                                <span>{{data.name}}</span>
                                <i :class="data.mychecked==true?'el-icon-check':' '" class="ckeckStyle"></i>
                            </span>
                        </el-tree>
                    </div>



                </div>
                <div class="right setSupSubStyle">
                    <div class="tit">已选：</div>
                    <div>
                        <ul>
                            <li v-for="(node,index) in user_selected_data" :key="index" class="itemStyle">
                                <span class="departmentName">{{ node.name }}</span>
                                <i class='el-icon-close closeIcon' @click="deleteSelected(node)"></i>
                            </li>
                        </ul>
                    </div>


                </div>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_usersDistribution = false">取 消
                </el-button>
                <el-button size="mini" class="frontBtn" @click="submitUsersDo">确 定
                </el-button>
            </div>
        </el-dialog>

        <!-- 开票信息对话框 -->
        <el-dialog title="查看开票信息" :visible.sync="is_InvoicingInfo" :width="dialogscreen?'82%':'60%'">
            <div class="invoicingInfo_style">

                <div class="invoicingInfo_btns_style">
                    <el-button class="editBtn" v-show="is_eidtBtns" size="mini" @click="editinvoicingInfo">编 辑
                    </el-button>
                    <el-button class="editBtn" v-show="!is_eidtBtns" size="mini" @click="saveinvoicingInfo">保 存
                    </el-button>
                    <el-button v-show="!is_eidtBtns" size="mini" @click="cancelinvoicingInfo">取 消</el-button>
                </div>

                <div class="invoicingInfo_tit_style">
                    <i class="el-icon-info iconColor"></i> {{invoicingInfoData.customer_name}}
                </div>

                <div class="invoicingInfo_con_style">
                    <div>
                        <span>税务代码：
                            <span v-show="is_eidtBtns">{{invoicingInfoData.register_number}}</span>
                            <el-input class="input_width" v-show="!is_eidtBtns"
                                v-model="editinvoicingInfo_form.register_number" size="mini" clearable></el-input>
                        </span>
                        <span>银行：
                            <span v-show="is_eidtBtns">{{invoicingInfoData.bank}}</span>
                            <el-input class="input_width" v-show="!is_eidtBtns" v-model="editinvoicingInfo_form.bank"
                                size="mini" clearable></el-input>

                        </span>
                        <span>银行账号：
                            <span v-show="is_eidtBtns">{{invoicingInfoData.bank_number}}</span>
                            <el-input class="input_width" v-show="!is_eidtBtns"
                                v-model="editinvoicingInfo_form.bank_number" size="mini" clearable></el-input>

                        </span>
                    </div>
                    <div>
                        <span>地址：
                            <span v-show="is_eidtBtns">{{invoicingInfoData.register_address}}</span>
                            <el-input class="input_width" v-show="!is_eidtBtns"
                                v-model="editinvoicingInfo_form.register_address" size="mini" clearable></el-input>

                        </span>
                        <span>电话：<span v-show="is_eidtBtns">{{invoicingInfoData.register_phone}}</span>
                            <el-input class="input_width" v-show="!is_eidtBtns"
                                v-model="editinvoicingInfo_form.register_phone" size="mini" clearable></el-input>

                        </span>
                        <span>更新时间：<span>{{invoicingInfoData.update_time}}</span>

                        </span>
                    </div>
                </div>


            </div>




            <div slot="footer" class="dialog-footer">
                <el-button size="mini" class="frontBtn" type="primary" @click="is_InvoicingInfo = false">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 申请入账抽屉 -->
        <el-drawer title="申请入账" :visible.sync="bEntryDrawer" :direction="sEntryDirection" size="55%"
            class="sEntryDrawer">
            <div class="content">
                <el-form status-icon :model="Form_Recharge" :rules="RechargeRules" ref="ruleForm" label-width="100px"
                    class="recharge-ruleForm">
                    <div class="base">
                        <div class="tit aicenter"><span class="sRectangleStyle"></span>入账信息
                        </div>

                        <div class="first">
                            <el-form-item label="财务类型:" class="el-form_item" prop="present_id">
                                <el-select v-model="Form_Recharge.present_id" clearable placeholder="请选择" size="small"
                                    @change="changePresent_recharge" class="present_select_style">
                                    <el-option v-for="item in PresentData" :key="item.present_id"
                                        :label="item.present_name" :value="item.present_id">
                                        {{item.present_name}}
                                        <span v-show="item.account!==undefined"> 余额：<span
                                                class="NumberNoLeft">{{item.account}}</span> </span>

                                        <span v-show="item.is_depend!==undefined">
                                            依赖： <span v-if="item.is_depend" class="yesColor el-icon-check"></span> <span
                                                v-else class="noColor el-icon-close"></span>
                                        </span>

                                    </el-option>
                                </el-select>
                            </el-form-item>


                            <el-form-item label="操作类型:" class="el-form_item">
                                <el-select v-model="Form_Recharge.finance_type_id" clearable placeholder="请选择"
                                    @change="changeFinance" size="small" class="present_select_style">
                                    <el-option v-for="item in FinanceData" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>


                            <el-form-item label="金额：" class="el-form_item" prop="amount">
                                <el-input v-model="Form_Recharge.amount" size="small" clearable class="amountInput"
                                    placeholder="请严格按照格式0.00来输入">
                                </el-input>
                            </el-form-item>


                        </div>

                        <div>
                            <el-form-item label="备注：" prop="remarks">
                                <el-input v-model="Form_Recharge.remarks" size="small" clearable class="amountInput"
                                    placeholder="请说明下缘由（必填）">
                                </el-input>
                            </el-form-item>
                        </div>


                    </div>
                </el-form>

                <div class="second">
                    <div class="title aicenter">
                        <span class="sRectangleStyle"></span>添加附件
                    </div>
                    <input class="chooseFileInputStyle" type="file" v-show="fileRefState" ref="fileRef" value="选择图片"
                        @change="fileChange" />

                    <div class="chooseRechargeStyle">
                        <el-button size="mini" :disabled="chooseRechargeBtnDis" class="frontBtn"
                            @click="chooseRechargeBtn">充值单选择</el-button>

                        <span v-show="selectedRefillCardData.length>0">建议最大配送额:<i
                                class="NumberNoLeft">￥{{maxSuggestPresent}}</i>
                        </span>
                    </div>
                    <div>

                        <el-table :data="selectedRefillCardData" tooltip-effect="dark" style="width: 100%"
                            :header-cell-style="styleBindFun" border v-show="selectedRefillCardData.length>0">

                            <el-table-column prop="order_num" label="财务单"  align="center">
                            </el-table-column>
                            <el-table-column prop="amount" label="账户余额" align="center">
                                <template slot-scope="scope">
                                    ￥{{scope.row.amount}}
                                </template>
                            </el-table-column>
                        </el-table>

                    </div>
                    <div class="sEntryBtn">
                        <el-button size="mini" class="frontBtn" @click="RechargeDo">确 认</el-button>
                    </div>
                </div>
            </div>

        </el-drawer>



        <!-- 配送时充值单选择对话框 -->
        <el-dialog title="配送时充值单选择" :visible.sync="is_setRefillCard" width="70%" class="refillCard_style">

            <el-table :data="refillCardData" ref="multipleTableRefill" border :header-cell-style="styleBindFun"
                style="width: 100%" @selection-change="handleSelectionChange" :row-key="getRowKeys">
                <el-table-column type="selection" width="60" align="center" :selectable="selectable"
                    :reserve-selection="true">
                </el-table-column>
                <el-table-column prop="order_num" label="财务单" width="260" align="center">
                </el-table-column>
                <el-table-column prop="amount" label="账户余额" align="center">
                    <template slot-scope="scope">
                        ￥{{scope.row.amount}}
                    </template>
                </el-table-column>

                <el-table-column prop="customer_name" label="店家" show-overflow-tooltip align="center">
                </el-table-column>

                <el-table-column prop="present_type_name" label="配送类型" show-overflow-tooltip align="center">
                </el-table-column>

                <el-table-column prop="finance_type_name" label="财务类型" show-overflow-tooltip align="center">
                </el-table-column>

                <el-table-column prop="finance_type_name" label="配送依赖" show-overflow-tooltip align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.is_can_present" class="yesIcon el-icon-check"></div>
                        <div v-else class="noIcon el-icon-close"></div>
                    </template>

                </el-table-column>

            </el-table>

            <!-- 分页器 -->
            <el-pagination @current-change="handleCurrentChange" :current-page="form_getRefillCard.page"
                :page-sizes="[5]" :page-size="form_getRefillCard.per_page"
                layout="total, sizes, prev, pager, next, jumper" :total="refillCardTotal" align="center">
            </el-pagination>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" class="frontBtn" @click="is_setRefillCard = false">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 激活配送对话框 -->
        <el-dialog title="激活配送" :visible.sync="is_DistributionTypeDo" width="50%" class="refillCard_style">

            <div class="DistributionType_con">
                配送类型：
                <el-select v-model="Form_activateDistributionType.present_id" clearable placeholder="请选择" size="small"
                    class="activate_input_width">
                    <el-option v-for="item in persentAllData" :key="item.id" :label="item.name" :value="item.id">
                        <div class="DistributionType_option">
                            <span class="DistributionType_con_item ">{{item.name}}</span>
                            <span class="DistributionType_con_item ">初始金额：<span
                                    class="NumberNoLeft">{{item.amount}}</span></span>
                            <span class="DistributionType_con_item">转出: <span v-if="item.is_transfer"
                                    class="yesColor  el-icon-check"></span><span v-else
                                    class="noColor el-icon-close"></span></span>
                            <span class="DistributionType_con_item"> 起订额: <span v-if="item.is_deliver_limit"
                                    class="yesColor  el-icon-check"></span><span v-else
                                    class="noColor el-icon-close"></span> </span>
                            <span class="DistributionType_con_item"> 依赖: <span v-if="item.is_depend"
                                    class="yesColor  el-icon-check"></span><span v-else
                                    class="noColor el-icon-close"></span></span>
                        </div>
                    </el-option>
                </el-select>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_DistributionTypeDo = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" @click="activateDistributionType">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 开票详情 -->
        <!-- 开票信息对话框 -->
        <el-dialog title="开票详情" :visible.sync="is_seeInvoiceDetail" width="60%" class="invoice_style">
            <!-- <el-descriptions title="票据详情">
     <el-descriptions-item label="税号">{{invoiceDetailData.tax.register_number}}</el-descriptions-item>
    <el-descriptions-item label="银行">{{invoiceDetailData.tax.bank}}</el-descriptions-item>
    <el-descriptions-item label="银行账号">{{invoiceDetailData.tax.bank_number}}</el-descriptions-item>
    <el-descriptions-item label="地址">
      {{invoiceDetailData.tax.register_address}}
    </el-descriptions-item>
    <el-descriptions-item label="电话">{{invoiceDetailData.tax.register_phone}}</el-descriptions-item>
    <el-descriptions-item label="包含订单">
          <el-table :header-cell-style="styleBindFun" :data="invoiceDetailData.orders">
                    <el-table-column prop="order_num" label="订单编号" align="center">
                    </el-table-column>
                    <el-table-column prop="status" label="已发货" align="center">
                    </el-table-column>
                    <el-table-column label="金额" align="center" >
                        <template slot-scope="scope">
                            ￥{{scope.row.amount}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="user_name" label="操作人" align="center">
                    </el-table-column>
                     <el-table-column prop="create_time" label="时间" align="center">
                    </el-table-column>
                </el-table>

        </el-descriptions-item>
</el-descriptions> -->


            <div slot="footer" class="dialog-footer">
                <el-button size="mini" class="frontBtn" type="primary" @click="is_seeInvoiceDetail = false">确 定
                </el-button>
            </div>
        </el-dialog>



        <!-- 申请开票对话框 -->
        <el-dialog title="申请开票" :visible.sync="is_addInvoice" :width="dialogscreen?'95%':'70%'" class="invoice_style">

            <div class="addInvoice_style">
                <div class="first"> <i class="el-icon-info infoStyle"></i>发票类型:
                    <el-select size="mini" v-model="addInvoice_form.type_id" clearable placeholder="请选择">
                        <el-option v-for="item in invoiceTypeData" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>

                <div class="second"><i class="el-icon-info infoStyle"></i>订单:
                    <span>
                        <el-button class="frontBtn addOrderStyle" size="mini" @click="addOrderFun">添加订单</el-button>
                    </span>
                    <!-- <span><el-button class="text_primary" type="text"  @click="addOrderFun">添加订单</el-button></span> -->
                    <el-table :data="invoiceOrderData" tooltip-effect="dark" style="width: 100%"
                        :header-cell-style="styleBindFun">

                        <el-table-column prop="order_num" label="订单号" width="210" align="center">
                        </el-table-column>
                        <el-table-column prop="status_name" label="订单状态" align="center">
                        </el-table-column>


                        <el-table-column prop="amount" label="订单" align="center" width="120">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">￥{{scope.row.amount}}</span>

                            </template>
                        </el-table-column>

                        <el-table-column label="退货" align="center" width="120">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">￥{{scope.row.return_amount }}</span>

                            </template>
                        </el-table-column>

                        <el-table-column label="开票" align="center" width="120">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">￥{{scope.row.invoice_amount }}</span>

                            </template>
                        </el-table-column>



                        <el-table-column prop="customer_name" label="客户" align="center" width="360">
                        </el-table-column>


                        <el-table-column label="操作" align="center" width="80">
                            <template slot-scope="scope">
                                <el-button class="text_delete" type="text" @click="deleteOrderItem(scope.row)"> 删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>


                <div class="third">
                    <span>订单数量:<span class="Number">{{invoiceOrderDataLength}}</span></span>
                    <span>开票金额:<span class="Number">￥{{invoiceOrderMoney}}</span></span>
                </div>

                <div class="last">
                    <i class="el-icon-info infoStyle"></i>备注:
                    <el-input class="invoiceRemarks" placeholder="说点什么吧" size="mini" type="textarea" clearable
                        v-model="addInvoice_form.remarks"></el-input>
                </div>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_addInvoice = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" @click="addInvoiceDo">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 申请开票选择订单对话框 -->
        <el-dialog title="选择订单" :visible.sync="is_addInvoice_chooseOrder" width="90%" class="invoice_style">

            <div class="sSearchStyle">
                <span>
                    日期：
                    <el-date-picker @change="fnSearchWithDate" size="mini" v-model="dataArea" type="daterange"
                        range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </span>

                <span>
                    <el-button size="mini" class="frontBtn el-icon-search" @click="fnSearchWithDate"> 搜 索</el-button>
                </span>
            </div>


            <el-table ref="multipleTableInvoice" :data="custOrderData" tooltip-effect="dark" style="width: 100%"
                class="elTableInvoiceStyle" :header-cell-style="styleBindFun" border>
                <el-table-column label="单选" width="50" fixed align="center">
                    <template slot-scope="scope">
                        <el-radio :disabled="!scope.row.is_can_invoice" v-model="tableRadio" :label="scope.row.id"
                            @change.native="getCurrentRow(scope.row)">&nbsp;</el-radio>
                    </template>
                </el-table-column>
                <el-table-column prop="order_num" fixed label="订单号" width="240" align="center">
                    <template slot-scope="scope">

                        <el-button   @click="fnOpenOrderPreview(scope.row)"  
                                    type="text"
                                    size="small"
                                    class="text_primary"
                                    >{{scope.row.order_num}}</el-button>
                    </template>
                </el-table-column>

                <el-table-column label="可开票" align="center" fixed>
                    <template slot-scope="scope">
                        <div v-if="scope.row.is_can_invoice" class="yesIcon el-icon-check"></div>
                        <div v-else class="noIcon el-icon-close"></div>
                    </template>
                </el-table-column>
                <el-table-column prop="status_name" label="订单状态" align="center">
                </el-table-column>

                <el-table-column prop="order_type" label="订单类型" align="center">
                </el-table-column>

                <el-table-column prop="package_time" label="打包状态" align="center" width="190">
                </el-table-column>

                <el-table-column prop="express_time" label="快递状态" align="center" width="190">
                </el-table-column>

                <el-table-column prop="amount" label="订单" align="center" width="120">
                    <template slot-scope="scope">
                        <span class="NumberNoLeft">￥{{scope.row.amount}}</span>

                    </template>
                </el-table-column>

                <el-table-column label="退货" align="center" width="120">
                    <template slot-scope="scope">
                        <span class="NumberNoLeft">￥{{scope.row.return_amount }}</span>

                    </template>
                </el-table-column>

                <el-table-column label="开票" align="center" width="120">
                    <template slot-scope="scope">
                        <span class="NumberNoLeft">￥{{scope.row.invoice_amount }}</span>

                    </template>
                </el-table-column>

                <el-table-column prop="customer_name" label="客户" align="center" width="300">
                </el-table-column>

                <el-table-column prop="shop_name" label="客户门店" align="center" width="120">
                </el-table-column>

                <el-table-column label="信用订单" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.is_credit" class="yesIcon el-icon-check"></span>
                        <span v-else class="noIcon el-icon-close"></span>
                    </template>
                </el-table-column>

                <el-table-column label="核销" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.is_verify" class="yesIcon el-icon-check"></span>
                        <span v-else class="noIcon el-icon-close"></span>
                    </template>
                </el-table-column>

                <el-table-column label="快递" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.is_express" class="yesIcon el-icon-check"></span>
                        <span v-else class="noIcon el-icon-close"></span>
                    </template>
                </el-table-column>

                <el-table-column label="创建时间" align="center" width="170">
                    <template slot-scope="scope">
                        {{scope.row.create_time}}
                    </template>
                </el-table-column>

                <!-- <el-table-column label="操作" align="center" width="200"> 
                            <template slot-scope="scope">
                            <el-button  type="text" :disabled="!scope.row.is_can_invoice" @click="addOrderItem(scope.row)"> 添加</el-button>
                            </template>
                        </el-table-column> -->
            </el-table>


            <!-- 分页器 -->
            <el-pagination @current-change="handleCurrentChangeInvoice" :current-page="this.getOrder_form.page"
                :page-size="this.getOrder_form.per_page" layout="total, prev, pager, next, jumper" :total="invoiceTotal"
                align="center">
            </el-pagination>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" class="frontBtn" @click="addOrderItemDo">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 冻结保证金对话框 -->
        <el-dialog title="冻结保证金" :visible.sync="is_freeze" :width="dialogscreen?'55%':'35%'" class="invoice_style">
            <el-form ref="form" :model="Form_freeze" :rules="freezeRules" label-width="100px">
                <el-form-item label="冻结金额:" prop="amount">
                    <el-input size="small" clearable v-model="Form_freeze.amount" placeholder="请输入"></el-input>
                </el-form-item>

                <el-form-item label="备注:" prop="remarks">
                    <el-input size="small" clearable v-model="Form_freeze.remarks" placeholder="说点什么吧"></el-input>
                </el-form-item>

            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_freeze = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" @click="freezeDo">确 定</el-button>
            </div>
        </el-dialog>


        <!-- 退还保证金对话框 -->
        <el-dialog title="退还保证金" :visible.sync="is_returnCashDeposit" :width="dialogscreen?'55%':'35%'"
            class="invoice_style">
            <el-form ref="form" label-width="100px" :model="Form_returnCashDeposit" :rules="returnCashDepositRules">
                <el-form-item label="退还金额:" prop="amount">
                    <el-input size="small" clearable v-model="Form_returnCashDeposit.amount" placeholder="请输入">
                    </el-input>
                </el-form-item>

                <el-form-item label="备注:" prop="amount">
                    <el-input size="small" clearable v-model="Form_returnCashDeposit.remarks" placeholder="说点什么吧">
                    </el-input>
                </el-form-item>

            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_returnCashDeposit = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" @click="returnCashDepositDo">确 定</el-button>
            </div>
        </el-dialog>


        <!-- 配送余额转换对话框 -->
        <el-dialog title="配送余额转换" :visible.sync="is_transitionMoney" :width="dialogscreen?'55%':'35%'"
            class="invoice_style">
            <el-form ref="Form_transitionMoney" label-width="80px" :model="Form_transitionMoney"
                :rules="transitionMoneyRules">
                <el-form-item label="转出:" prop="transfer_present_id">
                    <el-select v-model="Form_transitionMoney.transfer_present_id" clearable placeholder="请选择"
                        size="small">
                        <el-option v-for="item in PresentData" :key="item.present_id" v-show="item.is_transfer"
                            :label="item.present_name" :value="item.present_id">
                            {{item.present_name}} 金额:{{item.account}}
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="转入:" prop="receive_present_id">
                    <el-select v-model="Form_transitionMoney.receive_present_id" clearable placeholder="请选择"
                        size="small">
                        <el-option v-for="item in PresentData" :key="item.present_id" :label="item.present_name"
                            :value="item.present_id">
                            {{item.present_name}} 金额:{{item.account}}
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="金额:" prop="amount">
                    <el-input size="small" clearable v-model="Form_transitionMoney.amount" placeholder="大于0的整百数">
                    </el-input>
                </el-form-item>

                <el-form-item label="备注:" prop="remarks">
                    <el-input size="small" clearable v-model="Form_transitionMoney.remarks" placeholder="填写下缘由">
                    </el-input>
                </el-form-item>

            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_transitionMoney = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" @click="transitionMoneyDo">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 申请开票里查看订单信息 -->
        <!-- 订单预览对话框 -->
        <el-dialog title="订单预览 " :visible.sync="bOrderPreview" top="30px" :width="dialogscreen?'95%':'80%'"
            class="sOrderDetailStyle">
             <el-button class="frontBtn sendGoodsBtnStyle" size="small" @click="printDo">查看发货单</el-button>

            <div class="sAreaStyle">
                <div ref="content">

                    <!-- 1.基本信息 -->
                    <div class="first">
                        <div class="tit">
                            <span class="sRectangleStyle"></span>
                            基本信息
                        </div>
                        <div class="content">
                            <span>
                                <div class="left">客户名称:</div>
                                <div class="right">{{DetailData.customer_name}} </div>
                            </span>

                            <span>
                                <div class="left">订单类型:</div>
                                <div class="right">{{DetailData.order_type}} </div>
                            </span>

                            <span>
                                <div class="left">订单金额:</div>
                                <div class="right"><i class="NumberNoLeft">￥{{DetailData.amount}}</i> </div>
                            </span>


                            <span>
                                <div class="left">信用:</div>
                                <div class="right">
                                    <div v-show="DetailData.is_credit" class="yesColor ">是</div>
                                    <div v-show="!DetailData.is_credit" class="noColor ">否</div>
                                </div>
                            </span>


                            <span>
                                <div class="left">快递:</div>
                                <div class="right">
                                    <div v-show="DetailData.is_express" class="yesColor">是</div>
                                    <div v-show="!DetailData.is_express" class="noColor">否</div>
                                </div>
                            </span>




                            <span>
                                <div class="left">打包时间:</div>
                                <div class="right">
                                    <div v-show="DetailData.package_time !==0">{{DetailData.package_time}} </div>
                                </div>
                            </span>



                            <span>
                                <div class="left">快递时间:</div>
                                <div class="right">
                                    <div v-show="DetailData.express_time !==0">{{DetailData.express_time}} </div>
                                </div>
                            </span>
                            <span>
                                <div class="left">订单删除时间:</div>
                                <div class="right">
                                    <div v-show="DetailData.delete_time !==0">{{DetailData.delete_time}} </div>
                                </div>
                            </span>


                            <span>
                                <div class="left">订单创建时间:</div>
                                <div class="right">
                                    <div v-show="DetailData.create_time !==0">{{DetailData.create_time}} </div>
                                </div>
                            </span>

                            <span>
                                <div class="left">门店:</div>
                                <div class="right">
                                    <div v-show="DetailData.shop_name">{{DetailData.shop_name}}</div>
                                </div>
                            </span>

                            <span>
                                <div class="left">存放仓库:</div>
                                <div class="right">{{DetailData.store_name}} </div>
                            </span>

                            <span>
                                <div class="left">备注:</div>
                                <div class="right">{{DetailData.remarks}} </div>
                            </span>
                        </div>
                    </div>

                    <!-- 2.收货信息 -->
                    <!-- <div class="second" v-show="DetailData.is_express">
                        <div class="tit">
                            <span class="sRectangleStyle"></span>
                            收货地址
                        </div>
                        <div class="content">
                            <span class="address">地址: <span>{{DetailDataAddress.region_full_name}} </span>
                                <span>{{DetailDataAddress.address}}
                                </span>
                                <span class="mlr10"> <i class="NumberNoLeft">*</i> {{DetailData.express_type}}</span>
                                </span>
                            <span class="contact">联系人: <span>{{DetailDataAddress.name}} </span></span>
                            <span class="tel">电话: <span>{{DetailDataAddress.mobile}} </span></span>

                        </div>
                    </div> -->


                    <!-- 3.包含商品 -->
                    <div class="thirdly">
                        <div class="tit">
                            <span class="sRectangleStyle"></span>
                            包含商品
                        </div>
                        <div class="content sContainNumber">
                            <el-table border :data="DetailData.goods_list" style="width:100%"
                                :header-cell-style="styleBindFunOfTwo">
                                <el-table-column prop="goods_sn" label="货号" align="center">
                                </el-table-column>
                                <el-table-column prop="goods_name" label="产品名" align="center" width="330">
                                </el-table-column>
                                <el-table-column label="原单价（元）" align="center">
                                    <template slot-scope="scope">
                                        <span class="NumberNoLeft">{{scope.row.original_price}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="进货单价（元）" align="center">
                                    <template slot-scope="scope">
                                        <span class="NumberNoLeft">{{scope.row.sell_price}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="quantity" label="数量" align="center">
                                </el-table-column>
                            </el-table>
                            <div class="sGoodsNumberStyle">数量： <span class="NumberNoLeft">{{nGoodsContainNumber}}</span> </div>
                        </div>
                    </div>


                    <!-- 4.包含产品 -->
                    <div class="fourthly">
                        <div class="tit">
                            <span class="sRectangleStyle"></span>
                            包含产品
                        </div>
                        <div class="content sContainNumber">
                            <el-table border :data="DetailData.product_list" style="width:100%"
                                :header-cell-style="styleBindFunOfTwo">
                                <el-table-column prop="product_sn" label="产品号" align="center" width="100">
                                </el-table-column>
                                <el-table-column prop="record" label="备案编号" align="center" width="260px">
                                </el-table-column>
                                <el-table-column prop="goods_name" label="产品名" align="center" width="330">
                                    <template slot-scope="scope">
                                        {{scope.row.product_name}} <span v-show="scope.row.product_spec">（{{scope.row.product_spec}}）</span> 
                                    </template>
                                </el-table-column>
                                <el-table-column label="原单价（元）" align="center">
                                    <template slot-scope="scope">
                                        <span class="NumberNoLeft">{{scope.row.original_price}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="进货单价（元）" align="center">
                                    <template slot-scope="scope">
                                        <span class="NumberNoLeft">{{scope.row.sell_price}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="quantity" label="数量" align="center">
                                </el-table-column>
                                <el-table-column prop="return" label="退货" align="center">
                                </el-table-column>
                                <el-table-column fixed="right" label="批次" align="center" width="100">
                                    <template slot-scope="scope">
                                        <el-dropdown v-show="scope.row.batchs.length>0">
                                            <span class="el-dropdown-link">
                                                <span class="NumberRight">{{scope.row.batchs.length}}</span>批 <i
                                                    class="el-icon-arrow-down el-icon--right"></i>
                                            </span>
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item v-for="list  in scope.row.batchs"
                                                    :key="list.batch_sn">
                                                    {{list.batch_sn}} <span
                                                        class="validityDateStyle">限用日期：{{list.batch_expiry_date}}
                                                        数量：<span class="NumberNoLeft">{{list.quantity}}</span>
                                                    </span>
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="sGoodsNumberStyle">数量： <span class="NumberNoLeft">{{nProductContainNumber}}</span> </div>
                        </div>
                    </div>

                </div>

                <!-- 5.快递 -->
                <div class="fifth">
                    <div class="tit">
                        <span class="sRectangleStyle"></span>
                        快递
                    </div>
                    <div class="content">
                        <el-table :data="DetailData.expresss" style="width:100%" :header-cell-style="styleBindFunOfTwo">
                            <el-table-column prop="express_num" label="快递单号" align="center">
                                <template slot-scope="scope">
                                    <el-button type='text' class="text_primary" @click="jumpExpress(scope.row)">
                                        {{scope.row.express_num}}</el-button>
                                </template>
                            </el-table-column>
                            <el-table-column prop="create_time" label="时间" align="center">
                            </el-table-column>
                        </el-table>

                    </div>
                </div>


                <!-- 6.订单日志 -->
                <div class="sixth">
                    <div class="tit">
                        <span class="sRectangleStyle"></span>
                        订单日志
                    </div>
                    <div class="content">
                        <el-timeline>
                            <el-timeline-item v-for="(activity, index) in DetailData.logs" :key="index"
                                icon="el-icon-check" type="primary" size="activity.large"
                                :timestamp="activity.update_time">
                                {{activity.log}}
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" class="frontBtn" @click="bOrderPreview = false">确 定</el-button>
            </div>
        </el-dialog>     

        <!-- ------------------------------------------抽屉------------------------------- -->
        <el-drawer title="门店的员工权限" :visible.sync="is_seeStoreUsers" :direction="direction">
            <span>
                <el-button @click="managementPowerDistribution()" size="mini" class="frontBtn">
                    管理权分配
                </el-button>
                <el-table :header-cell-style="styleBindFun" :data="seeStoreUsersData">
                    <el-table-column prop="name" label="员工" align="center">
                    </el-table-column>
                    <el-table-column prop="department_full_name" label="部门" align="center" width="200">
                    </el-table-column>
                    <el-table-column prop="position_name" label="职务" align="center">
                    </el-table-column>
                </el-table>
            </span>
        </el-drawer>

        <el-drawer title="负责的客户" :visible.sync="bTakeChargeCustomer" size="75%"
            :direction="sTakeChargeCustomerDirection">
            <span>
                <el-table :header-cell-style="styleBindFun" :data="aTakeChargeCustomerData">
                    <el-table-column prop="number" label="客户编号" align="center" width="220">
                    </el-table-column>
                    <el-table-column align="center" width="320">
                        <template slot="header">
                            客户 <span style="color:#fa5a57">（点击查看详情）</span>
                        </template>
                        <template slot-scope="scope">

                            <span @click="lookDetail(scope.row)" type="text" class="text_customername" size="big">
                                {{scope.row.name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="alias" label="别名" align="center" width="320">
                    </el-table-column>
                    <el-table-column label="账户余额" align="center" width="120">
                        <template slot-scope="scope">
                            <i class="NumberNoLeft">￥{{scope.row.account_format}}</i>
                        </template>
                    </el-table-column>

                    <el-table-column label="保证金" align="center" width="120">
                        <template slot-scope="scope">
                            <i class="NumberNoLeft">￥{{scope.row.deposit_format}}</i>
                        </template>
                    </el-table-column>


                    <el-table-column label="消费" align="center" width="120">
                        <template slot-scope="scope">
                            <i class="NumberNoLeft">￥{{scope.row.spend_format}}</i>
                        </template>
                    </el-table-column>

                    <el-table-column label="信用支出" align="center" width="120">
                        <template slot-scope="scope">
                            <i class="NumberNoLeft">￥{{scope.row.credit_spend_format}}</i>
                        </template>
                    </el-table-column>



                    <el-table-column label="可用信用额" align="center" width="120">
                        <template slot-scope="scope">
                            <i class="NumberNoLeft">￥{{scope.row.credit_limit_format}}</i>
                        </template>
                    </el-table-column>

                    <el-table-column label="余额" align="center" width="120">
                        <template slot-scope="scope">
                            <i class="NumberNoLeft">￥{{scope.row.balance_format}}</i>
                        </template>
                    </el-table-column>


                </el-table>
            </span>
        </el-drawer>
    </div>
</template>

<script>
    import {
        erpApi
    } from '../../config'

    import {
        domainApi
    } from '../../config'

    export default {
        data() {
            var validateMoney = (rule, value, callback) => {

                const reg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{2})$/;
                if (reg.test(value)) {


                    callback()
                } else {
                    callback(new Error('请输入正确格式的金额'))
                }

            };


            return {
                skeletonState: true,
                customerNumber: this.$route.query.data,
                customerName: '',
                discountValue: '',
                discountName: '',
                base: '',
                // ---------------------初始化数据-----------------------
                lookDetailData: {},

                // ---------------------创建备注单数据-----------------------
                is_addRemarks: false,
                addRemarks_content: '',

                // ----------------------创建回访记录：--------------------------------
                is_ReturnVisitRecord: false,
                Form_addReturnVisitRecord: {
                    customer_number: '',
                    remarks: '',
                },



                // ----------------------创建门店：--------------------------------
                storeManagementData: [], //获取该客户的门店信息
                RegionData: [],
                is_StoreManagement: false,
                Form_addStoreManagement: {
                    customer_number: '',
                    region_id: '',
                    address: '',
                    remarks: '',
                    name: '',
                    telephone: '',
                    contact_name: '',
                    contact_mobile: '',
                    sort_order: '',
                    shop_id: ''
                },

                // ----------------------修改门店：--------------------------------
                is_editStore: false,



                // ----------------------创建收货地址：--------------------------------
                is_addReceivingAddress: false,
                Form_addReceivingAddress: {
                    customer_number: '',
                    address_id: '',
                    region_id: '',
                    address: '',
                    remarks: '',
                    name: '',
                    mobile: '',
                    sort_order: '',
                },
                Form_addReceivingAddressRules: {

                    region_id: [{
                        required: true,
                        message: '请填写完整表单',
                        trigger: 'blur'
                    }, ],
                    address: [{
                        required: true,
                        message: '请填写完整表单',
                        trigger: 'blur'
                    }, ],
                    remarks: [{
                        required: true,
                        message: '请填写完整表单',
                        trigger: 'blur'
                    }, ],
                    name: [{
                        required: true,
                        message: '请填写完整表单',
                        trigger: 'blur'
                    }, ],
                    mobile: [{
                        required: true,
                        message: '请填写完整表单',
                        trigger: 'blur'
                    }, ],

                },



                // ----------------------修改收货地址：--------------------------------
                is_editReceivingAddress: false,



                choosedRegion: [],
                choosedRegionFirst: '',
                choosedRegionTwo: '',


                // 查看客户员工权限
                activeName: 'first',
                customer_usersData: [],
                group_usersData: [],

                // 管理权分配
                is_usersDistribution: false,
                userInfoData: [],
                defaultProps: {
                    children: 'children',
                    label: 'name',
                },
                search_keywords: '',
                user_data_ids: [],
                user_selected_data: [],
                is_searchRes: false,
                search__result__Data: [],

                // 收货地址管理
                addressInfoData: [],

                // 备注管理
                getRemarksInfo: {
                    customer_number: this.$route.query.data,
                    page: 1,
                    per_page: 5,
                    keyword: '',
                },
                remarksInfoData: [],
                remarksTotal: 0,

                // 回访记录
                getnoteInfo: {
                    customer_number: this.$route.query.data,
                    page: 1,
                    per_page: 5,
                    keyword: '',
                },
                noteInfoData: [],
                noteTotal: 0,


                // 门店的员工权限
                is_seeStoreUsers: false,
                direction: 'ltr',
                seeStoreUsersData: [],
                shop_id: '',

                getlogs_form: {
                    customer_number: this.$route.query.data,
                    page: 1,
                    per_page: 5,
                },
                logsData: [],
                logsTotal: 0,

                // 开票信息
                is_InvoicingInfo: false,
                invoicingInfoData: [],
                is_eidtBtns: true,
                editinvoicingInfo_form: {
                    customer_number: '',
                    register_number: '',
                    register_address: '',
                    register_phone: '',
                    bank: '',
                    bank_number: '',
                },

                //  申请入账
                is_Recharge: false,
                Form_Recharge: {
                    customer_number: '',
                    present_id: '',
                    finance_type_id: '',
                    finance_list: [],
                    amount: '',
                    remarks: '',
                    file: '',
                },

                // 申请入账规则
                RechargeRules: {
                    present_id: [{
                        required: true,
                        message: '请输入财务类型',
                        trigger: 'blur'
                    }, ],
                    amount: [{
                            validator: validateMoney,
                            trigger: 'blur'
                        },
                        {
                            required: true,
                            message: '金额是必须的',
                            trigger: 'blur'
                        },
                    ],
                    remarks: [{
                        required: true,
                        message: '请填写备注',
                        trigger: 'blur'
                    }, ]
                },


                choosedRechargeData: [],



                // 配送时充值单选择
                is_setRefillCard: false,
                form_getRefillCard: {
                    page: 1,
                    per_page: 5,
                    customer_number: ''
                },
                refillCardData: [],
                refillCardTotal: 0,
                selectedRefillCardData: [],
                is_set: false,

                PresentData: [],
                FinanceData: [],


                // 激活配送
                is_activate: true,
                is_DistributionTypeDo: false,
                Form_activateDistributionType: {
                    customer_number: '',
                    present_id: '',
                },
                persentAllData: [],

                // 开票管理
                invoiceTypeData: [],
                invoiceData: [],
                form_getInvoice: {
                    customer_number: '',
                    page: 1,
                    per_page: 5,
                    type_id: '',
                },

                invoiceTabs: 'first',
                is_seeInvoiceDetail: false,
                invoiceDetailData: [],
                is_addInvoice: false,
                addInvoice_form: {
                    customer_number: '',
                    type_id: '',
                    order_list: '',
                    remarks: '',
                },

                // 订单
                getOrder_form: {
                    page: 1,
                    per_page: 5,
                    keyword: '',
                    customer_number: '',
                    status: '',
                    present_id: '',
                    is_credit: '',
                    start_time: '',
                    end_time: '',
                },
                custOrderData: [],

                invoiceOrderData: [],
                invoiceOrderDataLength: '',
                invoiceOrderMoney: 0,
                invoiceTotal: 0,

                maxSuggestPresent: '',
                plan_ratio: '', //配送比
                chooseRechargeBtnDis: true,
                fileRefState: false,


                // 冻结保证金
                is_freeze: false,
                Form_freeze: {
                    customer_number: '',
                    amount: '',
                    remarks: '',
                },
                freezeRules: {
                    amount: [{
                        required: true,
                        message: '请输入冻结金额',
                        trigger: 'blur'
                    }, ],
                    remarks: [{
                        required: true,
                        message: '请输入备注',
                        trigger: 'blur'
                    }, ],
                },



                // 退还保证金
                is_returnCashDeposit: false,
                Form_returnCashDeposit: {
                    customer_number: '',
                    amount: '',
                    remarks: '',
                },
                returnCashDepositRules: {
                    amount: [{
                        required: true,
                        message: '请输入退还金额',
                        trigger: 'blur'
                    }, ],
                    remarks: [{
                        required: true,
                        message: '请输入备注',
                        trigger: 'blur'
                    }, ],
                },

                // 配送余额转换
                is_transitionMoney: false,
                Form_transitionMoney: {
                    customer_number: '',
                    transfer_present_id: '',
                    receive_present_id: '',
                    amount: '',
                    remarks: '',
                },
                transitionMoneyRules: {
                    transfer_present_id: [{
                        required: true,
                        message: '请选择转出配送',
                        trigger: 'blur'
                    }, ],
                    receive_present_id: [{
                        required: true,
                        message: '请输入转入配送',
                        trigger: 'blur'
                    }, ],
                    amount: [{
                        required: true,
                        message: '请选择转换金额',
                        trigger: 'blur'
                    }, ],
                    remarks: [{
                        required: true,
                        message: '请输入备注',
                        trigger: 'blur'
                    }, ],
                },





                // 申请开票选择订单
                is_addInvoice_chooseOrder: false,

                tableRadio: '',


                dataArea: [],


                nCurrentStoreId: '',
                nCurrentNoteId: '',
                nCurrentRemarksId: '',


                dialogscreen: false,

                fullscreenLoading: false,



                // 切换版样式
                sCustomerDetailActiveName: 'first',


                // 入账财务类型
                bEnterAccountFinsType: false,

                // 负责的客户
                bTakeChargeCustomer: false,
                sTakeChargeCustomerDirection: 'rtl',
                aTakeChargeCustomerData: [],


                // 申请入账
                bEntryDrawer: false,
                sEntryDirection: 'rtl',

                // 订单预览
                bOrderPreview:false,
                DetailData:[],
                nProductContainNumber:'',
                nGoodsContainNumber:'',
                currentOrderNumber:'',


            }
        },
        created() {
            this.getData();
            this.getRegion();
            this.getStore()
            this.getCustomerUsers()
            this.getUserInfo()
            this.getAddressInfo()
            this.getRemarks()
            this.getNote()
            this.getLogs()
            this.getPresent()
            this.getFinance()
            this.getinvoiceData()
            this.getinvoice()
            this.fnResize()
        },

        mounted() {
            window.onresize = () => {
                let width = document.body.clientWidth
                if (width < 1550) {
                    this.dialogscreen = true
                } else {
                    this.dialogscreen = false
                }
            }
        },

        methods: {

            fnResize() {

                let width = document.body.clientWidth
                if (width < 1550) {
                    this.dialogscreen = true
                } else {
                    this.dialogscreen = false
                }

            },


            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind = "background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                return styleBind;
            },



            // 获取客户订单
            fnSearchWithDate() {
                if (this.dataArea !== null && this.dataArea.length > 0) {
                    this.getOrder_form.start_time = this.dataArea[0]
                    this.getOrder_form.end_time = this.dataArea[1]
                } else {
                    this.getOrder_form.start_time = ''
                    this.getOrder_form.end_time = ''
                }

                this.getCustOrder()
            },

            async getCustOrder() {
                this.getOrder_form.customer_number = this.customerNumber
                let params = this.getOrder_form
                const res = await this.$https.post(erpApi + '/customer/orders/index?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.custOrderData = res.data.data
                this.invoiceTotal = res.data.total
                this.calcInvoice()
            },

            // echo(){
            //     this.invoiceOrderData.forEach(ele=>{
            //         this.custOrderData.forEach(node=>{
            //             if(ele.id == node.id)
            //         })
            //     })
            // },


            // 获取发票类型
            async getinvoice() {
                const res = await this.$https.post(erpApi + '/invoice/type/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.invoiceTypeData = res.data
            },

            // 获取全部配送类型
            async getAllPresent() {
                const res = await this.$https.post(erpApi + '/present/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.persentAllData = res.data
            },

            // 获取配送类型
            async getPresent() {
                let params = {
                    customer_number: this.customerNumber
                }
                const res = await this.$https.post(erpApi + '/customer/presents?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.PresentData = res.data
                if (this.PresentData.length == 0) {
                    this.is_activate = false
                }
                let obj = {
                    present_id: 0,
                    present_name: '预存款'
                }

                this.PresentData.unshift(obj)


            },

            // 获取财务类型
            async getFinance() {
                const res = await this.$https.post(erpApi + '/finance/type/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.FinanceData = res.data
                let obj = {
                    "id": 0,
                    "name": "系统生成"
                }
                // this.FinanceData.push(obj)
            },

            // ---------------------------1.获取初始化方法-----------------------------------
            async getData() {
                //  let data=this.$route.query.data
                //  this.lookDetailData=data
                //  console.log(data,'query.data');
                let parmas = {
                    customer_number: this.$route.query.data
                }
                const res = await this.$https.post(erpApi + '/customer/detail?_ajax=1', parmas)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.lookDetailData = res.data

                this.customerName = this.lookDetailData.name
                this.plan_ratio = this.lookDetailData.plan_ratio
                this.discountValue = this.lookDetailData.discount_value
                this.discountName = this.lookDetailData.discount_name
                this.base = this.lookDetailData.name + ':'
                this.skeletonState = false
            },

            // 获取客户日志
            async getLogs() {
                let parmas = this.getlogs_form
                const res = await this.$https.post(erpApi + '/customer/logs?_ajax=1', parmas)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.logsData = res.data.data
                this.logsTotal = res.data.total
            },

            handleCurrentChangelogs(data) {
                this.getlogs_form.page = data
                this.getLogs()
            },

            // 查看客户员工权限
            async getCustomerUsers() {
                const res = await this.$https.get(erpApi + '/customer/users?_ajax=1&customer_number=' + this.$route
                    .query.data)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.customer_usersData = res.data.customer_users
                this.group_usersData = res.data.group_users
            },

            // ---------------------------0.获取收货地址方法-----------------------------------
            async getAddressInfo() {
                let params = {
                    customer_number: this.customerNumber
                }
                const res = await this.$https.post(erpApi + '/customer/address/index?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.addressInfoData = res.data
            },



            // ---------------------------1.获取初始化门店方法-----------------------------------
            async getStore() {
                let params = {
                    customer_number: this.$route.query.data
                }
                const res = await this.$https.post(erpApi + '/customer/shop/index?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.storeManagementData = res.data
            },


            // ------------------------------获取备注---------------------------------
            async getRemarks() {
                let params = this.getRemarksInfo
                const res = await this.$https.post(erpApi + '/customer/remark/index?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.remarksInfoData = res.data.data
                this.remarksTotal = res.data.total
            },
            handleCurrentChangeGetRemarks(data) {
                this.getRemarksInfo.page = data
                this.getRemarks()
            },

            // ------------------------------获取回访记录列表---------------------------------
            async getNote() {
                let params = this.getnoteInfo
                const res = await this.$https.post(erpApi + '/customer/note/index?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.noteInfoData = res.data.data
                this.noteTotal = res.data.total
            },
            handleCurrentChangeGetNote(data) {
                this.getnoteInfo.page = data
                this.getNote()
            },

            // 返回上一步
            goBack() {
                this.$router.push({
                    path: '/customerManagement',
                    query: {
                        backUp: this.backUpInfo
                    }
                })
            },

            // ---------------------创建备注单数据方法-----------------------
            addRemarks() {
                this.is_addRemarks = true
                this.addRemarks_content = ''
            },
            async addRemarksDo() {
                let params = {
                    customer_number: this.lookDetailData.number,
                    remarks: this.addRemarks_content
                }
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/remark/create?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_addRemarks = false
                this.getRemarks()
            },



            // ---------------------删除备注单数据方法-----------------------
            async deleteRemarks(row) {

                this.$confirm('您确定删除该备注单吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    .then(async () => {

                        let params = {
                            customer_number: this.lookDetailData.number,
                            remark_id: row.id
                        }
                        const {
                            data
                        } = await this.$https.post(erpApi + '/customer/remark/delete?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)
                        this.getRemarks();

                    })
                    .catch(() => {
                        this.$message.error('您取消了按钮')
                    })
            },

            // ----------------------创建回访记录：--------------------------------
            addReturnVisitRecord() {
                this.is_ReturnVisitRecord = true
                this.Form_addReturnVisitRecord.remarks = ''
            },
            async addReturnVisitRecordDo() {
                this.Form_addReturnVisitRecord.customer_number = this.lookDetailData.number
                let params = this.Form_addReturnVisitRecord
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/note/create?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.is_ReturnVisitRecord = false
                this.$message.success(data.msg)
                this.getNote()
            },
            // ---------------------删除回访记录方法-----------------------
            deleteReturnVR(row) {
                this.$confirm('您确定删除该回访记录吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    .then(async () => {

                        let params = {
                            customer_number: this.lookDetailData.number,
                            note_id: row.id
                        }
                        const {
                            data
                        } = await this.$https.post(erpApi + '/customer/note/delete?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)
                        this.getNote()

                    })
                    .catch(() => {
                        this.$message.error('您取消了按钮')
                    })
            },


            // ----------------------创建门店：--------------------------------
            // 获取区域信息
            async getRegion() {
                const res = await this.$https.get(erpApi + '/region/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.RegionData = res.data
            },
            addStore() {
                this.is_StoreManagement = true
                this.choosedRegion = []
            },
            async addStoreDo() {

                let area = this.choosedRegion[this.choosedRegion.length - 1]

                this.Form_addStoreManagement.region_id = area
                this.Form_addStoreManagement.customer_number = this.lookDetailData.number
                let params = this.Form_addStoreManagement


                const {
                    data
                } = await this.$https.post(erpApi + '/customer/shop/create?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_StoreManagement = false
                this.getStore()
            },

            // ----------------------删除门店：--------------------------------
            async deleteStore(row) {
                let params = {
                    customer_number: this.lookDetailData.number,
                    shop_id: row.id
                }
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/shop/delete?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.getStore()
            },

            // ----------------------修改门店：--------------------------------
            editStore(row) {
                this.getStoreDetail(row.id)
                this.Form_addStoreManagement.shop_id = row.id
                this.Form_addStoreManagement.contact_name = row.address.contact.name
                this.Form_addStoreManagement.contact_mobile = row.address.contact.mobile
                this.Form_addStoreManagement.address = row.address.address

                setTimeout(() => {
                    this.is_editStore = true
                }, 500);
            },

            async getStoreDetail(id) {
                let params = {
                    customer_number: this.customerNumber,
                    shop_id: id
                }
                const res = await this.$https.post(erpApi + '/customer/shop/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                let data = res.data
                this.Form_addStoreManagement.customer_number = this.customerNumber
                this.Form_addStoreManagement.remarks = data.remarks
                this.Form_addStoreManagement.name = data.name
                this.Form_addStoreManagement.tel = data.telephone
                this.Form_addStoreManagement.sort_order = data.sort_order

                let edit_region_data = data.region_full_name.split('/')
                this.choosedRegion = []
                this.choosedRegionFirst = []
                this.choosedRegionTwo = []

                this.recursionGetRegionId(this.RegionData, edit_region_data[0])
                this.recursionGetRegionId_of_second(this.choosedRegionFirst, edit_region_data[1])
                if (edit_region_data[2] !== undefined) {
                    this.recursionGetRegionId_of_second(this.choosedRegionTwo, edit_region_data[2])
                }


            },


            async editStoreDo() {
                let area = this.choosedRegion[this.choosedRegion.length - 1]
                this.Form_addStoreManagement.region_id = area
                this.Form_addStoreManagement.customer_number = this.lookDetailData.number
                let params = this.Form_addStoreManagement

                const {
                    data
                } = await this.$https.post(erpApi + '/customer/shop/edit?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_editStore = false
                this.getStore();
            },



            // ----------------------创建收货地址：--------------------------------
            addReceivingAddress() {

                this.is_addReceivingAddress = true
                for (let i in this.Form_addReceivingAddress) {
                    this.Form_addReceivingAddress[i] = ''
                }
                this.choosedRegion = []

            },
            async addReceivingAddressDo() {
                this.fullscreenLoading = true;

                let area = this.choosedRegion[this.choosedRegion.length - 1]

                this.Form_addReceivingAddress.region_id = area
                this.Form_addReceivingAddress.customer_number = this.lookDetailData.number
                let params = this.Form_addReceivingAddress
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/address/create?_ajax=1', params)
                this.fullscreenLoading = false;

                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_addReceivingAddress = false
                this.getAddressInfo()
            },


            // ----------------------删除收货地址：--------------------------------
            deleteReceivingAddress(item) {

                this.$confirm('您确定删除该收货地址吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    .then(async () => {

                        let params = {
                            customer_number: this.lookDetailData.number,
                            address_id: item.id
                        }
                        const {
                            data
                        } = await this.$https.post(erpApi + '/customer/address/delete?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)
                        this.getAddressInfo()

                    })
                    .catch(() => {
                        this.$message.error('您取消了按钮')
                    })
            },


            // ----------------------修改收货地址：--------------------------------
            editReceivingAddress(row) {
                this.Form_addReceivingAddress.address_id = row.id
                this.getAddressDetail(row.id)
                this.is_editReceivingAddress = true

                this.Form_addReceivingAddress.customer_number = this.$route.query.data
                this.Form_addReceivingAddress.address = row.address
                if (row.contact) {
                    this.Form_addReceivingAddress.name = row.contact.name
                    this.Form_addReceivingAddress.mobile = row.contact.mobile
                }
                this.Form_addReceivingAddress.sort_order = 100
                this.Form_addReceivingAddress.remarks = row.remarks


                let edit_region_data = row.region_full_name.split('/')

                this.choosedRegion = []
                this.choosedRegionFirst = []
                this.choosedRegionTwo = []


                this.recursionGetRegionId(this.RegionData, edit_region_data[0])
                this.recursionGetRegionId_of_second(this.choosedRegionFirst, edit_region_data[1])
                if (edit_region_data[2] !== undefined) {
                    this.recursionGetRegionId_of_second(this.choosedRegionTwo, edit_region_data[2])
                }
            },

            async getAddressDetail(id) {
                let params = {
                    customer_number: this.customerNumber,
                    address_id: id
                }
                const res = await this.$https.post(erpApi + '/customer/address/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                let data = res.data
                this.Form_addReceivingAddress.sort_order = data.sort_order
            },



            // 递归
            recursionGetRegionId(data, name) {
                data.forEach(node => {
                    if (node.name == name) {
                        this.choosedRegion.push(node.id)
                        this.choosedRegionFirst = node
                    }
                })
            },

            recursionGetRegionId_of_second(data, name) {
                data.children.forEach(node => {
                    if (node.name == name) {
                        this.choosedRegion.push(node.id)
                        this.choosedRegionTwo = node
                    }
                })
            },

            // --------------------------提交修改数据------------------------------------------------------------
            async editReceivingAddressDo() {
                this.fullscreenLoading = true

                this.Form_addReceivingAddress.region_id = this.choosedRegion[this.choosedRegion.length - 1]
                let params = this.Form_addReceivingAddress
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/address/edit?_ajax=1', params)
                this.fullscreenLoading = false
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_editReceivingAddress = false
                this.getAddressInfo()
            },







            // ----------------------客户管理权分配------------------------------------------------
            authorityAssignmentDo() {
                this.is_usersDistribution = true
                this.user_selected_data = []
                this.customer_usersData.forEach(node => {
                    this.initauthorityAssignment(this.userInfoData, node.id)
                })
            },

            // 初始化
            initauthorityAssignment(data, id) {
                data.forEach(node => {
                    if (node.user_id) {
                        if (node.user_id == id) {
                            this.user_selected_data.push(node)
                            node.mychecked = true
                        } else if (node.children) {
                            this.initauthorityAssignment(node.children, id)
                        }
                    } else if (node.children) {
                        this.initauthorityAssignment(node.children, id)
                    }
                })
            },





            // 获取员工信息
            async getUserInfo() {

                let params = {
                    keyword: this.search_keywords
                }
                const res = await this.$https.post(erpApi + '/user/select?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                if (res.data.length == 1) {
                    this.userInfoData = res.data
                    this.userInfoData.forEach(node => {
                        this.user_data_ids.push(node.id)
                        this.is_searchRes = false
                    })
                } else {
                    this.search__result__Data = res.data.users
                    this.is_searchRes = true

                }

            },

            searchFunction() {
                if (this.search_keywords !== '') {
                    this.getUserInfo()
                } else {
                    this.is_searchRes = false
                }
            },

            selectStaffFun(list, index) {


                //  2.添加到右边
                // 判断是否已经有
                let valve = false
                this.user_selected_data.forEach(node => {
                    if (node.user_id == list.user_id) {
                        this.$message.error('所选员工已经存在')
                        valve = true
                    }
                })
                if (!valve) {
                    // 1.先删除本地
                    this.search__result__Data.splice(index, 1)
                    this.initauthorityAssignment(this.userInfoData, list.user_id)
                    // 判断数组长度
                    if (this.search__result__Data.length == 0) {
                        this.is_searchRes = false
                        this.search_keywords = ''
                    }

                }
            },



            handleNodeClick_Choose(data) {
                if (data.user_id) {
                    data.mychecked = !data.mychecked
                    if (data.mychecked) {
                        this.user_selected_data.push(data)
                    } else {
                        let i = this.user_selected_data.indexOf(data)
                        this.user_selected_data.splice(i, 1)
                    }
                }
            },

            deleteSelected(data) {
                let i = this.user_selected_data.indexOf(data)
                this.user_selected_data.splice(i, 1)
                this.recursionDeleteIcon(this.userInfoData, data.user_id)
            },

            // 递归
            recursionDeleteIcon(data, user_id) {
                data.forEach(node => {
                    if (node.user_id) {
                        if (node.user_id == user_id) {
                            node.mychecked = false
                        } else if (node.children) {
                            this.recursionDeleteIcon(node.children, user_id)
                        }
                    } else if (node.children) {
                        this.recursionDeleteIcon(node.children, user_id)
                    }
                })
            },

            async submitUsersDo() {
                if (!this.is_seeStoreUsers) {
                    let user_list = []
                    this.user_selected_data.forEach(node => {
                        user_list.push(node.user_id)
                    })
                    let params = {
                        customer_number: this.$route.query.data,
                        user_list: user_list
                    }

                    const {
                        data
                    } = await this.$https.post(erpApi + '/customer/users?_ajax=1', params)
                    if (data.error_code !== 0) return this.$message.error(data.msg)
                    this.$message.success(data.msg)
                    this.is_usersDistribution = false
                    this.getCustomerUsers()
                    this.getUserInfo()
                } else {
                    let user_list = []
                    this.user_selected_data.forEach(node => {
                        user_list.push(node.user_id)
                    })
                    let params = {
                        customer_number: this.$route.query.data,
                        shop_id: this.shop_id,
                        user_list: user_list
                    }

                    const {
                        data
                    } = await this.$https.post(erpApi + '/customer/shop/users?_ajax=1', params)
                    if (data.error_code !== 0) return this.$message.error(data.msg)
                    this.$message.success(data.msg)
                    this.getStoreUsers()
                    this.getUserInfo()
                    this.is_usersDistribution = false

                }
            },



            // 查看客户员工权限


            //查看门店员工权限 
            seeStoreUsers(row) {
                this.is_seeStoreUsers = true
                this.shop_id = row.id
                this.getStoreUsers()
            },
            async getStoreUsers() {
                const
                    res = await this.$https.get(erpApi + '/customer/shop/users?_ajax=1&customer_number=' + this
                        .customerNumber + '&shop_id=' + this.shop_id)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.seeStoreUsersData = res.data.users
            },


            managementPowerDistribution() {
                this.is_usersDistribution = true
                this.user_selected_data = []
                this.seeStoreUsersData.forEach(node => {
                    this.initauthorityAssignment(this.userInfoData, node.id)
                })
            },



            addNewOrder() {
                this.$router.push({
                    path: '/addOrder',
                    query: {
                        data: this.customerNumber,
                        discountValue: this.discountValue,
                    }
                })
            },

            // 开票信息
            async InvoicingInfo() {
                let params = {
                    customer_number: this.customerNumber
                }
                const res = await this.$https.post(erpApi + '/customer/tax/detail?_ajax=1', params)
                //     if(res.data == null) return this.$message.error('没用开票信息')
                if (res.data == null) {
                    this.invoicingInfoData.customer_name = this.lookDetailData.name
                    this.invoicingInfoData.register_number = ''
                    this.invoicingInfoData.register_address = ''
                    this.invoicingInfoData.register_phone = ''
                    this.invoicingInfoData.bank = ''
                    this.invoicingInfoData.bank_number = ''
                } else {
                    this.invoicingInfoData = res.data
                }
                this.is_InvoicingInfo = true
                this.editinvoicingInfo_form.customer_number = this.customerNumber
            },


            editinvoicingInfo() {
                this.is_eidtBtns = false
                // 赋值
                this.editinvoicingInfo_form.register_number = this.invoicingInfoData.register_number
                this.editinvoicingInfo_form.register_address = this.invoicingInfoData.register_address
                this.editinvoicingInfo_form.register_phone = this.invoicingInfoData.register_phone
                this.editinvoicingInfo_form.bank = this.invoicingInfoData.bank
                this.editinvoicingInfo_form.bank_number = this.invoicingInfoData.bank_number
            },


            async saveinvoicingInfo() {
                let params = this.editinvoicingInfo_form
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/tax/edit?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.saveafter()

            },

            async saveafter() {
                let params = {
                    customer_number: this.editinvoicingInfo_form.customer_number
                }
                const res = await this.$https.post(erpApi + '/customer/tax/detail?_ajax=1', params)
                this.invoicingInfoData = res.data
                this.is_eidtBtns = true

            },

            cancelinvoicingInfo() {
                this.is_eidtBtns = true
            },

            // -------------------充值-------------------------------------------
            doRecharge() {
                this.bEntryDrawer = true
            },

            async RechargeDo() {

                if (this.Form_Recharge.present_id == '' && this.Form_Recharge.present_id !== 0) {
                    return this.$message.error('财务类型不能为空')
                }



                this.Form_Recharge.finance_list = []
                this.selectedRefillCardData.forEach(node => {
                    this.Form_Recharge.finance_list.push(node.order_num)
                })


                let formData = new FormData()
                formData.append('customer_number', this.customerNumber)
                formData.append('present_id', this.Form_Recharge.present_id)
                formData.append('finance_type_id', this.Form_Recharge.finance_type_id)

                formData.append('amount', this.Form_Recharge.amount)
                formData.append('remarks', this.Form_Recharge.remarks)
                formData.append('file', this.Form_Recharge.file)
                this.Form_Recharge.finance_list.forEach((value, index) => {
                    formData.append(`finance_list[${index}]`, value)
                });

                const {
                    data
                } = await this.$https.post(erpApi + '/customer/finance/create?_ajax=1', formData)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_Recharge = false
                this.fnRefresh()
            },

            fnRefresh() {
                this.$router.go(0)
            },



            changePresent_recharge(data) {
                if (data > 0) {
                    this.fileRefState = false
                    this.bEnterAccountFinsType = true
                    // this.fnGetRefillCard()
                }
                this.PresentData.forEach(node => {
                    if (node.present_id == data) {
                        if (node.is_depend) {
                            this.chooseRechargeBtnDis = false
                        } else {
                            this.chooseRechargeBtnDis = true
                        }
                    }
                })

                this.selectedRefillCardData = []

                // 删除对话框的选中状态
                 this.refillCardData.forEach(node => {
                     this.$refs.multipleTableRefill.toggleRowSelection(node,false)
                })

            },



            chooseRechargeBtn() {
                this.is_setRefillCard = true
                this.fnGetRefillCard()
            },

            async fnGetRefillCard() {
                this.form_getRefillCard.customer_number = this.customerNumber
                let params = this.form_getRefillCard
                const res = await this.$https.post(erpApi + '/finance/select?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.refillCardData = res.data.data
                this.refillCardTotal = res.data.total
            },



            fileChange() {
                this.Form_Recharge.file = this.$refs.fileRef.files[0]
            },

            getRowKeys(row) {
                return row.order_num
            },
            handleSelectionChange(selection) {
                this.selectedRefillCardData = selection
                this.calcMaxSuggestPresent()
            },




            calcMaxSuggestPresent() {
                // 计算建议最大配送额
                let sum = 0
                this.selectedRefillCardData.forEach(node => {
                    sum += Number(node.amount)
                })
                this.maxSuggestPresent = sum * this.plan_ratio
                this.maxSuggestPresent = this.maxSuggestPresent.toFixed(2)
            },

            handleCurrentChange(data) {

                this.form_getRefillCard.page = data
                this.fnGetRefillCard()
            },


            selectable(row) {
                return row.is_can_present
            },




            // 配送类型
            DistributionTypeDo() {
                this.is_DistributionTypeDo = true
                this.getAllPresent()
            },

            async activateDistributionType() {
                this.Form_activateDistributionType.customer_number = this.customerNumber
                let params = this.Form_activateDistributionType
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/finance/activate?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.getPresent()
                this.is_DistributionTypeDo = false
            },



            editCustomer() {
                this.$router.push({
                    path: '/editNewCustomer',
                    query: {
                        data: this.customerNumber
                    }
                })
            },


            //    开票管理
            // 获取开票列表信息
            async getinvoiceData() {
                this.form_getInvoice.customer_number = this.customerNumber
                let params = this.form_getInvoice
                const res = await this.$https.post(erpApi + '/customer/invoice/index?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.invoiceData = res.data.data
            },



            async seeInvoiceDetail(row) {
                this.is_seeInvoiceDetail = true
                let params = {
                    order_num: row.order_num
                }
                const res = await this.$https.post(erpApi + '/customer/invoice/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.invoiceDetailData = res.data
            },

            // 申请开票
            addInvoice() {
                this.is_addInvoice = true
                this.addInvoice_form.type_id = ''
                this.invoiceOrderData = []
                this.calcInvoice()
                this.addInvoice_form.remarks = ''
                this.emptyApplyChoose()
            },

            // 置空申请开票-选择订单
            emptyApplyChoose() {
                this.custOrderData.forEach(node => {
                    this.$refs.multipleTableInvoice.toggleRowSelection(node, false)
                })
            },



            handleCloseInvoice(tag) {
                let i = this.invoiceOrderData.indexOf(tag)
                this.invoiceOrderData.splice(i, 1)
                this.custOrderData.forEach(node => {
                    if (node.id == tag.id) {
                        this.$nextTick(() => {
                            this.$refs.multipleTableInvoice.toggleRowSelection(node)
                        });
                    }
                })
                this.calcInvoice()
            },

            handleCurrentChangeInvoice(data) {
                this.getOrder_form.page = data
                this.getCustOrder()
            },

            calcInvoice() {
                this.invoiceOrderDataLength = this.invoiceOrderData.length
                this.invoiceOrderMoney = 0
                this.invoiceOrderData.forEach(node => {
                    this.invoiceOrderMoney += Number(node.invoice_amount)
                })
            },


            async addInvoiceDo() {

                this.addInvoice_form.customer_number = this.customerNumber
                // 遍历数组
                this.addInvoice_form.order_list = []
                this.invoiceOrderData.forEach(node => {
                    this.addInvoice_form.order_list.push(node.order_num)
                })
                let params = this.addInvoice_form
                const res = await this.$https.post(erpApi + '/customer/invoice/create?_ajax=1', params)
                if (res.data.error_code !== 0) return this.$message.error(res.data.msg)
                this.$message.success(res.data.msg)
                this.is_addInvoice = false
            },

            // 添加订单
            addOrderFun() {
                this.is_addInvoice_chooseOrder = true
                this.getCustOrder()
            },


            getCurrentRow(row) {
                this.tableRadio = row.id
                this.invoiceOrderData = []
                this.invoiceOrderData.push(row)
                this.calcInvoice()
            },




            addOrderItemDo() {
                this.is_addInvoice_chooseOrder = false
                this.calcInvoice()
            },

            deleteOrderItem(row) {
                let i = this.invoiceOrderData.indexOf(row)
                this.invoiceOrderData.splice(i, 1)
                this.tableRadio = ''
            },


            changeFinance(data) {
                if (!this.bEnterAccountFinsType) {
                    this.FinanceData.forEach(node => {
                        if (node.id == data) {
                            if (node.is_upload) {
                                this.fileRefState = true
                            } else {
                                this.fileRefState = false
                            }
                        }
                    })
                    this.Form_Recharge.file = ''
                }

            },

            // 冻结保证金
            freeze() {
                this.is_freeze = true
                this.Form_freeze.customer_number = this.customerNumber
                this.Form_freeze.amount = ''
                this.Form_freeze.remarks = ''
            },
            async freezeDo() {
                let params = this.Form_freeze
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/finance/deposit?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_freeze = false
            },


            // 退还保证金
            returnCashDeposit() {
                this.is_returnCashDeposit = true
                this.Form_returnCashDeposit.customer_number = this.customerNumber
                this.Form_returnCashDeposit.amount = ''
                this.Form_returnCashDeposit.remarks = ''
            },
            async returnCashDepositDo() {
                let params = this.Form_returnCashDeposit
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/finance/undeposit?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_returnCashDeposit = false
            },


            // 配送余额转换
            transitionMoney() {
                this.is_transitionMoney = true
                this.Form_transitionMoney.customer_number = this.customerNumber
                this.Form_transitionMoney.transfer_present_id = ''
                this.Form_transitionMoney.receive_present_id = ''
                this.Form_transitionMoney.amount = ''
                this.Form_transitionMoney.remarks = ''
            },
            async transitionMoneyDo() {
                let params = this.Form_transitionMoney
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/finance/transfer?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_transitionMoney = false
            },

            // ----------------------退货管理方法------------------------------------------------
            fnSalesReturn() {
                let obj = {
                    sCustomerNumber: this.customerNumber,
                    sCustomerName: this.lookDetailData.name,
                    sCustomerAccount: this.lookDetailData.account,
                    sCustomerCreditSpend: this.lookDetailData.credit_spend,
                    sCustomerDeposit: this.lookDetailData.deposit,

                }
                this.$router.push({

                    path: '/salesReturnSingle',
                    query: {
                        data: obj,
                    }
                })
            },

            // ----------------------发票管理方法------------------------------------------------
            fnInvoiceManagement() {
                let obj = {
                    sCustomerNumber: this.customerNumber,
                    sCustomerName: this.lookDetailData.name,
                    sCustomerAccount: this.lookDetailData.account,
                    sCustomerCreditSpend: this.lookDetailData.credit_spend,
                    sCustomerDeposit: this.lookDetailData.deposit,

                }
                this.$router.push({

                    path: '/invoiceSingle',
                    query: {
                        data: obj,
                    }
                })
            },



            // ----------------------客户财务管理方法------------------------------------------------
            financialManagement(item) {

                this.$router.push({
                    path: '/cusFinancialManagement',
                    query: {
                        data: this.customerNumber,
                    }
                })
            },
            // ----------------------订单管理方法------------------------------------------------
            OrderManagement(item) {

                this.$router.push({
                    path: '/OrderManagement',
                    query: {
                        data: this.customerNumber,
                    }
                })
            },


            // =--------------------------------------------------------------
            fnStoreClickItem(node) {
                this.nCurrentStoreId = node.id
            },


            fnNoteClickItem(node) {
                this.nCurrentNoteId = node.id
            },

            fnRemarksClickItem(node) {
                this.nCurrentRemarksId = node.id
            },


            // 切换样式
            fnCutsomerDetailHandleClick(data) {
                if (data.index == 0) {
                    this.sCustomerDetailActiveName = "first"
                }
                if (data.index == 1) {
                    this.sCustomerDetailActiveName = "second"
                }
                if (data.index == 2) {
                    this.sCustomerDetailActiveName = "third"
                }
                if (data.index == 3) {
                    this.sCustomerDetailActiveName = "fourth"
                }
                if (data.index == 4) {
                    this.sCustomerDetailActiveName = "fifth"
                }
                if (data.index == 5) {
                    this.sCustomerDetailActiveName = "sixth"
                }
                if (data.index == 6) {
                    this.sCustomerDetailActiveName = "seventh"
                }
                if (data.index == 7) {
                    this.sCustomerDetailActiveName = "eighth"
                }
            },

            // 查看详情
            async fnDetail(row) {
                this.bTakeChargeCustomer = true
                const res = await this.$https.get(erpApi + '/user/customer/bind?_ajax=1&user_id=' + row.id)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.aTakeChargeCustomerData = res.data;
            },

            // -----------------------查看客户详情数据--------------------------------------
            async lookDetail(row) {

                let routeData = this.$router.resolve({
                    path: '/customerDetail',
                    query: {
                        data: row.number,

                    }
                })
                window.open(routeData.href, '_blank')
            },


            // 点开订单预览
            fnOpenOrderPreview(row){
                this.currentOrderNumber = row.order_num
                this.bOrderPreview = true
                this.fnGetOrderDetail(row)
            },
            // 获取订单详情
           async fnGetOrderDetail(row){
                 let params = {
                    order_num: row.order_num
                }
                 const res = await this.$https.post(erpApi + '/customer/orders/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.DetailData = res.data
                this.fnValNumberOfGoods( this.DetailData.goods_list)
                this.fnValNumberOfProduct(this.DetailData.product_list)

            },
            // 订单预览跳转快递
              jumpExpress(row) {
                let expressNum = row.express_num
                let url = "https://api.rainben.cn/express/detail?num="  + expressNum
                
                window.open(url)

            },
            // 绑定表格头部样式方法 ---第二种
            styleBindFunOfTwo() {
                let styleBind = "background:#f2f3f5;fontSize:12px;color:#1D2129;fontWeight: normal;"
                return styleBind;
            },
             fnValNumberOfProduct(data){
               this.nProductContainNumber=data.length
            },
             // 赋值个数
            fnValNumberOfGoods(data){
               this.nGoodsContainNumber=data.length
            },

             // 跳转打印单
            printDo() {

                let number = this.currentOrderNumber
                window.open('/printContent.html?number=' + number)
            },










        }
    }
</script>


<style lang="less" scoped>
    .DistributionSchemeBtns {
        // background-color: #dfe9f5;
        padding: 5px 20px;
        display: flex;
        flex-wrap: wrap;
    }

    .el-descriptions {
        width: 75%;
    }

    .DistributionSchemeBtns span {
        width: 25%;
        margin: 0 10px 10px 10px;
        display: flex;
        justify-content: start;
        align-items: center;
    }

    .DistributionSchemeBtns span .el-input {
        width: 60%;
    }

    .el-descriptions {
        width: 94%;
    }

    .el-divider--horizontal {
        width: 95%;
    }

    .sCustomerDetailTopStyle .el-divider--horizontal {
        width: 100%;
    }

    .el-card {
        margin-bottom: 20px;
        position: relative;
    }


    // .ReceivingAddress {
    //     position: relative;
    // }

    // .ReceivingAddress .el-button {
    //     position: absolute;
    //     top: 10%;
    //     right: 7%;
    //     background-color: #e2231a;
    //     border: none;
    // }

    .users .el-descriptions-item__content {
        width: 100%;
    }

    .users,
    .ReceivingAddress,
    .remarks,
    .ReturnVisitRecord,
    .StoreManagement,
    .logs {
        width: 100%;
        box-sizing: border-box;
        position: relative;
    }

    .ManagementAuthorityAssignmentBtn {
        // position: absolute;
        // right: 5%;
        // top: 8%;
        float: left;
        margin-top: 10px;
        // margin-left: 5%;
        margin-bottom: 20px;
    }


    .addReturnVisit {
        float: right;
        margin-right: 5%;
        margin-bottom: 10px;

    }




    .logstable {
        overflow-y: auto;
    }

    .users .title,
    .StoreManagement .title,
    .remarks .title,
    .ReceivingAddress .title,
    .ReturnVisitRecord .title,
    .logs .title {
        color: #303133;
        font-size: 16px;
        font-family: 'microsoft yahei';
        font-weight: 550;
        // background-color: pink;
        display: flex;
        align-items: center;
        position: relative;
        // background-color: pink;
    }

    .remarks .title span {
        position: absolute;
        right: 2%;
    }

    .ReceivingAddress .title,
    .DistributionType_style .title {
        color: #303133;
        font-size: 16px;
        font-family: 'microsoft yahei';
        font-weight: 550;
    }

    .DistributionType_style .content,
    .ReceivingAddress .content {
        display: flex;
        flex-wrap: wrap;
        // padding-left: 10%;
        width: 100%;

    }



    .ReceivingAddress .content .item,
    .DistributionType_style .content .item {
        width: 20%;
        padding: 18px 20px;
        margin-top: 20px;
        margin-right: 2%;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        // background-color: pink;
        background-color: #f7f8fa;
        position: relative;
        border-radius: 6px;
        transition: all 0.3s ease-in-out;
    }

    .ReceivingAddress .content .item:hover,
    .DistributionType_style .content .item:hover {
        cursor: pointer;
        transform: translateY(-1px);
        box-shadow: 1px 1px 5px #c8cacc;
    }



    .ReceivingAddress .content .item div,
    .DistributionType_style .content .item div {
        margin: 2px 0;
    }

    .ReceivingAddress .content .item div:nth-child(1),
    .DistributionType_style .content .item div:nth-child(1) {
        margin: 5px 0;
    }

    .ReceivingAddress .content .item .tit,
    .DistributionType_style .content .item .tit {
        color: #000000D9;
        font-size: 15px;
        font-weight: 550;
    }

    .ReceivingAddress .delete {
        position: absolute;
        right: 10%;
        bottom: 3%;
        font-weight: 650;
        font-size: 14px;
        color: #f56c6c;
    }

    .ReceivingAddress .edit {
        position: absolute;
        right: 21%;
        bottom: 3%;
        font-weight: 650;
        font-size: 14px;

    }

    .ReceivingAddress .delete:hover {
        color: #f56c6c;
    }

    .addReceving {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: none;
        background-color: #fafafa;
    }






    .base {
        position: relative;
    }


    .goback {
        position: absolute;
        right: 5%;
        top: 5%;
        background-color: #e7e7e7;
        border-color: #e7e7e7;
        color: #333;
    }

    .goback:hover {
        background-color: #dcdcdc;
        border-color: #dcdcdc;
        color: #333;
    }

    .goback:focus {
        background-color: #ababab;
        border-color: #ababab;
        color: #333;
    }

    .remarksContent {
        width: 200%;
    }

    .deleteRemarks {
        color: #f56c6c;
    }

    .deleteRemarks:hover {
        color: #f56c6c;
    }





    // 管理权限分配

    /deep/.bindUserGroup .el-dialog__body {
        height: 500px !important;
        overflow-y: hidden !important;
    }

    .all {
        display: flex;
        justify-content: start;
        width: 100%;
    }

    .all .left {
        flex: 7;
        position: relative;
        background-color: #fdfdfd;
    }

    .all .right {
        flex: 6;
        padding-left: 3%;
        background-color: #fdfdfd;
        border-left: 1px solid #e4e6e9;
        margin-left: 5%;
        padding-top: 0;
    }

    .all .right .tit {
        color: #787878;
    }

    .setSupSubStyle {
        margin-top: 1%;
        height: 480px;
        overflow-y: auto;
    }

    .setSupSubStyle .tit {
        font-size: 15px;
    }

    .setSupSubStyle .tit span {
        font-size: 13px;
    }

    /deep/.addDAMElTree .el-tree-node:focus>.el-tree-node__content {
        background-color: #f9fafc !important;
    }

    .bindUserGroup-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 14px;
        padding-right: 24px;
        font-size: 14px;
        font-family: Arial, Helvetica, sans-serif;
        color: #000000;
        margin-bottom: 8px;
    }

    .itemStyle {
        margin-bottom: 8px;
        position: relative;
    }

    ul,
    li {
        list-style: none;
        text-decoration: none;
        padding: 0;
    }

    .closeIcon {
        font-weight: 1000;
        color: #ababab;
        position: absolute;
        right: 6%;
    }

    .tabs_style {
        margin-top: 10px;
        margin-left: 10px;
    }

    .remarks_input_style {
        width: 10%;
    }

    .remarks_search {
        font-size: 14px;
        color: #333;
        margin-top: 5px;
        padding: 10px;
    }


    .el-timeline {

        margin-left: 20px;
    }

    .el-timeline-item {
        padding-bottom: 20px;
    }

    .logItem_style {
        margin-right: 20px;
    }

    #app {
        height: auto;
    }


    .logs .content::-webkit-scrollbar,
    .setSupSubStyle::-webkit-scrollbar {
        width: 6px;
    }



    .logs .content::-webkit-scrollbar-thumb,
    .setSupSubStyle::-webkit-scrollbar-thumb {

        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(221, 222, 224);
    }

    .logs .content {
        margin-top: 30px;
        height: 350px;
        overflow-y: auto;
    }

    .sort_style {
        padding-left: 7%;
        width: 39%;
    }




    /deep/.el-cascader {
        width: 130% !important;
    }

    /deep/.edit_Store_style .el-dialog__body {
        display: flex;
        justify-content: start;
    }

    /deep/.edit_Store_style .el-form {
        display: flex;
        flex-wrap: wrap;
    }


    /deep/.edit_Store_style .el-form-item {
        width: 95%;
    }


    /deep/.edit_Store_style .el-form-item .el-cascader {
        width: 100% !important;
    }










    .setSuperior__search__style {
        width: 90%;
        margin-bottom: 15px;
    }

    .setSuperior__search__result__style {
        padding: 10px;
    }

    .setSuperior__search__result__style .content {
        display: flex;
        flex-direction: column;
    }

    .setSuperior__search__result__style .content .item {
        margin-top: 10px;
        padding: 5px 5px 5px 10px;
    }

    .active__setsuperior__style {
        background-color: #4a77ac;
        cursor: pointer;
        color: #fff;
    }


    .hover__setsuperior__style:hover {
        background-color: #f2f2f2;
        cursor: pointer;
        border-radius: 5px;
    }

    .ckeckStyle {
        margin-left: 6px;
    }

    .newOrder_style {
        position: absolute;
        right: 10%;
        top: 6%;
    }

    .btns_style {
        // background-color: #f5f7fa;
        // background-color: #f6f8fb;
        background-color: #dfe9f5;
        // background-color: #fffbf3;

        padding: 6px 20px;
        margin-bottom: 20px;
    }



    .invoicingInfo_style {
        width: 100%;
        position: relative;
    }

    .invoicingInfo_tit_style {
        color: #333333;
        font-size: 16px;
        font-weight: bolder;
    }

    .iconColor {
        color: #0052d9
    }

    .invoicingInfo_con_style {
        margin-top: 15px;
        margin-left: 20px;
    }

    .invoicingInfo_btns_style {
        position: absolute;
        right: 0%;
        top: 1px;
    }



    .invoicingInfo_con_style div span {
        flex: 1;
        color: #6f6f6f;
        display: flex;
        margin-bottom: 6px;
    }

    .invoicingInfo_con_style div span span {
        color: #333333;
    }

    .input_width {
        width: 50%;
    }

    .is_set_style {
        margin-left: 10px;
    }

    /deep/.refillCard_style .el-dialog__body {
        display: flex;
        flex-direction: column;
    }

    .DistributionType_con {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
    }

    .activate_input_width {
        width: 80%;
    }

    .DistributionType_con_item {
        margin-left: 20px;
    }

    div /deep/.invoice_style .el-dialog__body {
        display: flex;
        flex-direction: column;
    }

    /deep/.el-descriptions-item__label {
        // font-weight: 550;
        // color: #666;
        color: #999;
        font-weight: 590;
        font-size: 14px;
    }

    /deep/.base .el-descriptions-item__container {
        margin-bottom: 3px;
    }

    .rechargeForm_style {
        width: 100%;
    }

    .rechargeForm_present_style {
        width: 100%;
    }

    .addInvoice_style {
        padding: 0 10px;
    }

    .addInvoice_style div {
        font-size: 14px;
        color: #333;

    }

    .addInvoice_style .first,
    .addInvoice_style .second,
    .addInvoice_style .third {
        margin-bottom: 30px;
    }

    .addInvoice_style .third {
        display: flex;
        justify-content: end;
        margin-right: 20px;
    }

    .addInvoice_style .third span:first-child {
        margin-right: 10px;
    }

    .invoiceSelectedItem {
        margin-right: 20px;
    }

    .addInvoice_style .last {
        display: flex;
        align-items: center;
        margin-top: 20px;
    }

    .addInvoice_style .second {
        position: relative;
    }

    .addInvoice_style .second .addOrderStyle {
        position: absolute;
        top: 0;
        right: 0;
    }

    .invoiceRemarks {
        width: 70%;
        margin-left: 20px;
    }

    .invoiceTabs_style {
        margin-top: 10px;
        margin-left: 10px;
    }

    div /deep/.present_select_style .el-select {
        width: 150%;
    }

    div /deep/.recharge_style .el-dialog__body {
        display: flex;
        flex-direction: column;
    }

    .recharge_style .content {
        padding-right: 30px;
    }



    .recharge_style .content .first {
        display: flex;
        justify-content: start;


    }

    .recharge_style .content .first .el-form_item {
        width: 30%;
    }

    .eltag_selectedRefillCard {
        margin: 5px 10px;
    }

    .recharge_style .content .second {
        padding-left: 30px;
    }

    .recharge_style .content .second .tit {
        margin: 30px 0;
    }

    .recharge_style .content .second .tit span {
        margin-left: 10px;
    }

    .infoStyle {
        color: #1f6eef;
        font-size: 14px;
        margin-right: 3px;
    }



    .recharge-ruleForm .base .tit {
        font-size: 14px;
        font-weight: bold;
        color: #333;
        padding-left: 10px;
        margin-bottom: 5px;
    }

    .recharge-ruleForm .second {
        margin-top: 50px;
    }

    .sEntryDrawer .content .second {
        padding-left: 25px;
    }

    .sEntryDrawer .content .second .title {
        font-size: 14px;
        font-weight: bold;
        color: #333;
        transform: translate(-15px);


    }



    div /deep/.elTableInvoiceStyle .el-table__body .el-table__row .el-table__cell {
        padding: 8px
    }

    /* el-table 滚动条样式 */
    div /deep/.el-table__body-wrapper::-webkit-scrollbar {
        height: 8px;
        width: 6px;
    }



    div /deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }

    .sSearchStyle {
        background-color: #f5f7fa;
        padding: 10px 20px;

    }

    .sSearchStyle span {
        margin-right: 20px;
    }


    .sInvoiceTableStyle,
    .sStoreManagementTableStyle {
        max-height: 485px;
        width: 100%;
        overflow-y: auto;
    }


    /* el-table 滚动条样式 */
    .sStoreManagementTableStyle::-webkit-scrollbar {
        height: 8px;
        width: 6px;
    }



    .sStoreManagementTableStyle::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }

    // 切换样式

    .sCustomerDetailTopStyle .tit .name {
        font-size: 24px;
        color: #000;
        font-weight: bolder;
        display: flex;
    }

    .sCustomerDetailTopStyle .tit .alias {
        font-size: 14px;
        color: #848994;
        margin-top: 15px;
    }

    .sCustomerDetailTopStyle .tit .status {
        margin-left: 18px;
        display: flex;
        align-items: center;
    }

    .sCustomerDetailTopStyle .content {
        display: flex;
        flex-wrap: wrap;
    }

    .sCustomerDetailTopStyle .content span {
        display: flex;
        margin-right: 100px;
        font-size: 14px;

    }

    .sCustomerDetailTopStyle .content span .left {
        color: #606266;
        margin-right: 10px;
        display: flex;
    }

    .sCustomerDetailTopSmallScreenStyle span:nth-child(1) {
        margin-bottom: 10px;
    }






    .sCustomerGeneralItemStyle {
        display: flex;
        width: 70%;
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .sCustomerGeneralItemStyle span {
        display: flex;
        align-items: center;
        flex: 1;
        font-size: 14px;
    }

    .sCustomerGeneralItemStyle span .left {
        margin-right: 10px;
    }

    .sCustomerDetailStyle {
        position: relative;
    }

    .sCustomerDetailStyle .sCustomerDetaolBtnStyle,
    .sCustomerDetailStyle .sCustomerDetailBtnSmallScreenStyle {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .sCustomerDetailStyle .sCustomerDetaolBtnStyle span {
        padding: 8px 15px;
        border-top: 1px solid #dce0e2;
        border-right: 1px solid #dce0e2;
        border-bottom: 1px solid #dce0e2;
        font-size: 14px;
        color: #777c86;
    }


    .sCustomerDetailStyle .sCustomerDetaolBtnStyle span:nth-child(1) {
        border-left: 1px solid #dce0e2;
        border-radius: 3px 0 0 3px;
    }

    .sCustomerDetailStyle .sCustomerDetaolBtnStyle span:last-child {
        border-radius: 0 3px 3px 0;
    }


    .sCustomerDetailStyle .sCustomerDetaolBtnStyle span:hover {
        background-color: #707bc0;
        color: #fff;
        cursor: pointer;
    }

    .sCustomerDetailBtnSmallScreenStyle div {
        display: flex;
    }

    .sCustomerDetailBtnSmallScreenStyle div span {
        padding: 8px 20px;
        border-top: 1px solid #dce0e2;
        border-right: 1px solid #dce0e2;
        border-bottom: 1px solid #dce0e2;
        font-size: 14px;
        color: #777c86;
    }

    .sCustomerDetailStyle .sCustomerDetailBtnSmallScreenStyle div span:nth-child(1) {
        border-left: 1px solid #dce0e2;
        border-radius: 3px 0 0 3px;
    }

    .sCustomerDetailStyle .sCustomerDetailBtnSmallScreenStyle div span:last-child {
        border-radius: 0 3px 3px 0;
    }

    .sCustomerDetailStyle .sCustomerDetailBtnSmallScreenStyle div span:hover {
        background-color: #707bc0;
        color: #fff;
        cursor: pointer;
    }



    .sCustomerDetailBtnSmallScreenStyle .bottom {
        margin-top: 10px;
        margin-bottom: 10px;
    }







    .sCustomerDetailTabsStyle {
        margin-bottom: 0px;
        height: 630px;
    }

    .sTypeStyle {
        margin-left: 80px;
    }

    .aicenter {
        display: flex;
        align-items: center;
    }

    .chooseFileInputStyle {
        margin-top: 15px;
    }

    .amountInput {
        width: 50%;
    }

    .chooseRechargeStyle {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }

    .chooseRechargeStyle span {
        font-size: 14px;
    }

    .sEntryBtn{
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }

    // 订单预览
    

    div /deep/ .sOrderDetailStyle .el-dialog__header {
        padding: 10px 20px;
        font-weight: bolder;
    }

    div /deep/ .sOrderDetailStyle .el-dialog__body {
        display: flex;
        flex-direction: column;
    }

    div /deep/ .sOrderDetailStyle .sAreaStyle .thirdly .content .el-table__header tr,
    div /deep/ .sOrderDetailStyle .sAreaStyle .thirdly .content .el-table__header th,
    div /deep/ .sOrderDetailStyle .sAreaStyle .fourthly .content .el-table__header tr,
    div /deep/ .sOrderDetailStyle .sAreaStyle .fourthly .content .el-table__header th,
    div /deep/ .sOrderDetailStyle .sAreaStyle .fifth .content .el-table__header tr,
    div /deep/ .sOrderDetailStyle .sAreaStyle .fifth .content .el-table__header th {
        padding: 5px;
    }

    /* el-table 滚动条样式 */
    div /deep/ .sOrderDetailStyle .el-dialog__body::-webkit-scrollbar {
        height: 8px;
        width: 8px;
    }



    div /deep/ .sOrderDetailStyle .el-dialog__body::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }

    div /deep/ .sOrderDetailStyle .el-dialog__body::-webkit-scrollbar-thumb:hover {
        background-color: #a8a8a8;
    }



    .sOrderDetailStyle .sAreaStyle {
        height: 680px;
    }

    .sOrderDetailStyle .sAreaStyle .thirdly,
    .sOrderDetailStyle .sAreaStyle .fifth,
    .sOrderDetailStyle .sAreaStyle .sixth,
    .sOrderDetailStyle .sAreaStyle .seventh,
    .sOrderDetailStyle .sAreaStyle .fourthly {
        margin-top: 10px;
    }


    .sOrderDetailStyle .sAreaStyle .first .tit,
    .sOrderDetailStyle .sAreaStyle .second .tit,
    .sOrderDetailStyle .sAreaStyle .fourthly .tit,
    .sOrderDetailStyle .sAreaStyle .fifth .tit,
    .sOrderDetailStyle .sAreaStyle .sixth .tit,
    .sOrderDetailStyle .sAreaStyle .seventh .tit,
    .sOrderDetailStyle .sAreaStyle .thirdly .tit {

        font-size: 14px;
        color: #333;
        display: flex;
        align-items: center;
    }


    .sOrderDetailStyle .sAreaStyle .first .content,
    .sOrderDetailStyle .sAreaStyle .second .content,
    .sOrderDetailStyle .sAreaStyle .fourthly .content,
    .sOrderDetailStyle .sAreaStyle .fifth .content,
    .sOrderDetailStyle .sAreaStyle .sixth .content,
    .sOrderDetailStyle .sAreaStyle .seventh .content,
    .sOrderDetailStyle .sAreaStyle .thirdly .content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 6px 20px;
    }

    .sOrderDetailStyle .sAreaStyle .thirdly .content,
    .sOrderDetailStyle .sAreaStyle .fifth .content,
    .sOrderDetailStyle .sAreaStyle .sixth .content,
    .sOrderDetailStyle .sAreaStyle .seventh .content,
    .sOrderDetailStyle .sAreaStyle .fourthly .content {
        padding: 0px 20px;
    }

    .sOrderDetailStyle .sAreaStyle .first .content span {
        width: 33%;
        display: flex;
        margin-bottom: 10px;
        font-size: 12px;
    }

    .sOrderDetailStyle .sAreaStyle .first .content span .left {
        margin-right: 12px;
    }

    .sOrderDetailStyle .sAreaStyle .second .content .address,
    .sOrderDetailStyle .sAreaStyle .second .content .contact,
    .sOrderDetailStyle .sAreaStyle .second .content .tel {
        margin-right: 50px;
    }


    div /deep/ .sOrderDetailStyle .sAreaStyle .thirdly .content .el-table,
    div /deep/ .sOrderDetailStyle .sAreaStyle .fifth .content .el-table,
    div /deep/ .sOrderDetailStyle .sAreaStyle .sixth .content .el-table,
    div /deep/ .sOrderDetailStyle .sAreaStyle .fourthly .content .el-table {
        margin-top: 10px;
    }
    .sContainNumber{
        display: flex;
        flex-direction: column;
    }

    .sGoodsNumberStyle{
        margin-top: 10px;
        padding-left: 90%;
    }

    .sendGoodsBtnStyle{
        width: 100px;
        margin-bottom: 20px;
    }

</style>