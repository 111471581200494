<template>
    <div id="app">
        <!-- 面包屑导航 -->
        <!-- <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>客户设置</el-breadcrumb-item>
            <el-breadcrumb-item>配送方案设置</el-breadcrumb-item>
        </el-breadcrumb> -->


        <el-card>
            <div class="sliderAreaStyle">
          
            <!--按钮区 -->
           
            <div class="DistributionSchemeBtns">
                <el-button @click="createDistributionScheme()" icon="el-icon-plus" class="frontBtn" size="small">创建配送方案
                </el-button>
              
            </div>

                <el-table :data="DistributionSchemeData" height="720" border style="width: 100%" :header-cell-style="styleBindFun">
                       <el-table-column prop="name" label="配送方案名称" align="center">
                </el-table-column>
                <el-table-column prop="amount" label="金额" align="center">
                    <template slot-scope="scope">
                        ￥{{scope.row.amount}}
                    </template>
                </el-table-column>
                <el-table-column prop="ratio" label="比例" align="center">
                </el-table-column>
                <el-table-column prop="present_amount" label="配送金额" align="center">
                    <template slot-scope="scope">
                     <i class="NumberNoLeft">￥{{scope.row.present_amount_format}}</i>   
                    </template>
                </el-table-column>
                <el-table-column prop="customers_count" label="顾客人数" align="center">
                </el-table-column>
                <el-table-column prop="update_time" label="更新时间" align="center">
                    <template slot-scope="scope">
                        {{scope.row.update_time.trim().split(" ")[0]}}
                    </template>
                </el-table-column>

                <el-table-column fixed="right" label="操作" align="center">
                    <template slot-scope="scope">
                  
                        <el-button @click="editDistributionScheme(scope.row)" type="text" size="medium">修改</el-button>
                         <span class="splitLineClass"></span>
                        <el-button @click="deleteDistributionScheme(scope.row)" type="text" class="deleteTextBtn" size="medium">删除</el-button>
                  
                    </template>
                </el-table-column>
            </el-table>

          
                  
            </div>
        </el-card>



        <!-- ---------------------------------------- 对话框第一层 --------------------------------------------------- -->
        <!-- 编辑配送方案详情对话框 -->
        <el-dialog title="修改配送方案" :visible.sync="is_DistributionScheme_edit" width="28%">

            <el-form :model="Form_DistributionScheme" :rules="DistributionSchemeRules" label-width="100px">
                <el-form-item label="方案名称" prop="name">
                    <el-input v-model="Form_DistributionScheme.name"></el-input>
                </el-form-item>
                <el-form-item label="金额" prop="amount">
                    <el-input v-model="Form_DistributionScheme.amount"></el-input>
                </el-form-item>
                <el-form-item label="比例" prop="ratio">
                    <el-input v-model="Form_DistributionScheme.ratio"></el-input>
                </el-form-item>
                  <el-form-item label="排序：" >
                    <el-input v-model="Form_DistributionScheme.sort_order" size="small"></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_DistributionScheme_edit = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" type="primary" @click="editDistributionSchemeDo">确 定</el-button>
            </div>
        </el-dialog>


        <!-- 创建配送方案详情对话框 -->
        <el-dialog title="创建配送方案" :visible.sync="is_createDistributionScheme" width="28%">

            <el-form :model="Form_DistributionScheme" :rules="DistributionSchemeRules" label-width="100px">
                <el-form-item label="方案名称：" prop="name">
                    <el-input v-model="Form_DistributionScheme.name" size="small"></el-input>
                </el-form-item>
                <el-form-item label="金额：" prop="amount">
                    <el-input v-model="Form_DistributionScheme.amount" size="small"></el-input>
                </el-form-item>
                <el-form-item label="比例：" prop="ratio">
                    <el-input v-model="Form_DistributionScheme.ratio" size="small"></el-input>
                </el-form-item>
                <el-form-item label="排序：" >
                    <el-input v-model="Form_DistributionScheme.sort_order" size="small"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_createDistributionScheme = false">取 消</el-button>
                <el-button size="mini" class="frontBtn"  type="primary" @click="createDistributionSchemeDo">确 定</el-button>
            </div>
        </el-dialog>



      

    </div>
</template>

<script>
      import {
        adminApi
    } from '../../config'

    import {
    domainApi
  } from '../../config'
 
    export default {
        data() {
            return {
                // -----------------------获取配送方案数据--------------------------------------
                DistributionSchemeData: [],

                // ----------------------查看配送方案详情数据--------------------------------------
                DistributionSchemeDetailData: [],
                is_DistributionScheme_detail: false,

                // ----------------------创建配送方案详情数据--------------------------------------
                is_createDistributionScheme: false,
                Form_DistributionScheme: {
                    name: '',
                    amount: '',
                    ratio: '',
                    sort_order:'',
                },
                DistributionSchemeRules: {
                    name: {
                        required: true,
                        message: '请输入配送方案名称',
                        trigger: 'blur'
                    },
                    amount: {
                        required: true,
                        message: '请输入配送方案金额',
                        trigger: 'blur'
                    },
                    ratio: {
                        required: true,
                        message: '请输入配送方案比例',
                        trigger: 'blur'
                    },
                },

                // ----------------------编辑配送方案详情数据--------------------------------------
                is_DistributionScheme_edit: false,
                current_edit_id: "",

                bData: [],
               

            }
        },
        created() {
            this.getDistributionScheme();
        },
       
        methods: {
          
            

            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind = "background:#f6f8fa;fontSize:14px;color:#333333;fontWeight: bold;"
                return styleBind;
            },

            // -----------------------获取配送方案数据--------------------------------------
            async getDistributionScheme() {
                const res = await this.$https.post(adminApi+'/plans?_ajax=1')
                if(res.status!==200) return this.$message.error(res.statusText)
                 this.DistributionSchemeData=res.data   
            },

          


            // ----------------------查看配送方案详情数据--------------------------------------
            async seeDistributionScheme(row) {
                this.is_DistributionScheme_detail = true
                let params = {
                    id: row.id
                }
                const res = await this.$https.post(adminApi+'/plan/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.DistributionSchemeDetailData = res.data

            },

            // ----------------------创建配送方案详情数据--------------------------------------
            createDistributionScheme() {

                this.is_createDistributionScheme = true

                // 清空
                for (let k in this.Form_DistributionScheme) {
                    this.Form_DistributionScheme[k] = ''
                }
            },
            async createDistributionSchemeDo() {
               
                        let params = this.Form_DistributionScheme
                        const {
                            data
                        } = await this.$https.post(adminApi+'/plan/create?_ajax=1', params)
                      
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)
                        this.is_createDistributionScheme = false
                        this.getDistributionScheme();

            },

            // ----------------------编辑配送方案详情数据--------------------------------------
            editDistributionScheme(row) {

                this.is_DistributionScheme_edit = true
                let {
                    id,
                    name,
                    amount,
                    ratio,
                    sort_order
                } = row
                this.Form_DistributionScheme.name = name
                this.Form_DistributionScheme.amount = amount
                this.Form_DistributionScheme.ratio = ratio
                this.Form_DistributionScheme.sort_order = sort_order
                this.current_edit_id = id


            },
            async editDistributionSchemeDo() {
                        let params = this.Form_DistributionScheme
                        params.id = this.current_edit_id
                        const {
                            data
                        } = await this.$https.post(adminApi+'/plan/edit?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg);
                        this.is_DistributionScheme_edit = false
                        this.getDistributionScheme();
            },


            // ----------------------删除配送方案详情数据--------------------------------------
            async deleteDistributionScheme(row) {
                this.$confirm('确认删除这个配送方案吗?')
                    .then(async () => {
                        let params = {
                            id: row.id
                        }
                        const {
                            data
                        } = await this.$https.post(adminApi+'/plan/delete?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)
                        this.getDistributionScheme()
                    })
                    .catch(() => {
                        this.$message.ingo('您取消了按钮')
                    });
            },
        },
    }
</script>

<style lang="less" scoped>
   
   

    .DistributionSchemeBtns {

        padding: 6px 20px;
        margin-bottom: 20px;
    }

    .el-table{
        // max-height: 730px;
        // overflow-y:scroll;
    }
      /* el-table 滚动条样式 */
    div /deep/.el-table__body-wrapper::-webkit-scrollbar {
        height: 8px;
        width: 6px;
    }



    div /deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }
</style>