<template>
    <div id="app">
        <!-- 面包屑导航 -->
        <!-- <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>业务管理</el-breadcrumb-item>
            <el-breadcrumb-item>客户管理</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item>创建订单</el-breadcrumb-item>
        </el-breadcrumb> -->

        <el-card class="addNewCustomer">
            <div class="top">{{customerName}}</div>
            <div class="title">修改订单
            </div>
            <el-button class="goBack" @click="goBack" size="small">返回</el-button>

            <div class="item">

                <span>

                    <el-form label-position="top" label-width="80px">
                        <el-form-item label="发货方式：" prop="must">
                            <el-select class="choosed" v-model="chooseExpress" clearable @change="expressChange"
                                placeholder="请选择" size="small">
                                <el-option v-for="item in expressData" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </span>

                <span>

                    <el-form label-position="top" label-width="80px">
                        <el-form-item label="收货地址：" prop="must">
                            <el-select class="choosed" v-model="chooseCustomerAddress" clearable placeholder="请选择"
                                size="small" :disabled="isDis">
                                <el-option v-for="item in CustomerAddressData" :key="item.id" :label="item.address"
                                    :value="item.id">
                                     {{item.region_full_name}}-
                                    {{item.address}} <span class="contact_text"> {{item.contact.name}}
                                        -{{item.contact.mobile}} </span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </span>

            </div>

            <div class="item">
                <span>

                    <el-form label-position="top" label-width="80px">
                        <el-form-item label="进货门店：" prop="must">
                            <el-select class="choosed" v-model="chooseStore"  clearable
                                placeholder="请选择" size="small">
                                <el-option v-for="item in StoreData" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </span>

                <span>
                    <el-form label-position="top" label-width="80px">
                        <el-form-item label="发货仓库：" prop="must">
                            <el-select @change="changeStoreFun" class="choosed" v-model="chooseWareHouse" clearable
                                placeholder="请选择" size="small">
                                <el-option v-for="item in WareHouseData" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </span>


            </div>


            <div class="itemjcstart">

                <el-form label-position="top" label-width="80px">
                    <el-form-item label="备注：" prop="must">
                        <el-input @blur="changeReamrks" size="small" class="remark_style" type='textarea'
                            v-model="remarks" placeholder="请输入此次修改的原因"></el-input>
                    </el-form-item>
                </el-form>
            </div>


            <div class="itemjcstart">
                <div class="goodsListStyle">
                    <span>
                    商品列表：
                    <el-button class="text_primary" type="text" @click="addGoods_Of_newOrder"> 修改商品</el-button>
                    </span>

                    <span>
                        折扣：
                        <span v-if="getGoodsInfo.present_id == 0">
                            <el-tag effect="plain" size="small">{{discountName}}</el-tag>
                        </span>
                        <span v-else>
                            无
                        </span>
                    </span>
                </div>
            </div>

            <div class="goodsList">
                <el-table :data="tempData" :header-cell-style="styleBindFun" style="width: 100%" ref="multiple">
                    <el-table-column prop="goods_sn" label="货号" align="center" width="100">
                    </el-table-column>
                    <el-table-column prop="goods_name" label="商品名称" align="center" width="330">
                    </el-table-column>
                   <el-table-column label="原单价（元）" align="center" prop="price">
                        <template slot-scope="scope">
                            {{scope.row.original_price}}
                        </template>
                    </el-table-column>
                     <el-table-column label="进货单价（元）" align="center" prop="price">
                        <template slot-scope="scope">
                            {{scope.row.sell_price}}
                        </template>
                    </el-table-column>

                    <el-table-column label="数量" align="center">
                        <template slot-scope="scope">
                            <el-input @blur="calc" clearable size="mini" v-model="scope.row.quantity"></el-input>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button class="text_delete" type="text" @click="deleteItem(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>

                </el-table>


                <div class="summary">
                    产品共计种类：<span>{{productType}}</span> 
                    产品共计个数：<span>{{productNum}}</span>
                    订单金额：<span>{{orderAmount}}</span>
                    </div>
            </div>



            <div class="btns">
                <el-button class="submitDo" @click="submitDo" size="small">提交</el-button>
            </div>

        </el-card>




        <!-- ---------------------------------------- 对话框第一层 --------------------------------------------------- -->
        <!-- 添加商品对话框 -->
        <el-dialog title="添加商品" :visible.sync="is_addGoods" width="90%" class="addGoods_of_addOrder">

            <div class="btnStyle">
                <span>
                    选择商品分类：
                    <el-cascader v-model="checkedGoodsType" size="small" :options="GoodsTypeData" clearable
                        @change="searchWithCondition" :show-all-levels="true"
                        :props="{ value:'id',label:'name',checkStrictly: true}"></el-cascader>
                </span>

                <span>
                    搜索:
                    <el-input size="small" class="addGoodsInput " placeholder="请输入内容" v-model="getGoodsInfo.keyword"
                        @change="searchWithCondition" clearable>
                    </el-input>
                </span>

                <el-button size="mini" class="el-icon-refresh reverseBtn" @click="resetDo">重 置</el-button>
            </div>

            <el-table :data="GoodsData_newOrderData" border :header-cell-style="styleBindFun" style="width: 100%"
                ref="multiple_addGoods">


                <el-table-column prop="sn" label="货号" align="center" width="100">
                </el-table-column>
                <el-table-column prop="name" label="商品名称" align="center" width="260">
                    <template slot-scope="scope">
                        <el-button type="text" class="text_primary" @click="seeGoodsDetail(scope.row)">
                            {{scope.row.name}}</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="price" label="价格" align="center">
                    <template slot-scope="scope">
                       <span class="NumberNoLeft">￥{{scope.row.price}}</span> 
                    </template>
                </el-table-column>

                  <el-table-column prop="max_num" label="最大库存数" align="center" width="130">
                </el-table-column>

                <el-table-column label="商品下架日期" align="center" width="130">
                    <template slot-scope="scope">
                        <span v-show="scope.row.end_time">{{scope.row.end_time}}</span>
                    </template>
                </el-table-column>

                <el-table-column label="可售卖" align="center" width="100">
                    <template slot-scope="scope">
                        <div v-if="scope.row.is_sell" class="yesIcon el-icon-check"></div>
                        <div v-else class="noIcon el-icon-close"></div>
                    </template>
                </el-table-column>

                <el-table-column label="一口价" align="center" width="100">
                    <template slot-scope="scope">

                        <div v-if="scope.row.is_fixed" class="yesIcon el-icon-check"></div>
                        <div v-else class="noIcon el-icon-close"></div>
                    </template>
                </el-table-column>

                <el-table-column label="可回收" align="center" width="100">
                    <template slot-scope="scope">
                        <div v-if="scope.row.is_return" class="yesIcon el-icon-check"></div>
                        <div v-else class="noIcon el-icon-close"></div>
                    </template>
                </el-table-column>

                <el-table-column prop="sell_min_num" label="起订数量" align="center" width="130">
                </el-table-column>

                <el-table-column prop="sell_max_num" label="最大可售量" align="center" width="130">
                </el-table-column>

                <el-table-column prop="sell_max_num" label="限购数量" align="center">
                </el-table-column>

              



                <el-table-column label="更新时间" align="center" width="130">
                    <template slot-scope="scope">
                        <span v-show="scope.row.create_time">{{scope.row.create_time.trim().split(" ")[0]}}</span>
                    </template>
                </el-table-column>

                <el-table-column label="操作" align="center" width="130">
                    <template slot-scope="scope">
                        <el-button type="text" :disabled="scope.row.myChecked" @click="addProItem(scope.row)">添加
                        </el-button>
                    </template>
                </el-table-column>



            </el-table>

            <el-pagination @current-change="handleCurrentChange" :current-page="getGoodsInfo.page" :page-sizes="[5]"
                layout="total, sizes, prev, pager, next, jumper" :total="addGoodsTotal" align="center">
            </el-pagination>


            <div slot="footer" class="dialog-footer">
                <el-button class="frontBtn" size="mini" @click="addGoodsDo">确 定</el-button>
            </div>
        </el-dialog>



    </div>
</template>

<script>
    import {
        erpApi
    } from '../../config'

    export default {
        data() {
            return {

                // 传过来的数据
                initData: [],
                order_num: '',
                orderDetailData: [],

                customer_number: this.$route.query.data.customer_number,
                customerName: '',
                discountValue:'',
                discountName:'',
                WareHouseData: [],
                PresentData: [],
                StoreData: [],
                isDis: false,
                CustomerAddressData: [],
              

                expressData: [{
                        id: 0,
                        name: '自取'
                    },
                    {
                        id: 1,
                        name: '包邮'
                    },
                     {
                        id: 2,
                        name: '到付'
                    },
                ],

                chooseWareHouse: '',
                choosePresent: '',
                chooseStore: '',
                chooseCustomerAddress: '',
                chooseExpress: '',

                // --------------------添加商品---------------------------------

                TakefromOneself: false,
                is_addGoods: false,
                GoodsData_newOrderData: [],
                getGoodsInfo: {
                    category_id: '',
                    present_id: '',
                    keyword: '',
                    store_id: '',
                    page: 1,
                    per_page: 5,
                },
                addGoodsTotal: 0,
                presentData: [],
                checkedGoodsType: [],

                GoodsTypeData: [],
                order_addGoods_goodstype_id: '',
                order_addGoods_keyword: '',
                choosedGoods: [],
                numState: false,
                issetNum: false,
                setNum: '',
                currentGoodsName: '',
                productNum: 0,
                productType: 0,


                rules: {
                    must: [{
                        required: true,
                        message: '请把表单填写完整',
                        trigger: 'blur'
                    }],
                },

                choosedGoodsData: [],

                // row临时数组
                rowsData: [],
                tempData: [],
                testData: [],

                remarks: '',


                // 多选框数组
                isIndeterminate: false,
                checkedGood: true,
                checkAll: false,

                orderAmount:0.00,



            }
        },
        created() {

            this.getCustomerAddress()
            this.getGoodsInfoFun()
            this.getWareHouse()
            this.getCustomerDetail()
            this.init()
        },
        methods: {

            changeReamrks() {
                if (this.remarks == '') {
                    return this.$message.error('请填写备注!')
                }
            },

            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind = "background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                return styleBind;
            },

            init() {
                this.initData = this.$route.query.data

                this.order_num = this.initData.order_num
                this.getOrderDetail()
                
                this.getStore()
            },

            async getCustomerDetail(){
                let params={
                    customer_number:this.customer_number
                }
                const res = await this.$https.post(erpApi + '/customer/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.discountValue=res.data.discount_value
                this.discountName=res.data.discount_name
            },




            async getOrderDetail() {
                let params = {
                    order_num: this.order_num
                }

                const res = await this.$https.post(erpApi + '/customer/orders/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.orderDetailData = res.data
                // 赋值
                this.chooseExpress = this.orderDetailData.is_express ? 1 : 0

                this.chooseWareHouse = this.orderDetailData.store_id
                this.customerName = this.orderDetailData.customer_name
                // 收货地址
                  if(this.orderDetailData.address_id !== null) {
                    this.chooseCustomerAddress=this.orderDetailData.address_id
                }

                // 备注
                this.remarks=this.orderDetailData.remarks
                // 门店
                if(this.orderDetailData.shop_id !== null) {
                    this.chooseStore=this.orderDetailData.shop_id
                }

                // 配送id
                this.getGoodsInfo.present_id=this.orderDetailData.present_id


                if (this.chooseExpress == 0) {
                    this.isDis = true
                }
                this.getGoodsInfo.present_id = this.orderDetailData.present_id


                this.tempData = this.orderDetailData.goods_list
                this.calc()


            },

            // 选择仓库
            async getWareHouse() {
                const res = await this.$https.post(erpApi + '/store/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.WareHouseData = res.data

            },

            // 选择配送类型
            async getPresent() {
                const res = await this.$https.post(erpApi + '/present/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.PresentData = res.data
            },

            // 选择门店
            async getStore() {
                let params = {
                    customer_number: this.$route.query.data.customer_number
                }
                const res = await this.$https.post(erpApi + '/customer/shop/index?_ajax=1', params)

                if (res.status !== 200) return this.$message.error(res.statusText)
                this.StoreData = res.data
            },

            // 选择客户地址
            async getCustomerAddress() {
                let params = {
                    customer_number: this.customer_number
                }
                const res = await this.$https.post(erpApi + '/customer/address/select?_ajax=1', params)
               console.log(res,'res1232');
               if (res.status !== 200) return this.$message.error(res.statusText)
                this.CustomerAddressData = res.data
            },


            // 提交
            async submitDo() {
                let goods_list = []
                this.tempData.forEach(item => {
                    let {
                        goods_id,
                        quantity
                    } = item
                    let obj = {}
                    obj.goods_id = goods_id
                    obj.quantity = Number(quantity)
                    goods_list.push(obj)
                })
                let params = {
                    order_num: this.order_num,
                    store_id: this.chooseWareHouse,
                    shop_id: this.chooseStore === '' ? 0 : this.chooseStore,
                    address_id: this.chooseCustomerAddress === '' ? 0 : this.chooseCustomerAddress,
                    is_express: this.chooseExpress,
                    goods_list: goods_list,
                    remarks: this.remarks
                }




                const {
                    data
                } = await this.$https.post(erpApi + '/customer/orders/edit?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.$router.go(-1)
            },


            resetSubmiteDo() {
                this.chooseWareHouse = ''
                this.choosePresent = ''
                this.chooseStore = ''
                this.chooseCustomerAddress = ''
                this.chooseExpress = ''
                this.choosedGoods = []

            },


            // ===============================================================
            // ---------------------------添加商品-------------------------------------
            addGoods_Of_newOrder() {

                if (this.chooseWareHouse == '') {
                    return this.$message.error('请先选择发货仓库')
                }

                this.getGoodsInfo.store_id=this.chooseWareHouse

                this.is_addGoods = true
                this.getGoodsInfoFun()
                this.getKHpresent()
                this.getGoodsType()
            },

            async getGoodsInfoFun() {
                if (this.checkedGoodsType.length > 0) {
                    this.getGoodsInfo.category_id = this.checkedGoodsType[this.checkedGoodsType.length - 1]
                } else {
                    this.getGoodsInfo.category_id = ''
                }


                let params = this.getGoodsInfo
                const res = await this.$https.post(erpApi + '/storage/goods/select?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.addGoodsTotal = res.data.total
                this.GoodsData_newOrderData = res.data.data
                this.echo()
            },

            echo() {
                this.tempData.forEach(ele => {
                    this.GoodsData_newOrderData.forEach(node => {
                        if (ele.goods_id == node.id) {
                            node.myChecked = true
                        }
                    })
                })
            },









            // 获取客户配送类型
            async getKHpresent() {
                const res = await this.$https.post(erpApi + '/present/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.presentData = res.data
            },

            // 获取商品分类
            async getGoodsType() {
                const res = await this.$https.post(erpApi + '/category/select?_ajax=1')

                if (res.status !== 200) return this.$message.error(res.statusText)
                this.GoodsTypeData = res.data
            },



            setNumComplete() {
                this.issetNum = false
                this.choosedGoods.forEach(item => {
                    if (item.name == this.currentGoodsName) {
                        item.num = this.setNum
                    }
                })
            },












            addGoodsDo() {
                this.is_addGoods = false
                this.calc()
            },



            // 计算个数
            calc() {

                // 判断折扣

                if (this.getGoodsInfo.present_id !== 0) {
                    this.discountValue = 1
                }
                this.productNum = 0
                this.orderAmount=0
                this.productType = this.tempData.length
                this.tempData.forEach(item => {
                    this.productNum += Number(item.quantity)
                })

              // 计算金额
                this.tempData.forEach(item => {
                    this.orderAmount += Number(item.sell_price) * Number(item.quantity)
                })
                
                this.orderAmount = this.orderAmount.toFixed(2)


            },



            deleteItem(row) {
                let i = this.tempData.indexOf(row)
                this.tempData.splice(i, 1)
                this.calc()
            },











            expressChange(data) {

                if (data == 0) {
                    this.isDis = true
                } else {
                    this.isDis = false
                }
            },




            //    --------------------------搜索与重置----------------------------------------------------

            resetDo() {
                this.checkedGoodsType = []
                this.getGoodsInfo.category_id = ''
                this.getGoodsInfo.keyword = ''
                this.getGoodsInfoFun()
            },
            searchWithCondition() {
                this.getGoodsInfo.page = 1
                this.getGoodsInfoFun()
            },

            handleCurrentChange(data) {
                this.getGoodsInfo.page = data
                this.getGoodsInfoFun()
            },

            addProItem(row) {
                row.myChecked = true
                let discount =this.discountValue
                if(row.is_fixed){
                    discount=1
                }
                let sellPrice= row.price * discount
                sellPrice=sellPrice.toFixed(2)

                let obj = {
                    goods_id: row.id,
                    goods_name: row.name,
                    goods_sn: row.sn,
                    original_price: row.price,
                    sell_price: sellPrice,
                    is_fixed:row.is_fixed,
                    quantity: 0,
                }
                this.tempData.push(obj)
                this.calc()
            },


            changeStoreFun() {
                this.tempData = []
                this.getGoodsInfo.page=1
                this.calc()
            },






            // 返回
            goBack() {
                this.$router.go(-1)
            },


        },
    }
</script>

<style lang="less" scoped>
    .addNewCustomer {
        padding: 10px 19%;
        position: relative;
    }

    .addNewCustomer .item {
        display: flex;
        justify-content: space-around;
        margin-bottom: 5px;

    }

    // .addNewCustomer .item:last-child {
    //     justify-content: start;
    //     margin-left: 7%;
    // }

    .itemjcstart {
        display: flex;
        justify-content: start;
        margin-left: 7%;
        // 
        font-size: 14px;
        margin-top: 20px;
    }

    .goodsList {
        margin-top: 30px;
        padding-left: 7%;
        margin-bottom: 20px;
    }



    .addNewCustomer .item span {
        width: 36%;

    }

    .addNewCustomer .title {
        font-size: 18px;
        color: #333;
        margin-left: 6%;
        font-weight: 540;
        margin-bottom: 18px;
    }

    .addNewCustomer .top {
        font-size: 20px;
        color: #333;
        margin-left: 6%;
        font-weight: 600;
        margin-bottom: 10px;
    }



    .detailAddress {
        width: 700px;
    }

    .submitDo,
    .goBack {
        background-color: #0052d9;
        border-color: #0052d9;
        color: #ffffff;
    }

    .submitDo:hover,
    .goBack:hover {

        background-color: #176eff;
        border-color: #176eff;
        color: #ffffff;
    }

    .submitDo:focus,
    .goBack:focus {

        background-color: #e7e7e7;
        border-color: #e7e7e7;
        color: #333;
    }


    .goBack {
        background-color: #e7e7e7;
        border-color: #e7e7e7;
        color: #333;
    }


    .goBack:focus {
        background-color: #ababab;
        border-color: #ababab;
        color: #333;
    }


    .goBack:hover {
        background-color: #dcdcdc;
        border-color: #dcdcdc;
        color: #333;
    }



    .goBack {
        position: absolute;
        right: 12%;
        top: 2%;
    }

    .btns {
        margin-left: 15%;
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .choosed {
        width: 90%;
    }

    .addGoodsInput {
        width: 16%;
    }

    .addGoods_of_addOrder .btn {
        margin-bottom: 12px;
    }

    .addGoods_of_addOrder .btn span {
        margin-left: 20px;
        font-weight: 540;
    }

    .addGoods_of_addOrder .btn span:nth-child(1) {
        margin-left: 0;
    }

    .searchDo {
        background-color: #0052d9;
        border-color: #0052d9;
        color: #ffffff;
        margin-left: 1.5%;

    }

    .searchDo:hover {
        background-color: #176eff;
        border-color: #176eff;
        color: #ffffff;
    }

    .searchDo:focus {
        background-color: #024cd2;
        border-color: #024cd2;
        color: #ffffff;
    }

    .reset {
        background-color: #e7e7e7;
        border-color: #e7e7e7;
        color: #333;

    }


    .reset:hover {
        background-color: #dcdcdc;
        border-color: #dcdcdc;
        color: #333;
    }


    .reset:focus {
        background-color: #ababab;
        border-color: #ababab;
        color: #333;
    }

    .summary {

        display: flex;
        justify-content: end;
        padding-right: 10%;
        font-size: 16px;
        color: #333;
        font-weight: 600;
        margin-top: 20px;
    }

    .summary span {
        margin-right: 20px;
        color: #F56C6C;
    }

    /deep/.remark_style .el-textarea__inner {
        width: 300%;
    }

    .checkedGood_item_style {
        width: 30%;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .btnStyle {
        background-color: #f5f7fa;
        padding: 10px 20px;
    }

    .btnStyle span {
        margin-right: 20px;
    }

    .goodsListStyle{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 5%;
    }
</style>