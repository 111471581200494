<template>
    <div id="app">
        <!-- 面包屑导航 -->
        <!-- <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统设置</el-breadcrumb-item>
            <el-breadcrumb-item>客户设置</el-breadcrumb-item>
            <el-breadcrumb-item>折扣设置</el-breadcrumb-item>
        </el-breadcrumb> -->


        <el-card>

            <!--按钮区 -->

            <div class="DistributionSchemeBtns">
                <el-button @click="createDiscount" class="frontBtn" icon="el-icon-plus" size="small">创建折扣</el-button>
            </div>

            <el-table :data="discountsData" border style="width: 100%" :header-cell-style="styleBindFun">
                <el-table-column prop="name" label="折扣名称" align="center">
                </el-table-column>
                <el-table-column prop="value" label="折扣力度" align="center">
                </el-table-column>
                <el-table-column prop="customers_count" label="顾客人数" align="center">
                </el-table-column>
                <el-table-column fixed="right" label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button @click="editDiscount(scope.row)" type="text" size="medium">修改</el-button>
                        <span class="splitLineClass"></span>
                        <el-button @click="deleteDiscount(scope.row)" type="text" class="deleteTextBtn" size="medium">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>



        <!-- ---------------------------------------- 对话框第一层 --------------------------------------------------- -->
        <!-- 修改折扣对话框 -->
        <el-dialog title="修改折扣" :visible.sync="is_Discount_edit" width="35%">

            <el-form :model="Form_createDiscount" :rules="DiscountRules" label-width="120px">
                <el-form-item label="信用等级名称" prop="name">
                    <el-input v-model="Form_createDiscount.name"></el-input>
                </el-form-item>
                <el-form-item label="信用限额" prop="value">
                    <el-input v-model="Form_createDiscount.value"></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size='mini' @click="is_Discount_edit = false">取 消</el-button>
                <el-button size='mini' class="frontBtn" type="primary" @click="editDiscountDo">确 定</el-button>
            </div>
        </el-dialog>


        <!-- 创建折扣对话框 -->
        <el-dialog title="创建折扣" :visible.sync="is_createDiscount" width="30%">

            <el-form :model="Form_createDiscount" :rules="DiscountRules" label-width="120px">
                <el-form-item label="折扣名称：" prop="name">
                    <el-input v-model="Form_createDiscount.name"></el-input>
                </el-form-item>
                <el-form-item label="折扣力度：" prop="value">
                    <el-input v-model="Form_createDiscount.value"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size='mini' @click="is_createDiscount = false">取 消</el-button>
                <el-button size='mini' class="frontBtn" @click="createDiscountDo">确 定</el-button>
            </div>
        </el-dialog>


    </div>
</template>

<script>
    import {
        adminApi
    } from '../../config'

    import {
    domainApi
  } from '../../config'
    export default {
        data() {
            return {
                // -----------------------获取信用等级列表数据--------------------------------------
                discountsData: [],

                // ----------------------查看配送方案详情数据--------------------------------------
                DistributionSchemeDetailData: [],
                is_DistributionScheme_detail: false,

                // ----------------------创建折扣数据--------------------------------------
                is_createDiscount: false,
                Form_createDiscount: {
                    name: '',
                    value: '',
                },
                DiscountRules: {
                    name: {
                        required: true,
                        message: '请输入折扣名称',
                        trigger: 'blur'
                    },
                    value: {
                        required: true,
                        message: '请输入折扣力度',
                        trigger: 'blur'
                    },
                },

                // ----------------------编辑信用等级数据--------------------------------------
                is_Discount_edit: false,
                current_edit_id: "",

            }
        },
        created() {
            this.getDiscounts();
        },
       
        methods: {
          



            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind = "background:#f6f8fa;fontSize:14px;color:#333333;fontWeight: bold;"
                return styleBind;
            },

            // -----------------------获取信用等级列表数据--------------------------------------
            async getDiscounts() {
                const res = await this.$https.post(adminApi + '/discounts?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.discountsData = res.data

            },


            // ----------------------创建折扣数据--------------------------------------
            createDiscount() {
                this.is_createDiscount = true

                // 清空
                for (let k in this.Form_createDiscount) {
                    this.Form_createDiscount[k] = ''
                }
            },
            async createDiscountDo() {

                let params = this.Form_createDiscount
                const {
                    data
                } = await this.$https.post(adminApi + '/discount/create?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_createDiscount = false
                this.getDiscounts();
            },

            // ----------------------编辑折扣数据--------------------------------------
            editDiscount(row) {

                this.is_Discount_edit = true
                let {
                    id,
                    name,
                    value,
                } = row
                this.Form_createDiscount.name = name
                this.Form_createDiscount.value = value
                this.current_edit_id = id
            },
            async editDiscountDo() {

                let params = this.Form_createDiscount
                params.id = this.current_edit_id
                const {
                    data
                } = await this.$https.post(adminApi + '/discount/edit?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)

                this.$message.success(data.msg);
                this.is_Discount_edit = false
                this.getDiscounts();
            },


            // ----------------------删除折扣数据--------------------------------------
            async deleteDiscount(row) {
                this.$confirm('确认删除这个折扣吗?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    .then(async () => {
                        let params = {
                            id: row.id
                        }
                        const {
                            data
                        } = await this.$https.post(adminApi + '/discount/delete?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)
                        this.getDiscounts()
                    })
                    .catch(() => {
                        this.$message.info('您取消了按钮')
                    });
            },
        },
    }
</script>

<style lang="less" scoped>
    .DistributionSchemeBtns {
        // background-color: #dfe9f5;
        padding: 6px 20px;
        margin-bottom: 20px;
    }
</style>