<template>
    <div id="app">
        <!-- 面包屑导航 -->
        <!-- <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>商品设置</el-breadcrumb-item>
        </el-breadcrumb> -->

        <el-card>
            <el-tabs v-model="activeName" class="goodsSet--Style" >
                <el-tab-pane label="设置商品分类" name="first">
                    <div class="sortSet">
                        <div>
                            <el-button class="frontBtn addSort--Style" size="small" @click="addSortFunction">创建分类
                            </el-button>
                        </div>
                        <!-- 数据表格 -->
                        <el-table :data="sortSetData" :header-cell-style="styleBindFun" max-height="auto"
                            style="width:100%" border>


                            <el-table-column prop="name" label="分类" align="center">
                            </el-table-column>

                            <el-table-column prop="full_name" label="分类名称" align="center">
                            </el-table-column>


                            <el-table-column label="拥有的产品个数" align="center">
                                <template slot-scope="scope">
                                    {{scope.row.goods_count}}
                                    <el-button @click="seeSortDetail(scope.row)" type="text" class="text_primary">详情
                                    </el-button>
                                </template>
                            </el-table-column>

                            <el-table-column fixed="right" label="操作" width="280" align="center">
                                <template slot-scope="scope">

                                    <el-button @click="editSort(scope.row)" type="text" class="text_primary">修改
                                    </el-button>

                                    <i class="splitLineClass"></i>

                                    <el-button @click="deleteSort(scope.row)" type="text" class="text_delete">删除
                                    </el-button>

                                </template>
                            </el-table-column>


                        </el-table>

                    </div>
                </el-tab-pane>
                <el-tab-pane label="设置商品" name="second">
                    <div class="GoodsSet">
                        <span>
                            商品分类：
                            <el-cascader clearable @change="searchWithCondition" size="mini" v-model="checkedCategoryIds" :options="categoryData"
                                :show-all-levels="true" 
                                :props="{value:'id',label:'name', expandTrigger: 'hover',checkStrictly: true }">
                            </el-cascader>
                        </span>

                        <span>
                            是否销售：
                            <el-select clearable @change="searchWithCondition" size="mini"  v-model="GoodsList_form.is_sell" placeholder="请选择">
                                <el-option v-for="item in whetherData" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </span>


                        <span>
                            是否可以退货：
                            <el-select clearable @change="searchWithCondition" size="mini"  v-model="GoodsList_form.is_return" placeholder="请选择">
                                <el-option v-for="item in whetherData" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </span>



                        <span>
                            是否一口价：
                            <el-select class="isFixedStyle" clearable @change="searchWithCondition" size="mini"  v-model="GoodsList_form.is_fixed" placeholder="一口价代表不区分客户折扣,指定这个价格">
                                <el-option v-for="item in whetherData" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </span>



                        <span>
                            是否设定了下架日期：
                            <el-select clearable @change="searchWithCondition" size="mini" v-model="GoodsList_form.is_end" placeholder="请选择">
                                <el-option v-for="item in whetherData" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </span>

                           <span>
                            搜索：
                            <el-input  clearable @change="searchWithCondition" v-model="GoodsList_form.keyword" class="searchStyle" size="mini" placeholder="关键词搜索 支持名称 商品货号"></el-input>
                        </span>

                       
                        <span >
                        <el-button class="el-icon-refresh reverseBtn resetBtnStyle" size="mini" @click="GoodsSetReset">重 置
                        </el-button>
                        </span>
                    </div>

                    <div class="createAndRecycleArea">
                          <el-button class="frontBtn" size="mini" icon="el-icon-circle-plus-outline"
                        @click="addGoods">创建商品
                    </el-button>


                            <el-button type="primary"  size="mini" icon="el-icon-delete"
                            @click="openRecycleGoods">回收站</el-button> 
                    </div>
                  
                    <!-- 数据表格 -->
                    <el-table :header-cell-style="styleBindFun" :data="goodsListData" max-height="auto"
                        style="width:100%" border>

                        <el-table-column prop="sn" label="商品货号" align="center" width="160">
                        </el-table-column>

                        <el-table-column label="商品名称" align="center" width="260">
                            <template slot-scope="scope">


                                <el-button @click="seeGoodsDetail(scope.row)" type="text" class="text_name">
                                    {{scope.row.name}}
                                </el-button>
                            </template>
                        </el-table-column>

                        <el-table-column prop="price" label="商品单价 (元)" align="center" width="120">
                        </el-table-column>

                        <el-table-column prop="products_count" label="种类" align="center" width="130">
                        </el-table-column>

                        <el-table-column prop="products_sum" label="数量" align="center" width="130">
                        </el-table-column>

                        <el-table-column label="所属分类" align="center" width="300">
                            <template slot-scope="scope">
                                {{ scope.row.category_full_name }}
                            </template>
                        </el-table-column>


                        <el-table-column prop="is_sell" label="可售" align="center" width="150">
                            <template slot-scope="scope">
                                <div v-if="scope.row.is_sell" class="yesIcon el-icon-check"></div>
                                <div v-else class="noIcon el-icon-close"></div>
                            </template>
                        </el-table-column>

                        <el-table-column prop="is_return" label="退货" align="center" width="150">
                            <template slot-scope="scope">
                                <div v-if="scope.row.is_return" class="yesIcon el-icon-check"></div>
                                <div v-else class="noIcon el-icon-close"></div>
                            </template>
                        </el-table-column>

                        <el-table-column prop="is_fixed" label="一口价" align="center" width="150">
                            <template slot-scope="scope">
                                <div v-if="scope.row.is_fixed" class="yesIcon el-icon-check"></div>
                                <div v-else class="noIcon el-icon-close"></div>
                            </template>
                        </el-table-column>

                        <el-table-column prop="sell_min_num" label="起订数量" align="center">
                        </el-table-column>

                        <el-table-column prop="sell_max_num" label="限购数量" align="center">
                            <template slot-scope="scope">
                                <span></span>
                                <span class="Number">{{scope.row.sell_max_num?scope.row.sell_max_num:'无限制'}}
                                </span>
                            </template>
                        </el-table-column>

                        <el-table-column prop="sell_max_num" label="最大可售" align="center" width="120">
                            <template slot-scope="scope">
                                {{scope.row.max_num}}
                            </template>
                        </el-table-column>


                        <el-table-column prop="end_time" label="下架日期" align="center" width="120">
                            <template slot-scope="scope">
                                <span v-show="scope.row.end_time">{{scope.row.end_time}}</span>
                            </template>
                        </el-table-column>







                        <el-table-column prop="update_time" label="更新时间" align="center" width="200">
                            <!-- <template slot-scope="scope">
                                {{scope.row.update_time.trim().split(" ")[0]}}
                            </template> -->
                        </el-table-column>


                        <el-table-column fixed="right" label="操作" width="280" align="center">
                            <template slot-scope="scope">


                                <el-button @click="editDetail(scope.row)" type="text" class="text_primary">修改
                                </el-button>

                                <el-button @click="moveRecycle(scope.row)" type="text" class="text_primary">移至回收站
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>

                    <!-- 分页器 -->
                    <el-pagination @size-change="handleSizeChangeGoodsSet" @current-change="handleCurrentChangeGoodsSet"
                        :current-page="GoodsList_form.page" :page-sizes="[7]" :page-size="GoodsList_form.per_page"
                        layout="total, sizes, prev, pager, next, jumper" :total="goodsListTotal" align="center">
                    </el-pagination>
                </el-tab-pane>
            </el-tabs>
        </el-card>


        <!-- -----------------------------------Dialog   对话框（一层）------------------------------------------- -->
        <!-- 添加分类对话框 -->
        <el-dialog title="添加分类" :visible.sync="is_addSortSet" width="28%">

            <el-form :model="addSortSet_Form" :rules="addSortSet_Rules" label-width="150px">

                <el-form-item label="分类名称：" prop="name">
                    <el-input v-model="addSortSet_Form.name"></el-input>
                </el-form-item>
                <el-form-item label="选择父级分类：" prop="name">
                    <el-cascader v-model="addSortSet_Form.parent_id" :options="categoryData" :show-all-levels="true"
                        :props="{value:'id',label:'name', expandTrigger: 'hover',checkStrictly: true }"></el-cascader>
                </el-form-item>
                <el-form-item label="排序：">
                    <el-input v-model="addSortSet_Form.sort_order" placeholder="默认100，范围1-200"></el-input>
                </el-form-item>
                <el-form-item label="备注：">
                    <el-input v-model="addSortSet_Form.remarks" placeholder="请输入"></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_addSortSet = false">取 消</el-button>
                <el-button class="frontBtn" size="mini" @click="addSortSetDo">确 定</el-button>
            </div>
        </el-dialog>


        <!-- 修改分类对话框 -->
        <el-dialog title="修改分类" :visible.sync="is_editSortSet" width="28%">

            <el-form :model="editSortSet_Form" :rules="addSortSet_Rules" label-width="100px"
                class="elFormEditSortSet_style">

                <el-form-item label="分类名称:" prop="name">
                    <el-input v-model="editSortSet_Form.name" size="small"></el-input>
                </el-form-item>
                <el-form-item label="选择父类:" prop="name">
                    <el-cascader size="small" v-model="parentsIds" :options="categoryData" :show-all-levels="true"
                        :props="{value:'id',label:'name', expandTrigger: 'hover',checkStrictly: true }"></el-cascader>
                </el-form-item>
                <el-form-item label="排序:">
                    <el-input size="small" v-model="editSortSet_Form.sort_order" placeholder="1-200"></el-input>
                </el-form-item>
                <el-form-item label="备注:">
                    <el-input size="small" v-model="editSortSet_Form.remarks" placeholder="请填写修改缘由"></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_editSort = false">取 消</el-button>
                <el-button class="frontBtn" size="mini" @click="editSortSetDo">确 定</el-button>
            </div>
        </el-dialog>





        <!-- 查看商品详情对话框 -->
        <el-dialog title="商品详情" :visible.sync="is_GoodsDetail" width="85%">

            <!-- 数据表格 -->
            <el-table :data="goodsDetailData" :header-cell-style="styleBindFun" max-height="auto" style="width:100%"
                border>

                <el-table-column prop="sn" label="货号" align="center">
                </el-table-column>

                <el-table-column prop="brand_name" label="品牌" align="center">
                </el-table-column>

                <el-table-column prop="name" label="商品名称" align="center" width="260">
                    <template slot-scope="scope">
                        {{scope.row.name}} <span v-show="scope.row.spec">({{scope.row.spec}})</span>
                    </template>
                </el-table-column>

                <el-table-column label="条码" align="center">
                    <template slot-scope="scope">
                        <el-tag type="info" v-show="scope.row.barcode">
                            {{ scope.row.barcode }}
                        </el-tag>
                    </template>
                </el-table-column>


                <el-table-column prop="num" label="数量" align="center">
                    <template slot-scope="scope">
                        {{scope.row.pivot.quantity}} {{scope.row.unit_name}}
                    </template>
                </el-table-column>

                <el-table-column label="产品单价（元）" align="center">
                    <template slot-scope="scope">
                        {{scope.row.pivot.price}}
                    </template>
                </el-table-column>

            </el-table>

            <div slot="footer" class="dialog-footer">
                <el-button class="frontBtn" size="mini" @click="is_GoodsDetail = false">确 定</el-button>
            </div>
        </el-dialog>



        <el-dialog title="分类详情" :visible.sync="is_sortDetail" width="80%">
            <el-table :data="sortSetDetailData" :header-cell-style="styleBindFun" max-height="auto" style="width:100%"
                border>

                <el-table-column prop="sn" label="货号" align="center">
                </el-table-column>

                <el-table-column prop="name" label="产品" align="center" width="260">
                </el-table-column>

                <el-table-column prop="price" label="价格" align="center">
                </el-table-column>

                <el-table-column prop="end_time" label="商品下架日期" align="center">
                </el-table-column>

                <el-table-column label="是否可售" align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.is_sell" class="yesIcon el-icon-check"></div>
                        <div v-else class="noIcon el-icon-close"></div>
                    </template>
                </el-table-column>
                <el-table-column label="是否可回收" align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.is_return" class="yesIcon el-icon-check"></div>
                        <div v-else class="noIcon el-icon-close"></div>
                    </template>
                </el-table-column>
                <el-table-column label="能否一口价" align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.is_fixed" class="yesIcon el-icon-check"></div>
                        <div v-else class="noIcon el-icon-close"></div>
                    </template>
                </el-table-column>

                <el-table-column prop="sell_min_num" label="最少可售量" align="center">
                </el-table-column>

                <el-table-column prop="sell_max_num" label="最大可售量" align="center">
                </el-table-column>

                <el-table-column prop="category_name" label="名称" align="center">
                </el-table-column>

                <el-table-column prop="category_full_name" label="分类全称" align="center" width="260">
                </el-table-column>


            </el-table>



            <div slot="footer" class="dialog-footer">
                <el-button size="mini" class="frontBtn" @click="is_sortDetail = false">确 定</el-button>
            </div>
        </el-dialog>


        <!-- -----------------------------------Dialog   对话框（二层）------------------------------------------- -->










        <!-- -----------------------------------Drawer 抽屉（一层）------------------------------------------- -->


        <el-drawer title="商品回收站" :visible.sync="is_openRecycleGoods" :direction="direction" size="39%">
            <el-table :header-cell-style="styleBindFun" :data="GoodsRecycleData" border style="width: 100%">
                <el-table-column prop="sn" label="货号" align="center">
                </el-table-column>
                <el-table-column prop="name" label="商品名" align="center">
                </el-table-column>
                <el-table-column label="所属分类" align="center" width="150">
                    <template slot-scope="scope">
                        <el-tag type="primary">
                            {{ scope.row.category_name }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button @click="restoreRecycleGoods(scope.row)" type="text" size="mini">
                            恢复
                        </el-button>
                        <el-button @click="deleteRecycleGoods(scope.row)" type="text" class="deleteTextBtn" size="mini">
                            删除
                        </el-button>
                    </template>
                </el-table-column>


            </el-table>
            <!-- 分页器 -->
            <el-pagination @current-change="handleCurrentChangeOpenRecycleGoods"
                :current-page="getRecycleGoodsInfo.page" :page-sizes="[10]"
                layout="total, sizes, prev, pager, next, jumper" :total="GoodsRecycleDataTotal" align="center">
            </el-pagination>
        </el-drawer>

    </div>
</template>

<script>
    import {
        adminApi
    } from '../../config'


    export default {
        data() {
            return {
                // ------------展示设置分类数据-------------------------
                sortSetData: [],
                defaultProps: {
                    children: 'children',
                    label: 'name'
                },
                activeName: 'first',
                direction: 'rtl',

                sortSetDetailData: [],
                is_sortDetail: false,

                // ------------添加设置分类数据-------------------------
                is_addSortSet: false,
                addSortSet_Form: {
                    name: '',
                    parent_id: '',
                    remarks: '',
                    sort_order: '',
                },
                addSortSet_Rules: {
                    name: {
                        required: true,
                        message: '请输入商品分类名称',
                        trigger: 'blur'
                    }
                },
                currentFatherSort: '',



                // ------------编辑分类数据-------------------------
                is_editSortSet: false,
                editSortSet_Form: {
                    id: '',
                    name: '',
                    parent_id: '',
                    remarks: '',
                    sort_order: '',
                },

                parentsIds: [],

                // ※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※
                // ------------------------ 设置商品数据 --------------------------------------------------
                GoodsList_form: {
                    page: 1,
                    per_page: 7,
                    is_sell: '',
                    is_return: '',
                    is_fixed: '',
                    is_end: '',
                    category_id: '',
                    keyword: '',
                },
                whetherData: [{
                        id: 0,
                        name: '否'
                    },
                    {
                        id: 1,
                        name: '是'
                    },
                ],
                checkedCategoryIds:[],
                goodsListData: [],
                goodsListTotal: 0,

                // ------------------查看商品单数据---------------------
                is_GoodsDetail: false,
                goodsDetailData: [],

                // ------------------搜索商品单条件---------------------

                GoodsSetInput: "",
                chooseCategory: '',

                // ------------------创建商品 数据---------------------

                // -----------------------设置商品回收站方法---------------------------------
                is_openRecycleGoods: false,
                GoodsRecycleData: [],
                GoodsRecycleDataTotal: 0,
                getRecycleGoodsInfo: {
                    page: 1,
                    per_page: 10
                },

                categoryData: [],

            }
        },
        created() {

            this.getSortSetInfo()
            this.getGoodsListInfo()
            this.getCategoryData()
            this.activeName = this.$route.query.data ? 'second' : 'first'

        },
        methods: {
            styleBindFun() {
                let styleBind = "background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                return styleBind;
            },

            


            async getCategoryData() {
                const res = await this.$https.post(adminApi + '/category/select?_ajax=1')
               
               if (res.status !== 200) return this.$message.error(res.statusText)
                this.categoryData = res.data
            },




            // ------------展示设置分类数据   方法------------------------
            async getSortSetInfo() {
                const res = await this.$https.post(adminApi + '/categorys?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.sortSetData = res.data
            },

            // -------------------分类详情
            async seeSortDetail(row) {
                this.is_sortDetail = true
                let params = {
                    id: row.id
                }
                const res = await this.$https.post(adminApi + '/category/detail?_ajax=1', params)
                 if (res.status !== 200) return this.$message.error(res.statusText)
                this.sortSetDetailData = res.data.goods_list
            },

            // -------------------设置分类数据  编辑方法----------------------------------------
            async editSort(row) {

                this.is_editSortSet = true
                this.editSortSet_Form.id = row.id
                this.editSortSet_Form.name = row.name




                // 获取sord_order
                let params = {
                    id: row.id
                }
                const res = await this.$https.post(adminApi + '/category/detail?_ajax=1', params)
               let dataTemp = res.data
                this.editSortSet_Form.sort_order = dataTemp.sort_order
                this.editSortSet_Form.remarks = dataTemp.remarks
                this.parentsIds = []
                this.recursionGetParentIds(this.categoryData, dataTemp.parent_id)


            },
            // 递归找出父类id
            recursionGetParentIds(data, id) {
                //1.循环
                data.forEach(node => {
                    //2.判断
                    if (node.id == id) {
                        //3.判断父类
                        if (node.parent_id !== 0) {
                            this.parentsIds.unshift(node.id)
                            this.recursionGetParentIds(this.categoryData, node.parent_id)
                        } else {
                            this.parentsIds.unshift(node.id)
                        }
                    } else if (node.children) {
                        this.recursionGetParentIds(node.children, id)
                    }
                })
            },

            async editSortSetDo() {
                this.editSortSet_Form.parent_id = this.parentsIds[this.parentsIds.length - 1]
                let params = this.editSortSet_Form
                const {
                    data
                } = await this.$https.post(adminApi + '/category/edit?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)

                this.$message.success(data.msg)
                this.is_editSortSet = false
                this.getSortSetInfo()
                this.getCategoryData()
            },



            deleteSort(row) {
                this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    .then(async () => {
                        let params = {
                            id: row.id
                        }
                        const res= await this.$https.post(adminApi + '/category/delete?_ajax=1', params)
                          if (res.data.error_code !== 0) this.$message.error(res.data.msg);
                        this.$message.success(data.msg);
                        this.getSortSetInfo()
                    })
                    .catch(() => {
                        this.$message.info('您取消了按钮')
                    })
            },

            addSortFunction() {
                this.is_addSortSet = true
                this.addSortSet_Form.name = ''
                this.addSortSet_Form.remarks = ''
                this.addSortSet_Form.sort_order = ''
                this.addSortSet_Form.parent_id = ''

            },

            async addSortSetDo() {
                this.addSortSet_Form.parent_id = this.addSortSet_Form.parent_id[this.addSortSet_Form.parent_id
                    .length - 1]
                let params = this.addSortSet_Form
                const {
                    data
                } = await this.$https.post(adminApi + '/category/create?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg);
                this.$message.success(data.msg);
                this.is_addSortSet = false
                this.getSortSetInfo()
            },


            // ※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※※

            // -----------------------------  设置商品   --------------------------------
            async getGoodsListInfo() {
                if(this.checkedCategoryIds.length>0){
                    this.GoodsList_form.category_id=this.checkedCategoryIds[this.checkedCategoryIds.length-1]
                }
                if(this.checkedCategoryIds.length == 0){
                     this.GoodsList_form.category_id=''
                }
                let params = this.GoodsList_form
                const res = await this.$https.post(adminApi + '/goods/index?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.goodsListData = res.data.data
                this.goodsListTotal = res.data.total
            },
            handleSizeChangeGoodsSet(data) {
                this.GoodsList_form.per_page = data
                this.getGoodsListInfo()
            },
            handleCurrentChangeGoodsSet(data) {
                this.GoodsList_form.page = data
                this.getGoodsListInfo()
            },

            async seeGoodsDetail(row) {
                this.is_GoodsDetail = true
                let params = {
                    id: row.id
                }
                const res = await this.$https.post(adminApi + '/goods/detail?_ajax=1', params)
               if (res.status !== 200) return this.$message.error(res.statusText)
                this.goodsDetailData = res.data.products
            },

            // 移至回收站方法
            async moveRecycle(row) {
                let params = {
                    id: row.id,
                }
                const res = await this.$https.post(adminApi + '/goods/destroy?_ajax=1', params)
               if (res.status !== 200) return this.$message.error(res.statusText)
                this.$message.success(res.data.msg)
                this.getGoodsListInfo()
            },

           

           

            searchWithCondition() {
                this.GoodsList_form.page=1
                this.getGoodsListInfo()
            },

            GoodsSetReset() {
                this.GoodsList_form.is_sell=''
                this.GoodsList_form.is_return=''
                this.GoodsList_form.is_fixed=''
                this.GoodsList_form.is_end=''
                this.GoodsList_form.keyword=''
                this.GoodsList_form.category_id=''
                this.checkedCategoryIds=[]
                this.getGoodsListInfo()
            },



            // 打开回收站方法
            async openRecycleGoods() {
                this.is_openRecycleGoods = true
                let params = {
                    type: 'onlytrash',
                    page: this.getRecycleGoodsInfo.page,
                    per_page: this.getRecycleGoodsInfo.per_page,
                }
                const res = await this.$https.post(adminApi + '/goods/index?_ajax=1', params)
               if (res.status !== 200) return this.$message.error(res.statusText)
                this.GoodsRecycleData = res.data.data
                this.GoodsRecycleDataTotal = res.data.total
            },
            handleCurrentChangeOpenRecycleGoods(data) {
                this.getRecycleGoodsInfo.page = data
                this.openRecycleGoods()
            },

            //  恢复商品接口
            async restoreRecycleGoods(row) {
                let params = {
                    id: row.id
                }
                const res = await this.$https.post(adminApi + '/goods/restore?_ajax=1', params)
                if (res.data.error_code !== 0) return this.$message.error(res.data.msg)
                this.$message.success(res.data.msg)
                this.openRecycleGoods()
                this.getGoodsListInfo()
            },
            deleteRecycleGoods(row) {
                this.$confirm('此操作将永久删除该商品, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    .then(async () => {
                        let params = {
                            id: row.id,
                            type: "delete"
                        }
                        const {
                            data
                        } = await this.$https.post(adminApi + '/goods/destroy?_ajax=1', params)
                        if (data.error_code !== 0) this.$message.error(data.msg);
                        this.$message.success(data.msg);
                        this.openRecycleGoods()

                    })
                    .catch(() => {
                        this.$message.info('您取消了按钮')
                    })
            },

            addGoods() {
                // this.is_addGoods = true
                this.$router.push({
                    path: '/addGoods'
                })
            },
            async addGoodsDo() {
                this.addGoods_Form.category_id = this.addGoods_Form_Category.pop()
                this.choosedAddGoodsProduct.forEach(item => {
                    let obj = {}
                    obj.product_id = item.id
                    obj.quantity = item.quantity
                    obj.price = item.price
                    this.addGoods_Form.products.push(obj)
                })

                let params = this.addGoods_Form
                const {
                    data
                } = await this.$https.post('/admin/goods/create?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.getGoodsListInfo()
                this.Empty()
                this.is_addGoods = false
            },
            Empty() {
                // 置空
                this.addGoods_Form.name = ''
                this.addGoods_Form.category_id = ''
                this.addGoods_Form.price = ''
                this.addGoods_Form.end_time = ''
                this.addGoods_Form.is_sell = ''
                this.addGoods_Form.is_return = ''
                this.addGoods_Form.products = []
                this.choosedAddGoodsProduct = []

                // for(let k in this.addGoods_Form){
                //     console.log(this.addGoods_Form[k],'k');
                //     // if(this.addGoods_Form[k]==''){
                //     //     this.addGoods_Form[k]=''
                //     // } 
                //     // if(this.addGoods_Form[k]==[]){
                //     //     this.addGoods_Form[k]=[]
                //     // }
                // }
            },





            // 去重方法
            // uniqueArr(arr) {
            //     var newArr=[]

            //     for (let index = 0; index < arr.length; index++) {
            //        if(newArr.indexOf(arr[index])===-1){
            //           newArr.push(arr[index])
            //        }else{
            //            console.log('重复了');
            //        }
            //     }
            //     return newArr;

            // },




            //阿拉伯数字转换成大写汉字
            numberParseChina(money) {
                //汉字的数字
                var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');
                //基本单位
                var cnIntRadice = new Array('', '拾', '佰', '仟');
                //对应整数部分扩展单位
                var cnIntUnits = new Array('', '万', '亿', '兆');
                //对应小数部分单位
                var cnDecUnits = new Array('角', '分', '毫', '厘');
                //整数金额时后面跟的字符
                var cnInteger = '整';
                //整型完以后的单位
                var cnIntLast = '圆';
                //最大处理的数字
                var maxNum = 999999999999999.9999;
                //金额整数部分
                var integerNum;
                //金额小数部分
                var decimalNum;
                //输出的中文金额字符串
                var chineseStr = '';
                //分离金额后用的数组，预定义
                var parts;
                if (money == '') {
                    return '';
                }
                money = parseFloat(money);
                if (money >= maxNum) {
                    //超出最大处理数字
                    return '';
                }
                if (money == 0) {
                    chineseStr = cnNums[0] + cnIntLast + cnInteger;
                    return chineseStr;
                }
                //转换为字符串
                money = money.toString();
                if (money.indexOf('.') == -1) {
                    integerNum = money;
                    decimalNum = '';
                } else {
                    parts = money.split('.');
                    integerNum = parts[0];
                    decimalNum = parts[1].substr(0, 4);
                }
                //获取整型部分转换
                if (parseInt(integerNum, 10) > 0) {
                    var zeroCount = 0;
                    var IntLen = integerNum.length;
                    for (var i = 0; i < IntLen; i++) {
                        var n = integerNum.substr(i, 1);
                        var p = IntLen - i - 1;
                        var q = p / 4;
                        var m = p % 4;
                        if (n == '0') {
                            zeroCount++;
                        } else {
                            if (zeroCount > 0) {
                                chineseStr += cnNums[0];
                            }
                            //归零
                            zeroCount = 0;
                            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
                        }
                        if (m == 0 && zeroCount < 4) {
                            chineseStr += cnIntUnits[q];
                        }
                    }
                    chineseStr += cnIntLast;
                }
                //小数部分
                if (decimalNum != '') {
                    var decLen = decimalNum.length;
                    for (var i = 0; i < decLen; i++) {
                        var n = decimalNum.substr(i, 1);
                        if (n != '0') {
                            chineseStr += cnNums[Number(n)] + cnDecUnits[i];
                        }
                    }
                }
                if (chineseStr == '') {
                    chineseStr += cnNums[0] + cnIntLast + cnInteger;
                } else if (decimalNum == '') {
                    chineseStr += cnInteger;
                }
                return chineseStr;
            },




            handleCloseAddGoodsProduct(tag) {
                this.choosedAddGoodsProduct.splice(this.choosedAddGoodsProduct.indexOf(tag), 1)
            },


            // 跳转到修改页面
            editDetail(row) {
                this.$router.push({
                    path: '/editGoods',
                    query: {
                        data: row,
                        data2: this.sortSetData
                    }
                })
            },




        },
    }
</script>

<style lang="less" scoped>
    .addSort--Style {
        margin-top: 10px;
        margin-left: 10px;
    }





    .GoodsSet {
        padding: 10px 20px 0 20px;
        background-color: #f5f7fa;
        display: flex;
        flex-wrap: wrap;
        position: relative;
    }

    .GoodsSet span {
        width: 25%;
        margin-bottom: 10px;
        color: #333;
        font-size: 14px;
        
    }

    .searchStyle{
        width: 70%;
    }

   




   




   

    div /deep/.el-table__body-wrapper::-webkit-scrollbar {
        height: 8px;
        width: 6px;
    }



    div /deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }

    .elFormEditSortSet_style {
        width: 100%;
    }

    div /deep/ .elFormEditSortSet_style .el-cascader {
        width: 100%;
    }

   div /deep/.isFixedStyle  .el-input{
        width: 150% !important;
    }

    .createAndRecycleArea{
        display: flex;
        justify-content:space-between;
        margin-top: 16px;
        padding: 0 20px;
    }


</style>