<template>
    <div id="app">
        <!-- 面包屑导航 -->
        <!-- <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>业务管理</el-breadcrumb-item>
            <el-breadcrumb-item>客户管理</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item>创建订单</el-breadcrumb-item>
        </el-breadcrumb> -->

        <el-card class="addNewCustomer">
            <div class="top">{{customer_name}}</div>
            <div class="title">创建订单
            </div>
            <el-button class="reverseBtn goBack" @click="goBack" size="small">返回</el-button>

            <div class="item">

                <span>
                    <el-form label-position="top" label-width="80px">
                        <el-form-item label="发货方式：" prop="must">
                            <el-select class="choosed" v-model="chooseExpress" clearable @change="expressChange"
                                placeholder="请选择" size="small">
                                <el-option v-for="item in expressData" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </span>

                <span>

                    <el-form label-position="top" label-width="80px">
                        <el-form-item label="扣款方式：" prop="must">
                            <el-select @change="changePresentFun" class="choosed" v-model="choosePresent" clearable
                                placeholder="请选择" size="small">
                                <el-option v-for="item in PresentData" :key="item.present_id" :label="item.present_name"
                                    :value="item.present_id">
                                    {{item.present_name}} <span
                                        v-show="item.account !== undefined">余额:{{item.account}}</span>
                                    <span v-show="item.is_depend !== undefined">
                                        依赖: <span v-if="item.is_depend" class="el-icon-check yesColor"></span> <span
                                            v-else class="el-icon-close noColor"></span>
                                    </span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </span>
            </div>

            <div class="item">
                <span>

                    <el-form label-position="top" label-width="80px">
                        <el-form-item label="进货门店：" prop="must">
                            <el-select class="choosed" v-model="chooseStore" clearable placeholder="请选择" size="small">
                                <el-option v-for="item in storeData" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </span>

                <span>
                    <el-form label-position="top" label-width="80px">
                        <el-form-item label="发货仓库：" prop="must">
                            <el-select @change="changeStoreFun" class="choosed" v-model="chooseWareHouse" clearable
                                placeholder="请选择" size="small">
                                <el-option v-for="item in WareHouseData" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                            <div class="defaultCkStyle">您的默认仓库是：{{defaultStoreName}}</div>
                        </el-form-item>
                    </el-form>
                </span>


            </div>


            <div class="item">
                <span>

                    <el-form label-position="top" label-width="80px">
                        <el-form-item label="收货地址：" prop="must">
                            <el-select class="choosed" filterable remote  v-model="chooseCustomerAddress" clearable placeholder="请选择"
                               :remote-method="remoteMethod"  size="small" :disabled="TakefromOneself">
                                <el-option v-for="item in CustomerAddressData" :key="item.id" :label="item.address"
                                    :value="item.id">
                                    {{item.region_full_name}}-
                                    {{item.address}} 
                                    <span class="contact_text"> {{item.contact.name}}  {{item.contact.mobile}} </span>
                                    <!-- <span class="contact_text"> {{item.contact.name}}  </span> -->

                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </span>

                <span>

                    <el-form label-position="top" label-width="80px">
                        <el-form-item label="备注：" prop="must">
                            <el-input type="textarea" v-model="remarks" size="small"></el-input>
                        </el-form-item>
                    </el-form>
                </span>

            </div>


            <div class="itemjcstart">
                <div class="goodsListStyle">
                    <span>
                        商品列表：
                        <el-button size="mini" type="text" class="text_primary" @click="addGoods_Of_newOrder">添加商品
                        </el-button>
                    </span>

                    <span class="discountStyle">
                        折扣：
                        <span v-if="choosePresent == 0 && choosePresent !== ''">
                            <el-tag size="small" effect="plain">{{discountName}}</el-tag>
                        </span>
                        <span v-else>无</span>

                    </span>
                </div>
            </div>

            <div class="goodsList">
                <el-table :data="tempData" style="width:100%" :header-cell-style="styleBindFun">
                    <el-table-column prop="sn" label="货号" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="名称" align="center" width="270">
                    </el-table-column>
                    <el-table-column label="原单价（元）" align="center" prop="price">
                        <template slot-scope="scope">
                            {{scope.row.original_price}}
                        </template>
                    </el-table-column>
                    <el-table-column label="进货单价（元）" align="center" prop="price">
                        <template slot-scope="scope">
                            {{scope.row.sell_price}}
                        </template>
                    </el-table-column>
                    
                    

                    <el-table-column label="数量" align="center">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.num" size="mini" @input="blurInputFun(scope.row.num)" clearable ></el-input>
                        </template>
                    </el-table-column>

                     <el-table-column label="价格计算正确" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.bPriceCorrect" class="yesIcon el-icon-check"></div>
                            <div v-else class="noIcon el-icon-close"></div>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" class="text_delete" @click="deleteNode(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <div class="summary">
                    产品共计种类：<span>{{productType}}</span>
                    产品共计个数：<span>{{productNum}}</span>
                    订单金额：<span>￥{{orderAmount}}</span>

                </div>
            </div>



            <div class="btns">
                <el-button class="frontBtn" v-loading.fullscreen.lock="fullscreenLoading" @click="submitDo" size="small">提交</el-button>
            </div>

        </el-card>




        <!-- ---------------------------------------- 对话框第一层 --------------------------------------------------- -->
        <!-- 添加商品对话框 -->
        <el-dialog title="添加商品" :visible.sync="is_addGoods" width="90%" class="addGoods_of_addOrder">
            <div class="btn">
                <span>
                    选择商品分类：
                </span>
                <el-cascader v-model="checkedGoodsType" size="small" :options="GoodsTypeData" clearable
                    @change="searchWithCondition" :show-all-levels="false"
                    :props="{ value:'id',label:'name',checkStrictly: true}"></el-cascader>


                <span>
                    请输入关键词:
                </span>
                <el-input size="small" class="addGoodsInput" placeholder="请输入内容" v-model="getGoodsInfo.keyword"
                    @change="searchWithCondition" clearable>
                </el-input>

                <el-button size="mini" class="frontBtn searchBtnStyle" @click="searchWithCondition">搜 索</el-button>
                <el-button size="mini" class="reset reverseBtn" @click="resetDo">重 置</el-button>


            </div>
            <!-- <el-checkbox v-model="checkedAll" @change="checkedAllFun">全选</el-checkbox> -->
            <el-table :data="GoodsData_newOrderData" border :header-cell-style="styleBindFun" style="width: 100%"
                ref="multiple_addGoods" @selection-change="handleSelectionChange" :row-key="getRowKeys">
                <el-table-column type="selection" width="60" align="center"
                :selectable="fnSelectable"
                 :reserve-selection="true">
                </el-table-column>
                
                <el-table-column prop="sn" label="货号" align="center" width="100">
                </el-table-column>
                <el-table-column prop="name" label="商品名称" align="center" width="260">
                    <template slot-scope="scope">
                        <el-button type="text" class="text_primary" @click="seeGoodsDetail(scope.row)">
                            {{scope.row.name}}</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="price" label="价格" align="center">
                    <template slot-scope="scope">
                        <span class="NumberNoLeft">￥{{scope.row.price}}</span> 
                    </template>
                </el-table-column>

                   <el-table-column prop="max_num" label="最大库存数" align="center" width="130">
                </el-table-column>

                <el-table-column label="商品下架日期" align="center" width="130">
                    <template slot-scope="scope">
                        <span v-show="scope.row.end_time">{{scope.row.end_time}}</span>
                    </template>
                </el-table-column>

                <el-table-column label="可售卖" align="center" width="100">
                    <template slot-scope="scope">
                        <div v-if="scope.row.is_sell" class="yesIcon el-icon-check"></div>
                        <div v-else class="noIcon el-icon-close"></div>
                    </template>
                </el-table-column>

                <el-table-column label="一口价" align="center" width="100">
                    <template slot-scope="scope">

                        <div v-if="scope.row.is_fixed" class="yesIcon el-icon-check"></div>
                        <div v-else class="noIcon el-icon-close"></div>
                    </template>
                </el-table-column>

                <el-table-column label="可回收" align="center" width="100">
                    <template slot-scope="scope">
                        <div v-if="scope.row.is_return" class="yesIcon el-icon-check"></div>
                        <div v-else class="noIcon el-icon-close"></div>
                    </template>
                </el-table-column>

                <el-table-column prop="sell_min_num" label="起订数量" align="center" width="130">
                </el-table-column>

                <el-table-column prop="sell_max_num" label="最大可售量" align="center" width="130">
                </el-table-column>

                <el-table-column  label="限购数量" align="center">
                    <template slot-scope="scope">
                        {{scope.row.max_num > scope.row.sell_max_num? scope.row.sell_max_num: scope.row.max_num }}
                    </template>
                </el-table-column>

             



                <el-table-column label="更新时间" align="center" width="130">
                    <template slot-scope="scope">
                        <span v-show="scope.row.create_time">{{scope.row.create_time.trim().split(" ")[0]}}</span>
                    </template>
                </el-table-column>

            </el-table>

            <el-pagination @current-change="handleCurrentChangeAddGoods" :current-page="getGoodsInfo.page"
                :page-sizes="[5]" layout="total, sizes, prev, pager, next, jumper" :total="addGoodsTotal"
                align="center">
            </el-pagination>




            <div slot="footer" class="dialog-footer">
                <el-button size="mini" class="frontBtn" @click="addGoodsDo">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 商品组成对话框 -->
        <el-dialog title="商品组成" :visible.sync="is_goodsDetail" width="60%" class="addGoods_of_addOrder">

            <el-table :data="productDetailData" border :header-cell-style="styleBindFun" style="width: 100%"
                ref="multiple">


                <el-table-column prop="sn" label="货号" align="center">
                </el-table-column>

                <el-table-column prop="brand_name" label="品牌" align="center">
                </el-table-column>

                <el-table-column label="产品" align="center" width="360">
                    <template slot-scope="scope">
                        {{scope.row.name}} <span v-show="scope.row.spec">({{scope.row.spec}})</span>
                    </template>
                </el-table-column>

                <el-table-column label="数量" align="center">
                    <template slot-scope="scope">
                        {{scope.row.pivot.quantity}} {{scope.row.unit_name}}
                    </template>
                </el-table-column>

                <el-table-column label="价格" align="center">
                    <template slot-scope="scope">
                        {{scope.row.pivot.price}}
                    </template>
                </el-table-column>



            </el-table>


            <div slot="footer" class="dialog-footer">
                <el-button size="mini" class="frontBtn" @click="is_goodsDetail=false">确 定</el-button>
            </div>
        </el-dialog>


        <!-- 偏好设置对话框 -->
        <el-dialog title="偏好设置" :visible.sync="is_setProfile" width="25%">

            <el-form ref="form" label-width="120px">
                <el-form-item label="选择仓库：">
                    <el-select size="mini" v-model="form_setProfile.store_id" placeholder="请选择">
                        <el-option v-for="item in WareHouseData" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

            </el-form>


            <div slot="footer" class="dialog-footer">
                <el-button size="mini" class="frontBtn" @click="setProfileDo">确 定</el-button>
            </div>
        </el-dialog>



    </div>
</template>

<script>
    import {
        erpApi
    } from '../../config'

    import {
        domainApi
    } from '../../config'




    export default {
        data() {
            return {
                customer_number: this.$route.query.data,
                customer_name: '',
                discountValue: '',
                ntestdiscountValue:this.$route.query.discountValue,
                discountName: '',
                WareHouseData: [],
                PresentData: [],

                CustomerAddressData: [],
                expressData: [{
                        id: 0,
                        name: '自取'
                    },
                    {
                        id: 1,
                        name: '包邮'
                    },
                     {
                        id: 2,
                        name: '到付'
                    },
                ],

                chooseWareHouse: '',
                choosePresent: '',
                chooseStore: '',
                chooseCustomerAddress: '',
                chooseExpress: '',

                // 搜索收货地址表单
                form_getCustomerAddress:{
                     customer_number:'',
                     keyword:''  
                },

                // --------------------添加商品---------------------------------

                TakefromOneself: false,
                is_addGoods: false,
                GoodsData_newOrderData: [],
                getGoodsInfo: {
                    category_id: '',
                    present_id: '',
                    keyword: '',
                    store_id: '',
                    page: 1,
                    per_page: 5,
                },
                addGoodsTotal: 0,
                presentData: [],
                storeData: [],

                GoodsTypeData: [],
                order_addGoods_goodstype_id: '',
                order_addGoods_keyword: '',
                choosedGoods: [],
                numState: false,
                issetNum: false,
                setNum: '',
                currentGoodsName: '',
                productNum: 0,
                productType: 0,


                rules: {
                    must: [{
                        required: true,
                        message: '请把表单填写完整',
                        trigger: 'blur'
                    }],

                },

                choosedGoodsData: [],


                remarks: "",


                chooseProductData: [],

                is_goodsDetail: false,
                productDetailData: [],

                defaultStoreId: '',


                checkedGoodsType: [],
                tempData: [],
                orderAmount: 0.00,

                // 偏好设置
                is_setProfile: false,
                form_setProfile: {
                    store_id: ''
                },
                defaultStoreName: '',

                // 全选设置
                checkedAll: false,

                regularValue:true,


                bSmallScreen:false,

                fullscreenLoading:false,

            }
        },
        created() {
            this.getWareHouse()
            this.getPresent()

            this.getShop()
            this.getCustomerAddress()
            this.getGoodsType()
            this.getProfile()
            this.fnGetCustomerDetail()
        },
         mounted () {
             window.onresize=()=>{
                let width=document.body.clientWidth
                if(width<1550){
                    this.bSmallScreen=true
                }else{
                    this.bSmallScreen=false
                }
            }  
        },
        methods: {
            async fnGetCustomerDetail(){
                let params = {
                    customer_number:this.$route.query.data
                }
                const res = await this.$https.post(erpApi + '/customer/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.customer_name=res.data.name
                this.discountValue=res.data.discount_value
                this.discountName=res.data.discount_name


            },

            async setProfileDo() {
                let params = this.form_setProfile
                const {
                    data
                } = await this.$https.post(domainApi + '/set_profile?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.getProfile()
                this.is_setProfile = false
            },

            // 获取偏好
            async getProfile() {
                const res = await this.$https.post(domainApi + '/profile?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.defaultStoreId = res.data.default_store_id
                this.defaultStoreName = res.data.default_store_name
                // 设置偏好仓库
                if (this.defaultStoreId == 0) {
                    this.$confirm('检测到您未设置偏好，现在设置', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.is_setProfile = true
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        });
                    });


                }

                this.chooseWareHouse = this.defaultStoreId
            },

            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind = "background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                return styleBind;
            },

            // 选择仓库
            async getWareHouse() {
                const res = await this.$https.post(erpApi + '/store/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.WareHouseData = res.data
            },

            // 选择配送类型
            async getPresent() {
                let params = {
                    customer_number: this.$route.query.data
                }
                const res = await this.$https.post(erpApi + '/customer/presents?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.PresentData = res.data
                let obj = {
                    present_id: 0,
                    present_name: '预存款'
                }
                this.PresentData.unshift(obj)
            },

            // 选择门店
            async getShop() {
                let params = {
                    customer_number: this.$route.query.data
                }
                const res = await this.$https.post(erpApi + '/customer/shop/index?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.storeData = res.data
            },

            // 选择客户地址
            async getCustomerAddress() {
                this.form_getCustomerAddress.customer_number=this.customer_number
                let params = this.form_getCustomerAddress
                const res = await this.$https.post(erpApi + '/customer/address/select?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.CustomerAddressData = res.data
            },

            // 远程搜索
            remoteMethod(query){
                this.form_getCustomerAddress.keyword=query

                setTimeout(()=>{
                    this.getCustomerAddress()
                },500)
            },




            // 提交
            async submitDo() {
                 
                if(this.chooseExpress == '' && this.chooseExpress !== 0 ){
                    return this.$message.error('请选择发货方式')
                }

                if(this.regularValue == false){
                    return this.$message.error('请输入正确的数字12')
                }



                let goods_list = [] 
                this.tempData.forEach(item => {
                    let {
                        id,
                        num
                    } = item
                    let obj = {}
                    obj.goods_id = id
                    obj.quantity = Number(num)
                    goods_list.push(obj)
                })
                let params = {
                    customer_number: this.$route.query.data,
                    store_id: this.chooseWareHouse,
                    present_id: this.choosePresent === '' ? 0 : this.choosePresent,
                    shop_id: this.chooseStore === '' ? 0 : this.chooseStore,
                    address_id: this.chooseCustomerAddress === '' ? 0 : this.chooseCustomerAddress,
                    is_express: this.chooseExpress,
                    goods_list: goods_list,
                    remarks: this.remarks,
                }

                this.fullscreenLoading=true
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/orders/create?_ajax=1', params)
                this.fullscreenLoading=false
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.$router.go(-1)
            },


            resetSubmiteDo() {
                this.chooseWareHouse = ''
                this.choosePresent = ''
                this.chooseStore = ''
                this.chooseCustomerAddress = ''
                this.chooseExpress = ''
                this.choosedGoods = []

            },



            // ---------------------------添加商品-------------------------------------
            searchWithCondition() {
                this.getGoodsInfo.page = 1
                this.addGoods_Of_newOrder()
            },
            async addGoods_Of_newOrder() {
               if(typeof this.discountValue !== 'number' ){
                    return this.$message.error('折扣不对,请刷新或重新进入')
               }

                if (!this.chooseWareHouse) {
                    return this.$message.error('请选择发货仓库')
                }

                if (this.choosePresent == '' && this.choosePresent !== 0) {
                    return this.$message.error('请选择扣款方式')
                }

                if (this.checkedGoodsType.length > 0) {
                    this.getGoodsInfo.category_id = this.checkedGoodsType[this.checkedGoodsType.length - 1]
                } else {
                    this.getGoodsInfo.category_id = ''
                }

                //   赋值
                this.getGoodsInfo.present_id = this.choosePresent
                this.getGoodsInfo.store_id = this.chooseWareHouse

                this.is_addGoods = true
                let params = this.getGoodsInfo
                const res = await this.$https.post(erpApi + '/storage/goods/select?_ajax=1', params)

                if (res.status !== 200) return this.$message.error(res.statusText)

                this.GoodsData_newOrderData = res.data.data
                this.addGoodsTotal = res.data.total

                

               
            },

            fnSelectable(row){
                let val =row.max_num>row.sell_max_num?row.sell_max_num:row.max_num
                if( val == 0){
                    return false
                }else{
                    return true
                }
            },

            // echo() {
            //     this.tempData.forEach(ele => {
            //         this.GoodsData_newOrderData.forEach(node => {
            //             if (ele.id == node.id) {
            //                 node.myChecked = true
            //             }
            //         })
            //     })
            // },


            deleteNode(row) {
                let i = this.tempData.indexOf(row)
                this.tempData.splice(i, 1)

                // 删除表格中的数据状态
                this.GoodsData_newOrderData.forEach(node => {
                    if (node.id == row.id) {
                        this.$nextTick(() => {
                            this.$refs.multiple_addGoods.toggleRowSelection(node)
                        });
                    }
                })

                this.calc()
            },








            handleCurrentChangeAddGoods(data) {
                this.getGoodsInfo.page = data
                this.addGoods_Of_newOrder()
            },

            // 获取客户配送类型
            // async getKHpresent() {
            //     const res = await this.$https.post(erpApi + '/present/select?_ajax=1')
            //     if (res.status !== 200) return this.$message.error(res.statusText)
            //     this.presentData = res.data
            // },

            // 获取商品分类
            async getGoodsType() {
                const res = await this.$https.post(erpApi + '/category/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.GoodsTypeData = res.data
            },




            // 添加商品方法 start------------------------------------------------------------------------------------------
            getRowKeys(row) {
                return row.id
            },
            // addProItem(row) {
            //     row.myChecked = true
            //     let discount = this.discountValue
            //     if (row.is_fixed) {
            //         discount = 1
            //     }
            //     let obj = {
            //         sn: row.sn,
            //         id: row.id,
            //         name: row.name,
            //         original_price: row.price,
            //         sell_price: row.price * discount,
            //         is_fixed: row.is_fixed,
            //         num: 0,
            //     }
            //     this.tempData.push(obj)
            //     this.calc()
            // },

            handleSelectionChange(selection) {
                this.chooseProductData = []
                selection.forEach(item => {
                    let discount = Number(this.discountValue)
                    if (item.is_fixed) {
                        discount = 1
                    }


                    let sellPrice = item.price * discount
                    sellPrice= sellPrice.toFixed(2)

                    // 折前价格对比
                    let original_price=item.price
                    let bcontrast = original_price == sellPrice ? true:false
                    let bPriceCorrect= item.is_fixed === bcontrast ? true:false

                    let obj = {
                        sn: item.sn,
                        id: item.id,
                        name: item.name,
                        original_price: item.price,
                        is_fixed: item.is_fixed,
                        num: 0,
                        sell_price: sellPrice,
                        bPriceCorrect: bPriceCorrect,
                    }
                    this.chooseProductData.push(obj)
                })
                this.calc()
            },


            addGoodsDo() {
                // 第一步
                let arr1 = [];
                let arr2 = [];

                this.tempData.forEach(item => {
                    arr2.push(item.id)
                })

                this.chooseProductData.forEach(item => {
                    arr1.push(item.id)
                })

                let data = arr1
                    .filter(x => arr2.indexOf(x) == -1)
                    .concat(arr2.filter(x => arr1.indexOf(x) == -1));

                // 第二步     
                // 判断用户是增加了产品还是减少了
                // 第一种情况
                if (arr1.length > arr2.length) {
                    for (let i = 0; i < data.length; i++) {
                        this.chooseProductData.forEach(item => {
                            if (item.id == data[i]) {
                                this.tempData.push(item)
                            }
                        })
                    }
                }
                if (arr1.length < arr2.length) {
                    // 减少
                    for (let i = 0; i < data.length; i++) {
                        this.tempData.forEach(item => {
                            if (item.id == data[i]) {
                                this.tempData.splice(this.tempData.indexOf(item), 1)
                            }
                        })
                    }
                }

                if (arr1.length == arr2.length) {

                    // 减
                    for (let i = 0; i < data.length; i++) {
                        this.tempData.forEach(item => {
                            if (item.id == data[i]) {
                                this.tempData.splice(this.tempData.indexOf(item), 1)
                            }
                        })
                    }

                    // 加
                    for (let i = 0; i < data.length; i++) {
                        this.chooseProductData.forEach(item => {
                            if (item.id == data[i]) {
                                this.tempData.push(item)
                            }
                        })
                    }

                }


                this.is_addGoods = false
                this.calc()
            },






            // 添加商品方法 end------------------------------------------------------------------------------------------








            // 计算个数
            calc() {
                // 判断折扣
              
                if (this.choosePresent !== 0) {
                    this.discountValue = 1
                }
                this.productNum = 0
                this.orderAmount = 0
                this.productType = this.tempData.length

                 if(this.regularValue){
                 
                this.tempData.forEach(item => {
                    this.productNum += Number(item.num)
                })
                // 计算金额
                this.tempData.forEach(item => {
                    this.orderAmount += Number(item.sell_price) * Number(item.num)
                })

                this.orderAmount = this.orderAmount.toFixed(2)
                    
                 }
            },



            blurInputFun(data){
                let reg = /^[0-9]*[1-9][0-9]*$/;
                this.regularValue= reg.test(data)
                if(!this.regularValue) return this.$message.error('请输入正确的数字')
                this.calc()
            },









            //    --------------------------搜索与重置----------------------------------------------------
          
            resetDo() {
                this.getGoodsInfo.category_id = ''
                this.getGoodsInfo.present_id = ''
                this.getGoodsInfo.keyword = ''
                this.getGoodsInfo.store_id = ''
                this.getGoodsInfo.category_id = ''
                this.searchWithCondition()
            },

            expressChange(data) {

                if (data === 0) {
                    this.TakefromOneself = true
                } else {
                    this.TakefromOneself = false
                }
            },

            // 查看商品详情
            async seeGoodsDetail(row) {

                this.is_goodsDetail = true
                let params = {
                    id: row.id
                }
                const res = await this.$https.post(erpApi + '/goods/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.productDetailData = res.data.products
            },



            // ------------------------------------------------------------
            changePresentFun() {
                this.tempData = []
                this.getGoodsInfo.page = 1
                this.calc()
            },
            changeStoreFun() {
                this.tempData = []
                this.getGoodsInfo.page = 1

                 this.GoodsData_newOrderData.forEach(node=>{
                     this.$refs.multiple_addGoods.toggleRowSelection(node,false)
               })  

                this.calc()
            },

           



            // 返回
            goBack() {
                this.$router.go(-1)
            },


        },
    }
</script>

<style lang="less" scoped>
    .addNewCustomer {
        position: relative;
        padding: 10px 20px;

    }

    

    .addNewCustomer .item {
        display: flex;
        justify-content: space-around;
        margin-bottom: 5px;

    }

    // .addNewCustomer .item:last-child {
    //     justify-content: start;
    //     margin-left: 7%;
    // }

    .itemjcstart {
        display: flex;
        justify-content: start;
        margin-left: 7%;
        // 
        font-size: 14px;
        margin-top: 20px;
    }

    .goodsList {
        margin-top: 30px;
        padding-left: 7%;
        margin-bottom: 20px;
    }



    .addNewCustomer .item span {
        width: 36%;

    }

    .addNewCustomer .title {
        font-size: 18px;
        color: #333;
        margin-left: 6%;
        font-weight: 540;
        margin-bottom: 18px;
    }

    .addNewCustomer .top {
        margin-left: 6%;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 18px;
    }



    .detailAddress {
        width: 700px;
    }




    .goBack {
        position: absolute;
        right: 12%;
        top: 2%;
    }

    .btns {
        margin-left: 15%;
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .choosed {
        width: 90%;
    }

    .addGoodsInput {
        width: 16%;
    }

    .addGoods_of_addOrder .btn {
        margin-bottom: 10px;
    }

    .addGoods_of_addOrder .btn span {
        margin-left: 20px;
        font-weight: 540;
    }

    .addGoods_of_addOrder .btn span:nth-child(1) {
        margin-left: 0;
    }

    .searchDo {
        background-color: #0052d9;
        border-color: #0052d9;
        color: #ffffff;
        margin-left: 1.5%;

    }

    .searchDo:hover {
        background-color: #176eff;
        border-color: #176eff;
        color: #ffffff;
    }

    .searchDo:focus {
        background-color: #024cd2;
        border-color: #024cd2;
        color: #ffffff;
    }

    .reset {
        margin-left: 20px;
    }




    .summary {

        display: flex;
        justify-content: end;
        padding-right: 10%;
        font-size: 16px;
        color: #333;
        font-weight: 600;
        margin-top: 20px;
    }

    .summary span {
        margin-right: 20px;
        color: #F56C6C;
    }

    .addGoods_of_addOrder .btn {
        background-color: #f5f7fa;
        padding: 10px 20px;
    }

    .itemjcstart div {
        width: 100%;
        position: relative;
    }

    .itemjcstart div .empty_style {
        position: absolute;
        right: 0%;
    }

    /* el-table 滚动条样式 */
    div /deep/.el-table__body-wrapper::-webkit-scrollbar {
        height: 8px;
        width: 6px;
    }



    div /deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }

    .goodsListStyle {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .discountStyle {
        margin-right: 5%;
    }

    .defaultCkStyle {
        // position:absolute;
        // top: 0;
        // right: 0%;
        text-align: center;
    }
    
 /* el-table */
div /deep/ .el-table__body .el-table__row  .el-table__cell{
         padding: 2px
 }

    .searchBtnStyle{
        margin-left: 10px;
    }
</style>