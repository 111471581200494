<template>
    <div id="app">
        <!-- 面包屑导航 -->
        <!-- <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统设置</el-breadcrumb-item>
            <el-breadcrumb-item>客户设置</el-breadcrumb-item>
            <el-breadcrumb-item>客户分组设置</el-breadcrumb-item>
        </el-breadcrumb>
 -->

        <el-card>

            <!--按钮区 -->

            <div class="DistributionSchemeBtns">
                <el-button @click="createGroups()" class="frontBtn" icon="el-icon-plus" size="small">创建客户组</el-button>
            </div>

            <el-table :data="GroupData"  style="width: 100%" height="700" :header-cell-style="styleBindFun">
                <el-table-column prop="name" label="客户分组名称" align="center">
                </el-table-column>
                <el-table-column prop="full_name" label="全称" align="center">
                </el-table-column>
                <el-table-column prop="update_time" label="更新时间" align="center">
                    <template slot-scope="scope">
                        {{scope.row.update_time.trim().split(" ")[0]}}
                    </template>
                </el-table-column>
                <el-table-column prop="customers_count" label="顾客人数" align="center">
                </el-table-column>
                <el-table-column fixed="right" label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button @click="seeDetail(scope.row)" type="text" size="medium">详情</el-button>
                        <span class="splitLineClass"></span>
                        <el-button @click="editGroups(scope.row)" type="text" size="medium">修改</el-button>
                        <span class="splitLineClass"></span>
                        <el-button @click="deleteGroups(scope.row)" type="text" class="deleteTextBtn" size="medium">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>



        <!-- ---------------------------------------- 对话框第一层 --------------------------------------------------- -->
        <!-- 修改分组对话框 -->
        <el-dialog title="修改分组" :visible.sync="is_Groups_edit" width="39%" class="sGroupsEditStyle">

            <el-form :model="Form_editGroups" :rules="editGroupsRules" label-width="150px"
                class="el_form_EditGroups_style">
                <el-form-item label="客户组名称：" prop="editName">
                    <el-input size="mini" v-model="Form_editGroups.name"></el-input>
                </el-form-item>
                <el-form-item label="选择上级客户组：" prop="editName">
                    <span v-if="nForm_eidtPId == 0">无</span>
                    <span v-else>
                         <el-cascader :options="options" v-model="groupParentIds" size="mini"
                        :props="{ value:'id',label:'name',checkStrictly: true }" clearable></el-cascader>
                    </span>
                   
                </el-form-item>
                <el-form-item label="排序：" prop="editName">
                    <el-input size="mini" clearable placeholder="默认100，范围0~200" v-model="Form_editGroups.sort_order">
                    </el-input>
                </el-form-item>
                <el-form-item label="权限分配：" prop="editName">
                    <el-tag class="tag_style" type="primary" effect="plain" :key="tag.user_id" 
                        v-for="tag in user_selected_principal_data" closable :disable-transitions="false"
                        @close="handleClose(tag)">
                        {{tag.name}}
                    </el-tag>
                    <el-button type="text" class="text_primary" @click="chooseCustomerPrincipal">设 置</el-button>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_Groups_edit = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" type="primary" @click="editGroupsDo">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 创建客户组对话框 -->
        <el-dialog title="创建客户组" :visible.sync="is_createGroups" width="30%">

            <el-form :model="Form_createGroups" :rules="GroupsRules" label-width="150px"
                class="el_form_CreateGroups_style">
                <el-form-item label="客户组名称：" prop="name">
                    <el-input size="mini" v-model="Form_createGroups.name"></el-input>
                </el-form-item>
                <el-form-item label="选择上级客户组：" prop="name">
                    <el-cascader :options="options" v-model="Form_createGroups.parent_id" size="mini"
                        :props="{ value:'id',label:'name',checkStrictly: true }" clearable></el-cascader>
                </el-form-item>
                <el-form-item label="排序：" prop="name">
                    <el-input size="mini" clearable placeholder="默认100，范围0~200" v-model="Form_createGroups.sort_order">
                    </el-input>
                </el-form-item>
                <el-form-item label="权限分配：" prop="name">
                    <el-tag class="tag_style" type="info" effect="plain" :key="tag.user_id"
                        v-for="tag in user_selected_principal_data" closable :disable-transitions="false"
                        @close="handleClose(tag)">
                        {{tag.name}}
                    </el-tag>
                    <el-button type="text" class="text_primary" @click="chooseCustomerPrincipal">设 置</el-button>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_createGroups = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" type="primary" @click="createGroupsDo">确 定</el-button>
            </div>
        </el-dialog>



        <!-- 查看客户组详情对话框 -->
        <el-dialog title="客户组详情" :visible.sync="is_detail" width="50%" class="sGroupDetailDialog">
            <div class="sGroupDetailTit">{{sGroupDetailName}}</div>
            <div class="sGroupDetailArea">
            <el-table :data="detailData" border style="width: 100%" :header-cell-style="styleBindFun">

                <el-table-column prop="name" label="当前组权限" align="center">
                </el-table-column>
                <!-- <el-table-column label="所属部门" align="center">
                    <template slot-scope="scope">
                        <el-tag type="primary">{{scope.row.department_full_name}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="position_name" label="职位" align="center">
                </el-table-column> -->
            </el-table>

             <el-table :data="detailParentData" border style="width: 100%" :header-cell-style="styleBindFun" class="sGroupParentTable">

                <el-table-column prop="name" label="父级组权限" align="center">
                </el-table-column>
            
            </el-table>

                
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button class="frontBtn" size="mini" @click="is_detail = false">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 选择客户组联系人对话框 -->
        <el-dialog title="选择客户组负责人" :visible.sync="is_CustomerPrincipal" width="36%" class="bindUserGroup">
            <div class="all">
                <div class="left  setSupSubStyle">
                    <!-- 输入框 -->
                    <!-- <el-input clearable placeholder="搜索员工" v-model="Form_setSuperior.keyword"  @input="searchWordMonitorFun"
                        class="setSuperior__search__style" @keyup.enter.native="searchStaff__setSuperior" size="small" prefix-icon="el-icon-search">
                    </el-input> -->

                    <!-- <div v-if="is_setSuperior__searchRes" class="setSuperior__search__result__style">
                        <div class="tit">成员</div>
                        <div class="content">
                            <span v-for="(list,index) in setSuperior__search__result__Data" :key="index" class="item" 
                            @click="setSuperior__selectStaffFun(list,index)"
                             :class="index==0?'active__setsuperior__style':'hover__setsuperior__style'">
                                 <i class='el-icon-s-custom'></i>
                                {{list.name}}
                            </span>
                        </div>
                    </div> -->

                    <!-- <div v-else> -->
                    <el-tree :data="user_data" accordion :props="defaultProps" class="addDAMElTree" node-key="id"
                        :default-expanded-keys="user_data_ids" @node-click="handleNodeClick">
                        <span class="bindUserGroup-tree-node" slot-scope="{ data }">
                            <span>{{data.name}}</span>
                            <i :class="data.mychecked==true?'el-icon-check':' '" class="ckeckStyle"></i>
                        </span>
                    </el-tree>
                    <!-- </div> -->



                </div>
                <div class="right setSupSubStyle">
                    <div class="tit">负责人：</div>
                    <div>
                        <ul>
                            <li v-for="(node,index) in user_selected_principal_data" :key="index" class="itemStyle">

                                <span class="departmentName">{{ node.name }}</span>
                                <i class='el-icon-close closeIcon' @click="deleteSelected(node)"></i>
                            </li>
                        </ul>
                    </div>


                </div>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="cancelSubmitDo">取 消
                </el-button>
                <el-button size="mini" class="frontBtn" @click="is_CustomerPrincipal = false">确 定
                </el-button>
            </div>
        </el-dialog>


    </div>
</template>

<script>
    import {
        adminApi
    } from '../../config'

    import {
    domainApi
  } from '../../config'
    export default {
        data() {
            return {
                // -----------------------获取分组列表数据--------------------------------------
                GroupData: [],


                // ----------------------创建客户组数据--------------------------------------
                is_createGroups: false,
                Form_createGroups: {
                    name: '',
                    parent_id: '',
                    sort_order: '',
                    user_list: []
                },
                Form_editGroups: {
                    id: '',
                    name: '',
                    parent_id: '',
                    sort_order: '',
                    user_list: []
                },
                GroupsRules: {
                    name: {
                        required: true,
                        message: '请填写完整',
                        trigger: 'blur'
                    }
                },

                editGroupsRules: {
                    editName: [
                        // { required: true, message: '请填写完整', trigger: 'blur' },

                    ],
                },

                // ----------------------编辑信用等级数据--------------------------------------
                is_Groups_edit: false,
                current_edit_id: "",


                // ---------------------查看详情数据--------------------------------------------------
                detailData: [],
                is_detail: false,

                //  ---------------------选择客户组负责人------------------------------------------
                is_CustomerPrincipal: false,
                user_data: [],
                defaultProps: {
                    children: 'children',
                    label: 'name',
                },
                user_data_ids: [],
                user_selected_principal_data: [],
                options: [],


                groupParentIds: [],
                nForm_eidtPId:'',
                detailParentData:[],
                sGroupDetailName:'',


            }
        },
        created() {
            this.getGroups();
            this.getGroups_create()
            this.getUserdata()


        },
        
        methods: {
           



            // 绑定表格头部样式方法
            styleBindFun() {
                // let styleBind = "background:#f6f8fa;fontSize:14px;color:#333333;fontWeight: bold;"
                 let styleBind = "background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                return styleBind;
            },

            // -----------------------获取客户组列表数据--------------------------------------
            async getGroups() {
                const res = await this.$https.post(adminApi + '/groups?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.GroupData = res.data

            },


            // ----------------------创建客户组数据--------------------------------------
            createGroups() {
                this.is_createGroups = true

                // 清空
                for (let k in this.Form_createGroups) {
                    this.Form_createGroups[k] = ''
                }
                this.user_selected_principal_data.forEach(node => {
                    this.recursionDeleteIcon(this.user_data, node.user_id)
                })
                this.user_selected_principal_data = []
            },
            async createGroupsDo() {

                this.Form_createGroups.parent_id = this.Form_createGroups.parent_id[this.Form_createGroups.parent_id
                    .length - 1]
                this.Form_createGroups.user_list = []
                this.user_selected_principal_data.forEach(node => {
                    this.Form_createGroups.user_list.push(node.user_id)
                })

                let params = this.Form_createGroups
                const {
                    data
                } = await this.$https.post(adminApi + '/group/create?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_createGroups = false
                this.getGroups();
            },

            // ----------------------编辑分组数据--------------------------------------
            async editGroups(row) {
                this.is_Groups_edit = true

                let params = {
                    id: row.id
                }
                const res = await this.$https.post(adminApi + '/group/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                console.log(res,'rt1');
                this.Form_editGroups.id = res.data.id
                this.Form_editGroups.name = res.data.name
                this.Form_editGroups.sort_order = res.data.sort_order
                this.nForm_eidtPId=res.data.parent_id
                this.groupParentIds = []
                this.recursionGetParentIds(this.options, res.data.parent_id)
                this.user_selected_principal_data = []
                res.data.users.forEach(node => {
                    this.recursiongetSelectedUser(this.user_data, node.id)
                    this.recursionAddIcon(this.user_data, node.id)
                })

            },

            // 获取父类客户组
            recursionGetParentIds(data, id) {
                //1.循环
                data.forEach(node => {
                    //2.判断
                    if (node.id == id) {
                        //3.判断父类
                        if (node.parent_id !== 0) {
                            this.groupParentIds.unshift(node.id)
                            this.recursionGetParentIds(this.options, node.parent_id)
                        } else {
                            this.groupParentIds.unshift(node.id)
                        }
                    } else if (node.children) {
                        this.recursionGetParentIds(node.children, id)
                    }
                })
            },





            recursiongetSelectedUser(data, id) {
                data.forEach(node => {
                    if (node.user_id) {
                        if (node.user_id == id) {
                            this.user_selected_principal_data.push(node)
                        } else if (node.children) {
                            this.recursiongetSelectedUser(node.children, id)
                        }
                    } else if (node.children) {
                        this.recursiongetSelectedUser(node.children, id)
                    }
                })
            },


            async editGroupsDo() {
                if (this.groupParentIds.length > 0) {
                    this.Form_editGroups.parent_id = this.groupParentIds[this.groupParentIds.length - 1]
                }
                this.Form_editGroups.user_list = []
                this.user_selected_principal_data.forEach(node => {
                    this.Form_editGroups.user_list.push(node.user_id)
                })

                let params = this.Form_editGroups
                const {
                    data
                } = await this.$https.post(adminApi + '/group/edit?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)

                this.$message.success(data.msg);
                this.is_Groups_edit = false
                this.getGroups();
            },


            // ----------------------删除分组数据--------------------------------------
            async deleteGroups(row) {
                this.$confirm('确认删除这个分组吗?')
                    .then(async () => {
                        let params = {
                            id: row.id
                        }
                        const {
                            data
                        } = await this.$https.post(adminApi + '/group/delete?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)
                        this.getGroups()
                    })
                    .catch(() => {
                        this.$message.info('您取消了按钮')
                    });
            },


            // ----------------------查看分组数据--------------------------------------
            async   seeDetail(row) {
                this.is_detail = true
                 let params = {
                     id:row.id
                }
                const res = await this.$https.post(adminApi + '/group/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.detailData = res.data.users
                this.sGroupDetailName=res.data.full_name
                if(row.parent_id !== 0){
                    this.getParentGroupDetail(row.parent_id)
                }
            },

           async getParentGroupDetail(id){
                let params = {
                     id
                }
                const res = await this.$https.post(adminApi + '/group/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.detailParentData = res.data.users
           },


            // ------------------选择客户组负责人---------------------------
            chooseCustomerPrincipal() {
                this.is_CustomerPrincipal = true

                // 初始化

                this.user_selected_principal_data.forEach(node => {
                    this.recursionAddIcon(this.user_data, node.user_id)
                })
            },
            async getGroups_create() {
                const res = await this.$https.post(adminApi + '/group/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.options = res.data
            },
            async getUserdata() {
                const res = await this.$https.post(adminApi + '/user/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.user_data = res.data
                this.user_data_ids = []
                this.user_data.forEach(node => {
                    this.user_data_ids.push(node.id)
                })
            },

            handleNodeClick(data) {
                if (data.user_id) {
                    data.mychecked = !data.mychecked
                    if (data.mychecked) {
                        this.user_selected_principal_data.push(data)
                    } else {
                        let i = this.user_selected_principal_data.indexOf(data)
                        this.user_selected_principal_data.splice(i, 1)
                    }
                }
            },
            deleteSelected(data) {
                let i = this.user_selected_principal_data.indexOf(data)
                this.user_selected_principal_data.splice(i, 1)
                this.recursionDeleteIcon(this.user_data, data.user_id)
            },
            // 递归
            recursionDeleteIcon(data, user_id) {
                data.forEach(node => {
                    if (node.user_id) {
                        if (node.user_id == user_id) {
                            node.mychecked = false
                        } else if (node.children) {
                            this.recursionDeleteIcon(node.children, user_id)
                        }
                    } else if (node.children) {
                        this.recursionDeleteIcon(node.children, user_id)
                    }
                })
            },

            // 点开初始化
            recursionAddIcon(data, user_id) {
                data.forEach(node => {
                    if (node.user_id) {
                        if (node.user_id == user_id) {
                            node.mychecked = true
                        } else if (node.children) {
                            this.recursionAddIcon(node.children, user_id)
                        }
                    } else if (node.children) {
                        this.recursionAddIcon(node.children, user_id)
                    }
                })
            },
            cancelSubmitDo() {
                this.user_selected_principal_data.forEach(node => {
                    this.recursionDeleteIcon(this.user_data, node.user_id)
                })
                this.user_selected_principal_data = []
                this.is_CustomerPrincipal = false

            },


            handleClose(data) {
                let i = this.user_selected_principal_data.indexOf(data)
                this.user_selected_principal_data.splice(i, 1)
                this.recursionDeleteIcon(this.user_data, data.user_id)
            },

        },
    }
</script>

<style lang="less" scoped>
    .DistributionSchemeBtns {

        // padding: 6px 20px;
        padding: 6px 0px;
        margin-bottom: 20px;
    }

    /deep/.bindUserGroup .el-dialog__body {
        height: 500px !important;
        overflow-y: hidden !important;
    }

    .all {
        display: flex;
        justify-content: start;
        width: 100%;
    }

    .all .left {
        flex: 7;
        position: relative;
        background-color: #fdfdfd;
    }

    .all .right {
        flex: 6;
        padding-left: 3%;
        background-color: #fdfdfd;
        border-left: 1px solid #e4e6e9;
        margin-left: 5%;
        padding-top: 0;
    }

    .all .right .tit {
        color: #787878;
    }

    .setSupSubStyle::-webkit-scrollbar {
        width: 6px;
    }


    .setSupSubStyle::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(221, 222, 224);
    }

    .setSupSubStyle {
        margin-top: 1%;
        height: 480px;
        overflow-y: auto;
    }

    .setSupSubStyle .tit {
        font-size: 15px;
    }

    .setSupSubStyle .tit span {
        font-size: 13px;
    }

    /deep/.addDAMElTree .el-tree-node:focus>.el-tree-node__content {
        background-color: #f9fafc !important;
    }

    .bindUserGroup-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 14px;
        padding-right: 24px;
        font-size: 14px;
        font-family: Arial, Helvetica, sans-serif;
        color: #000000;
        margin-bottom: 8px;
    }

    .ckeckStyle {
        margin-left: 6px;
    }

    .itemStyle {
        margin-bottom: 8px;
        position: relative;
    }

    .closeIcon {
        font-weight: 1000;
        color: #ababab;
        position: absolute;
        right: 6%;
    }


    .closeIcon:hover {
        cursor: pointer;
    }

    ul,
    li {
        list-style: none;
        text-decoration: none;
        padding: 0;
    }

    .tag_style {
        margin-right: 10px;
    }

    .el_form_EditGroups_style,
    .el_form_CreateGroups_style {
        width: 100%;
    }

    div /deep/ .el_form_EditGroups_style .el-cascader,
    div /deep/ .el_form_CreateGroups_style .el-cascader {
        width: 100%;
    }

//       /* el-table 滚动条样式 */
// div /deep/.el-table__body-wrapper::-webkit-scrollbar{
//     height: 8px;
//     width: 6px;
//  }

 

// div /deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
//   border-radius: 5px;
//   -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
//   background: rgba(0, 0, 0, 0.2);
// }

   div /deep/ .sGroupsEditStyle .el-dialog__body{
        padding-right: 60px;
    }

    .sGroupDetailArea{
        width: 100%;
        display: flex;
        padding: 0 20px;
        box-sizing: border-box;
    }

    .sGroupParentTable{
        margin-left: 50px;
    }

    div /deep/.sGroupDetailDialog .el-dialog__body{
        padding-top: 0;
        display: flex;
        flex-direction: column;
    }

    .sGroupDetailTit{
        margin-top: 20px;
        font-size: 14px;
        color: #333;
        font-weight: bold;

    }

</style>