<template>
    <div id="app">

        <!-- 布局 -->
        <div class="purchaseLayout">

            <!-- 面包屑导航 -->
            <!-- <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>采购入库</el-breadcrumb-item>
                <el-breadcrumb-item>入库列表</el-breadcrumb-item>
            </el-breadcrumb> -->

            <el-card>
                <!-- 工具栏部分 -->
                <div class="warehousing">
                    <el-date-picker class="datePicker" v-model="choose_time" type="daterange" range-separator="至"
                        size="small" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                    </el-date-picker>

                    <span >
                        请选择仓库：
                        <el-select v-model="getReceivesInfo.store_id" clearable placeholder="请选择" size="small ">
                            <el-option v-for="item in WarehouseData" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </span>
                    <el-button size="mini" class="frontBtn" icon="el-icon-search" @click="RKsearch">搜 索</el-button>
                    <el-button size="mini" class="reverseBtn" icon="el-icon-refresh" @click="RKreset">重 置</el-button>
                    <el-button size="small" class="reverseBtn BackBtn-Style" @click="goBack">返 回</el-button>

                </div>

                <div class="Fun--Style">
                       <!-- 添加按钮 -->
                    <el-button class="frontBtn directRKBtn--Style" size="small" icon="el-icon-circle-plus"  @click="directRK">直接入库
                    </el-button>
                </div>

                <!-- 数据表格 -->
                <el-table :data="ReceivesData" max-height="600" style="width:100%" :header-cell-style="styleBindFun"
                    border>

                    <el-table-column  label="入库单号" align="center" >
                         <template slot-scope="scope">
                            <el-button @click="lookDetail(scope.row)" type="text" class="text_primary" size="big">{{scope.row.order_num}}</el-button>
                        </template>
                    </el-table-column>

                    <el-table-column prop="user_name" label="操作人" align="center" >
                        <template slot-scope="scope">
                            {{scope.row.user_name}}
                        </template>
                    </el-table-column>

                    <el-table-column prop="store_name" label="货仓" align="center" >
                    </el-table-column>

                    <el-table-column prop="products_count_format" label="入库种类" align="center" >
                    </el-table-column>

                    <el-table-column prop="products_quantity_format" label="入库个数" align="center" >
                        <template slot-scope="scope">
                           <span class="NumberNoLeft">{{scope.row.products_quantity_format}}</span> 
                        </template>
                    </el-table-column>

                      

            
                   
                </el-table>

                <!-- 分页器 -->
                <el-pagination @current-change="handleCurrentChange" :current-page="getReceivesInfo.current_page"
                    layout="total,  prev, pager, next, jumper" :total="ReceivesTotal" align="center">
                </el-pagination>
            </el-card>
        </div>


        <!-- 入库单详情对话框 -->
        <el-dialog title="入库单详情：" :visible.sync="is_receiveDetail" width="75%" class="sReceiveDetail">

            <div class="sReceiveDetailTit">
                <span>
                    编号: {{nCurrentOrderNum}}
                </span>

            </div>

            <el-table tooltip-effect="dark" style="width: 100%" :data="receiveDetail" :header-cell-style="styleBindFun"
                align="center">
                <el-table-column label="入库类型" align="center">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.plans">采购入库</el-tag>
                        <el-tag v-else>直接入库</el-tag>

                    </template>
                </el-table-column>
                <el-table-column prop="brand_name" label="品牌" align="center">
                </el-table-column>
                <el-table-column prop="product_sn" label="货号" align="center">
                </el-table-column>
                <el-table-column label="产品名称" align="center" width="300">
                    <template slot-scope="scope">
                        {{scope.row.product_name}} <span v-show="scope.row.product_spec">({{scope.row.product_spec}})</span>
                    </template>
                </el-table-column>
                <el-table-column label="入库前个数" align="center">
                    <template slot-scope="scope">
                        {{scope.row.before_quantity}} {{scope.row.unit_name}}
                    </template>
                </el-table-column>
                <el-table-column label="入库后个数" align="center">
                    <template slot-scope="scope">
                        {{scope.row.after_quantity}} {{scope.row.unit_name}}
                    </template>
                </el-table-column>
                <el-table-column label="入库个数" align="center">
                    <template slot-scope="scope">
                        <span class="Number">{{scope.row.quantity}} </span>
                    </template>
                </el-table-column>

                  <el-table-column label="批次" align="center" width="320">
                    <template slot-scope="scope">
                        {{scope.row.batch_name}} <span class="validityDateStyle">限用日期：{{scope.row.batch_expiry_date}}</span>
                    </template>
                </el-table-column>

            </el-table>

            <div class="remarks">
              <span> 备注:</span>  {{sCurrentRemarks}}
            </div>



            <div slot="footer" class="dialog-footer">
                <el-button  class="frontBtn" size="mini" @click="is_receiveDetail = false">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
      import {
        erpApi
    } from '../../config'
    export default {
        data() {
            return {

                ReceivesData: [],
                getReceivesInfo: {
                    start_date: '',
                    end_date: '',
                    store_id: '',
                    page: 1,
                    per_page: 8,
                },
                ReceivesTotal: 0,
                choose_time: [],
                WarehouseData: [],

                // 详情
                is_receiveDetail: false,
                receiveDetail: [],

                nCurrentOrderNum:'',
                sCurrentRemarks:'',



            }
        },
        created() {
            this.getReceives()
            this.getWarehouseInfo()

        },
        methods: {
            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind = "background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                return styleBind;
            },


            // 获取入库单列表
            async getReceives() {
                if (this.getReceivesInfo.start_date == '') {
                    delete this.getReceivesInfo.start_date
                    delete this.getReceivesInfo.end_date
                }
                if (this.getReceivesInfo.store_id == '') {
                    delete this.getReceivesInfo.store_id
                }
                let params = this.getReceivesInfo
                const res = await this.$https.post(erpApi+'/receives?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.ReceivesData = res.data.data
                console.log(res, 'res');
                this.ReceivesTotal = res.data.total
            },
            handleCurrentChange(data) {
                this.getReceivesInfo.page = data
                this.getReceives()
            },

            // 获取仓库
            async getWarehouseInfo() {
                const res = await this.$https.post(erpApi+'/store/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.WarehouseData = res.data
            },


            // 直接入库
            async directRK() {
                // this.is_directRK = true
                this.$router.push({
                    path: '/directRk'
                })
            },

            // 搜索与充值
            RKsearch() {
                if (this.choose_time !== null) {
                    this.getReceivesInfo.start_date = this.choose_time[0]
                    this.getReceivesInfo.end_date = this.choose_time[1]
                } else {
                    this.getReceivesInfo.start_date = ''
                }
                this.getReceives()

            },
            RKreset() {
                this.getReceivesInfo.start_date = ''
                this.getReceivesInfo.end_date = ''
                this.getReceivesInfo.store_id = ''
                this.choose_time = []
            },

            // 查看详情
            async lookDetail(row) {
                this.is_receiveDetail = true
                let params = {
                    order_num: row.order_num
                }
                const res = await this.$https.post(erpApi+'/receive/detail?_ajax=1', params)
                console.log(res,'res123');
               if (res.status !== 200) return this.$message.error(res.statusText)
                this.receiveDetail = res.data.stocks
                this.nCurrentOrderNum=res.data.order_num
                this.sCurrentRemarks=res.data.remarks
                console.log(this.receiveDetail,'receiveDetail');
            },

            // 返回
            goBack() {
                this.$router.push({
                    path: '/purchase'
                })
            },







        },
    }
</script>

<style lang="less" scoped>
    .warehousing {
        padding: 10px 0;
        display: flex;
        align-items: center;
        background-color: #f5f7fa;
        padding: 10px 20px;
    }

    .warehousing span{
        color: #333;
        font-size: 14px;
        margin: 0 30px;
    }


    .directRKBtn--Style{
        margin-top: 20px;
        margin-left: 10px;
    }

    .BackBtn-Style{
        position: absolute;
        right: 6%;
    }




    .back {
        position: absolute;
        right: 4%;
    }

    .el-card {
        height: 87vh;
    }

      .validityDateStyle {
        font-size: 13px;
        margin-left: 10px;
        // color:#0052d9;
    }


           /* el-table 滚动条样式 */
/deep/.el-table__body-wrapper::-webkit-scrollbar{
    height: 8px;
    width: 6px;
 }

 

/deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}


    div /deep/.sReceiveDetail .el-dialog__body{
        display: flex;
        flex-direction: column;
    }


     .sReceiveDetail .remarks{
        margin-top: 10px;

     }

    .sReceiveDetail .remarks span{
        font-size: 16px;
        color: #333;
        font-weight:bolder;
        margin-right: 5px;
    }
</style>