<template>
    <div id="app">
        <!-- 布局 -->
        <div class="unitSet">
            <div class="unitSet_left">


                <!-- 卡片视图区域 -->
                <el-card class="card_left">
                    <div>
                        <el-button icon="el-icon-circle-plus" size="small" class="frontBtn" @click="addWarehouseBorder">
                            添加仓库</el-button>
                    </div>

                    <el-table :data="warehouseTableData" max-height="auto" style="width:100%" 
                        :header-cell-style="styleBindFun">
                        <el-table-column prop="name" label="仓库" align="center" >
                        </el-table-column>

                        <el-table-column prop="region_full_name" label="区域" align="center" >
                        </el-table-column>

                        <el-table-column prop="address" label="地址" align="center" width="390">
                        </el-table-column>

                        <el-table-column prop="phone" label="联系电话" align="center" >
                        </el-table-column>

                         <el-table-column prop="" label="负责人数" align="center" >
                              <template slot-scope="scope">
                                {{scope.row.users_count}}
                                <el-button @click="detail(scope.row)" type="text" class="text_primary" size="big">详情</el-button>
                            </template>
                        </el-table-column>

                        <el-table-column  label="创建时间" align="center" width="200">
                            <template slot-scope="scope">
                                <span v-show="scope.row.create_time">
                                 {{scope.row.create_time.trim().split(" ")[0]}}
                                </span>
                            </template>
                        </el-table-column>

                           <el-table-column prop="is_use" label="是否启用" align="center" >
                            <template slot-scope="scope">
                                <el-switch @change="isUserChange(scope.row)" v-model="scope.row.is_use">
                                </el-switch>
                            </template>
                        </el-table-column>


                        <el-table-column fixed="right" label="操作" width="220" align="center">
                            <template slot-scope="scope">
                                <el-button @click="editWarehouse(scope.row)" type="text" class="text_primary" size="big">编辑</el-button>
                                <el-button @click="deleteWarehouse(scope.row)" type="text" class="deleteTextBtn" size="big">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </div>



        </div>

        <el-dialog title="增加仓库" :visible.sync="dialogFormVisibleOfadd" width="50%">
            <el-form class="addWareHosue--style">
                <div class="first">
                    <span>
                        <el-form-item label="请输入要增加的仓库名称：" :label-width="formLabelWidth">
                            <el-input size="mini" placeholder="请填写" v-model="addWarehouseForm.name" autocomplete="off">
                            </el-input>
                        </el-form-item>
                    </span>
                    <span>
                        <el-form-item label="请输入要增加的仓库联系电话：" :label-width="formLabelWidth">
                            <el-input size="mini" placeholder="请填写" v-model="addWarehouseForm.phone" autocomplete="off">
                            </el-input>
                        </el-form-item>
                    </span>
                </div>

                <div class="second">
                    <span>
                        <el-form-item label="请选择区域：" :label-width="formLabelWidth">
                            <el-cascader v-model="region_currentIds" size="mini" :options="regionData"
                                :props="{ value:'id',label:'name' ,expandTrigger: 'hover',checkStrictly: true }"></el-cascader>
                        </el-form-item>
                    </span>
                    <span>

                        <el-form-item label="请输入要增加的品牌仓库地址：" :label-width="formLabelWidth">
                            <el-input size="mini" placeholder="请填写" v-model="addWarehouseForm.address"
                                autocomplete="off">
                            </el-input>
                        </el-form-item>
                    </span>
                </div>

                <div class="thirdly">
                    <span>

                        <el-form-item label="排序：" :label-width="formLabelWidth">
                            <el-input size="mini" v-model="addWarehouseForm.sort_order" autocomplete="off"
                                placeholder="排序依据 默认100 范围0~200"></el-input>
                        </el-form-item>
                    </span>
                    <span>
                        <el-form-item label="是否启用：" :label-width="formLabelWidth">
                              <el-radio-group v-model="addWarehouseForm.is_use">
    <el-radio :label="0">否</el-radio>
    <el-radio :label="1">是</el-radio>
  </el-radio-group>
                        </el-form-item>

                    </span>
                </div>

                <div class="fourthly">
                    <el-form-item label="负责人：" :label-width="formLabelWidth">
                        <el-tag class="leaderItem--Style" @close="closeleaderItem(list)" closable
                            v-for="(list,index) in user_selected_warehouse_data" :key="index">{{list.name}}</el-tag>
                        <el-button @click="chooseLeader()" class="text_primary" type="text" size="small">选择</el-button>
                    </el-form-item>
                </div>


                <div class="fifth">

                    <el-form-item label="备注：" :label-width="formLabelWidth">
                        <el-input size="mini" placeholder="备注信息" v-model="addWarehouseForm.remarks" autocomplete="off">
                        </el-input>
                    </el-form-item>

                </div>




            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogFormVisibleOfadd = false">取 消</el-button>
                <el-button size="mini" type="primary" class="frontBtn" @click="addWarehouseDo">确 定</el-button>
            </div>
        </el-dialog>


         <el-dialog title="修改仓库" :visible.sync="dialogFormVisibleOfedit" width="50%">
            <el-form class="addWareHosue--style">
                <div class="first">
                    <span>
                        <el-form-item label="请输入要增加的仓库名称：" :label-width="formLabelWidth">
                            <el-input size="mini" placeholder="请填写" v-model="editWarehouseForm.name" autocomplete="off">
                            </el-input>
                        </el-form-item>
                    </span>
                    <span>
                        <el-form-item label="请输入要增加的仓库联系电话：" :label-width="formLabelWidth">
                            <el-input size="mini" placeholder="请填写" v-model="editWarehouseForm.phone" autocomplete="off">
                            </el-input>
                        </el-form-item>
                    </span>
                </div>

                <div class="second">
                    <span>
                        <el-form-item label="请选择区域：" :label-width="formLabelWidth">
                            <el-cascader v-model="region_currentIds" size="mini" :options="regionData"
                                :props="{ value:'id',label:'name' ,expandTrigger: 'hover',checkStrictly: true }"></el-cascader>
                        </el-form-item>
                    </span>
                    <span>

                        <el-form-item label="请输入要增加的品牌仓库地址：" :label-width="formLabelWidth">
                            <el-input size="mini" placeholder="请填写" v-model="editWarehouseForm.address"
                                autocomplete="off">
                            </el-input>
                        </el-form-item>
                    </span>
                </div>

                <div class="thirdly">
                    <span>

                        <el-form-item label="排序：" :label-width="formLabelWidth">
                            <el-input size="mini" v-model="editWarehouseForm.sort_order" autocomplete="off"
                                placeholder="排序依据 默认100 范围0~200"></el-input>
                        </el-form-item>
                    </span>
                    <span>
                        <el-form-item label="是否启用：" :label-width="formLabelWidth">
                              <el-radio-group v-model="editWarehouseForm.is_use">
    <el-radio :label="0">否</el-radio>
    <el-radio :label="1">是</el-radio>
  </el-radio-group>
                        </el-form-item>

                    </span>
                </div>

                <div class="fourthly">
                    <el-form-item label="负责人：" :label-width="formLabelWidth">
                        <el-tag class="leaderItem--Style" @close="closeleaderItem(list)" closable
                            v-for="(list,index) in user_selected_warehouse_data" :key="index">{{list.name}}</el-tag>
                        <el-button @click="chooseLeader()" class="text_primary" type="text" size="small">选择</el-button>
                    </el-form-item>
                </div>


                <div class="fifth">

                    <el-form-item label="备注：" :label-width="formLabelWidth">
                        <el-input size="mini" placeholder="备注信息" v-model="editWarehouseForm.remarks" autocomplete="off">
                        </el-input>
                    </el-form-item>

                </div>




            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogFormVisibleOfedit = false">取 消</el-button>
                <el-button size="mini" type="primary" class="frontBtn" @click="editWarehouseDo">确 定</el-button>
            </div>
        </el-dialog>


   

        <!-- 选择仓库负责人对话框 -->
        <el-dialog title="选择仓库负责人" :visible.sync="is_setWarehouseLeader" width="36%" class="bindUserGroup">
            <div class="all">
                <div class="left  setSupSubStyle addWareLeaderTree">
                    <!-- 输入框 -->
                    <!-- <el-input clearable placeholder="搜索员工" v-model="Form_setSuperior.keyword"  @input="searchWordMonitorFun"
                        class="setSuperior__search__style" @keyup.enter.native="searchStaff__setSuperior" size="small" prefix-icon="el-icon-search">
                    </el-input> -->

                    <!-- <div v-if="is_setSuperior__searchRes" class="setSuperior__search__result__style">
                        <div class="tit">成员</div>
                        <div class="content">
                            <span v-for="(list,index) in setSuperior__search__result__Data" :key="index" class="item" 
                            @click="setSuperior__selectStaffFun(list,index)"
                             :class="index==0?'active__setsuperior__style':'hover__setsuperior__style'">
                                 <i class='el-icon-s-custom'></i>
                                {{list.name}}
                            </span>
                        </div>
                    </div> -->

                    <!-- <div v-else> -->
                    <el-tree :data="user_data" accordion :props="defaultProps" node-key="id"
                        :default-expanded-keys="user_data_ids" @node-click="handleNodeClick_Warehouse">
                        <span class="bindUserGroup-tree-node" slot-scope="{ data }">
                            <span>{{data.name}}</span>
                            <i :class="data.mychecked?'el-icon-check':' '" class="ckeckStyle"></i>
                        </span>
                    </el-tree>
                    <!-- </div> -->



                </div>
                <div class="right setSupSubStyle">
                    <div class="tit">负责人：</div>
                    <div>
                        <ul>
                            <li v-for="(node,index) in user_selected_warehouse_data" :key="index" class="itemStyle">

                                <span class="departmentName">{{ node.name }}</span>
                                <i class='el-icon-close closeIcon' @click="deleteSelectedWarehouse(node)"></i>
                            </li>
                        </ul>
                    </div>


                </div>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" class="frontBtn" @click="is_setWarehouseLeader = false">确 定
                </el-button>

            </div>
        </el-dialog>


         <el-dialog title="仓库详情" :visible.sync="dialogFormVisible" width="39%">
             <el-table :data="detailData.users" max-height="auto" style="width:100%" 
                        :header-cell-style="styleBindFun">
                        <el-table-column prop="name" label="负责人" align="center" >
                        </el-table-column>

                          <el-table-column prop="department_full_name" label="部门" align="center">
                        </el-table-column>

                          <el-table-column prop="position_name" label="职位" align="center" >
                        </el-table-column>  
                    </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" class="frontBtn" @click="dialogFormVisible = false">确 定</el-button>
            </div>
        </el-dialog>



    </div>
</template>

<script>
    import {
        adminApi
    } from '../../config'

    export default {
        data() {
            return {

                warehouseTableData: [], //仓库数据
                unitadd: '',
                currentEditBrand: '', //当前要编辑的品牌
                currentEditId: '', //当前要编辑的品牌的id
                currentDeleteWarehouse: '', //当前要删除的品牌
                currentDeleteId: '', //当前要删除的品牌Id
                dialogFormVisible:false,
                dialogFormVisibleOfadd: false, //控制添加品牌弹出框
                dialogFormVisibleOfedit: false, //控制编辑品牌弹出框
                dialogFormVisibleOfdelete: false, //控制删除品牌弹出框
                detailData:[],
                addWarehouseForm: {
                    name: '',
                    region_id: '',
                    address: '',
                    phone: '',
                    is_use: 0,
                    remarks: '',
                    sort_order: '',
                    user_list: [],
                }, //添加框的输入值
                editWarehouseForm: {
                    id:'',
                    name: '',
                    region_id: '',
                    address: '',
                    phone: '',
                    is_use: 0,
                    remarks: '',
                    sort_order: 100,
                    user_list: [],

                }, //编辑框的输入值
                formLabelWidth: '230px', //表单宽度

                // 区域选择
                regionData: [],
                region_currentIds: [],
                findRegionDataids:[],

                // 负责人选择
                is_setWarehouseLeader: false,
                user_data: [],
                defaultProps: {
                    children: 'children',
                    label: 'name',
                },
                user_data_ids: [],
                user_selected_warehouse_data: [],

            }
        },
        created() {
            this.getWarehouse();
            this.getRegion()
            this.getUserData()

        },
        methods: {
            styleBindFun() {
                // let  styleBind="background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                let styleBind = "background:#f6f8fa;fontSize:14px;color:#333333;fontWeight: bold;"
                return styleBind;
            },

           async detail(row){
               this. dialogFormVisible=true
               let params={
                   id:row.id
               }
                const res = await this.$https.post(adminApi + '/store/detail?_ajax=1',params)
               if(res.status !==200) return this.$message.error(res.statusText)
                this.detailData=res.data
            },



            // 获取区域数据
            async getRegion() {
                const res = await this.$https.get(adminApi + '/region/select?_ajax=1')
              
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.regionData = res.data
            },

            // 获取正常使用仓库数据
            async getWarehouse() {
                // 发送请求获取单位属性数据
                const res = await this.$https.post(adminApi + '/stores?_ajax=1')
                if (res.status !==200) {
                    return this.$message.error(res.statusText)
                }
                this.warehouseTableData = res.data
            },
           
            // //弹出增加框
            addWarehouseBorder() {
                this.dialogFormVisibleOfadd = true
                // 重置 
                this.addWarehouseForm.name=''
                this.addWarehouseForm.phone=''
                this.region_currentIds=[]
                this.addWarehouseForm.address=''
                this.addWarehouseForm.sort_order=''
                this.addWarehouseForm.is_use=''
                this.addWarehouseForm.remarks=''
                this.user_selected_warehouse_data=[]
            },

            // 增加
            async addWarehouseDo() {
                // 先拿区域最后一个id
                this. addWarehouseForm.region_id= this.region_currentIds[this.region_currentIds.length-1]
                // 把选中的数组加到表单里
                this.addWarehouseForm.user_list=[]
                this.user_selected_warehouse_data.forEach(node=>{
                    this.addWarehouseForm.user_list.push(node.user_id)
                })

                let params=this.addWarehouseForm
                  const res= await this.$https.post(adminApi + '/store/create?_ajax=1', params)
                if(res.data.error_code !==0 )  return this.$message.error( res.data.msg)
                this.$message.success( res.data.msg)
                this.dialogFormVisibleOfadd=false
                this.getWarehouse()
            },

           
           

            // 启动状态切换
            async isUserChange(info) {

                // 参数
                let data = {
                    id: info.id
                }
                // 3. 点击确定发送修改数据
                const res = await this.$https.post(adminApi + '/store/set_status?_ajax=1', data)

                if (res.data.error_code !== 0) {
                    return this.$message.error(res.data.msg)

                }
                this.$message.success(res.data.msg)
                // 重新调用
                this.getWarehouse();
            },

            // 负责人选择
            chooseLeader() {
                this.is_setWarehouseLeader = true
                this.getUserData()
                // 初始化渲染
                this.$nextTick(()=>{
                      this.user_selected_warehouse_data.forEach(node=>{
                    this.recursionInit(this.user_data,node.user_id)
                })
                });
              

            },
            recursionInit(data,id){
               
                data.forEach(node=>{
                     if(node.user_id){
                        if(node.user_id == id){
                            node['mychecked']=true
                        }else if(node.children){
                            this.recursionInit(node.children,id)
                        }
                     }else if(node.children){
                     this.recursionInit(node.children,id)
                      }
                })
               
            },
            // 获取负责人信息
            async getUserData() {
                const res = await this.$https.post(adminApi + '/user/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.msg)
                this.user_data = res.data
                // 默认展开
                this.user_data.forEach(element => {
                    this.user_data_ids.push(element.id)
                });

            },

           

            handleNodeClick_Warehouse(data) {
               
                // 判断添加还是减少
                if (data.user_id) {
                    let i = this.user_selected_warehouse_data.indexOf(data)
                    if (i < 0) {
                        // 添加
                        this.user_selected_warehouse_data.push(data)
                        this.recursionAddIcon(this.user_data,data.user_id)
                    } else {
                        // 减少
                        this.user_selected_warehouse_data.splice(i, 1)
                        this.recursionRemoveIcon(this.user_data,data.user_id)
                    }
                }
            },
            recursionAddIcon(data, user_id) {
                data.forEach(node => {
                    if (node.user_id) {
                        if (node.user_id == user_id) {
                            node['mychecked'] = true
                        } else if (node.children) {
                            this.recursionAddIcon(node.children, user_id)
                        }
                    } else if (node.children) {
                        this.recursionAddIcon(node.children, user_id)
                    }
                })
            },
              recursionRemoveIcon(data, user_id) {
                data.forEach(node => {
                    if (node.user_id) {
                        if (node.user_id == user_id) {
                            node['mychecked'] = false
                        } else if (node.children) {
                            this.recursionRemoveIcon(node.children, user_id)
                        }
                    } else if (node.children) {
                        this.recursionRemoveIcon(node.children, user_id)
                    }
                })
            },




            deleteSelectedWarehouse(data) {
                let i = this.user_selected_warehouse_data.indexOf(data)
                this.user_selected_warehouse_data.splice(i, 1)
                 this.recursionRemoveIcon(this.user_data,data.user_id)
            },

            closeleaderItem(data) {
                this.deleteSelectedWarehouse(data)
            },

            deleteWarehouse(row){
                this.$confirm('此操作将永久删除该仓库, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    .then(async () => {
                        let params = {
                            id: row.id
                        }
                        const res= await this.$https.post(adminApi + '/store/delete?_ajax=1', params)
                        if (res.data.error_code !== 0) return this.$message.error(res.data.msg)

                        this.$message.success(res.data.msg)                        
                        this.getWarehouse()

                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: '已取消删除'
                        })
                    })
            },

             // 编辑
           async editWarehouse(row) {
                this.dialogFormVisibleOfedit=true
            
                let params={
                    id:row.id
                }
                const res = await this.$https.post(adminApi + '/store/detail?_ajax=1',params)
                if (res.status !== 200) return this.$message.error(res.msg)
                this.editWarehouseForm.id=row.id
                let array=res.data
                this.editWarehouseForm.name=array.name
                this.editWarehouseForm.address=array.address
                this.editWarehouseForm.phone=array.phone
                this.editWarehouseForm.is_use=array.is_use?1:0
                this.editWarehouseForm.remarks=array.remarks
                this.editWarehouseForm.sort_order=array.sort_order
                this.user_selected_warehouse_data=[]
                array.users.forEach(node=>{
                    this.recursionSelectedUsers(this.user_data,node.id)
                })
                this.findRegionDataids=[]
                this.recursionRegionIds(this.regionData ,array.region_id)
                this.findRegionDataids.push(array.region_id)
                this.region_currentIds=this.findRegionDataids
            },

            // 递归找出父级元素id
            recursionRegionIds(data,id){
                data.forEach(node=>{
                    if(node.id==id){
                        if(node.parent_id!==0){
                            this.findRegionDataids.push(node.parent_id)
                            this.recursionRegionIds(data,node.parent_id)
                        }
                    }else if(node.children){
                        this.recursionRegionIds(node.children,id)
                    }
                })
            },

            // 递归
            recursionSelectedUsers(data,id){
                data.forEach(node=>{
                      if(node.user_id){
                          if(node.user_id==id){
                              this.user_selected_warehouse_data.push(node)
                          }else if(node.children){
                            this.recursionSelectedUsers(node.children,id)
                          }
                       }else if(node.children){
                            this.recursionSelectedUsers(node.children,id)
                       }
                })
              
            },
            // 编辑发送
           async editWarehouseDo(){
                 // 先拿区域最后一个id
                this. editWarehouseForm.region_id= this.region_currentIds[this.region_currentIds.length-1]
                // 把选中的数组加到表单里
                this.editWarehouseForm.user_list=[]
                this.user_selected_warehouse_data.forEach(node=>{
                    this.editWarehouseForm.user_list.push(node.user_id)
                })
                let params=this.editWarehouseForm
                  const res= await this.$https.post(adminApi + '/store/edit?_ajax=1', params)
                if(res.data.error_code !==0 )  return this.$message.error( res.data.msg)
                this.$message.success(res.data.msg)
                this.dialogFormVisibleOfedit=false
                this.getWarehouse()
           },


        }

    }
</script>

<style lang="less" scoped>
    #app {
        overflow: hidden;
    }

    ul,
    li {
        list-style: none;
        text-decoration: none;
        padding: 0;
    }


    .card_left {
        width: 100%;
        padding: 10px;
        height: 700px;
        max-height: 700px;
        overflow-y: auto;

    }


    ul,
    li {
        text-decoration: none;
        list-style: none;
    }

    #app {
        position: relative;
    }

    .addUnit {
        //  margin-left: 1220px;
        margin-left: 84%;
        margin-bottom: 10px;
    }

    .unitSet {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .unitSet_left {
        flex: 1;
    }

    .unitSet_right {
        flex: 1;
        margin-top: 30px;
        width: 100%;

    }

    .card_right {
        margin-top: 20px;
        width: 45%;
        height: 220px;
        overflow-y: auto;
    }

    .unitSet_right .word {
        color: #606266;
        font-weight: 500;
    }



    .icon-huishouzhan {
        font-size: 20px;
        color: #606266;
        margin-right: 10px;
    }

    .el-table--scrollable-y .el-table__body-wrapper {
        overflow-y: hidden;
    }

    .el-table--scrollable-y .el-table__body-wrapper:hover {
        overflow-y: auto;
    }


    .recycle_card {
        margin-top: 20px;
    }

    .addWareHosue--style {
        width: 100%;
    }

    .addWareHosue--style .first,
    .addWareHosue--style .second,
    .addWareHosue--style .thirdly {
        display: flex;
        justify-content: space-evenly;
    }

    .addWareHosue--style .thirdly {
        justify-content: space-around;
    }



    .el-cascader {
        margin-left: 0 !important;
    }


    /deep/.bindUserGroup .el-dialog__body {
        height: 500px !important;
        overflow-y: hidden !important;
    }

    .all {
        display: flex;
        justify-content: start;
        width: 100%;
    }

    .all .left {
        flex: 7;
        position: relative;
        background-color: #fdfdfd;
    }

    .all .right {
        flex: 6;
        padding-left: 3%;
        background-color: #fdfdfd;
        border-left: 1px solid #e4e6e9;
        margin-left: 5%;
        padding-top: 0;
    }

    .all .right .tit {
        color: #787878;
    }

    .setSupSubStyle {
        margin-top: 1%;
        height: 480px;
        overflow-y: auto;
    }

    .ckeckStyle {
        margin-left: 6px;
    }

    .itemStyle {
        margin-bottom: 8px;
        position: relative;
    }

    .closeIcon {
        font-weight: 1000;
        color: #ababab;
        position: absolute;
        right: 6%;
    }


    .closeIcon:hover {
        cursor: pointer;
    }

    .addWareLeaderTree::-webkit-scrollbar {
        width: 6px !important;
    }

    .addWareLeaderTree::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(221, 222, 224);
    }

    .leaderItem--Style {
        margin-right: 10px;
    }

      /* el-table 滚动条样式 */
/deep/.el-table__body-wrapper::-webkit-scrollbar{
    height: 8px;
    width: 6px;
 }

 

/deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
</style>