<template>
    <div id="app">
        <el-card>
            <div class="sSearchArea_style">
                <div>
                      <span>
                    配送类型:
                        <el-select v-model="oReturnInfo.present_id" clearable placeholder="请选择"
                            @change="fnSeacrhWithCondition" size="small">
                            <el-option v-for="item in aPresentData" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </span>
               

                <span>
                    仓库类型:
                    
                        <el-select v-model="oReturnInfo.store_id" clearable placeholder="请选择"
                            @change="fnSeacrhWithCondition" size="small">
                            <el-option v-for="item in aWarehouseData" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                   
                </span>
                </div>

                <div>
                         <span>
                   日期:
                        <!-- <el-date-picker v-model="aDateArea" @change="fnSeacrhWithCondition" size="small"
                            type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                            value-format="yyyy-MM-dd hh:mm:ss">
                        </el-date-picker> -->

                       <el-date-picker @change="fnChangeTime" v-model="sBeginTime" type="datetime" size="mini" value-format="yyyy-MM-dd HH:mm:ss" placeholder="开始时间">
                        </el-date-picker>
                       
                       <i class="toStyle">至</i> 

                        <el-date-picker @change="fnChangeTime"  v-model="sEndTime" size="mini" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束时间">
                        </el-date-picker>

                        <span  class="sTimeShortCut">
                         <el-button  plain :class="nActiveIndex==0?'dateSwitchBtn':''" size="mini" @click="fnInitDateRange">今</el-button>
                         <el-button  plain :class="nActiveIndex==1?'dateSwitchBtn':''" size="mini" @click="fnYesterday">昨</el-button>
                         <el-button  plain :class="nActiveIndex==2?'dateSwitchBtn':''" size="mini" @click="fnWeek">近一周</el-button>
                         <el-button  plain :class="nActiveIndex==3?'dateSwitchBtn':''" size="mini" @click="fnMonth">本月</el-button>
                         <el-button  plain :class="nActiveIndex==4?'dateSwitchBtn':''" size="mini" @click="fnYear">本年</el-button>
                         <el-button  plain :class="nActiveIndex==5?'dateSwitchBtn':''" size="mini" @click="fnUnlimited">不限时间</el-button>

                    </span>
                </span>

                </div>
              

           
                <div>
                         <span>
                    <el-button size="mini" class="frontBtn" icon="el-icon-search" @click="fnSeacrhWithCondition">搜 索
                    </el-button>
                    <el-button size="mini" class="reverseBtn" icon="el-icon-refresh" @click="fnReset">重 置
                    </el-button>
                </span>
                </div>
           
            </div>

             <table width="100%" border="1" cellspacing="0" class="sTableStyle">
                        <thead class="sCustomerTheadStyle">
                            <tr class="sCustomerHeadStyle">
                                <th>退货编号<span style="color:#fa5a57">（双击查看详情）</span></th>
                                <th>订单类型</th>
                                <th>客户<span style="color:#fa5a57">（双击查看详情）</span></th>
                                <th>客户别名</th>
                                <th>退货金额</th>
                                <th>退货仓库</th>
                                <th>退货时间</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(node,index) in aReturnData" :key="index" class="sCustomerItemStyle"
                                @click="fnClickItem(node)"
                                :class="nOrderNum == node.order_num ? 'sClickItemStyle' : ''">
                              
                                <td align="center" @dblclick="fnGetReturnDetail(node)">
                                    {{node.order_num}}
                                </td>
                                <td align="center">{{node.order_type}}</td>
                                 <td align="center" @dblclick="fnLookDetail(node)">
                                    {{node.customer_name}}
                                </td>
                                <td align="center">{{node.customer_alias}}</td>
                                <td align="center">{{node.amount}}</td>
                                  <td align="center">{{node.store_name}}</td>
                                <td align="center">{{node.create_time}}</td>   
                            </tr>
                        </tbody>
                    </table>   


                 <!-- 分页器 -->
                <el-pagination @current-change="handleCurrentChange" :current-page="this.oReturnInfo.page"
                     layout="total, prev, pager, next, jumper" :total="ntotal"
                    align="center">
                </el-pagination>
        </el-card>

        <!--退货详情对话框 -->
        <el-dialog title="退货详情" :visible.sync="bReturnDetail" :width="dialogscreen?'95%':'60%'">
            <div class="sReturnDetail_style">

                <div class="product" v-show="oReturnDetail.product_list">
                    <div class="tit">
                      
                        退货产品 <i class="el-icon-caret-bottom caretStyle"></i>
                 
                        </div>
                    <el-table :data="oReturnDetail.product_list" border style="width: 100%"
                        :header-cell-style="styleBindFun">
                        <el-table-column label="所属订单" align="center" width="230">
                            <template slot-scope="scope">
                                {{scope.row.order_num}}
                            </template>
                        </el-table-column>


                        <el-table-column label="产品编号" align="center" width="110">
                            <template slot-scope="scope">
                                {{scope.row.product_sn}}
                            </template>
                        </el-table-column>


                        <el-table-column label="产品" align="center">
                            <template slot-scope="scope">
                                {{scope.row.product_name}} <span
                                    v-show="scope.row.product_spec">{{scope.row.product_spec}} </span>
                            </template>
                        </el-table-column>

                        <el-table-column label="价格" align="center" width="110">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">￥{{scope.row.price}} </span>
                            </template>
                        </el-table-column>

                        <el-table-column label="数量" align="center" width="110">
                            <template slot-scope="scope">
                                {{scope.row.quantity}}
                            </template>
                        </el-table-column>
                    </el-table>


                    <div class="SusernameStyle">
                       <span>操作人员:</span>
                        {{oReturnDetail.user_name}}
                    </div>

                    <div class="remarks">
                        <span class="tit">备注:</span>
                        <span>{{oReturnDetail.remarks}}</span>
                    </div>
                </div>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" class="frontBtn" @click="bReturnDetail = false">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import {
        erpApi
    } from '../../config'



    export default {
        data() {
            return {

                oReturnInfo: {
                    page: 1,
                    per_page: 10,
                    present_id: '',
                    start_time: '',
                    end_time: '',
                    store_id: '',
                },
                aPresentData: [],
                aWarehouseData: [],
                aDateArea: [],
                aReturnData: [],
                ntotal: 0,


                // 详情
                bReturnDetail: false,
                oReturnDetail: '',

                nOrderNum:'',

                sBeginTime:'',
                sEndTime:'',
                nActiveIndex:'',

                dialogscreen:false,



            }
        },
        created() {
            this.fnGetPresent()
            this.fnGetWarehouse()
            this.fnUnlimited()
            this.fnJudgeScreen()
        },

          mounted () {
             window.onresize=()=>{
                let width=document.body.clientWidth
                if(width<1550){
                    this.dialogscreen=true
                }else{
                    this.dialogscreen=false
                }
            }  
        },




        methods: {
             // 判断页面尺寸
            fnJudgeScreen(){
                let width=document.body.clientWidth
                if(width<1550){
                    this.dialogscreen=true
                }else{
                    this.dialogscreen=false
                }
            },

            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind = "background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                return styleBind;
            },
            // 获取配送类型
            async fnGetPresent() {
                const res = await this.$https.post(erpApi + '/present/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.aPresentData = res.data
                let obj = {
                    "id": 0,
                    "name": "预存款"
                }
                this.aPresentData.unshift(obj)
            },

            // 获取仓库
            async fnGetWarehouse() {
                const res = await this.$https.post(erpApi + '/store/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.aWarehouseData = res.data
            },

               fnChangeTime(){
                this.nActiveIndex=10

           },

             // 初始化当天日期范围
            fnInitDateRange() {
                let date = new Date();
                let year = date.getFullYear();
                let month = Number(date.getMonth() + 1);
                month = month >= 10 ? month : '0' + month
                let day = Number(date.getDate());
                day = day >= 10 ? day : '0' + day

                // 当天
                let sIntraDayBegin = year + '-' + month + '-' + day + ' ' + '00:00:00' 

                let sIntraDayEnd = year + '-' + month + '-' + day + ' ' + '23:59:59' 

                this.sBeginTime=sIntraDayBegin
                this.sEndTime=sIntraDayEnd

                this.fnSeacrhWithCondition()
                this.nActiveIndex=0


            },


              fnYesterday(){
                let date = new Date();
                date.setTime(date.getTime()-3600 * 1000 * 24);
                let year = date.getFullYear();
                let month = Number(date.getMonth() + 1);
                month = month >= 10 ? month : '0' + month
                let day = Number(date.getDate());
                day = day >= 10 ? day : '0' + day

                // 昨天
                let Begin = year + '-' + month + '-' + day + ' ' + '00:00:00' 

                let End = year + '-' + month + '-' + day + ' ' + '23:59:59' 

                this.sBeginTime=Begin
                this.sEndTime=End

                this.fnSeacrhWithCondition()

                this.nActiveIndex=1

            },

              fnWeek(){
                let date = new Date();
                date.setTime(date.getTime()-3600 * 1000 * 24 * 7);
                let year = date.getFullYear();
                let month = Number(date.getMonth() + 1);
                month = month >= 10 ? month : '0' + month
                let day = Number(date.getDate());
                day = day >= 10 ? day : '0' + day


                 let date2 = new Date();
                let year2 = date2.getFullYear();
                let month2 = Number(date2.getMonth() + 1);
                month2 = month2 >= 10 ? month2 : '0' + month2
                let day2 = Number(date2.getDate());
                day2 = day2 >= 10 ? day2 : '0' + day2

                // 近一周
                let Begin = year + '-' + month + '-' + day + ' ' + '00:00:00' 

                let End = year2 + '-' + month2 + '-' + day2 + ' ' + '23:59:59' 

                this.sBeginTime=Begin
                this.sEndTime=End

                this.fnSeacrhWithCondition()

                this.nActiveIndex=2

            },

             fnMonth(){
                let date = new Date();
                let year = date.getFullYear();
                let month = Number(date.getMonth() + 1);
                month = month >= 10 ? month : '0' + month
                let day = Number(date.getDate());
                day = day >= 10 ? day : '0' + day


                let  dayBegin="01"
                // 本月
                let Begin = year + '-' + month + '-' + dayBegin + ' ' + '00:00:00' 

                let End = year + '-' + month + '-' + day + ' ' + '23:59:59' 

                this.sBeginTime=Begin
                this.sEndTime=End

               this.fnSeacrhWithCondition()

                this.nActiveIndex=3

            },


             fnYear(){
                let date = new Date();
                let year = date.getFullYear();
                let month = Number(date.getMonth() + 1);
                month = month >= 10 ? month : '0' + month
                let day = Number(date.getDate());
                day = day >= 10 ? day : '0' + day


                let  dayBegin="01"
                let  monthBegin="01"
                // 本年
                let Begin = year + '-' + monthBegin + '-' + dayBegin + ' ' + '00:00:00' 

                let End = year + '-' + month + '-' + day + ' ' + '23:59:59' 

                this.sBeginTime=Begin
                this.sEndTime=End

               this.fnSeacrhWithCondition()

                this.nActiveIndex=4

            },

               fnUnlimited(){
                this.sBeginTime=''
                this.sEndTime=''
               
                this.fnSeacrhWithCondition()

                this.nActiveIndex=5
            },




            fnSeacrhWithCondition() {
                this.oReturnInfo.start_time=this.sBeginTime
                this.oReturnInfo.end_time=this.sEndTime

                if(this.sBeginTime ==null){
                    this.oReturnInfo.start_time=''
                }

                if(this.sEndTime ==null){
                    this.oReturnInfo.end_time=''
                }

                if(this.sBeginTime ==null && this.sEndTime !==null){
                    return this.$message.error('请把时间选择完整')
                }

                 if(this.sBeginTime !==null && this.sEndTime ==null){
                    return this.$message.error('请把时间选择完整')
                }

                 if(this.sBeginTime =='' && this.sEndTime !==''){
                    return this.$message.error('请把时间选择完整')
                }

                  if(this.sBeginTime !=='' && this.sEndTime ==''){
                    return this.$message.error('请把时间选择完整')
                }

                this.oReturnInfo.page = 1
                this.fnGetReturnList()

            },

            fnReset(){
                this.oReturnInfo.present_id=''
                this.oReturnInfo.store_id=''

                this.fnUnlimited()
            },

            // 获取退货数据
            async fnGetReturnList() {
                let params = this.oReturnInfo
                const res = await this.$https.post(erpApi + '/return/index?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.aReturnData = res.data.data
                this.ntotal = res.data.total
            },

            handleCurrentChange(data){
                this.oReturnInfo.page=data
                this.fnGetReturnList()
            },

            // 获取退货详情
            async fnGetReturnDetail(row) {
                this.bReturnDetail = true
                let params = {
                    order_num: row.order_num
                }
                const res = await this.$https.post(erpApi + '/return/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.oReturnDetail = res.data
            },

            fnClickItem(node) {
                this.nOrderNum = node.order_num
            },


            // -----------------------查看客户详情数据--------------------------------------
            async fnLookDetail(row) {

                let routeData = this.$router.resolve({
                    path: '/customerDetail',
                    query: {
                        data: row.customer_number,

                    }
                })
                window.open(routeData.href, '_blank')
            },

        },
    }
</script>

<style lang="less" scoped>
    #app{
        height: 100%;
    }
    .sSearchArea_style {
        background-color: #f5f7fa;
        padding: 10px 20px;
    }

    .sSearchArea_style div{
        margin-bottom: 5px;
    }

    .sSearchArea_style div span{
        margin-right: 50px;
        font-size: 14px;
    }


   


    .sReturnDetail_style{
        width: 100%;
    }

    .sReturnDetail_style .base .tit,
    .sReturnDetail_style .product .tit {
        font-size: 16px;
        font-weight: bolder;
        margin-bottom: 10px;
        color: #303133;
    }


    .sReturnDetail_style .product {
        // margin-top: 20px;
        margin-bottom: 20px;
        position: relative;
    }

    .sReturnDetail_style .product .SusernameStyle{
        position: absolute;
        right: 0;
        top: 0;

        color: #606266;
        font-size: 14px;
    }

    .sReturnDetail_style .product .SusernameStyle span{
        margin-right: 10px;
    }

      .sReturnDetail_style .product .remarks {
        margin-top: 20px;
     }

      .sReturnDetail_style .product .remarks .tit{
          margin-right: 10px;
      }

   div /deep/.el-descriptions__table{
        border: none;
    }

    .sTableStyle{
        margin-top: 20px;
    }
</style>