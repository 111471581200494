import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/components/Login'
import Home from '@/components/Home'
import Welcome from '@/components/Welcome'
import purchase from '@/components/CaiGouManagement/purchase'
import productList from '@/components/CaiGouManagement/productList'

import addPurchase from '@/components/CaiGouManagement/addPurchase'
import editPurchase from '@/components/CaiGouManagement/editPurchase'
import addtoPurchase from '@/components/CaiGouManagement/addtoPurchase'
import employeeManagement from '@/components/employeeManagement'
import goodsSet from '@/components/GoodsSet/goodsSet'
import addGoods from '@/components/GoodsSet/addGoods'
import editGoods from '@/components/GoodsSet/editGoods'

import DistributionScheme from '@/components/CustomerSet/DistributionScheme'
import customerMOQ from '@/components/CustomerSet/customerMOQ'
import CreditRating from '@/components/CustomerSet/CreditRating'
import discountSet from '@/components/CustomerSet/discountSet'
import CustomerGroup from '@/components/CustomerSet/CustomerGroup'
import addDistributionType  from '@/components/CustomerSet/addDistributionType'
import editDistributionType  from '@/components/CustomerSet/editDistributionType'
import editCustomer  from '@/components/CustomerSet/editCustomer'
import OrderManagementAll  from '@/components/CustomerManagement/OrderManagementAll'




import CustomerSet from '@/components/CustomerSet/CustomerSet'
import DistributionType  from '@/components/CustomerSet/DistributionType'
import CustomerManagement  from '@/components/CustomerManagement/customerManagement'
import SnapshotManagement  from '@/components/CustomerManagement/SnapshotManagement'
import customerDetail  from '@/components/CustomerManagement/customerDetail'
import addNewCustomer  from '@/components/CustomerManagement/addNewCustomer'
import editNewCustomer  from '@/components/CustomerManagement/editNewCustomer'
import invoice  from '@/components/CustomerManagement/invoice'
import invoiceSingle  from '@/components/CustomerManagement/invoiceSingle'
import salesReturn  from '@/components/CustomerManagement/salesReturn'
import salesReturnSingle  from '@/components/CustomerManagement/salesReturnSingle'





import cusFinancialManagement  from '@/components/CustomerManagement/cusFinancialManagement'
import cusFinancialManagementAll  from '@/components/CustomerManagement/cusFinancialManagementAll'
import OrderManagement  from '@/components/CustomerManagement/OrderManagement'
import directRk  from '@/components/CaiGouManagement/directRK'
import productScrap  from '@/components/CaiGouManagement/productScrap'
import addProductScrap  from '@/components/CaiGouManagement/addProductScrap'
import allocation from '@/components/CaiGouManagement/allocation'
import addAllocation from '@/components/CaiGouManagement/addAllocation'
import PurchaseOrderRK from '@/components/CaiGouManagement/PurchaseOrderRK'





import Warehousing  from '@/components/CaiGouManagement/Warehousing'
import batchManage  from '@/components/CaiGouManagement/batchManage'

import WarehouseInventory  from '@/components/CaiGouManagement/WarehouseInventory'
import addOrder  from '@/components/CustomerManagement/addOrder'
import editOrder  from '@/components/CustomerManagement/editOrder'
import handWorkExchangeGoods  from '@/components/CustomerManagement/handWorkExchangeGoods'
// import test  from '@/components/CustomerManagement/test'
import test  from '@/components/CustomerSet/test'


import unitSet  from '@/components/GoodsSet/unitSet'
import brandSet  from '@/components/GoodsSet/brandSet'
import wareHouseSet  from '@/components/GoodsSet/wareHouseSet'
import productsSet  from '@/components/GoodsSet/productsSet'
























Vue.use(VueRouter)

// 获取原型对象上的push函数
const originalPush=VueRouter.prototype.push

// 修改原型对象上种的push方法
VueRouter.prototype.push=function push(location) { 
  return originalPush.call(this,location).catch(err => err)
 }

const routes=[
  { path: '/', redirect: '/login' },
  { path: '/login', component: Login },
 
  {
     path: '/home', 
     component: Home,
     redirect:"/welcome",
     children:[
       {path:'/welcome',component:Welcome},
       {path:'/purchase',component:purchase},
       {path:'/employeemanagement',component:employeeManagement},
       {path:'/goodsSet',component:goodsSet},
       {path:'/distributionScheme',component:DistributionScheme},
       {path:'/customerMOQ',component:customerMOQ},
       {path:'/creditRating',component:CreditRating},
       {path:'/discountSet',component:discountSet},
       {path:'/customerGroup',component:CustomerGroup},
       {path:'/customerSet',component:CustomerSet},
       {path:'/distributionType',component:DistributionType},
       {path:'/customerManagement',component:CustomerManagement},
       {path:'/customerDetail',component:customerDetail},
       {path:'/addNewCustomer',component:addNewCustomer},
       {path:'/editNewCustomer',component:editNewCustomer},
       {path:'/cusFinancialManagement',component:cusFinancialManagement},
       {path:'/addPurchase',component:addPurchase},
       {path:'/editPurchase',component:editPurchase},
       {path:'/addtoPurchase',component:addtoPurchase},
       {path:'/directRk',component:directRk},
       {path:'/Warehousing',component:Warehousing},
       {path:'/WarehouseInventory',component:WarehouseInventory},
       {path:'/OrderManagement',component:OrderManagement},
       {path:'/addOrder',component:addOrder},
       {path:'/editOrder',component:editOrder},
       {path:'/handWorkExchangeGoods',component:handWorkExchangeGoods},
       {path:'/batchManage',component:batchManage},
       {path:'/addGoods',component:addGoods},
       {path:'/productScrap',component:productScrap},
       {path:'/addProductScrap',component:addProductScrap},
       {path:'/allocation',component:allocation},
       {path:'/addAllocation',component:addAllocation},
       {path:'/PurchaseOrderRK',component:PurchaseOrderRK},
       {path:'/editGoods',component:editGoods},
       {path:'/addDistributionType',component:addDistributionType},
       {path:'/editDistributionType',component:editDistributionType},
       {path:'/editCustomer',component:editCustomer},
       {path:'/SnapshotManagement',component:SnapshotManagement},
       {path:'/OrderManagementAll',component:OrderManagementAll},
       {path:'/invoice',component:invoice},
       {path:'/cusFinancialManagementAll',component:cusFinancialManagementAll},
       {path:'/salesReturn',component:salesReturn},
       {path:'/salesReturnSingle',component:salesReturnSingle},
       {path:'/invoiceSingle',component:invoiceSingle},
       {path:'/productList',component:productList},









       {path:'/unitSet',component:unitSet},
       {path:'/brandSet',component:brandSet},
       {path:'/wareHouseSet',component:wareHouseSet},
       {path:'/productsSet',component:productsSet},







       {path:'/test',component:test},





     ] 
}, 

]
const router = new VueRouter({
  mode:'history',
  routes
})
// 
//挂载路由导航守卫,to表示将要访问的路径，from表示从哪里来，next是下一个要做的操作
// router.beforeEach((to,from,next)=>{
//   if(to.path==='/login'){
//      return next()
//   }

//   // 获取token
//   const tokenStr=window.sessionStorage.getItem('token');

//   if(!tokenStr){
//      return next('/login');
//   }
//   next()
// })



export default router
