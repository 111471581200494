<template>
    <div id="app">
        <el-card class="BtnRegion">
             <div class="title_style">
                {{customerName}}
                <span>预存款: <i class="Number">￥{{customerAccount}}</i></span>
                <span>信用消费: <i class="Number">￥{{customerCreditSpend}}</i></span>
                <span>保证金: <i class="Number">￥{{customerDeposit}}</i></span>

                <span>
                    <el-button size="mini" class="reverseBtn sBackBtnStyle" @click="fnBack">返 回</el-button>
                </span>
            </div>

            <div class="btns">
                <div >
                    发票类型：
                    <span >
                        
                    <el-select clearable @change="searchWithCondition" size="mini" v-model="form_getinvoice.type_id"
                        placeholder="请选择">
                        <el-option v-for="item in invoiceType" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                    </span>
                </div>


                <div >
                    日期：
                    <!-- <el-date-picker @change="searchWithCondition" size="mini" v-model="dataArea" type="datetimerange"
                        range-separator="至" value-format="yyyy-MM-dd hh:mm:ss" start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker> -->

                     <el-date-picker @change="fnChangeTime" v-model="sBeginTime" type="datetime" size="mini" value-format="yyyy-MM-dd HH:mm:ss" placeholder="开始时间">
                        </el-date-picker>
                       
                       <i class="toStyle">至</i> 

                        <el-date-picker @change="fnChangeTime"  v-model="sEndTime" size="mini" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束时间">
                        </el-date-picker>

                     <span  class="sTimeShortCut">
                         <el-button  plain :class="nActiveIndex==0?'dateSwitchBtn':''" size="mini" @click="fnInitDateRange">今</el-button>
                         <el-button  plain :class="nActiveIndex==1?'dateSwitchBtn':''" size="mini" @click="fnYesterday">昨</el-button>
                         <el-button  plain :class="nActiveIndex==2?'dateSwitchBtn':''" size="mini" @click="fnWeek">近一周</el-button>
                         <el-button  plain :class="nActiveIndex==3?'dateSwitchBtn':''" size="mini" @click="fnMonth">本月</el-button>
                         <el-button  plain :class="nActiveIndex==4?'dateSwitchBtn':''" size="mini" @click="fnYear">本年</el-button>
                         <!-- <el-button  plain :class="nActiveIndex==5?'dateSwitchBtn':''" size="mini" @click="fnUnlimited">不限时间</el-button> -->

                    </span>

                </div>

                <div>
                    <el-button size="mini" class="frontBtn" icon="el-icon-search" @click="searchWithCondition">搜 索
                    </el-button>
                    <el-button size="mini" class="reverseBtn" icon="el-icon-refresh" @click="fnReset">重 置
                    </el-button>
                </div>

            </div>

            <div class="totalAmountStyle">开票总金额: <span class="Number">￥{{totalAmount}}</span> </div>
            

            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="全部" name="first">
                   <el-table :data="invoiceData" border :header-cell-style="styleBindFun" :cell-class-name="cellClsNm" style="width: 100%">
                        <el-table-column prop="status_name" label="状态" align="center" width="auto">
                        </el-table-column>

                        <el-table-column prop="type_name" label="发票类型" align="center" width="100">
                        </el-table-column>

                        <el-table-column  align="center" width="320%">
                             <template slot="header">
                                开票号 <span style="color:#fa5a57">（单击查看详情）</span>
                            </template>
                         

                              <template slot-scope="scope">
                                    <span type="text_primary" class="text_customername" @click="detailItem(scope.row)"
                                    size="big">
                                    {{scope.row.order_num}}</span>
                                <span class="text_copy_style">
                                    <el-button class="copyNameBtn" type="text"  size="mini" icon="el-icon-document-copy"
                                        @click="fnCopyOrderNum(scope.row.order_num)">复制
                                    </el-button>
                                </span>
                                

                            </template>
                        </el-table-column>

                        

                        <el-table-column  align="center" width="235%">
                             <template slot="header">
                                发票编号 <span style="color:#fa5a57">（单击复制）</span>
                            </template>

                            <template slot-scope="scope">
                                <span @click="fnCopyOrderNum(scope.row.tax_num)">{{scope.row.tax_num}}</span>
                            </template>

                        </el-table-column>



                        <el-table-column  label="开票金额" align="center" width="150">
                            <template slot-scope="scope">
                               <i class="NumberNoLeft">￥{{scope.row.amount_format}}</i> 
                            </template>
                        </el-table-column>

                        <el-table-column align="center" width="360">
                            <template slot="header">
                                客户 <span style="color:#fa5a57">（点击查看详情）</span>
                            </template>
                            <template slot-scope="scope">
                                <el-button @click="lookDetail(scope.row)" type="text" class="text_customername"
                                    size="big">
                                    {{scope.row.customer_name}}</el-button>
                            </template>
                        </el-table-column>

                         <el-table-column  label="别名" align="center" width="300">
                            <template slot-scope="scope">
                               {{scope.row.customer_alias}}
                            </template>
                        </el-table-column>

                        <el-table-column prop="user_name" label="申请人" align="center" width="auto">
                        </el-table-column>

                        <el-table-column prop="create_time" label="申请时间" align="center" width="200">
                        </el-table-column>



                    </el-table>

                </el-tab-pane>

                <el-tab-pane label="已开票" name="second">
                     <el-table :data="invoiceData" border :header-cell-style="styleBindFun" :cell-class-name="cellClsNm" style="width: 100%">
                        <el-table-column prop="status_name" label="状态" align="center" width="auto">
                        </el-table-column>

                        <el-table-column prop="type_name" label="发票类型" align="center" width="100">
                        </el-table-column>

                        <el-table-column  align="center" width="320%">
                             <template slot="header">
                                开票号 <span style="color:#fa5a57">（单击查看详情）</span>
                            </template>
                         

                              <template slot-scope="scope">
                                    <span type="text_primary" class="text_customername" @click="detailItem(scope.row)"
                                    size="big">
                                    {{scope.row.order_num}}</span>
                                <span class="text_copy_style">
                                    <el-button class="copyNameBtn" type="text"  size="mini" icon="el-icon-document-copy"
                                        @click="fnCopyOrderNum(scope.row.order_num)">复制
                                    </el-button>
                                </span>
                                

                            </template>
                        </el-table-column>

                        

                        <el-table-column  align="center" width="235%">
                             <template slot="header">
                                发票编号 <span style="color:#fa5a57">（单击复制）</span>
                            </template>

                            <template slot-scope="scope">
                                <span @click="fnCopyOrderNum(scope.row.tax_num)">{{scope.row.tax_num}}</span>
                            </template>

                        </el-table-column>



                        <el-table-column  label="开票金额" align="center" width="150">
                            <template slot-scope="scope">
                               <i class="NumberNoLeft">￥{{scope.row.amount_format}}</i> 
                            </template>
                        </el-table-column>

                        <el-table-column align="center" width="360">
                            <template slot="header">
                                客户 <span style="color:#fa5a57">（点击查看详情）</span>
                            </template>
                            <template slot-scope="scope">
                                <el-button @click="lookDetail(scope.row)" type="text" class="text_customername"
                                    size="big">
                                    {{scope.row.customer_name}}</el-button>
                            </template>
                        </el-table-column>

                         <el-table-column  label="别名" align="center" width="300">
                            <template slot-scope="scope">
                               {{scope.row.customer_alias}}
                            </template>
                        </el-table-column>

                        <el-table-column prop="user_name" label="申请人" align="center" width="auto">
                        </el-table-column>

                        <el-table-column prop="create_time" label="申请时间" align="center" width="200">
                        </el-table-column>



                    </el-table>

                </el-tab-pane>
                <el-tab-pane label="未开票" name="third">
                          <el-table :data="invoiceData" border :header-cell-style="styleBindFun" :cell-class-name="cellClsNm" style="width: 100%">
                        <el-table-column prop="status_name" label="状态" align="center" width="auto">
                        </el-table-column>

                        <el-table-column prop="type_name" label="发票类型" align="center" width="100">
                        </el-table-column>

                        <el-table-column  align="center" width="320%">
                             <template slot="header">
                                开票号 <span style="color:#fa5a57">（单击查看详情）</span>
                            </template>
                         

                              <template slot-scope="scope">
                                    <span type="text_primary" class="text_customername" @click="detailItem(scope.row)"
                                    size="big">
                                    {{scope.row.order_num}}</span>
                                <span class="text_copy_style">
                                    <el-button class="copyNameBtn" type="text"  size="mini" icon="el-icon-document-copy"
                                        @click="fnCopyOrderNum(scope.row.order_num)">复制
                                    </el-button>
                                </span>
                                

                            </template>
                        </el-table-column>

                        

                        <el-table-column  align="center" width="235%">
                             <template slot="header">
                                发票编号 <span style="color:#fa5a57">（单击复制）</span>
                            </template>

                            <template slot-scope="scope">
                                <span @click="fnCopyOrderNum(scope.row.tax_num)">{{scope.row.tax_num}}</span>
                            </template>

                        </el-table-column>



                        <el-table-column  label="开票金额" align="center" width="150">
                            <template slot-scope="scope">
                               <i class="NumberNoLeft">￥{{scope.row.amount_format}}</i> 
                            </template>
                        </el-table-column>

                        <el-table-column align="center" width="360">
                            <template slot="header">
                                客户 <span style="color:#fa5a57">（点击查看详情）</span>
                            </template>
                            <template slot-scope="scope">
                                <el-button @click="lookDetail(scope.row)" type="text" class="text_customername"
                                    size="big">
                                    {{scope.row.customer_name}}</el-button>
                            </template>
                        </el-table-column>

                         <el-table-column  label="别名" align="center" width="300">
                            <template slot-scope="scope">
                               {{scope.row.customer_alias}}
                            </template>
                        </el-table-column>

                        <el-table-column prop="user_name" label="申请人" align="center" width="auto">
                        </el-table-column>

                        <el-table-column prop="create_time" label="申请时间" align="center" width="200">
                        </el-table-column>



                    </el-table>
                </el-tab-pane>

                <el-tab-pane label="已签收" name="fourth">
                     <el-table :data="invoiceData" border :header-cell-style="styleBindFun" :cell-class-name="cellClsNm" style="width: 100%">
                        <el-table-column prop="status_name" label="状态" align="center" width="auto">
                        </el-table-column>

                        <el-table-column prop="type_name" label="发票类型" align="center" width="100">
                        </el-table-column>

                        <el-table-column  align="center" width="320%">
                             <template slot="header">
                                开票号 <span style="color:#fa5a57">（单击查看详情）</span>
                            </template>
                         

                              <template slot-scope="scope">
                                    <span type="text_primary" class="text_customername" @click="detailItem(scope.row)"
                                    size="big">
                                    {{scope.row.order_num}}</span>
                                <span class="text_copy_style">
                                    <el-button class="copyNameBtn" type="text"  size="mini" icon="el-icon-document-copy"
                                        @click="fnCopyOrderNum(scope.row.order_num)">复制
                                    </el-button>
                                </span>
                                

                            </template>
                        </el-table-column>

                        

                        <el-table-column  align="center" width="235%">
                             <template slot="header">
                                发票编号 <span style="color:#fa5a57">（单击复制）</span>
                            </template>

                            <template slot-scope="scope">
                                <span @click="fnCopyOrderNum(scope.row.tax_num)">{{scope.row.tax_num}}</span>
                            </template>

                        </el-table-column>



                        <el-table-column  label="开票金额" align="center" width="150">
                            <template slot-scope="scope">
                               <i class="NumberNoLeft">￥{{scope.row.amount_format}}</i> 
                            </template>
                        </el-table-column>

                        <el-table-column align="center" width="360">
                            <template slot="header">
                                客户 <span style="color:#fa5a57">（点击查看详情）</span>
                            </template>
                            <template slot-scope="scope">
                                <el-button @click="lookDetail(scope.row)" type="text" class="text_customername"
                                    size="big">
                                    {{scope.row.customer_name}}</el-button>
                            </template>
                        </el-table-column>

                         <el-table-column  label="别名" align="center" width="300">
                            <template slot-scope="scope">
                               {{scope.row.customer_alias}}
                            </template>
                        </el-table-column>

                        <el-table-column prop="user_name" label="申请人" align="center" width="auto">
                        </el-table-column>

                        <el-table-column prop="create_time" label="申请时间" align="center" width="200">
                        </el-table-column>



                    </el-table>
                </el-tab-pane>

                <!-- 分页器 -->
                <el-pagination @current-change="handleCurrentChange" :current-page="this.form_getinvoice.page"
                    :page-size="this.form_getinvoice.per_page" layout="total, prev, pager, next, jumper" :total="total"
                    align="center">
                </el-pagination>

            </el-tabs>


        </el-card>



        <!-- ---------------------------------------- 对话框第一层 --------------------------------------------------- -->
        <el-dialog title="开票详情" :visible.sync="is_detail" top="80px" :width="dialogscreen?'95%':'65%'" class="invoiceDetailStyle">
            <div class="btnStyle">
                <span>
                    <el-tag   size="medium" v-if="detailData.status_name=='待开票'" type="info" effect="plain">
                        {{detailData.status_name}}
                    </el-tag>
                    <el-tag  size="medium" v-else effect="plain">
                        {{detailData.status_name}}
                    </el-tag>
                </span>

                <span>

                    <el-button v-show="currentStatusName == '待开票'" class="frontBtn" size="mini" @click="checkItem()">
                        审核开票
                    </el-button>
                    <el-button v-show="currentStatusName == '待开票'" size="mini" class="reverseBtn" @click="cancelItem()">
                        取消开票
                    </el-button>

                    <el-button v-show="currentStatusName == '已开票'" class="frontBtn" size="mini" @click="fnSign">
                        签收
                    </el-button>

                </span>
            </div>

          

            <div class="sSliderAreaStyle">

            <div class="sBaseStyle">
                <div class="tit"> <span class="sRectangleStyle"></span>基本信息 </div>
                 <div class="sDetailFirst">
                        <span>
                            类型：
                            <span class="text_primary">{{detailData.type_name}}</span>
                        </span>

                        <span>
                            金额：
                            <span class="NumberNoLeft">￥{{detailData.amount_format}}</span>
                        </span>


                        <span v-show="detailData.tax_num">
                            发票号：
                            {{detailData.tax_num}}
                        </span>

                        <span>
                            开票时间：{{detailData.create_time}}
                        </span>

                        <span>
                            客户：
                           <span @click="fnCopyCustomerName(detailData.customer_name)">{{detailData.customer_name}}</span> 
                        </span>


                        <span>
                            申请人：{{detailData.user_name}}
                        </span>
                </div>
            </div>

            <div class="sInvoiceInfoStyle">
                <div class="tit"> <span class="sRectangleStyle"></span>开票信息 </div>
                 <div class="sDetailThird">
                        <span>税务代码：{{detailData_tax.register_number}}</span>
                        <span>银行：{{detailData_tax.bank}}</span>
                        <span>银行账号:{{detailData_tax.bank_number}}</span>
                        <span>地址：{{detailData_tax.register_address}}</span>
                        <span>电话：{{detailData_tax.register_phone}}</span>
                    </div>
            </div>


            <div class="sOrderStyle">
                <div class="tit"> <span class="sRectangleStyle"></span>订单 </div>
                  <div>

                        <el-table :data="detailData.orders" border :header-cell-style="styleBindFun"
                            style="width: 100%">
                            <el-table-column  align="center" width="220">
                                <template slot="header">
                                订单号 <span style="color:#fa5a57">（点击跳转）</span>
                            </template>

                                  <template slot-scope="scope">
                                    <el-button type="text" class="moveTextbtn" @click="printDo(scope.row)">{{scope.row.order_num}}</el-button>
                                </template>  
                            </el-table-column>

                            <el-table-column label="金额" align="center">
                                <template slot-scope="scope">
                                    <span class="NumberNoLeft">￥{{scope.row.amount_format}}</span>
                                </template>
                            </el-table-column>

                            <el-table-column label="退货" align="center">
                                <template slot-scope="scope">
                                    <span class="NumberNoLeft">￥{{scope.row.return_amount_format}}</span>
                                </template>
                            </el-table-column>

                            <el-table-column label="开票金额" align="center">
                                <template slot-scope="scope">
                                    <span class="NumberNoLeft">￥{{scope.row.invoice_amount_format}}</span>
                                </template>
                            </el-table-column>



                            <el-table-column prop="user_name" label="创建人" align="center" width="350">
                                <template slot-scope="scope">
                                    {{scope.row.user_name}} 
                                </template>
                            </el-table-column>

                            <el-table-column prop="create_time" label="创建时间" align="center" width="180">
                            </el-table-column>

                             
                        </el-table>

                    </div>
            </div>

            <div class="sProductStyle">
                <div class="tit"> <span class="sRectangleStyle"></span>产品 </div>
                  <div>

                        <el-table :data="detailData_proList" border :header-cell-style="styleBindFun"
                            style="width: 100%">
                            <el-table-column prop="product_sn" label="货号" align="center">
                            </el-table-column>

                            <el-table-column label="产品" align="center" width="390">
                                <template slot-scope="scope">
                                    {{scope.row.product_name}}
                                </template>
                            </el-table-column>

                            <el-table-column label="规格" align="center">
                                <template slot-scope="scope">
                                    {{scope.row.product_spec}}
                                </template>
                            </el-table-column>

                            <el-table-column label="单位" align="center">
                                <template slot-scope="scope">
                                    {{scope.row.unit_name}}
                                </template>
                            </el-table-column>

                            <el-table-column prop="quantity" label="数量" align="center">
                            </el-table-column>



                            <el-table-column label="单价" align="center">
                                <template slot-scope="scope">
                                    <span class="NumberNoLeft">￥{{scope.row.price}}</span>
                                </template>
                            </el-table-column>




                            <el-table-column label="小计" align="center">
                                <template slot-scope="scope">
                                    <span class="NumberNoLeft">￥{{scope.row.total_price}}</span>
                                </template>
                            </el-table-column>



                        </el-table>

                    </div>
            </div>
            
            </div>

              <div class="remarks">
                <span>备注：</span>
                {{detailData.remarks}}
            </div>









            <div slot="footer" class="dialog-footer">
                <el-button class="frontBtn" size="mini" @click="is_detail=false">确 定</el-button>
            </div>
        </el-dialog>


        <el-dialog title="签收发票" :visible.sync="bSign" width="30%">

            <el-form label-width="100px" class="check_form">
                <el-form-item label="发票号码:" prop="must">
                    <el-input clearabel v-model="oFormSign.tax_num" size="mini" placeholder="开具的发票号码8位数字"></el-input>

                </el-form-item>

                <el-form-item label="备注:" prop="must">
                    <el-input type="textarea" clearabel v-model="oFormSign.remarks" size="mini" placeholder="备注">
                    </el-input>

                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button class="frontBtn" size="mini" @click="fnSignDo">确 定</el-button>
            </div>
        </el-dialog>


        <el-dialog title="取消开票" :visible.sync="is_cancel" width="30%">

            <el-form label-width="80px" class="check_form">

                <el-form-item label="缘由:" prop="must">
                    <el-input type="textarea" clearabel v-model="form_cancel.remarks" size="mini" placeholder="说明取消的原因">
                    </el-input>

                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button class="frontBtn" size="mini" @click="cancelItemDo">确 定</el-button>
            </div>
        </el-dialog>



    </div>
</template>

<script>
    import {
        erpApi
    } from '../../config'

 

    export default {
        data() {
            return {
                activeName: 'first',
                form_getinvoice: {
                    page: 1,
                    per_page: 9,
                    type_id: '',
                    status: '',
                    start_time: '',
                    end_time: '',
                    customer_number: this.$route.query.data.sCustomerNumber
                },
                customerName: this.$route.query.data.sCustomerName,
                customerAccount:this.$route.query.data.sCustomerAccount,
                customerCreditSpend: this.$route.query.data.sCustomerCreditSpend,
                customerDeposit: this.$route.query.data.sCustomerDeposit,


                invoiceData: [],
                total: 0,

                detailData: [],
                is_detail: false,

                form_check: {
                    order_num: '',
                },

                bSign: false,
                oFormSign: {
                    order_num: '',
                    tax_num: '',
                    remarks: '',
                },

                is_cancel: false,
                form_cancel: {
                    order_num: '',
                    remarks: '',
                },

                invoiceType: [],

                detailData_tax: '',
                detailData_proList: [],

                // 当前订单号
                currentOrderNum: '',
                currentStatusName: '',

                dataArea: [],

                dialogscreen:false,

                sBeginTime:'',
                sEndTime:'',

                nActiveIndex:'',

                bIsDblclick:false,

                totalAmount:'',



            }
        },
        created() {

            this.fnYear()
            this.getType()
            this.fnJudgeScreen()
        },
         mounted () {
             window.onresize=()=>{
                let width=document.body.clientWidth
                if(width<1550){
                    this.dialogscreen=true
                }else{
                    this.dialogscreen=false
                }
            }  
        },


        methods: {

            // 判断页面尺寸
            fnJudgeScreen(){
                let width=document.body.clientWidth
                if(width<1550){
                    this.dialogscreen=true
                }else{
                    this.dialogscreen=false
                }
            },


            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind = "background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                return styleBind;
            },
            handleClick(data) {
                if (data.index == 0) {
                    this.activeName = 'first',
                        this.form_getinvoice.status = ''
                    this.getInfo()
                }
                if (data.index == 1) {
                    this.activeName = 'second',
                        this.form_getinvoice.status = 1
                    this.getInfo()

                }
                if (data.index == 2) {
                    this.activeName = 'third',
                        this.form_getinvoice.status = 0
                    this.getInfo()

                }
                if (data.index == 3) {
                    this.activeName = 'fourth',
                        this.form_getinvoice.status = 2
                    this.getInfo()

                }
            },

            async getType() {
                const res = await this.$https.post(erpApi + '/invoice/type/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.invoiceType = res.data
            },


           fnChangeTime(){
                this.nActiveIndex=10

           },

             // 初始化当天日期范围
            fnInitDateRange() {
                let date = new Date();
                let year = date.getFullYear();
                let month = Number(date.getMonth() + 1);
                month = month >= 10 ? month : '0' + month
                let day = Number(date.getDate());
                day = day >= 10 ? day : '0' + day

                // 当天
                let sIntraDayBegin = year + '-' + month + '-' + day + ' ' + '00:00:00' 

                let sIntraDayEnd = year + '-' + month + '-' + day + ' ' + '23:59:59' 

                this.sBeginTime=sIntraDayBegin
                this.sEndTime=sIntraDayEnd

                this.searchWithCondition()
                this.nActiveIndex=0


            },


              fnYesterday(){
                let date = new Date();
                date.setTime(date.getTime()-3600 * 1000 * 24);
                let year = date.getFullYear();
                let month = Number(date.getMonth() + 1);
                month = month >= 10 ? month : '0' + month
                let day = Number(date.getDate());
                day = day >= 10 ? day : '0' + day

                // 昨天
                let Begin = year + '-' + month + '-' + day + ' ' + '00:00:00' 

                let End = year + '-' + month + '-' + day + ' ' + '23:59:59' 

                this.sBeginTime=Begin
                this.sEndTime=End

                this.searchWithCondition()

                this.nActiveIndex=1

            },

              fnWeek(){
                let date = new Date();
                date.setTime(date.getTime()-3600 * 1000 * 24 * 7);
                let year = date.getFullYear();
                let month = Number(date.getMonth() + 1);
                month = month >= 10 ? month : '0' + month
                let day = Number(date.getDate());
                day = day >= 10 ? day : '0' + day


                 let date2 = new Date();
                let year2 = date2.getFullYear();
                let month2 = Number(date2.getMonth() + 1);
                month2 = month2 >= 10 ? month2 : '0' + month2
                let day2 = Number(date2.getDate());
                day2 = day2 >= 10 ? day2 : '0' + day2

                // 近一周
                let Begin = year + '-' + month + '-' + day + ' ' + '00:00:00' 

                let End = year2 + '-' + month2 + '-' + day2 + ' ' + '23:59:59' 

                this.sBeginTime=Begin
                this.sEndTime=End

                this.searchWithCondition()

                this.nActiveIndex=2

            },

             fnMonth(){
                let date = new Date();
                let year = date.getFullYear();
                let month = Number(date.getMonth() + 1);
                month = month >= 10 ? month : '0' + month
                let day = Number(date.getDate());
                day = day >= 10 ? day : '0' + day


                let  dayBegin="01"
                // 本月
                let Begin = year + '-' + month + '-' + dayBegin + ' ' + '00:00:00' 

                let End = year + '-' + month + '-' + day + ' ' + '23:59:59' 

                this.sBeginTime=Begin
                this.sEndTime=End

               this.searchWithCondition()

                this.nActiveIndex=3

            },


             fnYear(){
                let date = new Date();
                let year = date.getFullYear();
                let month = Number(date.getMonth() + 1);
                month = month >= 10 ? month : '0' + month
                let day = Number(date.getDate());
                day = day >= 10 ? day : '0' + day


                let  dayBegin="01"
                let  monthBegin="01"
                // 本年
                let Begin = year + '-' + monthBegin + '-' + dayBegin + ' ' + '00:00:00' 

                let End = year + '-' + month + '-' + day + ' ' + '23:59:59' 

                this.sBeginTime=Begin
                this.sEndTime=End

               this.searchWithCondition()

                this.nActiveIndex=4

            },

               fnUnlimited(){
                this.sBeginTime=''
                this.sEndTime=''
               
                this.searchWithCondition()

                this.nActiveIndex=5
            },



            searchWithCondition() {

                this.form_getinvoice.start_time=this.sBeginTime
                this.form_getinvoice.end_time=this.sEndTime

                if(this.sBeginTime ==null){
                    this.form_getinvoice.start_time=''
                }

                if(this.sEndTime ==null){
                    this.form_getinvoice.end_time=''
                }

                if(this.sBeginTime ==null && this.sEndTime !==null){
                    return this.$message.error('请把时间选择完整')
                }

                 if(this.sBeginTime !==null && this.sEndTime ==null){
                    return this.$message.error('请把时间选择完整')
                }

                 if(this.sBeginTime =='' && this.sEndTime !==''){
                    return this.$message.error('请把时间选择完整')
                }

                  if(this.sBeginTime !=='' && this.sEndTime ==''){
                    return this.$message.error('请把时间选择完整')
                }

                this.form_getinvoice.page = 1
                this.getInfo()
            },

            fnReset() {

                this.form_getinvoice.type_id = ''
                this.fnUnlimited()
            },

            async getInfo() {
                let params = this.form_getinvoice
                const res = await this.$https.post(erpApi + '/customer/invoice/index?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.invoiceData = res.data.data
                this.total = res.data.total
                this.totalAmount=res.data.sum_amount_format
            },

            async detailItem(row) {
                this.bIsDblclick=true
                this.is_detail = true
                this.currentOrderNum = row.order_num
                let params = {
                    order_num: row.order_num
                }
                const res = await this.$https.post(erpApi + '/customer/invoice/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.detailData = res.data
                this.detailData_tax = res.data.tax
                this.detailData_proList = res.data.product_list
                this.currentStatusName = res.data.status_name
            },


            async detailItemAfter() {
                let params = {
                    order_num: this.currentOrderNum
                }
                const res = await this.$https.post(erpApi + '/customer/invoice/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.detailData = res.data
                this.detailData_tax = res.data.tax
                this.detailData_proList = res.data.product_list
                this.currentStatusName = res.data.status_name

            },

            checkItem() {
                this.$confirm('确定审核该票据?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    .then(async () => {
                        this.form_check.order_num = this.currentOrderNum
                        let params = this.form_check
                        const res = await this.$https.post(erpApi + '/customer/invoice/audit?_ajax=1', params)
                        if (res.data.error_code !== 0) return this.$message.error(res.data.msg)
                        this.$message.success(res.data.msg)
                        this.detailItemAfter()
                        this.getInfo()

                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: '已取消删除'
                        })
                    })






            },




            cancelItem() {
                this.is_cancel = true
                this.form_cancel.order_num = this.currentOrderNum
                this.form_cancel.remarks = ''
            },

            async cancelItemDo() {
                let params = this.form_cancel
                const res = await this.$https.post(erpApi + '/customer/invoice/cancel?_ajax=1', params)
                if (res.data.error_code !== 0) return this.$message.error(res.data.msg)
                this.$message.success(res.data.msg)
                this.detailItemAfter()
                this.getInfo()
                this.is_cancel = false
            },


            handleCurrentChange(data) {
                this.form_getinvoice.page = data
                this.getInfo()
            },

            // 签收
            fnSign() {
                this.bSign = true
                this.oFormSign.order_num = this.currentOrderNum
                this.oFormSign.tax_num = ''
                this.oFormSign.remarks = ''

            },
            async fnSignDo() {
                let params = this.oFormSign
                const res = await this.$https.post(erpApi + '/customer/invoice/sign?_ajax=1', params)
                if (res.data.error_code !== 0) return this.$message.error(res.data.msg)
                this.$message.success(res.data.msg)
                this.detailItemAfter()
                this.getInfo()
                this.bSign = false
            },

            // -----------------------查看客户详情数据--------------------------------------
            async lookDetail(row) {

                let routeData = this.$router.resolve({
                    path: '/customerDetail',
                    query: {
                        data: row.customer_number,

                    }
                })
                window.open(routeData.href, '_blank')
            },

            // ----------------------复制名字--------------------------------------------
            fnCopyCustomerName(data){
                var input = document.createElement("input"); // 创建input对象
                input.value = data; // 设置复制内容
                document.body.appendChild(input); // 添加临时实例
                input.select(); // 选择实例内容
                document.execCommand("Copy"); // 执行复制
                document.body.removeChild(input); // 删除临时实例
                this.$message.success('复制成功！');
            },

            fnCopyOrderNum(data){
                this.bIsDblclick=false
                setTimeout(()=>{
                if(this.bIsDblclick==true) return 
                var input = document.createElement("input"); // 创建input对象
                input.value = data; // 设置复制内容
                document.body.appendChild(input); // 添加临时实例
                input.select(); // 选择实例内容
                document.execCommand("Copy"); // 执行复制
                document.body.removeChild(input); // 删除临时实例
                this.$message.success('复制成功！');   
                },500)
               
            },

              fnBack(){
                this.$router.go(-1)
            },

             cellClsNm(data){
                if(data.columnIndex == '2'){
                    return 'sCustomerNameCell'
                }
            },

            // 跳转打印单
            printDo(data) {
                let number = data.order_num
                window.open('/printContent.html?number=' + number)
            },


         


        },
    }
</script>

<style lang="less" scoped>
    .btns {
        background-color: #f5f7fa;
        padding: 10px 20px;
        border-radius: 3px;
        margin-bottom: 20px;
        font-size: 14px;
    }


    .btns div {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }

    .remarks{
        margin-top: 20px;
    }


    div /deep/ .invoiceDetailStyle .el-dialog__body {
        display: flex;
        flex-direction: column;
        padding: 20px 20px;
    }

    .invoiceDetailStyle .first,
    .invoiceDetailStyle .third {
        margin-bottom: 35px;
    }














    .invoiceDetailStyle .third div,
    .invoiceDetailStyle .fourth div,
    .invoiceDetailStyle .fifth div {
        display: flex;
        flex-wrap: wrap;
    }

    .invoiceDetailStyle .fifth {
        margin-top: 30px;
    }

    .invoiceDetailStyle .third div span {
        width: 33%;
        margin-top: 10px;
    }

    .invoiceDetailStyle .first .tit,
    .invoiceDetailStyle .third .tit,
    .invoiceDetailStyle .fourth .tit,
    .invoiceDetailStyle .fifth .tit {
        font-weight: 600;
        font-size: 16px;
        color: #333;
        margin-bottom: 2px;
        vertical-align: middle;
    }


    .invoiceDetailStyle .first div:nth-child(2),
    .invoiceDetailStyle .third div:nth-child(2),
    .invoiceDetailStyle .fourth div:nth-child(2),
    .invoiceDetailStyle .fifth div:nth-child(2) {
        padding: 0 5px;
    }





    .invoiceDetailStyle .btnStyle {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        background-color: #f5f7fa;
        padding: 5px ; 
    }

    .sDetailThird,
    .sDetailFirst {
        display: flex;
        flex-wrap: wrap;
    }

    .sDetailFirst span,
    .sDetailThird span {
        margin-top: 10px;
        width: 33%;
    }

    .check_form {
        width: 100%;
    }

    .infoStyle {
        color: #1f6eef;
        font-size: 16px;
        display: flex;
        align-items: center;
        margin-right: 3px;
    }


    /* el-table 滚动条样式 */
    .sSliderArea_style::-webkit-scrollbar {
        height: 8px;
        width: 6px;
    }



    .sSliderArea_style::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }
     .sSliderArea_style::-webkit-scrollbar-thumb:hover {
        background-color:#a8a8a8;
    }

    .invoiceDetailStyle .remarks{
        margin-bottom: 20px;
    }

    .invoiceDetailStyle .remarks span{
        font-weight: 550;
        font-size: 16px;
        color: #333;
    }


    .sInvoiceInfoStyle,
    .sOrderStyle,
    .sProductStyle{
        margin-top: 20px;
    }

    .sBaseStyle .tit,
    .sInvoiceInfoStyle  .tit,
    .sOrderStyle  .tit,
    .sProductStyle  .tit{
        color: #333;
        font-size: 14px;
        font-weight: bolder;
        display: flex;
        align-items: center;
    }

    .sSliderAreaStyle{
        height: 500px;
        overflow-y:scroll;
    }

.sSliderAreaStyle::-webkit-scrollbar{
    height: 8px;
    width: 6px;
 }

 

        .sSliderAreaStyle::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

  .sSliderAreaStyle::-webkit-scrollbar-thumb:hover {
        background-color:#a8a8a8;
    }

     .BtnRegion {
        margin-bottom: 10px;
        height: 850px;
        position: relative;
    }

    .title_style {
        font-weight: bolder;
        font-size: 18px;
        margin-bottom: 20px;
        position: relative;
    }

    .title_style span {
        margin: 0 60px;
        color: #606266;
        font-size: 14px;
        font-weight: normal;
       
    }

    .sBackBtnStyle{
        position: absolute;
        right: 2%;
    }

        //   .el-card{
        //     position: relative;
        // }

        .totalAmountStyle{
            position: absolute;
            right: 2%;
            top: 25%;
            font-size: 15px;
        }

        .totalAmountStyle span{
            font-size: 18px;
        }


        /* el-table 滚动条样式 */
    div /deep/.el-table__body-wrapper::-webkit-scrollbar {
        height: 8px;
        width: 6px;
    }



    div /deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }

     div /deep/.el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
        background-color:#a8a8a8;
    }
    
</style>