<template>
    <div id="app">
        <!-- 面包屑导航 -->
        <!-- <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>业务管理</el-breadcrumb-item>
            <el-breadcrumb-item>客户管理</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>

        </el-breadcrumb> -->

        <el-card class="BtnRegion">
              <div class="title_style">
                {{customerName}}
                <span>预存款: <i class="Number">￥{{customerAccount}}</i></span>
                <span>信用消费: <i class="Number">￥{{customerCreditSpend}}</i></span>
                <span>保证金: <i class="Number">￥{{customerDeposit}}</i></span>
            </div>
            <el-button size="mini" class="reverseBtn back" @click="fnBack">返 回</el-button>


            <div class="firstColumnStyle">
                <div class="top">
                    <span class="labelName">
                        扣款类型:
                        <el-select v-model="OrdergetInfo.present_id" size="mini" clearable
                            @change="searchWithCondition">
                            <el-option v-for="item in deductMoneyData" :key="item.id" :label="item.name"
                                :value="item.id">
                                {{item.name}}

                            </el-option>
                        </el-select>
                    </span>

                    <span class="labelName ">
                        信用订单:
                        <el-select v-model="OrdergetInfo.is_credit" size="mini" clearable @change="searchWithCondition">
                            <el-option v-for="item in CreditData" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>


                    </span>

                    <span class="labelName ">
                        仓库:
                        <el-select v-model="OrdergetInfo.store_id" size="mini" clearable @change="searchWithCondition">
                            <el-option v-for="item in storeData" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </span>

                    <span class="labelName ">
                        退货:
                        <el-select v-model="OrdergetInfo.is_return" size="mini" clearable @change="searchWithCondition">
                            <el-option v-for="item in returnData" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </span>
                </div>

                <div class="middle">
                    <span class="labelName">
                        <!-- 日期:
                    <el-date-picker @change="searchWithCondition" size="mini" v-model="dataArea" type="datetimerange"
                        range-separator="至" value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker> -->
                        出单时间:
                        <el-date-picker @change="fnChangeTime" v-model="sBeginTime" type="datetime" size="mini"
                            value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间">
                        </el-date-picker>

                        <i class="toStyle">至</i>

                        <el-date-picker @change="fnChangeTime" v-model="sEndTime" size="mini" type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束时间">
                        </el-date-picker>

                        <span class="sTimeShortCut">
                            <el-button plain :class="nActiveIndex==0?'dateSwitchBtn':''" size="mini"
                                @click="fnInitDateRange">今</el-button>
                            <el-button plain :class="nActiveIndex==1?'dateSwitchBtn':''" size="mini"
                                @click="fnYesterday">昨</el-button>
                            <el-button plain :class="nActiveIndex==2?'dateSwitchBtn':''" size="mini" @click="fnWeek">近一周
                            </el-button>
                            <el-button plain :class="nActiveIndex==3?'dateSwitchBtn':''" size="mini" @click="fnMonth">本月
                            </el-button>
                            <el-button plain :class="nActiveIndex==4?'dateSwitchBtn':''" size="mini" @click="fnYear">本年
                            </el-button>
                            <el-button plain :class="nActiveIndex==5?'dateSwitchBtn':''" size="mini"
                                @click="fnUnlimited">不限时间</el-button>

                        </span>

                    </span>



                </div>

                <div class="middle">
                    <span class="labelName">
                        关键词:
                        <el-input size="mini" class="CPKCInput" placeholder="关键词搜索 目前支持备注搜索"
                            @change="searchWithCondition" v-model="OrdergetInfo.keyword" clearable>
                        </el-input>
                    </span>
                </div>
                <div>









                    <div class="bottom">
                        <span class="labelName">
                            <el-button size="mini" class="frontBtn el-icon-search" @click="searchWithCondition">搜 索
                            </el-button>

                            <el-button size="mini" class="reverseBtn el-icon-refresh" @click="Orderreset">重 置
                            </el-button>

                        </span>


                        <span class="labelName">
                            <el-button size="mini" class="stat2Btn" icon="el-icon-share" @click="fnExportOrder()">导出订单
                            </el-button>
                            <el-button size="mini" class="stat2Btn" icon="el-icon-s-data" @click="fnMoneyStatistics()">
                                金额统计</el-button>
                            <el-button size="mini" class="stat2Btn" icon="el-icon-s-data" @click="fnGoodsStatistics()">
                                商品统计</el-button>


                        </span>

                    </div>



                </div>

            </div>

            <div>
                <!-- <el-button size="mini" class="reverseBtn" icon="el-icon-sort" @click="handWorkExchange">手工换货</el-button> -->
            </div>
        </el-card>

        <el-card class="OrderManagement">

            <!-- <div class="sTopArea">总金额: <span class="Number">￥{{nTotalPrice}}</span> </div> -->
            <el-tabs v-model="activeName" @tab-click="handleClick" class="tabs_style">
                <el-tab-pane label="全部" name="first">
                    <table width="100%" border="1" cellspacing="0">
                        <thead class="sCustomerTheadStyle">
                            <tr class="sCustomerHeadStyle">
                                <th>订单状态</th>
                                <th>下单时间</th>
                                <th>订单号<span style="color:#fa5a57">（双击查看详情）</span></th>
                                <th>订单类型</th>
                                <th>客户<span style="color:#fa5a57">（双击查看详情）</span></th>

                                <th>别名</th>
                                <th>订单金额</th>
                                <th>仓库</th>
                                <th>创建人</th>
                                <th>信用</th>
                                <th>物流</th>
                                <th>打包</th>
                                <th>发货</th>
                                <th>操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(node,index) in OrderManagementData" :key="index" class="sCustomerItemStyle"
                                @click="fnClickItem(node)" :class="nId == node.id ? 'sClickItemStyle' : ''">

                                <td align="center">
                                    {{node.status_name}}
                                </td>
                                <td align="center">
                                    {{node.create_time}}
                                </td>
                                <td align="center" @dblclick="lookOrderDetail(node)">
                                    {{node.order_num}}
                                </td>
                                 <td align="center">
                                   <span v-if="node.is_credit == true && node.is_verify == false" class="NumberNoLeft">{{node.order_type}}</span> 
                                   <span v-else>{{node.order_type}}</span> 
                                    
                                    </td>
                                <td align="center" @dblclick="lookDetail(node)">
                                    {{node.customer_name}}
                                </td>
                                <td align="center">{{node.customer_alias}}</td>

                                <td align="center"><span class="NumberNoLeft">￥{{node.amount_format}}</span></td>
                                <td align="center">{{node.store_name}}</td>
                                <td align="center">{{node.user_name}}</td>

                                <td align="center">
                                    <i v-if="node.is_credit" class="el-icon-check iconCheck"></i>
                                    <i v-else class="el-icon-close iconClose"></i>
                                </td>
                                <td align="center">
                                   {{node.express_type}}
                                    <!-- <i v-if="node.express_type" class="el-icon-check iconCheck"></i>
                                    <i v-else class="el-icon-close iconClose"></i> -->
                                </td>
                                <td align="center">
                                    <span v-show="node.package_time">{{node.package_time}}</span>
                                </td>
                                <td align="center">
                                    <span v-show="node.express_time">{{node.express_time}}</span>
                                </td>
                                <td align="center"><span @click="editOrderDetail(node)" v-show="node.status == 0"
                                        class="sRemoveStyle">修改</span></td>
                            </tr>
                        </tbody>
                    </table>
                </el-tab-pane>
                <el-tab-pane label="待审核" name="second">
                      <table width="100%" border="1" cellspacing="0">
                        <thead class="sCustomerTheadStyle">
                            <tr class="sCustomerHeadStyle">
                                <th>订单状态</th>
                                <th>下单时间</th>
                                <th>订单号<span style="color:#fa5a57">（双击查看详情）</span></th>
                                <th>订单类型</th>
                                <th>客户<span style="color:#fa5a57">（双击查看详情）</span></th>

                                <th>别名</th>
                                <th>订单金额</th>
                                <th>仓库</th>
                                <th>创建人</th>
                                <th>信用</th>
                                <th>物流</th>
                                <th>打包</th>
                                <th>发货</th>
                                <th>操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(node,index) in OrderManagementData" :key="index" class="sCustomerItemStyle"
                                @click="fnClickItem(node)" :class="nId == node.id ? 'sClickItemStyle' : ''">

                                <td align="center">
                                    {{node.status_name}}
                                </td>
                                <td align="center">
                                    {{node.create_time}}
                                </td>
                                <td align="center" @dblclick="lookOrderDetail(node)">
                                    {{node.order_num}}
                                </td>
                                 <td align="center">
                                   <span v-if="node.is_credit == true && node.is_verify == false" class="NumberNoLeft">{{node.order_type}}</span> 
                                   <span v-else>{{node.order_type}}</span> 
                                    
                                    </td>
                                <td align="center" @dblclick="lookDetail(node)">
                                    {{node.customer_name}}
                                </td>
                                <td align="center">{{node.customer_alias}}</td>

                                <td align="center"><span class="NumberNoLeft">￥{{node.amount_format}}</span></td>
                                <td align="center">{{node.store_name}}</td>
                                <td align="center">{{node.user_name}}</td>

                                <td align="center">
                                    <i v-if="node.is_credit" class="el-icon-check iconCheck"></i>
                                    <i v-else class="el-icon-close iconClose"></i>
                                </td>
                                <td align="center">
                                    {{node.express_type}}
                                    <!-- <i v-if="node.express_type" class="el-icon-check iconCheck"></i>
                                    <i v-else class="el-icon-close iconClose"></i> -->
                                </td>
                                <td align="center">
                                    <span v-show="node.package_time">{{node.package_time}}</span>
                                </td>
                                <td align="center">
                                    <span v-show="node.express_time">{{node.express_time}}</span>
                                </td>
                                <td align="center"><span @click="editOrderDetail(node)" v-show="node.status == 0"
                                        class="sRemoveStyle">修改</span></td>
                            </tr>
                        </tbody>
                    </table>
                </el-tab-pane>
                <el-tab-pane label="已审核" name="third">
                    <table width="100%" border="1" cellspacing="0">
                        <thead class="sCustomerTheadStyle">
                            <tr class="sCustomerHeadStyle">
                                <th>订单状态</th>
                                <th>下单时间</th>
                                <th>订单号<span style="color:#fa5a57">（双击查看详情）</span></th>
                                <th>订单类型</th>
                                <th>客户<span style="color:#fa5a57">（双击查看详情）</span></th>

                                <th>别名</th>
                                <th>订单金额</th>
                                <th>仓库</th>
                                <th>创建人</th>
                                <th>信用</th>
                                <th>物流</th>
                                <th>打包</th>
                                <th>发货</th>
                                <th>操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(node,index) in OrderManagementData" :key="index" class="sCustomerItemStyle"
                                @click="fnClickItem(node)" :class="nId == node.id ? 'sClickItemStyle' : ''">

                                <td align="center">
                                    {{node.status_name}}
                                </td>
                                <td align="center">
                                    {{node.create_time}}
                                </td>
                                <td align="center" @dblclick="lookOrderDetail(node)">
                                    {{node.order_num}}
                                </td>
                                 <td align="center">
                                   <span v-if="node.is_credit == true && node.is_verify == false" class="NumberNoLeft">{{node.order_type}}</span> 
                                   <span v-else>{{node.order_type}}</span> 
                                    
                                    </td>
                                <td align="center" @dblclick="lookDetail(node)">
                                    {{node.customer_name}}
                                </td>
                                <td align="center">{{node.customer_alias}}</td>

                                <td align="center"><span class="NumberNoLeft">￥{{node.amount_format}}</span></td>
                                <td align="center">{{node.store_name}}</td>
                                <td align="center">{{node.user_name}}</td>

                                <td align="center">
                                    <i v-if="node.is_credit" class="el-icon-check iconCheck"></i>
                                    <i v-else class="el-icon-close iconClose"></i>
                                </td>
                                <td align="center">
                                    {{node.express_type}}
                                    <!-- <i v-if="node.express_type" class="el-icon-check iconCheck"></i>
                                    <i v-else class="el-icon-close iconClose"></i> -->
                                </td>
                                <td align="center">
                                    <span v-show="node.package_time">{{node.package_time}}</span>
                                </td>
                                <td align="center">
                                    <span v-show="node.express_time">{{node.express_time}}</span>
                                </td>
                                <td align="center"><span @click="editOrderDetail(node)" v-show="node.status == 0"
                                        class="sRemoveStyle">修改</span></td>
                            </tr>
                        </tbody>
                    </table>
                </el-tab-pane>

                <el-tab-pane label="已打包" name="fourth">
                     <table width="100%" border="1" cellspacing="0">
                        <thead class="sCustomerTheadStyle">
                            <tr class="sCustomerHeadStyle">
                                <th>订单状态</th>
                                <th>下单时间</th>
                                <th>订单号<span style="color:#fa5a57">（双击查看详情）</span></th>
                                <th>订单类型</th>
                                <th>客户<span style="color:#fa5a57">（双击查看详情）</span></th>

                                <th>别名</th>
                                <th>订单金额</th>
                                <th>仓库</th>
                                <th>创建人</th>
                                <th>信用</th>
                                <th>物流</th>
                                <th>打包</th>
                                <th>发货</th>
                                <th>操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(node,index) in OrderManagementData" :key="index" class="sCustomerItemStyle"
                                @click="fnClickItem(node)" :class="nId == node.id ? 'sClickItemStyle' : ''">

                                <td align="center">
                                    {{node.status_name}}
                                </td>
                                <td align="center">
                                    {{node.create_time}}
                                </td>
                                <td align="center" @dblclick="lookOrderDetail(node)">
                                    {{node.order_num}}
                                </td>
                                  <td align="center">
                                   <span v-if="node.is_credit == true && node.is_verify == false" class="NumberNoLeft">{{node.order_type}}</span> 
                                   <span v-else>{{node.order_type}}</span> 
                                    
                                    </td>
                                <td align="center" @dblclick="lookDetail(node)">
                                    {{node.customer_name}}
                                </td>
                                <td align="center">{{node.customer_alias}}</td>

                                <td align="center"><span class="NumberNoLeft">￥{{node.amount_format}}</span></td>
                                <td align="center">{{node.store_name}}</td>
                                <td align="center">{{node.user_name}}</td>

                                <td align="center">
                                    <i v-if="node.is_credit" class="el-icon-check iconCheck"></i>
                                    <i v-else class="el-icon-close iconClose"></i>
                                </td>
                                <td align="center">
                                    {{node.express_type}}
                                    <!-- <i v-if="node.express_type" class="el-icon-check iconCheck"></i>
                                    <i v-else class="el-icon-close iconClose"></i> -->
                                </td>
                                <td align="center">
                                    <span v-show="node.package_time">{{node.package_time}}</span>
                                </td>
                                <td align="center">
                                    <span v-show="node.express_time">{{node.express_time}}</span>
                                </td>
                                <td align="center"><span @click="editOrderDetail(node)" v-show="node.status == 0"
                                        class="sRemoveStyle">修改</span></td>
                            </tr>
                        </tbody>
                    </table>
                </el-tab-pane>

                <el-tab-pane label="已发货" name="five">
                    <table width="100%" border="1" cellspacing="0">
                        <thead class="sCustomerTheadStyle">
                            <tr class="sCustomerHeadStyle">
                                <th>订单状态</th>
                                <th>下单时间</th>
                                <th>订单号<span style="color:#fa5a57">（双击查看详情）</span></th>
                                <th>订单类型</th>
                                <th>客户<span style="color:#fa5a57">（双击查看详情）</span></th>

                                <th>别名</th>
                                <th>订单金额</th>
                                <th>仓库</th>
                                <th>创建人</th>
                                <th>信用</th>
                                <th>物流</th>
                                <th>打包</th>
                                <th>发货</th>
                                <th>操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(node,index) in OrderManagementData" :key="index" class="sCustomerItemStyle"
                                @click="fnClickItem(node)" :class="nId == node.id ? 'sClickItemStyle' : ''">

                                <td align="center">
                                    {{node.status_name}}
                                </td>
                                <td align="center">
                                    {{node.create_time}}
                                </td>
                                <td align="center" @dblclick="lookOrderDetail(node)">
                                    {{node.order_num}}
                                </td>
                                 <td align="center">
                                   <span v-if="node.is_credit == true && node.is_verify == false" class="NumberNoLeft">{{node.order_type}}</span> 
                                   <span v-else>{{node.order_type}}</span> 
                                    
                                    </td>
                                <td align="center" @dblclick="lookDetail(node)">
                                    {{node.customer_name}}
                                </td>
                                <td align="center">{{node.customer_alias}}</td>

                                <td align="center"><span class="NumberNoLeft">￥{{node.amount_format}}</span></td>
                                <td align="center">{{node.store_name}}</td>
                                <td align="center">{{node.user_name}}</td>

                                <td align="center">
                                    <i v-if="node.is_credit" class="el-icon-check iconCheck"></i>
                                    <i v-else class="el-icon-close iconClose"></i>
                                </td>
                                <td align="center">
                                    {{node.express_type}}
                                    <!-- <i v-if="node.express_type" class="el-icon-check iconCheck"></i>
                                    <i v-else class="el-icon-close iconClose"></i> -->
                                </td>
                                <td align="center">
                                    <span v-show="node.package_time">{{node.package_time}}</span>
                                </td>
                                <td align="center">
                                    <span v-show="node.express_time">{{node.express_time}}</span>
                                </td>
                                <td align="center"><span @click="editOrderDetail(node)" v-show="node.status == 0"
                                        class="sRemoveStyle">修改</span></td>
                            </tr>
                        </tbody>
                    </table>
                </el-tab-pane>


                <el-tab-pane label=" 已取消" name="seven">
                    <table width="100%" border="1" cellspacing="0">
                        <thead class="sCustomerTheadStyle">
                            <tr class="sCustomerHeadStyle">
                                <th>订单状态</th>
                                <th>下单时间</th>
                                <th>订单号<span style="color:#fa5a57">（双击查看详情）</span></th>
                                <th>订单类型</th>
                                <th>客户<span style="color:#fa5a57">（双击查看详情）</span></th>

                                <th>别名</th>
                                <th>订单金额</th>
                                <th>仓库</th>
                                <th>创建人</th>
                                <th>信用</th>
                                <th>物流</th>
                                <th>打包</th>
                                <th>发货</th>
                                <th>操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(node,index) in OrderManagementData" :key="index" class="sCustomerItemStyle"
                                @click="fnClickItem(node)" :class="nId == node.id ? 'sClickItemStyle' : ''">

                                <td align="center">
                                    {{node.status_name}}
                                </td>
                                <td align="center">
                                    {{node.create_time}}
                                </td>
                                <td align="center" @dblclick="lookOrderDetail(node)">
                                    {{node.order_num}}
                                </td>
                                  <td align="center">
                                   <span v-if="node.is_credit == true && node.is_verify == false" class="NumberNoLeft">{{node.order_type}}</span> 
                                   <span v-else>{{node.order_type}}</span> 
                                    
                                    </td>
                                <td align="center" @dblclick="lookDetail(node)">
                                    {{node.customer_name}}
                                </td>
                                <td align="center">{{node.customer_alias}}</td>

                                <td align="center"><span class="NumberNoLeft">￥{{node.amount_format}}</span></td>
                                <td align="center">{{node.store_name}}</td>
                                <td align="center">{{node.user_name}}</td>

                                <td align="center">
                                    <i v-if="node.is_credit" class="el-icon-check iconCheck"></i>
                                    <i v-else class="el-icon-close iconClose"></i>
                                </td>
                                <td align="center">
                                    {{node.express_type}}
                                    <!-- <i v-if="node.express_type" class="el-icon-check iconCheck"></i>
                                    <i v-else class="el-icon-close iconClose"></i> -->
                                </td>
                                <td align="center">
                                    <span v-show="node.package_time">{{node.package_time}}</span>
                                </td>
                                <td align="center">
                                    <span v-show="node.express_time">{{node.express_time}}</span>
                                </td>
                                <td align="center"><span @click="editOrderDetail(node)" v-show="node.status == 0"
                                        class="sRemoveStyle">修改</span></td>
                            </tr>
                        </tbody>
                    </table>
                </el-tab-pane>
            </el-tabs>



            <el-pagination @size-change="handleSizeChangegetInfo" @current-change="handleCurrentChangegetInfo"
                :current-page="OrdergetInfo.page" :page-sizes="[10]" :page-size="OrdergetInfo.per_page"
                layout="total, sizes, prev, pager, next, jumper" :total="Ordertotal" align="center">
            </el-pagination>
        </el-card>


        <!-- ---------------------------------------------------对话框一层-------------------------------------------------------------------------- -->
        <!-- 查看订单详情对话框 -->
        <el-dialog title="订单详情 " :close-on-click-modal="false" :visible.sync="is_OrderDetail"
            :width="dialogscreen?'95%':'68%'" class="OrderDetail">


            <div class="slideArea_style">
                <div class="sOrderDetailCardStyle">
                    <div class="sOrderDetailtop">
                        <div class="left">
                            <div><i class="el-icon-s-order"></i></div>
                            <div>订单</div>
                            <div>{{DetailData.order_num}}</div>
                            <div>
                                <el-tag size="small">{{DetailData.status_name}}</el-tag>
                            </div>
                        </div>

                        <div class="right">
                            <div class="sBtnsArea">
                                <span v-show="is_examine_btn" @click="examine()">审核订单</span>
                                <span v-show="is_reject_btn" @click="reject()">驳回订单</span>
                                <span v-show="is_cancel_btn" @click="cancel()">取消订单</span>
                                <span v-show="is_writeoff_btn" @click="writeOff()">核销订单</span>
                                <span v-show="is_advanceDepositTocreditOrder"
                                    @click="convertToCreditOrder()">预存款订单转为信用订单</span>
                                <span v-show="is_creditOrder" @click="convertToAdvanceDeposit()">信用订单转为预存款订单</span>
                                <span v-show="is_advanceDepositToDelivery"
                                    @click="convertToDelivery()">预存款订单转为配送订单</span>
                                <span v-show="is_Delivery" @click="PresentconvertToAdvanceDeposit()">配送订单转为预存款订单</span>
                                <span v-show="currentOrderStatus == 3" @click="handleSalesReturn()">手工退货</span>
                                <span v-show="DetailData.status !== 0" @click="printDo">发货单</span>
                                <span @click="fnPreview">预览</span>
                            </div>
                        </div>
                    </div>
                    <el-tabs v-model="sActiveNameofOrderDetail" @tab-click="fnOrderDetailHandleClick">
                        <el-tab-pane label="订单信息" name="first">
                            <el-descriptions title="基本信息：" :column=2>




                                <el-descriptions-item label="客户名称">{{DetailData.customer_name}} </el-descriptions-item>

                                <el-descriptions-item label="订单类型">
                                    {{DetailData.order_type}}
                                </el-descriptions-item>

                                <el-descriptions-item label="订单金额">{{DetailData.amount}} 元</el-descriptions-item>

                                <el-descriptions-item label="信用">
                                    <span v-show="DetailData.is_credit" class="yesColor">是</span>
                                    <span v-show="!DetailData.is_credit" class="noColor">否</span>
                                </el-descriptions-item>

                                <el-descriptions-item label="快递">
                                    <span v-show="DetailData.is_express" class="yesColor">是</span>
                                    <span v-show="!DetailData.is_express" class="noColor">否</span>
                                </el-descriptions-item>

                                <el-descriptions-item label="打包时间">
                                    <span v-show="DetailData.package_time !==0">{{DetailData.package_time}} </span>
                                </el-descriptions-item>

                                <el-descriptions-item label="快递时间">
                                    <span v-show="DetailData.express_time !==0">{{DetailData.express_time}} </span>
                                </el-descriptions-item>


                                <el-descriptions-item label="订单删除时间">
                                    <span v-show="DetailData.delete_time !==0">{{DetailData.delete_time}} </span>
                                </el-descriptions-item>


                                <el-descriptions-item label="存放仓库">{{DetailData.store_name}} </el-descriptions-item>

                                <el-descriptions-item label="门店">
                                    <span v-show="DetailData.shop_name">{{DetailData.shop_name}}</span>
                                </el-descriptions-item>



                                <el-descriptions-item label="订单创建时间">
                                    <span v-show="DetailData.create_time !==0">{{DetailData.create_time}} </span>
                                </el-descriptions-item>

                            </el-descriptions>

                            <el-descriptions :column=1>
                                <el-descriptions-item label="备注">{{DetailData.remarks}} </el-descriptions-item>
                            </el-descriptions>

                            <div class="Order_GoodsList" v-show="DetailData.is_express">
                                <div class="tit">收货地址：</div>
                                <div class="content">
                                    <span>地址: <span>{{DetailDataAddress.region_full_name}} </span>
                                        <span>{{DetailDataAddress.address}}
                                        </span>( {{DetailData.express_type}} )</span>
                                    <span>联系人: <span>{{DetailDataAddress.name}} </span></span>
                                    <span>电话: <span>{{DetailDataAddress.mobile}} </span></span>
                                </div>
                            </div>

                        </el-tab-pane>
                        <el-tab-pane label="包含商品" name="second">
                            <el-table :data="DetailData.goods_list" style="width:100%"
                                :header-cell-style="styleBindFun">
                                <el-table-column prop="goods_sn" label="货号" align="center">
                                </el-table-column>
                                <el-table-column prop="goods_name" label="产品名" align="center" width="330">
                                </el-table-column>
                                <el-table-column label="原单价（元）" align="center">
                                    <template slot-scope="scope">
                                        <span class="NumberNoLeft">{{scope.row.original_price}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="进货单价（元）" align="center">
                                    <template slot-scope="scope">
                                        <span class="NumberNoLeft">{{scope.row.sell_price}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="quantity" label="数量" align="center">
                                </el-table-column>
                            </el-table>

                        </el-tab-pane>
                        <el-tab-pane label="包含产品" name="third">
                            <el-table :data="DetailData.product_list" style="width:100%"
                                :header-cell-style="styleBindFun" border>
                                <el-table-column prop="product_sn" label="产品号" align="center" width="100px">
                                </el-table-column>
                                <el-table-column prop="record" label="备案编号" align="center" width="260px">
                                </el-table-column>
                                <el-table-column prop="goods_name" label="产品名" align="center" width="390px">
                                    <template slot-scope="scope">
                                        {{scope.row.product_name}} <span v-show="scope.row.product_spec">（{{scope.row.product_spec}}）</span> 
                                    </template>
                                </el-table-column>

                                <el-table-column label="原单价（元）" align="center" width="110px">
                                    <template slot-scope="scope">
                                        <span class="NumberNoLeft">{{scope.row.original_price}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="进货单价（元）" align="center" width="120px">
                                    <template slot-scope="scope">
                                        <span class="NumberNoLeft">{{scope.row.sell_price}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="quantity" label="数量" align="center">
                                </el-table-column>
                                <el-table-column prop="return" label="退货" align="center">
                                </el-table-column>
                                <el-table-column fixed="right" label="批次" align="center" width="100px">
                                    <template slot-scope="scope">
                                        <el-dropdown v-show="scope.row.batchs.length>0">
                                            <span class="el-dropdown-link">
                                                <span class="NumberRight">{{scope.row.batchs.length}}</span>批 <i
                                                    class="el-icon-arrow-down el-icon--right"></i>
                                            </span>
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item v-for="list  in scope.row.batchs"
                                                    :key="list.batch_sn">
                                                    {{list.batch_sn}} <span
                                                        class="validityDateStyle">限用日期：{{list.batch_expiry_date}}
                                                        数量：<span class="NumberNoLeft">{{list.quantity}}</span>
                                                    </span>
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </template>
                                </el-table-column>
                            </el-table>

                        </el-tab-pane>

                        <el-tab-pane label="快递" name="fourth">
                            <el-table :data="DetailData.expresss" style="width:100%" :header-cell-style="styleBindFun">
                                <el-table-column prop="express_num" label="快递单号" align="center">
                                    <template slot-scope="scope">
                                        <el-button type='text' class="text_primary" @click="jumpExpress(scope.row)">
                                            {{scope.row.express_num}}</el-button>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="create_time" label="时间" align="center">
                                </el-table-column>
                            </el-table>

                        </el-tab-pane>

                        <el-tab-pane label="订单日志" name="five">
                            <div class="OrderLogsStyle">

                                <el-timeline>
                                    <el-timeline-item v-for="(activity, index) in DetailData.logs" :key="index"
                                        icon="el-icon-check" type="primary" size="activity.large"
                                        :timestamp="activity.update_time">
                                        {{activity.log}}
                                    </el-timeline-item>
                                </el-timeline>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>

            </div>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" class="frontBtn" @click="OrderDetailDo">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 订单预览对话框 -->
        <el-dialog title="订单预览 " :visible.sync="bOrderPreview" top="30px" :width="dialogscreen?'95%':'80%'"
            class="sOrderDetailStyle">

            <div class="sAreaStyle">
                <div ref="content">

                    <!-- 1.基本信息 -->
                    <div class="first">
                        <div class="tit">
                            <span class="sRectangleStyle"></span>
                            基本信息
                        </div>
                        <div class="content">
                            <span>
                                <div class="left">客户名称:</div>
                                <div class="right">{{DetailData.customer_name}} </div>
                            </span>

                            <span>
                                <div class="left">订单类型:</div>
                                <div class="right">{{DetailData.order_type}} </div>
                            </span>

                            <span>
                                <div class="left">订单金额:</div>
                                <div class="right"><i class="NumberNoLeft">￥{{DetailData.amount}}</i> </div>
                            </span>


                            <span>
                                <div class="left">信用:</div>
                                <div class="right">
                                    <div v-show="DetailData.is_credit" class="yesColor">是</div>
                                    <div v-show="!DetailData.is_credit" class="noColor">否</div>
                                </div>
                            </span>


                            <span>
                                <div class="left">快递:</div>
                                <div class="right">
                                    <div v-show="DetailData.is_express" class="yesColor">是</div>
                                    <div v-show="!DetailData.is_express" class="noColor">否</div>
                                </div>
                            </span>




                            <span>
                                <div class="left">打包时间:</div>
                                <div class="right">
                                    <div v-show="DetailData.package_time !==0">{{DetailData.package_time}} </div>
                                </div>
                            </span>



                            <span>
                                <div class="left">快递时间:</div>
                                <div class="right">
                                    <div v-show="DetailData.express_time !==0">{{DetailData.express_time}} </div>
                                </div>
                            </span>
                            <span>
                                <div class="left">订单删除时间:</div>
                                <div class="right">
                                    <div v-show="DetailData.delete_time !==0">{{DetailData.delete_time}} </div>
                                </div>
                            </span>


                            <span>
                                <div class="left">订单创建时间:</div>
                                <div class="right">
                                    <div v-show="DetailData.create_time !==0">{{DetailData.create_time}} </div>
                                </div>
                            </span>

                            <span>
                                <div class="left">门店:</div>
                                <div class="right">
                                    <div v-show="DetailData.shop_name">{{DetailData.shop_name}}</div>
                                </div>
                            </span>

                            <span>
                                <div class="left">存放仓库:</div>
                                <div class="right">{{DetailData.store_name}} </div>
                            </span>

                            <span>
                                <div class="left">备注:</div>
                                <div class="right">{{DetailData.remarks}} </div>
                            </span>
                        </div>
                    </div>

                    <!-- 2.收货信息 -->
                    <div class="second" v-show="DetailData.is_express">
                        <div class="tit">
                            <span class="sRectangleStyle"></span>
                            收货地址
                        </div>
                        <div class="content">
                            <span class="address">地址: <span>{{DetailDataAddress.region_full_name}} </span>
                                <span>{{DetailDataAddress.address}}
                                </span></span>
                            <span class="contact">联系人: <span>{{DetailDataAddress.name}} </span></span>
                            <span class="tel">电话: <span>{{DetailDataAddress.mobile}} </span></span>

                        </div>
                    </div>


                    <!-- 3.包含商品 -->
                    <div class="thirdly">
                        <div class="tit">
                            <span class="sRectangleStyle"></span>
                            包含商品
                        </div>
                        <div class="content sContainNumber">
                            <el-table :data="DetailData.goods_list" style="width:100%"
                                :header-cell-style="styleBindFunOfTwo">
                                <el-table-column prop="goods_sn" label="货号" align="center">
                                </el-table-column>
                                <el-table-column prop="goods_name" label="产品名" align="center" width="330">
                                </el-table-column>
                                <el-table-column label="原单价（元）" align="center">
                                    <template slot-scope="scope">
                                        <span class="NumberNoLeft">{{scope.row.original_price}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="进货单价（元）" align="center">
                                    <template slot-scope="scope">
                                        <span class="NumberNoLeft">{{scope.row.sell_price}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="quantity" label="数量" align="center">
                                </el-table-column>
                            </el-table>
                            <div class="sGoodsNumberStyle">数量： <span class="NumberNoLeft">{{nGoodsContainNumber}}</span> </div>
                        </div>
                    </div>


                    <!-- 4.包含产品 -->
                    <div class="fourthly">
                        <div class="tit">
                            <span class="sRectangleStyle"></span>
                            包含产品
                        </div>
                        <div class="content sContainNumber">
                            <el-table :data="DetailData.product_list" style="width:100%"
                                :header-cell-style="styleBindFunOfTwo">
                                <el-table-column prop="product_sn" label="产品号" align="center" width="100">
                                </el-table-column>
                                <el-table-column prop="record" label="备案编号" align="center" width="260px">
                                </el-table-column>
                                <el-table-column prop="goods_name" label="产品名" align="center" width="330">
                                    <template slot-scope="scope">
                                        {{scope.row.product_name}} （{{scope.row.product_spec}}）
                                    </template>
                                </el-table-column>
                                <el-table-column label="原单价（元）" align="center">
                                    <template slot-scope="scope">
                                        <span class="NumberNoLeft">{{scope.row.original_price}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="进货单价（元）" align="center">
                                    <template slot-scope="scope">
                                        <span class="NumberNoLeft">{{scope.row.sell_price}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="quantity" label="数量" align="center">
                                </el-table-column>
                                <el-table-column prop="return" label="退货" align="center">
                                </el-table-column>
                                <el-table-column fixed="right" label="批次" align="center" width="100">
                                    <template slot-scope="scope">
                                        <el-dropdown v-show="scope.row.batchs.length>0">
                                            <span class="el-dropdown-link">
                                                <span class="NumberRight">{{scope.row.batchs.length}}</span>批 <i
                                                    class="el-icon-arrow-down el-icon--right"></i>
                                            </span>
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item v-for="list  in scope.row.batchs"
                                                    :key="list.batch_sn">
                                                    {{list.batch_sn}} <span
                                                        class="validityDateStyle">限用日期：{{list.batch_expiry_date}}
                                                        数量：<span class="NumberNoLeft">{{list.quantity}}</span>
                                                    </span>
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="sGoodsNumberStyle">数量： <span class="NumberNoLeft">{{nProductContainNumber}}</span> </div>
                        </div>
                    </div>

                </div>

                <!-- 5.快递 -->
                <div class="fifth">
                    <div class="tit">
                        <span class="sRectangleStyle"></span>
                        快递
                    </div>
                    <div class="content">
                        <el-table :data="DetailData.expresss" style="width:100%" :header-cell-style="styleBindFunOfTwo">
                            <el-table-column prop="express_num" label="快递单号" align="center">
                                <template slot-scope="scope">
                                    <el-button type='text' class="text_primary" @click="jumpExpress(scope.row)">
                                        {{scope.row.express_num}}</el-button>
                                </template>
                            </el-table-column>
                            <el-table-column prop="create_time" label="时间" align="center">
                            </el-table-column>
                        </el-table>

                    </div>
                </div>


                <!-- 6.订单日志 -->
                <div class="sixth">
                    <div class="tit">
                        <span class="sRectangleStyle"></span>
                        订单日志
                    </div>
                    <div class="content">
                        <el-timeline>
                            <el-timeline-item v-for="(activity, index) in DetailData.logs" :key="index"
                                icon="el-icon-check" type="primary" size="activity.large"
                                :timestamp="activity.update_time">
                                {{activity.log}}
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                </div>

                <!-- 7.截图 -->
                <div class="seventh">
                    <div class="tit">
                        <span class="sRectangleStyle"></span>
                        截图
                    </div>
                    <div class="content">
                        <el-image class="sScreenshotStyle" style="width: 120px; height: 120px" :src="imgUrl"
                            :preview-src-list="aImgSrcList">
                        </el-image>
                    </div>
                </div>
            </div>



            <div slot="footer" class="dialog-footer">
                <el-button size="mini" class="frontBtn" @click="bOrderPreview = false">确 定</el-button>
            </div>
        </el-dialog>


        <!-- 预存款订单转为信用订单对话框 -->
        <el-dialog title="预存款订单转为信用订单" :visible.sync="is_YCKTurnIntoXY" width="30%">

            <el-form label-width="100px">
                <el-form-item label="添加备注：">
                    <el-input type="textarea" class="TurnIntoremarks" v-model="YCKTurnIntoXY_info.remarks"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="is_YCKTurnIntoXY = false">取 消</el-button>
                <el-button type="primary" @click="YCKTurnIntoXYDo">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 信用订单转为预存款订单对话框 -->
        <el-dialog title="信用订单转为预存款订单" :visible.sync="is_XYTurnIntoYCK" width="30%">

            <el-form label-width="100px">
                <el-form-item label="添加备注：">
                    <el-input type="textarea" class="TurnIntoremarks" v-model="XYTurnIntoYCK_info.remarks"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="is_XYTurnIntoYCK = false">取 消</el-button>
                <el-button type="primary" @click="XYTurnIntoYCKDo">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 驳回订单 -->
        <el-dialog title="驳回订单" :visible.sync="is_reject" width="30%">

            <el-form label-width="100px">
                <el-form-item label="驳回原因：">
                    <el-input type="textarea" class="TurnIntoremarks" size="small" placeholder="请输入缘由"
                        v-model="reject_reason"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_reject = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" @click="rejectDo">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 取消订单 -->
        <el-dialog title="取消订单" :visible.sync="is_cancel" width="30%">

            <el-form label-width="100px">
                <el-form-item label="取消原因：">
                    <el-input type="textarea" class="TurnIntoremarks" size="small" placeholder="请输入缘由"
                        v-model="cancel_reason"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_cancel = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" @click="cancelDo">确 定</el-button>
            </div>
        </el-dialog>


        <!-- 审核订单 -->
        <el-dialog title="审核订单" :visible.sync="is_examine" width="30%">

            <el-form label-width="100px">
                <el-form-item label="缘由：">
                    <el-input type="textarea" class="TurnIntoremarks" size="small" placeholder="请输入缘由"
                        v-model="examine_reason"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_examine = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" @click="examineDo">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 预存款订单转为信用订单 -->
        <el-dialog title="预存款订单转为信用订单" :visible.sync="is_convertToCreditOrder" width="30%">

            <el-form label-width="100px">
                <el-form-item label="缘由：">
                    <el-input type="textarea" class="TurnIntoremarks" size="small" placeholder="请输入缘由"
                        v-model="convertToCreditOrder_reason"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_convertToCreditOrder = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" @click="convertToCreditOrderDo">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 信用订单转为预存款订单 -->
        <el-dialog title="信用订单转为预存款订单" :visible.sync="is_convertToAdvanceDeposit" width="30%">

            <el-form label-width="100px">
                <el-form-item label="缘由：">
                    <el-input type="textarea" class="TurnIntoremarks" size="small" placeholder="请输入缘由"
                        v-model="convertToAdvanceDeposit_reason"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_convertToAdvanceDeposit = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" @click="convertToAdvanceDepositDo">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 信用订单转为预存款订单 -->
        <el-dialog title="预存款订单转为配送订单" :visible.sync="is_convertToDelivery" width="30%">

            <el-form label-width="100px">
                <el-form-item label="配送类型:">
                    <el-select class="convertToDelivery_width" size='small' v-model="convertToDelivery_present_id"
                        clearable placeholder="请选择">
                        <el-option v-for="item in presentsData" :key="item.present_id" :label="item.present_name"
                            :value="item.present_id">
                            {{item.present_name}} 余额:{{item.account}} 依赖: <span v-if="item.is_depend"
                                class="el-icon-check yesColor"></span> <span v-else
                                class="el-icon-close noColor"></span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="缘由：">
                    <el-input type="textarea" class="TurnIntoremarks" size="small" placeholder="请输入缘由"
                        v-model="convertToDelivery_reason"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_convertToDelivery = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" @click="convertToDeliveryDo">确 定</el-button>
            </div>
        </el-dialog>


        <!-- 配送订单转为预存款订单 -->
        <el-dialog title="配送订单转为预存款订单" :visible.sync="is_PresentconvertToAdvanceDeposit" width="30%">

            <el-form label-width="100px">
                <el-form-item label="缘由：">
                    <el-input type="textarea" class="TurnIntoremarks" size="small" placeholder="请输入缘由"
                        v-model="PresentconvertToAdvanceDeposit_reason"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_PresentconvertToAdvanceDeposit = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" @click="PresentconvertToAdvanceDepositDo">确 定</el-button>
            </div>
        </el-dialog>


        <!-- 手工退货对话框 -->
        <el-dialog :close-on-click-modal="false" title="手工退货" :visible.sync="is_handleSalesReturn"
            class="handleSalesReturnStyle" width="75%">
            <div>
                <div class="first">
                    <div><i class="el-icon-info infoStyle"></i>备注:</div>
                    <el-input v-model="form_handleSalesReturn.remarks" type="textarea" placeholder="请填写退货的缘由">
                    </el-input>
                </div>

                <div class="second">
                    <div class="tit"><i class="el-icon-info infoStyle"></i>退货产品:
                        <!-- <span class="btn"><el-button size="mini" class="frontBtn" @click="addProOfSalesReturn">添加产品</el-button></span> -->
                    </div>
                    <el-table :data="salesReturnData" border style="width: 100%" :header-cell-style="styleBindFun"
                        @selection-change="handleSelectionChange" @select="handleSelectChange"
                        @select-all="handleSelectAllChange" ref="multipleSalesReturn" :row-key="getRowKeys">
                        <el-table-column type="selection" width="80" align="center" :reserve-selection="true">
                        </el-table-column>



                        <el-table-column label="产品编号" align="center" width="100">
                            <template slot-scope="scope">
                                {{scope.row.product_sn}}
                            </template>
                        </el-table-column>


                        <el-table-column label="产品" align="center" width="360">
                            <template slot-scope="scope">
                                {{scope.row.product_name}} <span
                                    v-show="scope.row.product_spec">（{{scope.row.product_spec}}）</span>
                            </template>
                        </el-table-column>



                        <el-table-column label="原单价（元）" align="center" width="160">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">{{scope.row.original_price}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="进货单价（元）" align="center" width="160">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">{{scope.row.sell_price}}</span>
                            </template>
                        </el-table-column>



                        <el-table-column prop="quantity" label="数量" align="center" width="130">
                        </el-table-column>

                        <el-table-column label="已退货数量" align="center" width="130">
                            <template slot-scope="scope">
                                <span class="textBtnColor">{{scope.row.return}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="批次" align="center">
                            <template slot-scope="scope">
                                <el-dropdown v-show="scope.row.batchs.length>0">
                                    <span class="el-dropdown-link">
                                        <span class="NumberRight">{{scope.row.batchs.length}}</span>批 <i
                                            class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item v-for="list  in scope.row.batchs" :key="list.batch_sn">
                                            {{list.batch_sn}} <span
                                                class="validityDateStyle">限用日期：{{list.batch_expiry_date}}
                                                数量：<span class="NumberNoLeft">{{list.quantity}}</span>
                                            </span>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </el-table-column>

                        <el-table-column label="退货数量" align="center" width="160">
                            <template slot-scope="scope">
                                <span v-if="scope.row.showReturnNum">
                                    <el-input placeholder="请输入数量" @blur="blurSetReturnNum" size="mini" clearable
                                        v-model="scope.row.returnNum"></el-input>
                                </span>
                                <span v-else>
                                    {{scope.row.returnNum}}
                                </span>

                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <div class="third">
                    <span>退货种类：<span class="NumberRight">{{salesReturnType}}</span></span>
                    <span>退货个数：<span class="NumberRight">{{salesReturnTotal}}</span></span>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_handleSalesReturn = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" type="primary" @click="handleSalesReturnDo">确 定</el-button>
            </div>
        </el-dialog>


        <!-- 选择产品对话框 -->
        <!-- <el-dialog :close-on-click-modal="false" title="选择产品" :visible.sync="is_addPro_SalesReturn" class="addPro_SalesReturn_style" width="65%">
             <div class="searchWithConditionStyle">
                 <div>
                     <span>仓库：</span>
                      <el-select @change="searchWithCondition"  size='small' v-model="addPro_SalesReturn_info.store_id"
                        clearable placeholder="请选择">
                        <el-option v-for="item in storeData" :key="item.id" :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                 </div>

                 <div>
                     <span>关键词：</span>
                     <el-input @change="searchWithCondition" size="small" clearable placeholder="搜索 产品名称 别名 货号 条码" v-model="addPro_SalesReturn_info.keyword"></el-input>
                 </div>

                <div>
                    <el-button size="mini" class="frontBtn el-icon-search" @click="searchWithCondition">搜索</el-button>
                    <el-button size="mini" class="reverseBtn el-icon-refresh" @click="addPro_SalesReturn_reset">重置</el-button>
                </div>
             </div>
              <el-table :data="productsData" border style="width: 100%" :header-cell-style="styleBindFun">
                        <el-table-column label="产品号" align="center" >
                            <template slot-scope="scope">
                                {{scope.row.sn}}
                            </template>
                        </el-table-column>

                          <el-table-column label="待处理时间" align="center" width="120">
                            <template slot-scope="scope">
                                {{scope.row.remain_time}}
                            </template>
                        </el-table-column>

                        <el-table-column label="产品" align="center" width="360">
                            <template slot-scope="scope">
                                {{scope.row.product_name}} <span v-show="scope.row.spec">（{{scope.row.spec}}）</span>
                            </template>
                        </el-table-column>

                          <el-table-column label="数量" align="center" >
                            <template slot-scope="scope">
                                {{scope.row.quantity}} <span v-show="scope.row.unit_name">（{{scope.row.unit_name}}）</span>
                            </template>
                        </el-table-column>



                        <el-table-column label="存放地址" align="center" >
                            <template slot-scope="scope">
                                {{scope.row.store_name}}
                            </template>
                        </el-table-column>

                           <el-table-column label="批次" align="center">
                            <template slot-scope="scope">
                                            <el-dropdown v-show="scope.row.is_batch">
                                                <span class="el-dropdown-link">
                                                   <span class="NumberNoLeft">{{scope.row.batchs.length}}</span>  批<i class="el-icon-arrow-down el-icon--right"></i>
                                                </span>
                                                <el-dropdown-menu slot="dropdown">
                                                    <el-dropdown-item v-for="list  in scope.row.batchs" :key="list.id">
                                                        {{list.name}} <span
                                                            class="validityDateStyle">限用日期：{{list.expiry_date}}
                                                            数量：<span class="NumberNoLeft">{{list.pivot.quantity}}</span>
                                                        </span>
                                                    </el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                        </template>
                        </el-table-column>

                        <el-table-column fixed="right" label="操作"  align="right">
                            <template slot-scope="scope">
                                <el-button type="text" :disabled="scope.row.myChecked" size="medium" @click="editOrderDetail(scope.row)">添加</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click="is_addPro_SalesReturn = false">取 消</el-button>
                <el-button type="primary" @click="addProOfSalesReturnDo">确 定</el-button>
            </div>
        </el-dialog> -->






    </div>
</template>

<script>
    import {
        erpApi
    } from '../../config'

    import {
        domainApi
    } from '../../config'

    import html2canvas from "html2canvas";

    export default {
        data() {
            return {
                customerName: '',
                customerCreditSpend: '',
                customerAccount: '',
                customerDeposit: '',

                dialogscreen: false,
                //订单列表数据
                OrderManagementData: [],

                sBeginTime: "",
                sEndTime: "",
                OrdergetInfo: {
                    customer_number: this.$route.query.data,
                    status: '',
                    present_id: '',
                    is_credit: '',
                    is_return: '',
                    page: 1,
                    per_page: 10,
                    keyword: '',
                    start_time: '',
                    end_time: '',
                    store_id: '',
                },
                Ordertotal: 0,

                // ---------------------------------搜索条件选择参数------------------------------------------------


                CreditData: [{
                        id: 0,
                        name: '否'
                    },
                    {
                        id: 1,
                        name: '是'
                    },
                ],
                returnData: [{
                        id: 0,
                        name: '否'
                    },
                    {
                        id: 1,
                        name: '是'
                    },
                ],
                order_status: '',
                order_present_id: '',
                order_is_credit: '',
                order_keyword: '',

                // ----------------查看订单详情-----------------------------------------------------
                is_OrderDetail: false,
                DetailData: [],
                // -----------------预存款订单转为信用订单---------------------------------------------------------------
                is_YCKTurnIntoXY: false,
                YCKTurnIntoXY_info: {
                    remarks: '',
                    order_num: ''
                },
                // -----------------信用订单转为预存款订单---------------------------------------------------------------
                is_XYTurnIntoYCK: false,
                XYTurnIntoYCK_info: {
                    remarks: '',
                    order_num: ''
                },


                // -----------------数据----------------------
                groupData: [],
                deliverData: [],
                creditData: [],
                discountData: [],
                regionData: [],
                presentsData: [],

                // 订单切换
                activeName: 'first',

                // 驳回订单
                is_reject: false,
                reject_reason: '',

                // 取消订单
                is_cancel: false,
                cancel_reason: '',

                // 审核订单
                is_examine: false,
                examine_reason: '',

                // 按钮状态
                is_advanceDepositTocreditOrder: false,
                is_advanceDepositToDelivery: false,
                is_creditOrder: false,
                is_Delivery: false,


                // 订单转换
                orderConversion_currentnum: '',
                is_convertToCreditOrder: false,
                convertToCreditOrder_reason: '',

                is_convertToAdvanceDeposit: false,
                convertToAdvanceDeposit_reason: '',

                is_convertToDelivery: false,
                convertToDelivery_reason: '',
                convertToDelivery_present_id: '',


                is_PresentconvertToAdvanceDeposit: false,
                PresentconvertToAdvanceDeposit_reason: '',

                currentOrderNumber: '',


                // 日期选择
                dataArea: [],
                storeData: [],



                // 按钮判断
                is_examine_btn: false,
                is_reject_btn: false,
                is_cancel_btn: false,
                is_writeoff_btn: false,

                DetailDataAddress: '',

                // is_btns:true,
                is_expresssData: false,


                // 手工退货---------------------------------------------
                is_handleSalesReturn: false,
                form_handleSalesReturn: {
                    order_num: '',
                    product_list: [],
                    remarks: '',
                },

                is_addPro_SalesReturn: false,
                addPro_SalesReturn_info: {
                    page: 1,
                    per_page: 5,
                    store_id: '',
                    keyword: '',
                },
                productsData: [],
                productsDataTotal: 0,

                // 当前订单状态
                currentOrderStatus: '',

                // 退货部分
                salesReturnData: [],

                salesReturnType: 0,
                salesReturnTotal: 0,

                checkedSalesReturnData: [],

                handleSelectAllChangeCheck: false,

                canSalesReturnNum: 0,

                deductMoneyData: [],

                currentCustomerNumber: '',



                nTotalPrice: 0,

                nId: '',


                sActiveNameofOrderDetail: 'first',

                nActiveIndex: '0',

                bOrderPreview: false,

                imgUrl: '',

                aImgSrcList: [],

                nGoodsContainNumber:'',
                nProductContainNumber:'',



            }
        },
        created() {

            this.getStore()
            this.getDeductMoney()
            this.fnWeek()
            this.fnJudgeScreen()
            this.getCustomerDetail()
            // this.getGroup()
            // this.getDeliver()
            // this.getCredit()
            // this.getDiscount()
            // this.getRegion()

        },

        mounted() {
            window.onresize = () => {
                let width = document.body.clientWidth
                if (width < 1550) {
                    this.dialogscreen = true
                } else {
                    this.dialogscreen = false
                }
            }
        },


        methods: {

            fnJudgeScreen() {
                let width = document.body.clientWidth
                if (width < 1550) {
                    this.dialogscreen = true
                } else {
                    this.dialogscreen = false
                }
            },


            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind = "background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                return styleBind;
            },
            // 绑定表格头部样式方法 ---第二种
            styleBindFunOfTwo() {
                let styleBind = "background:#f2f3f5;fontSize:12px;color:#1D2129;fontWeight: normal;"
                return styleBind;
            },

             // 获取客户详情
            async getCustomerDetail() {
                let params = {
                    customer_number: this.OrdergetInfo.customer_number
                }
                const res = await this.$https.post(erpApi + '/customer/detail?_ajax=1', params)
                this.customerName = res.data.name
                this.customerCreditSpend = res.data.credit_spend
                this.customerAccount = res.data.account
                this.customerDeposit = res.data.deposit
            },

            // 初始化当天日期范围
            fnInitDateRange() {
                let date = new Date();
                let year = date.getFullYear();
                let month = Number(date.getMonth() + 1);
                month = month >= 10 ? month : '0' + month
                let day = Number(date.getDate());
                day = day >= 10 ? day : '0' + day

                // 当天
                let sIntraDayBegin = year + '-' + month + '-' + day + ' ' + '00:00:00'

                let sIntraDayEnd = year + '-' + month + '-' + day + ' ' + '23:59:59'

                this.sBeginTime = sIntraDayBegin
                this.sEndTime = sIntraDayEnd

                this.OrdergetInfo.start_time = this.sBeginTime
                this.OrdergetInfo.end_time = this.sEndTime
                this.OrdergetInfo.page = 1
                this.getOrderList()

                this.nActiveIndex = 0


            },


            fnYesterday() {
                let date = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24);
                let year = date.getFullYear();
                let month = Number(date.getMonth() + 1);
                month = month >= 10 ? month : '0' + month
                let day = Number(date.getDate());
                day = day >= 10 ? day : '0' + day

                // 昨天
                let Begin = year + '-' + month + '-' + day + ' ' + '00:00:00'

                let End = year + '-' + month + '-' + day + ' ' + '23:59:59'

                this.sBeginTime = Begin
                this.sEndTime = End

                this.OrdergetInfo.start_time = this.sBeginTime
                this.OrdergetInfo.end_time = this.sEndTime
                this.OrdergetInfo.page = 1
                this.getOrderList()

                this.nActiveIndex = 1

            },

            fnWeek() {
                let date = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                let year = date.getFullYear();
                let month = Number(date.getMonth() + 1);
                month = month >= 10 ? month : '0' + month
                let day = Number(date.getDate());
                day = day >= 10 ? day : '0' + day


                let date2 = new Date();
                let year2 = date2.getFullYear();
                let month2 = Number(date2.getMonth() + 1);
                month2 = month2 >= 10 ? month2 : '0' + month2
                let day2 = Number(date2.getDate());
                day2 = day2 >= 10 ? day2 : '0' + day2

                // 近一周
                let Begin = year + '-' + month + '-' + day + ' ' + '00:00:00'

                let End = year2 + '-' + month2 + '-' + day2 + ' ' + '23:59:59'

                this.sBeginTime = Begin
                this.sEndTime = End

                this.OrdergetInfo.start_time = this.sBeginTime
                this.OrdergetInfo.end_time = this.sEndTime
                this.OrdergetInfo.page = 1
                this.getOrderList()

                this.nActiveIndex = 2

            },

            fnMonth() {
                let date = new Date();
                let year = date.getFullYear();
                let month = Number(date.getMonth() + 1);
                month = month >= 10 ? month : '0' + month
                let day = Number(date.getDate());
                day = day >= 10 ? day : '0' + day


                let dayBegin = "01"
                // 本月
                let Begin = year + '-' + month + '-' + dayBegin + ' ' + '00:00:00'

                let End = year + '-' + month + '-' + day + ' ' + '23:59:59'

                this.sBeginTime = Begin
                this.sEndTime = End

                this.OrdergetInfo.start_time = this.sBeginTime
                this.OrdergetInfo.end_time = this.sEndTime
                this.OrdergetInfo.page = 1
                this.getOrderList()

                this.nActiveIndex = 3

            },

            fnYear() {
                let date = new Date();
                let year = date.getFullYear();
                let month = Number(date.getMonth() + 1);
                month = month >= 10 ? month : '0' + month
                let day = Number(date.getDate());
                day = day >= 10 ? day : '0' + day


                let dayBegin = "01"
                let monthBegin = "01"
                // 本年
                let Begin = year + '-' + monthBegin + '-' + dayBegin + ' ' + '00:00:00'

                let End = year + '-' + month + '-' + day + ' ' + '23:59:59'

                this.sBeginTime = Begin
                this.sEndTime = End

                this.OrdergetInfo.start_time = this.sBeginTime
                this.OrdergetInfo.end_time = this.sEndTime
                this.OrdergetInfo.page = 1
                this.getOrderList()

                this.nActiveIndex = 4

            },

            fnUnlimited() {
                this.sBeginTime = ''
                this.sEndTime = ''
                this.OrdergetInfo.start_time = this.sBeginTime
                this.OrdergetInfo.end_time = this.sEndTime
                this.searchWithCondition()

                this.nActiveIndex = 5
            },


            fnChangeTime() {
                this.nActiveIndex = 10
            },










            async getStore() {
                const res = await this.$https.post(erpApi + '/store/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.storeData = res.data
            },

            async getPresents() {
                let params = {
                    customer_number: this.currentCustomerNumber
                }
                const res = await this.$https.post(erpApi + '/customer/presents?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.presentsData = res.data

                let obj = {
                    present_id: 0,
                    present_name: '预存款/信用订单'
                }
                this.presentsData.unshift(obj)
            },

            // 获取扣款类型
            async getDeductMoney() {

                const res = await this.$https.post(erpApi + '/present/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.deductMoneyData = res.data

                let obj = {
                    id: 0,
                    name: '预存款/信用订单'
                }
                this.deductMoneyData.unshift(obj)
            },



            searchWithCondition() {

                this.OrdergetInfo.start_time = this.sBeginTime
                this.OrdergetInfo.end_time = this.sEndTime

                if (this.sBeginTime == null) {
                    this.OrdergetInfo.start_time = ''
                }

                if (this.sEndTime == null) {
                    this.OrdergetInfo.end_time = ''
                }

                if (this.sBeginTime == null && this.sEndTime !== null) {
                    return this.$message.error('请把时间选择完整')
                }

                if (this.sBeginTime !== null && this.sEndTime == null) {
                    return this.$message.error('请把时间选择完整')
                }

                if (this.sBeginTime == '' && this.sEndTime !== '') {
                    return this.$message.error('请把时间选择完整')
                }

                if (this.sBeginTime !== '' && this.sEndTime == '') {
                    return this.$message.error('请把时间选择完整')
                }

                this.OrdergetInfo.page = 1
                this.getOrderList()
            },

            //  获取订单列表
            async getOrderList() {

                // if (this.dataArea !== null && this.dataArea.length > 0) {
                //     this.OrdergetInfo.start_time = this.dataArea[0]
                //     this.OrdergetInfo.end_time = this.dataArea[1]
                // } else {
                //     this.OrdergetInfo.start_time = ''
                //     this.OrdergetInfo.end_time = ''
                // }

                let params = this.OrdergetInfo
                const res = await this.$https.post(erpApi + '/customer/orders/index?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.OrderManagementData = res.data.data
                this.Ordertotal = res.data.total
                this.OrderManagementData.forEach(node => {
                    if (node.remain_time !== '') {
                        node.remain_time = this.secTotime(node.remain_time)
                    } else {
                        node.remain_time = ''
                    }
                })

                // 计算订单列表总金额
                this.nTotalPrice = 0
                this.OrderManagementData.forEach(node => {
                    this.nTotalPrice += Number(node.amount)
                })
                this.nTotalPrice = this.nTotalPrice.toFixed(2)

            },


            // 将秒转化成时分秒
            secTotime(my_time) {
                var days = my_time / 1000 / 60 / 60 / 24;
                var daysRound = Math.floor(days);
                var hours = my_time / 1000 / 60 / 60 - (24 * daysRound);
                var hoursRound = Math.floor(hours);
                var minutes = my_time / 1000 / 60 - (24 * 60 * daysRound) - (60 * hoursRound);
                var minutesRound = Math.floor(minutes);
                var seconds = my_time / 1000 - (24 * 60 * 60 * daysRound) - (60 * 60 * hoursRound) - (60 *
                    minutesRound);
                var time = daysRound + '天 ' + hoursRound + '时 ' + minutesRound + '分'
                return time;
            },



            handleSizeChangegetInfo(data) {
                this.OrdergetInfo.per_page = data
                this.getOrderList()
            },
            handleCurrentChangegetInfo(data) {
                this.OrdergetInfo.page = data
                this.getOrderList()
            },



            // ------------------------------搜索与重置----------------------------------------------

            Orderreset() {
                this.OrdergetInfo.present_id = ''
                this.OrdergetInfo.is_credit = ''
                this.OrdergetInfo.keyword = ''
                this.OrdergetInfo.is_return = ''
                this.OrdergetInfo.store_id = ''
                this.fnWeek()
            },

            // ----------------查看订单详情-----------------------------------------------------
            async lookOrderDetail(row) {
                this.orderConversion_currentnum = row.order_num
                this.form_handleSalesReturn.order_num = row.order_num
                this.is_OrderDetail = true
                this.currentOrderNumber = row.order_num
                this.currentCustomerNumber = row.customer_number

                let params = {
                    order_num: row.order_num
                }
                const res = await this.$https.post(erpApi + '/customer/orders/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.DetailData = res.data
                console.log(this.DetailData,'DetailData');
                // 赋值表格个数
                  this.fnValNumberOfGoods( this.DetailData.goods_list)
                  this.fnValNumberOfProduct(this.DetailData.product_list)

                // 赋值状态
                this.currentOrderStatus = this.DetailData.status
                this.is_expresssData = false
                if (this.DetailData.expresss.length > 0) {
                    this.is_expresssData = true
                }

                if (this.DetailData.address !== undefined) {
                    this.DetailDataAddress = this.DetailData.address
                    console.log(this.DetailDataAddress,'this.DetailDataAddress');
                }


                // 状态置空
                this.is_advanceDepositTocreditOrder = false
                this.is_advanceDepositToDelivery = false
                this.is_creditOrder = false
                this.is_Delivery = false
                this.is_examine_btn = false
                this.is_reject_btn = false
                this.is_cancel_btn = false
                this.is_writeoff_btn = false




                // 判断订单类型按钮
                if (this.DetailData.is_credit == false && this.DetailData.present_id == 0 && this.DetailData
                    .status == 0) {
                    this.is_advanceDepositTocreditOrder = true
                    this.is_advanceDepositToDelivery = true
                    this.is_creditOrder = false
                    this.is_Delivery = false
                }

                if (this.DetailData.is_credit == true && this.DetailData.present_id == 0 && this.DetailData
                    .status == 0) {
                    this.is_advanceDepositTocreditOrder = false
                    this.is_advanceDepositToDelivery = false
                    this.is_creditOrder = true
                    this.is_Delivery = false
                }

                if (this.DetailData.present_id > 0 && this.DetailData.status == 0) {
                    this.is_advanceDepositTocreditOrder = false
                    this.is_advanceDepositToDelivery = false
                    this.is_creditOrder = false
                    this.is_Delivery = true
                }



                // 判断订单按钮条件
                if (this.DetailData.status == 0) {
                    this.is_examine_btn = true
                    this.is_reject_btn = true
                    this.is_cancel_btn = false
                }
                if (this.DetailData.status == 1) {
                    this.is_examine_btn = false
                    this.is_reject_btn = false
                    this.is_cancel_btn = true
                }
                if (this.DetailData.is_credit == true &&
                    this.DetailData.present_id == 0 &&
                    this.DetailData.is_verify == false
                ) {
                    this.is_writeoff_btn = true
                }


                // 置空手动退货
                if (this.DetailData.status == '3') {
                    this.form_handleSalesReturn.remarks = ''
                    this.salesReturnData.forEach(node => {
                        this.$nextTick(() => {
                            this.$refs.multipleSalesReturn.toggleRowSelection(node, false)
                        });
                    })
                }


                // 判断横幅区域
                // if(
                //     this.is_examine_btn == false &&
                //     this.is_reject_btn == false &&
                //     this.is_cancel_btn == false &&
                //     this.is_writeoff_btn == false &&
                //     this.is_advanceDepositTocreditOrder == false &&
                //     this.is_creditOrder == false &&
                //     this.is_advanceDepositToDelivery == false &&
                //     this.is_Delivery == false 
                // ){
                //     this.is_btns=false
                // }

                // console.log(this.is_btns,'is_btns');
            },
            async lookOrderDetailAfter() {

                let params = {
                    order_num: this.currentOrderNumber
                }
                const res = await this.$https.post(erpApi + '/customer/orders/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.DetailData = res.data

                 // 赋值表格个数
                  this.fnValNumberOfGoods( this.DetailData.goods_list)
                  this.fnValNumberOfProduct(this.DetailData.product_list)

                // 赋值状态
                this.currentOrderStatus = this.DetailData.status

                this.is_expresssData = false
                if (this.DetailData.expresss.length > 0) {
                    this.is_expresssData = true
                }

                if (this.DetailData.address !== undefined) {
                    this.DetailDataAddress = this.DetailData.address
                }



                // 状态置空
                this.is_advanceDepositTocreditOrder = false
                this.is_advanceDepositToDelivery = false
                this.is_creditOrder = false
                this.is_Delivery = false
                this.is_examine_btn = false
                this.is_reject_btn = false
                this.is_cancel_btn = false
                this.is_writeoff_btn = false



                // 判断订单类型按钮
                if (this.DetailData.is_credit == false && this.DetailData.present_id == 0 && this.DetailData
                    .status == 0) {
                    this.is_advanceDepositTocreditOrder = true
                    this.is_advanceDepositToDelivery = true
                    this.is_creditOrder = false
                    this.is_Delivery = false
                }

                if (this.DetailData.is_credit == true && this.DetailData.present_id == 0 && this.DetailData
                    .status == 0) {
                    this.is_advanceDepositTocreditOrder = false
                    this.is_advanceDepositToDelivery = false
                    this.is_creditOrder = true
                    this.is_Delivery = false
                }

                if (this.DetailData.present_id > 0 && this.DetailData.status == 0) {
                    this.is_advanceDepositTocreditOrder = false
                    this.is_advanceDepositToDelivery = false
                    this.is_creditOrder = false
                    this.is_Delivery = true
                }


                // 判断订单按钮条件
                if (this.DetailData.status == 0) {
                    this.is_examine_btn = true
                    this.is_reject_btn = true
                    this.is_cancel_btn = false
                } else {
                    this.is_examine_btn = false
                    this.is_reject_btn = false
                    this.is_cancel_btn = false
                }
                if (this.DetailData.status == 1) {
                    this.is_examine_btn = false
                    this.is_reject_btn = false
                    this.is_cancel_btn = true
                }
                if (this.DetailData.is_credit == true &&
                    this.DetailData.present_id == 0 &&
                    this.DetailData.is_verify == false
                ) {
                    this.is_writeoff_btn = true
                }
            },

            // 赋值个数
            fnValNumberOfGoods(data){
            console.log(data.length,'dsd');
               this.nGoodsContainNumber=data.length
            },
            fnValNumberOfProduct(data){
               this.nProductContainNumber=data.length
            },

            OrderDetailDo() {
                this.getOrderList()
                this.is_OrderDetail = false
            },

            // -----------------新建订单---------------------------------------------------------------
            addNewOrder() {
                this.$router.push({
                    path: '/addOrder',
                    query: {
                        data: this.$route.query.data
                    }
                })
            },

            // -----------------修改订单---------------------------------------------------------------
            editOrderDetail(row) {

                row.customer_number = row.customer_number
                this.$router.push({
                    path: '/editOrder',
                    query: {
                        data: row
                    }
                })
            },

            // -----------------审核订单---------------------------------------------------------------
            examine() {
                this.examine_reason = ''
                this.is_examine = true
            },
            async examineDo() {
                let params = {
                    order_num: this.currentOrderNumber,
                    remarks: this.examine_reason,
                }
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/orders/audit?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.lookOrderDetailAfter()
                this.is_examine = false
            },
            // -----------------驳回订单---------------------------------------------------------------
            reject() {
                this.reject_reason = ''
                this.is_reject = true
            },
            async rejectDo() {
                let params = {
                    order_num: this.currentOrderNumber,
                    remarks: this.reject_reason,
                }
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/orders/reject?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_reject = false
                this.OrderDetailDo()

            },
            // -----------------取消订单---------------------------------------------------------------
            cancel(row) {
                this.cancel_reason = ''
                this.is_cancel = true
            },
            async cancelDo() {
                let params = {
                    order_num: this.currentOrderNumber,
                    remarks: this.cancel_reason,
                }
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/orders/cancel?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.lookOrderDetailAfter()
                this.is_cancel = false

            },

            //--------------------核销订单-------------------------------------------- 
            writeOff() {
                this.$confirm('确定核销此订单?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    .then(async () => {
                        let params = {
                            order_num: this.currentOrderNumber
                        }
                        const {
                            data
                        } = await this.$https.post(erpApi + '/customer/orders/verify?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)


                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: '已取消删除'
                        })
                    })
            },


            // -----------------预存款订单转为信用订单---------------------------------------------------------------
            YCKTurnIntoXY(row) {
                this.is_YCKTurnIntoXY = true
                this.YCKTurnIntoXY_info.order_num = row.order_num
            },

            async YCKTurnIntoXYDo() {
                this.is_YCKTurnIntoXY = false
                let parmas = this.YCKTurnIntoXY_info
                const {
                    data
                } = await this.$https.post('/erp/customer/orders/credit?_ajax=1', parmas)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
            },

            // -----------------信用订单转为预存款订单---------------------------------------------------------------
            XYTurnIntoYCK(row) {
                this.is_XYTurnIntoYCK = true
                this.XYTurnIntoYCK_info.order_num = row.order_num
            },

            async XYTurnIntoYCKDo() {
                this.is_YCKTurnIntoXY = false
                let parmas = this.XYTurnIntoYCK_info
                const {
                    data
                } = await this.$https.post('/erp/customer/orders/uncredit?_ajax=1', parmas)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
            },



            // ------------------------------手工换货-------------------------------------------------
            handWorkExchange() {
                this.$router.push({
                    path: '/handWorkExchangeGoods',
                    query: {
                        data: this.$route.query.data
                    }
                })
            },

            // 订单状态切换
            handleClick(data) {
                if (data.index == 0) {
                    this.OrdergetInfo.status = ''
                    this.OrdergetInfo.page = 1

                    this.getOrderList()
                }

                if (data.index == 1) {
                    this.OrdergetInfo.status = 0
                    this.OrdergetInfo.page = 1

                    this.getOrderList()
                }

                if (data.index == 2) {
                    this.OrdergetInfo.status = 1
                    this.OrdergetInfo.page = 1

                    this.getOrderList()
                }

                if (data.index == 3) {
                    this.OrdergetInfo.status = 2
                    this.OrdergetInfo.page = 1

                    this.getOrderList()
                }

                if (data.index == 4) {
                    this.OrdergetInfo.status = 3
                    this.OrdergetInfo.page = 1

                    this.getOrderList()
                }

                if (data.index == 5) {
                    this.OrdergetInfo.status = -1
                    this.OrdergetInfo.page = 1

                    this.getOrderList()
                }


            },

            // 预存款订单转为信用订单
            convertToCreditOrder() {
                this.convertToCreditOrder_reason = ''
                this.is_convertToCreditOrder = true
            },

            async convertToCreditOrderDo() {
                let params = {
                    order_num: this.orderConversion_currentnum,
                    remarks: this.convertToCreditOrder_reason,
                }
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/orders/account_to_credit?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.lookOrderDetailAfter()
                this.is_convertToCreditOrder = false
            },

            convertToAdvanceDeposit() {
                this.convertToAdvanceDeposit_reason = ''
                this.is_convertToAdvanceDeposit = true
            },
            async convertToAdvanceDepositDo() {
                let params = {
                    order_num: this.orderConversion_currentnum,
                    remarks: this.convertToAdvanceDeposit_reason,
                }
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/orders/credit_to_account?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.lookOrderDetailAfter()
                this.is_convertToAdvanceDeposit = false
            },

            convertToDelivery() {
                this.getPresents()
                this.convertToDelivery_reason = ''
                this.convertToDelivery_present_id = ''
                this.is_convertToDelivery = true

            },
            async convertToDeliveryDo() {
                let params = {
                    order_num: this.orderConversion_currentnum,
                    remarks: this.convertToDelivery_reason,
                    present_id: this.convertToDelivery_present_id,
                }
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/orders/account_to_present?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.lookOrderDetailAfter()
                this.is_convertToDelivery = false
            },

            PresentconvertToAdvanceDeposit() {
                this.PresentconvertToAdvanceDeposit_reason = ''
                this.is_PresentconvertToAdvanceDeposit = true
            },
            async PresentconvertToAdvanceDepositDo() {
                let params = {
                    order_num: this.orderConversion_currentnum,
                    remarks: this.PresentconvertToAdvanceDeposit_reason,
                }
                const {
                    data
                } = await this.$https.post(erpApi + '/customer/orders/present_to_account?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.lookOrderDetailAfter()
                this.is_PresentconvertToAdvanceDeposit = false
            },


            // 查看快递详情
            jumpExpress(row) {
                let orderNum = this.currentOrderNumber
                let expressNum = row.express_num
                let url = "https://api.rainben.cn/express/detail?num="  + expressNum
                
                window.open(url)

            },

            // ----------手工退货------------------------------------------------------------------------------------
            handleSalesReturn() {
                this.is_handleSalesReturn = true
                this.salesReturnData = this.DetailData.product_list


            },
            async handleSalesReturnDo() {
                this.form_handleSalesReturn.product_list = []
                this.checkedSalesReturnData.forEach(node => {
                    if (node.returnNum == '' || node.returnNum == 0 || node.returnNum == null) return this
                        .$message.error('退货数量必须大于0')
                    let obj = {
                        product_id: node.product_id,
                        quantity: node.returnNum
                    }
                    this.form_handleSalesReturn.product_list.push(obj)
                })

                let params = this.form_handleSalesReturn
                const res = await this.$https.post(erpApi + '/customer/orders/return?_ajax=1', params)
                console.log(res, 'res12323');
                if (res.data.error_code !== 0) return this.$message.error(res.data.msg)
                this.$message.success(res.data.msg)
                this.getOrderList()
                this.is_handleSalesReturn = false
            },


            handleSelectChange(selection, row) {
                row.showReturnNum = !row.showReturnNum
                if (!row.showReturnNum) {
                    row.returnNum = ''
                }
                this.calcSalesReturn()
            },

            handleSelectAllChange(selection) {
                if (selection.length == 0) {
                    this.handleSelectAllChangeCheck = false
                } else {
                    this.handleSelectAllChangeCheck = true
                }
                if (this.handleSelectAllChangeCheck) {
                    this.salesReturnData.forEach(node => {
                        node.showReturnNum = true
                    })
                } else {
                    this.salesReturnData.forEach(node => {
                        node.showReturnNum = false
                        node.returnNum = ''
                    })
                }

                this.calcSalesReturn()

            },



            handleSelectionChange(selection) {
                this.checkedSalesReturnData = selection
                this.calcSalesReturn()
            },

            getRowKeys(row) {
                return row.id
            },

            calcSalesReturn() {
                this.salesReturnType = 0
                this.salesReturnTotal = 0
                // 种类数
                this.salesReturnType = this.checkedSalesReturnData.length

                // 个数
                this.checkedSalesReturnData.forEach(node => {
                    this.salesReturnTotal += Number(node.returnNum)
                })
            },

            blurSetReturnNum() {
                let flag = this.checkIsNoMoreNum()
                if (flag) {
                    this.calcSalesReturn()
                }
            },

            checkIsNoMoreNum() {
                let flag = true
                this.checkedSalesReturnData.forEach(node => {
                    let value = node.quantity - node.return
                    if (value < node.returnNum) {
                        flag = false
                        return this.$message.error('退货数量超过可选数量，请重新输入')
                    }
                })
                return flag
            },


            // -----------------------查看客户详情数据--------------------------------------
            async lookDetail(row) {
                let routeData = this.$router.resolve({
                    path: '/customerDetail',
                    query: {
                        data: row.customer_number,

                    }
                })
                window.open(routeData.href, '_blank')
            },

            // 跳转打印单
            printDo() {

                let number = this.currentOrderNumber
                window.open('/printContent.html?number=' + number)
            },


            // ---------------------------------------金额统计---------------------------------------------------------------
            fnMoneyStatistics() {
                let customer_number = this.OrdergetInfo.customer_number
                let present_id = this.OrdergetInfo.present_id
                let is_credit = this.OrdergetInfo.is_credit
                let store_id = this.OrdergetInfo.store_id
                let start_time = this.OrdergetInfo.start_time
                let end_time = this.OrdergetInfo.end_time
                let status = this.OrdergetInfo.status

                window.location.href = erpApi + "/orders/down/amounts?_ajax=1&customer_number=" + customer_number +
                    "&present_id=" + present_id + "&is_credit=" + is_credit + "&store_id=" + store_id + "&start_time=" +
                    start_time +
                    "&end_time=" + end_time + "&status=" + status
            },


            // ---------------------------------------商品统计---------------------------------------------------------------
            fnGoodsStatistics() {
                let customer_number = ''
                let present_id = this.OrdergetInfo.present_id
                let is_credit = this.OrdergetInfo.is_credit
                let store_id = this.OrdergetInfo.store_id
                let is_return =  this.OrdergetInfo.is_return
                let start_time = this.OrdergetInfo.start_time
                let end_time = this.OrdergetInfo.end_time
                let status = this.OrdergetInfo.status
                let keyword = this.OrdergetInfo.keyword


                window.location.href = erpApi + "/orders/down/goods?_ajax=1&customer_number=" + customer_number +
                    "&present_id=" + present_id + "&is_credit=" + is_credit + "&store_id=" + store_id + "&start_time=" +
                    start_time +
                    "&end_time=" + end_time + "&status=" + status  + "&is_return=" + is_return + "&keyword=" + keyword
            },


            // ------------------------------------------导出订单-----------------------------------------------------
            fnExportOrder() {
                let customer_number = this.OrdergetInfo.customer_number
                let present_id = this.OrdergetInfo.present_id
                let is_credit = this.OrdergetInfo.is_credit
                let store_id = this.OrdergetInfo.store_id
                let start_time = this.OrdergetInfo.start_time
                let end_time = this.OrdergetInfo.end_time
                let status = this.OrdergetInfo.status
                let keyword = this.OrdergetInfo.keyword
                let is_return = this.OrdergetInfo.is_return

                window.location.href = erpApi + "/orders/down/order_details?_ajax=1&customer_number=" +
                    customer_number + '&keyword' + keyword +
                    "&present_id=" + present_id + "&is_credit=" + is_credit + "&store_id=" + store_id + "&start_time=" +
                    start_time +
                    "&end_time=" + end_time + "&status=" + status + "&is_return=" + is_return
            },




            fnClickItem(node) {
                this.nId = node.id
            },


            fnOrderDetailHandleClick(data) {
                if (data.index == 0) {
                    this.sActiveNameofOrderDetail = 'first'
                }
                if (data.index == 1) {
                    this.sActiveNameofOrderDetail = 'second'
                }
                if (data.index == 2) {
                    this.sActiveNameofOrderDetail = 'third'
                }
                if (data.index == 3) {
                    this.sActiveNameofOrderDetail = 'fourth'
                }
                if (data.index == 4) {
                    this.sActiveNameofOrderDetail = 'five'
                }
            },



            fnPreview() {
                this.bOrderPreview = true
                setTimeout(() => {
                    this.fnExportImg()
                }, 300)
            },


            fnExportImg() {
                let h = this.$refs.content.scrollHeight;
                // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
                html2canvas(this.$refs.content, {
                    height: h + 20,
                    backgroundColor: '#fff',
                    useCORS: true,
                    scale: 1,
                }).then((canvas) => {
                    let url = canvas.toDataURL("image/png");
                    this.imgUrl = url
                    this.aImgSrcList = []
                    this.aImgSrcList.push(url)
                })

            },









            //     addProOfSalesReturn(){
            //         this.is_addPro_SalesReturn=true
            //         this.getProducts()
            //     },

            //     addProOfSalesReturnDo(){

            //     },

            //    async getProducts(){
            //          let params = this.addPro_SalesReturn_info
            //         const res = await this.$https.post(erpApi + '/storage/product/select?_ajax=1', params)
            //         if(res.status !== 200) return this.$message.error(res.statusText)
            //         console.log(res,'dara');
            //         this.productsData=res.data.data
            //         this.productsDataTotal=res.data.total
            //     },

            //     searchWithCondition(){
            //         this.addPro_SalesReturn_info.page=1
            //         this.getProducts()
            //     },

            //     addPro_SalesReturn_reset(){
            //          this.addPro_SalesReturn_info.page=1
            //          this.addPro_SalesReturn_info.store_id=''
            //          this.addPro_SalesReturn_info.keyword=''
            //         this.getProducts()
            //     },

            // 返回
            fnBack() {
                 this.$router.go(-1)
            }



        },
    }
</script>

<style lang="less" scoped>
    .OrderManagement {
        position: relative;

    }


    .back {
        position: absolute;
        right: 5%;
        top: 10%;
    }




    .Order_GoodsList {
        margin: 20px 0;
    }

    .tit {
        font-size: 16px;
        color: #303133;
        font-weight: 550;
        margin-bottom: 5px;
    }

    .Order_GoodsList .content {
        margin-top: 20px;
        display: flex;
    }

    .Order_GoodsList .content span {
        flex: 1;
        margin-right: 20px;
        color: #333;
        font-size: 14px;

    }


    .Order_GoodsList .content span span {
        color: #606266;
        font-size: 15px;
        margin-left: 5px;
    }

    .Order_GoodsList .content span:first-child {
        flex: 3;

    }

    /deep/.el-textarea__inner {
        width: 390px;
    }

    /deep/.el-dropdown-item {
        display: flex;
        justify-content: center;
    }

    .BtnRegion {
        margin-bottom: 10px;
        position: relative;
    }

    .BtnRegion .title_style span {
        margin: 0 60px;
        color: #606266;
        font-size: 14px;
        font-weight: normal;
    }





    .el-timeline {
        margin-top: 20px;
    }

    /* el-table 滚动条样式 */
    /deep/.el-table__body-wrapper:hover::-webkit-scrollbar {}

    /deep/.el-table__body-wrapper::-webkit-scrollbar {
        height: 10px;
        width: 6px;
    }



    /deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }

    /deep/.el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
        background-color: #a8a8a8;
    }


    .slideArea_style {
        max-height: 550px;
        overflow-y: scroll;
        padding-top: 10px;
        padding-left: 5px;
        padding-right: 5px;
    }

    /* el-table 滚动条样式 */
    .slideArea_style::-webkit-scrollbar {
        height: 8px;
        width: 6px;
    }



    .slideArea_style::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }

    .slideArea_style::-webkit-scrollbar-thumb:hover {
        background-color: #a8a8a8;
    }



    .OrderDetail .btns {
        margin-bottom: 20px;
    }

    .title_style {
        font-weight: bolder;
        font-size: 18px;
        margin-bottom: 20px;
    }

    .convertToDelivery_width {
        width: 100%
    }

    .btns {
        // background-color: #f5f7fa;
        // padding: 5px 10px;
        // border-radius: 5px;
    }

    .firstColumnStyle {
        display: flex;
        flex-wrap: wrap;
        background-color: #f5f7fa;
        padding: 10px 20px 0px 20px;
    }

    .firstColumnStyle .top,
    .firstColumnStyle .middle,
    .firstColumnStyle .bottom {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 12px;

    }


    .firstColumnStyle .bottom {
        margin-top: 5px;
    }

    .firstColumnStyle .top .labelName,
    .firstColumnStyle .middle .labelName,
    .firstColumnStyle .bottom .labelName {

        font-size: 14px;
        color: #333;
        margin-right: 30px;
        display: flex;
        align-items: center;
    }

    .firstColumnStyle .middle .sKeywordStyle {
        // width: 200px;
    }



    .smallScreen .labelName {
        // width: 33%;
    }

    .CPKCInput {
        width: 240px;
    }

    .validityDateStyle {
        font-size: 12px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .OrderLogsStyle {
        height: 350px;
        overflow-y: scroll;
    }

    /*  滚动条样式 */
    .OrderLogsStyle::-webkit-scrollbar {
        height: 8px;
        width: 6px;
    }



    .OrderLogsStyle::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }

    .OrderLogsStyle::-webkit-scrollbar-thumb:hover {
        background-color: #a8a8a8;
    }

    .handleSalesReturnStyle div {
        width: 100%;
    }

    .handleSalesReturnStyle div .first {
        width: 100%;

    }

    .handleSalesReturnStyle div .first div {
        font-weight: 600;
        font-size: 16px;
        color: #333;

        margin-bottom: 10px;
    }

    .handleSalesReturnStyle div .second {
        margin-top: 30px;

    }

    .handleSalesReturnStyle div .second .tit {
        font-weight: 600;
        font-size: 16px;
        color: #333;
    }


    div /deep/.handleSalesReturnStyle div .second .el-table {
        margin-top: 10px;
    }

    .handleSalesReturnStyle div .second div .btn {
        float: right;
    }

    .handleSalesReturnStyle .third {
        margin-top: 10px;
        display: flex;
        justify-content: end;
        margin-right: 20px;
    }

    .handleSalesReturnStyle .third span {
        margin-right: 10px;
    }

    .handleSalesReturnStyle .first div,
    .handleSalesReturnStyle .second .tit {
        display: flex;
    }


    div /deep/ .addPro_SalesReturn_style .el-dialog__body {
        display: flex;
        flex-direction: column;
    }

    .addPro_SalesReturn_style .searchWithConditionStyle {
        background-color: #e9f2ff;
        display: flex;
        border-radius: 5px;
        padding: 10px 20px;
    }

    .searchWithConditionStyle div {
        width: 30%;
        margin-right: 20px;
        display: flex;
        align-items: center;
    }

    .infoStyle {
        color: #1f6eef;
        font-size: 16px;
        display: flex;
        align-items: center;
        margin-right: 5px;
    }

    .el-range-editor.el-input__inner {
        width: 80%;
    }

    .sTopArea {
        font-size: 14px;
        position: absolute;
        right: 50px;
        top: 30px;
    }

    .sOrderDetailtop {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .sOrderDetailtop div {
        display: flex;
    }

    .sOrderDetailtop .left div:nth-child(1) {
        width: 36px;
        height: 36px;
        background-color: #409EFF;
        border-radius: 3px;
        font-size: 20px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sOrderDetailtop div div:nth-child(2) {
        font-size: 24px;
        color: #323a4d;
        margin: 0 10px;
        font-weight: bolder;
    }

    .sOrderDetailtop div div:nth-child(3) {
        font-size: 18px;
        color: #333;
        display: flex;
        align-items: center;
        font-weight: 540;
        margin-right: 10px;
    }

    .sOrderDetailtop div div:nth-child(4) {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sOrderDetailtop .right .sBtnsArea {
        display: flex;
        color: #333;
        font-size: 12px;
        border: 1px solid #dce0e2;
        border-radius: 5px;
    }

    .sOrderDetailtop .right .sBtnsArea span {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        color: #777c86;
        border-right: solid 1px #dce0e2;
    }


    .sOrderDetailtop .right .sBtnsArea span:hover {
        background-color: #154599;
        color: #fff;
        cursor: pointer;
    }


    div /deep/ .el-descriptions .el-descriptions__body .el-descriptions__table {
        border: none !important;
    }

    div /deep/ .timerSelectStyle .el-input__inner {
        width: 50%;
    }



    div /deep/ .sOrderDetailStyle .el-dialog__header {
        padding: 10px 20px;
        font-weight: bolder;
    }

    div /deep/ .sOrderDetailStyle .el-dialog__body {
        display: flex;
        flex-direction: column;
    }

    div /deep/ .sOrderDetailStyle .sAreaStyle .thirdly .content .el-table__header tr,
    div /deep/ .sOrderDetailStyle .sAreaStyle .thirdly .content .el-table__header th,
    div /deep/ .sOrderDetailStyle .sAreaStyle .fourthly .content .el-table__header tr,
    div /deep/ .sOrderDetailStyle .sAreaStyle .fourthly .content .el-table__header th,
    div /deep/ .sOrderDetailStyle .sAreaStyle .fifth .content .el-table__header tr,
    div /deep/ .sOrderDetailStyle .sAreaStyle .fifth .content .el-table__header th {
        padding: 5px;
    }

    /* el-table 滚动条样式 */
    div /deep/ .sOrderDetailStyle .el-dialog__body::-webkit-scrollbar {
        height: 8px;
        width: 8px;
    }



    div /deep/ .sOrderDetailStyle .el-dialog__body::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }

    div /deep/ .sOrderDetailStyle .el-dialog__body::-webkit-scrollbar-thumb:hover {
        background-color: #a8a8a8;
    }



    .sOrderDetailStyle .sAreaStyle {
        height: 680px;
    }

    .sOrderDetailStyle .sAreaStyle .thirdly,
    .sOrderDetailStyle .sAreaStyle .fifth,
    .sOrderDetailStyle .sAreaStyle .sixth,
    .sOrderDetailStyle .sAreaStyle .seventh,
    .sOrderDetailStyle .sAreaStyle .fourthly {
        margin-top: 10px;
    }


    .sOrderDetailStyle .sAreaStyle .first .tit,
    .sOrderDetailStyle .sAreaStyle .second .tit,
    .sOrderDetailStyle .sAreaStyle .fourthly .tit,
    .sOrderDetailStyle .sAreaStyle .fifth .tit,
    .sOrderDetailStyle .sAreaStyle .sixth .tit,
    .sOrderDetailStyle .sAreaStyle .seventh .tit,
    .sOrderDetailStyle .sAreaStyle .thirdly .tit {

        font-size: 14px;
        color: #333;
        display: flex;
        align-items: center;
    }


    .sOrderDetailStyle .sAreaStyle .first .content,
    .sOrderDetailStyle .sAreaStyle .second .content,
    .sOrderDetailStyle .sAreaStyle .fourthly .content,
    .sOrderDetailStyle .sAreaStyle .fifth .content,
    .sOrderDetailStyle .sAreaStyle .sixth .content,
    .sOrderDetailStyle .sAreaStyle .seventh .content,
    .sOrderDetailStyle .sAreaStyle .thirdly .content {
        display: flex;
        flex-wrap: wrap;
        padding: 6px 20px;
    }

    .sOrderDetailStyle .sAreaStyle .thirdly .content,
    .sOrderDetailStyle .sAreaStyle .fifth .content,
    .sOrderDetailStyle .sAreaStyle .sixth .content,
    .sOrderDetailStyle .sAreaStyle .seventh .content,
    .sOrderDetailStyle .sAreaStyle .fourthly .content {
        padding: 0px 20px;
    }

    .sOrderDetailStyle .sAreaStyle .first .content span {
        width: 33%;
        display: flex;
        margin-bottom: 10px;
        font-size: 12px;
    }

    .sOrderDetailStyle .sAreaStyle .first .content span .left {
        margin-right: 12px;
    }

    .sOrderDetailStyle .sAreaStyle .second .content .address,
    .sOrderDetailStyle .sAreaStyle .second .content .contact,
    .sOrderDetailStyle .sAreaStyle .second .content .tel {
        margin-right: 50px;
    }


    div /deep/ .sOrderDetailStyle .sAreaStyle .thirdly .content .el-table,
    div /deep/ .sOrderDetailStyle .sAreaStyle .fifth .content .el-table,
    div /deep/ .sOrderDetailStyle .sAreaStyle .sixth .content .el-table,
    div /deep/ .sOrderDetailStyle .sAreaStyle .fourthly .content .el-table {
        margin-top: 10px;
    }

    .sScreenshotStyle {
        margin-top: 10px;
    }

    .sContainNumber{
        display: flex;
        flex-direction: column;
    }

    .sGoodsNumberStyle{
        margin-top: 10px;
        padding-left: 90%;
    }
</style>