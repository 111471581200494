<template>
    <div id="app">
        <!-- 布局 -->
        <div class="purchaseLayout">

            <!-- 面包屑导航 -->
            <!-- <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>员工管理</el-breadcrumb-item>
            </el-breadcrumb> -->



            <!-- 右侧数据栏 -->
            <el-card>
                <!-- 左边菜单页 -->
                <div class="left">
                    <!-- 输入框 -->
                    <el-input clearable placeholder="搜索成员或部门" v-model="department_input"
                        :disabled="is__framework__search__state" class="inputSearch" @keyup.enter.native="searchStaff"
                        size="small" prefix-icon="el-icon-search">
                    </el-input>


                    <!-- 选项切换 -->
                    <el-tabs align="center" stretch @tab-click="label_Show">
                        <el-tab-pane label="组织架构">
                            <el-tree :data="department_data" node-key="id" ref="departTree" accordion
                                :props="defaultProps" @node-click="handleNodeClick"
                                :default-expanded-keys="departmentIdArr">
                                <span class="custom-tree-node" slot-scope="{ data }" @mouseenter="mouseenter(data)"
                                    @mouseleave="mouseleave(data)">
                                    <!-- <i class="el-icon-s-fold iconColor"></i> -->
                                    <i class="iconfont icon-24gf-folderMinus iconColor"></i>
                                    <span class="departmentName">{{ data.name }}</span>
                                </span>
                            </el-tree>
                        </el-tab-pane>
                        <el-tab-pane label="标签">
                            <ul class="label_UL--style">
                                <li v-for="item in label_data" :key="item.id" class="labelintro"
                                    :class="current_label_id==item.id?'labelintrohover':''"
                                    @click="currentLabel(item.id)">
                                    <i class="iconfont icon-24gf-tags4"></i>
                                    {{item.name}}
                                </li>
                            </ul>
                            <div class="addLabel">
                                <el-button class="frontBtn" plain size="small" icon="el-icon-plus" @click="addLabel">
                                    添加标签
                                </el-button>
                            </div>
                        </el-tab-pane>


                    </el-tabs>
                </div>


                <!-- 右边数据区 -->
                <div class="right" v-show="active_label == 0">
                    <!-- 标题 -->
                    <div class="titleDepartmentStyle">{{current_department}}
                        <span v-show="is_peopletotal">
                            {{'('+current_department_number+'人'+')'}}
                        </span>
                    </div>
                    <!-- 按钮区 -->
                    <div class="buttons">
                        <el-button size="mini" @click="addStaff">添加员工</el-button>
                        <el-button size="mini" @click="addBranch">添加子部门</el-button>
                        <el-button size="mini" @click="editDepartment">修改部门</el-button>
                        <el-button size="mini" @click="deleteDepartment">删除部门</el-button>
                        <el-button size="mini" class="recycle" @click="recycle">回收站</el-button>
                    </div>
                    <!-- 数据表格 -->
                    <el-table :data="current_department_data" style="width: 100%" border class="staff_data"
                        :header-cell-style="styleBindFun">
                        <el-table-column prop="userid" label="工号" width="120" align="center">
                        </el-table-column>
                        <el-table-column prop="name" label="姓名" width="180" align="center">
                            <template slot-scope="scope">
                                <div class="staff_name">
                                    {{scope.row.name}}
                                    <i v-show="scope.row.is_leader" class="iconfont icon-fuzeren"></i>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="alias" label="别名" width="120" align="center">
                        </el-table-column>
                        <el-table-column prop="position_name" label="职务" align="center">
                        </el-table-column>
                        <el-table-column prop="department_full_name" label="部门" align="center" width="390">
                        </el-table-column>
                        <el-table-column prop="mobile" label="手机" align="center">
                        </el-table-column>

                        <el-table-column prop="is_enable" label="是否启用" align="center" width="120">
                            <template slot-scope="scope">
                                <el-switch size="mini" v-model="scope.row.is_enable" @change="editState(scope.row)">
                                </el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" label="操作" align="center">
                            <template slot-scope="scope">
                                <!-- <el-button @click="editStaff(scope.row)" class="textBtnColor" type="text" size="mini">编
                                    辑</el-button>
                                <span class="splitLineClass"></span> -->
                                <el-button @click="lookStaffDetail(scope.row)" class="textBtnColor" type="text"
                                    size="small">查看</el-button>
                                <span class="splitLineClass"></span>
                                <el-button @click="removeRecycle(scope.row)" class="textBtnColor" type="text"
                                    size="mini">移至回收站
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>

                    <!-- 分页器 -->
                    <el-pagination v-show="is_pagination" @size-change="handleSizeChange"
                        @current-change="handleCurrentChange" :current-page="staff_info.page"
                        :page-sizes="[5, 10, 20, 50]" :page-size="staff_info.per_page"
                        layout="total, sizes, prev, pager, next, jumper" :total="current_department_number"
                        align="center">
                    </el-pagination>
                </div>

                <div class="right" v-show="active_label == 1">
                    <!-- 标题 -->
                    <h2>{{current_label}}
                        <span>
                            {{'('+current_label_number+'人'+')'}}
                        </span>
                    </h2>
                    <!-- 按钮区 -->
                    <div class="buttons">

                        <el-button size="mini" @click="editLabel">修改</el-button>
                        <el-button size="mini" @click="deleteLabel">删除</el-button>
                        <el-button size="mini" @click="addDepartmentAndMember">添加部门/成员</el-button>
                        <el-button size="mini" @click="setJob">职务设置</el-button>

                    </div>
                    <!-- 数据表格 -->
                    <el-table :data="current_label_data" style="width: 100%" border class="staff_data"
                        :header-cell-style="styleBindFun">
                        <el-table-column prop="name" label="名称" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.full_name"> <i
                                        class="iconfont icon-24gf-folderMinus label__icon--style"></i>{{scope.row.full_name}}</span>
                                <span v-else>{{scope.row.name}} - {{scope.row.position_name}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column prop="department_full_name" label="部门" align="center">
                        </el-table-column>

                        <el-table-column prop="position_name" label="职位" align="center">
                        </el-table-column>


                        <el-table-column fixed="right" label="操作" align="center">
                            <!-- <template slot-scope="scope">
                                 <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                                <el-button type="text" size="small">编辑</el-button> -->
                            <!-- </template> -->
                        </el-table-column>
                    </el-table>

                </div>

                <!-- 添加员工区域 -->
                <div class="addStaffArea addLabelStyle" v-show="is_add_staff_area">
                    <div class="tit">
                        添加员工
                        <el-button size="mini" class="reverseBtn addStaffGoBackBtn" @click="addStaffBackBtn">返 回
                        </el-button>
                    </div>
                    <div class="content">
                        <el-form :model="form_add_staff" :rules="rules_add_staff">
                            <el-form-item label="姓名：" :label-width="formLabelWidth" prop="name">
                                <el-input size="small" class="input-add-staff" v-model="form_add_staff.name"
                                    autocomplete="off"></el-input>
                            </el-form-item>

                            <el-form-item label="别名：" :label-width="formLabelWidth">
                                <el-input size="small" class="input-add-staff" v-model="form_add_staff.alias"
                                    autocomplete="off"></el-input>
                            </el-form-item>

                            <el-form-item label="手机号：" :label-width="formLabelWidth" prop="mobile">
                                <el-input size="small" class="input-add-staff" v-model="form_add_staff.mobile"
                                    autocomplete="off"></el-input>
                            </el-form-item>


                            <el-form-item label="性别：" :label-width="formLabelWidth">
                                <el-radio v-model="form_add_staff.gender" label="男"></el-radio>
                                <el-radio v-model="form_add_staff.gender" label="女"></el-radio>
                            </el-form-item>

                            <el-form-item label="邮箱：" :label-width="formLabelWidth">
                                <el-input size="small" class="input-add-staff" v-model="form_add_staff.email"
                                    autocomplete="off"></el-input>
                            </el-form-item>

                             <el-form-item label="企业邮箱：" :label-width="formLabelWidth">
                                <el-input size="small" class="input-add-staff" v-model="form_add_staff.email"
                                    autocomplete="off"></el-input>
                            </el-form-item>

                            <el-form-item label="座机号：" :label-width="formLabelWidth">
                                <el-input size="small" class="input-add-staff" v-model="form_add_staff.telephone"
                                    autocomplete="off"></el-input>
                            </el-form-item>

                            <el-form-item v-for="item in attributesData" :key="item.id" :label="item.name+':'"
                                :label-width="formLabelWidth">
                                <el-date-picker size="small" v-model="date_onBoard" format="yyyy 年 MM 月 dd 日"
                                    value-format="yyyy-MM-dd" placeholder="选择日期">
                                </el-date-picker>
                            </el-form-item>


                            <el-form-item label="部门：" :label-width="formLabelWidth" prop="department_id">

                                <el-cascader class="department_choose_style" id="el-cascader" size="small"
                                    v-model="form_add_staff.department" :options="department_data"
                                    :props="{value:'id',label:'name' ,expandTrigger: 'hover',checkStrictly: true}">
                                </el-cascader>

                            </el-form-item>

                            <el-form-item label="职位：" :label-width="formLabelWidth" prop="position_id">

                                <el-dropdown size="small" split-button @command="editPositionAdd"
                                    :type="form_add_staff.position?'primary':''">
                                    {{form_add_staff.position?form_add_staff.position:'未填写'}}
                                    <el-dropdown-menu slot="dropdown" class="position_dropdown">
                                        <el-dropdown-item :command="item" v-for="item in positionData" :key="item.id">
                                            {{item.name}}
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </el-form-item>


                            <el-form-item label="是否负责人：" :label-width="formLabelWidth">
                                <el-radio v-model="form_add_staff.is_leader" label="0">否</el-radio>
                                <el-radio v-model="form_add_staff.is_leader" label="1">是</el-radio>

                            </el-form-item>

                            <el-form-item label="是否启用：" :label-width="formLabelWidth">
                                <el-radio v-model="form_add_staff.is_enable" label="0">否</el-radio>
                                <el-radio v-model="form_add_staff.is_enable" label="1">是</el-radio>

                            </el-form-item>


                        </el-form>
                        <div class="addStaffSubmit">
                            <el-button size="mini" class="frontBtn" @click="addStaffSubmitDo">提 交</el-button>
                        </div>
                    </div>


                </div>

                <!-- 查看员工详情区域 -->
                <div class="seeStaffArea addLabelStyle" v-show="is_staff_detail">
                    <div class="tit">
                        员工详情
                        <el-button size="mini" v-show="!is_edit_user" class="reverseBtn addStaffGoBackBtn"
                            @click="seeStaffBackBtn">返 回
                        </el-button>
                    </div>
                    <div class="content">
                        <div class="functionZon" v-show="!is_edit_user">
                            <el-button size="mini" @click="editUser">编辑</el-button>
                            <!-- <el-button size="mini" @click="removeRecycle">禁用</el-button> -->
                            <el-button size="mini" @click="bindUserGroups">修改员工的客户组权限</el-button>
                            <el-button size="mini" @click="editUserCustomer">修改客户权限</el-button>
                            <el-button size="mini" @click="editUserShop">修改门店权限</el-button>
                            <el-button size="mini" @click="setSuperior">设置上级领导</el-button>
                            <!-- <el-button size="mini" @click="setSubordinate">设置下属</el-button> -->

                        </div>

                        <div class="functionZon" v-show="is_edit_user">
                            <el-button size="mini" @click="editUserSaveDo" class="saveBtn"
                                v-loading.fullscreen.lock="bfullscreen">保存</el-button>
                            <el-button size="mini" @click="cancelEditUser">取消</el-button>
                        </div>
                        <div class="first">
                            <div>
                                <span v-show="!is_edit_user">
                                    {{staff_detail_data.name}}
                                </span>
                                <span v-show="is_edit_user" class="editUserNameStyle">
                                    姓名：
                                    <el-form :rules="rules_edit_user" :model="form_edit_user">
                                        <el-form-item prop="name">
                                            <el-input class="editUserInputStyle" placeholder="请修改名称" size="mini"
                                                v-model="form_edit_user.name" clearable
                                                @keyup.native="verifyEditUserName">
                                            </el-input>
                                        </el-form-item>
                                    </el-form>
                                </span>
                            </div>
                            <div v-show="!is_edit_user">
                                <span v-show="staff_detail_data.alias">
                                    别名：<span>{{staff_detail_data.alias}}</span>
                                </span>
                            </div>
                            <div>
                                帐号：{{staff_detail_data.userid}}
                            </div>
                        </div>
                        <div class="second">
                            <div>
                                <span>手机：</span>
                                <span v-show="!is_edit_user">{{staff_detail_data.mobile}}</span>
                                <span v-show="is_edit_user">
                                    <el-form :rules="rules_edit_user" :model="form_edit_user">
                                        <el-form-item prop="mobile">
                                            <el-input class="editUserInputStyle" placeholder="请修改手机号" size="mini"
                                                v-model="form_edit_user.mobile" clearable>
                                            </el-input>
                                        </el-form-item>
                                    </el-form>
                                </span>

                            </div>
                            <div>
                                <span>座机：</span>
                                <span v-show="!is_edit_user">
                                    <span v-if="staff_detail_data.telephone">{{staff_detail_data.telephone}}</span>
                                    <span v-else>未设置</span>
                                </span>
                                <span v-show="is_edit_user">
                                    <el-form :rules="rules_edit_user" :model="form_edit_user">
                                        <el-form-item prop="telephone">
                                            <el-input class="editUserInputStyle" placeholder="请修改座机号" size="mini"
                                                v-model="form_edit_user.telephone" clearable>
                                            </el-input>
                                        </el-form-item>
                                    </el-form>
                                </span>
                            </div>
                            <div>
                                <span>邮箱：</span>
                                <span v-show="!is_edit_user">
                                    <span v-if="staff_detail_data.email">{{staff_detail_data.email}}</span>
                                    <span v-else>未设置</span>
                                </span>
                                <span v-show="is_edit_user">
                                    <el-form :rules="rules_edit_user" :model="form_edit_user">
                                        <el-form-item prop="email">
                                            <el-input class="editUserInputStyle" placeholder="请修改邮箱" size="mini"
                                                v-model="form_edit_user.email" clearable>
                                            </el-input>
                                        </el-form-item>
                                    </el-form>
                                </span>
                            </div>
                            <!-- <div>
                                <span v-for="node in staff_detail_data.attributes" :key="node.id">
                                    {{node.name}}：
                                    <span v-show="!is_edit_user" style="color:#000">{{node.value}}</span>
                                    <span v-show="is_edit_user">
                                        <el-date-picker size="small" v-model="edit_user_date_onBoard"
                                            format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" placeholder="选择日期">
                                        </el-date-picker>
                                    </span>
                                </span>
                            </div> -->

                        </div>
                        <div class="third">
                            <div>
                                <span>部门：</span>
                                <span v-show="!is_edit_user">{{staff_detail_data.department_full_name}}</span>
                                <span v-show="is_edit_user">
                                    <el-cascader class="editUserDepartmentStyle" id="el-cascader" size="small"
                                        v-model="edit_user_department" :options="department_data"
                                        :show-all-levels="true"
                                        :props="{value:'id',label:'name',expandTrigger: 'hover',checkStrictly: true }">
                                    </el-cascader>
                                </span>
                            </div>
                            <div>
                                <span>标签：</span>
                                <span v-for="node in staff_detail_data.tags" :key="node.id" class="tagNodeStyle"
                                    :class="is_edit_user?'editTagNodeStyle':''"><i
                                        class="iconfont icon-24gf-tags4 editUserIconStyle"></i>{{node.name}}
                                </span>
                                <span class="editUserTextStyle" v-show="is_edit_user" @click="setUserTag">修改</span>
                            </div>

                            <div>
                                <span>职务：</span>
                                <span v-show="!is_edit_user">
                                    <span v-if="staff_detail_data.position_name"
                                        class="text_000">{{staff_detail_data.position_name}}</span>
                                    <span v-else>未设置</span>
                                </span>

                                <span v-show="is_edit_user">
                                    <el-select size="small"  filterable remote v-model="form_edit_user.position_id" clearable
                                        placeholder="请选择" :remote-method="remoteMethod" :loading="loading" @clear="fnClearPosition">
                                        <el-option v-for="item in positionData" :key="item.id" :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>    

                                </span>

                            </div>

                            <div>
                                <span v-show="!is_edit_user">
                                    <span>部门负责人：</span>
                                    <span v-if="staff_detail_data.is_leader">是</span>
                                    <span v-else style="color:#f56c86">否</span>
                                </span>

                                <span v-show="is_edit_user" class="edit_user_is_leader_style">
                                    <span class="spanTit">部门负责人：</span>
                                    <span>
                                        <el-radio-group v-model="form_edit_user.is_leader">
                                            <el-radio :label="false">否</el-radio>
                                            <el-radio :label="true">是</el-radio>
                                        </el-radio-group>
                                    </span>
                                </span>
                            </div>

                            <div>
                                <span v-show="!is_edit_user">
                                    <span>是否启用：</span>
                                    <span v-if="staff_detail_data.is_enable">是</span>
                                    <span v-else style="color:#f56c86">否</span>
                                </span>

                                <span v-show="is_edit_user">
                                    <span class="spanTit">是否启用：</span>
                                    <el-radio-group v-model="form_edit_user.is_enable">
                                        <el-radio :label="false">否</el-radio>
                                        <el-radio :label="true">是</el-radio>

                                    </el-radio-group>

                                </span>
                            </div>

                        </div>
                        <div class="fourthly">
                            <div>
                                <span>所属上级：</span>
                                <span v-for="node in staff_detail_data.leaders" :key="node.id" style="color:#fb5430">
                                    {{node.name}}
                                </span>
                                <!-- <span v-show="staff_detail_data.leaders.length == 0">未设置</span> -->

                            </div>
                            <div>
                                <span>所属下级：</span>

                                <span v-for="node in staff_detail_data.subordinates" :key="node.id"
                                    style=" font-size: 14px;color:#3969bd">
                                    {{node.name}}
                                </span>
                                <!-- <span v-show="staff_detail_data.subordinates.length == 0">未设置</span> -->
                            </div>

                            <div>
                                <span>客户组权限：</span>
                                <span v-for="node in staff_detail_data.groups" :key="node.id">
                                    {{node.name}}
                                </span>
                                <!-- <span v-show="staff_detail_data.groups.length == 0">--</span> -->
                            </div>

                            <div>
                                <span>仓库权限：</span>
                                <span v-for="node in staff_detail_data.stores" :key="node.id">
                                    {{node.name}}
                                </span>
                                <!-- <span v-show="staff_detail_data.stores.length==0">--</span> -->

                            </div>

                            <div>
                                <span>客户权限：</span>
                                <span v-for="node in staff_detail_data.customers" :key="node.id">
                                    {{node.name}}
                                </span>
                                <!-- <span v-show="staff_detail_data.customers.length==0">--</span> -->

                            </div>

                            <div>
                                <span>门店权限：</span>
                                <span v-for="node in staff_detail_data.shops" :key="node.id">
                                    {{node.name}}
                                </span>
                                <!-- <span v-show="staff_detail_data.shops.length==0">--</span> -->
                            </div>

                        </div>
                    </div>


                </div>

                <!-- 添加标签 -->
                <div class="right addLabelStyle" v-show="is_add_label">
                    <div class="tit">
                        <p class="addLabelTitleStyle">添加标签</p>
                        <el-button size="mini" class="reverseBtn backbtnlabel" @click="goBack">返回</el-button>
                    </div>
                    <el-form :model="add_label_form" label-width="120px" :rules="rules_add_label">
                        <el-form-item label="标签名：" prop="name">
                            <el-input size="medium" v-model="add_label_form.name" class="addLabeinput"></el-input>
                        </el-form-item>
                        <el-form-item label="排序：">
                            <el-input size="medium" v-model="add_label_form.sort_order" class="addLabeinput"
                                placeholder="排序依据 范围 0~200 默认100 值大的排序靠前">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="操作权限：">
                            <ul class="authority">
                                <li v-for="item in menuData" :key="item.id" class="authorityLi">
                                    <div class="authorityStyle">
                                        <span class="authorityStyleTit">{{item.name}}</span>
                                    </div>

                                    <div style="margin-bottom:20px">
                                        <div v-for="(node,nodeIndex) in item.children" :key="node.id"
                                            style="display:block;margin-top:10px;margin-bottom:0px;margin-left:20px">
                                            <div class="authoritySonStyle">
                                                <span class="authoritySonStyleTit">{{node.name}}</span>

                                                <el-checkbox v-model="node.mychecked"
                                                    :indeterminate="node.isIndeterminate"
                                                    @change="handleCheckAllChange(node,nodeIndex)">全选
                                                </el-checkbox>


                                            </div>
                                            <div style="display:inline-block;margin-bottom:10px;margin-left:20px">

                                                <el-checkbox v-for="list in node.children" :key="list.id"
                                                    v-model="list.mychecked" :label="list.name"
                                                    @change="handleCheckChange(node,node.children)">
                                                </el-checkbox>

                                            </div>
                                            <div class="labelList"></div>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                        </el-form-item>
                    </el-form>

                    <el-button size="mini" class="frontBtn submitBtn" @click="submitLabelDo()">提 交</el-button>
                </div>

                <!-- 修改标签 -->
                <div class="right addLabelStyle" v-show="is_edit_label">
                    <div class="tit">
                        <p class="addLabelTitleStyle">修改标签</p>
                        <el-button size="mini" class="reverseBtn backbtnlabel" @click="goBackEditLabel">返回</el-button>
                    </div>
                    <el-form :model="add_label_form" label-width="120px" :rules="rules_add_label">
                        <el-form-item label="标签名：" prop="name">
                            <el-input v-model="add_edit_form.name" class="addLabeinput"></el-input>
                        </el-form-item>
                        <el-form-item label="排序：">
                            <el-input v-model="add_edit_form.sort_order" class="addLabeinput"
                                placeholder="排序依据 范围 0~200 默认100 值大的排序靠前">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="操作权限：">
                            <ul class="authority">
                                <li v-for="item in menuData" :key="item.id" class="authorityLi">
                                    <div class="authorityStyle">
                                        <span class="authorityStyleTit">{{item.name}}</span>
                                    </div>

                                    <div style="margin-bottom:20px">
                                        <div v-for="(node,nodeIndex) in item.children" :key="node.id"
                                            style="display:block;margin-top:10px;margin-bottom:0px;margin-left:20px">
                                            <div class="authoritySonStyle">
                                                <span class="authoritySonStyleTit">{{node.name}}</span>

                                                <el-checkbox v-model="node.mychecked"
                                                    :indeterminate="node.isIndeterminate"
                                                    @change="handleCheckAllChange(node,nodeIndex)">全选
                                                </el-checkbox>


                                            </div>
                                            <div style="display:inline-block;margin-bottom:10px;margin-left:20px">

                                                <el-checkbox v-for="list in node.children" :key="list.id"
                                                    v-model="list.mychecked" :label="list.name"
                                                    @change="handleCheckChange(node,node.children)">
                                                </el-checkbox>

                                            </div>
                                            <div class="labelList"></div>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                        </el-form-item>
                    </el-form>

                    <el-button size="mini" class="frontBtn submitBtn" @click="editSubmitLabelDo">提 交</el-button>
                </div>



            </el-card>

            <!-- 添加子部门对话框 -->
            <el-dialog title="增加子部门" :visible.sync="dialogFormVisible_branch" width="30%">
                <el-form :model="form_branch">
                    <el-form-item label="当前部门：" :label-width="formLabelWidth">
                        <h3 class="form_branch_name_father">{{form_branch.form_branch_name_father}}</h3>
                    </el-form-item>
                    <el-form-item label="添加部门：" :label-width="formLabelWidth">
                        <el-input size="small" v-model="form_branch.form_branch_name_son" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="dialogFormVisible_branch = false">取 消</el-button>
                    <el-button size="mini" class="frontBtn" @click="addBranchDo">确 定</el-button>
                </div>
            </el-dialog>


            <!-- 修改部门名称对话框 -->
            <el-dialog title="修改部门" :visible.sync="dialogFormVisible_edit" width="30%" class="seditDepartStyle">
                <el-form :model="form_edit" class="seditDepartFormStyle">

                    <el-form-item label="部门名称：" label-width="120px">
                        <el-input size="small" placeholder="不修改可不填" v-model="form_edit.form_edit_name_before"
                            autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="上级部门：" label-width="120px">
                        <el-cascader clearable size="mini" v-model="form_edit.form_edit_parent_id"
                            :options="aDepartments" :show-all-levels="true"
                            :props="{value:'id',label:'name', expandTrigger: 'hover',checkStrictly: true }">
                        </el-cascader>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="dialogFormVisible_edit = false">取 消</el-button>
                    <el-button size="mini" class="frontBtn" @click="editDepartmentDo">确 定</el-button>
                </div>
            </el-dialog>




            <!-- 修改员工信息 -->
            <el-dialog title="修改员工信息" :visible.sync="dialogFormVisible_edit_staff" width="30%">
                <el-form :model="form_edit_staff" :rules="rules_edit_staff">
                    <el-form-item label="姓名：" :label-width="formLabelWidth" prop="name">
                        <el-input v-model="form_edit_staff.name" autocomplete="off"></el-input>
                    </el-form-item>

                    <el-form-item label="职务：" :label-width="formLabelWidth" prop="position_id">
                        <el-dropdown size="small" split-button @command="editPosition"
                            :type="form_edit_staff.position?'primary':''">
                            {{form_edit_staff.position?form_edit_staff.position:'未填写'}}
                            <el-dropdown-menu slot="dropdown" class="position_dropdown">
                                <el-dropdown-item :command="item" v-for="item in positionData" :key="item.id">
                                    {{item.name}}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-form-item>

                    <el-form-item label="部门：" :label-width="formLabelWidth" prop="department_id">
                        <el-cascader id="el-cascader" v-model="currentEditDepartment" :options="department_data"
                            :props="{value:'id',label:'name',checkStrictly: true }" @change="handleChangeCascader">
                        </el-cascader>
                    </el-form-item>

                    <el-form-item label="手机：" :label-width="formLabelWidth" prop="mobile">
                        <el-input v-model="form_edit_staff.mobile" autocomplete="off"></el-input>

                    </el-form-item>

                    <el-form-item label="邮箱：" :label-width="formLabelWidth">
                        <el-input v-model="form_edit_staff.email" autocomplete="off"></el-input>

                    </el-form-item>

                    <el-form-item label="座机：" :label-width="formLabelWidth">
                        <el-input v-model="form_edit_staff.telephone" autocomplete="off"></el-input>

                    </el-form-item>

                    <el-form-item label="是否负责人：" :label-width="formLabelWidth">
                        <el-radio v-model="form_edit_staff.is_leader" label="0">否</el-radio>
                        <el-radio v-model="form_edit_staff.is_leader" label="1">是</el-radio>
                    </el-form-item>

                    <el-form-item label="是否启用：" :label-width="formLabelWidth" prop="enable">
                        <el-radio v-model="form_edit_staff.is_enable" label="0">否</el-radio>
                        <el-radio v-model="form_edit_staff.is_enable" label="1">是</el-radio>
                    </el-form-item>


                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible_edit_staff = false">取 消</el-button>
                    <el-button type="primary" @click="editStaffDo">确 定</el-button>
                </div>
            </el-dialog>

            <!-- 增加员工信息 -->
            <el-dialog title="增加员工信息" :visible.sync="dialogFormVisible_add_staff" width="30%">

                <div slot="footer" class="dialog-footer">
                    <el-button size="medium" @click="dialogFormVisible_add_staff = false">取 消</el-button>
                    <el-button size="medium" type="primary" @click="addStaffDo">确 定</el-button>
                </div>
            </el-dialog>



            <!-- 添加直属领导对话框 -->
            <el-dialog title="选择成员直属领导" :visible.sync="dialogFormVisible_add_leaders" width="36%"
                class="addLeaderStyle">
                <div class="all">
                    <div class="left">
                        <el-tree :data="department_member_data" accordion :props="defaultProps" class="addDAMElTree"
                            node-key="name" :default-expanded-keys="departmentIdArr"
                            @node-click="handleNodeClick_add_leaders">
                            <span class="DAM-tree-node" slot-scope="{data}">
                                <i :class="data.id?'el-icon-s-fold':'el-icon-s-custom'" class="DAMStyle"></i>
                                <span class="departmentName">{{ data.name }}</span>
                                <i :class="data.mychecked==true?'el-icon-check':' '" class="ckeckStyle"></i>
                            </span>
                        </el-tree>
                    </div>
                    <div class="right">
                        <div class="tit">已选择的领导：</div>
                        <ul>
                            <li v-for="(node,index) in selected_Leaders_Data" :key="index" class="itemStyle">
                                <i :class="node.id?'el-icon-s-fold':'el-icon-s-custom'" class="DAMStyle"></i>
                                <span class="departmentName">{{ node.name }}</span>
                                <i class='el-icon-close closeIcon' @click="deleteSelectedLeaders(node)"></i>

                            </li>
                        </ul>
                    </div>
                </div>

                <div slot="footer" class="dialog-footer">
                    <el-button size="medium" type="primary" @click="dialogFormVisible_add_leaders = false">确 定
                    </el-button>
                </div>
            </el-dialog>



            <!-- 回收站对话框 -->
            <el-drawer title="回收站" :visible.sync="drawer" :direction="direction" :before-close="handleCloseDrawer"
                :size="drawerSize">
                <el-table :data="recycle_data" stripe style="width: 100%" border class="staff_data"
                    :header-cell-style="styleBindFun">
                    <el-table-column prop="name" label="姓名" width="180" align="center">
                    </el-table-column>
                    <el-table-column prop="department_name" label="部门" width="180" align="center">
                    </el-table-column>
                    <el-table-column prop="position_name" label="职务" align="center" width="180">
                    </el-table-column>
                    <el-table-column fixed="right" prop="address" label="操作" align="center" width="200">
                        <template slot-scope="scope">
                            <el-button @click="recover(scope.row)" type="text" size="mini">恢复</el-button>
                            <span class="splitLineClass"></span>
                            <el-button @click="deleteRecycle(scope.row)" type="text" class="deleteTextBtn" size="mini">
                                删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>


                <!-- 分页器 -->
                <el-pagination @current-change="handleCurrent__recycleBin__Change" :current-page="Form__recycleBin.page"
                    :page-sizes="[10]" :page-size="Form__recycleBin.per_page"
                    layout="total, sizes, prev, pager, next, jumper" :total="Form__recycleBin__total" align="center">
                </el-pagination>
            </el-drawer>









            <!-- 职务部分 ------------------------------------  -->
            <el-drawer title="职务列表" :visible.sync="job_drawer" :direction="direction" :size="drawerSize">
                <el-button size="small" class="frontBtn createJob" icon="el-icon-plus" @click="createJob">新建职务
                </el-button>

                <el-table :data="job_data" stripe style="width: 100%" border class="staff_data" 
                    :header-cell-style="styleBindFun">
                    <el-table-column prop="name" label="姓名" align="center" width="170">
                    </el-table-column>
                    <el-table-column prop="users_count" label="在岗员工人数" align="center">
                    </el-table-column>
                   
                    <el-table-column v-for="list in aMessageTypeIds" :key="list.id"  :label="list.name" align="center">
                        <template slot-scope="scope" >
                           <span v-if="scope.row.message_type_ids.indexOf(list.id)!==-1" class="yesColor el-icon-check"></span>
                           <span v-else class="noColor el-icon-close"></span>        
                        </template>
                    </el-table-column>
                     
                    <!-- <el-table-column prop="sort_order" label="排序" align="center">
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注" align="center">
                    </el-table-column>
                    <el-table-column prop="update_time" label="创建时间" align="center">
                        <template slot-scope="scope">
                            {{scope.row.update_time.trim().split(" ")[0]}}
                        </template>
                    </el-table-column> -->
                    <el-table-column fixed="right" prop="address" label="操作" align="center" width="200">
                        <template slot-scope="scope">
                            <el-button @click="seeJobDetail(scope.row)" type="text" class="text_primary" size="mini">详情
                            </el-button>
                            <span class="lineStyle">|</span>
                            <el-button @click="editJobDetail(scope.row)" type="text" class="text_primary" size="mini">修改
                            </el-button>
                            <span class="lineStyle">|</span>
                            <el-button @click="deleteJobDetail(scope.row)" type="text" class="text_delete" size="mini">
                                删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-drawer>

            <!-- 查看职务详情对话框 -->
            <el-dialog title="职务详情" :visible.sync="is_job_detail" width="20%">
                <el-table :data="job_detail_data" stripe style="width: 100%"  class="staff_data"
                    :header-cell-style="styleBindFun">
                    <el-table-column prop="name" label="姓名" align="center">
                        <template slot-scope="scope">
                            <!-- <div class="staff_name">
                                
                            </div> -->
                            {{scope.row.name}}
                                <i v-show="scope.row.is_leader" class="iconfont icon-fuzeren"></i>
                        </template>
                    </el-table-column>
                    
                    <el-table-column fixed="right" prop="address" label="操作" align="center" >
                        <template slot-scope="scope">
                            <el-button @click="fnEditPosition(scope.row)" type="text"  class="text_primary" size="mini">修改职务</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-dialog>

               <!-- 修改职务对话框 -->
            <el-dialog title="修改职务" :visible.sync="bEditPosition" width="18%">
                <div>
                    <el-select size="small"  filterable remote v-model="nEditPositionId" clearable
                                        placeholder="请选择" :remote-method="remoteMethod" :loading="loading" @clear="fnClearPosition">
                                        <el-option v-for="item in positionData" :key="item.id" :label="item.name"
                                            :value="item.id">
                                        </el-option>
                    </el-select>  
                </div>                
                  <div slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="bEditPosition = false">取 消</el-button>
                    <el-button size="mini" class="frontBtn" type="primary" @click="fnEditPositionDo">确 定</el-button>
                </div>
            </el-dialog>    


            <!-- 添加职务对话框 -->
            <el-dialog title="添加职务" :visible.sync="is_add_job" width="50%" class="addjob">
                <el-form :model="add_label_form" label-width="120px" :rules="rules_add_job">
                    <el-form-item label="职务名称：" >
                        <el-input size="small" v-model="add_job_form.name" class="inputWidth"></el-input>
                    </el-form-item>
                    <el-form-item label="排序：" >
                        <el-input size="small" v-model="add_job_form.sort_order" class="inputWidth"
                            placeholder="排序依据 范围 0~200 默认100 值大的排序靠前">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="消息类型选择：">
                        <el-checkbox-group v-model="add_job_form.message_type_ids">
                            <el-checkbox v-for="node in aMessageTypeIds"  :label="node.id"  :key="node.id" >{{node.name}}</el-checkbox>

                        </el-checkbox-group>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="is_add_job = false">取 消</el-button>
                    <el-button size="mini" class="frontBtn" type="primary" @click="addJobDo">确 定</el-button>
                </div>
            </el-dialog>


            <!-- 修改职务对话框 -->
            <el-dialog title="修改职务" :visible.sync="is_edit_job" width="50%" class="addjob">
                <el-form :model="add_label_form" label-width="120px" :rules="rules_add_job">
                    <el-form-item label="职务名称：">
                        <el-input v-model="add_job_form.name" class="inputWidth"></el-input>
                    </el-form-item>
                    <el-form-item label="排序：" >
                        <el-input v-model="add_job_form.sort_order" class="inputWidth" placeholder="排序依据 范围 0~200 默认100 值大的排序靠前">
                        </el-input>
                    </el-form-item>
                     <el-form-item label="消息类型选择：">
                        <el-checkbox-group v-model="add_job_form.message_type_ids">
                            <el-checkbox v-for="node in aMessageTypeIds"  :label="node.id"  :key="node.id" >{{node.name}}</el-checkbox>

                        </el-checkbox-group>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="is_add_job = false">取 消</el-button>
                    <el-button size="mini" class="frontBtn" type="primary" @click="editJobDo">确 定</el-button>
                </div>
            </el-dialog>


            <!---------------------------------------- 添加部门成员----------------------------------------------------- -->

            <!-- 添加部门成员对话框 -->
            <el-dialog title="添加成员/部门到标签" :visible.sync="is_add_DepartmentAndMember" width="36%" class="addDAMDialog">
                <div class="all">
                    <div class="left">
                        <el-input icon="el-icon-search" placeholder="搜索成员或部门" v-model="addDAM_Input"
                            class="input-select" size="medium" clearable @clear="clearSearch"
                            prefix-icon="el-icon-search" @keyup.enter.native="searchHandle">
                        </el-input>
                        <div class="eltreeleft">
                            <el-tree v-show="is_show_tree" :data="department_member_data" accordion
                                :props="defaultProps" class="addDAMElTree" node-key="id"
                                :default-expanded-keys="departmentIdArr" ref="departmentTree"
                                @node-click="handleNodeClick_depart_member">
                                <span class="DAM-tree-node" slot-scope="{ data }">
                                    <i :class="data.id?'el-icon-s-fold':'el-icon-s-custom'" class="DAMStyle"></i>
                                    <span class="departmentName">{{ data.name }}</span>
                                    <i :class="data.mychecked==true?'el-icon-check':' '" class="ckeckStyle"></i>
                                </span>
                            </el-tree>
                            <div class="searchResult" v-show="is_show_tree==false">
                                <div class="memberArea " v-show="searchedusersData.length>0">
                                    <div class="tit">人员</div>
                                    <div class="detail">
                                        <span v-for="(list,index) in searchedusersData" :key="index" class="item"
                                            :class="index==0?'activeClickStyle':'clickStyle'"
                                            @click="searchedNodeClick_depart_member(list)">
                                            <i class='el-icon-s-custom'></i>
                                            {{list?list.name:''}}
                                        </span>
                                    </div>
                                </div>
                                <div class="departmentArea" v-show="searchedDepartmentData.length>0">
                                    <div class="tit">部门</div>
                                    <div class="detail">
                                        <span v-for="(list,index) in searchedDepartmentData" :key="index" class="item"
                                            :class="index==0&&searchedusersData.length==0?'activeClickStyle':'clickStyle'"
                                            @click="searchedNodeClick_depart_member(list)">
                                            <i class='el-icon-s-fold'></i>
                                            {{list?list.name:''}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="right">
                        <div class="tit">已选择的部门或成员：</div>
                        <ul>
                            <li v-for="(node,index) in selected_addDAM_Data" :key="index" class="itemStyle">
                                <i :class="node.id?'el-icon-s-fold':'el-icon-s-custom'" class="DAMStyle"></i>
                                <span class="departmentName">{{ node.name }}</span>
                                <i class='el-icon-close closeIcon' @click="deleteSelectDAM(node)"></i>
                            </li>
                        </ul>

                    </div>
                </div>

                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="is_add_DepartmentAndMember = false">取 消</el-button>
                    <el-button size="small" class="frontBtn" type="primary" @click="bindLabelDAMDo">确 定</el-button>
                </div>
            </el-dialog>


            <!-- 绑定客户的客户组权限对话框 -->
            <el-dialog title="绑定客户组权限" :visible.sync="is_bindUserGroups" width="36%" class="bindUserGroup">
                <div class="all">
                    <div class="left bindUserGroups">
                        <el-tree :data="groupsData" accordion :props="defaultProps" class="addDAMElTree" node-key="id"
                            :default-expanded-keys="GroupsIdArray" @node-click="handleNodeClick_binUserGroup">
                            <span class="bindUserGroup-tree-node" slot-scope="{ data }">
                                <span>{{data.name}}</span>
                                <i :class="data.mychecked==true?'el-icon-check':' '" class="ckeckStyle"></i>
                            </span>
                        </el-tree>
                    </div>
                    <div class="right">
                        <div class="tit">已选择的权限：</div>
                        <ul>
                            <li v-for="(node,index) in user_selected_group_data" :key="index" class="itemStyle">

                                <span class="departmentName">{{ node.name }}</span>
                                <i class='el-icon-close closeIcon' @click="deleteSelectedGroups(node)"></i>
                            </li>
                        </ul>
                    </div>
                </div>

                <div slot="footer" class="dialog-footer">
                    <el-button size="small" class="frontBtn" @click="submitSelectedGroupsDo">确 定
                    </el-button>
                </div>
            </el-dialog>


            <!-- 修改员工的客户权限对话框 -->
            <el-dialog title="修改客户权限" :visible.sync="is_editUserCustomer" width="70%" class="editUserCustomerStyle">
                <!-- <div class="conditionArea">
                    <div class="top">
                        <span>
                            <span>搜索：

                                <el-input class="search" size="small" v-model="filter_customer_form.keyword"
                                    placeholder="支持店名 别名 联系人 电话 手机"></el-input>
                            </span>
                        </span>



                        <span>
                            <span>会员分组：

                                <el-cascader size="small" v-model="filter_customer_form.group_id" :options="groupsData"
                                    :props="{ expandTrigger: 'hover' ,value:'id',label:'name',checkStrictly: true}">
                                </el-cascader>
                            </span>
                        </span>

                        <span>
                            <span>是否禁用：

                                <el-select size="small" v-model="filter_customer_form.is_enable" placeholder="请选择">
                                    <el-option v-for="item in ban_option" :key="item.value" :label="item.name"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </span>
                        </span>
                    </div>
                    <div class="bottom">
                        <span>
                            <span>配送方案：

                                <el-select size="small" v-model="filter_customer_form.plan_id" placeholder="请选择">
                                    <el-option v-for="item in planData" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </span>
                        </span>

                        <span>
                            <span> 信用等级：
                                <el-select size="small" v-model="filter_customer_form.credit_id" placeholder="请选择">
                                    <el-option v-for="item in creditData" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </span>
                        </span>

                        <span>
                            <span> 折扣选择:

                                <el-select size="small" v-model="filter_customer_form.discount_id" placeholder="请选择">
                                    <el-option v-for="item in discountData" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </span>
                        </span>


                    </div>
                    <div class="filterArea">
                        <el-button size="mini" class="frontBtn" @click="screenDo">筛 选
                        </el-button>
                    </div>
                </div> -->
                <div>
                    <!-- 数据表格 -->
                    <el-table :data="CustomerInfoData" style="width: 100%" border class="staff_data" ref="multipleTable"
                        :header-cell-style="styleBindFun" @select="handleSelectionChange_EditUserCustomer">
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column prop="name" label="客户名称" width="130" align="center">
                        </el-table-column>
                        <el-table-column prop="number" label="客户编号" align="center" width="200">
                        </el-table-column>
                        <el-table-column prop="credit_spend" label="信用支出" align="center">
                        </el-table-column>
                        <el-table-column prop="account" label="账户余额" align="center">
                        </el-table-column>
                        <el-table-column prop="deposit" label="保证金" align="center">
                        </el-table-column>
                        <el-table-column prop="group_name" label="会员分组" width="220" align="center">
                        </el-table-column>
                        <el-table-column prop="credit_name" label="信用额度" align="center">
                        </el-table-column>
                        <el-table-column prop="discount_name" label="折扣力度" align="center">
                        </el-table-column>
                        <el-table-column prop="plan_name" label="配送方案" align="center">
                        </el-table-column>
                        <el-table-column prop="is_enable" label="是否启用" align="center" width="120">
                            <template slot-scope="scope">
                                <el-button type="success" size="mini" v-if="scope.row.is_enable" icon="el-icon-check"
                                    circle></el-button>
                                <el-button type="danger" size="mini" v-else icon="el-icon-close" circle></el-button>
                            </template>
                        </el-table-column>

                    </el-table>

                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="is_editUserCustomer = false">取 消
                    </el-button>
                    <el-button size="mini" class="frontBtn" @click="submitSelectedEditUserCustomerDo">确 定
                    </el-button>
                </div>
            </el-dialog>

            <!-- 修改员工的门店权限对话框 -->
            <el-dialog title="修改客户门店" :visible.sync="is_editUserShop" width="70%" class="editUserCustomerStyle">

                <div>
                    <!-- 数据表格 -->
                    <el-table :data="shopInfoData" style="width: 100%" border class="staff_data"
                        ref="multipleTableEditUserShop" :header-cell-style="styleBindFun"
                        @select="handleSelectionChange_EditUserShop">
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column prop="name" label="门店名" align="center" width="480">
                            <template slot-scope="scope">
                                {{scope.row.name}}

                                （ <el-button type="text" size="medium" @click="jump(scope.row.customer_id)">
                                    {{scope.row.customer_name}}</el-button>）
                            </template>
                        </el-table-column>
                        <el-table-column prop="telephone" label="电话" align="center">
                        </el-table-column>
                        <el-table-column label="是否营业" align="center">
                            <template slot-scope="scope">
                                <div v-if="scope.row.is_business" class="yesIcon el-icon-check"></div>
                                <div v-else class="noIcon el-icon-close"></div>
                            </template>
                        </el-table-column>
                        <el-table-column label="是否展示" align="center">
                            <template slot-scope="scope">
                                <div v-if="scope.row.is_show" class="yesIcon el-icon-check"></div>
                                <div v-else class="noIcon el-icon-close"></div>
                            </template>
                        </el-table-column>
                        <el-table-column label="创建时间" align="center">
                            <template slot-scope="scope">
                                {{scope.row.create_time.trim().split(" ")[0]}}
                            </template>
                        </el-table-column>

                    </el-table>

                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="is_editUserShop = false">取 消
                    </el-button>
                    <el-button size="mini" class="frontBtn" @click="submitSelectedEditUserShopDo">确 定
                    </el-button>
                </div>
            </el-dialog>


            <!-- 设置领导/下属对话框 -->
            <el-dialog title="设置领导" :visible.sync="is_setSuperior" width="36%" class="bindUserGroup">
                <div class="all">
                    <div class="left  setSupSubStyle">
                        <!-- 输入框 -->
                        <el-input clearable placeholder="搜索员工" v-model="Form_setSuperior.keyword"
                            @input="searchWordMonitorFun" class="setSuperior__search__style"
                            @keyup.enter.native="searchStaff__setSuperior" size="small" prefix-icon="el-icon-search">
                        </el-input>

                        <div v-if="is_setSuperior__searchRes" class="setSuperior__search__result__style">
                            <div class="tit">成员</div>
                            <div class="content">
                                <span v-for="(list,index) in setSuperior__search__result__Data" :key="index"
                                    class="item" @click="setSuperior__selectStaffFun(list,index)"
                                    :class="index==0?'active__setsuperior__style':'hover__setsuperior__style'">
                                    <i class='el-icon-s-custom'></i>
                                    {{list.name}}
                                </span>
                            </div>
                        </div>

                        <div v-else>
                            <el-tree :data="user_data" accordion :props="defaultProps" class="addDAMElTree"
                                node-key="id" :default-expanded-keys="user_data_ids"
                                @node-click="handleNodeClick_setSupSub">
                                <span class="bindUserGroup-tree-node" slot-scope="{ data }">
                                    <span>{{data.name}}</span>
                                    <i :class="data.mychecked==true?'el-icon-check':' '" class="ckeckStyle"></i>
                                </span>
                            </el-tree>
                        </div>



                    </div>
                    <div class="right setSupSubStyle">
                        <div class="tit">领导：<span>( 最多选择5个 )</span></div>
                        <div>
                            <ul>
                                <li v-for="(node,index) in user_selected_superior_data" :key="index" class="itemStyle">

                                    <span class="departmentName">{{ node.name }}</span>
                                    <i class='el-icon-close closeIcon' @click="deleteSelectedSuperior(node)"></i>
                                </li>
                            </ul>
                        </div>


                    </div>
                </div>

                <div slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="is_setSuperior = false">取 消
                    </el-button>
                    <el-button size="mini" class="frontBtn" @click="submitSelectedSuperiorSubordinateDo">确 定
                    </el-button>
                </div>
            </el-dialog>



            <!-- // 设置员工标签 -->
            <el-dialog title="设置员工标签" :visible.sync="is_setUserTag" width="36%">

                <el-checkbox-group v-model="checkUserTagList" class="labelNodesStyle">
                    <el-checkbox class="labelNodeStyle" v-for="node in label_data" :key="node.id" :label="node">
                        {{node.name}}
                    </el-checkbox>
                </el-checkbox-group>

                <div slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="is_setUserTag = false">取 消
                    </el-button>
                    <el-button size="mini" class="frontBtn" @click="submitUserTagDo">确 定
                    </el-button>
                </div>
            </el-dialog>


            <!-- 客户详情部分 ------------------------------------  -->
            <el-drawer title="客户详情" :visible.sync="cusDetail_drawer" class="cusDetail_drawer_style"
                :direction="cusDetail_direction" :size="cusDetail_drawerSize">
                <div class="tit">上海荷秀企业管理咨询有限公司</div>
                <div class="content">
                    <div class="div">客户名:<span></span>上海荷秀企业管理咨询有限公司</div>
                    <div class="div">别名:上海荷秀企业管理咨询有限公司</div>
                    <div class="div">客户编号:上海荷秀企业管理咨询有限公司</div>
                    <div class="div">是否启用:上海荷秀企业管理咨询有限公司</div>
                    <div class="div">是否有积分:上海荷秀企业管理咨询有限公司</div>
                    <div class="div">接收消息提醒:上海荷秀企业管理咨询有限公司</div>
                    <div class="div">消费:上海荷秀企业管理咨询有限公司</div>
                    <div class="div">信用消费:上海荷秀企业管理咨询有限公司</div>
                    <div class="div">预存款:上海荷秀企业管理咨询有限公司</div>
                    <div class="div">保证金:上海荷秀企业管理咨询有限公司</div>
                    <div class="div">所属客户组:上海荷秀企业管理咨询有限公司</div>
                    <div class="div">信用等级:上海荷秀企业管理咨询有限公司</div>
                    <div class="div">可用信用额:上海荷秀企业管理咨询有限公司</div>
                    <div class="div">配送方案:上海荷秀企业管理咨询有限公司</div>
                    <div class="div">合同日期:上海荷秀企业管理咨询有限公司</div>
                    <div class="div">开户时间:上海荷秀企业管理咨询有限公司</div>
                    <div class="div">更新时间:上海荷秀企业管理咨询有限公司</div>
                </div>
            </el-drawer>





        </div>
    </div>
</template>

<script>
    import apiConfig from '@/config.js'

    export default {
        data() {
            const generateData = _ => {
                const data = [];
                const cities = [];
                const pinyin = [];
                cities.forEach((city, index) => {
                    data.push({
                        label: city,
                        key: index,
                        pinyin: pinyin[index]
                    });
                });
                return data;
            };

            var validateEmail = (rule, value, callback) => {
                let regs = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                if (value.length == 0) {
                    callback();
                } else {
                    if (!regs.test(value)) {
                        callback([new Error('邮箱输入不合法')]);
                    } else {
                        callback();
                    }
                }
            }


            var validateName = (rule, value, callback) => {
                let regs = /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,4}$/;
                if (value.length == 0) {
                    callback();
                } else {
                    if (!regs.test(value)) {
                        callback([new Error('姓名输入不合法')]);
                    } else {
                        callback();
                    }
                }
            }

            var validateTelephone = (rule, value, callback) => {
                let regs =
                    /^(1[3|4|5|6|7|8|9])\d{9}$|^0\d{2,3}-?\d{7,8}$|^400[016789]\d{6}$|^400-[016789]\d{2}-\d{4}$/;
                if (value.length == 0) {
                    callback();
                } else {
                    if (!regs.test(value)) {
                        callback([new Error('座机号输入不合法')]);
                    } else {
                        callback();
                    }
                }
            }

            return {
                apiConfig: {
                    erp: 'https://api.rainben.cn/erp/'
                },
                is__framework__search__state: false,
                // currentId_department:'',
                active_label: '',
                department_input: '', //部门搜索内容
                department_data: [], //部门列表数据
                departmentIdArr: [], //默认展开第二层树形结构
                department_name_addstaff: '', //添加员工时，选择的部门名称
                label_data: [], //标签数据
                defaultProps: {
                    children: 'children',
                    label: 'name',
                },

                //部门数据属性
                staff_info: {
                    department_id: '', //当前点击部门id
                    // id: '', //当前点击部门id
                    page: 1, //当前页
                    per_page: 10 //页面显示条数
                },
                current_department: '薇碧', //点击的部门名称
                current_department_number: 0, //点击的部门人数
                current_department_data: [], //点击的部门数据
                // 部门对话框变量
                dialogFormVisible_branch: false, //控制添加子部门显示
                dialogFormVisible_edit: false, //控制编辑显示

                form_branch: {
                    form_branch_id_father: '',
                    form_branch_name_father: '',
                    form_branch_name_son: '',
                }, //添加子部门数据表单
                form_edit: {
                    form_edit_name_before: '',
                    form_edit_name_after: '',
                    form_edit_name_id: '',
                    form_edit_parent_id: '',
                    sort_order: 200
                }, //编辑部门名称表单
                form_delete: {
                    form_delete_name: '薇碧',
                    form_delete_id: ''
                }, //删除部门表单  

                // 员工对话框变量
                dialogFormVisible_add_staff: false, //控制增加员工信息
                dialogFormVisible_edit_staff: false, //控制编辑员工信息

                form_edit_staff: {
                    department_id: '', //部门id
                    position_id: '', //职位id
                    department: [], //部门
                    position: '', //职位
                    name: '', //姓名
                    alias: '', //别名
                    mobile: '', //手机号
                    gender: '', //性别
                    email: '', //邮箱
                    telephone: '', //座机
                    is_leader: '0', //是否是负责人
                    is_enable: '0', //是否启用
                    id: '', //员工id
                }, //编辑员工表单数据
                currentEditDepartment: [], //当前编辑员工所属部门
                rules_add_staff: {
                    department_id: [{
                        required: true,
                        message: '部门必须选择',
                        trigger: 'blur'
                    }],
                    position_id: [{
                        required: true,
                        message: '职位必须选择',
                        trigger: 'blur'
                    }],
                    name: [{
                        required: true,
                        message: '请输入姓名',
                        trigger: 'blur'
                    }, {
                        validator: validateName,
                        trigger: 'blur'
                    }],
                    mobile: [{
                        required: true,
                        message: '请输入手机号',
                        trigger: 'blur'
                    }, {
                        min: 11,
                        max: 11,
                        message: '长度在11个字符',
                        trigger: "blur"
                    }],
                },
                rules_edit_staff: {
                    department_id: [{
                        required: true,
                        message: '部门必须选择',
                        trigger: 'blur'
                    }],
                    position_id: [{
                        required: true,
                        message: '职位必须选择',
                        trigger: 'blur'
                    }],
                    name: [{
                        required: true,
                        message: '请输入姓名',
                        trigger: 'blur'
                    }, {
                        validator: validateName,
                        trigger: 'blur'
                    }],
                    mobile: [{
                        required: true,
                        message: '请输入手机号',
                        trigger: 'blur'
                    }, {
                        min: 11,
                        max: 11,
                        message: '长度在11个字符',
                        trigger: "blur"
                    }],
                    enable: [{
                        required: true,
                        message: '请选择是否启用',
                        trigger: 'blur'
                    }],

                },
                form_add_staff: {
                    department_id: '', //部门id
                    department: [], //选择部门
                    position_id: '', //职务id
                    position: '', //职务名称

                    attributes: [], //扩展属性 数组
                    leader_ids: [], //直属上级id 数组
                    name: '', //员工姓名
                    alias: '', //员工别名 = 昵称
                    mobile: '', //手机号码
                    gender: '', //性别 1 2
                    email: '', //邮箱 不填默认转姓名为拼音@公司后缀
                    telephone: '', //固定电话
                    is_leader: '0', //是否负责人 默认否
                    is_enable: '0' //是否启用账号 默认是
                },

                tagsData: [],
                formLabelWidth: '100px',
                treeExpandData: [],
                positionData: [], //职位数据
                departmentData_cascader: [], // 部门数据——级联
                drawer: false, //回收站抽屉


                dialogFormVisible_add_leaders: false,
                selectUserHaveedLabel: [],

                checkboxGroup_Tags: [],
                departData: [],
                departDataAll: [],
                checkboxGroup_Leader: [],
                leadersdata: generateData(),
                leadersvalue: [],


                recycle_data: [], //回收站员工信息
                direction: 'rtl',

                is_pagination: true, //控制分页器的显示与隐藏
                is_peopletotal: true, //控制员工人数的显示与隐藏
                drawerSize: '69%', // 抽屉
                cusDetail_drawerSize: '20%', // 抽屉
                // 标签部分-------------------------------------------------------
                current_label_id: "",
                current_label: '',
                current_label_number: '',
                current_label_data: [],
                current_detail: [],


                choose_add_label_of_staffs: [], //选中要添加的成员
                choose_add_label_of_staffs_id: [], //选中要添加的成员id

                add_label_form: {
                    name: '',
                    sort_order: '',
                    menu_list: []
                },
                add_edit_form: {
                    id: '',
                    name: '',
                    sort_order: '',
                    menu_list: []
                },
                rules_add_label: {
                    name: [{
                        required: true,
                        message: '值不能为空',
                        trigger: 'blur'
                    }]
                },
                new_addDepartment_id: '',
                authorizationData: [],
                is_add_label: false,
                is_edit_label: false,


                menuData: [],
                selectData: [],
                menuIndex: 0,
                menuBadge: 0,
                cancelAfterData: [],
                authorityChecked: true,
                authorityCheckList: [],

                isIndeterminateSet: false,
                allCheckArray: {},
                partCheckArray: [],

                CheckAllFlag: true,

                activeNode: '',
                activeCheckAllId: '',
                currentCheckAll: '',
                selectCheckAllId: [],
                checkedId: [],



                // 职务部分--------------------------------------------------------------------
                job_data: [],
                job_direction: 'ltr',
                job_drawer: false,
                is_job_detail: false,
                job_detail_data: [],
                is_add_job: false,
                aMessageTypeIds: [],
                add_job_form: {
                    name: '',
                    sort_order: '',
                    message_type_ids: [],
                },
                rules_add_job: {
                    name: [{
                        required: true,
                        message: '请填写名称',
                        trigger: 'blur'
                    }]
                },
                is_edit_job: false,
                edit_job_id: '',

                // 添加部门成员-----------------------------------------------------
                is_add_DepartmentAndMember: false,
                addDAM_Input: '',
                is_show_tree: true,
                department_member_data: [],
                select_department_member_data: [],
                selected_addDAM_Data: [],
                searchedDepartmentData: [],
                searchedusersData: [],
                add_DepartmentAndMember_Form: {
                    tag_id: '',
                    user_ids: [],
                    department_ids: []
                },

                // 添加员工-----------------------------------------------------
                is_add_staff_area: false,
                date_onBoard: '',
                attributesData: [],
                checkedDepartmentIds: [],
                //  添加直属领导对话框 属性
                is_addLeader_tree: false,
                selected_Leaders_Data: [],

                // 员工详情-------------------------------------------------------
                is_staff_detail: false,
                staff_detail_data: [],
                see_current_user_id: '',
                is_bindUserGroups: false,
                groupsData: [],
                GroupsIdArray: [],
                user_selected_group_data: [],

                // 修改员工的客户权限--------------------------------------
                is_editUserCustomer: false,
                ban_option: [{
                        value: 0,
                        name: "禁用"
                    },
                    {
                        value: 1,
                        name: "可用"
                    }
                ],
                filter_customer_form: {
                    keyword: '',
                    is_enable: '',
                    group_id: '',
                    plan_id: '',
                    credit_id: '',
                    discount_id: '',
                },
                planData: [],
                creditData: [],
                discountData: [],


                CustomerInfoData: [],
                CustomerInfoCheckedData: [],

                // 修改员工的门店权限--------------------------------------
                is_editUserShop: false,
                shopInfoData: [],
                shopInfoCheckedData: [],

                // 设置领导/下属
                is_setSuperior: false,
                is_setSubordinate: false,
                user_data: [],
                user_data_ids: [],
                user_selected_superior_data: [],
                user_selected_subordinate_data: [],
                is_setSuperior__searchRes: false,
                Form_setSuperior: {
                    keyword: ''
                },
                setSuperior__search__result__Data: [],
                setSuperiot__current__user_id: "",

                // 设置员工标签
                is_setUserTag: false,

                checkUserTagList: [],

                // 修改员工信息
                is_edit_user: false,
                edit_user_row: {},
                form_edit_user: {
                    id: '',
                    name: '',
                    mobile: '',
                    telephone: '',
                    email: '',

                    department_id: '',
                    position_id: '',

                    is_leader: 1,
                    is_enable: 0,
                    attributes: [],
                },
                edit_user_department: [],
                edit_user_date_onBoard: '',
                rules_edit_user: {
                    name: [{
                        required: true,
                        message: '请输入姓名',
                        trigger: 'blur'
                    }, {
                        validator: validateName,
                        trigger: 'blur'
                    }],
                    email: [{
                        validator: validateEmail,
                        trigger: 'blur'
                    }],
                    telephone: [{
                        required: true,
                        message: '请输入座机号',
                        trigger: 'blur'
                    }, {
                        validator: validateTelephone,
                        trigger: 'blur'
                    }],
                    mobile: [{
                        required: true,
                        message: '请输入手机号',
                        trigger: 'blur'
                    }, {
                        min: 11,
                        max: 11,
                        message: '长度在11个字符',
                        trigger: "blur"
                    }],
                },


                // -------------------回收站信息列表------------------------------
                Form__recycleBin: {
                    page: 1,
                    per_page: 10
                },
                Form__recycleBin__total: 0,

                // 全局数据
                currentDepartmentInfo: '',
                currentDepartmentAllId: [],



                // 客户详情
                cusDetail_drawer: false,
                cusDetail_direction: 'rtl',


                // 部门数据
                aDepartments: [],


                bfullscreen: false,

                loading:false,
                loading2:false,


                bEditPosition:false,

                nEditPositionId:'',
                nPositionId:'',

                nCurrentPositionId:'',



                






            }
        },
        created() {
            this.getDepartment();
            this.getLabel();
            this.getPosition();
            this.getStaffAll()
        },
        methods: {
            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind =
                    "background:#f6f8fa;fontSize:14px;color:#333333;fontWeight: bold;height:40px;padding:0px"
                return styleBind;
            },
             
            filterMethod(query, item) {
                return item.pinyin.indexOf(query) > -1;
            },

            // 获取部门选择方法
            async fnGetDepartments() {
                const res = await this.$https.post(this.apiConfig.erp + 'department/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.aDepartments = res.data
            },





            //   获取部门列表方法   
            async getDepartment() {
                const res = await this.$https.post(this.apiConfig.erp + 'departments?_ajax=1')
                if (!res) {
                    return this.$message.error('获取失败：' + res.statusText)
                }
                this.department_data = res.data
                // 赋值
                this.form_branch.form_branch_id_father = res.data[0].id;
                this.form_branch.form_branch_name_father = res.data[0].name;
                this.current_department = res.data[0].name
                this.form_edit.form_edit_name_before = res.data[0].name
                this.treeExpandData.push(res.data[0].id)
                this.department_data.forEach(item => {
                    this.departmentIdArr.push(item.id)
                    // this.initSelectedStyle(item.id)
                })



                // 获取员工数据
                this.getStaff()
            },
            mouseenter(data) {
                this.currentDepartId = data.id
            },
            mouseleave(data) {
                this.currentDepartId = 0
            },

            // 初始化er-tree选中的样式
            initSelectedStyle(id) {

                this.$nextTick(() => {

                    this.$refs.departTree.setCurrentKey(id)
                });
            },


            //  获取标签数据方法
            async getLabel() {

                const res = await this.$https.post(this.apiConfig.erp + 'tag/select?_ajax=1')
                this.label_data = res.data


            },


            // 获取员工信息方法
            async getStaff() {
                let params = this.staff_info;
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'users?_ajax=1', params)


                // 部门总数
                this.current_department_number = data.total;
                // 部门数据
                this.current_department_data = data.data
            },
            async getStaffAll() {
                let params = {
                    department_id: '', //当前点击部门id
                    page: 1, //当前页
                    per_page: 10 //页面显示条数
                };
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'users?_ajax=1', params)

                // 部门数据
                this.departData = data.data

            },


            // 页面显示数发生变化事件方法
            handleSizeChange(id) {
                this.staff_info.per_page = id;
                this.getStaff()
            },
            // 当前页发生变化
            handleCurrentChange(id) {
                this.staff_info.page = id;
                this.getStaff()
            },


            // /点击部门显示人员方法
            handleNodeClick(row) {


                if (row) {

                    // 赋值-添加员工
                    this.form_add_staff.department_id = row.id

                    // 赋值——获取表单数据
                    this.current_department = row.name;
                    this.staff_info.department_id = row.id;
                    // 把页码置空
                    this.staff_info.page = 1
                    // 赋值——增加子部门
                    this.form_branch.form_branch_id_father = row.id;
                    this.form_branch.form_branch_name_father = row.name;

                    // 赋值——编辑部门名称
                    this.form_edit.form_edit_name_before = row.name;
                    this.form_edit.form_edit_name_id = row.id;
                    this.form_edit.form_edit_parent_id = row.parent_id;
                    this.form_edit.sort_order = row.sort_order;


                    // 赋值——删除部门
                    this.form_delete.form_delete_name = row.name,
                        this.form_delete.form_delete_id = row.id;
                }

                this.active_label = 0
                this.is_staff_detail = false
                this.is_add_staff_area = false
                this.is_edit_user = false
                this.is_peopletotal = true
                this.getStaff()


            },




            // 添加子部门方法
            addBranch() {
                // 置空
                this.form_branch.form_branch_name_son = '';
                this.dialogFormVisible_branch = true;
            },
            async addBranchDo() {

                this.dialogFormVisible_branch = false
                let params = {
                    name: this.form_branch.form_branch_name_son,
                    parent_id: this.form_branch.form_branch_id_father,
                }
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'department/create?_ajax=1', params)

                if (data.error_code !== 0) {
                    return this.$message.error('增加子部门失败：' + data.msg)
                }
                this.$message.success('增加子部门成功：' + data.msg)




                //重新赋值部门展开项
                this.departmentIdArr = []
                this.departmentIdArr.push(this.current_department)


                // 重新赋值右侧显示项
                new Promise((resolve, reject) => {
                    resolve(
                        //重新获取数据    
                        this.getDepartment()
                    ) //这里有bug
                }).then((resolve, reject) => {
                    resolve(
                        // 重新赋值右侧显示项
                        this.getDepartmentIdAddDepartment(this.department_data, this.form_branch
                            .form_branch_name_son)
                    )
                })
                this.staff_info.department_id = this.new_addDepartment_id
                this.getStaff()
            },


            // 修改名称方法
            editDepartment() {
                // 打开
                this.dialogFormVisible_edit = true
                this.form_edit.form_edit_name_after = ''
                this.fnGetDepartments()

            },
            async editDepartmentDo() {
                // 对数据进行处理
                let Pid = this.form_edit.form_edit_parent_id[this.form_edit.form_edit_parent_id.length - 1]
                let params = {
                    id: this.form_edit.form_edit_name_id,
                    name: this.form_edit.form_edit_name_before,
                    parent_id: Pid,
                    sort_order: this.form_edit.sort_order,
                }
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'department/edit?_ajax=1', params)
                if (data.error_code !== 0) {
                    return this.$message.error(data.msg)
                }
                this.$message.success(data.msg)
                // 重新获取数据
                this.getDepartment()
                this.getStaff()
                this.dialogFormVisible_edit = false

            },


            // 删除部门方法
            deleteDepartment() {
                this.dialogFormVisible_delete = true
                this.$confirm('此操作将永久删除该部门, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    .then(async () => {

                        let params = {
                            id: this.form_delete.form_delete_id
                        }
                        const {
                            data
                        } = await this.$https.post(this.apiConfig.erp + 'department/delete?_ajax=1', params)

                        if (data.error_code !== 0) {
                            return this.$message.error(data.msg)
                        }
                        this.$message.success(data.msg)
                        this.current_department = '薇碧'
                        this.staff_info.department_id = ''
                        this.getDepartment()
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: '已取消删除'
                        })
                    })
            },



            // 编辑员工信息方法
            editStaff(row) {
                this.dialogFormVisible_edit_staff = true

                // 赋值——到修改员工信息表单页面
                this.form_edit_staff.department_id = row.department_id
                this.form_edit_staff.position_id = row.position_id
                this.form_edit_staff.name = row.name
                this.form_edit_staff.alias = row.alias
                this.form_edit_staff.mobile = row.mobile
                this.form_edit_staff.gender = row.gender
                this.form_edit_staff.email = row.email
                this.form_edit_staff.telephone = row.telephone
                this.form_edit_staff.id = row.id
                this.form_edit_staff.department = row.department_name
                this.form_edit_staff.position = row.position_name
                this.form_edit_staff.is_leader = row.is_leader ? '1' : '0'
                this.form_edit_staff.is_enable = row.is_enable ? '1' : '0'

                let current_department_data = row.department_name.split('/')
                this.currentEditDepartment = []
                current_department_data.forEach(item => {
                    this.getDepartmentId(this.department_data, item)
                })


            },

            // 查看员工的客户组权限
            seeCustomerGroup(row) {
                this.is_user_customers_auth = true
            },


            // 递归查询部门名称方法
            getDepartmentId(json, id) {
                var that = this;
                let o = {}
                json.forEach(function (item) {
                    if (item.name == id) {
                        that.currentEditDepartment.push(item.id)
                        o = {}

                    } else if (item.children && item.children.length > 0) {
                        o = that.getDepartmentId(item.children, id)
                    }
                })
                return o
            },


            // 递归查询部门编号方法
            getDepartmentName(json, id) {
                var that = this;
                let o = {}
                json.forEach(function (item) {
                    if (item.id == id) {
                        that.department_name_addstaff = item.name
                    } else if (item.children && item.children.length > 0) {
                        o = that.getDepartmentName(item.children, id)
                    }
                })
                return o
            },


            // 递归查询部门编号方法  --添加子部门方法使用
            getDepartmentIdAddDepartment(json, name) {

                var that = this;
                let o = {}
                json.forEach(function (item) {
                    if (item.name == name) {

                        that.new_addDepartment_id = item.id
                    } else if (item.children && item.children.length > 0) {
                        o = that.getDepartmentIdAddDepartment(item.children, name)
                    }
                })
                return o
            },


            // 监听switch状态改变方法
            isLeader(data) {
                this.form_edit_staff.is_leader = data
            },


            // 获取所有职位信息方法
            async getPosition(params) {
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'position/select?_ajax=1',params)
                this.positionData = data
                this.loading=false
            },
            handleChangeCascader(data) {
                // 效果：项目名称的input框随着内容的长度而自适应，设置 el_cascader 标签的width
                var el_cascader_element = document.querySelector('#el-cascader')
                var length = data.length + 1
                var num = length * 60 + 'px'
                el_cascader_element.style.width = num
                // 赋值
                let departmentIdArray = []
                for (let i in data) {
                    departmentIdArray.push(data[i])
                }
                let departmentId = departmentIdArray.pop()
                this.form_edit_staff.department_id = departmentId

            },
            handleChangeCascaderAdd(data) {
                let id = data.pop()
                this.form_add_staff.department_id = id
            },




            // 选择职位方法
            editPosition(command) {
                this.form_edit_staff.position = command.name
                this.form_edit_staff.position_id = command.id
            },
            editPositionAdd(command) {
                this.form_add_staff.position = command.name
                this.form_add_staff.position_id = command.id
            },


            // 初始化部门列表方法
            showDepartment() {

            },
            async editStaffDo() {
                // 关闭对话框
                this.dialogFormVisible_edit_staff = false
                let {
                    department_id,
                    position_id,
                    name,
                    mobile,
                    email,
                    telephone,
                    is_leader,
                    is_enable,
                    id
                } = this.form_edit_staff
                let params = {
                    id: id,
                    department_id: department_id,
                    position_id: position_id,
                    name: name,
                    mobile: mobile,
                    email: email,
                    telephone: telephone,
                    is_leader: is_leader,
                    is_enable: is_enable
                }

                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'user/edit?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.getStaff()

            },

            // 改变状态快捷方法
            async editState(row) {
                let params = {
                    id: row.id,
                    type: 'enable'
                }
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'user/set_status?_ajax=1', params);
                //    修改失败
                if (data.error_code !== 0) return this.$message.error(res.msg)
                this.$message.success(data.msg);
            },


            // 移至回收站方法
            async removeRecycle(row) {
                let params = {
                    id: row.id,
                }
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'user/destroy?_ajax=1', params);
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg);
                this.getStaff()
            },


            // 点击添加员工前，获取员工扩展属性方法
            async getExtendedProperties() {
                const res = await this.$https.post(this.apiConfig.erp + 'attribute/select?_ajax=1')

                if (res.status !== 200) return this.$message.error(res.statusText)

                this.attributesData = res.data
            },


            // 点击添加员工方法
            addStaff() {
                this.active_label = 10
                this.is_add_staff_area = true

                this.getDepartmentAndMemberData('addStaff')
                this.getExtendedProperties()
                this.form_add_staff.department = []
                this.form_add_staff.position_id = ''
                this.form_add_staff.department_id = ''
                this.form_add_staff.position = ''
                this.form_add_staff.name = ''
                this.form_add_staff.alias = ''
                this.form_add_staff.mobile = ''
                this.form_add_staff.gender = ''
                this.form_add_staff.email = ''
                this.form_add_staff.telephone = ''
                this.form_add_staff.is_leader = ''
                this.form_add_staff.is_enable = ''
                this.form_add_staff.attributes = []
                this.form_add_staff.leader_ids = []
                this.date_onBoard = ''
            },

            async addStaffSubmitDo() {
                let AttrObj = {};
                this.attributesData.forEach(node => {
                    AttrObj.id = node.id
                    AttrObj.value = this.date_onBoard
                })
                this.form_add_staff.attributes.push(AttrObj)
                this.form_add_staff.department_id = this.form_add_staff.department.pop()
                delete this.form_add_staff.leader_ids
                delete this.form_add_staff.position
                delete this.form_add_staff.department

                let params = this.form_add_staff

                let {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'user/create?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg);
                this.$message.success(data.msg);
                this.is_add_staff_area = false
                this.staff_info.department_id = this.form_add_staff.department_id
                this.recursionGetDepartmentNameForID(this.department_data, this.form_add_staff.department_id)
                this.getStaff()
                this.active_label = 0
            },

            // 递归根据id获取部门名称
            recursionGetDepartmentNameForID(data, id) {
                data.forEach(node => {
                    if (node.id == id) {
                        this.current_department = node.name
                    } else if (node.children) {
                        this.recursionGetDepartmentNameForID(node.children, id)
                    }
                })
            },



            // 删除选择的已选择的领导
            deleteSelectedLeaders(node) {
                let index = this.selected_Leaders_Data.indexOf(node)
                this.selected_Leaders_Data.splice(index, 1)
                this.recursionDeleteLeadersIcon(this.department_member_data, node.user_id)
            },

            // 递归改变选项选中状态
            recursionDeleteLeadersIcon(data, user_id) {
                data.forEach(node => {
                    if (node.user_id) {
                        if (node.user_id == user_id) {
                            node.mychecked = false
                        }
                    } else if (node.children) {
                        this.recursionDeleteLeadersIcon(node.children, user_id)
                    }
                })
            },


            // 递归初始化选择上级领导状态
            recursionInitAddLeaders(data, user_id) {
                data.forEach(node => {
                    if (node.user_id) {
                        if (node.user_id == user_id) {
                            node.mychecked = true
                        }
                    } else if (node.children) {
                        this.recursionInitAddLeaders(node.children, user_id)
                    }
                })
            },

            addStaffBackBtn() {
                this.is_add_staff_area = false
                this.active_label = 0
            },


            // 点击添加员工前递归找出选的部门名称方法
            lookupDepartmentName() {
                let id = this.form_add_staff.department.pop()
                // 递归查询
                this.getDepartmentName(this.department_data, id)
            },
            async addStaffDo() {
                this.lookupDepartmentName()
                let params = {
                    department_id: this.form_add_staff.department_id,
                    position_id: this.form_add_staff.position_id,
                    tag_ids: this.form_add_staff.tag_ids,
                    attributes: this.form_add_staff.attributes,
                    leader_ids: this.form_add_staff.leader_ids,
                    name: this.form_add_staff.name,
                    alias: this.form_add_staff.alias,
                    mobile: this.form_add_staff.mobile,
                    gender: this.form_add_staff.gender,
                    email: this.form_add_staff.email,
                    telephone: this.form_add_staff.telephone,
                    is_leader: this.form_add_staff.is_leader,
                    is_enable: this.form_add_staff.is_enable,
                }
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'user/create?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg);
                this.$message.success(data.msg)
                this.dialogFormVisible_add_staff = false;
                // 重新赋值部门id,重新获取  
                this.staff_info.department_id = this.form_add_staff.department_id
                this.getStaff()
                this.current_department = this.department_name_addstaff

            },




            addLeaders() {
                this.dialogFormVisible_add_leaders = true;
                this.selected_Leaders_Data.forEach(node => {
                    this.recursionInitAddLeaders(this.department_member_data, node.user_id)
                })
            },

            handleNodeClick_add_leaders(data) {
                if (data.id) {
                    return
                }
                data.mychecked = !data.mychecked
                // 把数据添加到数组中
                if (data.mychecked) {
                    // 把数据添加到数组中
                    this.selected_Leaders_Data.push(data)
                } else {
                    let index = this.selected_Leaders_Data.indexOf(data)
                    this.selected_Leaders_Data.splice(index, 1)
                }
                this.selected_Leaders_Data = [...new Set(this.selected_Leaders_Data)]

            },


            // 获取指定直属上级人员接口信息方法
            async getDepartmentStaff() {
                const res = await this.$https.post(this.apiConfig.erp + 'user/select?_ajax=1')
                this.department_staff_data = res.data
            },





            // 关闭回收站抽屉方法
            handleCloseDrawer() {
                this.drawer = false
            },




            // 回收站相关方法
            async recycle() {
                this.drawer = true
                let params = {
                    type: 'onlytrash',
                    page: this.Form__recycleBin.page,
                    per_page: this.Form__recycleBin.per_page,
                }
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'users?_ajax=1', params)
                if (data.length == 0) return this.$message.error('获取回收站信息失败')
                this.recycle_data = data.data
                this.Form__recycleBin__total = data.total
            },

            handleCurrent__recycleBin__Change(data) {
                this.Form__recycleBin.page = data
                this.recycle()
            },


            // 回收站恢复方法
            async recover(row) {
                let params = {
                    id: row.id
                }
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'user/restore?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.recycle()
                this.getStaff()

            },
            // 搜索员工方法
            async searchStaff() {
                let params = {
                    keyword: this.department_input
                }
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'users?_ajax=1', params)
                if (data.data.length == 0) return this.$message.error('未查到相关人员');
                // 数据赋值
                this.current_department_data = data.data;

                // 分页器隐藏
                this.is_pagination = false

                // 赋值搜索结果部门名称

                let department_name_search = ''
                this.current_department_data.forEach(item => {
                    let {
                        department_full_name
                    } = item
                    department_name_search = department_full_name
                })
                let department_name_search_data = department_name_search.split('/')
                let department_name_search_current = department_name_search_data.pop()
                this.current_department = department_name_search_current

                //隐藏员工人数
                this.is_peopletotal = false

                // 切换显示界面选项
                this.is_staff_detail = false
                this.is_add_staff_area = false

                this.active_label = 0
            },
            handleClick() {},

            // 回收站删除方法
            async deleteRecycle(row) {
                this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    .then(async () => {
                        let params = {
                            id: row.id,
                            type: 'delete'
                        }
                        const {
                            data
                        } = await this.$https.post(this.apiConfig.erp + 'user/destroy?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)
                        this.recycle()
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: '已取消删除'
                        })
                    })

            },

            // --------------------------------------------------------------------------------------------------
            // 标签部分方法

            // 显示标签右侧方法
            async label_Show(tab, event) {
                const res = await this.$https.post(this.apiConfig.erp + 'tag/select?_ajax=1')

                if (tab.index == 0) {
                    this.is__framework__search__state = false
                } else {
                    this.is__framework__search__state = true
                }

                this.is_add_label = false
                this.is_edit_label = false
                this.active_label = tab.index
                this.is_staff_detail = false

                this.label_show_first();
                this.getLabelMethods()

            },
            //初始化标签数组第一项的数据
            label_show_first() {
                let item = this.label_data.slice(0, 1)

                item.forEach(item => {
                    this.current_label_id = item.id
                })
                this.getLabelDetail()
            },
            // 把当前点击的标签项id赋值
            currentLabel(row) {
                this.is_add_label = false
                this.is_edit_label = false
                this.active_label = 1
                this.current_label_id = row

                this.getLabelDetail()
            },
            // 获取标签详情数据
            async getLabelDetail() {
                let params = {
                    id: this.current_label_id
                }
                const res = await this.$https.post(this.apiConfig.erp + 'tag/detail?_ajax=1', params)

                if (res.status !== 200) return this.$message.error(res.statusText)
                // 赋值
                this.current_detail = res.data

                // 赋值标签名称
                this.current_label = res.data.name
                // 赋值标签员工人数
                this.current_label_number = res.data.users.length
                // 赋值标签员工数据

                this.current_label_data = res.data.departments.concat(res.data.users)
            },



            // 点击项目触发的事件
            handleNodeClick_add_labels(data) {
                var that = this;
                if (!data.id) {
                    // 提示已经重复点击
                    this.choose_add_label_of_staffs_id.find(e => {
                        if (e == data.user_id) {
                            that.$message.error('该成员已经添加过')
                        }
                    })
                    this.choose_add_label_of_staffs.push(data);
                    this.choose_add_label_of_staffs_id.push(data.user_id)
                }
                this.choose_add_label_of_staffs = [...new Set(this.choose_add_label_of_staffs)]
                this.choose_add_label_of_staffs_id = [...new Set(this.choose_add_label_of_staffs_id)]

            },
            // 移除选中的标签成员方法
            handleClose_addLeables(tag) {
                this.choose_add_label_of_staffs.splice(this.choose_add_label_of_staffs.indexOf(tag), 1);
            },
            async addLabelsDo() {
                let params = {
                    tag_id: this.current_label_id,
                    user_ids: this.choose_add_label_of_staffs_id,
                    department_ids: []
                }
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'tag/attach?_ajax=1', params)
                if (data.error_code !== 0) {
                    return this.$message.error(data.msg)
                }
                this.$message.success(data.msg)

                this.getLabelDetail()
            },


            //添加标签方法
            addLabel() {
                this.active_label = 3
                this.is_add_label = true
                this.is_edit_label = false
                // 清空
                this.add_label_form.name = ''
                this.add_label_form.sort_order = ''
                this.add_label_form.menu_list = []

                this.getLabelMethods()

            },

            // 获取标签数据
            async getLabelMethods() {
                let {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'menu/select?_ajax=1')

                if (data.length == 0) return this.$message.error('获取操作权限失败')
                this.menuData = data
            },





            // 删除该标签方法
            deleteLabel() {
                this.$confirm('此操作将永久删除该标签, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    .then(async () => {
                        let params = {
                            id: this.current_label_id
                        }
                        const {
                            data
                        } = await this.$https.post(this.apiConfig.erp + 'tag/delete?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)

                        this.$message.success(data.msg)
                        this.getLabel()


                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: '已取消删除'
                        })
                    })
            },

            // 修改该标签方法
            editLabel() {

                this.giveValue()
                this.eachLabelAuth()

            },

            giveValue() {
                this.active_label = 3
                this.is_edit_label = true
                this.add_edit_form.id = this.current_detail.id
                this.add_edit_form.name = this.current_detail.name
                this.add_edit_form.sort_order = this.current_detail.sort_order
            },


            // 遍历标签已经拥有的权限
            eachLabelAuth() {
                let data = this.current_detail.authoritys
                let tempdata = []
                data.forEach(node => {
                    tempdata.push(node.id)
                })


                this.recursionClearCheckedMenu(this.menuData)

                this.menuData.forEach(node => {
                    node.children.forEach(item => {
                        if (item.children !== undefined) {
                            item.children.forEach(ele => {
                                tempdata.forEach(key => {
                                    if (ele.id == key) {
                                        ele.mychecked = true
                                    }
                                })
                            })
                        }
                    })

                })

            },

            recursionClearCheckedMenu(data) {
                data.forEach(node => {
                    node.mychecked = false
                    if (node.children) {
                        this.recursionClearCheckedMenu(node.children)
                    }
                })
            },



            async submitLabelDo() {
                this.getSelectLabelData()
                this.add_label_form.menu_list = this.checkedId
                if (this.add_label_form.sort_order == '') {
                    this.add_label_form.sort_order == 100
                }
                let params = this.add_label_form

                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'tag/create?_ajax=1', params)


                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                let tab = {
                    index: 1
                }

                const p = new Promise((resolve, reject) => {
                    this.getLabel()
                    resolve()
                })

                p.then(() => {
                    this.label_Show(tab)
                })
            },

            async editSubmitLabelDo() {
                this.getSelectLabelData()
                this.add_edit_form.menu_list = this.checkedId
                if (this.add_edit_form.sort_order == '') {
                    this.add_edit_form.sort_order == 100
                }
                let params = this.add_edit_form
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'tag/edit?_ajax=1', params)

                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.goBackEditLabel()
                this.getLabelDetail()

            },

            // 获取选择的标签数据
            getSelectLabelData() {
                // 先把选中的id和name置空'
                this.checkedId = []

                // 找出数据中的每一个数组
                // 如果数组中的选项中的mychecked为true
                // 把选中项的id和name分别加到对应数组
                this.menuData.forEach(node => {
                    node.children.forEach(item => {
                        if (item.children !== undefined) {
                            item.children.forEach(ele => {

                                if (ele.mychecked == true) {
                                    this.checkedId.push(ele.id)

                                }
                            })
                        }
                    });
                })


            },



            // 一级选择事件
            handleCheckAllChange(node, nodeIndex) {
                let currentData = [];
                // 先找出数组数据
                if (node.parent_id == 1) {
                    currentData = this.menuData[0];
                } else {
                    currentData = this.menuData[1];
                }

                if (currentData.children[nodeIndex].mychecked == true) {

                    // 1.把多选框不确定性选项置为false
                    currentData.children[nodeIndex].isIndeterminate = false
                    // 2.把选中的选项数据赋值到变量中
                    let childrenData = currentData.children[nodeIndex].children;
                    // 3.做个判断 

                    if (childrenData) {
                        // 把该数组中所有数据的mychecked置为false
                        let len = childrenData.length;
                        for (let i = 0; i < len; i++) {

                            childrenData[i].mychecked = true;
                        }
                    }

                } else {
                    // 1.把多选框不确定性选项置为false
                    currentData.children[nodeIndex].isIndeterminate = false
                    // 2.把选中的选项数据赋值到变量中
                    let childrenData = currentData.children[nodeIndex].children;
                    // 3.做个判断 

                    if (childrenData) {
                        // 把该数组中所有数据的mychecked置为false
                        let len = childrenData.length;
                        for (let i = 0; i < len; i++) {

                            childrenData[i].mychecked = false;
                        }
                    }

                }
            },

            // 二级选择事件
            handleCheckChange(node, data) {
                //1. 把选中的数组项数据赋值到变量中 已操作
                // 2.定义2个变量,打钩个数+取消打勾个数
                let tickCount = 0;
                let untickCount = 0;
                let len = data.length;
                // 3.判断数组中每项mychecked的值,分别计算个数
                for (let i = 0; i < len; i++) {
                    if (data[i].mychecked == true) {
                        tickCount++
                    } else {
                        untickCount++
                    }
                }
                // 4.如果打勾个数和数组长度相等，则全选
                if (tickCount == len) { //二级全勾选  一级勾选  
                    node.mychecked = true
                    node.isIndeterminate = false
                } else if (tickCount == 0) { //二级全不勾选 
                    node.isIndeterminate = false
                } else { //二级未全选  一级不勾选
                    node.mychecked = false
                    node.isIndeterminate = true
                }
            },





            goBack() {
                let tab = {
                    index: 1
                }
                this.label_Show(tab)
            },


            goBackEditLabel() {
                this.is_add_label = false
                this.is_edit_label = false
                this.active_label = 1
            },






            // 职务部分 -----------------------------------------------
            //  职务设置
            setJob() {
                this.job_drawer = true
                this.fnGetMessageTypeIds()
                setTimeout(() => {
                    this.getJobData()
                }, 0.1);
            },
            async getJobData() {
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'positions?_ajax=1')
                this.job_data = data
            },
            async seeJobDetail(row) {
                this.is_job_detail = true
                if(row){
                    this.nCurrentPositionId=row.id
                }
                let params = {
                    id: this.nCurrentPositionId
                }
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'position/detail?_ajax=1', params)

                this.job_detail_data = data.users
                this.getPosition() 

            },

            // 获取消息消息类型
            async fnGetMessageTypeIds() {
                const res = await this.$https.post(this.apiConfig.erp + 'messages/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.aMessageTypeIds = res.data
            },

            // 创建职务
            createJob() {
                this.is_add_job = true
                this.add_job_form.name=''
                this.add_job_form.sort_order=''            
                this.add_job_form.message_type_ids=[]            

            },
           
            async addJobDo() {
                let params = this.add_job_form
                let {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'position/create?_ajax=1',
                    params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_add_job = false
                this.getJobData()
            },
            async editJobDetail(row) {
                
                this.is_edit_job = true
                this.edit_job_id = row.id;

                let params = {
                    id:row.id
                }
                let res = await this.$https.post(this.apiConfig.erp + 'position/detail?_ajax=1',
                    params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                
                this.add_job_form.name = res.data.name;
                this.add_job_form.sort_order = res.data.sort_order;
                this.add_job_form.message_type_ids = res.data.message_type_ids;            
            },
            async editJobDo() {
                this.add_job_form.id = this.edit_job_id;
                let params = this.add_job_form
                let {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'position/edit?_ajax=1',
                    params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_edit_job = false
                this.getJobData()
            },

            deleteJobDetail(row) {

                this.$confirm('此操作将永久删除该标签, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    .then(async () => {
                        let params = {
                            id: row.id
                        }
                        const {
                            data
                        } = await this.$https.post(this.apiConfig.erp +
                            'position/delete?_ajax=1', params)


                        if (data.status !== 1) return this.$message.error(data.msg)
                        this.$message.success(data.msg)
                        this.getJobData()
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: '已取消删除'
                        })
                    })
            },



            // 添加部门成员-----------------------------------------------------
            addDepartmentAndMember() {
                this.is_add_DepartmentAndMember = true
                this.getDepartmentAndMemberData()
            },


            async getDepartmentAndMemberData(value) {
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'user/select?_ajax=1')
                if (data.length == 0) return this.$message.error('获取部门成员失败！')
                this.department_member_data = data
                this.selected_addDAM_Data = []

                if (value !== 'addStaff') {
                    this.initializeLabelDAM()
                }

            },




            handleNodeClick_depart_member(data) {

                data.mychecked = !data.mychecked

                if (data.mychecked) {
                    // 把数据添加到数组中
                    this.selected_addDAM_Data.push(data)
                } else {
                    let index = this.selected_addDAM_Data.indexOf(data)
                    this.selected_addDAM_Data.splice(index, 1)
                }
                this.selected_addDAM_Data = [...new Set(this.selected_addDAM_Data)]
            },

            searchedNodeClick_depart_member(list) {
                let flag = true;
                // 判断是否重复
                if (list.user_id) {
                    this.selected_addDAM_Data.forEach(node => {
                        if (node.user_id) {
                            if (node.user_id == list.user_id) {
                                this.$message.error('已存在，请勿重复添加！')
                                flag = false
                            }
                        }
                    })
                }

                if (list.id) {
                    this.selected_addDAM_Data.forEach(node => {
                        if (node.id) {
                            if (node.id == list.id) {
                                this.$message.error('已存在，请勿重复添加！')
                                flag = false
                            }
                        }
                    })
                }

                if (!flag) {
                    return;
                }

                if (list.user_id) {
                    this.selected_addDAM_Data.push(list)
                    this.recursionaddIcon(this.department_member_data, list)
                }
                if (list.id) {
                    this.selected_addDAM_Data.push(list)
                    this.recursionaddIcon(this.department_member_data, list)
                }

                this.selected_addDAM_Data = [...new Set(this.selected_addDAM_Data)]
                this.is_show_tree = true
                this.addDAM_Input = ''


            },

            clearSearch() {
                this.is_show_tree = true
            },



            async searchHandle() {

                let params = {
                    keyword: this.addDAM_Input
                }
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'user/select?_ajax=1', params)

                this.searchedDepartmentData = data.departments
                this.searchedusersData = data.users
                this.is_show_tree = false
            },



            deleteSelectDAM(node) {
                // 1.先删除本区域的数据
                let index = this.selected_addDAM_Data.indexOf(node)
                this.selected_addDAM_Data.splice(index, 1)
                this.recursionIcon(this.department_member_data, node)
            },

            async bindLabelDAMDo() {
                this.add_DepartmentAndMember_Form.tag_id = this.current_label_id
                // 清空
                this.add_DepartmentAndMember_Form.department_ids = []
                this.add_DepartmentAndMember_Form.user_ids = []

                this.selected_addDAM_Data.forEach(node => {
                    if (node.id) {
                        this.add_DepartmentAndMember_Form.department_ids.push(node.id)
                    }
                    if (node.user_id) {
                        this.add_DepartmentAndMember_Form.user_ids.push(node.user_id)
                    }
                })
                let params = this.add_DepartmentAndMember_Form
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'tag/bind?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_add_DepartmentAndMember = false
                this.getLabelDetail()
            },

            // 点击之后初始化所属标签成员部门
            initializeLabelDAM() {

                let usersData = this.current_detail.users
                let departmentsData = this.current_detail.departments
                // 遍历赋值
                usersData.forEach(node => {
                    this.recursionInit(this.department_member_data, node.id)
                })

                departmentsData.forEach(node => {
                    this.recursionInitDepartment(this.department_member_data, node.id)
                })



                this.selected_addDAM_Data = [...new Set(this.selected_addDAM_Data)]


            },

            // 递归删除左侧图标
            recursionIcon(data, json) {

                if (json.id) { //部门
                    data.forEach(node => {
                        if (node.id) {
                            if (node.id == json.id) {
                                node.mychecked = false
                            } else if (node.children) {
                                this.recursionIcon(node.children, json)
                            }
                        }
                    })
                }

                if (json.user_id) { //成员    

                    data.forEach(item => {
                        if (item.user_id) {

                            if (item.user_id == json.user_id) {

                                item.mychecked = false
                            } else if (item.children) {
                                this.recursionIcon(item.children, json)
                            }
                        } else if (item.children) {
                            this.recursionIcon(item.children, json)
                        }
                    })
                }
            },

            // 递归添加左侧图标
            recursionaddIcon(data, json) {

                if (json.id) { //部门
                    data.forEach(node => {
                        if (node.id) {
                            if (node.id == json.id) {
                                node.mychecked = true
                            } else if (node.children) {
                                this.recursionIcon(node.children, json)
                            }
                        }
                    })
                }

                if (json.user_id) { //成员    

                    data.forEach(item => {

                        if (item.user_id) {

                            if (item.user_id == json.user_id) {

                                item.mychecked = true
                            } else if (item.children) {


                                this.recursionaddIcon(item.children, json)
                            }
                        } else if (item.children) {

                            this.recursionaddIcon(item.children, json)
                        }
                    })
                }
            },

            // 递归初始化数据-员工
            recursionInit(data, user_id) {
                data.forEach(item => {
                    if (item.user_id) {
                        if (item.user_id == user_id) {
                            item.mychecked = true
                            this.selected_addDAM_Data.push(item)
                        } else if (item.children) {
                            this.recursionInit(item.children, user_id)
                        }
                    } else if (item.children) {
                        this.recursionInit(item.children, user_id)
                    }
                })
            },

            // 递归初始化数据-部门
            recursionInitDepartment(data, id) {
                data.forEach(item => {
                    if (item.id) {
                        if (item.id == id) {
                            item.mychecked = true
                            this.selected_addDAM_Data.push(item)
                        } else if (item.children) {
                            this.recursionInitDepartment(item.children, id)
                        }
                    } else if (item.children) {
                        this.recursionInitDepartment(item.children, id)
                    }
                })
            },


            // 查看员工详情--------------------------------------------------
            async lookStaffDetail(row) {

                this.active_label = 10
                this.see_current_user_id = row.id
                this.is_staff_detail = true
                let params = {
                    id: row.id
                }
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'user/detail?_ajax=1', params)

                if (data.length == 0) return this.$message.error('获取员工详情失败')
                this.staff_detail_data = data
                this.edit_user_row = data

                this.getGroupsInfo()
                this.getPlanInfo()
                this.getCreditInfo()
                this.getDiscountInfo()

            },
            seeStaffBackBtn() {
                this.active_label = 0
                this.is_staff_detail = false
            },

            async getGroupsInfo() {
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'group/select?_ajax=1')
                if (data.length == 0) return this.$message.error('获取客户组权限失败')
                this.groupsData = data
            },

            async bindUserGroups() {
                this.is_bindUserGroups = true

                // this.recursionGroupsChecked(this.groupsData)

                this.groupsData.forEach(node => {
                    this.GroupsIdArray.push(node.id)
                })
                // 初始化客户组选中状态
                this.staff_detail_data.groups.forEach(item => {
                    this.recursionInitGroupsCheck(this.groupsData, item.id)
                })


                this.user_selected_group_data = []
                // 初始化已选择数组
                this.staff_detail_data.groups.forEach(item => {
                    this.recursionAddAttr(this.groupsData, item.id)
                })

            },

            recursionAddAttr(data, id) {
                data.forEach(node => {
                    if (node.id == id) {
                        this.user_selected_group_data.push(node)
                    } else if (node.children) {
                        this.recursionAddAttr(node.children, id)
                    }
                })
            },


            // 递归加上选中属性
            recursionGroupsChecked(data) {
                data.forEach(node => {
                    node['groupsChecked'] = false
                    if (node.children) {
                        this.recursionGroupsChecked(node.children)
                    }
                })
            },

            // 递归初始化员工客户组权限选中状态
            recursionInitGroupsCheck(data, id) {
                data.forEach(node => {
                    if (node.id == id) {
                        node.mychecked = true
                    }
                    if (node.children) {
                        this.recursionInitGroupsCheck(node.children, id)
                    }
                })
            },

            handleNodeClick_binUserGroup(node) {
                // 判断是否重重
                let index = this.user_selected_group_data.indexOf(node)
                if (index !== -1) {
                    return this.$message.error('请勿重复添加')
                }
                node.mychecked = !node.mychecked
                this.user_selected_group_data.push(node)
            },

            deleteSelectedGroups(node) {
                let index = this.user_selected_group_data.indexOf(node)
                this.user_selected_group_data.splice(index, 1)
                this.recursiondeletegroupsIcon(this.groupsData, node.id)
            },

            // 递归循环添加左侧图标
            recursionaddgroupsIcon(data, id) {
                data.forEach(node => {
                    if (node.id == id) {
                        node['mychecked'] = false
                    }
                    if (node.children) {
                        this.recursionaddgroupsIcon(node.children, id)
                    }
                })
            },


            // 递归循环删掉左侧图标
            recursiondeletegroupsIcon(data, id) {
                data.forEach(node => {
                    if (node.id == id) {
                        node['mychecked'] = false
                    }
                    if (node.children) {
                        this.recursiondeletegroupsIcon(node.children, id)
                    }
                })
            },

            async submitSelectedGroupsDo() {
                let idsArray = []
                this.user_selected_group_data.forEach(node => {
                    idsArray.push(node.id)
                })
                let params = {
                    user_id: this.see_current_user_id,
                    group_list: idsArray
                }
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'user/group/bind?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.is_bindUserGroups = false
                this.getStaffDetail()

            },

            // 修改员工的客户权限--------------------------------------
            editUserCustomer() {
                this.is_editUserCustomer = true
                this.CustomerInfoData = this.staff_detail_data.customers

                this.$nextTick(() => {
                    this.CustomerInfoData.forEach(node => {
                        this.$refs.multipleTable.toggleRowSelection(node, true);
                    })
                });

            },

            // 修改员工的门店权限--------------------------------------
            editUserShop() {
                this.is_editUserShop = true
                this.shopInfoData = this.staff_detail_data.shops


                this.$nextTick(() => {
                    this.shopInfoData.forEach(node => {
                        this.$refs.multipleTableEditUserShop.toggleRowSelection(node, true);
                    })
                });
            },


            handleSelectionChange_EditUserCustomer(selection, row) {
                this.CustomerInfoCheckedData = selection
            },

            async submitSelectedEditUserCustomerDo() {
                let obj = {
                    user_id: this.see_current_user_id,
                    customer_list: []
                }
                this.CustomerInfoCheckedData.forEach(node => {
                    obj.customer_list.push(node.id)
                })
                let params = obj
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'user/customer/bind?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.getUserInfoAfterEdit()
                this.is_editUserCustomer = false
            },


            handleSelectionChange_EditUserShop(selection) {
                this.shopInfoCheckedData = selection
            },

            async submitSelectedEditUserShopDo() {
                let obj = {
                    user_id: this.see_current_user_id,
                    shop_list: []
                }
                this.shopInfoCheckedData.forEach(node => {
                    obj.shop_list.push(node.id)
                })
                let params = obj
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'user/shop/bind?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.getUserInfoAfterEdit()
                this.is_editUserShop = false
            },




            // 获取配送方案
            async getPlanInfo() {
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'plan/select?_ajax=1')
                this.planData = data
            },

            // 获取信用等级
            async getCreditInfo() {
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'credit/select?_ajax=1')
                this.creditData = data
            },

            // 获取折扣选择
            async getDiscountInfo() {
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'discount/select?_ajax=1')
                this.discountData = data
            },



            // 筛选功能
            screenDo() {},

            searchWordMonitorFun() {
                if (this.Form_setSuperior.keyword == '') {
                    this.is_setSuperior__searchRes = false
                }
            },

            // 设置领导/下属
            setSuperior() {
                this.is_setSuperior = true
                this.Form_setSuperior.keyword = ''
                this.is_setSuperior__searchRes = false
                this.getUserList()
            },

            // 获取员工选择
            async getUserList() {
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'user/select?_ajax=1')
                this.user_data = data
                this.user_selected_superior_data = []
                this.staff_detail_data.leaders.forEach(node => {
                    this.recursionInitSelectedIcon(this.user_data, node.name)
                    this.recursionAddSelectedLeaders(this.user_data, node)
                })
                this.user_data.forEach(node => {
                    this.user_data_ids.push(node.id)
                })
            },

            // 递归循环添加selected数组
            recursionAddSelectedLeaders(data, node) {

                data.forEach(ele => {
                    if (ele.user_id) {
                        if (ele.name == node.name) {
                            this.user_selected_superior_data.push(ele)
                        }
                    } else if (ele.children) {
                        this.recursionAddSelectedLeaders(ele.children, node)
                    }
                })
            },

            // 初始化-设置上下级图标
            recursionInitSelectedIcon(data, name) {
                data.forEach(node => {
                    if (node.user_id) {
                        if (node.name == name) {
                            node['mychecked'] = true
                        }
                    } else if (node.children) {
                        this.recursionInitSelectedIcon(node.children, name)
                    }
                })
            },





            handleNodeClick_setSupSub(node) {


                // 判断
                if (node.user_id) {
                    if (node.user_id == this.see_current_user_id) {
                        return this.$message.error('请不要选择自己')
                    }
                    let i = this.user_selected_superior_data.indexOf(node)
                    if (i !== -1) {
                        this.user_selected_superior_data.splice(i, 1)
                    } else {
                        if (this.user_selected_superior_data.length >= 5) {
                            return this.$message.error('已达领导最大选择数')
                        } else {
                            this.user_selected_superior_data.push(node)
                            this.user_selected_superior_data = [...new Set(this.user_selected_superior_data)]

                        }
                    }
                    node.mychecked = !node.mychecked

                }
            },


            setSuperior__selectStaffFun(list, index) {

                // 判断已选择数组长度
                if (this.user_selected_superior_data.length >= 5) {
                    return this.$message.error('已达领导最大选择数')
                }

                //  1.把自己从原有的数组中删除
                this.setSuperior__search__result__Data.splice(index, 1)

                // 2. 添加到新数组中去
                this.user_selected_superior_data.push(list)
                // 设置图标
                this.recursionAddIconSetSuperior(this.user_data, list.user_id)

                // 3. 判断一下数组长度
                if (this.setSuperior__search__result__Data.length == 0) {
                    this.is_setSuperior__searchRes = false
                }
            },

            // 递归添加图标
            recursionAddIconSetSuperior(data, user_id) {
                data.forEach(node => {
                    if (node.user_id) {
                        if (node.user_id == user_id) {
                            node['mychecked'] = true
                        } else if (node.children) {
                            this.recursionAddIconSetSuperior(node.children, user_id)
                        }
                    } else if (node.children) {
                        this.recursionAddIconSetSuperior(node.children, user_id)
                    }
                })
            },


            // 递归删掉图标
            recursionDeleteIconSetSuperior(data, user_id) {
                data.forEach(node => {
                    if (node.user_id) {
                        if (node.user_id == user_id) {
                            node['mychecked'] = false
                        } else if (node.children) {
                            this.recursionDeleteIconSetSuperior(node.children, user_id)
                        }
                    } else if (node.children) {
                        this.recursionDeleteIconSetSuperior(node.children, user_id)
                    }
                })
            },

            async searchStaff__setSuperior() {
                this.is_setSuperior__searchRes = true
                let params = this.Form_setSuperior
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'user/select?_ajax=1', params)
                this.setSuperior__search__result__Data = data.users
            },

            deleteSelectedSuperior(node) {
                let i = this.user_selected_superior_data.indexOf(node)
                this.user_selected_superior_data.splice(i, 1)
                this.recursionDeleteIconSetSuperior(this.user_data, node.user_id)
            },


            async submitSelectedSuperiorSubordinateDo() {
                let obj = {
                    id: this.see_current_user_id,
                    leader_ids: [],
                    subordinate_ids: []
                }

                this.user_selected_superior_data.forEach(node => {
                    if (node.id) {
                        obj.leader_ids.push(node.id)
                    } else {
                        obj.leader_ids.push(node.user_id)
                    }
                })


                let params = obj
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'user/set_leader?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_setSuperior = false
                this.getStaffDetail()
            },

            async getStaffDetail() {
                let params = {
                    id: this.see_current_user_id
                }
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'user/detail?_ajax=1', params)
                this.staff_detail_data = data
            },





            // 设置员工标签
            setUserTag() {
                this.is_setUserTag = true
                // 初始化数据

                this.staff_detail_data.tags.forEach(node => {
                    this.label_data.forEach(val => {
                        if (val.id == node.id) {
                            this.checkUserTagList.push(val)
                        }
                    })
                })

            },

            async submitUserTagDo() {
                let params = {
                    id: this.see_current_user_id,
                    tag_ids: []
                }
                this.checkUserTagList.forEach(node => {
                    params.tag_ids.push(node.id)
                })

                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'user/set_tag?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_setUserTag = false
                this.getStaffDetail()
            },


            // 修改员工
            editUser() {
                // this.getUserInfoAfterEdit()


                const p = new Promise((resolve, reject) => {
                    this.initValuation()
                    resolve()
                })

                p.then(() => {
                    this.is_edit_user = true
                })


            },

            initValuation() {


                this.form_edit_user.name = this.edit_user_row.name
                this.form_edit_user.mobile = this.edit_user_row.mobile
                this.form_edit_user.telephone = this.edit_user_row.telephone
                this.form_edit_user.email = this.edit_user_row.email
                this.form_edit_user.id = this.edit_user_row.id
                if (this.edit_user_row.attributes.length > 0) {
                    this.edit_user_date_onBoard = this.edit_user_row.attributes[0].value
                }
                this.form_edit_user.is_enable = this.edit_user_row.is_enable
                this.form_edit_user.is_leader = this.edit_user_row.is_leader
                this.form_edit_user.position_id = this.edit_user_row.position_id

                this.currentDepartmentAllId = []
                this.edit_user_department = []
                this.recursionAllDepartmentId(this.department_data, this.edit_user_row.department_id)
                this.recursionGetDepartmentAllPid(this.department_data, this.currentDepartmentInfo.parent_id)
                this.currentDepartmentAllId.unshift(this.edit_user_row.department_id)
                this.edit_user_department = this.currentDepartmentAllId.reverse()
            },


            // 递归获取所有部门名称
            // 递归获取部门父id
            recursionAllDepartmentId(data, id) {

                data.forEach(node => {
                    if (node.id == id) {

                        this.currentDepartmentInfo = node
                    } else if (node.children) {

                        this.recursionAllDepartmentId(node.children, id)
                    }
                })
            },

            // 递归获取所有父id
            recursionGetDepartmentAllPid(data, id) {
                data.forEach(node => {
                    if (node.id == id) {
                        this.currentDepartmentAllId.push(node.id)
                        this.recursionGetDepartmentAllPid(this.department_data, node.parent_id)
                    } else if (node.children) {
                        this.recursionGetDepartmentAllPid(node.children, id)
                    }
                })
            },

            cancelEditUser() {
                this.is_edit_user = false
            },



            async editUserSaveDo() {
                this.bfullscreen = true
                // 赋值扩展属性
                let obj = {
                    id: 1,
                    value: this.edit_user_date_onBoard
                }
                this.form_edit_user.attributes.push(obj)

                // 赋值部门id
                this.form_edit_user.department_id = this.edit_user_department[this.edit_user_department.length - 1]
                let params = this.form_edit_user
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'user/edit?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.is_edit_user = false
                this.is_staff_detail = true
                this.getUserInfoAfterEdit()
                this.bfullscreen = false

            },

            async getUserInfoAfterEdit() {
                let params = {
                    id: this.see_current_user_id
                }
                const {
                    data
                } = await this.$https.post(this.apiConfig.erp + 'user/detail?_ajax=1', params)

                if (data.length == 0) return this.$message.error('获取员工详情失败')
                this.staff_detail_data = data
                this.edit_user_row = data
            },

            verifyEditUserName() {
                // this.form_edit_user.name=this.form_edit_user.name.replace(

                // )
            },


            // 查看客户详情
            async jump(row) {
                this.cusDetail_drawer = true
                let params = {
                    customer_number: row
                }

                const res = await this.$https.post(this.apiConfig.erp + 'customer/detail?_ajax=1', params)
            },


            remoteMethod(data){
                this.loading=true
                let params={
                    keyword:data
                }
                this.getPosition(params) 
            },

            fnClearPosition(){
                this.getPosition() 
            },

           async fnEditPosition(row){
                this.bEditPosition=true;
                this.nPositionId=row.id
                let params={
                    id:row.id
                }
                const res = await this.$https.post(this.apiConfig.erp + 'user/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.nEditPositionId=res.data.position_id
            },

           async fnEditPositionDo(){
                let params={
                 id:  this.nPositionId,
                 position_id:this.nEditPositionId
                }
                const res = await this.$https.post(this.apiConfig.erp + 'user/set_position?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.$message.success(res.data.msg)
                this.bEditPosition=false;
               
                this.seeJobDetail()
                this.getJobData()
            },












        },
    }
</script>

<style lang="less" scoped>
    [v-cloak] {
        display: none;
    }

    #app {
        overflow: auto;
        width: auto;
        height: auto;
        box-sizing: border-box;
    }

    ul,
    li {
        list-style: none;
        text-decoration: none;
        padding: 0;
    }

    .icon-24gf-tags4 {
        margin-right: 5px;
    }

    .el-breadcrumb {
        font-size: 14px;
        margin-bottom: 20px;
    }

    /deep/.el-card__body {
        padding: 0px !important;
        display: flex;
        box-sizing: border-box;

    }

    .el-card {
        height: auto;
    }

    .left {
        width: 15%;
        height: 90vh;
        background-color: #f9fafc;
        // padding: 20px;
        box-sizing: border-box;
        // overflow-y: auto;

    }

    .right {
        width: 85%;
        height: auto;
        background-color: #fff;
        padding: 20px;

        box-sizing: border-box;
    }



    .inputSearch {
        width: 99%;
        margin-top: 10px;
        margin-left: 50%;
        transform: translate(-50%);
        box-sizing: border-box;

    }

    .el-tabs {
        margin-top: 10px;
        height: 94%;
    }

    .custom-tree-node,
    .bindUserGroup-tree-node,
    .DAM-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 14px;
        padding-right: 24px;
        font-size: 14px;
        font-family: Arial, Helvetica, sans-serif;
        color: #000000;
        margin-bottom: 8px;
    }


    .icon-shudian {
        margin-left: 100%;
        color: #606266;
        // font-size: 12px;
    }

    .departmentDo {
        display: flex;
        flex-direction: column;
    }

    .labelintro {
        font-size: 14px;
        font-family: Arial, Helvetica, sans-serif;
        color: #606266;
        margin: 6px 0;
        text-align: left;
        padding: 6px 10px;
        transition: 0.1s ease-in-out;
    }

    .labelintro:hover {
        cursor: pointer;
    }


    .labelintrohover {
        background-color: #4a77ac;
        cursor: pointer;
        color: #fff;
    }

    /deep/.el-tree-node:focus>.el-tree-node__content {
        background-color: #4a77ac !important;
    }

    /deep/.addDAMElTree .el-tree-node:focus>.el-tree-node__content {
        background-color: #f9fafc !important;
    }

    .el-tree-node:focus>.el-tree-node__content .custom-tree-node {
        color: #fff !important;
    }



    /deep/.el-tree-node__content:hover {
        background-color: #edf1f5;
    }

    /deep/.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
        background-color: #f9fafc;
        color: #fff !important;
    }


    .icon-wenjian,
    .icon-yonghu {
        padding: 0;
        margin-right: 5px;
        color: #7da4d1;
    }




    .buttons {
        margin-top: 20px;
        width: 97%;
        height: 40px;
        background-color: #dfe9f5;
        display: flex;
        align-items: center;
        padding: 0 20px;
        position: relative;
    }


    .duty_data {
        height: 600px;
        overflow-y: scroll;
    }

     .staff_data {
        height: auto;
    }

    .form_branch_name_father {
        color: #333;
    }

    .el-icon-info {
        font-size: 15px;
        margin-right: 5px;
    }

    .delete_department {
        font-size: 18px;
        margin: 0 5px;
        font-weight: 600;
        color: #333;
    }

    .position_dropdown {
        height: 300px;
        overflow-y: scroll;
    }

    .el-tag {
        margin-left: 10px;
        margin-bottom: 10px;
    }

    .tagsBtn {
        margin-left: 15px;
    }

    .chooseLeader {
        display: flex;
        height: auto;
    }


    .chooseLeadereltree {
        width: 260px;
        height: 560px;
        overflow-y: auto;
    }

    /deep/ .el-dialog__body {
        background-color: #fdfdfd !important;
        height: auto !important;

    }

    .chooseLeadereltree::-webkit-scrollbar {
        width: 6px;
    }

    .chooseLeadereltree::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(221, 222, 224);
    }

    .chooseLeadereltree::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
    }


    .setSupSubStyle::-webkit-scrollbar,
    .eltreeleft::-webkit-scrollbar {
        width: 6px;
    }

    .label_UL--style::-webkit-scrollbar {
        width: 5px;
    }

    .setSupSubStyle::-webkit-scrollbar-thumb,
    .label_UL--style::-webkit-scrollbar-thumb,
    .eltreeleft::-webkit-scrollbar-thumb {

        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(221, 222, 224);
    }






    // .el-divider {
    //     height: 560px;
    //     margin-left: 20px;
    //     margin-right: 50px;
    // }



    .labelList {
        width: 99%;
        height: 1px;
        border-top: 1px solid #dcdfe6;

    }

    .chooseLeadesRight {
        width: 220px;
        height: 560px;
        overflow-y: auto;
    }

    .chooseLeadesRight h4 {
        color: #787878;
        margin-bottom: 16px;
    }

    .addLabel .el-button {
        width: 85%;
        margin-top: 20px;
    }

    .delete_label {
        display: inline;
        margin: 0 10px;
        display: flex;
        align-items: center;
    }

    .staff_name {
        display: flex;
        align-items: center;
        // justify-content:start;
        margin-left: 30%;
    }

    .icon-fuzeren {
        font-size: 38px;
        color: #4a77ac;
        margin-left: 10px;
    }

    .el-tabs--border-card {
        background-color: #f9fafc;
    }

    /deep/.el-tabs--border-card>.el-tabs__header .el-tabs__item {
        background-color: #999;
        color: #fff;
    }

    /deep/.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
        background-color: #f9fafc;
        color: #333;
    }

    /deep/.el-tabs__item {
        font-size: 14px;
    }

    .el-tree {
        background-color: #f9fafc;
    }

    .createJob {
        // margin-left: 3%;
    }

    /deep/.sort .el-input__inner {
        width: 153%;
    }

    /deep/.addjob .el-dialog__body {
        display: flex;
        justify-content: start;
    }

    .addLabelStyle {
        padding: 0% 3%;
        box-sizing: border-box;
    }

    .tit {
        position: relative;
    }

    .backbtnlabel {
        position: absolute;
        right: 2%;
        top: 0;
    }

    .addLabelTitleStyle {
        font-size: 22px;
        color: #1f2f3d;
        font-family: Helvetica, sans-serif;
    }

    /deep/.addLabeinput .el-input__inner {
        width: 30%;
    }

    /deep/ .editUserDepartmentStyle {
        width: 22%;
    }







    .addAuth {
        margin-left: 20px;
    }

    .authority {
        position: relative;
    }

    .authorityLi {
        background-color: #f8f8f8;
        border-radius: 5px;
        padding: 0 1%;
    }

    .authoritySonStyle,
    .authorityStyle {
        display: flex;
        justify-content: space-between;
        padding-left: 1%;
        padding-right: 5%;

    }

    .authorityStyle {
        font-size: 15px;
    }

    .authoritySonStyleTit {
        font-weight: 600;
    }

    .authorityStyleTit {
        font-weight: 700;
        color: #ff2424;

    }


    .submitBtn {
        margin-left: 10%;
        margin-top: 10px;
    }

    /deep/.addDAMDialog .el-dialog__body,
    /deep/.bindUserGroup .el-dialog__body,
    /deep/.addLeaderStyle .el-dialog__body {
        height: 500px !important;
        overflow-y: hidden !important;
    }

    .all {
        display: flex;
        justify-content: start;
        width: 100%;
    }

    .all .left {
        flex: 7;
        position: relative;
        background-color: #fdfdfd;
    }

    .all .right {
        flex: 6;
        padding-left: 3%;
        background-color: #fdfdfd;
        border-left: 1px solid #e4e6e9;
        margin-left: 5%;
        padding-top: 0;
    }

    .all .right .tit {
        color: #787878;
    }

    .itemStyle {
        margin-bottom: 8px;
        position: relative;
    }

    .closeIcon {
        font-weight: 1000;
        color: #ababab;
        position: absolute;
        right: 6%;
    }


    .closeIcon:hover {
        cursor: pointer;
    }


    .all .left .input-select {
        position: absolute;
        top: 1%;
        left: 0;
        width: 90%;
    }

    /deep/.el-input__inner {
        // border-width: 1px;
    }



    .eltreeleft {
        margin-top: 20%;
        margin-left: 3%;
        height: 410px;
        overflow-y: auto;
    }

    .setSupSubStyle {
        margin-top: 1%;
        height: 480px;
        overflow-y: auto;
    }

    .setSupSubStyle .tit {
        font-size: 15px;
    }

    .setSupSubStyle .tit span {
        font-size: 13px;
    }



    /deep/ .el-tree {
        // background-color: #fdfdfd !important;
    }





    .DAMStyle {
        color: #7da4d1;
        margin: 0px 6px 0 0px;
        font-size: 16px;
    }

    .ckeckStyle {
        margin-left: 6px;
    }

    .searchResult .memberArea .tit,
    .searchResult .departmentArea .tit {
        color: #787878;
        font-size: 13px;
        margin-bottom: 10px;
    }

    .searchResult .memberArea .detail,
    .searchResult .departmentArea .detail {
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
    }

    .searchResult .memberArea .detail .item,
    .searchResult .departmentArea .detail .item {
        margin-bottom: 2px;
        padding: 5px 5px 5px 10px;
        border-radius: 1px;
    }

    .activeClickStyle {
        background-color: #4a77ac;
        color: #fff;
    }

    .clickStyle:hover {
        background-color: #f2f2f2;
    }

    .searchResult .memberArea .detail:hover,
    .searchResult .departmentArea .detail:hover {
        cursor: pointer;
    }

    .specialBgcStyle:hover {
        background-color: #f2f2f2;
    }

    .iconColor {
        color: #7da4d1;
        font-size: 20px;
        margin: 0px 6px 0 3px;
    }


    // 添加员工部分------------------------------------------
    .addStaffArea,
    .seeStaffArea {
        width: 85%;
        height: auto;
        background-color: #fff;

        box-sizing: border-box;
        position: relative;
    }



    .addStaffArea .tit,
    .seeStaffArea .tit {
        margin-top: 2%;
        font-size: 18px;
        color: #000;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        position: relative;
    }

    .addStaffGoBackBtn {
        position: absolute;
        right: 5%;
    }

    .addStaffArea .content {
        width: 50%;
        padding-top: 20px;
    }

    .input-add-staff {
        width: 60%;
        height: 10px;
    }

    .addStaffSubmit {
        position: absolute;
        bottom: 2%;
        left: 26%;
    }

    .tips {
        color: #aaaaaa;
        margin-left: 20px;
        font-size: 14px;
    }



    .seeStaffArea .content .functionZon {
        background: #dfe9f5;
        margin: 20px 0;
        padding: 5px 10px;
    }

    .seeStaffArea .content .first {
        padding-left: 2%;
        margin-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px dotted #e4e6e9;
        font-size: 14px;
        color: #787878;
    }

    .seeStaffArea .content .first div {
        margin-top: 10px;
    }

    .seeStaffArea .content .first div:first-child span {
        font-size: 18px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        color: #000000;
    }



    .seeStaffArea .content .second,
    .seeStaffArea .content .fourthly,
    .seeStaffArea .content .third {
        padding-left: 2%;
        padding-bottom: 20px;
        border-bottom: 1px dotted #e4e6e9;
    }

    .seeStaffArea .content .fourthly {
        border-bottom: 0px dotted #e4e6e9;
    }

    .seeStaffArea .content .second div,
    .seeStaffArea .content .fourthly div,
    .seeStaffArea .content .third div {
        margin-top: 10px;
    }

    .seeStaffArea .content .third div {
        margin-top: 20px;
    }

    .seeStaffArea .content .fourthly div {
        margin-top: 20px;
    }


    .seeStaffArea .content .second div span:first-child,
    .seeStaffArea .content .fourthly div span:first-child,
    .seeStaffArea .content .third div span:first-child {
        font-size: 14px;
        color: #787878;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .seeStaffArea .content .second div span:nth-of-type(n+2),
    .seeStaffArea .content .fourthly div span:nth-of-type(n+2),
    .seeStaffArea .content .third div span:nth-of-type(n+2) {
        color: #000;
        font-size: 14px;
        margin-left: 8px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .bindUserGroups {
        padding-top: 1%;
    }

    /deep/ .editUserCustomerStyle .el-dialog__body {
        display: flex;
        flex-direction: column;
    }

    .editUserCustomerStyle .conditionArea {
        width: 95%;
        padding: 20px;
        padding-bottom: 10px;
        border-radius: 2px;
        background-color: #f5f7fa;
    }

    .editUserCustomerStyle .conditionArea .top,
    .editUserCustomerStyle .conditionArea .bottom {
        display: flex;
    }

    .editUserCustomerStyle .conditionArea .top span,
    .editUserCustomerStyle .conditionArea .bottom span {
        flex: 1;
        display: flex;
        justify-content: start;

    }

    .editUserCustomerStyle .conditionArea .top span span,
    .editUserCustomerStyle .conditionArea .bottom span span {
        color: 333;
        font-size: 14px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

    }

    .editUserCustomerStyle .conditionArea .top span span span {}




    /deep/.editUserCustomerStyle .conditionArea .top span .search {
        width: 70%;

    }

    /deep/.editUserCustomerStyle .conditionArea .top span .search .el-input__inner {
        border: 1px solid #eee;
    }

    /deep/.editUserCustomerStyle .conditionArea span .el-input__inner {
        border: 1px solid #eee;
    }

    .el-select-dropdown__list {
        padding: 0 20px;
    }

    .filterArea {
        margin-top: 10px;
        margin-left: 5%;
    }

    .disabledNodeStyle {
        color: #787878;
    }

    .labelNodesStyle {
        display: flex;
        flex-wrap: wrap;
    }

    .labelNodeStyle {
        width: 20%;
        margin-bottom: 10px;
    }

    .tagNodeStyle {
        font-size: 14px;
        margin-right: 10px;
    }

    .editUserInputStyle {
        width: 20%;
    }

    // /deep/.editUserInputStyle .el-input__inner{
    //     &::placeholder{
    //         font-size: 13px;
    //     }
    // }

    .editUserTextStyle {
        color: #0c4c94 !important;
        font-size: 20px;
    }

    .editUserTextStyle:hover {
        cursor: pointer;
        text-decoration: underline
    }

    .editTagNodeStyle {
        border: 1px solid #e4e6e9;
        padding: 5px 11px 5px 7px;
        border-radius: 2px;
        margin-top: 100px;
    }

    .editUserIconStyle {
        font-size: 14px;
        color: #7da4d2;
    }

    .editUserNameStyle {
        color: #787878 !important;
        font-size: 14px !important;
    }

    .titleDepartmentStyle {
        font-size: 18px;
        color: #000000;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    /deep/.el-radio-group {
        vertical-align: 0%;
    }

    .spanTit {
        margin-right: 10px;
    }

    .setSuperior__search__style {
        width: 90%;
        margin-bottom: 15px;
    }

    .setSuperior__search__result__style {
        padding: 10px;
    }

    .setSuperior__search__result__style .content {
        display: flex;
        flex-direction: column;
    }

    .setSuperior__search__result__style .content .item {
        margin-top: 10px;
        padding: 5px 5px 5px 10px;
    }

    .hover__setsuperior__style:hover {
        background-color: #f2f2f2;
        cursor: pointer;
        border-radius: 5px;
    }

    .active__setsuperior__style {
        background-color: #4a77ac;
        cursor: pointer;
        color: #fff;
    }

    .label__icon--style {
        color: #4a77ac;
        margin-right: 5px;
        font-size: 16px;
    }

    .label_UL--style {
        height: 500px;
        overflow-y: auto;
    }



    .cusDetail_drawer_style .tit {
        font-weight: 520;
        font-size: 18px;
        text-align: center;
        margin-bottom: 10px;
    }

    .cusDetail_drawer_style .content div {
        margin: 5px 0;
        font-size: 14px;
        color: #333;
    }


    div /deep/ .department_choose_style .el-input {
        width: 150%;
    }


    .seditDepartFormStyle {
        width: 100%;

    }


    div /deep/ .seditDepartStyle .el-cascader {
        width: 100%;
    }

    .inputWidth{
        width: 60%;
    }

    div /deep/ .el-dropdown-item{
        background-color: pink;
    }
</style>