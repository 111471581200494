<template>
    <div id="app">
       
        <!-- 布局 -->
        <div class="purchaseLayout">
<!-- 
            面包屑导航
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>采购入库</el-breadcrumb-item>
                <el-breadcrumb-item>产品报废</el-breadcrumb-item>
            </el-breadcrumb> -->

            <el-card>
                <!-- 工具栏部分 -->
                  <div class="addProduct">
                    <div class="labelName">
                        选择仓库:
                    </div>
                    <el-select v-model="ProductScrap_warehouse" clearable size="medium">
                        <el-option v-for="item in warehouseData" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>

                    <div class="labelName secondLabelName">
                        选择时间:
                    </div>
                    <el-date-picker v-model="chooseDateRangeWHScrap" type="daterange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" size="medium">
                    </el-date-picker>


                    <el-button style="margin-left:30px" class="frontBtn" icon="el-icon-search" size="mini"
                        @click="SearchWHScrap">搜 索
                    </el-button>

                     <el-button style="margin-left:10px" class="reverseBtn" icon="el-icon-refresh-left" size="mini"
                        @click="resetBtn">重 置
                    </el-button>

                       <el-button  class="reverseBtn backBtn" size="small"
                        @click="goBack">返 回
                    </el-button>
                </div>

                   <el-button   class="frontBtn createBtn"  size="small"
                        @click="addWHScrap">创建报废单
                    </el-button>


                <!-- 数据表格 -->
                  <el-table tooltip-effect="dark" style="width: 100%" 
                 :header-cell-style="styleBindFun"
                :data="Product_Scrap_data" align="center" >
                    <el-table-column prop="order_num" label="报废单" align="center" width="200">
                    </el-table-column>
                    <el-table-column prop="store_name" label="仓库" align="center" width="130">
                    </el-table-column>
                    <el-table-column prop="products_count" label="种类" align="center" width="160">
                    </el-table-column>
                    <el-table-column prop="products_quantity" label="数量" align="center" width="160">
                    </el-table-column>
                    <el-table-column prop="create_time" label="报废时间" align="center">
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button @click="seeBFDetail(scope.row)" type="text" class="text_primary" size="big">查看
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

               <!-- 分页器 -->
                <el-pagination @size-change="handleSizeChangeBFgetInfo" @current-change="handleCurrentChangeBFgetInfo"
                    :current-page="BFgetInfo.page" :page-sizes="[5, 10, 20, 50]" :page-size="BFgetInfo.per_page"
                    layout="total, sizes, prev, pager, next, jumper" :total="BFTotal" align="center">
                </el-pagination>
            </el-card>
        </div>

         
            <!-- 查看报废单详情对话框 -->
            <el-dialog title="报废单详情" :visible.sync="is_WHScrapDetail" width="60%" class="WHScrapDetail_style">
                
                
                <el-table tooltip-effect="dark" style="width: 100%" :data="WHScrapDetail" align="center" :header-cell-style="styleBindFun"  border >

                    <el-table-column prop="product_sn" label="货号" align="center" width="120">
                    </el-table-column>
                    <el-table-column prop="brand_name" label="品牌" align="center" width="120">
                    </el-table-column>
                    <el-table-column label="产品名称" align="center" width="320">
                        <template slot-scope="scope">
                            {{scope.row.product_name}} ({{scope.row.product_spec}})
                        </template>
                    </el-table-column>
                   
                    <el-table-column label="报废前数量" align="center">
                        <template slot-scope="scope">
                            {{scope.row.before_quantity}} {{scope.row.unit_name}}
                        </template>
                    </el-table-column>
                    <el-table-column label="报废后数量" align="center">
                        <template slot-scope="scope">
                            {{scope.row.after_quantity}} {{scope.row.unit_name}}
                        </template>
                    </el-table-column>

                     <el-table-column label="报废数量" align="center">
                        <template slot-scope="scope">
                            {{scope.row.quantity}} {{scope.row.unit_name}}
                        </template>
                    </el-table-column>
                </el-table>
                <div class="remarks">
                    备注:{{WHScrapRemarks}}
                </div>
                    
                

                <div slot="footer" class="dialog-footer">
                    <el-button size="mini" class="frontBtn" @click="is_WHScrapDetail = false">确 定</el-button>
                </div>
            </el-dialog>


       


    </div>
</template>

<script>
      import {
 erpApi
    } from '../../config'
   
   
    export default {
       

        data() {
            return {
                warehouseData:[],//仓库数据

              is_ProductScrap_state: false, //控制产品报废对话框的显示与隐藏
                ProductScrap_warehouse: '', //产品报废选择的仓库
                chooseDateRangeWHScrap: [], //产品报废选择时间范围
                //  获取报废单添加条件
                BFgetInfo: {
                    page: 1, //当前页
                    per_page: 5, //页面显示个数
                    start_date: "", //搜索条件开始时间
                    end_date: '', //搜索条件结束时间
                    store_id: '',
                },
                BFTotal: 0,
                Product_Scrap_data: [],
                WHScrapProductsData: [], //添加产品（报废单)  数组
                is_addWHScrap: false, //控制添加报废单的显示与隐藏
                is_addWHScrapProducts: false, //控制添加报废单产品的显示与隐藏
              
                chooseWHScrapProductsObj: {
                    product_id: '',
                    quantity: 0,
                }, //报废单添加产品表单
                chooseWHScrapProductMax: 0, //添加产品（报废单）最大数
                is_WHScrapDetail: false, // 控制查看报废单详情的显示与隐藏
                WHScrapDetail: [], //查看报废单详情的数据

                WHScrapRemarks:'',


              
            }
        },
        created() {
          this.getScrap()
          this.getWareHouse()
        },
        methods: {
             // 绑定表格头部样式方法
            styleBindFun(){
                let  styleBind="background:#f6f8fa;fontSize:14px;color:#333333;fontWeight: bold;"
              return styleBind;
            },

              // 获取仓库列表
            async getWareHouse() {
                const res = await this.$https.post(erpApi+'/store/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.warehouseData = res.data
            },


           async  getScrap(){
                let params = this.BFgetInfo
                const res = await this.$https.post(erpApi+'/drops?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
         
                this.Product_Scrap_data = res.data.data
                this.BFTotal = res.data.total
            },
               handleSizeChangeBFgetInfo(data) {
                this.BFgetInfo.per_page = data
                this.getScrap()
            },
            handleCurrentChangeBFgetInfo(data) {
                this.BFgetInfo.page = data
                this.getScrap()
            },



             // 搜索报废单的方法
            SearchWHScrap() {
                if (this.chooseDateRangeWHScrap == null) {
                    this.chooseDateRangeWHScrap = []
                }
                this.BFgetInfo.start_date = this.chooseDateRangeWHScrap[0]
                this.BFgetInfo.end_date = this.chooseDateRangeWHScrap[1]
                this.BFgetInfo.store_id = this.ProductScrap_warehouse
                this.getScrap()
            },
            // 创建报废单方法
            addWHScrap() {
                // this.form_add_WHScrap.store_id = ''
                // this.form_add_WHScrap.products = []
                // this.form_add_WHScrap.remarks = ''
                // this.is_addWHScrap = true
                this.$router.push({
                    path:'/addProductScrap',
                    query:{
                        data:this.warehouseData
                    }
                })
            },
            async addWHScrapDo() {
                this.form_add_WHScrap.products.forEach(item => {
                    delete item.name
                })
                let params = this.form_add_WHScrap
                const res = await this.$https.post(erpApi+'/drop/create?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.$message.success('报废单添加成功！')
                this.is_addWHScrap = false
                this.ProductScrap()
            },



            // 查看报废单详情方法
            async seeBFDetail(row) {
                this.is_WHScrapDetail = true
                console.log(row, 'rowrow');
                let params = {
                    order_num: row.order_num
                }
                const res = await this.$https.post(erpApi+'/drop/detail?_ajax=1', params)
                console.log(res,'res123');
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.WHScrapDetail = res.data.stocks
                this.WHScrapRemarks=res.data.remarks
            },



            // 重置
            resetBtn(){
                this.chooseDateRangeWHScrap=[]
                this.ProductScrap_warehouse=''
            },   

             // 返回
            goBack() {
                this.$router.push({
                    path:'/purchase'
                })
            },







        },
    }
</script>

<style lang="less" scoped>
.warehousing{
    
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.el-card{
    height: 87vh;
}



    // -------------------------------------------
       .addProduct {
        padding: 10px 15px;
        display: flex;
        justify-content:start;
        margin: 0;
        position: relative;
        background-color: #f5f7fa;
    }

     .addProduct .labelName {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #333;
    }

     .secondLabelName {
         margin-left: 30px;
         
     }

    .backBtn{
        position: absolute;
        right: 5%;
    }

    .createBtn{
       margin-top: 25px;
       margin-left: 20px;
    }

   div  /deep/.WHScrapDetail_style .el-dialog__body{
        display: flex;
        flex-direction: column;
    }

    .WHScrapDetail_style .remarks{
        margin-top: 20px;
        margin-left: 20px;
    }


</style>