<template>
  <div id="app">
    
    <!-- 布局 -->
    <div class="unitSet">
      <div class="unitSet_left">
    
        <!-- 卡片视图区域 -->
        <el-card class="card_left">
          <div>
            <el-button icon="el-icon-circle-plus" size="small" class="frontBtn" @click="addBrandBorder">添加品牌</el-button>
          </div>

          <el-table :data="brandTableData" max-height="auto" style="width:100%" :header-cell-style="styleBindFun">
            <el-table-column prop="name" label="品牌" align="center" width="200">
              <template slot-scope="scope">
                <span>{{scope.row.name}}-{{scope.row.prefix}}</span>
              </template>
            </el-table-column>

            <el-table-column prop="update_time" label="修改时间" align="center" width="200">
            </el-table-column>

            <el-table-column fixed="right" label="操作" width="220" align="center">
              <template slot-scope="scope">
                <el-button @click="editBrand(scope.row)" type="text" class="text_primary" size="mini">编辑</el-button>
                <span class="splitLineClass"></span>
                <el-button @click="deleteBrand(scope.row)" type="text" class="text_delete" size="mini">删除</el-button>
              </template>
            </el-table-column>
          </el-table>

        </el-card>
      </div>


    </div>

    <el-dialog title="增加品牌" :visible.sync="dialogFormVisibleOfadd" width="600px">
      <el-form>
        <el-form-item label="请输入要增加的品牌名称：" :label-width="formLabelWidth">
          <el-input size="small" v-model="addBrandForm.brand_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="请输入要增加的品牌前缀：" :label-width="formLabelWidth">
          <el-input size="small" v-model="addBrandForm.prefix" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini"  @click="dialogFormVisibleOfadd = false">取 消</el-button>
        <el-button  size="mini" class="frontBtn" type="primary" @click="addBrand">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="修改品牌" :visible.sync="dialogFormVisibleOfedit" width="600px">
      <el-form>
        <el-form-item label="当前要修改的品牌名称：" :label-width="formLabelWidth">
          {{currentEditBrand}}
        </el-form-item>
        <el-form-item label="请输入要修改的品牌名称：" :label-width="formLabelWidth">
          <el-input size="small" v-model="editBrandForm.brand_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="请输入要修改的品牌前缀：" :label-width="formLabelWidth">
          <el-input size="small" v-model="editBrandForm.prefix" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogFormVisibleOfedit = false">取 消</el-button>
        <el-button size="mini" class="frontBtn" @click="editLaunchBrand">确 定</el-button>
      </div>
    </el-dialog>

   



 


  </div>
</template>

<script>    
 import {
        adminApi
    } from '../../config'

  export default {
    data() {
      return {
       
        brandTableData: [], //品牌数据
        unitadd: '',
        currentEditBrand: '', //当前要编辑的品牌
        currentEditId: '', //当前要编辑的品牌的id
        currentDeleteBrand: '', //当前要删除的品牌
        currentDeleteId: '', //当前要删除的品牌Id
        dialogFormVisibleOfadd: false, //控制添加品牌弹出框
        dialogFormVisibleOfedit: false, //控制编辑品牌弹出框
        dialogFormVisibleOfdelete: false, //控制删除品牌弹出框
        addBrandForm: {
          brand_name: '',
          prefix: ''
        }, //添加框的输入值
        editBrandForm: {
          brand_name: '',
          prefix: ''
        }, //编辑框的输入值
        formLabelWidth: '200px' //表单宽度
      }
    },
    created() {
      this.getBrand();
    },
     methods: {
         styleBindFun(){
              // let  styleBind="background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                let  styleBind="background:#f6f8fa;fontSize:14px;color:#333333;fontWeight: bold;"
              return styleBind;
            },	

   
      
      // 获取正常使用品牌数据
      async getBrand() {
        // 发送请求获取单位属性数据
        const res = await this.$https.post(adminApi+'/brands?_ajax=1')
        if ( res.status !== 200) {
          return this.$message.error(res.statusText)
        }
        this.brandTableData = res.data
      },
     
      //弹出增加框
      addBrandBorder() {
        this.dialogFormVisibleOfadd = true
        // 重置 
        this.addBrandForm.brand_name = ''
        this.addBrandForm.prefix = ''
      },
      // 增加
      async addBrand() {
        // 关闭添加弹出框
        this.dialogFormVisibleOfadd = false
        // 发送请求增加品牌属性数据
        const res = await this.$https.post(adminApi+'/brand/create?_ajax=1', {
          name: this.addBrandForm.brand_name,
          prefix: this.addBrandForm.prefix,
          sort_order: 100
        });
        if (res.data.error_code !== 0) {
          return this.$message.error( res.data.msg);

        } else {

          this.$message.success('添加成功')
          // 重新调用
          this.getBrand()
        }
      },
      // 编辑
      editBrand(row) {
        // 重置输入值
        this.editBrandForm.brand_name = row.name,
          this.editBrandForm.prefix = row.prefix,
          //  1.打开弹窗
          this.dialogFormVisibleOfedit = true
        //  2.把当前值显示到弹窗里
        this.currentEditBrand = row.name
        // 3.存储需要修改的id值
        this.currentEditId = row.id
      },
      // 编辑完发送
      async editLaunchBrand() {
        // 关闭弹窗
        this.dialogFormVisibleOfedit = false
        // 参数
        let data = {
          id: this.currentEditId,
          name: this.editBrandForm.brand_name,
          prefix: this.editBrandForm.prefix,
        }
        // 3. 点击确定发送修改数据
        const res= await this.$https.post(adminApi+'/brand/edit?_ajax=1', data)
        if (res.data.error_code !== 0) {
          return this.$message.error(res.data.msg)
        } else {
          this.$message.success('修改成功')
          // 重新调用
          this.getBrand()
         
        }
      },

      deleteBrand(row){
            this.$confirm('您确定删除该品牌吗？','提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
          })
          .then(async ()=>{
              let params={
                  id:row.id
              }
              const res=await this.$https.post(adminApi+'/brand/delete?_ajax=1', params)
           if (res.data.error_code !== 0) {
          return this.$message.error(res.data.msg)
        }else{
            this.$message.success(res.data.msg)
            this.getBrand()
        }
          })
             .catch(() => {
                            this.$message.error('您取消了按钮')
        })

      } 
  }}
   
</script>

<style lang="less" scoped>
  #app {
    overflow: hidden;
  }

  .card_left {
    width: 40%;
    padding: 10px;
    height: auto;
    max-height: 600px;
    overflow-y: auto;
  }

  .card_left div:nth-child(1){
      padding-left: 80%;
  }


  #app {
    position: relative;
  }

  .unitSet_left {
    flex: 1;
  }

  .unitSet_right {
    flex: 1;
    margin-top: 50px;

  }

  .card_right {
    margin-top: 20px;
    width: 55%;
  }

  .unitSet_right .word {
    color: #606266;
    font-weight: 500;
  }



  .icon-huishouzhan {
    font-size: 20px;
    color: #606266;
    margin-right: 10px;
  }

  .recycle_bin {

  }

  .recycle_card {
    margin-top: 20px;
  }

    /* el-table */
 div /deep/.el-table__body .el-table__row  .el-table__cell{
         padding: 10px
 }
</style>