<template>
    <div id="app">

        <!-- 布局 -->
        <div class="purchaseLayout">
            <!-- 面包屑导航 -->
            <!-- <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>采购入库</el-breadcrumb-item>
                <el-breadcrumb-item>仓库盘点</el-breadcrumb-item>
            </el-breadcrumb> -->
        </div>

        <el-card>

            <!-- <div class="backBtn">
            <el-button size="small" class="reverseBtn" @click="goBack">返 回</el-button>
            </div> -->


            <!-- 仓库盘点对话框 -->

            <el-tabs type="card" @tab-click="WHCheckTabs">
                <el-tab-pane label="产品库存批次列表">
                    <div class="addProduct ">

                        <span>
                            选择仓库:
                            <el-select v-model="check_warehouse" size="small" clearable @change="WareHouseCheckBYWHId">
                                <el-option v-for="item in warehouseData" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </span>


                        <span>
                            关键词:
                            <el-input size="small" class="CPKCInput" placeholder="请输入内容"
                                @keyup.enter.native="CPKCsearch" v-model="CPKCInput" clearable>
                            </el-input>
                        </span>

                        <el-button size="mini" class="frontBtn" icon="el-icon-search" clear
                            @keyup.enter.native="CPKCsearchenter" @click="CPKCsearch">搜 索
                        </el-button>
                        <el-button size="mini" class="reverseBtn" icon="el-icon-refresh" @click="CPKCreset">重 置
                        </el-button>
                    </div>

                    <el-table
                        :header-cell-style="{background:'#f2f3f5',fontSize:'14px',color:'#1D2129', fontWeight: 'normal'}"
                        tooltip-effect="dark" style="width: 100%" :data="Check_WareHouse_data" align="center" border>
                        <el-table-column prop="sn" label="货号" align="center">
                        </el-table-column>

                        <el-table-column label="产品名称" align="center" width="300">
                            <template slot-scope="scope">
                                {{scope.row.product_name}} <span v-show="scope.row.spec">({{scope.row.spec}})</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="产品个数" align="center">
                            <template slot-scope="scope">
                                {{scope.row.quantity}} {{scope.row.unit_name}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="store_name" label="存放仓库" align="center">
                        </el-table-column>
                        <el-table-column fixed="right" label="操作" align="center">
                            <template slot-scope="scope">
                                <el-dropdown v-show="scope.row.is_batch">
                                    <span class="el-dropdown-link">
                                        查看批次<i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item v-for="list  in scope.row.batchs" :key="list.id">
                                            {{list.name}} <span class="validityDateStyle">限用日期：{{list.expiry_date}}
                                                数量：<span class="NumberNoLeft">{{list.pivot.quantity}}</span>
                                            </span>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </el-table-column>
                    </el-table>


                    <!-- 分页器 -->
                    <el-pagination @current-change="handleCurrentChangePDgetInfo" :current-page="PDgetInfo.page"
                        :page-sizes="[9]" :page-size="PDgetInfo.per_page"
                        layout="total, sizes, prev, pager, next, jumper" :total="PDTotal" align="center">
                    </el-pagination>
                </el-tab-pane>


                <el-tab-pane label="仓库库存批次列表">
                    <div class="addProduct ">

                        <span>
                            选择仓库:
                            <el-select v-model="check_warehouse" size="small" clearable @change="chooseWarehouseDo">
                                <el-option v-for="item in warehouseData" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </span>
                    </div>

                    <el-collapse accordion class="detailDesc--Style" v-model="activeNames">
                        <el-collapse-item v-for="(list,index) in warehouse__inventory__data" :key="index"
                            :title="list.name" :name="index">
                            <div class="watehouse__inventory--desc">
                                <span>
                                    <el-tag type="small" v-if="list.is_use">已启用</el-tag>
                                    <el-tag v-else type="info">未启用</el-tag>
                                </span>

                                <span>
                                    存放产品种类：<span class="Number">{{list.storages_count}}</span>
                                </span>

                                <span>
                                    存放产品数量：<span class="Number">{{list.storages_sum}}</span>
                                </span>
                            </div>

                            <div>
                                <el-table
                                    :header-cell-style="{background:'#f2f3f5',fontSize:'14px',color:'#1D2129', fontWeight: 'normal'}"
                                    tooltip-effect="dark" style="width: 100%" :data="list.storages" align="center"
                                    border height="500">
                                    <el-table-column prop="sn" label="货号" align="center">
                                    </el-table-column>

                                    <el-table-column label="产品名称" align="center" width="300">
                                        <template slot-scope="scope">
                                            {{scope.row.product_name}} <span
                                                v-show="scope.row.spec">({{scope.row.spec}})</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="数量" align="center">
                                        <template slot-scope="scope">
                                            {{scope.row.quantity}} {{scope.row.unit_name}}
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="所有数量" align="center">
                                        <template slot-scope="scope">
                                            {{scope.row.storage_total_quantity}} {{scope.row.unit_name}}
                                        </template>
                                    </el-table-column>

                                    <el-table-column fixed="right" label="操作" align="center">
                                        <template slot-scope="scope">
                                            <el-dropdown v-show="scope.row.is_batch">
                                                <span class="el-dropdown-link">
                                                    查看批次<i class="el-icon-arrow-down el-icon--right"></i>
                                                </span>
                                                <el-dropdown-menu slot="dropdown">
                                                    <el-dropdown-item v-for="list  in scope.row.batchs" :key="list.id">
                                                        {{list.name}} <span
                                                            class="validityDateStyle">限用日期：{{list.expiry_date}}
                                                            数量：<span class="NumberNoLeft">{{list.pivot.quantity}}</span>
                                                        </span>
                                                    </el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                        </template>
                                    </el-table-column>
                                </el-table>

                            </div>
                        </el-collapse-item>
                    </el-collapse>



                </el-tab-pane>

                <el-tab-pane label="商品库存列表">
                    <div class="addProduct">
                        <span>
                            请选择仓库：
                            <el-select v-model="check_warehouse_goods" clearable size="small">
                                <el-option v-for="item in warehouseData" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </span>

                        <span>
                            请选择商品分类：
                            <el-cascader :options="check_warehouse_category_Data" size="small" :show-all-levels="false"
                                :props="{value:'id',label:'name',checkStrictly: true}" v-model="currentGoodsCateId"
                                clearable></el-cascader>
                        </span>

                        <el-button size="small" class="frontBtn" icon="el-icon-search" @click="GoodsKCListSearch">搜 索
                        </el-button>
                        <el-button size="small" class="reverseBtn" icon="el-icon-refresh" @click="GoodsKCListReset">重 置
                        </el-button>
                    </div>

                    <div class="goodsCheckRightContent" style="margin-top:20px;">
                        <el-table
                            :header-cell-style="{background:'#f5f7fa',fontSize:'14px',color:'#1D2129', fontWeight: 'normal'}"
                            tooltip-effect="dark" :data="PDGoodsData" align="center" border style="width:100%">
                            <el-table-column prop="sn" label="商品单号" align="center">
                            </el-table-column>
                            <el-table-column prop="name" label="商品名" align="center" width="260">
                            </el-table-column>
                            <el-table-column prop="price" label="商品价格（元）" align="center" width="120">
                            </el-table-column>
                            <el-table-column prop="end_time" label="商品下架日期" align="center" width="120">
                                <template slot-scope="scope">
                                    <span v-show="scope.row.end_time !==0">{{scope.row.end_time}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="sell_min_num" label="最少可售数量" align="center" width="120">
                            </el-table-column>
                            <el-table-column prop="sell_max_num" label="最大可售数量" align="center" width="120">
                            </el-table-column>

                            <el-table-column label="是否可售" align="center" width="110">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.is_sell" class="yesIcon el-icon-check"></div>
                                    <div v-else class="noIcon el-icon-close"></div>
                                </template>
                            </el-table-column>
                            <el-table-column label="是否一口价" align="center" width="110">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.is_fixed" class="yesIcon el-icon-check"></div>
                                    <div v-else class="noIcon el-icon-close"></div>
                                </template>
                            </el-table-column>

                            <el-table-column prop="products_count" label="组成产品种类" align="center" width="120">
                            </el-table-column>

                            <el-table-column prop="products_num" label="组成产品总数" align="center" width="120">
                            </el-table-column>

                            <el-table-column prop="max_num" label="限购数量" align="center" width="120">
                            </el-table-column>



                            <el-table-column fixed="right" label="操作" align="center" width="150">
                                <template slot-scope="scope">
                                    <el-button @click="seePDGoodsDetail(scope.row)" type="text" class="textBtnColor"
                                        size="big">
                                        查看详情
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>

                        <el-pagination @size-change="handleSizeChangePDGoodsInfo"
                            @current-change="handleCurrentChangePDGoodsInfo" :current-page="PDGoodsInfo.page"
                            :page-sizes="[5]" :page-size="PDGoodsInfo.per_page"
                            layout="total, sizes, prev, pager, next, jumper" :total="PDGoodsTotal" align="center">
                        </el-pagination>
                    </div>
                </el-tab-pane>

                <el-tab-pane label="库存变动列表">
                    <div class="addProduct">

                        <span>
                            仓库:
                            <el-select v-model="KCPDInfo.store_id" filterable clearable size="small">
                                <el-option v-for="item in warehouseData" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </span>

                        <span>
                            产品:
                            <el-select v-model="KCPDInfo.product_id" filterable clearable size="small">
                                <el-option v-for="item in inventory__change__chooseProData" :key="item.id"
                                    :label="item.name" :value="item.id">
                                    <span>{{item.name}}</span>
                                </el-option>
                            </el-select>
                        </span>

                        <span>
                            日期:
                            <el-date-picker  v-model="chooseDateRangeKCBD" type="daterange" range-separator="至"
                                size="small" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </span>

                        <el-button size="mini" class="frontBtn" icon="el-icon-search" @click="SearchKCPD">搜 索
                        </el-button>
                        <el-button size="mini" class="reverseBtn" icon="el-icon-refresh" @click="resetKCPD">重 置
                        </el-button>
                    </div>

                    <el-table :header-cell-style="styleBindFun" tooltip-effect="dark" style="width: 100%"
                        :data="Stock_Change_data" align="center" border>
                        <el-table-column label="库存变动单" align="center" width="215" fixed>
                            <template slot-scope="scope">
                                {{scope.row.model.order_num}}
                            </template>
                        </el-table-column>
                        <el-table-column label="变动类型" align="center" width="120">
                            <template slot-scope="scope">
                                {{scope.row.type_name}}
                            </template>
                        </el-table-column>
                       
                        <el-table-column prop="brand_name" label="品牌名称" align="center" width="120">
                        </el-table-column>
                        <el-table-column prop="store_name" label="现存放仓库" align="center" width="120">
                        </el-table-column>
                        <el-table-column label="货号-产品名称" align="center" width="350">
                            <template slot-scope="scope">
                                 {{scope.row.product_sn}} -
                                {{scope.row.product_name}} <span
                                    v-show="scope.row.product_spec">({{scope.row.product_spec}})</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="批次" align="center" width="200">
                            <template slot-scope="scope">

                                <span v-show="scope.row.batch_name">
                                    {{scope.row.batch_name}}
                                </span>
                            </template>
                        </el-table-column>



                        <el-table-column label="批次限用日期" align="center" width="120">
                            <template slot-scope="scope">
                                <span v-show="scope.row.batch_expiry_date">
                                    {{scope.row.batch_expiry_date}}
                                </span>
                            </template>
                        </el-table-column>


                        <el-table-column label="变动前数量" align="center" width="110">
                            <template slot-scope="scope">
                                {{scope.row.before_quantity}}
                            </template>
                        </el-table-column>
                        <el-table-column label="变动后数量" align="center" width="110">
                            <template slot-scope="scope">
                                {{scope.row.after_quantity}}
                            </template>
                        </el-table-column>
                        <el-table-column label="变动数量" align="center" width="110">
                            <template slot-scope="scope">
                                <span class="Number">{{scope.row.quantity}} </span> {{scope.row.unit_name}}
                            </template>
                        </el-table-column>

                     





                        <el-table-column label="创建时间" align="center" width="179">
                            <template slot-scope="scope">
                                {{scope.row.create_time}}
                            </template>
                        </el-table-column>

                    </el-table>

                    <el-pagination @size-change="handleSizeChangeKCPDgetInfo"
                        @current-change="handleCurrentChangeKCPDgetInfo" :current-page="KCPDInfo.page" :page-sizes="[6]"
                        :page-size="KCPDInfo.per_page" layout="total, sizes, prev, pager, next, jumper"
                        :total="KCPDTotal" align="center">
                    </el-pagination>
                </el-tab-pane>

                <el-tab-pane label="库存快照列表">
                    <div>
                        <span class="chooseCKStyle">
                            选择仓库：
                            <el-select v-model="KCKZInfo.store_id" clearable size="mini" @change="KCKZStoreSearch">
                                <el-option v-for="item in warehouseData" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </span>

                        <span class="chooseCKStyle">
                            日期：
                            <el-date-picker size="mini" v-model="dataArea" @change="getSnapshotsInfo" type="daterange"
                                value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期"
                                end-placeholder="结束日期">
                            </el-date-picker>
                        </span>

                        <el-button size="small" class="frontBtn snapshotBtn" @click="createSnapShot">创建库存快照</el-button>
                    </div>


                    <el-table :header-cell-style="styleBindFun" tooltip-effect="dark" style="width: 100%"
                        :data="snapshotData" align="center" border>

                        <el-table-column prop="name" label="快照名称" align="center">
                        </el-table-column>
                        <el-table-column prop="is_revise" label="是否修正过" align="center">
                            <template slot-scope="scope">
                                <div v-if="scope.row.is_revise" class="yesIcon el-icon-check"></div>
                                <div v-else class="noIcon el-icon-close"></div>
                            </template>
                        </el-table-column>
                        <el-table-column label="创建时间" align="center">
                            <template slot-scope="scope">
                                {{scope.row.create_time}}
                            </template>
                        </el-table-column>
                        <el-table-column label="操作人-部门-员工" align="center">
                            <template slot-scope="scope">
                                {{scope.row.user_name}} — {{scope.row.department_full_name}} —
                                {{scope.row.position_name}}
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button type="text" class="text_primary" size="big"
                                    @click="seeDetailSnapShots(scope.row)">详情
                                </el-button>

                                <el-button type="text" class="text_primary" size="big"
                                    @click="downLoadSnapShots(scope.row)">下载快照
                                </el-button>



                                <!-- <el-button type="text" class="text_primary" size="big" @click="editStockSnapshot(scope.row)">修正库存
                                </el-button> -->

                                <el-button type="text" class="text_delete" size="big"
                                    @click="deleteSnapshot(scope.row)">删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>

                    <el-pagination @current-change="handleCurrentChangeStorageSnapshots" :current-page="KCKZInfo.page"
                        :page-sizes="[8]" :page-size="KCKZInfo.per_page"
                        layout="total, sizes, prev, pager, next, jumper" :total="KCKZTotal" align="center">
                    </el-pagination>
                </el-tab-pane>

                <!-- <el-tab-pane label="库存报警">
                    <el-table :header-cell-style="styleBindFun" tooltip-effect="dark" style="width: 100%"
                        :data="InventoryWarningData" align="center" border>
                         <el-table-column prop="sn" label="货号" align="center">
                        </el-table-column>
                        <el-table-column prop="name" label="产品" align="center">
                            <template slot-scope="scope">
                              {{scope.row.name}} <span v-show="scope.row.spec">（{{scope.row.spec}}）</span>
                            </template>
                        </el-table-column>

                         <el-table-column prop="warning" label="预警数量" align="center">
                        </el-table-column>

                         <el-table-column prop="storages_count" label="存有仓库数" align="center">
                        </el-table-column>

                            <el-table-column label="库存情况" align="center">
                                <template slot-scope="scope">
                                <el-dropdown v-show="scope.row.storages.length>0">
                                    <span class="el-dropdown-link">
                                        查看<i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item v-for="(list,index)  in scope.row.storages" :key="index">
                                            {{list.store_name}} <span class="InventoryNumberDesc--Style"
                                                >库存：<span class="InventoryNumber--Style ">{{list.quantity}}</span></span>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                                </template>
                        </el-table-column>    
                    </el-table>

                    
                </el-tab-pane> -->


            </el-tabs>





        </el-card>


        <!-- 查看商品单详情对话框 -->
        <el-dialog title="商品库存详情" :visible.sync="is_PDGoodsDetail" width="82%" class="GoodsDetail">
            <el-table :header-cell-style="{background:'#f5f7fa',fontSize:'14px',color:'#1D2129', fontWeight: 'normal'}"
                tooltip-effect="dark" style="width: 100%" :data="PDGoodsDetail_Data" align="center" border>
                <el-table-column prop="sn" label="货号" align="center">
                </el-table-column>
                <el-table-column label="产品" align="center" width="280">
                    <template slot-scope="scope">
                        {{scope.row.name}}({{scope.row.spec}})
                    </template>
                </el-table-column>


                <el-table-column label="数量" align="center">
                    <template slot-scope="scope">
                        {{scope.row.pivot.quantity}}
                    </template>
                </el-table-column>

                <el-table-column label="价格（元）" align="center">
                    <template slot-scope="scope">
                        {{scope.row.pivot.price}}
                    </template>
                </el-table-column>

                <el-table-column label="当前库存" align="center">
                    <template slot-scope="scope">
                        {{scope.row.count}}
                    </template>
                </el-table-column>

                <el-table-column label="存放地址" align="center">
                    <template slot-scope="scope">
                        <el-dropdown v-show="scope.row.storages.length>0">
                            <span class="el-dropdown-link">
                                查看<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-for="(list,index)  in scope.row.storages" :key="index">
                                    {{list.store_name}} <span class="validityDateStyle">库存：<span
                                            class="Number">{{list.quantity}}</span></span>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>




            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" class="frontBtn" @click="is_PDGoodsDetail = false">确 定</el-button>
            </div>
        </el-dialog>




        <!-- 查看库存快照详情对话框 -->
        <el-dialog title="库存快照详情" :visible.sync="is_snapshotQuery" width="80%" class="GoodsDetail">
            <el-table :header-cell-style="styleBindFun" tooltip-effect="dark" style="width: 100%"
                :data="snapShotsDetailData" align="center" border>


                <el-table-column prop="sn" label="货号" align="center">
                    <template slot-scope="scope">
                        {{scope.row.sn}}
                    </template>
                </el-table-column>

                <el-table-column label="产品" align="center" width="320">
                    <template slot-scope="scope">
                        {{scope.row.product_name}} <span v-show="scope.row.spec">({{scope.row.spec}})
                        </span>
                    </template>
                </el-table-column>


                <el-table-column prop="quantity" label="快照数量" align="center">
                </el-table-column>
                <el-table-column prop="store_quantity" label="当前仓库" align="center">
                </el-table-column>

                <el-table-column prop="total_quantity" label="所有仓库" align="center">
                </el-table-column>

                <el-table-column label="修正数量" align="center">
                    <template slot-scope="scope">

                        <span v-if="reviseBtn">{{scope.row.revise_quantity}}</span>
                        <el-input v-else size="mini" placeholder="请输入" v-model="scope.row.revise_quantity"></el-input>

                    </template>
                </el-table-column>



                <el-table-column prop="batch_name" label="批次" align="center" width="350">
                    <template slot-scope="scope">
                        批次：{{scope.row.batch_sn}} <span v-show="scope.row.batch_expiry_date"
                            class="validityDateStyle">限用日期：{{scope.row.batch_expiry_date}}</span>
                    </template>
                </el-table-column>

            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" size="mini" :disabled="reviseBtn" @click="amendInventory()">修正库存</el-button>
                <el-button size="mini" class="frontBtn" @click="snapshotQueryDo">确 定</el-button>
            </div>
        </el-dialog>


        <!-- 修正库存对话框 -->
        <el-dialog title="修正库存" :visible.sync="is_editSnapshotQuery" width="88%" class="GoodsDetail">
            <el-table :header-cell-style="styleBindFun" tooltip-effect="dark" style="width: 100%"
                :data="snapShotDetailData" align="center" border @cell-dblclick="changeInputAndShow">
                <el-table-column prop="sn" label="货号" align="center">
                    <template slot-scope="scope">
                        {{scope.row.sn}}
                    </template>
                </el-table-column>

                <el-table-column label="产品" align="center" width="260">
                    <template slot-scope="scope">
                        {{scope.row.name}} <span v-show="scope.row.spec">{{scope.row.spec}} </span>
                    </template>
                </el-table-column>


                <el-table-column prop="quantity" label="快照数量" align="center">
                </el-table-column>
                <el-table-column prop="store_quantity" label="当前仓库" align="center">
                </el-table-column>

                <el-table-column prop="total_quantity" label="所有仓库" align="center">
                </el-table-column>



                <el-table-column label="修正数量" align="center">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.revise_quantity" v-show="scope.row.showNum" placeholder="晴填写"
                            size="small"></el-input>
                        <span v-show="!scope.row.showNum">{{scope.row.revise_quantity}}</span>
                    </template>
                </el-table-column>

                <el-table-column fixed="right" prop="remarks" label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" @click="setNumber(scope.row)">设置数量</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="editSnapshotDo">提 交</el-button>
            </div>
        </el-dialog>


        <!-- 创建库存快照对话框 -->
        <el-dialog title="创建库存快照" :visible.sync="is_createSnapShot" width="30%" class="GoodsDetail">
            <el-form label-width="210px" style="margin-top:20px">
                <el-form-item label="选择仓库：">
                    <el-select v-model="check_warehouse" size="medium" clearable @change="WareHouseCheckBYWHId">
                        <el-option v-for="item in warehouseData" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="frontBtn" size="mini" @click="createSnapshotDo">提 交</el-button>
            </div>
        </el-dialog>





    </div>
</template>

<script>
    import {
        erpApi
    } from '../../config'

    export default {
        data() {
            return {

                activeNames: [0],
                // 商品分类选择id
                currentGoodsCateId: [],

                // -------------------仓库盘点--------------------------------------


                is_WareHouseCheck_state: false, // 控制仓库盘点的显示与隐藏
                check_warehouse_goods: '', // 控制仓库盘点的选择仓库名

                check_warehouse: '', // 控制仓库盘点的选择仓库名
                //  获取盘点数据添加条件

                PDgetInfo: {
                    page: 1, //当前页
                    per_page: 9, //页面显示个数
                    product_id: '',
                    store_id: '',
                },
                PDTotal: 0,
                Check_WareHouse_data: [],
                warehouseData: [], //仓库列表数组
                check_warehouse_category_Data: [], //商品分类数组
                defaultProps: {
                    children: 'children',
                    label: 'name'
                },
                defaultExpand: [], //默认展开的数组
                PDGoodsData: [], //盘点仓库商品 --商品数据

                PDGoodsInfo: {
                    page: 1,
                    per_page: 5,
                    category_id: '', //盘点仓库商品--商品id
                    store_id: '', //仓库id
                },
                PDGoodsTotal: 0,

                // ------------------库存变动列表---------------------------------
                KCPDInfo: {
                    page: 1,
                    per_page: 6,
                    start_date: '',
                    end_date: '',
                    product_id: '',
                    store_id: '',
                },
                KCPDTotal: 0,
                Stock_Change_data: [],
                Stock_Change_Details_data: [],
                Stock_Change_Detail_data: [],
                is_Stock_Change_Detail: false,
                chooseDateRangeKCBD: [],
                WHMoveProductsData: [],
                CPKCInput: '',


                is_PDGoodsDetail: false,

                PDGoodsDetail_Data: [],



                //  -----------------------------------快照信息-----------------------------------------------------------------
                snapshotData: [],
                snapShotsDetailData: [],
                snapShotDetailData: [],
                is_snapshotQuery: false,
                is_editSnapshotQuery: false,
                currentEditStockId: '',
                editStockData: [],
                is_createSnapShot: false,
                KCKZInfo: {
                    page: 1,
                    per_page: 8,
                    store_id: '',
                    start_time: '',
                    end_time: '',
                },
                KCKZTotal: 0,

                //修正库存
                current__snapshot_id: '',

                // ---------------------仓库库存批次列表----------------------------------
                warehouse__inventory__data: [],

                inventory__change__chooseProData: [],

                // ----------------------库存报警--------------------------------
                InventoryWarningData: [],

                reviseBtn: true,

                dataArea: [],

            }
        },
        created() {

            this.getWareHouse()
            this.getWarehouseInventory()
            this.getCategoryInfo()

        },
        methods: {

            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind = "background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                return styleBind;
            },



            // -----------------仓库盘点--------------------------------------------------

            // 获取仓库列表
            async getWareHouse() {
                const res = await this.$https.post(erpApi + '/store/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.warehouseData = res.data
            },



            async getWarehouseInventory() {
                let params = this.PDgetInfo
                const res = await this.$https.post(erpApi + '/storage/products?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.Check_WareHouse_data = res.data.data
                this.PDTotal = res.data.total
            },



            // 仓库盘点（产品）BY 仓库ID
            WareHouseCheckBYWHId(data) {
                this.PDgetInfo.store_id = data
                this.getWarehouseInventory()
            },

            chooseStorageDo() {},


            handleCurrentChangePDgetInfo(data) {
                this.PDgetInfo.page = data
                this.getWarehouseInventory()
            },

            // ------------商品方法-------------------
            // 获取商品分类
            async getCategoryInfo() {
                const res = await this.$https.post(erpApi + '/category/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)

                this.check_warehouse_category_Data = res.data
                // this.check_warehouse_category_Data.forEach(item => {
                //     this.defaultExpand.push(item.id)
                // })
                //  this.check_warehouse_category_Data[0].children.forEach(item => {
                //     this.defaultExpand.push(item.id)
                // })
            },
            // 仓库盘点方法(商品)
            WareHouseCheckgoods() {
                this.getCategoryInfo()
                this.is_WareHouseCheckGoods_state = true
            },
            // 获取库存变动列表--产品数据
            async getInventoryChooseProduct() {
                let params = {
                    per_page: 10000
                }
                const {
                    data
                } = await this.$https.post(erpApi + '/product/select?_ajax=1', params)
                this.inventory__change__chooseProData = data.data
            },
            WHCheckTabs(tab) {
                
                if (tab.index == 1) {
                    this.getWarehouseInventoryList()
                }

              if (tab.index == 2) {
                                    this.getInfoByCategoryId()

                }
                 
                if (tab.index == 3) {
                    this.getInventoryChooseProduct()
                     this.stockChangeList()
                }


                 if (tab.index == 4) {
                    this.getSnapshotsInfo()
                }



               



               
            },

            handleNodeClick(data) {

                this.handleNodeCategoryId = data.id;
                this.getInfoByCategoryId()
            },
            async getInfoByCategoryId() {

                let params = this.PDGoodsInfo
                const res = await this.$https.post(erpApi + '/storage/goods_list?_ajax=1', params)
                if (res.status !== 200) return this.$message, error(res.statusText)

                this.PDGoodsData = res.data.data
                this.PDGoodsTotal = res.data.total


            },

            handleSizeChangePDGoodsInfo(data) {
                this.PDGoodsInfo.per_page = data
                this.getInfoByCategoryId()
            },
            handleCurrentChangePDGoodsInfo(data) {
                this.PDGoodsInfo.page = data
                this.getInfoByCategoryId()
            },

            async seePDGoodsDetail(row) {
                this.is_PDGoodsDetail = true
                let params = {
                    goods_id: row.id
                }
                const {
                    data
                } = await this.$https.post(erpApi + '/storage/goods_detail?_ajax=1', params)
                this.PDGoodsDetail_Data = data.products

            },

            // ----------库存变动列表-------------------------------
            async stockChangeList() {

                let params = this.KCPDInfo
                const res = await this.$https.post(erpApi + '/stocks?_ajax=1', params)

                if (res.status !== 200) return this.$message.error(res.statusText)

                this.Stock_Change_data = res.data.data
                this.KCPDTotal = res.data.total
                this.getProducts()
            },
            seeKCBDDetail(row) {
                this.is_Stock_Change_Detail = true
                this.Stock_Change_data.forEach(item => {
                    this.Stock_Change_Details_data.push(item.model)
                })
                this.Stock_Change_Details_data.forEach(item => {
                    if (row.model_id == item.id) {
                        this.Stock_Change_Detail_data = [];
                        this.Stock_Change_Detail_data.push(item)
                    }
                })

            },
            handleSizeChangeKCPDgetInfo(data) {
                this.KCPDInfo.per_page = data
                this.stockChangeList()
            },
            handleCurrentChangeKCPDgetInfo(data) {
                this.KCPDInfo.page = data
                this.stockChangeList()
            },
            SearchKCPD() {

                if (this.chooseDateRangeKCBD !== null) {
                    this.KCPDInfo.start_date = this.chooseDateRangeKCBD[0]
                    this.KCPDInfo.end_date = this.chooseDateRangeKCBD[1]
                    this.stockChangeList()
                } else {
                    this.KCPDInfo.start_date = ""
                    this.KCPDInfo.end_date = ""
                    this.stockChangeList()
                }
            },

            resetKCPD() {
                this.KCPDInfo.store_id = ''
                this.KCPDInfo.product_id = ''
                this.chooseDateRangeKCBD = []

            },

            // 获取指定仓库的产品数据
            // async getWHProducts() {
            //     let params = {
            //         store_id: this.form_add_WHMove.transfer_store_id
            //     }
            //     this.getProducts(params)
            // },

            async getProducts(data) {
                const res = await this.$https.post(erpApi + '/storage/products?_ajax=1', data)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.WHMoveProductsData = res.data.data
            },




            //   ----------------------------产品库存搜索与重置----------------------------------------------------------

            CPKCsearch() {
                this.PDgetInfo.store_id = this.check_warehouse
                this.PDgetInfo.keyword = this.CPKCInput
                delete this.PDgetInfo.product_id
                this.getWarehouseInventory()
            },
            CPKCreset() {
                this.check_warehouse = ''
                this.CPKCInput = ''
            },

            //   ----------------------------商品库存搜索与重置----------------------------------------------------------
            GoodsKCListSearch() {

                this.PDGoodsInfo.category_id = this.currentGoodsCateId.pop();
                this.PDGoodsInfo.store_id = this.check_warehouse_goods
                this.getInfoByCategoryId()
            },

            GoodsKCListReset() {
                this.currentGoodsCateId = []
                this.check_warehouse_goods = ''
            },


            // ---------------------------------快照方法------------------------------------------------------
            async getSnapshotsInfo() {

                if (this.dataArea.length > 0) {
                    this.KCKZInfo.start_time = this.dataArea[0]
                    this.KCKZInfo.end_time = this.dataArea[1]

                }
                let params = this.KCKZInfo
                const res = await this.$https.post(erpApi + '/storage/snapshots?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.snapshotData = res.data.data
                this.KCKZTotal = res.data.total
            },

            async getSnapshotsDetail(row) {
                this.current__snapshot_id = row.id
                let params = {
                    snapshot_id: row.id
                }
                const res = await this.$https.post(erpApi + '/storage/snapshot/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.snapShotsDetailData = res.data.details



            },

            seeDetailSnapShots(row) {
                if (row.is_revise) {
                    this.reviseBtn = true
                } else {
                    this.reviseBtn = false
                }
                this.is_snapshotQuery = true
                this.getSnapshotsDetail(row)
            },




            // 修正库存
            amendInventory() {
                this.$confirm('您确定修正该库存吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    .then(async () => {


                        let array = []
                        this.snapShotsDetailData.forEach(node => {
                            if (node.revise_quantity !== 0) {
                                let obj = {
                                    id: node.id,
                                    revise_quantity: node.revise_quantity,
                                }
                                array.push(obj)
                            }
                        })
                        let params = {
                            snapshot_id: this.current__snapshot_id,
                            product_list: array,
                        }

                        const {
                            data
                        } = await this.$https.post(erpApi + '/storage/snapshot/revise?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)
                        this.reviseBtn = true

                        this.refreshSnapshotsDetail()

                    })
                    .catch(() => {
                        this.$message.error('您取消了按钮')
                    })

            },

            async refreshSnapshotsDetail() {

                let params = {
                    snapshot_id: this.current__snapshot_id
                }
                const res = await this.$https.post(erpApi + '/storage/snapshot/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.snapShotsDetailData = res.data.details
            },

            snapshotQueryDo() {
                this.is_snapshotQuery = false
                this.getSnapshotsInfo()
            },

            //  下载方法
            downLoadSnapShots(row) {
                window.location.href = erpApi + '/storage/snapshot/down?_ajax=1&snapshot_id=' + row.id
            },

            async editStockSnapshot(row) {

                this.currentEditStockId = row.id
                this.is_editSnapshotQuery = true
                let params = {
                    snapshot_id: row.id
                }
                const res = await this.$https.post(erpApi + '/storage/snapshot/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.snapShotsDetailData = res.data.details

                this.snapShotDetailData = []
                this.snapShotsDetailData.forEach(item => {
                    let obj = {}
                    obj.sn = item.sn
                    obj.name = item.product_name
                    obj.spec = item.spec
                    obj.quantity = item.quantity
                    obj.store_quantity = item.store_quantity
                    obj.total_quantity = item.total_quantity
                    obj.revise_quantity = item.revise_quantity
                    obj.id = item.id
                    obj.batch_name = item.batch_name
                    obj.expiry_date = item.expiry_date

                    obj.showNum = false

                    this.snapShotDetailData.push(obj)
                })



            },

            // 表格双击事件
            changeInputAndShow(row, column, cell, event) {
                row.showNum = !row.showNum
            },

            setNumber(row) {
                row.showNum = !row.showNum
            },

            // 提交修改的库存数据
            async editSnapshotDo() {
                let arr1 = []
                let arr2 = []

                this.snapShotDetailData.forEach(item => {
                    arr1.push(item.revise_quantity)
                })

                this.snapShotsDetailData.forEach(item => {
                    arr2.push(item.revise_quantity)
                })

                let data = arr1
                    .filter(x => arr2.indexOf(x) == -1)
                    .concat(arr2.filter(x => arr1.indexOf(x) == -1));


                this.editStockData = []
                for (let i = 0; i < data.length; i++) {
                    this.snapShotDetailData.forEach(item => {
                        if (item.revise_quantity == data[i]) {
                            this.editStockData.push(item)
                        }
                    })
                }

                let productList = []

                this.editStockData.forEach(item => {
                    let {
                        product_id,
                        batch_id,
                        revise_quantity
                    } = item
                    let obj = {}
                    obj.product_id = product_id
                    obj.batch_id = batch_id
                    obj.revise_quantity = Number(revise_quantity)
                    productList.push(obj)
                })

                // 发起请求
                let params = {
                    snapshot_id: this.currentEditStockId,
                    product_list: productList
                }

                const res = await this.$https.post(erpApi + '/storage/snapshot/revise?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.$message.success(res.data.msg)

                this.is_editSnapshotQuery = false

            },


            createSnapShot() {
                this.is_createSnapShot = true

            },

            async createSnapshotDo() {

                let params = {
                    store_id: this.check_warehouse
                }
                const {
                    data
                } = await this.$https.post(erpApi + '/storage/snapshot/create?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)

                this.is_createSnapShot = false
                this.getSnapshotsInfo()
            },



            // 选择仓库方法
            chooseWarehouseDo() {
                this.getWarehouseInventoryList()
            },

            // ---------------------仓库库存批次列表----------------------------------
            async getWarehouseInventoryList() {
                let parmas = {
                    store_id: this.check_warehouse
                }
                const {
                    data
                } = await this.$https.post(erpApi + '/storage/stores?_ajax=1', parmas)
                this.warehouse__inventory__data = data
            },


            handleCurrentChangeStorageSnapshots(data) {
                this.KCKZInfo.page = data
                this.getSnapshotsInfo()
            },

            KCKZStoreSearch() {
                this.getSnapshotsInfo()
            },

            async deleteSnapshot(row) {
                let params = {
                    snapshot_id: row.id
                }

                const {
                    data
                } = await this.$https.post(erpApi + '/storage/snapshot/delete?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.getSnapshotsInfo()
            },


            // ----------------库存报警------------------------------
            async getInventoryWarning() {
                let res = await this.$https.post(erpApi + '/storage/warning?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.InventoryWarningData = res.data
            },






















            // 返回
            goBack() {
                this.$router.push({
                    path: '/purchase'
                })
            },


        },
    }
</script>

<style lang="less" scoped>
    #app {
        overflow: hidden;
    }

    h3 {
        font-size: 24px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        margin-bottom: 20px;
    }

    .el-breadcrumb {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .el-card {
        position: relative;
        height: auto;
    }

    .purchasechoose {
        margin: 0px 0 30px 0;
        background-color: #dfe9f5;
        padding: 6px 10px;
        display: flex;
        align-items: center;
    }

    .datePicker {
        margin-left: 20px;
    }

    .addpurchase {
        margin-left: 20px;
        margin-right: 10px;
    }


    .el-pagination {
        // margin-top: 30px;
    }

    .remarks {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 150px;
        text-align: center;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    /deep/.el-dialog__body {
        display: flex;
        flex-direction: column;
    }

    .purchaseRemark,
    .chooseWareHouse {
        margin-top: 30px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        font-weight: 550;
        font-size: 18px;
        letter-spacing: 2px;
    }

    .addProduct {
        // background-color: #dfe9f5;
        padding: 10px 20px;
        display: flex;
        // justify-content: center;
        margin-top: 10px;
        background-color: #f5f7fa;
    }


    .addProduct span {
        color: #333;
        font-size: 14px;
    }

    .addProduct span:nth-child(2) {
        margin-left: 20px;
    }

    .addProduct span:nth-child(3) {
        margin-left: 20px;
    }

    .addProduct .el-button {
        margin-left: 20px;
    }

    .addProduct .el-button:last-child {
        margin-left: 10px;
    }

    .PDProduct {
        justify-content: start
    }

    .addProduct .el-select {
        margin-right: 20px;
    }

    /deep/.el-input .el-input-group {
        width: 280px;
        float: right
    }

    /deep/.el-input-group>.el-input__inner {}

    /deep/ .key_word {
        width: 300px;
    }



    .el-tag {
        margin-right: 20px;
        margin-bottom: 15px;
        // padding: 0 10px;
    }

    .setProductNumber {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .icon-yiruku,
    .icon-bufenruku {
        color: #409eff;
        font-size: 26px;
        margin-left: 5px;
    }







    .CPKCInput {
        width: 78%;
    }




    .goodsCheck {
        padding: 8px 20px
    }

    .backBtn {
        // position: absolute;
        // top:10px;
        // right: 5%;
        display: flex;
        justify-content: end;
        padding-right: 5%;
    }

    .snapshotBtn {
        margin-top: 5px;
        margin-left: 20px;
    }

    .validityDateStyle {
        font-size: 12px;
        margin-left: 10px;
        margin-right: 10px;
    }

    /* el-dropdown */
    .el-dropdown-link {
        cursor: pointer;
        color: #2e63dc;
    }

    .watehouse__inventory--desc span {
        margin: 0 15px;
        margin-top: 10px;
        margin-bottom: 5px;
        font-size: 14px;
    }

    /deep/.el-collapse-item__header {
        color: #303133;
        font-size: 16px;
        font-weight: bolder;
        padding-left: 20px;
    }

    .detailDesc--Style {
        margin-top: 20px;
    }

    .chooseCKStyle {
        color: #333;
        font-size: 14px;
        margin-right: 20px;
        margin-left: 20px;
    }


    .InventoryNumberDesc--Style {
        margin-left: 5px;
    }

    .InventoryNumber--Style {
        color: #ff0000;
    }

    /* el-table 滚动条样式 */
    /deep/.el-table__body-wrapper::-webkit-scrollbar {
        height: 8px;
        width: 6px;
    }



    /deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }

    .ProductRK--Style {
        background-color: #d7eefe;
        color: #0594fa;
        padding: 10px;
        border-radius: 10px;
    }

    .diaoBoRK--Style {
        background-color: #fef3e6;
        color: #ed7b2f;
        padding: 10px;
        border-radius: 10px;
    }

    .ProductBF--Style {
        background-color: #fdecee;
        color: #e34d59;
        padding: 10px;
        border-radius: 10px;
    }

    .diaoBoCK--Style {
        background-color: #fce5f4;
        color: #ed49b4;
        padding: 10px;
        border-radius: 10px;
    }

    .amendInventory_width {
        width: 30%;

    }
</style>