<template>
    <div id="app">
        <!-- 布局 -->
        <div class="purchaseLayout">

            <!-- 面包屑导航 -->
            <!-- <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>库存管理</el-breadcrumb-item>
                <el-breadcrumb-item>采购入库</el-breadcrumb-item>
                <el-breadcrumb-item>直接入库</el-breadcrumb-item>
            </el-breadcrumb> -->
        </div>

        <el-card class="addPurchaseOrder">

            <el-button size="mini" class="reverseBtn back" @click="goBack">返 回</el-button>

            <el-form :model="form_add_purchase" :rules="rules" label-width="100px">
                <el-form-item label="选择仓库：" prop="name">
                    <el-select v-model="store_id" clearable placeholder="请选择" size="small" @change="changeStore_fun">
                        <el-option v-for="item in warehouseData" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>


                <el-form-item label="备注：">
                    <el-input type="textarea" v-model="form_add_purchase.remarks" placeholder="请输入内容"></el-input>
                </el-form-item>

            </el-form>

            <div>
                <el-button class="frontBtn choosePro" icon="el-icon-circle-plus" size="mini"
                    @click="addPurChaseProduct">选择入库产品
                </el-button>
            </div>


            <el-table :data="product_data" :header-cell-style="styleBindFun" style="width:100%" height="400"
                @cell-dblclick="changeInputAndShow" border>
                <el-table-column prop="sn" label="货号" align="center">
                </el-table-column>
                <el-table-column prop="brand_name" label="品牌名称" align="center">
                    <template slot-scope="scope">
                        <el-tag type="sucess">{{scope.row.brand_name}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="产品名称" align="center">
                    <template slot-scope="scope">
                        {{scope.row.name}}
                        <span v-if="scope.row.spec">( {{scope.row.spec}} )</span>
                    </template>
                </el-table-column>

                <el-table-column label="数量" align="center">
                    <template slot-scope="scope">
                        <el-input size="mini" placeholder="请输入内容" clearable v-model="scope.row.num" @blur="changeNumFunction">
                        </el-input>
                    </template>
                </el-table-column>

                <el-table-column label="批次" align="center">
                    <template slot-scope="scope">

                        <el-select v-model="scope.row.batch_id" clearable filterable remote placeholder="请选择"
                            :disabled="!scope.row.is_batch" @change="changeBatch" size="small"
                            :remote-method="remoteMethod">
                            <el-option v-for="item in BatchData" :key="item.id" :label="item.name" :value="item.id">
                                {{item.name}} — 限用日期至: {{item.expiry_date}}
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>

                <el-table-column fixed="right" label="操作" width="320" align="center">
                    <template slot-scope="scope">

                        <el-button @click="deleteProductNum(scope.row)" type="text" class="text_delete" size="small">删除
                        </el-button>

                        <el-button @click="copyItem(scope.row)" type="text" class="text_delete" size="small">复制
                        </el-button>



                    </template>
                </el-table-column>
            </el-table>


            <div class="containnumber">
                 产品种类: <span class="NumberRight20">{{TypeTotal}}</span>
                <span>总入库数量:
                    <span class="Number"> {{NumTotal}}</span>
                </span>
            </div>

            <div class="btnArea">
                <el-button size="small" class="frontBtn directRK--Style" @click="DirectRKDo">提 交</el-button>

            </div>




            <!-- 添加产品对话框 -->
            <el-dialog title="添加产品" :visible.sync="is_add_product" width="70%">


                <div class="addProduct">

                    <el-select size="small" v-model="choose_brand" clearable placeholder="选择品牌">
                        <el-option v-for="item in productBrand" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>

                    <el-select size="small" v-model="choose_show" clearable placeholder="选择是否上架">
                        <el-option v-for="item in showState" :key="item.id" :label="item.value" :value="item.id">
                        </el-option>
                    </el-select>

                    <el-select size="small" v-model="choose_qr" clearable placeholder="选择是否生成二维码">
                        <el-option v-for="item in qrState" :key="item.id" :label="item.value" :value="item.id">
                        </el-option>
                    </el-select>

                    <el-input size="small" class="key_word" placeholder="请输入要查找的内容" v-model="key_word" clearable>

                    </el-input>

                    <el-button size="small" class="search_addPro frontBtn" icon="el-icon-search"
                        @click="getAddProductShowProduct">搜 索
                    </el-button>

                    <el-button size="small" class="search_addPro reverseBtn" icon="el-icon-refresh"
                        @click="resetSearch">重 置
                    </el-button>

                </div>


                <el-table tooltip-effect="dark" style="width: 100%" ref="multipleTable" :data="purchase_add_data"
                    :header-cell-style="styleBindFun" align="center" border>

                    <el-table-column prop="brand_name" label="品牌名称" align="center">
                    </el-table-column>
                    <el-table-column prop="sn" label="货号" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="产品名称" align="center" width="300">
                        <template slot-scope="scope">
                            {{scope.row.name}}
                            <span v-if="scope.row.spec">( {{scope.row.spec}} )</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="是否批次管理" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.is_batch" class="yesIcon el-icon-check"></div>
                            <div v-else class="noIcon el-icon-close"></div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="unit_name" label="单位" align="center">
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" align="center" width="200">
                        <template slot-scope="scope">
                            <!-- <el-button @click="seePlanProduct(scope.row)" type="text" plain size="mini">查看产品相关信息
                            </el-button> -->

                            <el-button @click="add(scope.row)" type="text" :disabled="scope.row.myChecked"  size="medium">添加
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页器 -->
                <el-pagination @current-change="handleCurrentChangeAddProduct"
                    :current-page="form_add_product_showproduct.currentpage"
                    :page-size="form_add_product_showproduct.pagesize" layout="total, prev, pager, next, jumper"
                    :total="total_addproduct" align="center">
                </el-pagination>


                <div slot="footer" class="dialog-footer">
                    <el-button size="mini" class="frontBtn" @click="is_add_product = false">确 定</el-button>
                </div>
            </el-dialog>




            <!-- 查看商品相关信息抽屉对话框 -->
            <el-drawer title="查看产品相关信息" :visible.sync="drawer" size="45%" :direction="direction">
                <div class="drawerTitStyle">{{drawerTitle}}</div>
                <el-collapse accordion>
                    <el-collapse-item title="查看计划生产信息" name="1">
                        <el-table tooltip-effect="dark" :header-cell-style="styleBindFun" style="width: 100%"
                            :data="plan_production_info" align="center" border>
                            <el-table-column prop="sn" label="采购代码" align="center">
                            </el-table-column>
                            <el-table-column prop="order_num" label="计划生产单号" align="center">
                            </el-table-column>
                            <el-table-column prop="quantity" label="产品总数" align="center">
                            </el-table-column>
                            <el-table-column prop="receive" label="已到货数" align="center">
                            </el-table-column>

                        </el-table>
                    </el-collapse-item>
                    <el-collapse-item title="查看产品库存" name="2">
                        <el-table tooltip-effect="dark" :header-cell-style="styleBindFun" style="width: 100%"
                            :data="stock_info" align="center">
                            <el-table-column prop="store_name" label="仓库名称" align="center">
                            </el-table-column>
                            <el-table-column prop="quantity" label="库存数" align="center">
                            </el-table-column>
                            <el-table-column prop="create_time" label="创建时间" align="center">
                            </el-table-column>
                            <el-table-column label="更新时间" align="center">
                                <template slot-scope="scope">
                                    <el-dropdown v-show="scope.row.batchs">
                                        <span class="el-dropdown-link text_primary">
                                            查看批次<i class="el-icon-arrow-down el-icon--right"></i>
                                        </span>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item v-for="list  in scope.row.batchs" :key="list.id">
                                                {{list.name}} <span class="validityDateStyle">限用日期：{{list.expiry_date}}
                                                    数量：<span class="Number">{{list.pivot.quantity}}</span></span>
                                            </el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template>
                            </el-table-column>

                        </el-table>
                    </el-collapse-item>
                    <el-collapse-item title="查看所属商品信息" name="3">
                        <el-table tooltip-effect="dark" :header-cell-style="styleBindFun" style="width: 100%"
                            :data="belong_product_info" align="center">
                            <el-table-column prop="sn" label="货号" align="center">
                            </el-table-column>

                            <el-table-column prop="name" label="名称" align="center">
                            </el-table-column>

                            <el-table-column prop="category_name" label="分类名称" align="center">
                            </el-table-column>

                            <el-table-column prop="price" label="价格" align="center">
                            </el-table-column>

                            <el-table-column prop="pivot.quantity" label="数量" align="center">
                            </el-table-column>

                            <el-table-column label="是否可售" align="center">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.is_sell" class="yesIcon el-icon-check"></div>
                                    <div v-else class="noIcon el-icon-close"></div>
                                </template>
                            </el-table-column>
                            <el-table-column label="是否可回收" align="center">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.is_return" class="yesIcon el-icon-check"></div>
                                    <div v-else class="noIcon el-icon-close"></div>

                                </template>
                            </el-table-column>
                            <el-table-column label="能否一口价" align="center">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.is_fixed" class="yesIcon el-icon-check"></div>
                                    <div v-else class="noIcon el-icon-close"></div>
                                </template>
                            </el-table-column>

                            <el-table-column prop="sell_min_num" label="起订数量" align="center">
                            </el-table-column>

                            <el-table-column prop="sell_max_num" label="限购数量" align="center">
                            </el-table-column>

                        </el-table>
                    </el-collapse-item>
                </el-collapse>
            </el-drawer>



        </el-card>


    </div>
</template>

<script>
    import {
        erpApi
    } from '../../config'
    export default {
        data() {
            return {
                // 添加采购单  表单数据
                form_add_purchase: {
                    remarks: '', // 设置备注
                },
                // 添加采购单商品数据

                product_data: [], //商品列表数据
                product_name: '',
                product_num: 1, // 当前商品个数变量
                product_id: '', // 设置当前商品个数的id  
                is_product_num: false, // 控制设置商品个数对话框显示与隐藏
                products: [],
                ProductNumber: 0,
                ProductTypeNumber: 0,

                rules: {
                    name: [{
                        required: true,
                        message: '请选择仓库名称',
                        trigger: 'blur'
                    }],
                },

                // ---------------------------添加产品数据--------------------------------------------
                is_add_product: false,
                testchooseProductData: [], //选择的商品数组 
                chooseProductData: [], //选择的商品数组 
                purchase_add_data: [],
                total_addproduct: 200, // 添加商品时查看商品数据的商品总数

                plan_production_info: [], //查看产品计划生产信息数组
                stock_info: [], //查看库存信息数组
                belong_product_info: [], //查看所属商品信息数组
                plan_total_quantity: '', //查看所属商品计划生产总数
                plan_total_receive: '', //查看所属商品到货总数
                storage_total_quantity: '', //查看所属商品库存总数
                // 添加商品时查看商品数据的参数
                form_add_product_showproduct: {
                    currentpage: 1,
                    pagesize: 5,
                    is_plan: 1,
                    is_produce: 1,
                },

                productBrand: [], //品牌数据
                choose_brand: '', //选择的品牌
                showState: [{
                        id: 0,
                        value: '不上架'
                    },
                    {
                        id: 1,
                        value: '上架'
                    }
                ], //是否上架信息数组
                choose_show: '', //选择的上架状态
                qrState: [{
                        id: 0,
                        value: '不生成'
                    },
                    {
                        id: 1,
                        value: '生成'
                    }
                ], //是否生成二维码
                choose_qr: '', //选择的是否生成二维码
                key_word: '', // 搜索词
                warehouseData: [],
                store_id: '',


                // ---------------------------选择要入库的产品----------------------------------------------
                RK_chooseProductData: [],

                // -------------------------------批次数据-------------------------------------------
                BatchData: [],
                ItemChooseBatchName: '请选择',
                ItemChooseBatchNameId: 0,

                TypeTotal: '',
                NumTotal: '',

                drawer: false,
                direction: 'ltr',
                drawerTitle: '',


            }
        },
        created() {
            this.getWarehouseInfo()
            this.getBatchInfo()

        },
        methods: {

            // 获取仓库
            async getWarehouseInfo() {
                const res = await this.$https.post(erpApi + '/store/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.warehouseData = res.data
            },

            // 返回
            goBack() {
                this.$router.go(-1)
            },

            // 12.添加商品方法
            addPurChaseProduct() {
                if(this.store_id==''){
                    return this.$message.error('请先选择仓库')
                }
                this.is_add_product = true
                this.getAddProductShowProduct()
                this.getAddProductFilterBrand()
            },





            // 17.删除采购单添加商品选中项
            deleteAddProduct(data) {
                this.$confirm('您确定删除该商品吗？')
                    .then(
                        () => {
                            this.product_data.splice(this.product_data.indexOf(data), 1)
                            this.$message.success('您删除成功！')
                            let sum = 0;
                            this.product_data.forEach(item => {
                                sum += Number(item.num)
                            })
                            this.ProductNumber = sum
                        }
                    )
                    .catch(
                        () => {
                            this.$message.error('您取消了按钮')
                        })
            },

            async DirectRKDo() {
                this.$confirm('您确定登记这个入库吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'success'
                    })
                    .then(
                        async () => {
                            if (this.product_data.length == 0) {
                                return this.$message.error('请选择入库商品')
                            }
                            this.products = []
                            this.product_data.forEach(item => {
                                let productsObj = {};
                                let {
                                    num,
                                    id,
                                    batch_id
                                } = item
                                productsObj.product_id = id
                                productsObj.quantity = num
                                productsObj.batch_id = batch_id
                                this.products.push(productsObj)
                            })

                            let params = {
                                store_id: this.store_id,
                                remarks: this.form_add_purchase.remarks,
                                product_list: this.products
                            }


                            const {
                                data
                            } = await this.$https.post(erpApi + '/receive/create?_ajax=1', params)

                            if (data.error_code !== 0) return this.$message.error(data.msg)
                            this.$message.success(data.msg)

                            // 清空
                            this.product_data = [];

                            this.form_add_purchase.remarks = '';

                            this.$router.push({
                                path: '/Warehousing'
                            })
                        }
                    )
                    .catch(
                        () => {
                            this.$message.error('您取消了按钮')
                        }
                    )


            },




            // 15.添加商品时查看商品数据的方法
            async getAddProductShowProduct() {
                let params = {
                    page: this.form_add_product_showproduct.currentpage,
                    per_page: this.form_add_product_showproduct.pagesize,
                    is_plan: 0,
                    is_produce: 1,
                    brand_id: this.choose_brand,
                    is_show: this.choose_show,
                    is_qr: this.choose_qr,
                    keyword: this.key_word,
                }
                if (this.choose_show == '' && this.choose_show !== 0) {
                    delete params.is_show
                }
                if (this.choose_qr == '' && this.choose_qr !== 0) {
                    delete params.is_qr
                }
                if (this.choose_brand == '') {
                    delete params.brand_id
                }
                if (this.key_word == '') {
                    delete params.keyword
                }
                const res = await this.$https.post(erpApi + '/product/select?_ajax=1', params)
                if (res.status !== 200) return this.$message.error('获取商品数据失败')
                this.purchase_add_data = res.data.data
                this.purchase_add_data.forEach(item => {
                    item.num = 1
                })


                this.total_addproduct = res.data.total

                this.echo()

            },

            echo(){
                this.product_data.forEach(node=>{
                    this.purchase_add_data.forEach(ele=>{
                        if(node.id == ele.id){
                            ele.myChecked=true
                        }
                    })
                })
            },

            // 重置搜索数据方法
            resetSearch() {
                this.choose_brand = ''
                this.choose_show = ''
                this.choose_qr = ''
                this.key_word = ''
            },



            // 当前页发生改变触发的方法
            handleCurrentChangeAddProduct(data) {
                this.form_add_product_showproduct.currentpage = data;
                this.getAddProductShowProduct()
            },


            // 16.添加商品时过滤品牌数据的方法
            async getAddProductFilterBrand() {
                const res = await this.$https.post(erpApi + '/brand/select?_ajax=1')
                if (res.status !== 200) return this.$message.error('获取品牌数据失败')
                this.productBrand = res.data
            },

            // 返回
            goBack() {
                this.$router.go(-1)
            },



            add(row) {
                row.myChecked=true

                let i = this.product_data.indexOf(row)

                if (i == -1) {
                    let obj = {
                        id: row.id,
                        sn: row.sn,
                        brand_name: row.brand_name,
                        name: row.name,
                        spec: row.spec,
                        is_batch: row.is_batch,
                        unit_name: row.unit_name,
                        num: 0,
                        batch_id: '',
                    }
                    this.product_data.push(obj)
                }


                this.calcNum()
            },







            // 添加和保存数量方法
            SaveProductNum(row) {
                row.showNum = !row.showNum
                this.calcNum()
            },

            remoteMethod(data) {
                setTimeout(() => {
                    this.getBatchInfo(data)
                }, 300)
            },

            // 获取批次
            async getBatchInfo(data) {
                let params = {
                    keyword: data
                }
                const res = await this.$https.post(erpApi + '/batch/select?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.BatchData = res.data
            },

            // 选择批次
            changeBatch(data) {

                this.ItemChooseBatchNameId = data
                this.BatchData.forEach(item => {
                    if (item.id == data) {
                        this.ItemChooseBatchName = item.name
                    }
                })
            },

            // 选择保存批次

            saveBatch(row) {

                if (row.showBatch == true) {
                    if (this.ItemChooseBatchNameId === 0) {
                        this.ItemChooseBatchName = '——'
                    }
                    row.batch_name = this.ItemChooseBatchName
                    row.batch_id = this.ItemChooseBatchNameId
                }
                if (row.showBatch == false) {
                    if (this.ItemChooseBatchNameId === 0) {
                        this.ItemChooseBatchName = '请选择'
                    }
                }
                row.showBatch = !row.showBatch

            },





            // 表格双击事件
            changeInputAndShow(row, column, cell, event) {
                row.showNum = !row.showNum
                this.calcNum()

            },

            changeNumFunction() {
                this.calcNum()
            },



            // 计算数量
            calcNum() {
                this.NumTotal = 0
                this.product_data.forEach(item => {
                    this.NumTotal += +item.num
                })

                this.TypeTotal = 0
                this.TypeTotal = this.product_data.length
            },




            // 21.查看计划生产信息方法
            async seePlanProduct(row) {
                this.drawer = true
                let params = {
                    product_id: row.id
                }
                const res = await this.$https.post(erpApi + '/product/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)

                this.drawerTitle = res.data.sn + ' ' + res.data.name + ' ' + '(' + res.data.spec + ')'
                // 赋值产品计划生产信息数组
                this.plan_production_info = res.data.plan_details;

                // 赋值库存信息数组
                this.stock_info = res.data.storages;

                // 赋值所属商品信息数组
                this.belong_product_info = res.data.goods

                // 赋值其余参数
                this.plan_total_quantity = res.data.plan_total_quantity
                this.plan_total_receive = res.data.plan_total_receive
                this.storage_total_quantity = res.data.storage_total_quantity
            },


            // 删除
            deleteProductNum(row) {
               
                let i=this.product_data.indexOf(row)
                this.product_data.splice(i,1)
                this.calcNum()
            },

            // 赋值此项
            copyItem(row) {
                this.product_data.push(row)
                this.product_data = JSON.parse(JSON.stringify(this.product_data))
            },

            changeStore_fun(){
                this.product_data=[]
            },




            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind = "background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                return styleBind;
            },



        },
    }
</script>

<style lang="less" scoped>
    #app {
        height: auto;
    }

    .addPurchaseOrder {
        position: relative;
    }



    .back {
        position: absolute;
        right: 6%;
    }

    .el-form {
        margin-top: 30px;
    }

    .choosePro {
        margin-top: 10px;
        margin-left: 10px;
    }




    .search_addPro {
        margin-left: 20px;
    }



    .confirmList {
        margin-bottom: 20px;
    }

    #app {
        overflow: hidden;
    }

    h3 {
        font-size: 24px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        margin-bottom: 20px;
    }

    .el-breadcrumb {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .el-card {
        position: relative;
    }

    .purchasechoose {
        margin: 0px 0 30px 0;
        background-color: #dfe9f5;
        padding: 8px 10px;
    }

    .datePicker {
        margin-left: 20px;
    }

    .addpurchase {
        margin-left: 20px;
        margin-right: 10px;
        // margin-bottom: 15px;
        // margin-right: 15px;
    }

    .el-pagination {
        // margin-top: 30px;
    }

    .remarks {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 150px;
        text-align: center;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    /deep/.el-dialog__body {
        display: flex;
        flex-direction: column;
    }

    .purchaseRemark,
    .chooseWareHouse {
        margin-top: 30px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        font-weight: 550;
        font-size: 18px;
        letter-spacing: 2px;
    }

    .addProduct {
        // background-color: #dfe9f5;
        padding: 12px 20px;
        display: flex;
        justify-content: start;
        align-items: center;
        background-color: #f5f7fa;
    }

    .PDProduct {
        justify-content: start
    }

    .addProduct .el-select {
        margin-right: 20px;
    }

    /deep/.el-input .el-input-group {
        width: 280px;
        float: right
    }

    /deep/.el-input-group>.el-input__inner {}

    /deep/ .key_word {
        width: 300px;
    }



    .el-tag {
        margin-right: 20px;
        margin-bottom: 15px;
        // padding: 0 10px;
    }

    .setProductNumber {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .icon-yiruku,
    .icon-bufenruku {
        color: #409eff;
        font-size: 26px;
        margin-left: 5px;
    }

    .addProduct .labelName {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
    }

    .goodsCheck {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 500px;
    }

    .goodsCheckLeft {
        flex: 2;
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: #dfe9f5;
    }

    .goodsCheckRight {
        flex: 12;
        display: flex;
        align-items: flex-start;
        height: 100%;
        padding: 0;
        overflow-Y: auto;
        margin-left: 15px;
    }

    .goodsCheckRightContent .el-table {
        margin-top: 0;
    }

    .searchDo {
        background-color: #0052d9;
        border-color: #0052d9;
        color: #ffffff;
        margin-left: 20px;
        margin-bottom: 20px;

    }

    .searchDo:hover {
        background-color: #176eff;
        border-color: #176eff;
        color: #ffffff;
    }

    .searchDo:focus {
        background-color: #024cd2;
        border-color: #024cd2;
        color: #ffffff;
    }

    .setNumberInput {
        width: 150px;
    }



    .choose_warehouse {
        font-size: 14px;
        font-weight: 560;
        margin-top: 50px;
        margin-bottom: 20px;
    }

    .btnArea {
        padding-left: 10%;
        margin-top: 5px;
    }


    /* el-table 滚动条样式 */
    /deep/.el-table__body-wrapper::-webkit-scrollbar {
        height: 8px;
        width: 6px;
    }



    /deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }


    .drawerTitStyle {
        text-align: center;
        font-size: 16px;
        // color: #606266;
        font-weight: 500;
        color: #1f6ef0;
    }
</style>