<template>
    <div>


        <el-card>
            <el-button size="mini" class="reverseBtn backBtn" @click="goBack">返 回</el-button>
            <div class="title">修改商品:</div>
            <el-form :model="Form" :rules="Rules" label-width="120px" label-position="right">
                <div class="item">
                    <el-form-item label="商品名称:" prop="name">
                        <el-input v-model="Form.name" size="mini" placeholder="请输入名称"></el-input>
                    </el-form-item>
                    <el-form-item label="商品分类:" class="chooseCategory">
                        <el-cascader :options="sortSetData" :show-all-levels="true" clearable placeholder="请选择商品分类"
                            :props="{value:'id',label:'name', checkStrictly: true}" size="mini"
                            v-model="categoryIds_edit"></el-cascader>
                    </el-form-item>
                </div>

                <div class="item">
                    <el-form-item label="下架日期:">
                        <!-- <el-date-picker v-model="sEndtime" type="date" placeholder="选择日期" size="mini"
                            format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="fnChangeEndTime">

                        </el-date-picker> -->

                            <input type="date" v-model="Form.end_time">
                    </el-form-item>
                </div>


                <div class="item">




                    <el-form-item label="最小起订量：" prop="name">
                        <el-input v-model="Form.sell_min_num" size="mini" clearable class="Input"></el-input>
                    </el-form-item>

                    <el-form-item label="最大可售量：" prop="name">
                        <el-input v-model="Form.sell_max_num" size="mini" clearable class="Input"></el-input>
                    </el-form-item>

                </div>

                <div class="item">


                </div>


                <div class="item radioBtn">

                    <el-form-item label="是否可售:" prop="name">
                        <el-radio-group v-model="Form.is_sell">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>



                </div>

                <div class="item radioBtn">

                    <el-form-item label="是否可退回:" prop="name">
                        <el-radio-group v-model="Form.is_return">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>

                </div>


                <div class="item radioBtn">

                    <el-form-item label="是否一口价:" prop="name">
                        <el-radio-group v-model="Form.is_fixed">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>

                </div>

                <div class="item productList">

                    <el-form-item label="已选择产品:" prop="name">
                        <el-button @click="addProBtn" type="text" class="text_primary">修改</el-button>
                    </el-form-item>

                </div>



                <div>
                    <div class="selected">

                        <div v-for="(node,index) in this.selectedData" :key="index" class="checkedProductStyle">
                            <span>{{node.sn}}</span>
                            <span class="nodeItemStyle">{{node.name}} ({{node.spec}})</span>
                            <span class="quantity_style">数量:
                                <el-input @change="changeInput" clearable class="quantity_width" size="mini"
                                    v-model="node.pivot.quantity"></el-input>
                            </span>

                            <span>价格:
                                <el-input class="quantity_width" size="mini" clearable v-model="node.pivot.price"
                                    @change="changeInput"></el-input>
                            </span>

                            <span>
                                <el-button class="delete_style text_delete" type="text" @click="deleteNode(node)">删除
                                </el-button>
                            </span>

                        </div>

                    </div>
                </div>

                <div>

                </div>

                <div class="count">
                    <span>
                        产品种类: <span class="Number">{{type}}</span>
                    </span>

                    <span style="margin:0 30px">
                        产品个数: <span class="Number">{{total}} </span>
                    </span>

                    <span>
                        产品总价: <span class="Number"> {{totalPrice}}
                        </span>元
                    </span>

                </div>



            </el-form>


            <el-button class="frontBtn submit_style" size="mini" @click="submitDo">提 交</el-button>
        </el-card>



        <!-- -----------------------------------Dialog   对话框（一层）------------------------------------------- -->
        <!-- 添加产品对话框 -->
        <el-dialog title="添加产品" :visible.sync="is_addProBtn" width="80%" class="addProDialog">

            <div class="btns">
                <span>
                    品牌：
                    <el-select @change="searchWithCondition" clearable v-model="get_productForm.brand_id"
                        placeholder="请选择" size="mini">
                        <el-option v-for="item in brandData" :key="item.id" :label="item.name" :value="item.id">
                            {{item.name}}
                        </el-option>
                    </el-select>
                </span>

                <span>
                    是否计划生产：
                    <el-select @change="searchWithCondition" clearable v-model="get_productForm.is_plan"
                        placeholder="请选择" size="mini">
                        <el-option v-for="item in whetherData" :key="item.id" :label="item.name" :value="item.id">
                            {{item.name}}
                        </el-option>
                    </el-select>
                </span>

                <span>
                    是否上架：
                    <el-select @change="searchWithCondition" clearable v-model="get_productForm.is_show"
                        placeholder="请选择" size="mini">
                        <el-option v-for="item in whetherData" :key="item.id" :label="item.name" :value="item.id">
                            {{item.name}}
                        </el-option>
                    </el-select>
                </span>


                <span>
                    是否生产：
                    <el-select @change="searchWithCondition" clearable v-model="get_productForm.is_produce"
                        placeholder="请选择" size="mini">
                        <el-option v-for="item in whetherData" :key="item.id" :label="item.name" :value="item.id">
                            {{item.name}}
                        </el-option>
                    </el-select>
                </span>


                <span>
                    是否生成二维码：
                    <el-select @change="searchWithCondition" clearable v-model="get_productForm.is_qr" placeholder="请选择"
                        size="mini">
                        <el-option v-for="item in whetherData" :key="item.id" :label="item.name" :value="item.id">
                            {{item.name}}
                        </el-option>
                    </el-select>
                </span>

                <span>
                    是否批次管理：
                    <el-select @change="searchWithCondition" clearable v-model="get_productForm.is_batch"
                        placeholder="请选择" size="mini">
                        <el-option v-for="item in whetherData" :key="item.id" :label="item.name" :value="item.id">
                            {{item.name}}
                        </el-option>
                    </el-select>
                </span>

                <span>
                    <div>
                        搜索：
                    </div>
                    <el-input size="mini" class="searchStyle" @change="searchWithCondition" clearable
                        v-model="get_productForm.keyword" placeholder="请输入关键词"></el-input>
                </span>

                <span>
                    <el-button size="mini" class="reverseBtn el-icon-refresh" @click="resetBtn">重 置</el-button>
                </span>
            </div>

            <!-- 数据表格 -->
            <el-table :data="productsData" ref="multiple" :header-cell-style="styleBindFun" max-height="auto"
                style="width:100%" border>

                <el-table-column prop="sn" label="货号" align="center">
                </el-table-column>

                <el-table-column prop="barcode" label="条码" align="center">
                </el-table-column>

                <el-table-column prop="brand_name" label="品牌" align="center">
                </el-table-column>

                <el-table-column prop="name" label="商品名称" align="center" width="500">
                    <template slot-scope="scope">
                        {{scope.row.name}} <span v-show="scope.row.spec">({{scope.row.spec}})</span>
                    </template>
                </el-table-column>
                <el-table-column label="是否按批次管理" align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.is_batch" class="yesIcon el-icon-check"></div>
                        <div v-else class="noIcon el-icon-close"></div>
                    </template>
                </el-table-column>

                <el-table-column fixed="right" label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" :disabled="scope.row.mychecked" @click="addProItem(scope.row)">添加
                        </el-button>
                    </template>
                </el-table-column>

            </el-table>

            <!-- 分页器 -->
            <el-pagination @current-change="handleCurrentChange" :current-page="get_productForm.page" :page-sizes="[5]"
                :page-size="get_productForm.per_page" layout="total, sizes, prev, pager, next, jumper"
                :total="totalProduct" align="center">
            </el-pagination>



            <div slot="footer" class="dialog-footer">

                <el-button class="frontBtn" size="mini" @click="addProDo">确 定</el-button>
            </div>
        </el-dialog>




    </div>
</template>

<script>
    import {
        adminApi
    } from '../../config'

    export default {
        data() {
            return {
                initData: [],
                goodsDetailData: [],
                selectedData: [],

                Form: {
                    id: '',
                    name: '',
                    category_id: '',
                    end_time: '',
                    is_sell: '',
                    is_return: '',
                    is_fixed: '',
                    sell_min_num: '',
                    sell_max_num: '',
                    product_list: []
                },
                sEndtime: '',
                addGoods_Form_Category: [],

                Rules: {
                    name: {
                        required: true,
                        message: '请补全表单',
                        trigger: 'blur'
                    }
                },
                sortSetData: [],
                Productdata: [],
                type: 0,
                total: 0,
                totalPrice: 0,
                CNCapitalValue: '',
                temp: 0,
                tempData: [],


                // 产品
                is_product: false,
                productData: [],
                form_getPro: {
                    page: 1,
                    per_page: 5,
                },


                // 最新产品数据
                productsData: [],
                get_productForm: {
                    page: 1,
                    per_page: 5,
                    brand_id: '',
                    is_plan: '',
                    is_show: '',
                    is_produce: '',
                    is_qr: '',
                    is_batch: '',
                    keyword: '',
                },
                totalProduct: 0,

                chooseProductData: [],
                checkedGood: [],

                whetherData: [{
                        id: 0,
                        name: '否'
                    },
                    {
                        id: 1,
                        name: '是'
                    }
                ],


                // 选择产品
                is_choose_product: false,
                confirmData: [],

                // 添加产品
                is_addProBtn: false,


                // 商品分类
                categoryIds_edit: [],

                brandData: [],
                is_decimals: false,


                value1:''


            }
        },
        created() {
            // this.getSortSetInfo()
            this.getSortSetInfo()
        },
        methods: {


            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind = "background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                return styleBind;
            },

            init() {
                this.initData = this.$route.query.data
                this.getGoodsDetail(this.initData.id)
            },

            async getGoodsDetail(id) {
                let params = {
                    id: id
                }
                const res = await this.$https.post(adminApi + '/goods/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.goodsDetailData = res.data
                console.log(this.goodsDetailData, 'goodsDetailData');
                this.Form.id = this.goodsDetailData.id
                this.Form.name = this.goodsDetailData.name
                this.Form.end_time = this.goodsDetailData.end_time
                this.value1 = this.Form.end_time
                this.Form.is_sell = this.goodsDetailData.is_sell ? 1 : 0
                this.Form.is_return = this.goodsDetailData.is_return ? 1 : 0
                this.Form.is_fixed = this.goodsDetailData.is_fixed ? 1 : 0

                this.Form.sell_min_num = this.goodsDetailData.sell_min_num
                this.Form.sell_max_num = this.goodsDetailData.sell_max_num

                this.categoryIds_edit = []
                this.recursionGetCategoryIds(this.sortSetData, this.goodsDetailData.category_id)

                this.selectedData = this.goodsDetailData.products
                this.calc()

                // 赋值

            },

            recursionGetCategoryIds(data, id) {
                //1.循环
                data.forEach(node => {
                    //2.判断
                    if (node.id == id) {
                        //3.判断父类
                        if (node.parent_id !== 0) {
                            this.categoryIds_edit.unshift(node.id)
                            this.recursionGetCategoryIds(this.sortSetData, node.parent_id)
                        } else {
                            this.categoryIds_edit.unshift(node.id)
                        }
                    } else if (node.children) {
                        this.recursionGetCategoryIds(node.children, id)
                    }
                })
            },






            async getProductsData() {
                let params = this.get_productForm
                const res = await this.$https.post(adminApi + '/product/select?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.productsData = res.data.data
                this.totalProduct = res.data.total
                this.echo()
            },

            echo() {
                this.selectedData.forEach(ele => {
                    this.productsData.forEach(node => {
                        if (ele.id == node.id) {
                            node['mychecked'] = true
                        }
                    })
                })
            },


            // ------------展示设置分类数据   方法------------------------
            async getSortSetInfo() {
                const res = await this.$https.post(adminApi + '/category/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.sortSetData = res.data
                this.init()
            },

            async getBrandInfo() {
                const res = await this.$https.post(adminApi + '/brand/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.brandData = res.data
            },

            handleCurrentChange(data) {
                this.get_productForm.page = data
                this.getProductsData()
            },



            addProItem(row) {
                row.mychecked = true
                let obj = {
                    sn: row.sn,
                    id: row.id,
                    name: row.name,
                    spec: row.spec,
                    is_batch: row.is_batch,
                    pivot: {
                        price: 0,
                        quantity: 0,
                    }

                }
                this.selectedData.push(obj)
            },




            async submitDo() {
                if (this.categoryIds_edit.length > 0) {
                    this.Form.category_id = this.categoryIds_edit[this.categoryIds_edit.length - 1]
                }
                let tempArray = []
                this.selectedData.forEach(node => {
                    let obj = {
                        product_id: node.id,
                        quantity: node.pivot.quantity,
                        price: node.pivot.price,
                    }
                    tempArray.push(obj)
                })
                this.Form.product_list = tempArray
                let params = this.Form

                const {
                    data
                } = await this.$https.post(adminApi + '/goods/edit?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.$router.push({
                    path: '/goodsSet',
                    query: {
                        data: 'edit',
                    }
                })
            },


            deleteNode(node) {
                let i = this.selectedData.indexOf(node)
                this.selectedData.splice(i, 1)
            },

            // 添加产品
            addProBtn() {
                this.is_addProBtn = true
                this.getProductsData()

            },

            addProDo() {
                this.calc()
                this.is_addProBtn = false
            },

            calc() {
                this.total = 0
                this.totalPrice = 0
                this.type = this.selectedData.length
                this.selectedData.forEach(item => {
                    this.total += Number(item.pivot.quantity)
                })
                this.selectedData.forEach(item => {
                    this.totalPrice = this.totalPrice + Number(item.pivot.price) * item.pivot.quantity
                })
                this.totalPrice = this.totalPrice.toFixed(2)

            },

            changeInput() {
                this.calc()
            },

            // 添加产品搜索条件
            searchWithCondition() {
                this.get_productForm.page = 1
                this.getProductsData()
            },

            resetBtn() {
                this.get_productForm.brand_id = ''
                this.get_productForm.is_plan = ''
                this.get_productForm.is_show = ''
                this.get_productForm.is_produce = ''
                this.get_productForm.is_qr = ''
                this.get_productForm.is_batch = ''
                this.get_productForm.keyword = ''
                this.getProductsData()
            },

            fnChangeEndTime() {
                console.log( this.value1,' this.value1');
                this.Form.end_time = this.value1
                console.log(this.Form.end_time, 'this.Form.end_time');
            },





            // CNCapitalValue(){},


            goBack() {
                this.$router.push({
                    path: '/goodsSet',
                    query: {
                        data: 'edit',
                    }
                })
            },
        },
    }
</script>

<style scoped>
    .item {
        padding: 0;
        display: flex;

    }

    .Input {
        width: 80%;
    }

    .backBtn {
        position: absolute;
        right: 5%;
    }

    .count {
        font-size: 16px;
        color: #333;
        font-weight: 560;
        font-family: Arial, Helvetica, sans-serif;
        float: right;
        margin: 30px 120px;
    }

    .radioBtn {
        display: flex;
    }

    .title {
        margin-bottom: 30px;
        font-size: 20px;
        font-weight: bolder;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }

    div /deep/.chooseProduct_style .el-dialog__body {
        display: flex;
        flex-direction: column;
    }

    .checkboxItem_style {
        width: 30%;
        margin-top: 10px;
        margin-bottom: 10px;
    }


    .productList {
        display: flex;
        flex-direction: column;
    }

    .selected {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 10px;
    }

    .checkedProductStyle {
        width: 50%;
        font-size: 14px;
    }

    .nodeItemStyle {
        margin: 0 10px;
    }





    .quantity_width {
        width: 100px;
    }

    .quantity_style {
        margin: 0 5px;
    }

    .delete_style {
        margin-left: 20px;
    }

    div /deep/.addProDialog .el-dialog__body {
        display: flex;
        flex-direction: column;

    }

    .submit_style {
        margin-top: 5%;
        margin-left: 5%;
    }

    .selectedTit {
        font-size: 16px;
        font-weight: 550;
        margin-top: 10px;
    }

    div /deep/ .chooseCategory .el-cascader {
        width: 150%;
    }

    .addProDialog .btns {
        display: flex;
        flex-wrap: wrap;
        background-color: #f5f7fa;
        padding: 10px 20px 0 20px;
        border-radius: 3px;

    }

    .addProDialog .btns span {
        width: 25%;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        color: #333;
    }

    .searchStyle {
        width: 80%;
    }
</style>