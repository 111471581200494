<template>
    <div id="app">

        <!-- 布局 -->
        <div class="purchaseLayout">

            <!-- 面包屑导航 -->
            <!-- <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>采购入库</el-breadcrumb-item>
                <el-breadcrumb-item>产品调拨</el-breadcrumb-item>
            </el-breadcrumb> -->

            <el-card>

                <div class="btns">
                    <span class="BtnItem">
                        日期：
                        <el-date-picker size="small" v-model="searchDate" type="daterange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </span>

                    <span class="BtnItem">
                        调出仓库：
                        <el-select v-model="OutId" size="small" clearable placeholder="请选择" @change="changeOutSelect">
                            <el-option v-for="item in wareHouseData" :key="item.value" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </span>
                    <span class="BtnItem">
                        调入仓库：
                        <el-select v-model="InId" size="small" clearable placeholder="请选择" @change="changeInSelect">
                            <el-option v-for="item in wareHouseData" :key="item.value" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </span>
                    <span class="searchBtn">
                        <el-button class="frontBtn" size="mini" @click="search">搜 索</el-button>
                    </span>
                    <span class="resetBtn">
                        <el-button class="reverseBtn" size="mini" @click="reset">重 置</el-button>
                    </span>

                    <!-- <span class="backBtn">
                        <el-button class="reverseBtn" size="small" @click="goBack">返 回</el-button>
                    </span> -->
                </div>

                <div class="createAllocationBtn">
                    <el-button class="frontBtn"  size="small" @click="createAllocation">创建调拨单</el-button>
                </div>

                <div class="container">
                    <!-- 数据表格 -->
                    <el-table :data="allocationData" max-height="auto" style="width:100%" border
                        :header-cell-style="styleBindFun">

                        <el-table-column prop="order_num" label="调拨单编号" align="center"  fixed>
                        </el-table-column>

                        <el-table-column prop="transfer_store_name" label="调出仓库" align="center" fixed>
                        </el-table-column>

                        <el-table-column prop="receive_store_name" label="调入仓库" align="center"  fixed>
                        </el-table-column>

                        <el-table-column prop="products_count"  label="调出产品种类" align="center"  fixed>
                        
                        </el-table-column>

                        <el-table-column prop="products_quantity" label="调出产品个数" align="center" fixed>
                        </el-table-column>

                          <el-table-column prop="user_name" label="操作员" align="center"  fixed>
                        </el-table-column>

                        <el-table-column label="调出时间" align="center"  fixed>
                            <template slot-scope="scope">
                                {{scope.row.create_time}}
                            </template>
                        </el-table-column>

                        <el-table-column fixed="right" label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button type="text" class="text_primary" size="big" @click="seeDetail(scope.row)">
                                    查看详情</el-button>
                            </template>
                        </el-table-column>
                    </el-table>

                    <!-- 分页器 -->
                    <el-pagination @current-change="handleCurrentChangeList" :current-page="getAllocationInfo.page"
                        layout="total, prev, pager, next, jumper" :total="total" align="center">
                    </el-pagination>
                </div>



            </el-card>
        </div>


        <!-- 查看调拨单详情对话框 -->
        <el-dialog title="查看调拨单详情" :visible.sync="is_allocation_detail" width="84%" class="allocation_detail_style">
            <!-- 数据表格 -->
            <el-table :data="allocationDetailData" max-height="auto" style="width:100%" border
                :header-cell-style="styleBindFun">

                 <el-table-column prop="type_name" label="调拨类型" align="center">
                     <template slot-scope="scope">
                         {{scope.row.type_name}}
                         <i class="el-icon-bottom bottom_icon_style" v-if="scope.row.type_name=='调拨入库'"></i>
                         <i class="el-icon-top top_icon_style" v-else></i>
                     </template>
                </el-table-column>

                <el-table-column prop="product_sn" label="货号" align="center">
                </el-table-column>

                <el-table-column prop="brand_name" label="品牌" align="center">
                </el-table-column>


                <el-table-column label="产品" align="center" width="240">
                    <template slot-scope="scope">
                        {{scope.row.product_name}} <span v-show="scope.row.product_spec">（{{scope.row.product_spec}}）</span>
                    </template>
                </el-table-column>

                <el-table-column  label="批次" align="center" width="260">
                     <template slot-scope="scope">
                        <span v-show="scope.row.batch_name">{{scope.row.batch_name}} <span class="validityDateStyle">限用日期： {{scope.row.batch_expiry_date}}</span>  </span>
                    </template>
                </el-table-column>


                <el-table-column prop="store_name" label="仓库" align="center">
                </el-table-column>

               

                <el-table-column prop="before_quantity" label="调拨前仓库数量" align="center">
                    <template slot-scope="scope">
                        {{scope.row.before_quantity}} {{scope.row.unit_name}}
                    </template>
                </el-table-column>


                <el-table-column prop="after_quantity" label="调拨后仓库数量" align="center">
                    <template slot-scope="scope">
                        {{scope.row.after_quantity}} {{scope.row.unit_name}}
                    </template>
                </el-table-column>


                <el-table-column prop="quantity" label="调拨数量" align="center">
                    <template slot-scope="scope">
                       <span class="Number">{{scope.row.quantity}} </span> 
                    </template>
                </el-table-column>

            </el-table>

            <div class="remarks">
                <span>备注：</span>
                {{allocationDetailRemarks}}
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" class="frontBtn" @click="is_allocation_detail = false">确 定</el-button>
            </div>
        </el-dialog>





    </div>
</template>

<script>
    import {
        erpApi
    } from '../../config'

    export default {
        data() {
            return {
                //   搜索条件区
                searchDate: [],
                wareHouseData: [],
                InId: '',
                OutId: '',

                // 表格区
                allocationData: [],
                allocationDetailData: [],
                getAllocationInfo: {
                    page: 1,
                    per_page: 6,
                    start_date: '',
                    end_date: '',
                    transfer_store_id: '',
                    receive_store_id: '',
                },
                total: 0,

                // 对话框区
                is_allocation_detail: false,

                allocationDetailRemarks:'',




            }
        },
        created() {
            this.getWareHouseInfo()
            this.getAlloaction()
        },
        methods: {
            styleBindFun() {
                let styleBind = "background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                return styleBind;
            },

            // 获取仓库方法
            async getWareHouseInfo() {
                const res = await this.$https.post(erpApi + '/store/select?_ajax=1')

                if (res.status !== 200) return this.$message.error(res.statusText)
                this.wareHouseData = res.data
            },

            // 获取调拨列表方法
            async getAlloaction() {
                let params = this.getAllocationInfo
                const res = await this.$https.post(erpApi + '/transfers?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText);
                this.allocationData = res.data.data
                 this.allocationData.forEach(node=>{
                     node.products_count=(node.products_count/2)
                     node.products_quantity=(node.products_quantity/2)
                 })
                this.total = res.data.total

            },
            handleCurrentChangeList(data) {
                this.getAllocationInfo.page = data
                this.getAlloaction()
            },


            async seeDetail(row) {
                this.is_allocation_detail = true
                let params = {
                    order_num: row.order_num
                }
                const res = await this.$https.post(erpApi + '/transfer/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText);
                console.log(res,'res123');
                this.allocationDetailRemarks=res.data.remarks
               this.allocationDetailData = res.data.stocks
            },


            search() {
                if (this.OutId !== '' || this.InId !== '') {
                    if (this.OutId == this.InId) {
                        return this.$message.error('调出调入仓库不可一样')
                    }
                }
                if (this.searchDate.length !== 0) {
                    this.getAllocationInfo.start_date = this.searchDate[0]
                    this.getAllocationInfo.end_date = this.searchDate[1]
                }
                this.getAllocationInfo.transfer_store_id = this.OutId
                this.getAllocationInfo.receive_store_id = this.InId

                this.getAlloaction()


            },

            reset() {
                this.searchDate = []
                this.InId = ''
                this.OutId = ''
            },

            // 监听选择框发生改变
            changeOutSelect() {
                if (this.OutId !== '' || this.InId !== '') {
                    if (this.OutId == this.InId) {
                        return this.$message.error('调出调入仓库不可一样')
                    }
                }
            },
            changeInSelect() {
                if (this.OutId !== '' || this.InId !== '') {
                    if (this.OutId == this.InId) {
                        return this.$message.error('调出调入仓库不可一样')
                    }
                }
            },



            // 返回
            goBack() {
                this.$router.push({
                    path: '/purchase'
                })
            },


            createAllocation() {

                this.$router.push({
                    path: '/addAllocation',
                    query: {
                        data: this.wareHouseData
                    }
                })
            },




        },
    }
</script>

<style lang="less" scoped>
    .btns {
        position: relative;
        background-color: #f5f7fa;
        padding-left: 20px;
        padding-top: 15px;
        padding-bottom: 15px;

    }

    .BtnItem {
        // margin: 0 10px;
        margin-right: 30px;
        color: #333;
        font-size: 14px;
    }

    .searchBtn {
        margin-left: 30px;
        margin-right: 10px;
    }

    .resetBtn {}

    .backBtn {
        position: absolute;
        right: 5%;
    }

    .createAllocationBtn {
        margin: 20px 16px;
        margin-bottom: 15px;
    }

    .el-card {
        height: 87vh;
    }

    div /deep/.allocation_detail_style .el-dialog__body{
        display: flex;
        flex-direction: column;
    }

    .allocation_detail_style .remarks{
        margin-left: 20px;
        margin-top: 20px;
    }

    .allocation_detail_style .remarks span{
        font-weight: bolder;
    }
</style>