<template>
  <div class="login_container">
    <div class="logoImg">
      <img src="../assets/erplogo.png" alt="">
    </div>
    <div class="logo">
      <div class="tit">登录到<br>薇碧ERP 工作台</div>
      <el-button  size="medium" class="logoBtnStyle " type="primary" @click="Login">企 业 微 信 扫 码 登 录</el-button>
    </div>

    <div class="loginPic">
      <!-- <img src="../assets/erpbg.png" alt=""> -->
    </div>


    
   
  </div>
</template>

<script>
  export default {
    data() {
      return {
        flag: false,

        codeImgSrc: '',
        codeImgResult: '',
        url: '',
        token: '',
        // 这是登录表单的数据绑定对象
        loginForm: {
          username: 'vb0001',
          password: '1234',
        },
        verifiCode: '',
        // 这是表单的验证规则对象
        loginFormRules: {
          // 验证用户名是否合法
          username: [{
              required: true,
              message: '请输入登录名称',
              trigger: 'blur'
            },
            {
              min: 3,
              max: 10,
              message: '长度在 3 到 10 个字符',
              trigger: 'blur'
            }
          ],
          // 验证密码是否合法
          password: [{
              required: true,
              message: '请输入登录密码',
              trigger: 'blur'
            },
            {
              min: 6,
              max: 15,
              message: '长度在 6 到 15 个字符',
              trigger: 'blur'
            }
          ],

          stateJudage: this.$route.query.data



        }
      }
    },
    beforeCreate() {

    },
    created() {
      // this.getCsrfToken()

      // this.getCodeImg()
    },
    mounted() {

      this.testLoginState()
    },
    methods: {
      async testLoginState() {

        const res = await this.$https.post('https://api.rainben.cn/status?_ajax=1')
        console.log(res,'rs13');
        if (res.data.error_code == 0) {
          this.$router.push({
            path: "/welcome",
          })

        }

      },

      async Login() {
        const res = await this.$https.post('https://api.rainben.cn/status?_ajax=1')
        if (res.status !== 200) return this.$message.error(res.statusText)
        window.location.href = res.data.url
        // 'httpss://open.work.weixin.qq.com/wwopen/sso/qrConnect?appid=wx51323ea01c5f02d9&agentid=1000014&redirect_uri=https%3A%2F%2Flogin.rainben.cn%2Fqr&state=web_login'
      },

      // 获取验证码
      // getCodeImg() {
      //   this.codeImgSrc = "https://login.rainben.cn/verify?_=" + Math.random()
      // },
      // 获取token
      // async getCsrfToken() {
      //   const {
      //     data
      //   } = await this.$https.get('https://login.rainben.cn/csrf-token?_=' + Math.random(), {

      //     withCredentials: true,
      //   })
      //   this.token = data.token
      // },


      // async Login() {
      //   let params = {
      //     userid: this.loginForm.username,
      //     password: this.loginForm.password,
      //     verify: this.codeImgResult,
      //   }

      //   const res = await this.$https.post('https://login.rainben.cn/login?_=' + Math.random(), params, {
      //     headers: {
      //       'X-CSRF-TOKEN': this.token,
      //     },
      //     withCredentials: true,
      //   })

      //    if(res.data.status!=='success')  return   this.$message.error(res.data.msg)
      //    this.$message.success(res.data.msg)
      //   //  跳转
      //   // this.$router.push({
      //   //   // path:'home'
      //   //   path: '/none'
      //   // })
      //   //  window.location.href='https://www.baidu.com'
      // },












      // 点击重置按钮，重置登录表单
      // resetLoginForm() {
      //   // console.log(this);
      //   this.$refs.loginFormRef.resetFields()
      // },


      // login() {
      //   this.$refs.loginFormRef.validate(async valid => {
      //     if (!valid) return
      //     var params = {
      //       username: 'admin',
      //       password: '123456',
      //       verify: this.verifiCode
      //     }
      //     const {
      //       data: res
      //     } = await this.$https.post('/login?_=' + Math.random(), params, {
      //       headers: {
      //         'X-CSRF-TOKEN': this.token,
      //       },
      //       withCredentials: true,
      //     })
      //     console.log(res);
      //     // if (res.status !== 0) return this.$message.error('登录失败！')
      //     // this.$message.success('登录成功')
      //     //   // 1. 将登录成功之后的 token，保存到客户端的 sessionStorage 中
      //     //   //   1.1 项目中出了登录之外的其他API接口，必须在登录之后才能访问
      //     //   //   1.2 token 只应在当前网站打开期间生效，所以将 token 保存在 sessionStorage 中
      //     // window.sessionStorage.setItem('token', res.data.token)
      //     //   // 2. 通过编程式导航跳转到后台主页，路由地址是 /home
      //     // thiss.$router.push('/home')
      //   })
      // }
    }
  }
</script>



<style lang="less" scoped>
  .login_container {
    // background-color: #fff;
    // height: 100%;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    position: relative;
    background-color: #fcfdfe;
  }

  .logo{
      width: 20%;
      
      position: absolute;
      top: 32%;
      left: 5%;
      z-index: 999;
  }


  .logo .tit{
      font-size: 34px;
      font-weight: bolder;
      color: #191919;
      margin-bottom: 30px;
  }

 
  .logoBtnStyle{
    width: 280px;
  }


  .logoImg{
    width: 120px;
    position: absolute;
    top:10px;
    left: 24px;
  }

  .logoImg img{
    width: 100%;
    height: 100%;
  }

  // .loginPic{
  //   position: absolute;
  //   bottom: 0px;
  //   right: 0;
  //   width: 75%;
  // }

  // .loginPic img{
  //   width: 100%;
  // }

  .loginPic{
    position: absolute;
    bottom: 0px;
    right: 0;
    width: 75%;
    height: 86%;
    background-image: url('../assets/erpbg.png');
  }



 
</style>