<template>
    <div id="app">
        <el-card>
            <div class="top">
                <span>{{sCustomerName}}</span>
                <span>预存款: <i class="Number">￥{{sCustomerAccount}}</i></span>
                <span>信用消费: <i class="Number">￥{{sCustomerCreditSpend}}</i></span>
                <span>保证金:  <i class="Number">￥{{sCustomerDeposit}}</i></span>

                 <el-button size="mini" class="reverseBtn sBackBtnStyle"  @click="fnGoBack">返 回 
                </el-button>
            </div>

            <div class="sSearchArea_style">
                <span>
                    <span>配送类型:</span>
                    <span>
                        <el-select v-model="oReturnInfo.present_id" clearable placeholder="请选择"
                            @change="fnSeacrhWithCondition" size="small">
                            <el-option v-for="item in aPresentData" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </span>
                </span>

                <span>
                    <span>仓库类型:</span>
                    <span>
                        <el-select v-model="oReturnInfo.store_id" clearable placeholder="请选择"
                            @change="fnSeacrhWithCondition" size="small">
                            <el-option v-for="item in aWarehouseData" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </span>
                </span>

                <span>
                    <span>日期:</span>
                    <span>
                        <el-date-picker v-model="aDateArea" @change="fnSeacrhWithCondition" size="small"
                            type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                            value-format="yyyy-MM-dd hh:mm:ss">
                        </el-date-picker>
                    </span>
                </span>


                <span>
                    <el-button size="mini" class="frontBtn" icon="el-icon-search" @click="fnSeacrhWithCondition">搜 索
                    </el-button>
                    <el-button size="mini" class="reverseBtn" icon="el-icon-refresh" @click="fnSeacrhWithCondition">重 置
                    </el-button>
                </span>

               
            </div>

              <table width="100%" border="1" cellspacing="0" class="sTableStyle">
                        <thead class="sCustomerTheadStyle">
                            <tr class="sCustomerHeadStyle">
                                <th>退货编号<span style="color:#fa5a57">（双击查看详情）</span></th>
                                <th>订单类型</th>
                                <th>客户</th>
                                <th>客户别名</th>
                                <th>退货金额</th>
                                <th>退货仓库</th>
                                <th>退货时间</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(node,index) in aReturnData" :key="index" class="sCustomerItemStyle"
                                @click="fnClickItem(node)"
                                :class="nOrderNum == node.order_num ? 'sClickItemStyle' : ''">
                              
                                <td align="center" @dblclick="fnGetReturnDetail(node)">
                                    {{node.order_num}}
                                </td>
                                <td align="center">{{node.order_type}}</td>
                                 <td align="center">
                                    {{node.customer_name}}
                                </td>
                                <td align="center">{{node.customer_alias}}</td>
                                <td align="center">{{node.amount}}</td>
                                  <td align="center">{{node.store_name}}</td>
                                <td align="center">{{node.create_time}}</td>   
                            </tr>
                        </tbody>
                </table>   
        </el-card>

        <!--退货详情对话框 -->
        <el-dialog title="退货详情" :visible.sync="bReturnDetail" width="50%">
            <div class="sReturnDetail_style">

                <div class="base">
                    <div class="tit">退货信息 <i class="el-icon-caret-bottom caretStyle"></i></div>
                    <el-descriptions :column=2>
                        <el-descriptions-item label="操作人员">{{oReturnDetail.user_name}}</el-descriptions-item>
                        <el-descriptions-item label="备注">{{oReturnDetail.remarks}}</el-descriptions-item>
                    </el-descriptions>
                </div>

                <div class="product" v-show="oReturnDetail.product_list">
                    <div class="tit">退货产品 <i class="el-icon-caret-bottom caretStyle"></i></div>
                    <el-table :data="oReturnDetail.product_list" border style="width: 100%"
                        :header-cell-style="styleBindFun">
                        <el-table-column label="所属订单" align="center" width="210">
                            <template slot-scope="scope">
                                {{scope.row.order_num}}
                            </template>
                        </el-table-column>


                        <el-table-column label="产品编号" align="center" width="110">
                            <template slot-scope="scope">
                                {{scope.row.product_sn}}
                            </template>
                        </el-table-column>


                        <el-table-column label="产品" align="center">
                            <template slot-scope="scope">
                                {{scope.row.product_name}} <span
                                    v-show="scope.row.product_spec">{{scope.row.product_spec}} </span>
                            </template>
                        </el-table-column>

                        <el-table-column label="价格" align="center" width="110">
                            <template slot-scope="scope">
                                <span class="NumberNoLeft">￥{{scope.row.price}} </span>
                            </template>
                        </el-table-column>

                        <el-table-column label="数量" align="center" width="110">
                            <template slot-scope="scope">
                                {{scope.row.quantity}}
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" class="frontBtn" @click="bReturnDetail = false">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import {
        erpApi
    } from '../../config'



    export default {
        data() {
            return {
                sCustomerName:"",
                sCustomerAccount:"",
                sCustomerCreditSpend:"",
                sCustomerDeposit:"",


                oReturnInfo: {
                    customer_number:this.$route.query.data.sCustomerNumber,
                    page: 1,
                    per_page: 10,
                    present_id: '',
                    start_time: '',
                    end_time: '',
                    store_id: '',
                },
                aPresentData: [],
                aWarehouseData: [],
                aDateArea: [],
                aReturnData: [],
                ntotal: 0,


                // 详情
                bReturnDetail: false,
                oReturnDetail: '',

                nOrderNum:'',


            }
        },
        created() {
            console.log(this.oReturnInfo.customer_number);
            this.fnGetPresent()
            this.fnGetWarehouse()
            this.fnGetReturnList()
            this.init()
        },




        methods: {
            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind = "background:#f2f3f5;fontSize:14px;color:#1D2129;fontWeight: normal;"
                return styleBind;
            },

            init(){
               this.sCustomerName= this.$route.query.data.sCustomerName
               this.sCustomerAccount= this.$route.query.data.sCustomerAccount
               this.sCustomerCreditSpend= this.$route.query.data.sCustomerCreditSpend
               this.sCustomerDeposit= this.$route.query.data.sCustomerDeposit

            },


            // 获取配送类型
            async fnGetPresent() {
                const res = await this.$https.post(erpApi + '/present/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.aPresentData = res.data
                let obj = {
                    "id": 0,
                    "name": "预存款"
                }
                this.aPresentData.unshift(obj)
            },

            // 获取仓库
            async fnGetWarehouse() {
                const res = await this.$https.post(erpApi + '/store/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.aWarehouseData = res.data
            },

            fnSeacrhWithCondition() {
                if (this.aDateArea !== null && this.aDateArea.length > 0) {
                    this.oReturnInfo.start_time = this.aDateArea[0]
                    this.oReturnInfo.end_time = this.aDateArea[1]
                } else {
                    this.oReturnInfo.start_time = ''
                    this.oReturnInfo.start_time = ''
                }
                this.oReturnInfo.page = 1
                this.fnGetReturnInfo()

            },

            // 获取退货数据
            async fnGetReturnList() {
                let params = this.oReturnInfo
                const res = await this.$https.post(erpApi + '/return/index?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.aReturnData = res.data.data
                this.ntotal = res.data.total
            },

            // 获取退货详情
            async fnGetReturnDetail(row) {
                this.bReturnDetail = true
                let params = {
                    order_num: row.order_num
                }
                const res = await this.$https.post(erpApi + '/return/detail?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.oReturnDetail = res.data
            },

              fnClickItem(node) {
                this.nOrderNum = node.order_num
            },


           

            // 返回
            fnGoBack(){
                this.$router.go(-1)
            },

        },
    }
</script>

<style lang="less" scoped>

    .sSearchArea_style {
        background-color: #f5f7fa;
        padding: 10px 20px;
        display: flex;
        flex-wrap: wrap;
    }

    .sSearchArea_style span {
        flex: 1;
        display: flex;
        align-items: center;
    }

    .sSearchArea_style span span:nth-child(1) {
        font-size: 14px;
        flex: 2;
        display: flex;
        justify-content: flex-end;
    }


    .sSearchArea_style span span:nth-child(2) {
        flex: 8;
        padding: 0;
    }


    .sSearchArea_style span:last-child {
        padding-left: 20px;

    }

    .sReturnDetail_style .base .tit,
    .sReturnDetail_style .product .tit {
        font-size: 16px;
        font-weight: bolder;
        margin-bottom: 10px;
    }


    .sReturnDetail_style .product {
        margin-top: 20px;
    }

     .sReturnDetail_style{
        width: 100%;
    }



    div /deep/.el-descriptions__table{
        border: none;
    }

    .sTableStyle {
        margin-top: 20px;
    }

    .top {
       color: #606266;
        font-size: 14px;
        margin-bottom: 20px;
        position: relative;
    }

     .top span:nth-child(1) {
         font-weight: bolder;
        font-size: 18px;
        color: #333;
     }

      .top span {
          margin-right: 60px;
     }


     .el-range-editor.el-input__inner {
  width: 80%;
}

    .sBackBtnStyle{
        position: absolute;
        top: 0;
        right: 2%;
    }

</style>