<template>
    <div id="app">
        <!-- 面包屑导航 -->
        <!-- <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>业务管理</el-breadcrumb-item>
            <el-breadcrumb-item>客户管理</el-breadcrumb-item>
            <el-breadcrumb-item>创建客户</el-breadcrumb-item>
        </el-breadcrumb> -->

        <el-card class="addNewCustomer">
            <div class="title">创建客户
            </div>
            <el-button class="goBack reverseBtn" @click="goBack" size="small">返回</el-button>
            <div>

            <div class="baseTit">
                <!-- <i class="el-icon-caret-bottom baseTit_icon"></i> -->
                <span class="sRectangleStyle"></span>
                基本信息
            </div>
            <div class="item">
                <span>

                    <el-form  :rules="rules" :model="Form_addNewCustomer" label-position="top" label-width="80px">
                        <el-form-item prop="name" label="名称：">
                            <el-input placeholder="请输入" size="small" v-model="Form_addNewCustomer.name"></el-input>
                        </el-form-item>
                    </el-form>
                </span>
                <span>

                    <el-form :rules="rules" :model="Form_addNewCustomer" label-position="top" label-width="80px">
                        <el-form-item prop="alias" label="别名：">
                            <el-input placeholder="请输入" size="small" v-model="Form_addNewCustomer.alias"></el-input>
                        </el-form-item>
                    </el-form>
                </span>
            </div>

                <div class="item">
                <span>

                    <el-form  :rules="rules" :model="Form_addNewCustomer" label-position="top" label-width="80px">
                        <el-form-item prop="contactName"  label="联系人姓名：">
                            <el-input v-model="Form_addNewCustomer.contact" placeholder="请输入" size="small"></el-input>
                        </el-form-item>
                    </el-form>
                </span>
                <span>

                    <el-form :rules="rules" :model="Form_addNewCustomer" label-position="top" label-width="80px">
                        <el-form-item prop="telphone"  label="座机：">
                            <el-input v-model="Form_addNewCustomer.telephone" placeholder="请输入" size="small"></el-input>
                        </el-form-item>
                    </el-form>
                </span>
            </div>

             <div class="item">
               
                <span>

                    <el-form  label-position="top" label-width="80px">
                        <el-form-item  label="选择区域：">
                            <el-cascader @focus="fnFocusRegion" class="choosed" :options="RegionData" clearable v-model="checkedRegionData"
                                :props="{value:'id',label:'name',checkStrictly: true}" size="small">
                            </el-cascader>
                        </el-form-item>
                    </el-form>
                </span>

                 <span>
                    <el-form :rules="rules" :model="Form_addNewCustomer" label-position="top" label-width="80px">
                        <el-form-item prop="address"  label="填写详细地址：">
                            <el-input v-model="Form_addNewCustomer.address" placeholder="请输入" size="small"
                               ></el-input>
                        </el-form-item>
                    </el-form>
                </span>
            </div>

            <div class="item">

                <span>

                    <el-form :rules="rules" :model="Form_addNewCustomer" label-position="top" label-width="80px">
                        <el-form-item prop="phone"  label="手机：">
                            <el-input v-model="Form_addNewCustomer.mobile" placeholder="请输入" size="small"></el-input>
                        </el-form-item>
                    </el-form>
                </span>
               

                 <span>
                    <el-form :rules="rules" label-position="top" label-width="80px">
                        <el-form-item prop="value" label="是否接收短信：">
                            <el-radio-group v-model="Form_addNewCustomer.is_sms">
                                <el-radio :label="0">无</el-radio>
                                <el-radio :label="1">有</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-form>
                </span>
                
                
            </div>

             <div class="certificationTit">
                 <span class="sRectangleStyle"></span>
                资质信息
            </div>

                 <div class="item">
                <span>

                    <el-form :rules="rules"  :model="Form_addNewCustomer" label-position="top" label-width="80px">
                        <el-form-item prop="start_time" label="合作开始日期（不能晚于今天）：">
                            <el-date-picker  v-model="Form_addNewCustomer.start_time" type="date" placeholder="选择日期" size="small"
                                value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                    </el-form>
                </span>
                <span>

                    <el-form :rules="rules" :model="Form_addNewCustomer" label-position="top" label-width="80px">
                        <el-form-item prop="end_time" label="合作结束日期（只能选一年，不能多选）：">
                            <el-date-picker v-model="Form_addNewCustomer.end_time" type="date" placeholder="选择日期"  size="small"
                                value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                    </el-form>
                </span>
            </div>

            
            <div class="item">
                <span>

                    <el-form label-position="top" label-width="80px">
                        <el-form-item label="选择客户组：" >
                            <el-cascader @focus="fnFocusGroup" class="choosed" :options="GroupData" clearable v-model="checkedGroupData" 
                                :props="{value:'id',label:'name',checkStrictly: true}" size="small">
                            </el-cascader>

                        </el-form-item>
                    </el-form>
                </span>
                <span>

                    <el-form :rules="rules" :model="Form_addNewCustomer"  label-position="top" label-width="80px">
                        <el-form-item  label="选择信用等级：" prop="credit_id">
                            <el-select @focus="fnFocusCreditRating" class="choosed" v-model="Form_addNewCustomer.credit_id" clearable placeholder="请选择" size="small">
                                <el-option v-for="item in creditRatingData" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </span>
            </div>

            <div class="item">
                <span>

                    <el-form :rules="rules" :model="Form_addNewCustomer" label-position="top" label-width="80px">
                        <el-form-item label="选择折扣：" prop="discount_id">
                            <el-select @focus="fnFocusDiscounts" class="choosed" v-model="Form_addNewCustomer.discount_id" clearable placeholder="请选择"
                                size="small">
                                <el-option v-for="item in discountsData" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </span>
                <span>

                    <el-form :rules="rules" :model="Form_addNewCustomer" label-position="top" label-width="80px">
                        <el-form-item  label="选择配送方案：" prop="plan_id">
                            <el-select @focus="fnFocusDistributionScheme" class="choosed" v-model="Form_addNewCustomer.plan_id" clearable placeholder="请选择" size="small">
                                <el-option v-for="item in DistributionSchemeData" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </span>
            </div>

              <div class="item">
                

                 <span>
                    <el-form :rules="rules" :model="Form_addNewCustomer"  label-position="top" label-width="80px">
                        <el-form-item label="选择起订额：" prop="deliver_id">
                             <el-select @focus="fnFocusDeliver" class="choosed" v-model="Form_addNewCustomer.deliver_id" clearable placeholder="请选择" size="small">
                                <el-option v-for="item in deliverData" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </span>

                 <span>
                    <el-form :rules="rules" label-position="top" label-width="80px">
                        <el-form-item label="备注：" prop="value">
                            <el-input type="textarea" v-model="Form_addNewCustomer.remarks" placeholder="请输入" size="small"></el-input>
                        </el-form-item>
                    </el-form>
                </span>
              
            </div>


        

          
       


           
           </div>

        <div class="btns">
            <el-button class="frontBtn" @click="submitDo" size="small">提交</el-button>
            <el-button class="reverseBtn"  size="small">重置</el-button>
        </div>

          
        </el-card>

     
      

    </div>
</template>

<script>
    import {
        erpApi
    } from '../../config'

     

    export default {
        data() {
            return {
                // 新建客户表单数据
                Form_addNewCustomer: {
                    name: '',
                    alias: '',
                    group_id: '',
                    credit_id: '',
                    discount_id: '',
                    deliver_id:'',
                    plan_id: '',
                    contact: '',
                    telephone: '',
                    mobile: '',
                    remarks: '',
                    start_time: '',
                    end_time: '',
                    sort_order:'',
                    is_integral: '',
                    region_id: '',
                    address: '',
                    is_sms:'',
                    sort_order:100,
                },
                // 控制提取最后一个元素的阀门
                valve: true,
                RegionData: [],
                GroupData: [],

                DistributionSchemeData: [],

                creditRatingData: [],

                discountsData: [],

                deliverData:[],
                checkedGroupData:[],
                checkedRegionData:[],

                 rules:{
                   
                    name:[
                        { required: true, message: '请输入名称', trigger: 'blur' }
                    ],
                    alias:[
                        { required: true, message: '请输入别名', trigger: 'blur' }
                    ],
                    contactName:[
                        { required: true, message: '请输入联系人姓名', trigger: 'blur' }
                    ],
                    telphone:[
                        { required: true, message: '请输入座机', trigger: 'blur' }
                    ],
                     address:[
                        { required: true, message: '请填写详细地址', trigger: 'blur' }
                    ],
                     phone:[
                        { required: true, message: '请填写手机号', trigger: 'blur' }
                    ],
                    start_time:[
                        { required: true, message: '请选择合作开始日期', trigger: 'blur' }
                    ],
                    end_time:[
                        { required: true, message: '请选择合作结束日期', trigger: 'blur' }
                    ],
                      credit_id:[
                        { required: true, message: '请选择信用等级', trigger: 'blur' }
                    ],
                     discount_id:[
                        { required: true, message: '请选择折扣', trigger: 'blur' }
                    ],
                     plan_id:[
                        { required: true, message: '请选择配送方案', trigger: 'blur' }
                    ],
                      deliver_id:[
                        { required: true, message: '请选择起订额', trigger: 'blur' }
                    ],
                },

                // ConditionData: this.$route.query.data,
            }
        },
        created() {
            // this.splitConditionData()
          
            
            
        },
        methods: {
            // 获取区域
            fnFocusRegion(){
                this.getRegion()
            },

            fnFocusGroup(){
                this.getGroups()
            },

            fnFocusCreditRating(){
                  this.getCreditRating()
            },

            fnFocusDiscounts(){
            this.getCustomerDisCount()
            },

            fnFocusDistributionScheme(){
            this.getCustomerPeiSong()
            },

            fnFocusDeliver(){
                this.getDeliver()
            },






            // 分拆传过来的数据  
            // splitConditionData() {
            //     this.GroupData = this.ConditionData[0]
            //     this.DistributionSchemeData = this.ConditionData[1]
            //     this.creditRatingData = this.ConditionData[2]
            //     this.discountsData = this.ConditionData[3]
            // },

            // 获取起订额
            async getDeliver(){
                 const res = await this.$https.post(erpApi+'/deliver/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.deliverData = res.data
            },


            // 选择客户组选择数据
            async getGroups() {
                const res = await this.$https.post(erpApi+'/group/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.GroupData = res.data
            },

            // 选择信用等级选择数据
            async getCreditRating() {
                const res = await this.$https.post(erpApi+'/credit/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.creditRatingData = res.data

            },

            // 选择客户配送选择数据
            async getCustomerPeiSong() {
                const res = await this.$https.post(erpApi+'/plan/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.DistributionSchemeData = res.data

            },

            // 选择客户折扣方案选择数据
            async getCustomerDisCount() {
                const res = await this.$https.post(erpApi+'/discount/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.discountsData = res.data

            },


            // 获取区域信息
            async getRegion() {
                const res = await this.$https.get(erpApi+'/region/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.RegionData = res.data

            },

            // 提交信息
            async submitDo() {
                // 控制提取最后一个元素的阀门
                 this.Form_addNewCustomer.region_id=''
                 this.Form_addNewCustomer.group_id=''

                if(this.checkedRegionData.length>0){
                    this.Form_addNewCustomer.region_id = this.checkedRegionData[this.checkedRegionData.length-1];
                }
               
               if(this.checkedGroupData.length>0){
                   this.Form_addNewCustomer.group_id = this.checkedGroupData[this.checkedGroupData.length-1];
               }
    
                let params = this.Form_addNewCustomer
               
                const {data} = await this.$https.post(erpApi+'/customer/create?_ajax=1', params)
                this.valve = false
                if(data.error_code!==0) return this.$message.error(data.msg)
                this.$message.success(data.msg)
                // 返回
                this.$router.go(-1)
            },

          

            // 返回
            goBack(){
                this.$router.go(-1)
            }
        },
    }
</script>

<style lang="less" scoped>
    .addNewCustomer {
        padding: 10px 2%;
        position: relative;
    }

    .addNewCustomer .item {
        display: flex;
        justify-content: space-around;
        margin-bottom: 5px;
    }

 

    .addNewCustomer .item span {
        width: 36%;
      
    }

    .addNewCustomer .title {
        font-size: 20px;
        color: #333;
        font-weight: 540;
        margin-bottom: 30px;
    }

    .certificationTit,
    .baseTit{
        margin-left: 6%;
        font-weight:bolder;
        font-size: 15px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
    }

    .certificationTit{
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .baseTit_icon{
        font-size: 16px;
        color: #0052d9;
        font-weight: bolder;
    }

    .detailAddress {
        width: 100%;

    }

    
    .goBack{
        position: absolute;
        right: 12%;
        top: 2%;
    }

    .btns {
       
        padding-left: 20%;    
        margin-top: 40px;
        margin-bottom: 30px;
    }

    .choosed{
        width: 90%;
    }
</style>