<template>
    <div id="app">
        <!--  -->
        <el-card class="sProductListCardStyle">

            <div class="sSearchArea">
                <div class="first">
                    <span>选择品牌:
                          <el-select size="small" @change="fnGetProductList" v-model="oGetProductList.brand_id" clearable placeholder="请选择">
                        <el-option v-for="item in aProductBrand" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                    </span>

                    <span>是否计划生产:
                          <el-select size="small" @change="fnGetProductList" v-model="oGetProductList.is_plan" clearable placeholder="请选择">
                        <el-option v-for="item in aIsNot" :key="item.id" :label="item.value" :value="item.id">
                        </el-option>
                    </el-select>
                    </span>

                     
                </div>

                <div class="second">
                    <span>是否生产:
                          <el-select size="small" @change="fnGetProductList" v-model="oGetProductList.is_produce" clearable placeholder="请选择">
                        <el-option v-for="item in aIsNot" :key="item.id" :label="item.value" :value="item.id">
                        </el-option>
                    </el-select>

                     
                    </span>
                    <span>选择是否生成二维码:
                         <el-select size="small" @change="fnGetProductList" v-model="oGetProductList.is_qr" clearable placeholder="请选择">
                        <el-option v-for="item in aIsNot" :key="item.id" :label="item.value" :value="item.id">
                        </el-option>
                    </el-select>
                    </span>

                </div>

                <div class="thirdly">
                    <span>
                        关键词:
                         <el-input size="small" class="sKeyWordWidth" @change="fnGetProductList" @keyup.enter.native="fnGetProductList"  placeholder="请输入要查找的内容" v-model="oGetProductList.keyword" clearable>

                          </el-input>

                    </span>
                   
                     <el-button size="mini" class="frontBtn" icon="el-icon-search" @click="fnGetProductList">搜 索
                    </el-button>

                    <el-button size="mini" class="reverseBtn" icon="el-icon-refresh" @click="resetNewCustomer">重 置
                    </el-button>
                </div>
            </div>

            <!-- 数据表格 -->
                <el-table :data="aProductList"  style="width:100%"  border
                    :header-cell-style="styleBindFun">

                     <el-table-column prop="barcode" label="条码" align="center" >
                    </el-table-column>

                     <el-table-column prop="brand_name" label="品牌" align="center" >
                    </el-table-column>

                    <el-table-column prop="sn" label="产品编号" align="center">
                    </el-table-column>

                     <el-table-column  label="产品" align="center"  width="300">
                         <template slot-scope="scope">
                            {{scope.row.name}} <span v-show="scope.row.spec">({{scope.row.spec}})</span>
                        </template>
                    </el-table-column>

                     <el-table-column prop="record" label="备案编号" align="center"  width="300">
                    </el-table-column>

                    <el-table-column prop="goods_count" label="组成商品数" align="center" >
                             <template slot-scope="scope">
                                 <i class="NumberNoLeft">{{scope.row.goods_count}}</i>
                        </template>
                    </el-table-column>

                     <el-table-column prop="unit_name" label="单位" align="center" >
                        
                    </el-table-column>

                    <el-table-column prop="is_batch" label="是否按批次管理" align="center">
                            <template slot-scope="scope">
                                <div v-if="scope.row.is_batch" class="yesIcon el-icon-check"></div>
                            <div v-else class="noIcon el-icon-close"></div>
                      </template>
                    </el-table-column>

                </el-table>

                 <!-- 分页器 -->
                <el-pagination  @current-change="handleCurrentChange"
                    :current-page="oGetProductList.page" 
                    :page-size="oGetProductList.per_page" layout="total, sizes, prev, pager, next, jumper"
                    :total="nTotal" align="center">
                </el-pagination>
        </el-card>

       
    </div>
</template>

<script>

     import {
        erpApi
    } from '../../config'

    export default {
        data() {
            return {
                    oGetProductList:{
                        page:1,
                        per_page:12,
                        brand_id:'',
                        is_plan:'',
                        is_produce:'',
                        is_qr:'',
                        is_show:1,
                        keyword:'',
                    },
                    aProductList:[],
                    nTotal:0,

                    aProductBrand:[],
                    aIsNot:[
                        {
                            id:0,
                            value:'否'
                        },
                        {
                             id:1,
                            value:'是'
                        }
                    ],
            }
        },
        created() {
            this.fnGetProductList()
            this.fnGetProductBrand()
        },
        methods: {
             // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind = "background:#f6f8fa;fontSize:14px;color:#333333;fontWeight: bold;"
                return styleBind;
            },

            async fnGetProductList(){
                let params=this.oGetProductList;
                const res = await this.$https.post(erpApi + '/products?_ajax=1', params)
                if(res.status !== 200) return this.$message.error(res.statusText)
                this.aProductList = res.data.data
                this.nTotal=res.data.total
             }, 

            handleCurrentChange(data){
                this.oGetProductList.page=data
                this.fnGetProductList()
            },

            // 获取品牌
              async  fnGetProductBrand(){
                const res = await this.$https.post(erpApi + '/brand/select?_ajax=1')
               if(res.status !== 200) return this.$message.error(res.statusText)
                this.aProductBrand=res.data
            },


            searchWithCondition(){

            },

            resetNewCustomer(){
                this.oGetProductList.brand_id=''
                this.oGetProductList.is_plan=''
                this.oGetProductList.is_produce=''
                this.oGetProductList.is_qr=''
                this.oGetProductList.keyword=''
                this.fnGetProductList()
            },

            
        },
    }
</script>

<style lang="less" scoped>
    .sSearchArea{
        background-color: #f5f7fa;
        margin-bottom: 20px;
        padding: 10px 20px;
        border-radius: 5px;
    }

    .sSearchArea .first,
    .sSearchArea .thirdly,
    .sSearchArea .second{
        margin-bottom: 10px;
    }

     .sSearchArea .first span,
     .sSearchArea .thirdly span,
    .sSearchArea .second span{
        margin-right: 50px;
        font-size: 14px;
    }

    .sKeyWordWidth{
        width: 15%;
    }
  
</style>