<template>
    <div>

        <el-card>
            <div class="Back">
                <el-button class="reverseBtn" @click="goBack" size="small">返 回</el-button>
            </div>

            <div class="title FormTitle">
                修改客户
            </div>

            <el-form :model="Form" :rules="Rules"  label-width="150px" label-position="right">
                <div class="Item">
                    <el-form-item label="客户号:" >
                        <el-input v-model="Form.customer_number" disabled size="small"></el-input>
                    </el-form-item>

                    <el-form-item label="客户名称:" prop="name">
                        <el-input v-model="Form.name"  size="small"></el-input>
                    </el-form-item>




                </div>

                <div class="Item">

                    <el-form-item label="别名：" prop="alias">
                        <el-input v-model="Form.alias" size="small"></el-input>
                    </el-form-item>

                    <el-form-item label="信用等级：" prop="credit_id">
                        <el-select v-model="Form.credit_id" clearable placeholder="请选择" size="small">
                            <el-option v-for="item in creditRatingData" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>


                </div>

                <div class="Item">

                    <el-form-item label="折扣：" prop="discount_id">
                        <el-select v-model="Form.discount_id" clearable placeholder="请选择" size="small">
                            <el-option v-for="item in discountData" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="配送方案：" prop="plan_id">
                        <el-select v-model="Form.plan_id" clearable placeholder="请选择" size="small">
                            <el-option v-for="item in DistributionSchemeData" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>


                </div>

                <div class="Item">
                    <el-form-item label="是否启用：" prop="is_enable">
                        <el-radio-group v-model="Form.is_enable">
                            <el-radio :label="0">否</el-radio>
                            <el-radio :label="1">是</el-radio>
                        </el-radio-group>
                    </el-form-item>


                    <el-form-item label="是否有积分：" prop="is_integral">
                        <el-radio-group v-model="Form.is_integral">
                            <el-radio :label="0">否</el-radio>
                            <el-radio :label="1">是</el-radio>
                        </el-radio-group>
                    </el-form-item>

                </div>


            </el-form>

            <div class="submitBtn">
                <el-button class="frontBtn" @click="submit" size="small">提 交</el-button>
                <el-button class="reverseBtn" @click="goReset" size="small">重 置</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                Form: {
                    customer_number: '',
                    name: '',
                    alias: '',
                    credit_id: '',
                    discount_id: '',
                    plan_id: '',
                    is_enable: ''?true:false,
                    is_integral: ''?true:false,
                },
                Rules: {
                    name:[
                        {
                            required: true, message: '请把表单填写完整', trigger: 'blur' 
                        }
                    ],
                    customer_number:[
                        {
                            required: true, message: '请把表单填写完整', trigger: 'blur' 
                        }
                    ],
                    alias:[
                        {
                            required: true, message: '请把表单填写完整', trigger: 'blur' 
                        }
                    ],
                    credit_id:[
                        {
                            required: true, message: '请把表单填写完整', trigger: 'blur' 
                        }
                    ],
                    discount_id:[
                        {
                            required: true, message: '请把表单填写完整', trigger: 'blur' 
                        }
                    ],
                    plan_id:[
                        {
                            required: true, message: '请把表单填写完整', trigger: 'blur' 
                        }
                    ],
                     is_enable:[
                        {
                            required: true, message: '请把表单填写完整', trigger: 'blur' 
                        }
                    ],
                     is_integral:[
                        {
                            required: true, message: '请把表单填写完整', trigger: 'blur' 
                        }
                    ],
                },

                creditRatingData: [],
                discountData: [],
                DistributionSchemeData: [],

            }
        },
        created() {
            this.init()
            this.getcreditRating()
            this.getDiscount()
            this.getDistributionScheme()
        },
        methods: {

            init() {
                let data = this.$route.query.data

                this.Form.customer_number=data.number
                this.Form.name=data.name
                this.Form.alias=data.alias
                this.Form.credit_id=data.credit_id
                this.Form.discount_id=data.discount_id
                this.Form.plan_id=data.plan_id
                this.Form.is_enable=data.is_enable?1:0
                this.Form.is_integral=data.is_integral?1:0

            },

            //  获取信用等级
            getcreditRating() {
                this.$store.commit('getcreditRating')
                this.creditRatingData = this.$store.state.creditRatingData
            },

            // 获取折扣选择
            getDiscount() {
                this.$store.commit('getDiscount')
                this.discountData = this.$store.state.discountData
            },


            // 获取配送方案选择
            getDistributionScheme() {
                this.$store.commit('getDistributionScheme')
                this.DistributionSchemeData = this.$store.state.DistributionSchemeData
            },
            

            // 提交
            async submit(){
                let params=this.Form
                const {data}=await this.$https.post('/admin/customer/edit?_ajax=1',params)
                if(data.error_code!==0)  return this.$message.error(data.msg)
                this.$message.success(data.msg)
                this.$router.go(-1)
            },

            goReset(){
               for(let k in this.Form){    
                   this.Form[k]=''
               }
                this.Form.customer_number=this.$route.query.data.number
            },


         
            goBack() {
                this.$router.go(-1)
            }
        }
    }
</script>

<style lang="less" scoped>
    .Back {
        display: flex;
        justify-content: end;
        padding-right: 5%;
    }

    .title {
        padding-left: 30%;
        margin-bottom: 3%;
    }

    .Item {
        display: flex;
        align-items: center;
        justify-content:space-evenly;
        padding: 0 18%;
        // background-color: pink;
        margin-top: 20px;
    }

    .submitBtn{
        margin-top: 3%;
        margin-bottom: 2%;
        padding-left: 32%;
    }

    
</style>