import { render, staticRenderFns } from "./CustomerSet.vue?vue&type=template&id=2b32f844&scoped=true&"
import script from "./CustomerSet.vue?vue&type=script&lang=js&"
export * from "./CustomerSet.vue?vue&type=script&lang=js&"
import style0 from "./CustomerSet.vue?vue&type=style&index=0&id=2b32f844&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b32f844",
  null
  
)

export default component.exports