<template>
    <div id="app">
        <!-- 面包屑导航 -->
        <!-- <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>客户设置</el-breadcrumb-item>
            <el-breadcrumb-item>信用等级设置</el-breadcrumb-item>
        </el-breadcrumb> -->


        <el-card>

            <!--按钮区 -->

            <div class="DistributionSchemeBtns">
                <el-button @click="createCreditRating" icon="el-icon-plus" class="frontBtn" size="small">创建信用等级
                </el-button>
            </div>

            <el-table :data="creditRatingData" border style="width: 100%" :header-cell-style="styleBindFun">
                <el-table-column prop="name" label="信用等级" align="center">
                </el-table-column>
                <el-table-column prop="limit" label="信用限额" align="center">
                    <template slot-scope="scope">
                        ￥{{scope.row.limit}}.00
                    </template>
                </el-table-column>
                <el-table-column prop="num" label="信用结算订单数量" align="center">
                </el-table-column>
                <el-table-column label="信用结算周期" align="center">
                    <template slot-scope="scope">
                        {{scope.row.period}} 天
                    </template>
                </el-table-column>
                <el-table-column prop="customers_count" label="客户人数" align="center">
                </el-table-column>
                <el-table-column prop="update_time" label="更新时间" align="center">
                    <template slot-scope="scope">
                        {{scope.row.update_time.trim().split(" ")[0]}}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button @click="editCreditRating(scope.row)" type="text" size="medium">修改</el-button>
                        <span class="splitLineClass"></span>
                        <el-button @click="deletecustomerMOQ(scope.row)" type="text" class="deleteTextBtn"
                            size="medium">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>



        <!-- ---------------------------------------- 对话框第一层 --------------------------------------------------- -->
        <!-- 修改信用等级对话框 -->
        <el-dialog title="修改信用等级" :visible.sync="is_CreditRating_edit" width="24%">

            <el-form :model="Form_createCreditRating" :rules="CreditRatingRules" label-width="150px">
                <el-form-item label="信用等级名称" prop="name">
                    <el-input v-model="Form_createCreditRating.name"></el-input>
                </el-form-item>
                <el-form-item label="信用限额" prop="limit">
                    <el-input v-model="Form_createCreditRating.limit"></el-input>
                </el-form-item>
                <el-form-item label="信用结算订单数量" prop="num">
                    <el-input v-model="Form_createCreditRating.num"></el-input>
                </el-form-item>
                <el-form-item label="信用结算周期">
                    <el-input v-model="Form_createCreditRating.period"></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_CreditRating_edit = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" type="primary" @click="editCreditRatingDo">确 定</el-button>
            </div>
        </el-dialog>


        <!-- 创建信用等级对话框 -->
        <el-dialog title="创建信用等级" :visible.sync="is_createCreditRating" width="30%">

            <el-form :model="Form_createCreditRating" :rules="CreditRatingRules" label-width="150px">
                <el-form-item label="信用等级名称：" prop="name">
                    <el-input v-model="Form_createCreditRating.name"></el-input>
                </el-form-item>
                <el-form-item label="信用限额：" prop="limit">
                    <el-input v-model="Form_createCreditRating.limit"></el-input>
                </el-form-item>
                <el-form-item label="信用结算订单数量：" prop="num">
                    <el-input v-model="Form_createCreditRating.num"></el-input>
                </el-form-item>
                <el-form-item label="信用结算周期：" prop="period">
                    <el-input v-model="Form_createCreditRating.period"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_createCreditRating = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" type="primary" @click="createCreditRatingDo">确 定</el-button>
            </div>
        </el-dialog>


    </div>
</template>

<script>
    import {
        adminApi
    } from '../../config'

    import {
    domainApi
  } from '../../config'
  
    export default {
        data() {
            return {
                // -----------------------获取信用等级列表数据--------------------------------------
                creditRatingData: [],

                // ----------------------查看配送方案详情数据--------------------------------------
                DistributionSchemeDetailData: [],
                is_DistributionScheme_detail: false,

                // ----------------------创建信用等级数据--------------------------------------
                is_createCreditRating: false,
                Form_createCreditRating: {
                    name: '',
                    limit: '',
                    num: '',
                    period: '',
                },
                CreditRatingRules: {
                    name: {
                        required: true,
                        message: '请输入信用等级名称',
                        trigger: 'blur'
                    },
                    limit: {
                        required: true,
                        message: '请输入信用限额',
                        trigger: 'blur'
                    },
                    num: {
                        required: true,
                        message: '请输入信用结算订单数量',
                        trigger: 'blur'
                    },
                },

                // ----------------------编辑信用等级数据--------------------------------------
                is_CreditRating_edit: false,
                current_edit_id: "",

            }
        },
        created() {
            this.getCreditRating();
        },
       
        methods: {
         


            // 绑定表格头部样式方法
            styleBindFun() {
                let styleBind = "background:#f6f8fa;fontSize:14px;color:#333333;fontWeight: bold;"
                return styleBind;
            },

            // -----------------------获取信用等级列表数据--------------------------------------
            async getCreditRating() {
                const res = await this.$https.post(adminApi + '/credits?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.creditRatingData = res.data

            },


            // ----------------------创建起订量详情数据--------------------------------------
            createCreditRating() {
                this.is_createCreditRating = true

                // 清空
                for (let k in this.Form_createCreditRating) {
                    this.Form_createCreditRating[k] = ''
                }
            },
            async createCreditRatingDo() {
                this.$confirm('确认增加这个信用等级吗?')
                    .then(async () => {
                        let params = this.Form_createCreditRating
                        const {
                            data
                        } = await this.$https.post(adminApi + '/credit/create?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)
                        this.is_createCreditRating = false
                        this.getCreditRating();
                    })
                    .catch(() => {
                        this.$message.info('您取消了按钮')
                    });
            },

            // ----------------------编辑信用等级数据--------------------------------------
            editCreditRating(row) {

                this.is_CreditRating_edit = true
                let {
                    id,
                    name,
                    limit,
                    num,
                    period,
                } = row
                this.Form_createCreditRating.name = name
                this.Form_createCreditRating.limit = limit
                this.Form_createCreditRating.num = num
                this.Form_createCreditRating.period = period
                this.current_edit_id = id
            },
            async editCreditRatingDo() {

                let params = this.Form_createCreditRating
                params.id = this.current_edit_id
                const {
                    data
                } = await this.$https.post(adminApi + '/credit/edit?_ajax=1', params)
                if (data.error_code !== 0) return this.$message.error(data.msg)


                this.$message.success(data.msg);
                this.is_CreditRating_edit = false
                this.getCreditRating();

            },


            // ----------------------删除信用等级数据--------------------------------------
            async deletecustomerMOQ(row) {
                this.$confirm('确认删除这个信用等级吗?')
                    .then(async () => {
                        let params = {
                            credit_id: row.id
                        }
                        const {
                            data
                        } = await this.$https.post(adminApi + '/credit/delete?_ajax=1', params)
                        if (data.error_code !== 0) return this.$message.error(data.msg)
                        this.$message.success(data.msg)
                        this.getCreditRating()
                    })
                    .catch(() => {
                        this.$message.info('您取消了按钮')
                    });
            },
        },
    }
</script>

<style lang="less" scoped>
    .DistributionSchemeBtns {
        // background-color: #dfe9f5;
        padding: 6px 20px;
        margin-bottom: 20px;
    }
</style>